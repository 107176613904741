import React, { useEffect } from "react";

export default function MapLocationAds(props) {
  useEffect(() => {
    const iframeData = document.getElementById("iframeId");
    const lat =  props.props.lati; // Replace with your latitude
    const lon =  props.props.long; // Replace with your longitude

    // Construct the HTML content to embed the Google Map with the marker
    const iframeContent = `
      <html>
        <head></head>
        <body>
          <div>
            <iframe
              width="100%"
              height="300"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?q=${lat},${lon}&z=15&output=embed"
            ></iframe>
          </div>
          <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 1024 1024"
            >
            </svg>
          </div>
        </body>
      </html>
    `;

    // Set the iframe's content
    iframeData.contentWindow.document.open();
    iframeData.contentWindow.document.write(iframeContent);
    iframeData.contentWindow.document.close();
  });

  return (
    <div style={{ marginBottom: "10px", width: "100%" }}>
      <div style={{ border: "10px", borderColor: "blue" }}>
        <iframe
          id="iframeId"
          title="Google Map"
          height="300px"
          width="100%"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
}
