import React, { useContext, useEffect, useState } from "react";
import { MdCameraAlt } from "react-icons/md";
import { AuthContext } from "../context/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";

const UserDetail = ({
  userId,
  handleSelect,
  photoURL,
  name,
  lastMessage,
  userInfo,
  activeUser,
}) => {
  const { currentUser } = useContext(AuthContext);
  const isChatActive = userId?.includes(activeUser);
  const [unreadCount, setUnreadCount] = useState(null);
  const getChatsWithThisPerson = async () => {
    console.log(userId, "*** user");
    const res = await getDoc(doc(db, "chats", userId));
    const messages = res.data().messages;
    const count = messages.filter((item) => item["readBy"][currentUser.uid])
      .length;
    console.log(messages, "*** messages");
    setUnreadCount(messages.length - count);
  };

  useEffect(() => {
    getChatsWithThisPerson();
    return () => {
      getChatsWithThisPerson();
    };
  }, [userId, lastMessage]);
  return (
    <div
      className={`userChat ${isChatActive ? "userChatActive" : ""}`}
      key={userId}
      onClick={() => {
        handleSelect(userInfo);
        setUnreadCount(0);
      }}
    >
      <img
        src={
          Boolean(photoURL)
            ? photoURL
            : `https://ui-avatars.com/api/?name=${name}&background=random`
        }
        alt={"No img"}
      />
      <div className="userChatInfo">
        <span>{name}</span>
        {lastMessage?.text ? (
          <p>{lastMessage?.text.slice(0, 30)}</p>
        ) : lastMessage?.imageUrl === "" ? (
          <p
            style={{
              color: isChatActive ? "#fff" : "#bfbfbf",
            }}
          >
            <MdCameraAlt className="mr-1" />
            Image
          </p>
        ) : (
          <p
            style={{
              fontStyle: "italic",
              color: isChatActive ? "#fff" : "#bfbfbf",
            }}
          >
            No messages yet, Chat First
          </p>
        )}
      </div>
      {unreadCount !== null && unreadCount !== 0 && (
        <div
          style={{
            width: "fit-content",
            minWidth: "25px",
            aspectRatio: 1,
            background: "#8da4f1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            borderRadius: "100%",
            fontSize: "10px",
            lineHeight: "0px",
            padding: 0,
          }}
        >
          {unreadCount}
        </div>
      )}
    </div>
  );
};

export default UserDetail;
