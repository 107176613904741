import React, { useEffect, useState } from 'react'
import Carousel from "react-grid-carousel";
import { useParams } from 'react-router-dom';
import { url } from '../env';

const Viewimgver = () => {
    const {id}=useParams();
  const [imagesmutlipleads, setimagesmutlipleads] = useState([]);

    useEffect(() => {
        var formdata = new FormData();
        formdata.append("adsId", id);
    
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
    
        fetch(url + "api/adsapi/adsUpload", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log("result", result);
    
            var multipleImage = [];
    
            result.map((result) => {
              let tempObjImage = {};
              tempObjImage["url"] = result.fields.image;
              multipleImage.push(tempObjImage);
            });
            console.log("!!!!image ", multipleImage);
            setimagesmutlipleads(multipleImage);
          })
          .catch((error) => console.log("error", error));
      }, [id]);
  return (
    <div
                      style={{
                        border: "1px solid #00000012",
                        height: "300px",
                        borderRadius: "5px",
                      }}
                    >
                      <Carousel
                        cols={imagesmutlipleads.length > 1 ? 2 : 2}
                        rows={imagesmutlipleads.length > 3 ? 1 : 1}
                        gap={10}
                        loop
                        autoplay={3000}
                      >
                        {imagesmutlipleads.map((img, index) => {
                          return (
                            <Carousel.Item>
                              <img
                                alt=""
                                width="100%"
                                style={{
                                  height: "300px",
                                  backgroundRepeat: "fill",
                                }}
                                src={img.url}
                                
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
  )
}

export default Viewimgver