import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import { addSearchData } from "../../../../store/SearchCategory/searchCategorySlice"; // Importing addSearchData function from searchCategorySlice
import {
  All,
  subcategoryRealEstateBuy, // Imported from "../../../env"
  subcategoryRealEstateRent, // Imported from "../../../env"
  subcategoryType1, // Imported from "../../../env"
} from "../../../env";
import { stateMain, locationData } from "../../../Location";
import "./SearchBox.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Checkbox, Row, Col, Select, Input } from "antd";

const { Option } = Select;

const SearchBox = (props) => {
  // State variables
  const [subCategoryType, setsubCategoryType] = useState(null); // State for subcategory type
  const [subCategoryValue, setsubCategoryValue] = useState(null);
  const [rangeValues, setRangeValues] = useState({ min: 100, max: 1000000000 }); // State for subcategory value
  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    minPrice: null,
    maxPrice: null,
    searchValue: null,
    category: "RealEstate",
  }); // State for search form data
  const [ExtraFieldCopy, setExtraFieldCopy] = useState({});
  const [ExtraFieldCopy1, setExtraFieldCopy1] = useState({});
  const [ExtraFieldCopy2, setExtraFieldCopy2] = useState({});
  const [ExtraFieldCopy3, setExtraFieldCopy3] = useState({});
  const [ExtraFieldCopy4, setExtraFieldCopy4] = useState({});
  const [ExtraFieldCopy5, setExtraFieldCopy5] = useState({});
  const [ExtraFieldCopy6, setExtraFieldCopy6] = useState({});
  const [selectedage, setselectedage] = useState([]);
  const [buildup, setbuildup] = useState();
  const [rent, setrent] = useState();
  const [deposite, setdeposite] = useState();
  const [carpet, setcarpet] = useState();

  const handleDropdownChange = (value11) => {
    setbuildup({ "Build Up Area": value11 });
  };
  const handleDropdownChange2 = (value11) => {
    setrent({ "Build Up Area": value11 });
  };
  const handleDropdownChange3 = (value11) => {
    setdeposite({ "Build Up Area": value11 });
  };
  const handleDropdownChange1 = (value11) => {
    setcarpet({ "Carpet Area": value11 });
  };

  const handleTypeage = (selectedValues) => {
    setselectedage(selectedValues);
  };

  const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setsearchSending({ ...searchSending, [name]: value });
    disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  return (
    <div style={{ backgroundColor: "transparent" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "10px",
        }}
      >
        <button
          className="realestatebtn"
          name="subCategoryType"
          value="Residential"
          onClick={handleChange}
          style={{ backgroundColor: searchSending.subCategoryType === "Residential" ? "blue" : "", color: searchSending.subCategoryType === 'Residential' ?'white' : ''  }}
        >
          Residential
        </button>
        <button
          className="realestatebtn"
          name="subCategoryType"
          value="Commercial"
          onClick={handleChange}
          style={{ backgroundColor: searchSending.subCategoryType === "Commercial" ? "blue" : "" , color: searchSending.subCategoryType === 'Commercial' ?'white' : '' }}
        >
          Commercial
        </button>
      </div>
      <br />
      <div className="dropdown bootstrap-select show-tick houzez-field-textual form-control elementor-size-sm status-js mt-2">
        {/* Conditional rendering based on subCategoryType value */}
        {searchSending.subCategoryType === "Buy" ? (
          <div>
            <input
              className="inpstyle"
              placeholder="subcategoryRealEstateBuy"
              type="text"
              list="subcategoryRealEstateBuy"
              style={{
                padding: "12px",
                width: "100%",
                zIndex: "-50px",
              }}
              name="subCategoryValue"
              onChange={handleChange}
            />

            <datalist id="subcategoryRealEstateBuy">
              {subcategoryRealEstateBuy.map((result) => {
                return <option value={result}>{result}</option>;
              })}
            </datalist>
          </div>
        ) : searchSending.subCategoryType === "rent" ? (
          <div>
            <input
              className="inpstyle"
              placeholder="subcategoryRealEstateRent"
              type="text"
              list="subcategoryRealEstateRent"
              style={{
                padding: "12px",
                width: "100%",
                zIndex: "-50px",
              }}
              name="subCategoryValue"
              onChange={handleChange}
            />
            <datalist id="subcategoryRealEstateRent">
              {subcategoryRealEstateRent.map((result) => {
                return <option value={result}>{result}</option>;
              })}
            </datalist>
          </div>
        ) : searchSending.subCategoryType === "other" ? (
          <div>
            <input
              className="inpstyle"
              placeholder="subcategoryRealEstateRent"
              type="text"
              style={{
                padding: "12px",
                width: "100%",
                zIndex: "-50px",
              }}
              name="subCategoryValue"
              onChange={handleChange}
            />
          </div>
        ) : (
          <div>
            <input
              className="inpstyle"
              placeholder="All"
              type="text"
              list="subcategoryRealEstateRent"
              style={{
                padding: "10px",
                width: "100%",
                zIndex: "-50px",
              }}
              name="subCategoryValue"
              onChange={handleChange}
            />
            <datalist id="subcategoryRealEstateRent">
              {searchSending.subCategoryType === "Residential"
                ? subcategoryRealEstateBuy.map((result) => {
                    return <option value={result}>{result}</option>;
                  })
                : searchSending.subCategoryType === "Commercial"
                ? subcategoryRealEstateRent.map((result) => {
                    return <option value={result}>{result}</option>;
                  })
                : null}
            </datalist>
          </div>
        )}
        <div
          className="dropdown-menu"
          style={{ maxHeight: 260, overflow: "hidden" }}
        >
          {subCategoryType === "Buy" ? (
            <div className="col-lg-6 col-sm-12">
              <label>Type</label>

              <input
                className="inpstyle"
                placeholder="subcategoryRealEstateBuy"
                type="text"
                list="subcategoryRealEstateBuy"
                style={{
                  padding: "12px",
                  width: "100%",
                  zIndex: "-50px",
                }}
                onChange={(e) => {
                  setsubCategoryValue(e.target.value);
                }}
              />
              <datalist id="subcategoryRealEstateBuy">
                {subcategoryRealEstateBuy.map((result) => {
                  return <option value={result}>{result}</option>;
                })}
              </datalist>
            </div>
          ) : searchSending.subCategoryType === "rent" ? (
            <div className="col-lg-6 col-sm-12 m-1">
              <label>Type</label>

              <input
                className="inpstyle"
                placeholder="subcategoryRealEstateRent"
                type="text"
                list="subcategoryRealEstateRent"
                style={{
                  padding: "12px",
                  width: "100%",
                  zIndex: "-50px",
                }}
                onChange={(e) => {
                  setsubCategoryValue(e.target.value);
                }}
              />
              <datalist id="subcategoryRealEstateRent">
                {subcategoryRealEstateRent.map((result) => {
                  return <option value={result}>{result}</option>;
                })}
              </datalist>
            </div>
          ) : searchSending.subCategoryType === "other" ? (
            <div className="col-lg-6 col-sm-12 m-1">
              <label>Enter Here</label>

              <input
                className="inpstyle"
                placeholder="subcategoryRealEstateRent"
                type="text"
                style={{
                  padding: "12px",
                  width: "100%",
                  zIndex: "-50px",
                }}
                onChange={(e) => {
                  setsubCategoryValue(e.target.value);
                }}
              />
            </div>
          ) : null}
          <div
            className="inner show"
            role="listbox"
            id="bs-select-4"
            tabIndex={-1}
            aria-multiselectable="true"
            style={{
              maxHeight: 205,
              overflowY: "auto",
            }}
          >
            {subCategoryType === "Buy" ? (
              <div className="col-lg-6 col-sm-12">
                <label>Type</label>

                <input
                  className="inpstyle"
                  placeholder="subcategoryRealEstateBuy"
                  type="text"
                  list="subcategoryRealEstateBuy"
                  style={{
                    padding: "12px",
                    width: "100%",
                    zIndex: "-50px",
                  }}
                  onChange={(e) => {
                    setsubCategoryValue(e.target.value);
                  }}
                />
                <datalist id="subcategoryRealEstateBuy">
                  {subcategoryRealEstateBuy.map((result) => {
                    return <option value={result}>{result}</option>;
                  })}
                </datalist>
              </div>
            ) : searchSending.subCategoryType === "rent" ? (
              <div className="col-lg-6 col-sm-12 m-1">
                <label>Type</label>

                <input
                  className="inpstyle"
                  placeholder="subcategoryRealEstateRent"
                  type="text"
                  list="subcategoryRealEstateRent"
                  style={{
                    padding: "12px",
                    width: "100%",
                    zIndex: "-50px",
                  }}
                  onChange={(e) => {
                    setsubCategoryValue(e.target.value);
                  }}
                />
                <datalist id="subcategoryRealEstateRent">
                  {subcategoryRealEstateRent.map((result) => {
                    return <option value={result}>{result}</option>;
                  })}
                </datalist>
              </div>
            ) : searchSending.subCategoryType === "other" ? (
              <div className="col-lg-6 col-sm-12">
                <label>Enter Here</label>

                <input
                  className="inpstyle"
                  placeholder="subcategoryRealEstateRent"
                  type="text"
                  style={{
                    padding: "12px",
                    width: "100%",
                    zIndex: "-50px",
                  }}
                  onChange={(e) => {
                    setsubCategoryValue(e.target.value);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <br />
{/* 
        {searchSending.subCategoryValue === "Buy-Commercial" ||
        searchSending.subCategoryValue === "Rent-Commercial" ? (
          <>
            <div className="topcheck">
              <div>
                <h6>Property type</h6>
                <Checkbox.Group>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Office Space"
                        onClick={() => {
                          setExtraFieldCopy({ "Property Type": "Office Space " });
                        }}
                      >
                        Office Space
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="CO-Working"
                        onClick={() => {
                          setExtraFieldCopy({ "Property Type": "CO-Working" });
                        }}
                      >
                        CO-Working
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Shop"
                        onClick={() => {
                          setExtraFieldCopy({ "Property Type": "Shop" });
                        }}
                      >
                        Shop
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Showroom"
                        onClick={() => {
                          setExtraFieldCopy({ "Property Type": "Showroom" });
                        }}
                      >
                        Showroom
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Godown/Warehouse"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Property Type": "Godown/Warehouse",
                          });
                        }}
                      >
                        Godown/Warehouse
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Industrial Shed"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Property Type": "Industrial Shed",
                          });
                        }}
                      >
                        Industrial Shed
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Industrial Building"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Property Type": "Industrial Building",
                          });
                        }}
                      >
                        Industrial Building
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Other Business"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Property Type": "Other Business",
                          });
                        }}
                      >
                        Other Business
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div>
                <div>
                  <h6>Property Age</h6>
                  <Checkbox.Group value={selectedage} onChange={handleTypeage}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Checkbox
                          value="Less Than A Year"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "Less Than A Year",
                            });
                          }}
                        >
                          Less Than A Year
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="1 to 3 Years"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "1 to 3 Years",
                            });
                          }}
                        >
                          1 to 3 Years
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="3 to 5 Years"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "3 to 5 Years",
                            });
                          }}
                        >
                          3 to 5 Years
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="5 to 10 Years"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "5 to 10 Years",
                            });
                          }}
                        >
                          5 to 10 Years
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="10 to  15 Years"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "10 to  15 Years",
                            });
                          }}
                        >
                          10 to 15 Years
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="15 Years & Above"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "15 Years & Above",
                            });
                          }}
                        >
                          15 Years & Above
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </div>

                <div>
                  <h6>Furnishing</h6>
                  <Checkbox.Group>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Checkbox
                          value="Fully Furnished"
                          onClick={() => {
                            setExtraFieldCopy2({
                              "Furnishing Type": "Fully Furnished",
                            });
                          }}
                        >
                          Fully Furnished
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="Semi-Furnished"
                          onClick={() => {
                            setExtraFieldCopy2({
                              "Furnishing Type": "Semi-Furnished",
                            });
                          }}
                        >
                          Semi-Furnished
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="Unfurnished"
                          onClick={() => {
                            setExtraFieldCopy2({
                              "Furnishing Type": "Unfurnished",
                            });
                          }}
                        >
                          Unfurnished
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                zIndex: 10000,
              }}
            >
              <div>
                <h6>Build Up Area</h6>

                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="classclass"
                  placeholder="Build Up Area"
                  options={[
                    "100 to 150 sqft",
                    "151 to 200 sqft",
                    "201 to 250 sqft",
                    "251 to 300 sqft",
                    "301 to 350 sqft",
                    "351 to 400 sqft",
                    "401 to 450 sqft",
                  ].map((result) => ({
                    value: result,
                    label: result,
                  }))}
                  onChange={handleDropdownChange}
                />
              </div>
              <div>
                <h6>Carpet Area</h6>

                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="classclass"
                  placeholder="Build Up Area"
                  options={[
                    "100 to 150 sqft",
                    "151 to 200 sqft",
                    "201 to 250 sqft",
                    "251 to 300 sqft",
                    "301 to 350 sqft",
                    "351 to 400 sqft",
                    "401 to 450 sqft",
                  ].map((result) => ({
                    value: result,
                    label: result,
                  }))}
                  onChange={handleDropdownChange1}
                />
              </div>
            </div>
            <div>
              <h6>WiFi</h6>
              <Checkbox.Group>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Yes"
                      onClick={() => {
                        setExtraFieldCopy3({ Wifi: "Yes" });
                      }}
                    >
                      Yes
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="No"
                      onClick={() => {
                        setExtraFieldCopy3({ Wifi: "No" });
                      }}
                    >
                      No
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <h6>PowerBackup</h6>
              <Checkbox.Group>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Full"
                      onClick={() => {
                        setExtraFieldCopy4({ "Power Backup": "Full" });
                      }}
                    >
                      Full
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="DG Backup"
                      onClick={() => {
                        setExtraFieldCopy4({ "Power Backup": "DG Backup" });
                      }}
                    >
                      DG Backup
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Need To Arrange"
                      onClick={() => {
                        setExtraFieldCopy4({ "Power Backup": "Need To Arrange" });
                      }}
                    >
                      Need To Arrange
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <h6>Lift</h6>
              <Checkbox.Group>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Common"
                      onClick={() => {
                        setExtraFieldCopy5({ Lift: "Common" });
                      }}
                    >
                      Common
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Private"
                      onClick={() => {
                        setExtraFieldCopy5({ Lift: "Private" });
                      }}
                    >
                      Private
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="None"
                      onClick={() => {
                        setExtraFieldCopy5({ Lift: "None" });
                      }}
                    >
                      None
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <h6>Parking</h6>
              <Checkbox.Group>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="None"
                      onClick={() => {
                        setExtraFieldCopy6({ Parking: "None" });
                      }}
                    >
                      None
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Public And Reserved"
                      onClick={() => {
                        setExtraFieldCopy6({ Parking: "Public And Reserved" });
                      }}
                    >
                      Public And Reserved
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Reserved"
                      onClick={() => {
                        setExtraFieldCopy6({ Parking: "Reserved" });
                      }}
                    >
                      Reserved
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Public"
                      onClick={() => {
                        setExtraFieldCopy6({ Parking: "Public" });
                      }}
                    >
                      Public
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
          </>
        ) : null}

        {searchSending.subCategoryValue === "Buy-Residential" ? (
          <>
            <div className="topcheck">
              <div>
                <h6>Property type</h6>
                <Checkbox.Group>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Apartment"
                        onClick={() => {
                          setExtraFieldCopy({ "Property Type": "Apartment" });
                        }}
                      >
                        Apartment
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Independent House/Villa"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Property Type": "Independent House/Villa",
                          });
                        }}
                      >
                        Independent House/Villa
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Gated Community/Villa"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Property Type": "Gated Community/Villa",
                          });
                        }}
                      >
                        Gated Community/Villa
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Standalone Building"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Property Type": "Standalone Building",
                          });
                        }}
                      >
                        Standalone Building
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div>
                <div>
                  <h6>Property Age</h6>
                  <Checkbox.Group value={selectedage} onChange={handleTypeage}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Checkbox
                          value="Less Than A Year"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "Less Than A Year",
                            });
                          }}
                        >
                          Less Than A Year
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="1 to 3 Years"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "1 to 3 Years",
                            });
                          }}
                        >
                          1 to 3 Years
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="3 to 5 Years"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "3 to 5 Years",
                            });
                          }}
                        >
                          3 to 5 Years
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="5 to 10 Years"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "5 to 10 Years",
                            });
                          }}
                        >
                          5 to 10 Years
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="10 to  15 Years"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "10 to  15 Years",
                            });
                          }}
                        >
                          10 to 15 Years
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="15 Years & Above"
                          onClick={() => {
                            setExtraFieldCopy1({
                              "Property Age": "15 Years & Above",
                            });
                          }}
                        >
                          15 Years & Above
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </div>

                <div>
                  <h6>Furnishing</h6>
                  <Checkbox.Group>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Checkbox
                          value="Fully Furnished"
                          onClick={() => {
                            setExtraFieldCopy2({
                              "Furnishing Type": "Fully Furnished",
                            });
                          }}
                        >
                          Fully Furnished
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="Semi-Furnished"
                          onClick={() => {
                            setExtraFieldCopy2({
                              "Furnishing Type": "Semi-Furnished",
                            });
                          }}
                        >
                          Semi-Furnished
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox
                          value="Unfurnished"
                          onClick={() => {
                            setExtraFieldCopy2({
                              "Furnishing Type": "Unfurnished",
                            });
                          }}
                        >
                          Unfurnished
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                zIndex: 10000,
              }}
            >
              <div>
                <h6>Build Up Area</h6>
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="classclass"
                  placeholder="Build Up Area"
                  options={[
                    "100 to 300 sqft",
                    "300 to 600 sqft",
                    "600 to 1000 sqft",
                    "1000 to 1500 sqft",
                    "1500 to 2000 sqft",
                    "2000 to 2500 sqft",
                    "2500 to 3000 sqft",
                    "3000 to 4000 sqft",
                    "4000 to 5000 sqft",
                    "5000 sqft & Above",
                  ].map((result) => ({
                    value: result,
                    label: result,
                }))}
                onChange={handleDropdownChange}
              />
            </div>
            <div>
              <h6>Carpet Area</h6>

              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass"
                placeholder="Carpet Area"
                options={[
                  "100 to 300 sqft",
                  "300 to 600 sqft",
                  "600 to 1000 sqft",
                  "1000 to 1500 sqft",
                  "1500 to 2000 sqft",
                  "2000 to 2500 sqft",
                  "2500 to 3000 sqft",
                  "3000 to 4000 sqft",
                  "4000 to 5000 sqft",
                  "5000 sqft & Above",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange1}
              />
            </div>
          </div>
          <div>
            <h6>Bath Rooms</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Yes"
                    onClick={() => {
                      setExtraFieldCopy3({ "Bath Rooms": "Yes" });
                    }}
                  >
                    Yes
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="No"
                    onClick={() => {
                      setExtraFieldCopy3({ "Bath Rooms": "No" });
                    }}
                  >
                    No
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>PowerBackup</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Full"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Full" });
                    }}
                  >
                    Full
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Partial"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Partial" });
                    }}
                  >
                    Partial
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="None"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "None" });
                    }}
                  >
                    None
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Kitchen Type</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Modular"
                    onClick={() => {
                      setExtraFieldCopy5({ "Kitchen Type": "Modular" });
                    }}
                  >
                    Modular
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Covered Shelves"
                    onClick={() => {
                      setExtraFieldCopy5({ "Kitchen Type": "Covered Shelves" });
                    }}
                  >
                    Covered Shelves
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Open Shelves"
                    onClick={() => {
                      setExtraFieldCopy5({ "Kitchen Type": "Open Shelves" });
                    }}
                  >
                    Open Shelves
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Parking</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Car"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Car" });
                    }}
                  >
                    Car
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Bike"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Bike" });
                    }}
                  >
                    Bike
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Both"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Both" });
                    }}
                  >
                    Both
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="None"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "None" });
                    }}
                  >
                    None
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </>
      ) : null}

      {searchSending.subCategoryValue === "Rent-Residential" ? (
        <>
          <div className="topcheck">
            <div>
              <h6>Property type</h6>
              <Checkbox.Group>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Apartment"
                      onClick={() => {
                        setExtraFieldCopy({ "Property Type": "Apartment" });
                      }}
                    >
                      Apartment
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Independent House/Villa"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Independent House/Villa",
                        });
                      }}
                    >
                      Independent House/Villa
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Gated Community/Villa"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Gated Community/Villa",
                        });
                      }}
                    >
                      Gated Community/Villa
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <h6>Property Age</h6>
                <Checkbox.Group value={selectedage} onChange={handleTypeage}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Checkbox
                        value="Less Than A Year"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Less Than A Year",
                          });
                        }}
                      >
                        Less Than A Year
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="1 to 3 Years"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "1 to 3 Years",
                          });
                        }}
                      >
                        1 to 3 Years
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="3 to 5 Years"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "3 to 5 Years",
                          });
                        }}
                      >
                        3 to 5 Years
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="5 to 10 Years"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "5 to 10 Years",
                          });
                        }}
                      >
                        5 to 10 Years
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="10 to  15 Years"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "10 to  15 Years",
                          });
                        }}
                      >
                        10 to 15 Years
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="15 Years & Above"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "15 Years & Above",
                          });
                        }}
                      >
                        15 Years & Above
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>

              <div>
                <h6>Furnishing</h6>
                <Checkbox.Group>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Fully Furnished"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Furnishing Type": "Fully Furnished",
                          });
                        }}
                      >
                        Fully Furnished
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Semi-Furnished"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Furnishing Type": "Semi-Furnished",
                          });
                        }}
                      >
                        Semi-Furnished
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Unfurnished"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Furnishing Type": "Unfurnished",
                          });
                        }}
                      >
                        Unfurnished
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              zIndex: 10000,
            }}
          >
            <div>
              <h6>Build Up Area</h6>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass"
                placeholder="Build Up Area"
                options={[
                  "100 to 300 sqft",
                  "300 to 600 sqft",
                  "600 to 1000 sqft",
                  "1000 to 1500 sqft",
                  "1500 to 2000 sqft",
                  "2000 to 2500 sqft",
                  "2500 to 3000 sqft",
                  "3000 to 4000 sqft",
                  "4000 to 5000 sqft",
                  "5000 sqft & Above",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange}
              />
            </div>
            <div>
              <h6>Carpet Area</h6>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass"
                placeholder="Carpet Area"
                options={[
                  "100 to 300 sqft",
                  "300 to 600 sqft",
                  "600 to 1000 sqft",
                  "1000 to 1500 sqft",
                  "1500 to 2000 sqft",
                  "2000 to 2500 sqft",
                  "2500 to 3000 sqft",
                  "3000 to 4000 sqft",
                  "4000 to 5000 sqft",
                  "5000 sqft & Above",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange1}
              />
            </div>
          </div>
          <div>
            <h6>Bath Rooms</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Yes"
                    onClick={() => {
                      setExtraFieldCopy3({ "Bath Rooms": "Yes" });
                    }}
                  >
                    Yes
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="No"
                    onClick={() => {
                      setExtraFieldCopy3({ "Bath Rooms": "No" });
                    }}
                  >
                    No
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Park</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Yes"
                    onClick={() => {
                      setExtraFieldCopy3({ "Bath Rooms": "Yes" });
                    }}
                  >
                    Yes
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="No"
                    onClick={() => {
                      setExtraFieldCopy3({ "Bath Rooms": "No" });
                    }}
                  >
                    No
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Lift</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Yes"
                    onClick={() => {
                      setExtraFieldCopy3({ "Bath Rooms": "Yes" });
                    }}
                  >
                    Yes
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="No"
                    onClick={() => {
                      setExtraFieldCopy3({ "Bath Rooms": "No" });
                    }}
                  >
                    No
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>PowerBackup</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Full"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Full" });
                    }}
                  >
                    Full
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Partial"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Partial" });
                    }}
                  >
                    Partial
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="None"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "None" });
                    }}
                  >
                    None
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Kitchen Type</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Modular"
                    onClick={() => {
                      setExtraFieldCopy5({ "Kitchen Type": "Modular" });
                    }}
                  >
                    Modular
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Covered Shelves"
                    onClick={() => {
                      setExtraFieldCopy5({ "Kitchen Type": "Covered Shelves" });
                    }}
                  >
                    Covered Shelves
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Open Shelves"
                    onClick={() => {
                      setExtraFieldCopy5({ "Kitchen Type": "Open Shelves" });
                    }}
                  >
                    Open Shelves
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Parking</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Car"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Car" });
                    }}
                  >
                    Car
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Bike"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Bike" });
                    }}
                  >
                    Bike
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Both"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Both Car And Bike" });
                    }}
                  >
                    Both
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="None"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "None" });
                    }}
                  >
                    None 
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "PG-Hostel" ? (
        <>
          <div className="topcheck">
            <div>
              <h6>Available For</h6>
              <Checkbox.Group>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Male"
                      onClick={() => {
                        setExtraFieldCopy({ "Place Is Available For": "Male" });
                      }}
                    >
                      Male
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Female"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Place Is Available For": "Female",
                        });
                      }}
                    >
                      Female
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Anyone"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Place Is Available For": "Anyone",
                        });
                      }}
                    >
                      Anyone
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <h6>Preffered Guests</h6>
                <Checkbox.Group value={selectedage} onChange={handleTypeage}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Checkbox
                        value="  Working Professional"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Preffered Guests": "  Working Professional",
                          });
                        }}
                      >
                        {" "}
                        Working Professional
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Student"
                        onClick={() => {
                          setExtraFieldCopy1({ "Preffered Guests": "Student" });
                        }}
                      >
                        Student
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Both"
                        onClick={() => {
                          setExtraFieldCopy1({ "Preffered Guests": "Both" });
                        }}
                      >
                        Both
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>

              <div>
                <h6>Pg Rules</h6>
                <Checkbox.Group>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="No Smoking "
                        onClick={() => {
                          setExtraFieldCopy2({ "PG Rules": "No Smoking " });
                        }}
                      >
                        No Smoking{" "}
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="No Guardians Stay"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "PG Rules": "No Guardians Stay",
                          });
                        }}
                      >
                        No Guardians Stay
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="No Girls Stay"
                        onClick={() => {
                          setExtraFieldCopy2({ "PG Rules": "No Girls Stay" });
                        }}
                      >
                        No Girls Stay
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="No Drinking"
                        onClick={() => {
                          setExtraFieldCopy2({ "PG Rules": "No Drinking" });
                        }}
                      >
                        No Drinking
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="No Non Veg"
                        onClick={() => {
                          setExtraFieldCopy2({ "PG Rules": "No Non Veg" });
                        }}
                      >
                        No Non Veg
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              zIndex: 10000,
            }}
          >
            <div>
              <h6>Expected Rent</h6>

              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass"
                placeholder="Expected Rent"
                options={[
                  "5k to 7k",
                  "7k to 9k",
                  "9k to 11k",
                  "11k to 13k",
                  "13k to 15k",
                  "15k to 20k",
                  "20k to 25k",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange2}
              />
            </div>
            <div>
              <h6>Expected Deposit</h6>

              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass"
                placeholder="Expected Deposit"
                options={[
                  "Below 5k",
                  "5k to 7k",
                  "7k to 9k",
                  "9k to 11k",
                  "11k to 13k",
                  "13k to 15k",
                  "15k to 20k",
                  "20k to 25k",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange3}
              />
            </div>
          </div>
          <div>
            <h6>Food Included</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Yes"
                    onClick={() => {
                      setExtraFieldCopy3({ "Food Included": "Yes" });
                    }}
                  >
                    Yes
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="No"
                    onClick={() => {
                      setExtraFieldCopy3({ "Food Included": "No" });
                    }}
                  >
                    No
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Laundry</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Yes"
                    onClick={() => {
                      setExtraFieldCopy3({ Laundry: "Yes" });
                    }}
                  >
                    Yes
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="No"
                    onClick={() => {
                      setExtraFieldCopy3({ Laundry: "No" });
                    }}
                  >
                    No
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Room Cleaning</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Yes"
                    onClick={() => {
                      setExtraFieldCopy3({ "Room Cleaning": "Yes" });
                    }}
                  >
                    Yes
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="No"
                    onClick={() => {
                      setExtraFieldCopy3({ "Room Cleaning": "No" });
                    }}
                  >
                    No
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Looking For</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Single Room"
                    onClick={() => {
                      setExtraFieldCopy4({ "Looking For": "Single Room" });
                    }}
                  >
                    Single Room
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Double Room"
                    onClick={() => {
                      setExtraFieldCopy4({ "Looking For": "Double Room" });
                    }}
                  >
                    Double Room
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Triple Room"
                    onClick={() => {
                      setExtraFieldCopy4({ "Looking For": "Triple Room" });
                    }}
                  >
                    Triple Room
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Four Room"
                    onClick={() => {
                      setExtraFieldCopy4({ "Looking For": "Four Room" });
                    }}
                  >
                    Four Room
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Warden Facility</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Yes"
                    onClick={() => {
                      setExtraFieldCopy5({ "Warden Facility": "Yes" });
                    }}
                  >
                    Yes
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="No"
                    onClick={() => {
                      setExtraFieldCopy5({ "Warden Facility": "No" });
                    }}
                  >
                    No
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Open Shelves"
                    onClick={() => {
                      setExtraFieldCopy5({ "Kitchen Type": "Open Shelves" });
                    }}
                  >
                    Open Shelves
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div>
            <h6>Parking</h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Car"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Car" });
                    }}
                  >
                    Car
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Bike"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Bike" });
                    }}
                  >
                    Bike
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Both"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "Both" });
                    }}
                  >
                    Both
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="None"
                    onClick={() => {
                      setExtraFieldCopy6({ Parking: "None" });
                    }}
                  >
                    None 
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </>
      ) : null} */}

      <div
        style={{
          display: "flex",
          marginTop: "5px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>
      <br />
      <input
        className="inpstyle"
        placeholder="location"
        type="text"
        list="state"
        style={{
          padding: "12px",
          width: "100%",
          zIndex: "-50px",
        }}
        name="City"
        onChange={handleChange}
      />
      <datalist id="state">
        {/* Iterate over locationData array */}
        {locationData.map((result) => {
          return <option value={result}>{result}</option>;
        })}
      </datalist>

      <br />

      <div
        className="col-lg-3 col-sm-12"
        style={{ float: "right", marginLeft: "-20px" }}
      >
        {/* Search button */}
        <Link
          to={`/ads-listing/`}
          onClick={() => {
            console.log(searchSending);
            for (const key in searchSending) {
              if (
                searchSending[key]?.trim() === "" ||
                searchSending[key] === null
              ) {
                delete searchSending[key];
              }
            }
            searchSending["extraField"] = {
              ...ExtraFieldCopy,
              ...ExtraFieldCopy1,
              ...ExtraFieldCopy2,
              ...ExtraFieldCopy3,
              ...ExtraFieldCopy4,
              ...ExtraFieldCopy5,
              ...ExtraFieldCopy6,
              ...buildup,
              ...carpet,
            }; // Object Assign to searchSending object

            disptach(getExtraField(searchSending)); // Dispatching getExtraField action
          }}
        >
          <Button varient="outlined" className="">
            Search
          </Button>
        </Link>
      </div>
      <br />
    </div>
  );
};

export default SearchBox;
