import React from 'react';
import { useHistory, useNavigate, useParams } from 'react-router-dom';
// import './CancelFailedPage.css'; // Import your styles
import BiziverseCRM from '../../Dashboard/BiziverseCRM';
import { FaTimesCircle } from 'react-icons/fa';

const CancelFailedPage = () => {
    const history = useNavigate();
    const {id} =useParams();// Initialize useHistory for navigation

    return (
        <>
            <div className="cancel-failed-page">
                <FaTimesCircle className="failure-icon" />
                <h1>Payment Failed </h1>
                <p>Unfortunately, your payment was not successful.</p>
                <p>Please check your payment details and try again.</p>
                <p>{id}</p>

                {/* Animated Stylish Button to Go Back to Home */}
                <button
                    className="animated-button"
                    onClick={() => history('/')} // Navigate to the home page
                >
                    Go to Home
                </button>
            </div>

            <BiziverseCRM />
        </>
    );
};

export default CancelFailedPage;
