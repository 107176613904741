// import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { SearchOutlined } from "@ant-design/icons";
// import SearchBoxPets from "../searchBox/SearchBoxPets";
// import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
// import MyGallery from "./MyGallery";
// import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
// import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
// import { Input, Spin } from "antd";
// import { useDispatch } from "react-redux";
// import { add } from "../../../../store/Track/trackUserSlice";
// import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
// import "../searchBox/SearchBox.css";
// import { Link } from "react-router-dom";
// import "./allfilter.css";
// import { isMobile } from "react-device-detect";
// import PetsSlider from "../../../../Shared/CategorySlider/PetsSlider";
// import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
// import { searchApi } from "../../../../store/ToggleSearchSlice";
// import Allcatads from "../../../Allcatads";
// import PetsMainfilter from "./PetsMainfilter";
// import { BiSearch } from "react-icons/bi";
// import { AiOutlineCloseSquare, AiOutlineFilter } from "react-icons/ai";

// const mobi = {};
// const deski = {
//   marginLeft: "7rem",
//   marginRight: "7rem",
// };

// const mob1 = {
//   width: "95%",
//   marginLeft: "-60px",
// };
// const desk1 = {
//   borderRadius: "20px",
//   backgroundColor: "white",
// };

// const mobstyle = {
//   width: "auto",
//   marginLeft: "16px",
//   marginRight: "16px",
// };
// const deskstyle = {
//   padding: "20px",
//   marginBottom: "-10px",
//   marginRight: "10rem",
//   marginLeft: "10rem",
// };

// const mobileStyle = {
//   width: "80px",
//   height: "80px",
// };
// const desktopStyle = {
//   backgroundSize: "cover",
//   width: "130px",
//   height: "160px",
// };

// const mobilestyle1 = {
//   width: "auto",
//   flexDirection: "row",
// };
// const desktopstyle1 = {
//   width: "70%",
//   flexDirection: "row",
// };

// const mobilestyle2 = {
//   display: "flex",
//   flexDirection: "column",
// };
// const desktopstyle2 = {
//   display: "flex",
//   flexDirection: "column",
//   width: "30%",
// };

// const mobilestyle3 = {
//   display: "flex",
//   flexDirection: "column",
//   margin: "0.5rem",
// };
// const desktopstyle3 = {
//   display: "flex",
//   flexDirection: "row",
// };

// const mobilestyle4 = {
//   fontSize: "12px",
//   textAlign: "center",
//   color: "white",
//   backgroundColor: "gray",
// };
// const desktopstyle4 = {
//   textAlign: "center",
//   color: "white",
//   backgroundColor: "gray",
//   fontSize: "16px",
// };

// const Petscat = () => {
//   const [selectedFilter, setSelectedFilter] = useState(null);
//   // const [selectedFilter1, setSelectedFilter1] = useState(null);
//   const [SearchValue, setSearchValue] = useState("");
//   const [isPopupOpen, setPopupOpen] = useState(false);
//   const searchArray = () => {
//     if (SearchValue?.trim()) {
//       dispatch(searchApi(SearchValue));
//     }
//   };
//   const handleFilterClick = () => {
//     setPopupOpen(true);
//   };
//   const handleClosePopup = () => {
//     setPopupOpen(false);
//   };
//   const handleFilterButtonClick = (filterType) => {
//     setSelectedFilter(filterType);
//   };
//   const greeting = "Pets";

//   const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
//   const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
//   const disptach = useDispatch();
//   const dispatch = useDispatch();
//   // Accessing the Redux dispatch function

//   useEffect(() => {
//     disptach(add({ view: ["Petscat"] })); // Dispatching an action using Redux to add the view to the user's track
//   }, []);

//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [sub, setsub] = useState();

//   const [searchSending, setsearchSending] = useState({
//     subCategoryValue: sub,
//     category: "Pets",
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook

//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setExtraFieldCopy({ [name]: value });
//     // setsearchSending({ ...searchSending, [name]: value });
//     // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   const sliderStyle = {
//     width: "100%",
//     // marginTop:"2cm",
//     // maxWidth: "100vw",
//   };

//   useEffect(() => {
//     // Update searchSending state with the selected filter value
//     if (selectedFilter !== null) {
//       setsearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);


//   // AdsCards logic start

//   const [advClose, setadvClose] = useState(true);
//   useEffect(() => {
//     const isPopupClosed = sessionStorage.getItem('popupClosed');
//     const name = localStorage.getItem('name')
//     if (isPopupClosed && name) {
//       setadvClose(false);
//     }
//   }, []);
//   const handleCloseadv = () => {
//     setadvClose(false);
//     sessionStorage.setItem('popupClosed', 'true');
//   };
//   // AdsCards logic end



  
//   return (
//     <>


//      {/* AdsCards logic start */}
//      {advClose ? (
//         <div
//           style={{
//             position: "fixed",
//             bottom: "2%",
//             right: "2%",
//             width: "350px",
//             height: "170px",
//             zIndex: "3",
//             border: "0.5px solid gray",
//             background:
//               // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
//               'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
//             backgroundSize: "100% 100%",
//             borderRadius: '7px'
//           }}
//         >
//           <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px', fontSize:"18px",  marginRight:'6px' }} onClick={handleCloseadv}>x</span>
//           <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
//             <div style={{ color: 'white' }}>
//               Get the best <br />
//               <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
//             </div>
//             <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
//               Grow Your Business
//             </div>
//             <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
//           </div>
//         </div>
//       ) : null}
//       {/* AdsCards logic end */}



//       <div>
//         <h3
//           style={{
//             backgroundColor: "#082a3b",
//             textAlign: "center",
//             color: "white",
//             padding: "1rem",
//             fontFamily: "verdana",
//           }}
//         >
//           Welcome To Our Pets World
//         </h3>
//         <div style={isMobile ? mobilestyle3 : desktopstyle3}>
//           <div
//             className="container1"
//             style={isMobile ? mobilestyle1 : desktopstyle1}
//           >
//             <div style={{ border: "2px solid gray" }} className="mx-1">
//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["extraField"] = { ...extraFieldCopy };
//                   searchSending["subCategoryValue"] = "Pet Care - Accessories"; // Object Assign to searchSending object
//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <center>
//                   <img
//                     alt="imag"
//                     src="https://img.freepik.com/premium-vector/cute-pet-care-icon-illustration_278222-4771.jpg"
//                     style={isMobile ? mobileStyle : desktopStyle}
//                     className="circleHover"
//                     // height="80px"
//                   />
//                   <h5
//                     className="mt-2 mb-2"
//                     style={isMobile ? mobilestyle4 : desktopstyle4}
//                   >
//                     Pets Care
//                   </h5>
//                 </center>
//               </Link>
//             </div>
//             <div style={{ border: "2px solid gray" }} className="mx-1">
//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["extraField"] = { ...extraFieldCopy };
//                   searchSending["subCategoryValue"] = "Pet Clinics"; // Object Assign to searchSending object
//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <center>
//                   <img
//                     alt="imag"
//                     src="https://static.vecteezy.com/system/resources/previews/009/762/188/non_2x/pet-clinic-icon-isolated-contour-symbol-illustration-vector.jpg"
//                     style={isMobile ? mobileStyle : desktopStyle}
//                     className="circleHover"
//                   />
//                   <h5
//                     className=" mt-2 mb-2"
//                     style={isMobile ? mobilestyle4 : desktopstyle4}
//                   >
//                     Pets Clinic
//                   </h5>
//                 </center>
//               </Link>
//             </div>
//             <div style={{ border: "2px solid gray" }} className="mx-1">
//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["extraField"] = { ...extraFieldCopy };
//                   searchSending["subCategoryValue"] = "Pet Food"; // Object Assign to searchSending object
//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <center>
//                   <img
//                     alt="imag"
//                     src="https://thumbs.dreamstime.com/b/line-art-black-white-full-pet-food-bowl-line-art-black-white-full-pet-food-bowl-cat-dog-care-themed-vector-illustration-111828018.jpg"
//                     style={isMobile ? mobileStyle : desktopStyle}
//                     className="circleHover"

//                     // height="80px"
//                   />
//                   <h5
//                     className=" mt-2 mb-2"
//                     style={isMobile ? mobilestyle4 : desktopstyle4}
//                   >
//                     Pets Food
//                   </h5>
//                 </center>
//               </Link>
//             </div>
//             <div style={{ border: "2px solid gray" }} className="mx-1">
//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["extraField"] = { ...extraFieldCopy };
//                   searchSending["subCategoryValue"] = "Pet Shop"; // Object Assign to searchSending object
//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <center>
//                   <img
//                     alt="imag"
//                     src="https://static.vecteezy.com/system/resources/previews/007/836/685/non_2x/pet-shop-logo-illustration-vector.jpg"
//                     style={isMobile ? mobileStyle : desktopStyle}
//                     className="circleHover"

//                     // height="80px"
//                   />
//                   <h5
//                     className="mt-2 mb-2"
//                     style={isMobile ? mobilestyle4 : desktopstyle4}
//                   >
//                     Pets Shop
//                   </h5>
//                 </center>
//               </Link>
//             </div>
//             <div style={{ border: "2px solid gray" }} className="mx-1">
//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["extraField"] = { ...extraFieldCopy };
//                   searchSending["subCategoryValue"] =
//                     "Pet Training & Grooming​"; // Object Assign to searchSending object
//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <center>
//                   <img
//                     alt="imag"
//                     src="https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX50153865.jpg"
//                     style={isMobile ? mobileStyle : desktopStyle}
//                     className="circleHover"
//                     onMouseOver={() => {
//                       setsub("Pet Training & Grooming​");
//                     }}
//                     // height="80px"
//                   />
//                   <h5
//                     className=" mt-2 mb-2"
//                     style={isMobile ? mobilestyle4 : desktopstyle4}
//                   >
//                     Pets Training
//                   </h5>
//                 </center>
//               </Link>
//             </div>
//           </div>

//           <div
//             className="pets mt-3 mr-2 mb-3"
//             style={isMobile ? mobilestyle2 : desktopstyle2}
//           >
//             <div className="gallery1">
//               <img
//                 src="https://www.dailypaws.com/thmb/B6yWhzGpQZsg3kxMzLn-hvGIF7M=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/siberian-husky-100800827-2000-9449ca147e0e4b819bce5189c2411188.jpg"
//                 alt="a lovely kiss in the night"
//               />
//               <img
//                 src="https://cdn.pixabay.com/photo/2021/01/21/16/17/english-cocker-spaniel-5937757_1280.jpg"
//                 alt="a women inside a car"
//               />
//               <img
//                 src="https://cdn.pixabay.com/photo/2016/02/19/15/46/labrador-retriever-1210559_1280.jpg"
//                 alt="a baby"
//               />
//               <img
//                 src="https://t3.ftcdn.net/jpg/00/33/76/64/360_F_33766426_7IWdoKFDhIDVFsaxC43zKH0LhyN5z8Kw.jpg"
//                 alt="a baby"
//               />
//               <img
//                 src="https://media.istockphoto.com/id/1196201462/photo/shih-tzu.jpg?s=612x612&w=0&k=20&c=LGQzq2Y5ZPVD9EzXEV9bh1So6G05vA585sNzbO41HcY="
//                 alt="a baby"
//               />
//               <img
//                 src="https://d.newsweek.com/en/full/2195249/german-shepherd-eating-ice-cream.jpg?w=1600&h=1200&q=88&f=d3c379273d7472d31cc9e5f336a80a12"
//                 alt="a baby"
//               />
//             </div>
//             <div className="gallery1">
//               <img
//                 src="https://cdn.pixabay.com/photo/2016/02/10/16/37/cat-1192026_640.jpg"
//                 alt="a lovely kiss in the night"
//               />
//               <img
//                 src="https://cdn.pixabay.com/photo/2016/02/10/16/37/cat-1192026_640.jpg"
//                 alt="a women inside a car"
//               />
//               <img
//                 src="https://images.all-free-download.com/images/graphiclarge/cat_cute_cat_cat_face_271618.jpg"
//                 alt="a baby"
//               />
//               <img
//                 src="https://www.petsworld.in/blog/wp-content/uploads/2014/09/lovely-white-cat-image.jpg"
//                 alt="a baby"
//               />
//               <img
//                 src="https://cdn.wamiz.fr/cdn-cgi/image/format=auto,quality=80,width=776,fit=contain/article/images/UK%20Image/CAT/tabby-maine-coon-cat.jpg"
//                 alt="a baby"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="filetr24 mt-4">
//           <input
//             required
//             className="custom-select2 rounded-lg"
//             style={{
//               padding: "20px",
//               // position: "relative",
//               height: "47px",
//               width: "50%",
//               // zIndex: "2",
//               borderTopLeftRadius: "10px",
//               borderBottomLeftRadius: "10px",
//               border: "solid gray 2px",
//               textTransform: "uppercase",
//               // border: "2px solid whiteSmoke",
//             }}
//             placeholder="Search Here Pets,PetFood"
//             onChange={(e) => setSearchValue(e.target.value.trim())}
//             // dropdownClassName="select-dropdown"
//           />
//           <Link to={`/ads-listing1/`} onClick={searchArray}>
//             <button
//               style={{
//                 color: "white",
//                 backgroundColor: "#09213b",
//                 fontSize: "30px",
//                 height: "47px",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: "100px",
//                 borderTopRightRadius: "4px",
//                 borderBottomRightRadius: "4px",
//               }}
//             >
//               <SearchOutlined />
//             </button>
//           </Link>
//         </div>
//       </div>

//       <div>
//         <div className="filmain">
//           <div className="fildi">
//             <input
//               name="Brand Name"
//               required
//               className=""
//               style={{
//                 textTransform: "uppercase",
//                 border: "none",
//               }}
//               placeholder="Search For Pets"
//               onChange={(e) => setSearchValue(e.target.value.trim())}
//             />
//             <div className="tildiv">
//               <Link to={`/ads-listing1/`} onClick={searchArray}>
//                 <button className="search-btn">
//                   <BiSearch style={{ fontSize: "20px" }} />
//                   Search
//                 </button>
//               </Link>
//               &nbsp; &nbsp;
//               <button className="filter-btn" onClick={handleFilterClick}>
//                 <AiOutlineFilter style={{ fontSize: "20px" }} />
//                 Filter
//               </button>
//             </div>
//           </div>
//         </div>

//         {isPopupOpen && (
//           <div className="fildi2" style={isMobile ? mob1 : desk1}>
//             <div>
//               <button onClick={handleClosePopup} style={{ float: "right" }}>
//                 <AiOutlineCloseSquare
//                   style={{ color: "red", fontSize: "20px" }}
//                 />
//               </button>
//               <SearchBoxPets />
//             </div>
//           </div>
//         )}
//       </div>

//       {/*  <PetsMainfilter/>*/}

//       <div className="wholefil mt-0">
//         {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
//           <div>
//             <h5>
//               {" "}
//               <div className="example">
//                 <Spin />
//               </div>{" "}
//             </h5>{" "}
//           </div>
//         )}
//         {!loading && (
//           <>
//             <div className="mt-4" style={isMobile ? mobstyle : deskstyle}>
//               <Feature value="Pets" />{" "}
//               {/* Rendering the 'Feature' component with value "RealEstate" */}
//               <Allcatads greeting={greeting} subcategory={"PetFood"}  onClick={handleFilterClick}
//               />
//               {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
//             </div>
//             <div style={isMobile ? mobi : deski}>
//               <TrendingAds />
//             </div>
//           </>
//         )}
//         <NewsArticles category={"Bikes"} />{" "}
//         {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
//         <MyGallery selectedCategory="Pets" />
//       </div>
//       {/* <NewsArticles category={"RealEstate"}/> */}
//     </>
//   );
// };

// export default Petscat;








import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SearchOutlined } from "@ant-design/icons";
import SearchBoxPets from "../searchBox/SearchBoxPets";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import "../searchBox/SearchBox.css";
import { Link } from "react-router-dom";
import "./allfilter.css";
import { isMobile } from "react-device-detect";
import PetsSlider from "../../../../Shared/CategorySlider/PetsSlider";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import Allcatads from "../../../Allcatads";
import PetsMainfilter from "./PetsMainfilter";
import { BiSearch } from "react-icons/bi";
import { AiOutlineCloseSquare, AiOutlineFilter } from "react-icons/ai";
import './PetsCat21.css'



const mobi = {};
const deski = {
  marginLeft: "7rem",
  marginRight: "7rem",
};

const mob1 = {
  width: "95%",
  marginLeft: "-60px",
};
const desk1 = {
  borderRadius: "20px",
  backgroundColor: "white",
};

const mobstyle = {
  width: "auto",
  marginLeft: "16px",
  marginRight: "16px",
};
const deskstyle = {
  padding: "20px",
  marginBottom: "-10px",
  marginRight: "10rem",
  marginLeft: "10rem",
};

const mobileStyle = {
  width: "80px",
  height: "80px",
};
const desktopStyle = {
  backgroundSize: "cover",
  width: "130px",
  height: "160px",
};

const mobilestyle1 = {
  width: "auto",
  flexDirection: "row",
};
const desktopstyle1 = {
  width: "70%",
  flexDirection: "row",
};

const mobilestyle2 = {
  display: "flex",
  flexDirection: "column",
};
const desktopstyle2 = {
  display: "flex",
  flexDirection: "column",
  width: "30%",
};

const mobilestyle3 = {
  display: "flex",
  flexDirection: "column",
  margin: "0.5rem",
};
const desktopstyle3 = {
  display: "flex",
  flexDirection: "row",
};

const mobilestyle4 = {
  fontSize: "12px",
  textAlign: "center",
  color: "white",
  backgroundColor: "gray",
  height:"50px",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
};
const desktopstyle4 = {
  textAlign: "center",
  color: "white",
  backgroundColor: "gray",
  fontSize: "16px",
  height:"50px",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
};

const Petscat = () => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  // const [selectedFilter1, setSelectedFilter1] = useState(null);
  const [SearchValue, setSearchValue] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };
  const handleFilterClick = () => {
    setPopupOpen(true);
  };
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const handleFilterButtonClick = (filterType) => {
    setSelectedFilter(filterType);
  };
  const greeting = "Pets";

  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch();
  const dispatch = useDispatch();
  // Accessing the Redux dispatch function

  useEffect(() => {
    disptach(add({ view: ["Petscat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);

  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [sub, setsub] = useState();

  const [searchSending, setsearchSending] = useState({
    subCategoryValue: sub,
    category: "Pets",
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  const sliderStyle = {
    width: "100%",
    // marginTop:"2cm",
    // maxWidth: "100vw",
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);


  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);
  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end



  
  return (
    <>


     {/* AdsCards logic start */}
     {advClose ? (
        <div
          style={{
            position: "fixed",
            bottom: "2%",
            right: "2%",
            width: "350px",
            height: "170px",
            zIndex: "3",
            border: "0.5px solid gray",
            background:
              // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
              'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
            backgroundSize: "100% 100%",
            borderRadius: '7px'
          }}
        >
          <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px', fontSize:"18px",  marginRight:'6px' }} onClick={handleCloseadv}>x</span>
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
            <div style={{ color: 'white' }}>
              Get the best <br />
              <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
            </div>
            <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
              Grow Your Business
            </div>
            <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
          </div>
        </div>
      ) : null}
      {/* AdsCards logic end */}



      <div  className="Pets-cat-main-24">
        <h3
          style={{
            backgroundColor: "#082a3b",
            textAlign: "center",
            color: "white",
            padding: "1rem",
            fontFamily: "verdana",
          }}
        >
          Welcome To Our Pets World
        </h3>
        <div className="Pets-cat-main-resp-awi-24">
          <div className="Pets-cat-submain-aqwi-24">
          <div
            className="container1 Pets-cat-main-resp-cont-1-awi-24">
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pet Care - Accessories"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://img.freepik.com/premium-vector/cute-pet-care-icon-illustration_278222-4771.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                    // height="80px"
                  />
                  <h5
                    className="mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Care
                  </h5>
                </center>
              </Link>
            </div>
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pet Clinics"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://static.vecteezy.com/system/resources/previews/009/762/188/non_2x/pet-clinic-icon-isolated-contour-symbol-illustration-vector.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <h5
                    className=" mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Clinic
                  </h5>
                </center>
              </Link>
            </div>
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pet Food"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://thumbs.dreamstime.com/b/line-art-black-white-full-pet-food-bowl-line-art-black-white-full-pet-food-bowl-cat-dog-care-themed-vector-illustration-111828018.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"

                    // height="80px"
                  />
                  <h5
                    className=" mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Food
                  </h5>
                </center>
              </Link>
            </div>
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pet Shop"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://static.vecteezy.com/system/resources/previews/007/836/685/non_2x/pet-shop-logo-illustration-vector.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"

                    // height="80px"
                  />
                  <h5
                    className="mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Shop
                  </h5>
                </center>
              </Link>
            </div>
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] =
                    "Pet Training & Grooming​"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX50153865.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                    onMouseOver={() => {
                      setsub("Pet Training & Grooming​");
                    }}
                    // height="80px"
                  />
                  <h5
                    className=" mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Training
                  </h5>
                </center>
              </Link>
            </div>
          </div>
          <div className="filetr24 filtering-aqwi" >
          <input
            required
            className="custom-select2 rounded-lg"
            style={{
              padding: "20px",
              // position: "relative",
              height: "47px",
              width: "50%",
              // zIndex: "2",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              border: "solid gray 2px",
              textTransform: "uppercase",
              // border: "2px solid whiteSmoke",
            }}
            placeholder="Search Here Pets,PetFood"
            onChange={(e) => setSearchValue(e.target.value.trim())}
            // dropdownClassName="select-dropdown"
          />
          <Link to={`/ads-listing1/`} onClick={searchArray}>
            <button
              style={{
                color: "white",
                backgroundColor: "#09213b",
                fontSize: "30px",
                height: "47px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <SearchOutlined />
            </button>
          </Link>
        </div>
          </div>
          {/* <div
            className="container1 Pets-cat-main-resp-cont-1-awi-24">
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pet Care - Accessories"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://img.freepik.com/premium-vector/cute-pet-care-icon-illustration_278222-4771.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                    // height="80px"
                  />
                  <h5
                    className="mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Care
                  </h5>
                </center>
              </Link>
            </div>
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pet Clinics"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://static.vecteezy.com/system/resources/previews/009/762/188/non_2x/pet-clinic-icon-isolated-contour-symbol-illustration-vector.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <h5
                    className=" mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Clinic
                  </h5>
                </center>
              </Link>
            </div>
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pet Food"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://thumbs.dreamstime.com/b/line-art-black-white-full-pet-food-bowl-line-art-black-white-full-pet-food-bowl-cat-dog-care-themed-vector-illustration-111828018.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"

                    // height="80px"
                  />
                  <h5
                    className=" mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Food
                  </h5>
                </center>
              </Link>
            </div>
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pet Shop"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://static.vecteezy.com/system/resources/previews/007/836/685/non_2x/pet-shop-logo-illustration-vector.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"

                    // height="80px"
                  />
                  <h5
                    className="mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Shop
                  </h5>
                </center>
              </Link>
            </div>
            <div style={{ border: "2px solid gray" }} className="mx-1">
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] =
                    "Pet Training & Grooming​"; // Object Assign to searchSending object
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <center>
                  <img
                    alt="imag"
                    src="https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX50153865.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                    onMouseOver={() => {
                      setsub("Pet Training & Grooming​");
                    }}
                    // height="80px"
                  />
                  <h5
                    className=" mt-2 mb-2"
                    style={isMobile ? mobilestyle4 : desktopstyle4}
                  >
                    Pets Training
                  </h5>
                </center>
              </Link>
            </div>
          </div> */}

          <div
            className="pets Pets-cat-main-resp-cont-2-awi-24 mt-3 mr-2 mb-3" >
            <div className="gallery1">
              <img
                src="https://www.dailypaws.com/thmb/B6yWhzGpQZsg3kxMzLn-hvGIF7M=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/siberian-husky-100800827-2000-9449ca147e0e4b819bce5189c2411188.jpg"
                alt="a lovely kiss in the night"
              />
              <img
                src="https://cdn.pixabay.com/photo/2021/01/21/16/17/english-cocker-spaniel-5937757_1280.jpg"
                alt="a women inside a car"
              />
              <img
                src="https://cdn.pixabay.com/photo/2016/02/19/15/46/labrador-retriever-1210559_1280.jpg"
                alt="a baby"
              />
              <img
                src="https://t3.ftcdn.net/jpg/00/33/76/64/360_F_33766426_7IWdoKFDhIDVFsaxC43zKH0LhyN5z8Kw.jpg"
                alt="a baby"
              />
              <img
                src="https://media.istockphoto.com/id/1196201462/photo/shih-tzu.jpg?s=612x612&w=0&k=20&c=LGQzq2Y5ZPVD9EzXEV9bh1So6G05vA585sNzbO41HcY="
                alt="a baby"
              />
              <img
                src="https://d.newsweek.com/en/full/2195249/german-shepherd-eating-ice-cream.jpg?w=1600&h=1200&q=88&f=d3c379273d7472d31cc9e5f336a80a12"
                alt="a baby"
              />
            </div>
            <div className="gallery1">
              <img
                src="https://cdn.pixabay.com/photo/2016/02/10/16/37/cat-1192026_640.jpg"
                alt="a lovely kiss in the night"
              />
              <img
                src="https://cdn.pixabay.com/photo/2016/02/10/16/37/cat-1192026_640.jpg"
                alt="a women inside a car"
              />
              <img
                src="https://images.all-free-download.com/images/graphiclarge/cat_cute_cat_cat_face_271618.jpg"
                alt="a baby"
              />
              <img
                src="https://www.petsworld.in/blog/wp-content/uploads/2014/09/lovely-white-cat-image.jpg"
                alt="a baby"
              />
              <img
                src="https://cdn.wamiz.fr/cdn-cgi/image/format=auto,quality=80,width=776,fit=contain/article/images/UK%20Image/CAT/tabby-maine-coon-cat.jpg"
                alt="a baby"
              />
            </div>
          </div>
        </div>










        {/* <div className="filetr24 mt-4">
          <input
            required
            className="custom-select2 rounded-lg"
            style={{
              padding: "20px",
              // position: "relative",
              height: "47px",
              width: "50%",
              // zIndex: "2",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              border: "solid gray 2px",
              textTransform: "uppercase",
              // border: "2px solid whiteSmoke",
            }}
            placeholder="Search Here Pets,PetFood"
            onChange={(e) => setSearchValue(e.target.value.trim())}
            // dropdownClassName="select-dropdown"
          />
          <Link to={`/ads-listing1/`} onClick={searchArray}>
            <button
              style={{
                color: "white",
                backgroundColor: "#09213b",
                fontSize: "30px",
                height: "47px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <SearchOutlined />
            </button>
          </Link>
        </div> */}
      </div>






      <div>
        <div className="filmain">
          <div className="fildi">
            <input
              name="Brand Name"
              required
              className=""
              style={{
                textTransform: "uppercase",
                border: "none",
              }}
              placeholder="Search For Pets"
              onChange={(e) => setSearchValue(e.target.value.trim())}
            />
            <div className="tildiv">
              <Link to={`/ads-listing1/`} onClick={searchArray}>
                <button className="search-btn">
                  <BiSearch style={{ fontSize: "20px" }} />
                  Search
                </button>
              </Link>
              &nbsp; &nbsp;
              <button className="filter-btn" onClick={handleFilterClick}>
                <AiOutlineFilter style={{ fontSize: "20px" }} />
                Filter
              </button>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="fildi2" style={isMobile ? mob1 : desk1}>
            <div>
              <button onClick={handleClosePopup} style={{ float: "right" }}>
                <AiOutlineCloseSquare
                  style={{ color: "red", fontSize: "20px" }}
                />
              </button>
              <SearchBoxPets />
            </div>
          </div>
        )}
      </div>

      {/*  <PetsMainfilter/>*/}

      <div className="wholefil mt-0">
        {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
          <div>
            <h5>
              {" "}
              <div className="example">
                <Spin />
              </div>{" "}
            </h5>{" "}
          </div>
        )}
        {!loading && (
          <>
            <div className="mt-4" style={isMobile ? mobstyle : deskstyle}>
              <Feature value="Pets" />{" "}
              {/* Rendering the 'Feature' component with value "RealEstate" */}
              <Allcatads greeting={greeting} subcategory={"PetFood"}  onClick={handleFilterClick}
              />
              {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
            </div>
            <div style={isMobile ? mobi : deski}>
              <TrendingAds />
            </div>
          </>
        )}
        <NewsArticles category={"Bikes"} />{" "}
        {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
        <MyGallery selectedCategory="Pets" />
      </div>
      {/* <NewsArticles category={"RealEstate"}/> */}
    </>
  );
};

export default Petscat;
