import React, { useState, useEffect } from 'react';
import "./Adminverify.css"
import YoutubeMagic from '../ContentLoader/YoutubeMagic';
import { FaPhone, FaRegUserCircle } from 'react-icons/fa';
import { MdAlternateEmail } from 'react-icons/md';

const JobDetails = () => {

    const [businessData, setBusinessData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);
    const [itemsToLoad, setItemsToLoad] = useState(25);
    const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
  useEffect(() => {
    // Fetch data from the API
    fetch('https://databytess.com/api/user/jobDetails')
    .then(response => response.json())
      .then(data => {
        setBusinessData(data);
        setIsLoading(false); // Set loading to false once data is fetched
        setIsLoading1(false); // Set loading to false once data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false in case of an error
        setIsLoading1(false); // Set loading to false in case of an error
      });
  }, [itemsToLoad]);
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 25);
    setLimitIncreaseCount((prevCount) => prevCount + 1);

  };
  const [prevDataLength, setPrevDataLength] = useState(0);
  const [cardCount, setCardCount] = useState(20);

  const loadMoreCards = () => {
    // Increment the card count by 30.
    setCardCount(cardCount + 20);
      setLimitIncreaseCount(0);

  };
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading && limitIncreaseCount != 3) {
        const newDataLength = businessData.length;
  
        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, businessData, prevDataLength,limitIncreaseCount]);

  return (
    <>
    <div className="business-profile-container">
      {isLoading ? (
        <p><YoutubeMagic/></p>
      ) : (
        businessData.slice(0,cardCount).map((data) => (
          <>
          <BusinessProfileItem key={data.id} data={data} load={isLoading1} />
          </>
        ))
      )}

    </div>
    {cardCount > businessData.length && (
      <>
      {isLoading1 && <p className="loading-message"> <YoutubeMagic/> </p>}
      </>
      )}
      {cardCount < businessData.length && (
                    <div
                      className="loadbt text-center"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      <button className="loadmore" onClick={loadMoreCards}>
                        Load More
                      </button>
                    </div>
                  )}
</>
  );
};

const BusinessProfileItem = ({ data }) => {
  const [isVerified, setIsVerified] = useState(data.is_verified);
  const [isDeleted, setIsDeleted] = useState(false);


    return (
        <>
            <div className={`business-profile-card ${isDeleted ? 'deleted' : ''}`} style={{ border: isVerified ? "1px solid green" : "1px solid red", width:'300px', minHeight:'150px', padding:'10px'}}>
                <i style={{background:'whitesmoke', padding:'0 4px', borderRadius:'50%', fontSize:'70%', float:'right', margin:'0 3px'}}>{data.id}</i>
                        <div style={{display:'flex', flexDirection:'column'}}>
                        <i> <FaRegUserCircle /> &nbsp; : &nbsp; {data.name}</i>
                        <i> <FaPhone /> &nbsp; : &nbsp; {data.phone}</i>
                        <i><MdAlternateEmail /> &nbsp; : &nbsp; {data.email}</i>
                        <i><span style={{color:'black'}}>Title : </span> {data.title}</i>
                        <i><span style={{color:'black'}}>Introduction : </span> {data.Introduction}</i>
                        {/* <p>File Name: {data.filename}</p> */}
                        <i><span style={{color:'black'}}>Created at : </span> {data.created_at}</i>
                        </div>
            </div>
        </>
    );
};

export default JobDetails;