import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { localUrl } from "../env";
import { useDispatch } from "react-redux";
import { add } from "../../store/Track/trackUserSlice";

const UpdateBlog = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [user, setUser] = useState("");
  const [category, setCategory] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    // Load the blog data when the component mounts
    const loadBlogs = async () => {
      try {
        const response = await axios.get(localUrl + `blogsapi/${id}/`);
        const data = response.data;
        setImage1(data.image);
        setTitle(data.title);
        setSubtitle(data.subtitle);
        setDescription(data.description);
        setState(data.state);
        setCity(data.city);
        setUser(data.user);
        setCategory(data.category);
      } catch (error) {
        console.error("Error loading blog data:", error);
      }
    };

    loadBlogs();
  }, [id]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // formData.append("image", image);
    formData.append("title", title);
    formData.append("subtitle", subtitle);
    formData.append("description", description);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("user", user);
    formData.append("category", category);

    try {
      const response = await axios.put(localUrl + `blogsapi/${id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Blog updated successfully:", response);
      alert("Edit successfully")
      navigate("/"); // Navigate to the home page after updating
    } catch (error) {
      console.error("Error updating blog:", error);
      alert("Edit Failed")
    }
  };

  useEffect(() => {
    dispatch(add({ view: ["UpdateBlog"] }));
  }, [dispatch]);

  document.title = "Hola9 - UpdateBlog";

  return (
    <div className="container">
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Update Blog</h2>
        <form onSubmit={submitHandler} encType="multipart/form-data">
          <div className="form-group">
            <img src={image1} alt="Blog" height="100px" width="300px" />
         
          </div>

          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              placeholder="Enter title here"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Subtitle</label>
            <input
              type="text"
              name="subtitle"
              className="form-control"
              placeholder="Enter subtitle here"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              required
              name="description"
              className="form-control"
              placeholder="Enter description here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>State</label>
            <input
              type="text"
              className="form-control"
              name="state"
              placeholder="Enter state here"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              className="form-control"
              name="city"
              placeholder="Enter city here"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Category</label>
            <input
              type="text"
              className="form-control"
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>User</label>
            <input
              type="text"
              name="user"
              className="form-control"
              placeholder="Enter user here"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </div>

          <button className="btn btn-primary btn-block" type="submit">
            Update Blog
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateBlog;
