import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { BsHeartFill } from "react-icons/bs";
import "./trending.css";
import {
  BsFillTagFill,
  BsFillBookmarkFill,
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper, BsCoin, BsFire
} from "react-icons/bs";
import "./trending.css";
import { RiFireLine } from "react-icons/ri"
import DynamicFont from "react-dynamic-font";
import { Tooltip } from "antd";
import { url } from "../../env";
import { UserContext } from "../../../App";
import { useInterval } from "react-interval-hook";
import { useDispatch, useSelector } from "react-redux";
import { add, remove } from "../../../store/wishlistSlice";
import Distance from "../../distance";
import YoutubeMagic from "../../ContentLoader/YoutubeMagic";
import { SliderContainer } from "../../../Utils/SliderContainer";
import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";

const mobstyle = {
  width: "auto",

}
const deskstyle = {
  backgroundColor: "transparent"
}
var settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 8,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

const TrendingAds = (props) => {
  const [products, setProduct] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  const location = localStorage.getItem("selectedCity")
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url + "api/adsapi/trendingads", {
          method: "POST",
          body: JSON.stringify({ city: location }), // ---------------  get data based on the city ------------- //
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location]); // Empty dependency array ensures useEffect runs once after mount

  if (loading) {
    return <YoutubeMagic/>;
  }

  const postAdCardStyle = {
    width: '120px',
    height: '200px',
    display: 'flex',
    border: "1px black solid",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  };

  // CSS styles for the product media container (background)
  const postAdMediaStyle = {
    width: '120px',
    height: '200px',
    borderRadius: '10px',
    // filter: "brightness(90%)",
    // Background color of the card
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  // CSS styles for the "Post Ads" heading
  const headingStyle = {
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0',
    padding: '0',
  };

  // CSS styles for the plus icon container
  const plusIconStyle = {
    width: '60px',
    height: '60px',
    backgroundColor: '#ff5733', // Background color of the plus icon container
    borderRadius: '50%',
    display: 'flex',
    border: "2px solid white",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
    marginLeft: "7px"
  };

  // CSS styles for the plus icon
  const plusIconInnerStyle = {
    color: 'white',
    fontSize: '30px',
    fontWeight: 'bold',
  };

  return (
    <>
      {/* <div style={{display: "flex", flexDirection: "row"}}> */}
      <div>

        {products.length ? (
          <div>
            <div className="d-flex justify-content-between mb-2">
              {" "}
              <h2
                className="trending-ads-heading"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#007BFF",
                  textTransform: "capitalize",
                  letterSpacing: "1px",
                  textAlign: "center",
                  background: "linear-gradient(to right, #007BFF, #00BFFF)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4))",
                  transition: "transform 0.3s ease-in-out",
                  cursor: "pointer",
                }}
              >
                <RiFireLine style={{ fontSize: "20px" }} />Trending Ads
              </h2>

              <Link
                to={"/trending-ads/"}
                className="mt-3"
                style={{ marginRight: "20px" }}
              >
                <b> View More</b>
              </Link>

            </div>
            {/* {products.length===0 && <YoutubeMagic1/>} */}
            <div className="row">
              <SliderContainer>
                <Slider {...settings}>
                  <div className="trending2001">
                    <NavLink to="/allcatforpost/">
                      <div className="product-card" style={postAdCardStyle}>
                        <div className="product-media" id="tredpost" style={postAdMediaStyle}>
                          <div className="post-ad-card">
                            <h2 style={headingStyle}>Post Ads</h2>
                            <div style={plusIconStyle}>
                              <span style={plusIconInnerStyle}>+</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  {products
                    .sort((a, b) => b.viewsproduct - a.viewsproduct)
                    .map((product, index) =>
                      product.fields.is_active === false && product.fields?.expiry === false ? (
                        <div className="trending2002" key={index}>
                          <NavLink to={`/ads-listing/${product?.pk}/`}>
                            <div>
                              <div className="product-media" style={{ borderRadius: "10px" }}>
                                <div
                                  className="product-img" style={{ borderRadius: "10px", height: "200px", width: "120px" }}
                                >
                                  <img className="imgtred"
                                    src={
                                      !product?.fields?.image
                                        ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                        : product?.fields?.image
                                    }
                                    alt="Product Iage"
                                    style={{ height: "200px", width: "140px", filter: "brightness(90%)" }}
                                    onError={(e) => {
                                      e.target.src =
                                        "https://images.unsplash.com/photo-1509937528035-ad76254b0356?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTg0fHxwcm9kdWN0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60";
                                      e.onerror = null;
                                    }}
                                  />
                                </div>
                                <div className="product-type">
                                  <span className="flat-badge booking" >
                                    <b>
                                      <span
                                        className="flat-badge booking "
                                        style={{
                                          background:
                                            product?.fields?.plan === "Silver"
                                              ? "#cce6ff"
                                              : product?.fields?.plan === "Gold"
                                                ? "gold"
                                                : product?.fields?.plan === "Platinum"
                                                  ? "green"
                                                  : product?.fields?.plan === "Premium"
                                                    ? "linear-gradient(to right, #800080, #8A2BE2)"
                                                    : product?.fields?.plan === "Featured"
                                                      ? "linear-gradient(to right, #090979, #00d4ff)"
                                                      : // : ad?.fields?.plan === "Free"
                                                      // ? "green"
                                                      "transparent",
                                          color:
                                            product?.fields?.plan === "Silver"
                                              ? "blue"
                                              : product?.fields?.plan === "Gold"
                                                ? "black"
                                                : product?.fields?.plan === "Platinum"
                                                  ? "white"
                                                  : product?.fields?.plan === "Premium"
                                                    ? "white"
                                                    : product?.fields?.plan === "Featured"
                                                      ? "white"
                                                      // : product?.fields?.plan === "Free"
                                                      // ? "white"
                                                      : "transparent",
                                          padding: "5px",
                                        }}
                                      >
                                        {product?.fields?.plan}
                                      </span>
                                    </b>
                                  </span>
                                  <span style={{ color: "white", float: "right" }} ><RiFireLine className="tag-rifireline" style={{ color: "orange", fontSize: "25px" }} /></span>

                                </div>
                                <ul className="onimage">
                                  <li className="tredview">
                                    <i
                                      className="fas fa-eye"
                                      style={{ color: "white" }}
                                    />
                                    <span style={{ color: "white" }}>
                                      {product?.fields?.viewsproduct}
                                    </span>
                                  </li>
                                  <span style={{ color: "white", fontFamily: "sans-serif", textTransform: "capitalize", fontSize: "80%", marginLeft: "5px" }}>
                                    {product?.fields?.title.slice(0, 10)}
                                  </span>
                                </ul>
                              </div>

                            </div>
                          </NavLink>
                        </div>
                      ) : null
                    )}
                </Slider>
              </SliderContainer>
            </div>
            {/* </div> */}
          </div>
        ) : null}
        {/* {isloading ? <YoutubeMagic /> : null} */}
      </div>
      {/* <div>
     <><center>
     <div className="advertisement-banner ml-5">
     <div className="logo-container">
       <img src={logo} alt="Your Logo" className="logo1" />
     </div>
     <div>
       <h1 className="banheading" >Special Offer...!</h1>
       <h6 className="bancontent">Buy Plans To Get Genuine Services</h6>
     </div>
     <div className="button-container">
       <Link className="buttonbuy text-white" to="/pricing/">Buy Now</Link>
     </div>
   </div>
     </center>
     </>
      </div> */}
      {/* </div> */}
    </>
  );
};

export default TrendingAds;
