import React, { useState,useEffect } from 'react';
import { RiHeart2Line, RiImageLine, RiUser3Line, RiUserFollowLine, RiStarLine } from 'react-icons/ri';
import {BsPlusCircle,BsCart4,BsHeart, BsFillBookmarksFill} from "react-icons/bs"
import {GiReceiveMoney} from "react-icons/gi"
import {BiHome,BiSearchAlt2,BiTrim} from "react-icons/bi"
import './mobilenav.css'; // Import the CSS file if it's in the same directory
import { Link } from 'react-router-dom';
import {  useSelector } from "react-redux";
import { decrypt } from '../encryptDecrypt/encryptDecrypt';
import SearchWithCat from '../Search/searchWithCat/SearchWithCat';
// import user from "https://images.pexels.com/photos/3756985/pexels-photo-3756985.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";


const MyComponent = () => {
  const [mainSliderAnimation, setMainSliderAnimation] = useState(false);
  const [activeMainTab, setActiveMainTab] = useState('red');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const colors = {
    red: { 50: '#ffebee', 100: '#ffcdd2' },
    blue: { 50: '#e3f2fd', 100: '#bbdefb' },
    green: { 50: '#e8f5e9', 100: '#c8e6c9' },
    purple: { 50: '#f3e5f5', 100: '#e1bee7' },
    orange: { 50: '#ffe0b2', 100: '#ffe0b2' },
  };
  const userProfileDetails = useSelector((state) => state.userProfileData);


  const handleMainTabClick = (event, color, translateValue) => {
    setMainSliderAnimation(true);
    setActiveMainTab(color);
    document.documentElement.style.setProperty('--translate-main-slider', translateValue);
    document.documentElement.style.setProperty('--main-slider-color', colors[color][50]);
    document.documentElement.style.setProperty('--background-color', colors[color][100]);

    if (!filtersVisible && color === 'blue') {
      document.documentElement.style.setProperty('--filters-container-height', '3.8rem');
      document.documentElement.style.setProperty('--filters-wrapper-opacity', '1');
    } else {
      document.documentElement.style.setProperty('--filters-container-height', '0');
      document.documentElement.style.setProperty('--filters-wrapper-opacity', '0');
    }

    setTimeout(() => setMainSliderAnimation(false), 300);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      let result = decrypt("userdata");
      setName(result.name);
      setEmail(result.email);
    }
  }, []);

  const wishlistData = useSelector((state) => state.wishlistSlice);

  const [wishlistCount, setWishlistCount] = useState(0);

  useEffect(() => {
  
    if (wishlistData?.data) {
      setWishlistCount(wishlistData.data.length);
    } else {
      setWishlistCount(0);
    }
  }, [wishlistData]);

  return (
    <nav className="amazing-tabs">
    {localStorage.getItem("access_token") == null ? (
      <div className="main-tabs-wrapper">
        
           <ul className="main-tabs">
           
            <li>
              <button id='buttonnav' className={`round-button gallery ${activeMainTab === 'blue' ? 'active' : ''}`} style={{ '--round-button-active-color': '#2962ff' }} data-translate-value="100%" data-color="blue" onClick={(e) => handleMainTabClick(e, 'blue', '100%')}>
                <Link to="/login"><BiHome style={{height:"30px"}}/></Link>
              </button>
            </li>
            <li>
              <button id='buttonnav' className={`round-button ${activeMainTab === 'green' ? 'active' : ''}`} style={{ '--round-button-active-color': '#00c853' }} data-translate-value="200%" data-color="green" onClick={(e) => handleMainTabClick(e, 'green', '200%')}>
              <Link to="/login"><BiSearchAlt2 style={{height:"30px"}} /></Link>
              </button>
            </li>
            <li>
              <button id='buttonnav' className={`round-button ${activeMainTab === 'purple' ? 'active' : ''}`} style={{ '--round-button-active-color': '#aa00ff' }} data-translate-value="300%" data-color="purple" onClick={(e) => handleMainTabClick(e, 'purple', '300%')}>
              <Link to="/login"><BsPlusCircle style={{height:"30px"}}/></Link>
              </button>
            </li>
            <li>
              <button id='buttonnav' className={`round-button ${activeMainTab === 'orange' ? 'active' : ''}`} style={{ '--round-button-active-color': '#ff6d00' }} data-translate-value="400%" data-color="orange" onClick={(e) => handleMainTabClick(e, 'orange', '400%')}>
              <Link to="/login"><BsFillBookmarksFill className="cart" style={{height:"30px"}}/></Link>
              </button>
            </li>
            <li>
              <button id='buttonnav' className="round-button" data-translate-value="0" data-color="red" onClick={(e) => handleMainTabClick(e, 'red', '0')}>
                <Link to="/login">
                <span className="avatarr">
                <img src="https://images.pexels.com/photos/3756985/pexels-photo-3756985.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="user avatar" />
                </span>
               </Link>
              </button>
            </li>
          </ul>
      </div>
       ):( 
        <div className="main-tabs-wrapper">
        
           <ul className="main-tabs">
            <li>
              <button id='buttonnav' className={`round-button gallery ${activeMainTab === 'blue' ? 'active' : ''}`} style={{ '--round-button-active-color': '#2962ff' }} data-translate-value="100%" data-color="blue" onClick={(e) => handleMainTabClick(e, 'blue', '100%')}>
                <Link to="/"><BiHome style={{height:"30px"}} /></Link>
              </button>
            </li>
            <li>
              <button id='buttonnav' className={`round-button ${activeMainTab === 'purple' ? 'active' : ''}`} style={{ '--round-button-active-color': '#aa00ff' }} data-translate-value="300%" data-color="purple" onClick={(e) => handleMainTabClick(e, 'purple', '300%')}>
              <a href="#popup1"><BiSearchAlt2 style={{height:"30px"}} /></a>
              </button>
            </li>
            <div id="popup1" className="overlay mt-5">
        <SearchWithCat style={{height:"30px"}} />
      </div>
            
            
            <li>
              <button id='buttonnav' className={`round-button ${activeMainTab === 'green' ? 'active' : ''}`} style={{ '--round-button-active-color': '#00c853' }} data-translate-value="200%" data-color="green" onClick={(e) => handleMainTabClick(e, 'green', '200%')}>
              <Link to="/allcatforpost/"><BsPlusCircle style={{height:"30px"}}/></Link>
              </button>
            </li>
            
            <li>              <Link to="/dashboard/wishlist/">
              <button id='buttonnav' className={`round-button ${activeMainTab === 'orange' ? 'active' : ''}`} style={{ '--round-button-active-color': '#ff6d00' }} data-translate-value="400%" data-color="orange" onClick={(e) => handleMainTabClick(e, 'orange', '400%')}>

              <span className="bad1 " style={{fontSize:"15px",color:"white",backgroundColor:"red",borderRadius:"20px" }}>
                    {wishlistCount}
                  </span>
                  <br />
                  <BsFillBookmarksFill className="cart" style={{height:"30px"}} />
              </button></Link>
            
              
            </li>
            <li>
              <button id='buttonnav' className="round-button" data-translate-value="0" data-color="red" onClick={(e) => handleMainTabClick(e, 'red', '0')}>
                <Link to="/dashboard/"><span className="avatarr">
                {userProfileDetails.status ? (
                  <div class="loader "></div>
                ) : (
                  <img
                    className="m-0 p-0"
                    alt="profile"
                    style={{
                      borderRadius: "15px",
                      height: "30px",
                      width: "30px",
                    }}
                    src={
                      userProfileDetails?.data.length === 0 ||
                      userProfileDetails?.data[0]?.fields?.image == "undefined"
                        ? "https://images.pexels.com/photos/3756985/pexels-photo-3756985.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                        : userProfileDetails?.data[0]?.fields?.image
                    }
                  />
                )}                </span></Link>
              </button>
            </li>
          </ul>
      </div>
      )}
    </nav>
  );
};

export default MyComponent;
