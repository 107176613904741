import { location2} from "../component/Location";

export const filterInsertData = {
  Price: {
    minPrice: [],
    maxPrice: [],
  },

};

export const filterInsertJobData = {
  Price: {
    minSalary: [],
    maxSalary: [],
  },
  Location: {
    City: location2.map((value) => value),
  },
};
