import React, { useContext } from "react";
import { ChatContext } from "../context/ChatContext";
import Loader from "../../../../../Loader/Loader";
import UserDetail from "./UserDetail";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { AuthContext } from "../context/AuthContext";

const Chats = ({
  chats,
  loading,
  searchResults,
  showSearch,
  setShowSearch,
}) => {
  const { data, dispatch } = useContext(ChatContext);
  const { currentUser } = useContext(AuthContext);
  const handleSelect = (u) => {
    setShowSearch(false);
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  return loading ? (
    <div
      className="d-flex  justify-content-center align-items-center"
      style={{ height: "90%" }}
    >
      <Loader />
    </div>
  ) : showSearch ? (
    <div className="chats">
      {searchResults.length === 0 ? (
        <div className="empty-content">Nothing to show</div>
      ) : (
        searchResults.map((item) => {
          const user = item.user;
          const userId = item.userId;
          const photoURL = user?.userInfo?.photoURL;
          const name =
            user?.userInfo?.displayName ||
            user?.userInfo?.email ||
            "Hola9 User";
          const lastMessage = user?.lastMessage;
          return (
            <UserDetail
              key={userId}
              handleSelect={handleSelect}
              photoURL={photoURL}
              name={name}
              lastMessage={lastMessage}
              userInfo={user.userInfo}
            />
          );
        })
      )}
    </div>
  ) : (
    <div className="chats">
      {!chats && <div className="empty-content">Nothing to show</div>}
      {Object.entries(chats ? chats : {})
        ?.sort((a, b) => b[1].date - a[1].date)
        .map(([userId, user]) => {
          const photoURL = user?.userInfo?.photoURL;
          const name =
            user?.userInfo?.displayName ||
            user?.userInfo?.email ||
            "Hola9 User";
          const lastMessage = user?.lastMessage;

          return (
            <UserDetail
              userId={userId}
              handleSelect={handleSelect}
              photoURL={photoURL}
              name={name}
              lastMessage={lastMessage}
              userInfo={user.userInfo}
              activeUser={data?.user?.uid}
            />
          );
        })}
    </div>
  );
};

export default Chats;
