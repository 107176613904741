import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { localUrl } from "../../env";
import Slider from "react-slick";
import { NavLink, useNavigate } from "react-router-dom";
import { add, remove } from "../../../store/wishlistSlice";
import "./user.css";
import { url } from "../../env";
import "../../../component/Ads/FeaturedAds/Feature.css";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { isMobile } from "react-device-detect";
import { BsHeartFill } from "react-icons/bs";

import {
  BsFillTagFill,
  BsFillBookmarkFill,
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper,
  BsCoin,
} from "react-icons/bs";
import DynamicFont from "react-dynamic-font";

import { Tooltip, Spin } from "antd";

import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";

import { SliderContainer } from "../../../Utils/SliderContainer";
import YoutubeMagic from "../../ContentLoader/YoutubeMagic";
import NoDataFound from "../../datanotfound/NoDataFound";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";
import Owldemo1 from "../../Owldemo1";
import Owldemo2 from "../../Owldemo2";
import {
  FaAlignJustify,
  FaBars,
  FaBookReader,
  FaBuffer,
  FaHome,
  FaMedapps,
  FaPhoneAlt,
  FaRegFileVideo,
  FaRegNewspaper,
  FaStar,
} from "react-icons/fa";
import { Delete, Margin } from "@mui/icons-material";
import { MdOutlineSubject } from "react-icons/md";
import UserRating from "./UserRating";
const mobstyle = {
  width: "auto",
  marginLeft: "10px",
  marginRight: "10px",
};
const deskstyle = {
  marginLeft: "11.5rem",
  marginRight: "11.5rem",
};

// mobCartstyle start
const mobCartStyle = {
  display: "flex",
  flexDirection: " row",
  fontSize: "10px",
  width: "100%",
  Margin: "auto",
  backgroundColor: "white",
  borderRadius: "10px",
  justifyContent: "center",
  // paddingLeft:"25%"
};
// mobCartstyle end

// deskCartStyle start
const deskCartStyle = {
  backgroundColor: "white",
  borderRadius: "10px",
  width: "100%",
};
// deskcartStyle end

const UserActiveAds = () => {
  const [rating, setRating] = useState(null);
  const [starRating, setStarRating] = useState(null);
  const [color, setColor] = useState(null);

  const getEmoji = (rating) => {
    if (rating === 1) return "😔";
    if (rating === 2) return "😐";
    if (rating === 3) return "😦";
    if (rating === 4) return "😊";
    if (rating === 5) return "😍";
  };

  const mobuse = {
    marginLeft: "5px",
    marginRight: "5px",
  };
  const deskuse = {
    marginLeft: "11.5rem",
    marginRight: "11.5rem",
  };

  let { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const imageValue = useSelector((state) => state.profile);
  const [isloading, setIsLoading] = useState(true);
  const wishlistData = useSelector((state) => state.wishlistSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add({ value: value }));
    }

    // wishlistID.addEventListener('click', e => e.stopPropagation());
  };

  const localmail = localStorage.getItem("email");

  const getUserData = async () => {
    setIsLoading(true);
    var formdata = new FormData();
    formdata.append("start", 1);
    formdata.append("end", 100);
    formdata.append("user", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    await fetch(localUrl + "/adsapi/allAdsByInerval", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUserData(result);
        console.log(result, "re");
        setIsLoading(false);
      })
      .catch((error) => setIsLoading(false));
  };
  useEffect(() => {
    getUserData();
  }, [id]);

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("user", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/userProfileDetailsApi/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setName(result[0].fields.name);
        setEmail(result[0].fields.email);
      })
      .catch((error) => console.log("error", error));
  });

  // allAds geetings start
  const greeting = "RealEstate";
  const greeting1 = "Furniture";
  const greeting2 = "Electronics";
  const greeting3 = "Cars";
  const greeting4 = "Bikes";
  const greeting5 = "Pets";
  const greeting6 = "Mobiles";
  const greeting7 = "Services";
  const greeting8 = "Education";
  const greeting9 = "Jobs";
  //  allAds geetings end
  const [open, setOpen] = useState(true);

  const [minPrice, setMinPrice] = useState(""); // State for minimum price filter
  const [maxPrice, setMaxPrice] = useState(""); // State for maximum price filter
  const [searchQuery, setSearchQuery] = useState(""); // State for search bar

  // Function to filter ads based on min and max price
  const filterAdsByPrice = (ad) => {
    if (!minPrice && !maxPrice) return true; // If no filters are set, show all ads
    const adPrice = parseInt(ad.fields.price);
    const min = minPrice ? parseInt(minPrice) : 0;
    const max = maxPrice ? parseInt(maxPrice) : Number.MAX_SAFE_INTEGER;
    return adPrice >= min && adPrice <= max;
  };

  // Function to filter ads based on search query
  const filterAdsBySearch = (ad) => {
    if (!searchQuery) return true; // If no search query, show all ads
    // Convert all ad fields to lowercase for a case-insensitive search
    const adFields = Object.values(ad.fields).map((field) =>
      String(field).toLowerCase()
    );
    // Check if any field contains the search query
    return adFields.some((field) => field.includes(searchQuery.toLowerCase()));
  };

//  ------------------------ post rating --- (after posting fetch new data) ------------------------ //

  const userid = localStorage.getItem("userid");
  
  
  const [comment, setComment] = useState('');
  
  
  const postRatingComment = async () => {
    try {
      const date = new Date()
      const response = await fetch(url + "api/sellerreviews/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          rating_value: starRating,
          comment: comment, 
          seller_id: id, 
          user_id: userid, 
          name : name,
          city : 'city',
          date : date,
            description : '-'
          }),
        });
  
        if (response.ok) {
          alert('Rating posted successfully');

          // ------------ again fetch data --------------- //
          fetch(url + `api/sellerreviews/?seller_id=${id}`)
          .then(response => response.json())
          .then(data => {
            const lastItem = data[data.length - 1];  // Check if the last item in the array contains the average_rating_value
            if ('average_rating_value' in lastItem) {
              setRating(lastItem.average_rating_value); // If the last item contains the average_rating_value, use it
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
        setRating(0);  // ----------- if there is no rating set default rating "0" -------------------------- //
          });
        } else {
          alert('Failed to post rating');
        }
      } catch (error) {
        console.error('Error posting rating:', error);
      }
    };


//  ------------------------ get average rating ----------------------- //
    useEffect(() => {
      fetch(url + `api/sellerreviews/?seller_id=${id}`)
        .then(response => response.json())
        .then(data => {
          const lastItem = data[data.length - 1];   // Check if the last item in the array contains the average_rating_value
          if ('average_rating_value' in lastItem) {
            setRating(lastItem.average_rating_value);  // If the last item contains the average_rating_value, use it
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
      setRating(0);    // ----------- if there is no rating set default rating "0" -------------------------- //
        });
    }, []);

//  ---------------------- fetch comments posted -------------------------- //
    const [comments, setComments] = useState([]);
    
    useEffect(() => {
    const getCommentsData = async () => {
      try {
        const response = await fetch(url + `api/sellerreviews/?seller_id=${id}`);
        if (response.ok) {
          const data = await response.json();
          setComments(data);
        } else {
          console.error('Failed to fetch comments');
        }
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };
    getCommentsData();
  }, [id]);


//  --------------------- Delete comment & rating ---------------------- //
  const handleDeleteComment = async (commentId) => {
    try {
      const response = await fetch(`${url}api/sellerreviews/?seller_id=${commentId}`, {
        method: 'DELETE',
      });
  
      if (response.ok) { // Remove the deleted comment from the state 
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== commentId)
        );
        alert('Comment deleted successfully');
      } else {
        alert('Failed to delete comment');
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };










  const renderFilteredAds = () => {
    return userData
      .filter((ad) => filterAdsByPrice(ad) && filterAdsBySearch(ad))
      .map((ad) => (
        <div
          key={ad.pk}
          className="ad-card"
          onClick={() =>
            navigate(`/ads-listing/${ad?.pk === undefined ? ad?.pk : ad.pk}/`)
          }
        >
          <div className="glassmorphism">
            <div>
              <div className="ad-image">
                <div className="wtrmrk">Hola9.com</div>
                <img
                  className="imghover-scale"
                  src={
                    !ad?.fields?.image
                      ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                      : ad.fields?.image
                  }
                  alt="ad"
                  onError={(e) => {
                    e.target.src =
                      "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                    e.onerror = null;
                  }}
                />
              </div>
              <div className="product-type">
                <span className="flat-badge booking">
                  <b>
                    <span
                      className="flat-badge booking "
                      style={{
                        background:
                          ad?.fields?.plan === "Silver"
                            ? "#cce6ff"
                            : ad?.fields?.plan === "Gold"
                            ? "gold"
                            : ad?.fields?.plan === "Platinum"
                            ? "green"
                            : ad?.fields?.plan === "Premium"
                            ? "linear-gradient(to right, #800080, #8A2BE2)"
                            : ad?.fields?.plan === "Featured"
                            ? "linear-gradient(to right, #090979, #00d4ff)"
                            : "transparent",
                        color:
                          ad?.fields?.plan === "Silver"
                            ? "blue"
                            : ad?.fields?.plan === "Gold"
                            ? "black"
                            : ad?.fields?.plan === "Platinum"
                            ? "white"
                            : ad?.fields?.plan === "Premium"
                            ? "white"
                            : ad?.fields?.plan === "Featured"
                            ? "white"
                            : "transparent",
                        padding: "5px",
                      }}
                    >
                      {ad?.fields?.plan}
                    </span>
                  </b>
                </span>
              </div>
              <ul className="viewsub">
                <li className="view">
                  <i className="fas fa-eye" style={{ color: "white" }} />
                  <span style={{ color: "white" }}>
                    {ad?.fields?.viewsproduct}
                  </span>
                </li>
                <li className="rating">
                  <i className="" style={{ color: "white" }} />
                  <span style={{ color: "white" }}>
                    <BsNewspaper />
                    &nbsp; {ad?.fields?.subCategoryValue?.slice(0, 12) + "..."}
                  </span>
                </li>
              </ul>
            </div>
            <div className="ad-details m-0 p-0">
              <p className="titlefont hidden-sm hidden-xs m-0 p-0">
                <Tooltip placement="topLeft" title={ad?.fields?.title}>
                  {ad?.fields?.title?.length > 17 ? (
                    <p className="titlefont">
                      {ad?.fields?.title?.slice(0, 17)}
                      {ad?.fields?.title?.length > 17 ? "..." : null}
                    </p>
                  ) : (
                    <div>
                      <p className="titlefont">
                        <DynamicFont content={ad?.fields?.title} />
                      </p>
                    </div>
                  )}
                </Tooltip>
              </p>
              <p className="titlefont d-lg-none">
                <Tooltip placement="topLeft" title={ad.fields?.title}>
                  {ad?.fields?.title?.length > 12 ? (
                    <p className="titlefont">
                      {ad?.fields?.title?.slice(0, 12)}
                      {ad?.fields?.title?.length > 12 ? "..." : null}
                    </p>
                  ) : (
                    <div style={{}}>
                      <p className="titlefont">
                        <DynamicFont content={ad?.fields?.title} />
                      </p>
                    </div>
                  )}
                </Tooltip>
              </p>
              <div
                className="condiv justify-content-between "
                style={{ marginTop: "-15px" }}
              >
                <div className="widhdiv d-flex justify-content-between m-0 p-0">
                  <p
                    className="fs-10 m-0 p-0 mt-1"
                    style={{
                      color: "#062544",
                      fontSize: "90%",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <BsGeoAltFill className="mr-1 " style={{ color: "red" }} />
                    <b>{ad?.fields?.City}</b>
                  </p>
                  <BsFillBookmarkFill
                    id="wishlibyn"
                    style={{
                      fontSize: "20px",
                      marginTop: "6px",
                    }}
                    className={
                      wishlistData?.data?.indexOf(ad.pk) !== -1
                        ? "removemob"
                        : "heartmob"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      addingWishlist(ad.pk);
                    }}
                  />
                </div>
                {ad?.fields?.category == "RealEstate" ? (
                  <p
                    className="conpir fs-14"
                    style={{
                      color: "gray",
                      fontSize: "90%",
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    <BsBuilding
                      className="mr-2"
                      style={{
                        color: "green",
                        fontSize: "90%",
                      }}
                    />
                    {ad.subCategoryType?.slice(0, 9)}
                  </p>
                ) : (
                  <p
                    className="conpir fs-14"
                    style={{
                      fontSize: "90%",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <BsFillCheckSquareFill
                      className="mr-1"
                      style={{
                        color: "green",
                        fontSize: "90%",
                      }}
                    />
                    {ad?.fields?.condition?.slice(0, 9)}
                  </p>
                )}
              </div>
              <p
                className="ad-price mr-1"
                style={{
                  fontSize: "90%",
                  fontFamily: "verdana",
                  marginTop: "-15px",
                }}
              >
                &nbsp;₹ <b>{ad?.fields?.price}</b>
              </p>
              <button
                id="wishbbt"
                style={{ marginTop: "-15px" }}
                className={
                  wishlistData?.data?.indexOf(ad.pk) !== -1
                    ? "remove-button"
                    : "wishlist-button"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  addingWishlist(ad.pk);
                }}
              >
                {wishlistData?.data?.indexOf(ad.pk) !== -1 ? (
                  <>
                    <AiFillDelete />
                    &nbsp;&nbsp;Remove Wishlist
                  </>
                ) : (
                  <>
                    <BsFillBookmarkFill />
                    &nbsp;&nbsp;Add To Wishlist
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      ));
  };

  return (
    <>
      {isloading ? <YoutubeMagic /> : null}
      {userData.length ? (
        <>
          <div className="profile-card-main-div">
            <div className="profile-card-sub-div">
              {/* style={{backgroundColor:"aliceblue" ,borderRadius:"10px",display:"flex",justifyContent:"center"}} */}
              <div
                className="row"
                style={isMobile ? mobCartStyle : deskCartStyle}
              >
                <div
                  className="col-lg-3 profile-card-img-div"
                  // style={isMobile ? mobileStyle : desktopStyle}
                >
                  <a className="image-link" href="#">
                    {/* {default_image} */}
                    <img
                      className="profile-card-img"
                      alt="error"
                      src={
                        !imageValue.length > 0
                          ? "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                          : imageValue[imageValue.length - 1]
                      }
                    />
                  </a>
                  <div className="prof-card-rating">
                    <UserRating rating={rating} setRating={setRating}/>
                  </div>
                </div>
                <div
                  className="col-lg-9 profile-card-text-div"
                  // style={isMobile ? mobileStyle1 : desktopStyle1}
                >
                  <h4>{name}</h4>
                  <i>{email}</i>
                  {/* <i>User id :{id}</i> */}
                </div>
                
              </div>
            </div>
          </div>
          {/* all ads word start */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "50px",
              marginTop: "50px",
            }}
          >
            <input
              type="text"
              placeholder="Search by item name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ borderBottom: "1px black solid" }}
            />
            <div>
              <input
                type="number"
                placeholder="Min Price"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <input
                type="number"
                placeholder="Max Price"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div>
              <h3
                className="my-3"
                style={{ textAlign: "center", textDecoration: "underline" }}
              >
                All Ads
              </h3>
            </div>
            {/* all ads word end */}

            <div className="row" style={isMobile ? mobstyle : deskstyle}>
              <div className="ads-container">
                <div className="card-grid">{renderFilteredAds()}</div>
              </div>
            </div>
          </div>


          {comments.map((result) => (
    <div className="bg-white p-2">
    <div className="d-flex flex-row user-info" style={{paddingTop:'0'}}>
      <img
        className="rounded-circle"
        src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
     style={{width:'40px', height:'35px'}}
      />
      <div className="d-flex flex-column justify-content-start ml-2">
        <span className="d-block font-weight-bold name">
          {/* {result.id} */}
          {/* {result.name} */}
        <UserRating rating={result.rating_value} />
        </span>
        <span className="date text-black-50"
          style={{ marginTop: '-5px' }}>
          {result.date}
        </span>
      </div>
    </div>
    <div className="mt-2">
      <p className="comment-text mx-5" style={{ marginTop: '-15px' }}>
        {result.comment} &nbsp;
        {userid == result.user_id && (
          <Delete
            style={{ color: "red", cursor: "pointer", fontSize: '13px' }}
            onClick={() => handleDeleteComment(result.seller_id)}
          />
        )}
      </p>
    </div>
    <div className="text-right"></div>
  </div>
  ))
}
          

          <div className="row" style={isMobile ? mobuse : deskuse}>
            <div
              style={{
                margin: "4rem 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="rating-comment-main">
                <div className="rating-comment">
                  <div className="rating-icon-div">
                    <div className="rating-icon">
                      <h2>
                        {rating.toFixed(1)}
                      </h2>
                    </div>
                    <UserRating  rating={rating} setRating={setRating}/>
                  </div>

                  <div className="rating-cont-div">
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: "18px",
                          width: "30px",
                          marginTop: "1%",
                          marginBottom: "0%",
                        }}
                      >
                        {getEmoji(starRating)}
                      </p>
                      {[...Array(5)].map((stars, key) => {
                        const currentRate = key + 1;
                        return (
                          <>
                            <label
                              style={{ margin: "0%", paddingBottom: "5px" }}
                            >
                              <input
                                type="radio"
                                name="rate"
                                value={currentRate}
                                onClick={() => setStarRating(currentRate)}
                              />

                              <FaStar
                                style={{ marginRight: "10px" }}
                                size={30}
                                color={
                                  currentRate <= (color || starRating)
                                    ? "gold"
                                    : "gray"
                                }
                              />
                            </label>
                          </>
                        );
                      })}
                    </div>
                    <textarea
                    onChange={(e) => setComment(e.target.value)}
                    className="rating-textarea"
                    placeholder="Share details of your own experience"
                    ></textarea>
                  </div>
                </div>
                <button className="btn btn-primary btn-block" 
                onClick={postRatingComment}
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </>
      ) : !isloading ? (
        <NoDataFound />
      ) : null}

      {/* 
      {userData.length ? (
        <>
      <div className="row" style={isMobile ? mobuse : deskuse}>
        <div style={{margin: '4rem 0'}}>
        <UserRating />
        </div>
      </div>
      </>
      ) : !isloading ? (
        <NoDataFound />
      ) : null} */}

      {/*ads greetings start */}
      {/* style={{marginLeft:"11.5rem", marginRight:"11.5rem", marginTop:"25px",marginBottom:"25px"}} */}
      
      {/*ads greetings end  */}
    </>
  );
};
export default UserActiveAds;
