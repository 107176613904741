// import React from "react";
// import CategoryEnquiryForm from "./CategoryEnquiryForm";
// import { Link } from "react-router-dom";
// import ghar from "../../src/component/images/ghar.png";
// import furnitureCat from "../../src/component/images/furnitureCat.jpg";
// import doggy from "../../src/component/images/doggy.png";
// import bike from "../../src/component/images/bike.png";
// import bike1 from "../../src/component/images/bike1.jpg";
// import car from "../../src/component/images/car.png";
// import mob from "../../src/component/images/mob.png";
// import service1 from "../../src/component/images/Service1.jpg";
// import educat from "../../src/component/images/Education.jpg";
// import electronics from "../../src/component/images/electronics.png";
// import "./AllCategory.css";
// import { Jobs } from "../component/env";



// const AllCategories = () => {
//   return (
//     <>
//             <div
//             className="allcat"
//             style={{ background: "White", borderRadius: "8rem 8rem 8rem 8rem", paddingTop: '4rem', paddingLeft: '5rem' }}
//           >
//               <div class="">
//                 <div>
//                   <Link to="/business-pricing/Furnitures" title="Category Furnitures">
//                     <img
//                       alt="imag"
//                       src={furnitureCat}
//                       className="categoryHover"
//                     />
//                     <p className="text-center fs-14 mb-0">Furnitures</p>
//                   </Link>
//                 </div>
//               </div>
//               <div class="">
//                 <Link to="/business-pricing/realestate" title="Category RealEstates">
//                   <img
//                     alt="imag"
//                     src={ghar}
//                     // style={isMobile ? mobileStyle : desktopStyle}
//                     className="categoryHover"
//                   />
//                   <p className="text-center fs-14 mb-0">Real Estate</p>
//                 </Link>
//               </div>
//               <div class="">
//                 <div>
//                   <Link to="/business-pricing/bikes" title="Category Bikes">
//                     <img alt="imag" src={bike1} className="categoryHover" />
//                     <p className="text-center fs-14 mb-0">Bikes</p>
//                   </Link>
//                 </div>
//               </div>
//               <div class="">
//                 {" "}
//                 <div>
//                   <Link to="/business-pricing/mobiles" title="Category Mobiles">
//                     <img alt="imag" src={mob} className="categoryHover" />
//                     <p className="text-center fs-14 mb-0">Mobiles</p>
//                   </Link>
//                 </div>
//               </div>
//               <div class="">
//                 <div>
//                   <Link to="/business-pricing/pets" title="Category Pets">
//                     <img alt="imag" src={doggy} className="categoryHover" />
//                     <p className="text-center fs-14 mb-0">Pets</p>
//                   </Link>
//                 </div>
//               </div>
//               <div class="">
//                 <div>
//                   <Link to="/business-pricing/electronics" title="Category Electronics">
//                     <img
//                       alt="imag"
//                       src={electronics}
//                       className="categoryHover"
//                     />
//                     <p className="text-center fs-14 mb-0">Electronics</p>
//                   </Link>
//                 </div>
//               </div>
//               <div class="">
//                 <div>
//                   <Link to="/business-pricing/cars" title="Category Cars">
//                     <img alt="imag" src={car} className="categoryHover" />
//                     <p className="text-center fs-14 mb-0">Cars</p>
//                   </Link>
//                 </div>
//               </div>
//               <div class="">
//                 <div>
//                   <Link to="/business-pricing/services" title="Category Services">
//                     <img alt="imag" src={service1} className="categoryHover" />
//                     <p className="text-center fs-14 mb-0">Services</p>
//                   </Link>
//                 </div>
//               </div>
//               <div class="">
//                 <div>
//                   <Link to="/business-pricing/Education" title="Category Services">
//                     <img alt="imag" src={educat} className="categoryHover" />
//                     <p className="text-center fs-14 mb-0">Education</p>
//                   </Link>
//                 </div>
//               </div>
//             </div>
          
          
          
          
       
      
//     </>
//   );
// };

// export default AllCategories;

















// import React from "react";
// import CategoryEnquiryForm from "./CategoryEnquiryForm";
// import { Link } from "react-router-dom";
// import ghar from "../../src/component/images/ghar.png";
// import furnitureCat from "../../src/component/images/furnitureCat.jpg";
// import doggy from "../../src/component/images/doggy.png";
// import bike from "../../src/component/images/bike.png";
// import bike1 from "../../src/component/images/bike1.jpg";
// import car from "../../src/component/images/car.png";
// import mob from "../../src/component/images/mob.png";
// import service1 from "../../src/component/images/Service1.jpg";
// import educat from "../../src/component/images/Education.jpg";
// import electronics from "../../src/component/images/electronics.png";
// import jobs from "../../src/component/images/jobs.png"; // Add the image for the "Jobs" category
// import "./AllCategory.css";

// const AllCategories = () => {
//   return (
//     <>
//       <div
//         className="allcat"
//         style={{
//           background: "White",
//           borderRadius: "8rem 8rem 8rem 8rem",
//           paddingTop: '4rem',
//           paddingLeft: '5rem'
//         }}
//       >
//         <div>
//           <Link to="/business-pricing/Furnitures" title="Category Furnitures">
//             <img
//               alt="imag"
//               src={furnitureCat}
//               className="categoryHover"
//             />
//             <p className="text-center fs-14 mb-0">Furnitures</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/realestate" title="Category RealEstates">
//             <img
//               alt="imag"
//               src={ghar}
//               className="categoryHover"
//             />
//             <p className="text-center fs-14 mb-0">Real Estate</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/bikes" title="Category Bikes">
//             <img alt="imag" src={bike1} className="categoryHover" />
//             <p className="text-center fs-14 mb-0">Bikes</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/mobiles" title="Category Mobiles">
//             <img alt="imag" src={mob} className="categoryHover" />
//             <p className="text-center fs-14 mb-0">Mobiles</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/pets" title="Category Pets">
//             <img alt="imag" src={doggy} className="categoryHover" />
//             <p className="text-center fs-14 mb-0">Pets</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/electronics" title="Category Electronics">
//             <img
//               alt="imag"
//               src={electronics}
//               className="categoryHover"
//             />
//             <p className="text-center fs-14 mb-0">Electronics</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/cars" title="Category Cars">
//             <img alt="imag" src={car} className ="categoryHover" />
//             <p className="text-center fs-14 mb-0">Cars</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/services" title="Category Services">
//             <img alt="imag" src={service1} className="categoryHover" />
//             <p className="text-center fs-14 mb-0">Services</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/Education" title="Category Services">
//             <img alt="imag" src={educat} className="categoryHover" />
//             <p className="text-center fs-14 mb-0">Education</p>
//           </Link>
//         </div>
//         <div>
//           <Link to="/business-pricing/Jobs" title="Category Jobs">
//             <img alt="imag" src={jobs} className="categoryHover" />
//             <p className="text-center fs-14 mb-0">Jobs</p>
//           </Link>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AllCategories;















import React from "react";
import { Link } from "react-router-dom";
import "./AllCategory.css";

// Import all category images
import ghar from "../../src/component/images/ghar.png";
import furnitureCat from "../../src/component/images/furnitureCat.jpg";
import doggy from "../../src/component/images/doggy.png";
import bike from "../../src/component/images/bike.png";
import bike1 from "../../src/component/images/bike1.jpg";
import car from "../../src/component/images/car.png";
import mob from "../../src/component/images/mob.png";
import service1 from "../../src/component/images/Service1.jpg";
import educat from "../../src/component/images/Education.jpg";
import electronics from "../../src/component/images/electronics.png";
import jobs from "../../src/component/images/jobs.png"; // Add the image for the "Jobs" category

const AllCategories = () => {
  return (
    <div className="maincard" >
    <div className="category-card" >
      <div className="category furniture" >
        <Link to="/business-pricing/Furnitures" title="Category Furnitures" >
          <img alt="imag" src={furnitureCat} className="categoryHover" style={{marginTop:"40px"}}/>
          <p className="text-center fs-14 mb-0" style={{marginTop:"20px"}}>Furnitures</p>
        </Link>
      </div>
      <div className="category realestate">
        <Link to="/business-pricing/realestate" title="Category RealEstates">
          <img alt="imag" src={ghar} className="categoryHover" />
          <p className="text-center fs-14 mb-0" id="real1" style={{marginTop:"10px"}}>Real Estate</p>
        </Link>
      </div>

      <div className="category bikes">
        <Link to="/business-pricing/bikes" title="Category Bikes">
          <img alt="imag" src={bike1} className="categoryHover" style={{marginTop:"50px"}} />
          <p className="text-center fs-14 mb-0" style={{marginTop:"35px"}}>Bikes</p>
        </Link>
      </div>
      <div className="category mobiles">
        <Link to="/business-pricing/mobiles" title="Category Mobiles">
          <img alt="imag" src={mob} className="categoryHover" style={{marginTop:"40px"}} />
          <p className="text-center fs-14 mb-0"  style={{marginTop:"20px"}}>Mobiles</p>
        </Link>
      </div>
      <div className="category pets">
        <Link to="/business-pricing/pets" title="Category Pets">
          <img alt="imag" src={doggy} className="categoryHover" style={{marginTop:"40px"}} />
          <p className="text-center fs-14 mb-0" style={{marginTop:"20px"}}>Pets</p>
        </Link>
      </div>
      <div className="category electronics">
        <Link to="/business-pricing/electronics" title="Category Electronics">
          <img alt="imag" src={electronics} className="categoryHover" style={{marginTop:"40px"}} />
          <p className="text-center fs-14 mb-0" style={{marginTop:"30px"}}>Electronics</p>
        </Link>
      </div>
      <div className="category cars">
        <Link to="/business-pricing/cars" title="Category Cars">
          <img alt="imag" src={car} className="categoryHover" style={{marginTop:"20px"}} />
          <p className="text-center fs-14 mb-0" style={{marginTop:"10px"}}>Cars</p>
        </Link>
      </div>
      <div className="category services">
        <Link to="/business-pricing/services" title="Category Services">
          <img alt="imag" src={service1} className="categoryHover" style={{marginTop:"50px"}} />
          <p className="text-center fs-14 mb-0" style={{marginTop:"45px"}}>Services</p>
        </Link>
      </div>
      <div className="category education">
        <Link to="/business-pricing/Education" title="Category Services">
          <img alt="imag" src={educat} className="categoryHover" style={{marginTop:"40px"}} />
          <p className="text-center fs-14 mb-0" style={{marginTop:"25px"}}>Education</p>
        </Link>
      </div>
      <div className="category jobs">
        <Link to="/business-pricing/Jobs" title="Category Jobs">
          <img alt="imag" src={jobs} className="categoryHover" style={{marginTop:"40px"}} />
          <p className="text-center fs-14 mb-0" style={{marginTop:"30px"}}>Jobs</p>
        </Link>
      </div>
    </div>
    </div>
  );
};

export default AllCategories;






