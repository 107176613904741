
// import React, { useState } from "react";
// import "./allfilter.css"; // Make sure to create the corresponding CSS file
// // import { CityMain, CityMain1, Jobs } from '../../../env';
// import { CityMain1, Jobs } from "../../../env";
// import { Link, Navigate, useNavigate } from "react-router-dom";
// import { SearchOutlined } from "@mui/icons-material";
// import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
// import { searchApi } from "../../../../store/ToggleSearchSlice";
// import { useContext, useEffect } from "react";
// import { UserContext } from "../../../../App";
// import { useDispatch } from "react-redux";
// import { add } from "../../../../store/Track/trackUserSlice";
// import { getExtraField } from "../../../../store/ProductFilterSlice";
// import { isMobile } from "react-device-detect";
// import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
// import { Spin } from "antd";
// import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
// import Jobsubcat from "./Jobsubcat";
// import { Citys, stateMain } from "../../../Location";
// import Allcatads from "../../../Allcatads";
// import { Education1 } from "../../../env";
// import JobsMainFilter from "./JobsMainfilter";
// import { endAt } from "firebase/firestore";
// import CloseIcon from "@mui/icons-material/Close";
// import MyGallery from "./MyGallery";

// const Jobscat = () => {
//   const mobstyle = {
//     width: "100%",

//     // marginTop: "-180px",
//   };
//   const deskstyle = {
//     padding: "20px",
//     marginBottom: "-10px",
//     marginRight: "10rem",
//     marginLeft: "10rem",
//     marginTop: "250px",
//   };
//   const mobi = {



//   }
//   const deski = {
//     marginLeft: "7rem", marginRight: "7rem"
//   }



//   const [selectedSubcategory, setSelectedSubcategory] = useState('');
//   const [selectedSecondaryOption, setSelectedSecondaryOption] = useState('');



//   const handleSubcategoryChange = (event) => {
//     const selectedValue = event.target.value;
//     setSelectedSubcategory(selectedValue);
//     setSelectedSecondaryOption(''); // Reset the secondary option when changing subcategory
//   };


//   const [flagSearh, setFlagSearch] = useState(false);
//   // const cities = ['New York', 'Los Angeles', 'Chicago', 'San Francisco', 'Miami'];
//   const subcategories = ["Contract Job", "Full Time Jobs", "Walk-In"];

//   // State to hold selected values


//   const [selectedCity, setSelectedCity] = useState('');
//   const [filteredList, setFilteredList] = useState([]);
//   const [Error, setError] = useState('');


//   // for button  filter
//   // const [isPopupOpen, setPopupOpen] = useState(false);



//   const [isPopupOpen, setPopupOpen] = useState(false);

//   const handleClosePopup = () => {
//     setPopupOpen(false);
//   };
//   const openPopup = () => {
//     setPopupOpen(true);
//   };


//   // end button filter



//   const handleFilterChange = (event) => {
//     setSelectedFilter(event.target.value);
//   };
//   const handleInputChange = (e) => {
//     const inputValue = e.target.value;
//     setSelectedCity(inputValue);

//     if (inputValue) {
//       const filteredOptions = Citys.filter(city =>
//         city.toLowerCase().includes(inputValue.toLowerCase())
//       );
//       setFilteredList(filteredOptions);
//     } else {
//       setFilteredList([]);
//     }
//   }

//   const handleOptionClick = (city) => {
//     setSelectedCity(city);
//     setFilteredList([]);
//   }

//   // const [selectedCity, setSelectedCity] = useState("");
//   // const [selectedSubcategory, setSelectedSubcategory] = useState("");

//   // Handle form submission
//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here, e.g., sending data to the server
//     console.log("Selected City:", selectedCity);
//     console.log("Selected Subcategory:", selectedSubcategory);
//   };

//   const greeting = "Jobs";

//   const [selectedFilter, setSelectedFilter] = useState(null);
//   // const [selectedFilter1, setSelectedFilter1] = useState(null);
//   const [SearchValue, setSearchValue] = useState("");
//   const searchArray = () => {
//     if (SearchValue?.trim()) {
//       dispatch(searchApi(SearchValue));
//     }
//   };
//   //  for filter button

//   const handleFilterClick = () => {
//     setPopupOpen(false);
//   };

//   // const handleClosePopup = () => {
//   //   setPopupOpen(false);
//   // };

//   // end


//   const handleFilterButtonClick = (filterType) => {
//     setSelectedFilter(filterType);
//   };
//   const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
//   const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
//   const disptach = useDispatch();
//   const dispatch = useDispatch();
//   // Accessing the Redux dispatch function

//   useEffect(() => {
//     disptach(add({ view: ["jobcat"] })); // Dispatching an action using Redux to add the view to the user's track
//   }, []);
//   const [extraFieldCopy, setExtraFieldCopy] = useState({});

//   const [searchSending, setsearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     City: null,
//     pricemin: null,
//     pricemax: null,
//     searchValue: null,
//     category: "Jobs",
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook

//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setExtraFieldCopy({ [name]: value });
//     // setsearchSending({ ...searchSending, [name]: value });
//     // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   useEffect(() => {
//     // Update searchSending state with the selected filter value
//     if (selectedFilter !== null) {
//       setsearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);
//   const [selectedButton, setSelectedButton, onClick] = useState("button1", "furniture");


//   // const [Error, setError] = useState(null);
//   const naviagate = useNavigate();
//   const clickbuttonhandler = () => {
//     if (selectedCity === "" && selectedSubcategory === "") {
//       setError("please enter all the fields");
//     } else if (selectedCity === "") {
//       setError("please enter the city name");
//     } else if (selectedSubcategory === "") {
//       setError("please enter the Job Option");
//     } else {

//       for (const key in searchSending) {
//         if (searchSending[key]?.trim() === "" || searchSending[key] === null) {
//           delete searchSending[key];
//         }
//       }
//       searchSending["extraField"] = { ...extraFieldCopy }; // Object Assign to searchSending object
//       searchSending["subCategoryValue"] = selectedSubcategory; // Object Assign to searchSending object
//       searchSending["City"] = selectedCity; // Object Assign to searchSending object

//       disptach(getExtraField(searchSending));
//       naviagate(`/ads-listing/`)// Dispatching getExtraField action
//     }
//   };




//   // AdsCards logic start

//   const [advClose, setadvClose] = useState(true);
//   useEffect(() => {
//     const isPopupClosed = sessionStorage.getItem('popupClosed');
//     const name = localStorage.getItem('name')
//     if (isPopupClosed && name) {
//       setadvClose(false);
//     }
//   }, []);
//   const handleCloseadv = () => {
//     setadvClose(false);
//     sessionStorage.setItem('popupClosed', 'true');
//   };
//   // AdsCards logic end

//   return (
//     <>


    
//       {/* AdsCards logic start */}
//       {advClose ? (
//         <div
//           style={{
//             position: "fixed",
//             bottom: "2%",
//             right: "2%",
//             width: "350px",
//             height: "170px",
//             zIndex: "3",
//             border: "0.5px solid gray",
//             background:
//               // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
//               'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
//             backgroundSize: "100% 100%",
//             borderRadius: '7px'
//           }}
//         >
//           <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"18px",  marginRight:'6px' }} onClick={handleCloseadv}>x</span>
//           <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
//             <div style={{ color: 'white' }}>
//               Get the best <br />
//               <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
//             </div>
//             <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
//               Grow Your Business
//             </div>
//             <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
//           </div>
//         </div>
//       ) : null}
//       {/* AdsCards logic end */}




//       <div className="jobscat-container">
//         <div className="full-width-image">
//           {/* Replace the source with your actual image */}

//           <img
//             className="jobimg99"
//             src="https://images.pexels.com/photos/4065876/pexels-photo-4065876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
//             alt="Full Width"
//           />
//           <div className="text99">
//             <h1 style={{ marginTop: "-50px" }}>
//               Explore Different Job Opportunities
//             </h1>
//           </div>
//         </div>
//         { }
//         <div className="search-and-post-form99">
//           <h2 style={{ color: "#0B0C0D" }}>Search and find</h2>
//           <form onSubmit={handleFormSubmit}>

//             <div className="form-group">
//               <label htmlFor="cityDropdown">Select a City:</label>
//               <div className="search-box">
//                 <input
//                   type="text"
//                   id="cityInput"
//                   name="cityInput"
//                   value={selectedCity}
//                   placeholder="Select City"
//                   onChange={handleInputChange}
//                   className="search-bar"
//                 />
//                 {filteredList.length > 0 && (
//                   <ul className="filtered-options">
//                     {filteredList.map((city) => (
//                       <li key={city} onClick={() => handleOptionClick(city)}>
//                         {city}
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </div>
//             </div>
//             {selectedCity === "" && (
//               <small className="text-danger ml-2">{Error}</small>
//             )}

//             <div className="form-group99" >
//               <label htmlFor="subcategoryDropdown">Select a Job Option:</label>
//               <select
//                 id="subcategoryDropdown99"
//                 name="subcategory99"
//                 value={selectedSubcategory}
//                 onChange={(e) => setSelectedSubcategory(e.target.value)}
//                 className="dropdown99"

//               >
//                 <option>Select a subcategory</option>
//                 {subcategories.map((subcategory) => (
//                   <option key={subcategory} value={subcategory}>
//                     {subcategory}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             {selectedSubcategory === "" && (
//               <small className="text-danger ml-2">{Error}</small>
//             )}
//             <br></br>
//             <button type="submit" className="search-button99" onClick={clickbuttonhandler}>
//               Search
//             </button>
//             <Link to="/cat/apply">
//               <button className="myjob123"
//               >MyJobs
//               </button></Link>

//           </form>
//         </div>
//         <div className="hdesign job-filter-head">
//           <h1 style={{ marginTop: "100px", color: "#1B3147" }}>
//             Explore Different Jobs
//           </h1>

//         </div>

//         {/* Subcategory sections */}
//       </div>


//       {/*  Start Side filter for Jobs category */}
//       {/* 
//       <button 
//       style={{padding:"6px 10px",border:"solid black 1.5px",borderRadius:"20px",marginLeft:"20cm"}}
//        onClick={handleFilterClick} ><JobsMainFilter/>filter</button> */}

//       <JobsMainFilter />
//       {/* End Side filter for Jobs category */}

//       {loading && (
//         <div>
//           <h5>
//             {" "}
//             <div className="example">
//               <Spin />
//             </div>{" "}
//           </h5>{" "}
//         </div>
//       )}
//       {!loading && (
//         <>
//           <div
//             className="mb-3 mt-2"
//             style={isMobile ? mobstyle : deskstyle}
//           >
//             <Feature value="Jobs" />
//             {" "}
//             {/* Rendering the 'Feature' component with value "RealEstate" */}
//             <Allcatads greeting={greeting} subcategory={"ContractJobs"} onClick={openPopup} />
//             {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
//           </div>
//           <div style={isMobile ? mobi : deski}>
//             <TrendingAds />
//           </div></>
//       )}

//       {isPopupOpen && (
//         <div className="fildi2">
//           <div>
//             <button onClick={handleClosePopup}>
//               <CloseIcon style={{ color: "red", fontSize: "20px", float: "right" }} />
//             </button>
//             {/* Rest of your filter content */}
//             <JobsMainFilter />
//           </div>


//         </div>
//       )}


//       <div style={{ marginTop: "-30px" }}>
//         <Jobsubcat />
//       </div>
//       <MyGallery selectedCategory="Jobs" />

//     </>
//   );
// };

// export default Jobscat;




import React, { useState } from "react";
import "./allfilter.css"; // Make sure to create the corresponding CSS file
// import { CityMain, CityMain1, Jobs } from '../../../env';
import { CityMain1, Jobs } from "../../../env";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import { searchApi } from "../../../../store/ToggleSearchSlice";
import { useContext, useEffect } from "react";
import { UserContext } from "../../../../App";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice";
import { isMobile } from "react-device-detect";
import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import { Spin } from "antd";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import Jobsubcat from "./Jobsubcat";
import { Citys, stateMain } from "../../../Location";
import Allcatads from "../../../Allcatads";
import { Education1 } from "../../../env";
import JobsMainFilter from "./JobsMainfilter";
import { endAt } from "firebase/firestore";
import CloseIcon from "@mui/icons-material/Close";
import MyGallery from "./MyGallery";

const Jobscat = () => {
  const mobstyle = {
    width: "100%",

    // marginTop: "-180px",
  };
  const deskstyle = {
    padding: "20px",
    marginBottom: "-10px",
    marginRight: "10rem",
    marginLeft: "10rem",
    marginTop: "250px",
  };
  const mobi = {



  }
  const deski = {
    marginLeft: "7rem", marginRight: "7rem"
  }



  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedSecondaryOption, setSelectedSecondaryOption] = useState('');



  const handleSubcategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSubcategory(selectedValue);
    setSelectedSecondaryOption(''); // Reset the secondary option when changing subcategory
  };


  const [flagSearh, setFlagSearch] = useState(false);
  // const cities = ['New York', 'Los Angeles', 'Chicago', 'San Francisco', 'Miami'];
  const subcategories = ["Contract Job", "Full Time Jobs", "Walk-In"];

  // State to hold selected values


  const [selectedCity, setSelectedCity] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [Error, setError] = useState('');


  // for button  filter
  // const [isPopupOpen, setPopupOpen] = useState(false);



  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const openPopup = () => {
    setPopupOpen(true);
  };


  // end button filter



  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSelectedCity(inputValue);

    if (inputValue) {
      const filteredOptions = Citys.filter(city =>
        city.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredList(filteredOptions);
    } else {
      setFilteredList([]);
    }
  }

  const handleOptionClick = (city) => {
    setSelectedCity(city);
    setFilteredList([]);
  }

  // const [selectedCity, setSelectedCity] = useState("");
  // const [selectedSubcategory, setSelectedSubcategory] = useState("");

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., sending data to the server
    console.log("Selected City:", selectedCity);
    console.log("Selected Subcategory:", selectedSubcategory);
  };

  const greeting = "Jobs";

  const [selectedFilter, setSelectedFilter] = useState(null);
  // const [selectedFilter1, setSelectedFilter1] = useState(null);
  const [SearchValue, setSearchValue] = useState("");
  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };
  //  for filter button

  const handleFilterClick = () => {
    setPopupOpen(false);
  };

  // const handleClosePopup = () => {
  //   setPopupOpen(false);
  // };

  // end


  const handleFilterButtonClick = (filterType) => {
    setSelectedFilter(filterType);
  };
  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch();
  const dispatch = useDispatch();
  // Accessing the Redux dispatch function

  useEffect(() => {
    disptach(add({ view: ["jobcat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);
  const [extraFieldCopy, setExtraFieldCopy] = useState({});

  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    City: null,
    pricemin: null,
    pricemax: null,
    searchValue: null,
    category: "Jobs",
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  const [selectedButton, setSelectedButton, onClick] = useState("button1", "furniture");


  // const [Error, setError] = useState(null);
  const naviagate = useNavigate();
  const clickbuttonhandler = () => {
    if (selectedCity === "" && selectedSubcategory === "") {
      setError("please enter all the fields");
    } else if (selectedCity === "") {
      setError("please enter the city name");
    } else if (selectedSubcategory === "") {
      setError("please enter the Job Option");
    } else {

      for (const key in searchSending) {
        if (searchSending[key]?.trim() === "" || searchSending[key] === null) {
          delete searchSending[key];
        }
      }
      searchSending["extraField"] = { ...extraFieldCopy }; // Object Assign to searchSending object
      searchSending["subCategoryValue"] = selectedSubcategory; // Object Assign to searchSending object
      searchSending["City"] = selectedCity; // Object Assign to searchSending object

      disptach(getExtraField(searchSending));
      naviagate(`/ads-listing/`)// Dispatching getExtraField action
    }
  };




  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);
  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end

  return (
    <>


    
      {/* AdsCards logic start */}
      {advClose ? (
        <div
          style={{
            position: "fixed",
            bottom: "2%",
            right: "2%",
            width: "350px",
            height: "170px",
            zIndex: "3",
            border: "0.5px solid gray",
            background:
              // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
              'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
            backgroundSize: "100% 100%",
            borderRadius: '7px'
          }}
        >
          <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"18px",  marginRight:'6px' }} onClick={handleCloseadv}>x</span>
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
            <div style={{ color: 'white' }}>
              Get the best <br />
              <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
            </div>
            <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
              Grow Your Business
            </div>
            <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
          </div>
        </div>
      ) : null}
      {/* AdsCards logic end */}




      <div className="jobscat-container">
        <div className="full-width-image" style={{position:"relative"}}>
          {/* Replace the source with your actual image */}

          <img
            className="jobimg99"
            src="https://images.pexels.com/photos/4065876/pexels-photo-4065876.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Full Width"
          />
          <div className="text99-awi">
            <h1 className="text99-header-awi-24">
              Explore Different Job Opportunities
            </h1>
          </div>

          <div className="search-and-post-form99 search-and-post-form-awi-23">
          <h2 style={{ color: "#0B0C0D" }}>Search and find</h2>
          <form onSubmit={handleFormSubmit}>

            <div className="form-group">
              <label htmlFor="cityDropdown">Select a City:</label>
              <div className="search-box">
                <input
                  type="text"
                  id="cityInput"
                  name="cityInput"
                  value={selectedCity}
                  placeholder="Select City"
                  onChange={handleInputChange}
                  className="search-bar"
                />
                {filteredList.length > 0 && (
                  <ul className="filtered-options">
                    {filteredList.map((city) => (
                      <li key={city} onClick={() => handleOptionClick(city)}>
                        {city}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {selectedCity === "" && (
              <small className="text-danger ml-2">{Error}</small>
            )}

            <div className="form-group99" >
              <label htmlFor="subcategoryDropdown">Select a Job Option:</label>
              <select
                id="subcategoryDropdown99"
                name="subcategory99"
                value={selectedSubcategory}
                onChange={(e) => setSelectedSubcategory(e.target.value)}
                className="dropdown99"

              >
                <option>Select a subcategory</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
              </select>
            </div>
            {selectedSubcategory === "" && (
              <small className="text-danger ml-2">{Error}</small>
            )}
            <br></br>
            <button type="submit" className="search-button99" onClick={clickbuttonhandler}>
              Search
            </button>
            <Link to="/cat/apply">
              <button className="myjob123"
              >MyJobs
              </button></Link>

          </form>
        </div>
        </div>
        { }
        <div className="search-and-post-form99 search-and-post-form-awi-23-2">
          <h2 style={{ color: "#0B0C0D" }}>Search and find</h2>
          <form onSubmit={handleFormSubmit}>

            <div className="form-group">
              <label htmlFor="cityDropdown">Select a City:</label>
              <div className="search-box">
                <input
                  type="text"
                  id="cityInput"
                  name="cityInput"
                  value={selectedCity}
                  placeholder="Select City"
                  onChange={handleInputChange}
                  className="search-bar"
                />
                {filteredList.length > 0 && (
                  <ul className="filtered-options">
                    {filteredList.map((city) => (
                      <li key={city} onClick={() => handleOptionClick(city)}>
                        {city}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {selectedCity === "" && (
              <small className="text-danger ml-2" style={{marginTop:"-0.5rem"}}>{Error}</small>
            )}

            <div className="form-group99" >
              <label htmlFor="subcategoryDropdown">Select a Job Option:</label>
              <select
                id="subcategoryDropdown99"
                name="subcategory99"
                value={selectedSubcategory}
                onChange={(e) => setSelectedSubcategory(e.target.value)}
                className="dropdown99"

              >
                <option>Select a subcategory</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
              </select>
            </div>
            {selectedSubcategory === "" && (
              <small className="text-danger ml-2">{Error}</small>
            )}
            <br></br>
            <button type="submit" className="search-button99" onClick={clickbuttonhandler}>
              Search
            </button>
            <Link to="/cat/apply">
              <button className="myjob123"
              >MyJobs
              </button></Link>

          </form>
        </div>
        {/* <div className="hdesign job-filter-head">
          <h1 style={{ marginTop: "100px", color: "#1B3147" }}>
            Explore Different Jobs
          </h1>

        </div> */}

        {/* Subcategory sections */}
      </div>


      {/*  Start Side filter for Jobs category */}
      {/* 
      <button 
      style={{padding:"6px 10px",border:"solid black 1.5px",borderRadius:"20px",marginLeft:"20cm"}}
       onClick={handleFilterClick} ><JobsMainFilter/>filter</button> */}

      {/* <JobsMainFilter className="avinash-close-mainfilt"/> */}
      {/* End Side filter for Jobs category */}

      {loading && (
        <div>
          <h5>
            {" "}
            <div className="example">
              <Spin />
            </div>{" "}
          </h5>{" "}
        </div>
      )}
      {!loading && (
        <>
          <div
            className="mb-3 mt-2"
            style={isMobile ? mobstyle : deskstyle}
          >
            <Feature value="Jobs" />
            {" "}
            {/* Rendering the 'Feature' component with value "RealEstate" */}
            <Allcatads greeting={greeting} subcategory={"ContractJobs"} onClick={openPopup} />
            {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
          </div>
          <div style={isMobile ? mobi : deski}>
            <TrendingAds />
          </div></>
      )}

      {isPopupOpen && (
        <div className="fildi2">
          <div>
            <button onClick={handleClosePopup}>
              <CloseIcon style={{ color: "red", fontSize: "20px", float: "right" }} />
            </button>
            {/* Rest of your filter content */}
            <JobsMainFilter />
          </div>


        </div>
      )}


      <div style={{ marginTop: "-30px" }}>
        <Jobsubcat />
      </div>
      <MyGallery selectedCategory="Jobs" />

    </>
  );
};

export default Jobscat;