export const category1 = [
  "Electronics",
  "Cars",
  "Furniture",
  "Travels",
  "Household",
  "Mobile & Laptops",
  "Travelling",
  "Mobiles",
  "RealEstate",
  "Services",
  "Jobs",
  "Cars",
  "Bikes",
  "Health",
  "Photography",
];
const listby = ["Owner", "Dealer"];
//for prod
// export const localUrl = "https://hola9backend.pythonanywhere.com/api/"
// export const url = "https://hola9backend.pythonanywhere.com/"
// f,m.c,bi.e.  r-agent owner - job - freelancer employyer pets -mdealer individaul
// for local

// export const localUrl = "http://www.databytesoft.com/api/"
// export const url = "http://www.databytesoft.com/"

// export const localUrl = "http://www.databytesoft.com/api/"
// // export const url = "http://www.databytesoft.com/"

// export const localUrl = "http://databytesoft.com/api/"
// export const url = "http://databytesoft.com/"

export const localUrl = "https://databytess.com/api/";
export const url = "https://databytess.com/";
export const locationcurrent = "AKfycbxj8ZdfzTZ2KLXvS_1nO2VNfcdhCYlehaPqoylrblFhOrhbubBMVigvrG5TEMjBg88/exec?action=getData";

export const state = ["West Bengal", "Goa"];
export const cityConst = ["Bengaluru", "Pune", "Mumbai"];
export const access_token = localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : null;
export const subcategoryType1 = ["Residential", "Commercial"];
export const subcategoryType2 = ["Buy", "Rent"];
//Sub category Furniture (condition)
export const furnitureCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];
// Sub Categoty Fridge
export const fridgeDoorStyle = [
  "Multi Door​",
  "Side By side​",
  "Single Door",
  "Triple Door​",
  "Freezer On Top",
];
export const FridgeCondition = [
  "Brand New",
  "Gently Used",
  "Heavily used",
  "Unboxed",
];
export const FridgeBrandName = [
  "Bosch",
  "BuyerZone",
  "D Pex",
  "Samsung",
  "Onida",
  "Panasonic",
  "Sony",
  "L.G",
  "Intex",
  "Others",
];
export const FridgeCapacity = [
  "Upto 120L",
  "120L-200L",
  "200L-300L",
  "300L-400L",
  "400L & Above",
];

// Subcategory Washing Machines
export const WashingMachineBrand = [
  "Bosch",
  "BuyerZone",
  "Whirlpool",
  "Lioyd",
  "D Pex",
  "Samsung",
  "Onida",
  "Panasonic",
  "Sony",
  "L.G",
  "Intex",
  "Voltas",
  "Videcon",
  "Others",
];
export const WashingMachineCapacity = [
  "Upto 5.9kg",
  "6kg -6.9kg",
  "7kg-7.9kg",
  "8kgs & Above",
];
export const WashingMachineCondition = ["Brand New", "Fair", "Good", "Unboxed"];
export const WashingmachineLoad = ["Front Load", "Top Load"];

// Subcategory Air Coolers​
export const AirCoolerBrand = [
  "Bajaj",
  "BMQ",
  "Whirlpool",
  "Cello",
  "Blue Star",
  "Havells",
  "Hindware",
  "Hotstar",
  "Kelvinator",
  "Kenstar",
  "Kiran",
  "Voltas",
  "Khaitan",
  "Kunstomcon",
  "Orient",
  "Mccoy",
  "Others",
];
export const AirCoolerCondition = [
  "Brand New",
  "Gently Used",
  "Heavily used",
  "Unboxed",
];
export const AirCoolerCapacity = [
  "11L-15L",
  "16L-20L",
  "200L-300L",
  "21L-40L",
  "400L & Above",
];

// AirConditioner
export const ACBrand = [
  "Bosch",
  "BuyerZone",
  "Whirlpool",
  "Lioyd",
  "D Pex",
  "Samsung",
  "Onida",
  "Panasonic",
  "Sony",
  "L.G",
  "Intex",
  "Voltas",
  "Videcon",
  "Others",
];
export const ACType = ["Window", "Split"];
export const ACCondition = [
  "Brand New",
  "Gently Used​",
  "Heavily used",
  "Unboxed",
];
export const ACTonnage = [
  "1Ton",
  "1.25Ton",
  "1.5Ton",
  "1.8Ton",
  "2.0Ton & Above",
];

// Water Heaters / Geysers​
export const GyserBrand = [
  "Havells",
  "Apson",
  "Arise",
  "Bajaj",
  "Onida​",
  "Panasonic",
  "Bala",
  "LG ",
  "Blue Me ",
  "Eazy",
  "Florex",
  "Genius",
  "Happy Home​",
  "Others",
];
export const HeaterType = ["InstantWater Heatere", "Storage Water Heater​"];
export const GyserCondition = [
  "Brand New",
  "Gently Used​",
  "Heavily used",
  "Unboxed​",
];
export const HeaterCapacity = [
  "11L-15L​",
  "16L-20L​",
  "21L-50L​",
  "50L-100L",
  "100L &Above​",
];
// Laptops
export const LaptopBrand = [
  "IBM",
  "Lenovo",
  "Hp",
  "Dell",
  "Asus",
  "Panasonic",
  "Samsung",
  "Realmi",
  "Zenith",
  "Sharp",
  "Others",
];
export const ProductType = ["Desktop", "Laptop"];
export const LaptopCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Accessories
export const PeripheralsType = [
  "Battery",
  "Head Phones",
  "Mouse",
  "Speaker",
  "Usb Drive",
  "Access Point",
  "CPU",
  "Cooling pad",
  "Data Card",
  "Headset",
  "Keyboard",
  "Network Card",
  "Printer",
  "Ram​",
  "Router​",
  "Scanner​",
  "Webcam​",
  "USB Wireless Adaptor​",
  "Others",
];

export const PeripheralCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Water Purifiers
export const WPBrand = [
  "Havells",
  "Aquaguard",
  "kent",
  "Livpure",
  "Others",
  "Panasonic",
  "Bala",
  "LG",
  "Blue Me",
  "panasonic",
];
export const WPCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

//Microwave Ovens
export const MOvenBrand = ["LG", "Onida", "Panasonic", "Samsung", "Others"];
export const MOvenType = ["Convection", "Grill", "Solo"];
export const MOvenCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];
export const MOvenCapacity = [
  "Upto 20L​",
  "21L-25L​​",
  "21L-50L​",
  ".26L-30L​",
  "31L & Above​​",
];

// Mixer / Grinder / Juicer
export const GrinderBrand = [
  "Activa",
  "Asian",
  "Aditi",
  "Amazing Bullet",
  "Ameet",
  "Anjani",
  "Apex",
  "Arise",
  "BEC",
  "BTL",
  "Bala",
  "Blue me",
  "Bluesky",
  "Bonzeal",
  "Boss",
  "Borosil",
  "Butterfly",
  "Cpex",
  "Others",
];

export const GrinderCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Induction Cook Tops
export const InductionBrand = [
  "Greenchef",
  "Havells",
  "Hindware",
  "Hotstar",
  "Hilex",
  "Jaipan",
  "Jindal",
  "Kenwood",
  "Longer",
  "Nova",
  "Orbit",
  "Orbon",
  "Oster",
  "Ovastar",
  "Phillips",
  "pigeon",
  "Others",
];

export const InductionCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Gas Stove​
export const GasStoveBrand = [
  "Skyline",
  "Usha",
  "Skyflame",
  "Wonder World",
  "Wonder Chef",
  "Surya",
  "Quba",
  "Prestige",
  "Apex",
  "Longer",
  "Nova",
  "Orbit",
  "Orbon",
  "oster",
  "Ovastar",
  "Other",
  "Phillips",
  "pigeon",
  "Others",
];

export const GasStoveCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Cameras – Digicams or Digital SLR OR Digital Point & Shoot Cameras
export const CameraBrand = [
  "Canon",
  "Nikon",
  "Panasonic",
  "Samsung",
  "Sony",
  "Olympus",
  "Flip",
  "Casio",
  "Leica",
  "Others",
];

export const CameraProductType = [
  "Camcorders",
  "Binoculars",
  "Telescopes",
  "Digital SLR",
  "Film Cameras",
];
export const CameraCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

//Camera Accessories
export const CamAccessories = [
  "Battery Charger",
  "Camera Bag",
  "Filter",
  "Lens",
  "Lens Cleaner",
  "Flash",
  "Lens Cap",
  "Memory Card",
  "Strap",
  "Others",
];
export const CamAccessoriesCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// TV
export const TvDisplayType = [
  "CRT",
  "Liquid Crystal Display (LCD)",
  "Plasma Display Panels",
  "Organic Light-Emitting Diode (OLED) ",
  "Quantum Light Emitting Diode (QLED)",
  "4K TVs",
  "8K TVs",
  "Flat TVs",
  "Curved TVs",
];
export const TVCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];
export const TVBrand = [
  "LG",
  "Phillips",
  "videocon",
  "Samsung",
  "Onida",
  "Panasonic",
  "Sony",
  "BPL",
  "BenQ",
  "Others",
];
export const ScreenSize = [
  "Upto 23inch",
  "24inch-31inch",
  "32inch-39inch",
  "40icnh-47inch",
  "48inch-54inch",
  "55inch&Above",
];
export const Resolution = [
  "Full HD",
  "HD Ready",
  "Standard Definition(SD)",
  "Ultra HD(4K)",
  "WXGA",
];
export const HDMIPorts = ["1", "2", "3", "4 and Above"];

// Video games
export const VGBrand = [
  "Microsoft Xbox",
  "Nintendo Game boy",
  "Nintendo wii",
  "PC games",
  "SonyPSP",
  "Sony Play Statio",
  "Nokia N-gage",
  "others",
];
export const VGCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// music system-home theater
export const MSBrand = [
  "Canon",
  "Nikon",
  "Panasonic",
  "Samsung",
  "Sony",
  "Olympus",
  "Flip ",
  "Casio",
  "Kodak",
  "Leica",
  "Others",
];
export const SpeakerType = [
  "Bluetooth Speakers",
  "Blueshelf speakers",
  "Home Theatre systems",
  "Outdoor Speakers",
  "Over Ear Head phones",
  "Sound bar speakers",
];

export const MSCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];
export const Workinghours = ["4 hours", "6 hours", "8 hours", "12 hours"];

export const subcategoryRealEstateBuy = [
  "Buy-Residential",
  "Rent-Residential",
  "PG-Hostel",
];

export const subcategoryRealEstateRent = ["Buy-Commercial", "Rent-Commercial"];

export const All = [
  "apartment",
  "field",
  "building",
  "home",
  "PG-Hostel",
  "flats",
  "rentals",
  "properties",
  "houses",
  "BHKType",
  "price",
  "hostels",
  "mensPg",
  "mensFlatMates",
  "ladiesPg",
  "ladiesFlatMates",
];

export const ProductCondition = ["Good", "Excellent", "Fair"];
export const NoOfBathrooms = ["1", "2", "3", "4"];
export const ListedBy = ["Indivisual", "Dealer"];
export const Facing = ["east", "west", "north", "south"];

export const RealEsateAllDetails = {
  BuildUpArea: ["1sqr", "2sqr"],
  Flor: [
    "1 Flor",
    "2Flor",
    "3Flor",
    "4Flor",
    "5Flor",
    "6Flor",
    "7Flor",
    ">7Flor",
  ],
  ApartMentType: ["1bhk", "2bhk", "3bhk"],
  Availability: ["readyToMove", "UnderConstruction", "Within_15days"],
  FurnishedType: ["FullFurnished", "Semi Furnished", "None"],
  Property: ["New", "<1year", "<2Year", "<3year+"],
  Parking: ["Bike", "Car"],

  PowerBackup: ["yes", "No"],
  Amenity: {
    Gym: ["yes", "No"],
    Garden: ["yes", "No"],
    Pool: ["yes", "No"],
    Lift: ["yes", "No"],
  },
};
// export const stateMain = [
//   "Andaman & Nicobar Islands",
//   "Andhra Pradesh",
//   "Arunachal Pradesh",
//   "Assam",
//   "Bihar",
//   "Chandigarh",
//   "Chhattisgarh",
//   "Dadra & Nagar Haveli",
//   "Daman and Diu",
//   "Delhi",
//   "Goa",
//   "Gujarat",
//   "Haryana",
//   "Himachal Pradesh",
//   "Jammu & Kashmir",
//   "Jharkhand",
//   "Karnataka",
//   "Kerala",
//   "Lakshadweep",
//   "Madhya Pradesh",
//   "Maharashtra",
//   "Manipur",
//   "Meghalaya",
//   "Mizoram",
//   "Nagaland",
//   "Odisha",
//   "Puducherry",
//   "Punjab",
//   "Rajasthan",
//   "Sikkim",
//   "Tamil Nadu",
//   "Telangana",
//   "Tripura",
//   "Uttarkhand",
//   "Uttar Pradesh",
//   "West Bengal",
// ];

//all state and city getting map from here

export const Electronics = [
  "Air Conditioners - AC",
  "Air Coolers​",
  "Camera Accessories",
  "Cameras",
  "Gas Stove",
  "Induction Cook Tops​",
  "Laptops Or (Desktops - Computers)",
  "Microwave Ovens​",
  "Mixer - Grinder - Juicer",
  "Monitor Or Printers Or Scanners",
  "Music Systems - Home Theatre",
  "Refrigerators - Fridge",
  "TV",
  "Video Games - Consoles",
  "Washing Machines",
  "Water Heaters - Geysers",
  "Water Purifiers",
];

// export const Electronics=['Refrigerators/Fridge',"Music Systems - Home Theatre","TV","Gas Stove","Video Games - Consoles", "Cameras ","Camera Accessories","Microwave Ovens​","Induction Cook Tops​" , "Mixer / Grinder / Juicer","Water Purifiers","Laptops or (Desktops / Computers)","Monitor or Printers Or Scanners","Washing Machines","Water Heaters / Geysers","Air Coolers​","Air Conditioners / AC",'Dsktop/Computers','Household Appliances','Cameras','Gaming','Audio & Video','Headphones','Watches','Computeraccessories','Pendrive','Hometheater','Projectoraccessories','Cabels','Settopbox','MediaPlayers']

export const Furniture = [
  "Furniture For Home & Office",
  "Home Decor - Furnishings",
  "Household",
  "Kitchenware",
  "Painting",
];

// before it has all those category -->>> export const Furniture=["Furniture for Home &Office","Home Decor - Furnishings",'Household','Kitchenware','Painting','OfficeFurnitures','Kids Furniture','HomeDecor','SeatingFurnitures','LivingRoomFurnitures','Benches','Dining','BedroomFurnitures','StorageFurnitures','BathroomFurnitures','OutdoorFurnitures','KitchenFurnitures',]

export const Gadgets = [
  "Datacabels",
  "Chargers",
  "Laptops",
  "Keyboards",
  "Replacement",
  "Speaker",
  "Lenskit",
  "Headsets",
  "Powerbank ",
  "Batteries",
];

export const HomeAndLifeStyle = [
  [
    "Appliances",
    "Cleaning",
    "HomeDecore",
    "HomeFurnishing",
    "Improvement",
    "Lightning",
    "OutdoorFurniture",
    "Storage",
  ],
];

export const Cars = ["Cars", "Spare Parts - Accessories"];
export const Bikes = ["Bikes", "Spare Parts - Accessories"];
export const Services = [
  "Appliance Repair",
  "Computer Repair",
  "Home Cleaning",
  "Home Help",
  "Home Repair",
  "Interior Designing & Home improvements",
  "Invertor & Batteries",
  "Kitchen Appliance Repair",
  "Mobile & Tablet Repair",
  "Packers and Movers",
  "Pest Control",
];

//Cars
// export const Cars = [
//   "Audi",
//   "Bentley",
//   "BMW",
//   "Chevrolet",
//   "Fiat",
//   "Ford",
//   "Daewoo",
//   "Hindustan Motor",
//   "Maruti Suzuk",
//   "Hyundai",
//   "Honda",
//   "Toyota",
//   "Tata",
//   "Mercedes Benz",
//   "Mahindra",
//   "Mitsubishi",
//   "Nissan",
//   "Opel",
//   "Rolls Royce",
//   "Volkswagen",
//   "Volvo",
//   "Renault",
//   "Aston Martin",
//   "Ferrari",
//   "Jaguar",
//   "Lamborghini",
//   "Land Rover",
//   "Maserati",
//   "Mini",
//   "Porsche",
//   "Ashok Leyland",
//   "Bugatti",
//   "Caterham",
//   "Datsun",
//   "DC",
//   "ICML",
//   "Isuzu",
//   "Premier",
//   "Force Motors",
//   "San",
//   "Ssangyong",
//   "Eicher Polaris",
//   "Jeep",
//   "Lexus",
//   "Kia",
//   "MG Motors",
// ];

export const CarFueltype = [
  "CNG",
  "Diesel",
  "Electric",
  "Hybrid",
  "LPG",
  "Petrol",
];
export const YearOfRegistrationCar = [
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
];
export const CarColor = [
  "Black",
  "Blue",
  "Golden",
  "Green",
  "Indigo",
  "Orange",
  "Red",
  "Silver",
  "Violet",
  "White",
  "Yellow",
];

export const Jobs = ["Full Time Jobs", "Contract Job", "Walk-In"];
export const Others = ["Type of services", "Contract Job", "Walk-In"];

export const Health = ["Private", "Government", "Semi-Government"];
export const Travels = ["Private", "Government", "Semi-Government"];
export const Photography = ["Camera", "MobilePhotography"];
export const Salon = ["Parlour"];
// export const Mobiles=["RealMe","iPhone","OPPO","Xaomi","POCO","SAMSUNG"]
export const Mobiles = ["Mobile Phones", "Accessories", "Tablets"];

// export const Others=["Office_Space","Shop","Co_working"]

export const freePlanDetails = [
  " 3 Regular Ads",
  "7 Days Limit",
  "Limited Response",
  "Unlimited Response",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const silverPlanDetails = [
  "5 silver Ads",
  "15 Days live",
  "30 days validity",
  "Unlimited Response",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const goldPlanDetails = [
  "10 Gold Ads",
  "30 Days live",
  "60 days validity",
  "Unlimited Response",
  // "No. of Top Ads : 5",
  // "No. of Featured Ads : 5",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const platinumPlanDetails = [
  "15 platinum Ads",
  "60 Days live",
  "90 days validity",
  "Unlimited Response",
  // "No. of Top Ads : 5",
  // "No. of Featured Ads : 5",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const customPlanDetails = [
 
  "Unlimited Response",
  // "No. of Top Ads : 5",
  // "No. of Featured Ads : 5",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const Free = {
  user: "",
  category: "Free",
  days: 7,
  regulars: 3,
  topAds: 0,
  featured: 0,
  teleSupport: 0,
  response: "Limited",
  chatSupport: 0,
  dedicatedRm: 0,
  hola9Website: 0,
};
export const Silver = {
  user: "",
  category: "Silver",
  days: 15,
  regulars: 5,
  topAds: 2,
  featured: 3,
  teleSupport: 1,
  response: "Unlimited",
  chatSupport: 0,
  dedicatedRm: 0,
  hola9Website: 0,
};
export const Gold = {
  user: "",
  category: "Gold",
  days: 30,
  regulars: 10,
  topAds: 5,
  featured: 5,
  teleSupport: 1,
  response: "Unlimited",
  chatSupport: 1,
  dedicatedRm: 1,
  hola9Website: 0,
};
export const Platinum = {
  user: "",
  category: "Platinum",
  days: 60,
  regulars: 10,
  topAds: 5,
  featured: 5,
  teleSupport: 1,
  response: "Unlimited",
  chatSupport: 1,
  dedicatedRm: 1,
  hola9Website: 1,
};

export const Category = [
  "RealEstate",
  "Furniture",
  "Pets",
  "Cars",
  "Bikes",
  "Electronics",
  "Services",
  "Jobs",
  "Mobiles",
  "Education",
];
export const Category1 = [
  "RealEstate",
];
//Education Category
export const Education = [
  "Career Counseling",
  "Certification & Training",
  "Competative Exams Coaching",
  "Distance Learning Courses",
  "Entrance Exam Coaching",
  "Hobby Classes",
  "Play Schools-Creche",
  "Schools & School Tuitions",
  "Study Abroad Consultants",
  "Text books & Study Material",
  "Vocational Skill Training",
];

//business plan
export const businessPrimiumAds = ["40","100 ","140", "150"];
export const businessPrimiumValidity = ["3","7","9","12"];
export const adsFilter = [
  { ads: "10", price:8},
  { ads: "20", price:10},
  { ads: "40", price:12},
  { ads: "50", price: 15 },
  { ads: "65", price:17 },
  { ads: "80", price:22},
  { ads: "100", price: 25 },
  { ads: "120", price:30 },
  { ads: "130", price:32},
  { ads: "150", price: 35 },
];
export const validateFilter = [
  { days: "1", price: 5 },
  { days: "2", price: 10 },
  { days: "3", price: 15 },
  { days: "4", price: 20 },
  { days: "5", price: 25 },
  { days: "6", price: 30 },
  { days: "7", price: 35 },
  { days: "8", price: 40 },
  { days: "9", price: 45 },
  { days: "10", price: 50 },
  { days: "12", price: 55 },
];
export const metroCity = [
  "Ahmedabad",
  "Bangalore",
  "Chennai",
  "Delhi",
  "Hyderabad",
  "Kolkata",
  "Mumbai",
  "Pune",
  "Surat",

];

export const color = ["red", "Green", "yellow"];
export const size = ["1kg", "2kg", "3kg"];

export const Year = ["hhhh", "2kg", "3kg"];
export const Engine = ["115cc", "125cc", "150cc", "180cc", "250cc"];

// subcategory

export const Pets = [
  "Pet Food",
  "Pet Shop",
  "Pet Clinics",
  "Pet Training & Grooming​​",
  "Pet Care - Accessories​",
];
export const oldPets = ["1Year", "2Year"];
export const PetCategory = ["dog", "cat", "cow"];
export const Breed = ["pavilean", "german"];
export const FurnitureSubCategory = [
  "Beds",
  "Chair&Seating",
  "Chests",
  "Desks",
  "Other",
];
export const educationSubCategory = [
  "Training",
  "Public Events & Activities",
  "School Visits& Academic Program",
  "College",
  "course",
];

//pets

// export const allSubcategory=[...Electronics,...Furniture,...Gadgets,...HomeAndLifeStyle,...Pets,...Education,...Mobiles,...All]
export const allSubcategory = [
  ...Electronics,
  ...Furniture,
  ...Pets,
  ...Mobiles,
  ...All,
];

export const Andhra_Pradesh = [
  "kurnool",
  "Visakhapatnam",
  "Vijayawada",
  "Guntur",
  "Nellore",
  "Kurnool",
  "Kakinada",
  "Rajahmundry",
  "Kadapa",
  "Tirupati",
  "Anantapuram",
  "Vizianagaram",
  "Eluru",
  "Nandyal",
  "Ongole",
  "Adoni",
  "	Madanapalle",
  "Machilipatnam",
  "Tenali",
  "Proddatur",
  "Chittoor",
  "Hindupur",
  "Srikakulam",
  "Bhimavaram",
  "Tadepalligudem",
  "Guntakal",
  "Dharmavaram",
  "Gudivada",
  "Narasaraopet",
  "Kadiri",
  "Tadipatri",
  "Mangalagiri",
  "Chilakaluripet",
];

//Education Category dropdowns

//01 "Career Counseling" 03 Competitive Exams Coaching
export const careerStream = [
  "Defense",
  "Administrative Services",
  "Engineering and Medical",
  "Teaching",
  "Other Staff",
];
export const deliveryMode = [
  "Classroom",
  "Correspondence",
  "Home Tution",
  "Online",
];
export const listedBy = ["Institutions", "Freelancers"];

//02  "Certification & Training"
export const careerDomain = [
  "Business Analytics Courses ",
  "Computer Certification Courses",
  "Computer Training Courses",
  "Design Courses",
  "Hardware Training Courses",
  "Management Training Courses",
  "Paramedical Courses",
  "Soft Skills Courses",
];
export const deliveryMode1 = ["Classroom", "Books & CD's", "Online"];

//03 "Distance Learning Courses"
export const programType = [
  "Postgraduate Distance Program(PG)",
  "Undergraduate Distance Program(UG)",
];
export const deliveryMode2 = [
  "Distance",
  "Correspondence",
  "Hybrid",
  "Online Only",
  "Regular",
  "Part Time",
];

//04  "Entrance Exam Coaching"
export const careerStream2 = [
  "Accounting & Finance",
  "Fashion and Design",
  "Engineering",
  "Law",
  "Management",
  "Medical",
  "Study Abroad",
  "Other Exam",
];
export const deliveryMode3 = [
  "Classroom",
  "Corresponence",
  "Home Tuition",
  "Online",
];

//06  "Hobby Classes"
export const hobby = [
  "Chocolate Making",
  "Cooking Class",
  "Dance Class",
  "Embroidery",
  "Foreign Language Classes",
  "Gardening",
  "Gift Wrapping & Decoration",
  "Guitar Classes",
  "Handwriting Classes",
  "Jewelery Making",
  "Knitting",
  "Music Class",
  "Painting Class",
  "Piano Classes",
  "Pottery Class",
  "Singing Class",
  "Other Hobbies",
];
export const eligibility = [
  "Kids Only",
  "Kids and Ladies",
  "Ladies Only",
  "Open for all",
];
export const classesHeldOn = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const classTimings = ["Morning", "Afternoon", "Evening"];

//07 "Play Schools-Creche"
export const programsOffered = [
  "After School Care",
  "Full Time Day Care",
  "Lower Kindergarten",
  "Nursery",
  "Part time Day care",
  "Play group with parent",
  "Pre Nursery",
  "Upper Kindergarten",
];
export const minAge = [
  "5years",
  "10years",
  "15years",
  "20years",
  "25years",
  "30years",
  "35years",
  "40years",
  "45years",
  "50years",
  "55years",
  "60years",
];
export const maxAge = [
  "10years",
  "15years",
  "20years",
  "25years",
  "30years",
  "35years",
  "40years",
  "45years",
  "50years",
  "55years",
  "60years",
];
export const educationMethod = [
  "Integrated",
  "Montessori",
  "Multiple Intelligence",
  "Play way",
  "Reggio Emilia",
  "Waldorf Steiner",
];

export const studentTeacherRacFat = [
  "Meals & Snacks",
  "Medical Assistance",
  "Transportation  Facility",
  "Toilet Training",
];

//08  "Schools & School Tuitions"
export const schoolBoard = [
  "CBSE",
  "ICSE",
  "IGCSE",
  "ISC",
  "STATE BOARD",
  "INTERNATIONAL BACCALAUREATE",
];

export const subjects = [
  "Accounting",
  "Biology",
  "Business studies",
  "Chemistry",
  "Commerce",
  "Computer Science",
  "Economics",
  "English Grammar",
  "English Literature",
  "Geography",
  "Hindi",
  "History",
  "Maths",
  "Physics",
  "Political science",
  "Regional Indian Languages",
  "Sociology",
  "Other Subjects",
];

export const standard = ["1-5th", "5-7th", "7-10th", "1st PU", "2nd PU"];

export const deliveryMode5 = [
  "Classroom",
  "Correspondence",
  "Home tuition",
  "Online",
];

//09  "Study Abroad Consultants"
export const country = [
  "Australia",
  "Canada",
  "Europe",
  "New Zealand",
  "Singapore",
  "UK",
  "USA",
  "Others",
];

export const servicesOffered = [
  "Accommodation Assistance",
  "College Admission Assistance",
  "Entrance Examination Assistance",
  "Student Loan Assistance",
  "Student Visa Assistance",
  "Travel Assistance",
];

export const educationStream = [
  "Design",
  "Engineering",
  "MBA",
  "Medical",
  "Others",
];

//10  "Text books & Study Material"
export const productType1 = ["Book", "Calculator", "Study Material", "Others"];

//11 "Vocational Skill Training"
export const industry = [
  "Aviation",
  "Computer & IT",
  "Electronics& Appliances Repair",
  "Health & Beauty",
  "Industrial Training",
  "Medical",
  "Other Industries",
];

export const keyFeatures = [
  "Apprenticeship Support",
  "Job Placement Support",
  "(PMKVYA) Pradhan Mantri Kaushal Vikas Yojana Authorized",
];

// Jobpost gorm  subcategorey details
export const MinExp = [ "Select", "6month - 1 year", "1year - 1.5year", "1.5year - 2year"];

export const MaxExp = [
  "Select",
  "1.5 years to 2 years",
  "2 years to 2.5 years",
  "2.5 years to 3 years",
  "3 years to 3.5 years",
  "3.5 years to 4 years",
  "4 years to 4.5 years",
  "4.5 years to 5 years",
  "5 years to 5.5 years",
  "5.5 years to 6 years",
  "6 years to 6.5 years",
  "6.5 years to 7 years",
  "7 years to 7.5 years",
  "7.5 years to 8 years",
  "8 years to 8.5 years",
  "8.5 years to 9 years",
  "9 years to 9.5 years",
  "9.5 years to 10 years",
  "10 years to 10.5 years",
  "10.5 years to 11 years",
  "11 years to 11.5 years",
  "11.5 years to 12 years",
  "12 years to 12.5 years",
  "12.5 years to 13 years",
  "13 years to 13.5 years",
  "13.5 years to 14 years",
  "14 years to 14.5 years",
  "14.5 years to 15 years",
  "15 years to 15.5 years",
  "15.5 years to 16 years",
  "16 years to 16.5 years",
  "16.5 years to 17 years",
  "17 years to 17.5 years",
  "17.5 years to 18 years",
  "18 years to 18.5 years",
  "18.5 years to 19 years",
  "19 years to 19.5 years",
  "19.5 years to 20 years",
  "20 years to 20.5 years",
  "20.5 years to 21 years",
  "21 years to 21.5 years",
  "21.5 years to 22 years",
  "22 years to 22.5 years",
  "22.5 years to 23 years",
  "23 years to 23.5 years",
  "23.5 years to 24 years",
  "24 years to 24.5 years",
  "24.5 years to 25 years",
  "25 years to 25.5 years",
  "25.5 years to 26 years",
  "26 years to 26.5 years",
  "26.5 years to 27 years",
  "27 years to 27.5 years",
  "27.5 years to 28 years",
  "28 years to 28.5 years",
  "28.5 years to 29 years",
  "29 years to 29.5 years",
  "29.5 years to 30 years",
];

export const Industry1 = [
  "Select",
  "Animation",
  "Architecture/Interior Design",
  "Automotive/Automobile/Ancillaries",
  "Banking/Accounting/Financial Services",
  "Bio Technology & Life Sciences",
  "Chemicals/Petrochemicals",
  "Construction & Engineering",
  "Electronics Manufacturing",
  "Engineering & Design",
  "Escalators/Elevators",
  "Fashion/Apparels",
  "FMCG",
  "Education",
  "Entertainment/Media/Publishing",
  "Insurance",
  "ITES/BPO",
  "IT/Computers - Hardware & Networking",
  "IT/Computers - Software",
  "KPO/Research/Analytics",
  "Legal/Law Firm",
  "Machinery/Equipment Mfg.",
  "Oil/Gas/Petroleum",
  "Pharmaceutical",
  "Plastic/Rubber",
  "Power/Energy",
  "Railways Specialty/Infrastructure",
  "Real Estate",
  "Recruitment/Staffing/RPO",
  "Retailing",
  "Telecom",
  "Advertising/PR/Events",
  "Agriculture/Dairy/Forestry/Fishing",
  "Airlines/Aviation/Aerospace",
  "Wellness/Fitness/Sports",
  "Beverages/Liquor",
  "Cement/Concrete/Readymix",
  "Ceramics & Sanitary Ware",
  "Consulting/Advisory Services",
  "Courier/Freight/Transportation",
  "Internet/E-commerce",
  "E-Learning",
  "Electrical/Switchgears",
  "Engineering/Procurement/Construction",
  "Environmental Service",
  "Facility management",
  "Fertilizer/Pesticides",
  "Food & Packaged Food",
  "Textiles/Yarn/Fabrics/Garments",
  "Gems & Jewellery",
  "GLASS",
  "Government/PSU/Defence",
  "Consumer Electronics/Durables/Appliances",
  "Hospitals/Healthcare/Diagnostics",
  "Heat Ventilation Air Conditioning (HVAC)",
  "Hotels/Hospitality/Restaurant",
  "General Trading/Import/Export",
  "Iron/Steel",
  "ISP",
  "Law Enforcement/Security Ser,vices",
  "Leather",
  "Market Research",
  "Medical Transcription",
  "Mining",
  "NGO/Social Services",
  "Non-Ferrous Metals (Aluminium/Zinc etc.)",
  "Office Equipment/Automation",
  "Paints",
  "Paper",
  "Printing/Packaging",
  "Public Relations (PR)",
  "Semiconductor",
  "Shipping/Marine Services",
  "Social Media",
  "Sugar",
  "Travel/Tourism",
  "Tyres",
  "Wood",
  "Other",
  "Any",
];

export const Education1 = [
  "Select",
  "Bachelor of Arts (B.A)",
  "Bachelor of Architecture (B.Arch)",
  "Bachelor of Business Administration (B.B.A)",
  "Bachelor Of Computer Application (B.C.A)",
  "Bachelor of Commerce (B.Com)",
  "Bachelor of Dental Surgery (B.D.S)",
  "Bachelor Of Technology (B.Tech/B.E)",
  "Bachelor of Education (B.Ed)",
  "Bachelor of Ayurvedic Medicine and Surgery (BAMS)",
  "Bachelor in Hotel Management (B.H.M)",
  "Bachelor of Homeopathic Medicine and Surgery (BHMS)",
  "Bachelors of Law (B.L/L.L.B)",
  "Bachelor Of Pharmacy (B.Pharm)",
  "Bachelor of Science (B.Sc)",
  "Bachelor of Social Work (B.S.W)",
  "Chartered Accountancy (C.A)",
  "Chartered Accountancy Inter (C.A Inter)",
  "12th Class (XII)",
  "Company Secretary",
  "Doctor of Social Work (D.S.W)",
  "Diploma",
  "ICWA",
  "ICWA Inter",
  "Masters in Arts (M.A)",
  "Masters of Architecture (M.Arch)",
  "Master OF Business Administration (M.B.A)",
  "MBBS",
  "Master in Computer Application (M.C.A)",
  "Master of Commerce (M.Com)",
  "Doctor of Medicine (M.D/M.S)",
  "Master of Education (M.Ed)",
  "Masters in Technology (M.Tech/M.E/M.Sc)",
  "Master of Law (M.L/L.L.M)",
  "Master of Pharmacy (M.Pharm)",
  "Master of Philosophy (M.Phil)",
  "Master of Social Work (M.S.W)",
  "Post Graduate Diploma in Computer Applications (PGDCA)",
  "PGDM",
  "Post Graduate Programme in Management for Executives",
  "Phd",
  "Other",
];

export const Nationality = [
  "Albanian",
  "Algerian",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australian",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahraini",
  "Bangladeshi",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canadian",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "Chinese",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egyptian",
  "El Salvador",
  "Emirati/UAE",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "French",
  "French Guiana",
  "French Polynesia",
  "Wallis and Futuna",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "German",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Indian",
  "Indonesian",
  "Vietnamese",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Iranian",
  "Iraqi",
  "Ireland",
  "Israel",
  "Italian",
  "Jamaica",
  "Japan",
  "Jordanian",
  "Kazakhstan",
  "Kenyan",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwaiti",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanese",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Western Sahara",
  "Yemeni",
  "Zambia",
  "Zimbabwe",
  "Macedonia, the Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysian",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Moroccan",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepalese",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigerian",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Omani",
  "Pakistani",
  "Palau",
  "Palestinian Territory",
  "Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines/Filipino",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatari",
  "Reunion",
  "Romania",
  "Russian",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabian",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singaporean",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South African",
  "South Georgia and the South Sandwich Islands",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian",
  "Taiwanese",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thai",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
];

export const Annualsalary = ["Select", "Permanent Full Time", "Permanent Part Time"];

export const PermanentFullTime = [
  "Select",
  "0.0 - 0.5 Lakh",
  "0.5 - 1 Lakh",
  "1 - 2 Lakh",
  "2 - 3 Lakh",
  "3 - 4 Lakh",
  "4 - 5 Lakh",
  "5 - 6 Lakh",
  "6 - 7 Lakh",
  "7 - 8 Lakh",
  "8 - 9 Lakh",
  "9 - 10 Lakh",
  "10 - 11 Lakh",
  "11 - 12 Lakh",
  "12 - 13 Lakh",
  "13 - 14 Lakh",
  "14 - 15 Lakh",
  "15 - 16 Lakh",
  "16 - 17 Lakh",
  "17 - 18 Lakh",
  "18 - 19 Lakh",
  "19 - 20 Lakh",
  "20 - 21 Lakh",
  "21 - 22 Lakh",
  "22 - 23 Lakh",
  "23 - 24 Lakh",
  "24 - 25 Lakh",
  "25 - 26 Lakh",
  "26 - 27 Lakh",
  "27 - 28 Lakh",
  "28 - 29 Lakh",
  "29 - 30 Lakh",
  "30 - 31 Lakh",
  "31 - 32 Lakh",
  "32 - 33 Lakh",
  "33 - 34 Lakh",
  "34 - 35 Lakh",
  "35 - 36 Lakh",
  "36 - 37 Lakh",
  "37 - 38 Lakh",
  "38 - 39 Lakh",
  "39 - 40 Lakh",
  "40 - 41 Lakh",
  "41 - 42 Lakh",
  "42 - 43 Lakh",
  "43 - 44 Lakh",
  "44 - 45 Lakh",
  "45 - 46 Lakh",
  "46 - 47 Lakh",
  "47 - 48 Lakh",
  "48 - 49 Lakh",
  "49 - 50 Lakh",
];

export const PermanentPartTime = [
  // "0.0 - 0.5 Lakh",
  "Select",
  "0.5 - 1 Lakh",
  "1 - 2 Lakh",
  "2 - 3 Lakh",
  "3 - 4 Lakh",
  "4 - 5 Lakh",
  "5 - 6 Lakh",
  "6 - 7 Lakh",
  "7 - 8 Lakh",
  "8 - 9 Lakh",
  "9 - 10 Lakh",
  "10 - 11 Lakh",
  "11 - 12 Lakh",
  "12 - 13 Lakh",
  "13 - 14 Lakh",
  "14 - 15 Lakh",
  "15 - 16 Lakh",
  "16 - 17 Lakh",
  "17 - 18 Lakh",
  "18 - 19 Lakh",
  "19 - 20 Lakh",
  "20 - 21 Lakh",
  "21 - 22 Lakh",
  "22 - 23 Lakh",
  "23 - 24 Lakh",
  "24 - 25 Lakh",
  "25 - 26 Lakh",
  "26 - 27 Lakh",
  "27 - 28 Lakh",
  "28 - 29 Lakh",
  "29 - 30 Lakh",
  "30 - 31 Lakh",
  "31 - 32 Lakh",
  "32 - 33 Lakh",
  "33 - 34 Lakh",
  "34 - 35 Lakh",
  "35 - 36 Lakh",
  "36 - 37 Lakh",
  "37 - 38 Lakh",
  "38 - 39 Lakh",
  "39 - 40 Lakh",
  "40 - 41 Lakh",
  "41 - 42 Lakh",
  "42 - 43 Lakh",
  "43 - 44 Lakh",
  "44 - 45 Lakh",
  "45 - 46 Lakh",
  "46 - 47 Lakh",
  "47 - 48 Lakh",
  "48 - 49 Lakh",
  "49 - 50 Lakh",
];

export const SelectTenure = [
  "Select",
  "Upto 6 Month",
  "06 - 12 Month",
  "Above 12 Month",
  "Contract To Hire",
];

export const CityMain = [
  "Select",
  "Andaman and Nicobar Islands:",
  "Bamboo Flat",
  "Nicobar",
  "Port Blair",
  "South Andaman",

  "Andhra Pradesh:",
  "Addanki",
  "Adoni",
  "Akasahebpet",
  "Akividu",
  "Akkarampalle",
  "Amalapuram",
  "Amudalavalasa",
  "Anakapalle",
  "Anantapur",
  "Atmakur",
  "Attili",
  "Avanigadda",
  "Badvel",
  "Banganapalle",
  "Bapatla",
  "Betamcherla",
  "Bhattiprolu",
  "Bhimavaram",
  "Bhimunipatnam",
  "Bobbili",
  "Challapalle",
  "Chemmumiahpet",
  "Chilakalurupet",
  "Chinnachowk",
  "Chipurupalle",
  "Chirala",
  "Chittoor",
  "Chodavaram",
  "Cuddapah",
  "Cumbum",
  "Darsi",
  "Dharmavaram",
  "Dhone",
  "Diguvametta",
  "East Godavari",
  "Elamanchili",
  "Ellore",
  "Emmiganur",
  "Erraguntla",
  "Etikoppaka",
  "Gajuwaka",
  "Ganguvada",
  "Gannavaram",
  "Giddalur",
  "Gokavaram",
  "Gorantla",
  "Govindapuram,Chilakaluripet,Guntur",
  "Gudivada",
  "Gudlavalleru",
  "Gudur",
  "Guntakal Junction",
  "Guntur",
  "Hindupur",
  "Ichchapuram",
  "Jaggayyapeta",
  "Jammalamadugu",
  "Kadiri",
  "Kaikalur",
  "Kakinada",
  "Kalyandurg",
  "Kamalapuram",
  "Kandukur",
  "Kanigiri",
  "Kankipadu",
  "Kanuru",
  "Kavali",
  "Kolanukonda",
  "Kondapalle",
  "Korukollu",
  "Kosigi",
  "Kovvur",
  "Krishna",
  "Kuppam",
  "Kurnool",
  "Macherla",
  "Machilipatnam",
  "Madanapalle",
  "Madugula",
  "Mandapeta",
  "Mandasa",
  "Mangalagiri",
  "Markapur",
  "Nagari",
  "Nagireddipalli",
  "Nandigama",
  "Nandikotkur",
  "Nandyal",
  "Narasannapeta",
  "Narasapur",
  "Narasaraopet",
  "Narasingapuram",
  "Narayanavanam",
  "Narsipatnam",
  "Nayudupet",
  "Nellore",
  "Nidadavole",
  "Nuzvid",
  "Ongole",
  "Pakala",
  "Palakollu",
  "Palasa",
  "Palkonda",
  "Pallevada",
  "Palmaner",
  "Parlakimidi",
  "Parvatipuram",
  "Pavuluru",
  "Pedana",
  "pedda nakkalapalem",
  "Peddapuram",
  "Penugonda",
  "Penukonda",
  "Phirangipuram",
  "Pippara",
  "Pithapuram",
  "Polavaram",
  "Ponnur",
  "Ponnuru",
  "Prakasam",
  "Proddatur",
  "Pulivendla",
  "Punganuru",
  "Puttaparthi",
  "Puttur",
  "Rajahmundry",
  "Ramachandrapuram",
  "Ramanayyapeta",
  "Ramapuram",
  "Rampachodavaram",
  "Rayachoti",
  "Rayadrug",
  "Razam",
  "Razampeta",
  "Razole",
  "Renigunta",
  "Repalle",
  "Salur",
  "Samalkot",
  "Sattenapalle",
  "Singarayakonda",
  "Sompeta",
  "Srikakulam",
  "Srisailain",
  "Suluru",
  "Tadepalle",
  "Tadepallegudem",
  "Tadpatri",
  "Tanuku",
  "Tekkali",
  "Tirumala",
  "Tirupati",
  "Tuni",
  "Uravakonda",
  "vadlamuru",
  "Vadlapudi",
  "Venkatagiri",
  "Vepagunta",
  "Vetapalem",
  "Vijayawada",
  "Vinukonda",
  "Visakhapatnam",
  "Vizianagaram",
  "Vizianagaram District",
  "Vuyyuru",
  "West Godavari",
  "Yanam",
  "Yanamalakuduru",
  "Yarada",

  "Arunachal Pradesh:",
  "Along",
  "Anjaw",
  "Basar",
  "Bomdila",
  "Changlang",
  "Dibang Valley",
  "East Kameng",
  "East Siang",
  "Hayuliang",
  "Itanagar",
  "Khonsa",
  "Kurung Kumey",
  "Lohit District",
  "Lower Dibang Valley",
  "Lower Subansiri",
  "Margherita",
  "Naharlagun",
  "Pasighat",
  "Tawang",
  "Tezu",
  "Tirap",
  "Upper Siang",
  "Upper Subansiri",
  "West Kameng",
  "West Siang",
  "Ziro",

  "Assam:",
  "Abhayapuri",
  "Amguri",
  "Badarpur",
  "Baksa",
  "Barpathar",
  "Barpeta",
  "Barpeta Road",
  "Basugaon",
  "Bihpuriagaon",
  "Bijni",
  "Bilasipara",
  "Bokajan",
  "Bokakhat",
  "Bongaigaon",
  "Cachar",
  "Chabua",
  "Chapar",
  "Chirang",
  "Darrang",
  "Dergaon",
  "Dhekiajuli",
  "Dhemaji",
  "Dhing",
  "Dhubri",
  "Dibrugarh",
  "Digboi",
  "Dima Hasao District",
  "Diphu",
  "Dispur",
  "Duliagaon",
  "Dum Duma",
  "Gauripur",
  "Goalpara",
  "Gohpur",
  "Golaghat",
  "Golakganj",
  "Goshaingaon",
  "Guwahati",
  "Haflong",
  "Hailakandi",
  "Hajo",
  "Hojai",
  "Howli",
  "Jogighopa",
  "Jorhat",
  "Kamrup",
  "Kamrup Metropolitan",
  "Karbi Anglong",
  "Karimganj",
  "Kharupatia",
  "Kokrajhar",
  "Lakhimpur",
  "Lakhipur",
  "Lala",
  "Lumding Railway Colony",
  "Mahur",
  "Maibong",
  "Makum",
  "Mangaldai",
  "Mariani",
  "Moranha",
  "Morigaon",
  "Nagaon",
  "Nahorkatiya",
  "Nalbari",
  "Namrup",
  "Nazira",
  "North Guwahati",
  "North Lakhimpur",
  "Numaligarh",
  "Palasbari",
  "Raha",
  "Rangapara",
  "Rangia",
  "Sapatgram",
  "Sarupathar",
  "Sibsagar",
  "Silapathar",
  "Silchar",
  "Soalkuchi",
  "Sonari",
  "Sonitpur",
  "Sorbhog",
  "Tezpur",
  "Tinsukia",
  "Titabar",
  "Udalguri",

  "Bihar:",
  "Amarpur",
  "Araria",
  "Arrah",
  "Arwal",
  "Asarganj",
  "Aurangabad",
  "Bagaha",
  "Bahadurganj",
  "Bairagnia",
  "Baisi",
  "Bakhtiyarpur",
  "Bangaon",
  "Banka",
  "Banmankhi",
  "Bar Bigha",
  "Barauli",
  "Barh",
  "Barhiya",
  "Bariarpur",
  "Baruni",
  "Begusarai",
  "Belsand",
  "Bettiah",
  "Bhabhua",
  "Bhagalpur",
  "Bhagirathpur",
  "Bhawanipur",
  "Bhojpur",
  "Bihar Sharif",
  "Bihariganj",
  "Bikramganj",
  "Birpur",
  "Buddh Gaya",
  "Buxar",
  "Chakia",
  "Chapra",
  "Chhatapur",
  "Colgong",
  "Dalsingh Sarai",
  "Darbhanga",
  "Daudnagar",
  "Dehri",
  "Dhaka",
  "Dighwara",
  "Dinapore",
  "Dumra",
  "Dumraon",
  "Fatwa",
  "Forbesganj",
  "Gaya",
  "Ghoga",
  "Gopalganj",
  "Hajipur",
  "Hilsa",
  "Hisua",
  "Islampur",
  "Jagdispur",
  "Jahanabad",
  "Jamalpur",
  "Jamui",
  "Jaynagar",
  "Jehanabad",
  "Jha-Jha",
  "Jhanjharpur",
  "Jogbani",
  "Kaimur District",
  "Kasba",
  "Katihar",
  "Khagaria",
  "Khagaul",
  "Kharagpur",
  "Khusropur",
  "Kishanganj",
  "Koath",
  "Koelwar",
  "Lakhisarai",
  "Lalganj",
  "Luckeesarai",
  "Madhepura",
  "Madhipura",
  "Madhubani",
  "Maharajgani",
  "Mairwa",
  "Maner",
  "Manihari",
  "Marhaura",
  "Masaurhi Buzurg",
  "Mohiuddinnagar",
  "Mokameh",
  "Monghyr",
  "Mothihari",
  "Munger",
  "Murliganj",
  "Muzaffarpur",
  "Nabinagar",
  "Nalanda",
  "Nasriganj",
  "Naugachhia",
  "Nawada",
  "Nirmali",
  "Pashchim Champaran",
  "Patna",
  "Piro",
  "Pupri",
  "Purba Champaran",
  "Purnia",
  "Rafiganj",
  "Raghunathpur",
  "Rajgir",
  "Ramnagar",
  "Raxaul",
  "Revelganj",
  "Rohtas",
  "Rusera",
  "Sagauli",
  "Saharsa",
  "Samastipur",
  "Saran",
  "Shahbazpur",
  "Shahpur",
  "Sheikhpura",
  "Sheohar",
  "Sherghati",
  "Silao",
  "Sitamarhi",
  "Siwan",
  "Supaul",
  "Teghra",
  "Tekari",
  "Thakurganj",
  "Vaishali",
  "Waris Aliganj",

  "Chandigarh:",
  "Chandigarh",

  "Dadra and Nagar Haveli:",
  "Amli",
  "Dadra",
  "Dadra & Nagar Haveli",
  "Silvassa",

  "Daman and Diu:",
  "Daman",
  "Daman District",
  "Diu",

  "Delhi:",
  "Alipur",
  "Bawana",
  "Central Delhi",
  "Delhi",
  "Deoli",
  "East Delhi",
  "Karol Bagh",
  "Najafgarh",
  "Nangloi Jat",
  "Narela",
  "New Delhi",
  "North Delhi",
  "North East Delhi",
  "North West Delhi",
  "Pitampura",
  "Rohini",
  "South Delhi",
  "South West Delhi",
  "West Delhi",

  "Goa:",
  "Aldona",
  "Arambol",
  "Baga",
  "Bambolim",
  "Bandora",
  "Benaulim",
  "Calangute",
  "Candolim",
  "Carapur",
  "Cavelossim",
  "Chicalim",
  "Chinchinim",
  "Colovale",
  "Colva",
  "Cortalim",
  "Cuncolim",
  "Curchorem",
  "Curti",
  "Davorlim",
  "Dicholi",
  "Goa Velha",
  "Guirim",
  "Jua",
  "Kankon",
  "Madgaon",
  "Mapuca",
  "Morjim",
  "Mormugao",
  "Navelim",
  "North Goa",
  "Palle",
  "Panaji",
  "Pernem",
  "Ponda",
  "Quepem",
  "Queula",
  "Raia",
  "Saligao",
  "Sancoale",
  "Sanguem",
  "Sanquelim",
  "Sanvordem",
  "Serula",
  "Solim",
  "South Goa",
  "Taleigao",
  "Vagator",
  "Valpoy",
  "Varca",
  "Vasco da Gama",

  "Gujarat:",
  "Abrama",
  "Adalaj",
  "Ahmedabad",
  "Ahwa",
  "Amod",
  "Amreli",
  "Amroli",
  "Anand",
  "Anjar",
  "Ankleshwar",
  "Babra",
  "Bagasara",
  "Bagasra",
  "Banas Kantha",
  "Bantva",
  "Bardoli",
  "Bedi",
  "Bhachau",
  "Bhanvad",
  "Bharuch",
  "Bhavnagar",
  "Bhayavadar",
  "Bhuj",
  "Bilimora",
  "Bilkha",
  "Borsad",
  "Botad",
  "Chaklasi",
  "Chalala",
  "Chanasma",
  "Chhala",
  "Chhota Udepur",
  "Chikhli",
  "Chotila",
  "Dabhoi",
  "Dahegam",
  "Dahod",
  "Dakor",
  "Damnagar",
  "Dangs (India)",
  "Dayapar",
  "Delvada",
  "Delwada",
  "Devbhumi Dwarka",
  "Devgadh Bariya",
  "Dhandhuka",
  "Dhanera",
  "Dharampur",
  "Dhari",
  "Dhola",
  "Dholka",
  "Dhoraji",
  "Dhrangadhra",
  "Dhrol",
  "Dhuwaran",
  "Disa",
  "Dohad",
  "Dungarpur",
  "Dwarka",
  "Gadhada",
  "Gandevi",
  "Gandhidham",
  "Gandhinagar",
  "Gariadhar",
  "Ghogha",
  "Gir Somnath",
  "Godhra",
  "Gondal",
  "Halol",
  "Halvad",
  "Hansot",
  "Harij",
  "Himatnagar",
  "Jalalpore",
  "Jalalpur",
  "Jambusar",
  "Jamnagar",
  "Jasdan",
  "Jetalsar",
  "Jetpur",
  "Jhulasan",
  "Jodhpur",
  "Jodia",
  "Jodiya Bandar",
  "Junagadh",
  "Kachchh",
  "Kadi",
  "Kadod",
  "Kalavad",
  "Kalol",
  "Kandla",
  "Kanodar",
  "Kapadvanj",
  "Karamsad",
  "Kathor",
  "Katpur",
  "Kavant",
  "Kawant",
  "Keshod",
  "Khambhalia",
  "Khambhat",
  "Kheda",
  "Khedbrahma",
  "Kheralu",
  "Kodinar",
  "Kosamba",
  "Kundla",
  "Kutch district",
  "Kutiyana",
  "Lakhtar",
  "Lalpur",
  "Lathi",
  "Limbdi",
  "Lunavada",
  "Mahemdavad",
  "Mahesana",
  "Mahudha",
  "Malpur",
  "Manavadar",
  "Mandal",
  "Mandvi",
  "Mandvi (Surat)",
  "Mangrol",
  "Mansa",
  "Meghraj",
  "Mehsana",
  "Mendarda",
  "Modasa",
  "Morbi",
  "Morva (Hadaf)",
  "Morwa",
  "Mundra",
  "Nadiad",
  "Naliya",
  "Narmada",
  "Naroda",
  "Navsari",
  "Okha",
  "Olpad",
  "Paddhari",
  "Padra",
  "Palanpur",
  "Palitana",
  "Paliyad",
  "Panch Mahals",
  "Panchmahal district",
  "Pardi",
  "Parnera",
  "Patan",
  "Pavi Jetpur",
  "Petlad",
  "Porbandar",
  "Radhanpur",
  "Rajkot",
  "Rajpipla",
  "Rajula",
  "Ranavav",
  "Rapar",
  "Roha",
  "Sabar Kantha",
  "Sachin",
  "Salaya",
  "Sanand",
  "Sankheda",
  "Sarkhej",
  "Savarkundla",
  "Sayla",
  "Shahpur",
  "Shivrajpur",
  "Siddhpur",
  "Sihor",
  "Sikka",
  "Sinor",
  "Sojitra",
  "Songadh",
  "Surat",
  "Surendranagar",
  "Talaja",
  "Tankara",
  "Tapi",
  "Than",
  "Thangadh",
  "Tharad",
  "Thasra",
  "The Dangs",
  "Umrala",
  "Umreth",
  "Un",
  "Una",
  "Unjha",
  "Upleta",
  "Utran",
  "Vadnagar",
  "Vadodara",
  "Vaghodia",
  "Vallabh Vidyanagar",
  "Vallabhipur",
  "Valsad",
  "Vansda",
  "Vapi",
  "Vartej",
  "Vasa",
  "Vaso",
  "Vejalpur",
  "Veraval",
  "Vijapur",
  "Vinchhiya",
  "Vinchia",
  "Virpur",
  "Visavadar",
  "Visnagar",
  "Vyara",
  "Wadhai",
  "Wadhwan",
  "Waghai",
  "Wankaner",

  "Haryana:",
  "Ambala",
  "Asandh",
  "Ateli Mandi",
  "Bahadurgarh",
  "Bara Uchana",
  "Barwala",
  "Bawal",
  "Beri Khas",
  "Bhiwani",
  "Bilaspur",
  "Buriya",
  "Charkhi Dadri",
  "Chhachhrauli",
  "Dabwali",
  "Dharuhera",
  "Ellenabad",
  "Faridabad",
  "Faridabad District",
  "Farrukhnagar",
  "Fatehabad",
  "Fatehabad District",
  "Firozpur Jhirka",
  "Gharaunda",
  "Gohana",
  "Gorakhpur",
  "Gurgaon",
  "Hansi",
  "Hasanpur",
  "Hisar",
  "Hodal",
  "Inda Chhoi",
  "Indri",
  "Jagadhri",
  "Jakhal",
  "Jhajjar",
  "Jind",
  "Kaithal",
  "Kalanaur",
  "Kalanwali",
  "Kanina Khas",
  "Karnal",
  "Kharkhauda",
  "Kheri Sampla",
  "Kurukshetra",
  "Ladwa",
  "Loharu",
  "Maham",
  "Mahendragarh",
  "Mandholi Kalan",
  "Mustafabad",
  "Narayangarh",
  "Narnaul",
  "Narnaund",
  "Narwana",
  "Nilokheri",
  "Nuh",
  "Palwal",
  "Panchkula",
  "Panipat",
  "Pataudi",
  "Pehowa",
  "Pinjaur",
  "Punahana",
  "Pundri",
  "Radaur",
  "Rania",
  "Ratia",
  "Rewari",
  "Rewari District",
  "Rohtak",
  "Safidon",
  "Samalkha",
  "Shadipur Julana",
  "Shahabad",
  "Sirsa",
  "Sohna",
  "Sonipat",
  "Taoru",
  "Thanesar",
  "Tohana",
  "Tosham",
  "Uklana",
  "Yamunanagar",

  "Himachal Pradesh:",
  "Arki",
  "Baddi",
  "Banjar",
  "Bilaspur",
  "Chamba",
  "Chaupal",
  "Chowari",
  "Chuari Khas",
  "Dagshai",
  "Dalhousie",
  "Daulatpur",
  "Dera Gopipur",
  "Dharamsala",
  "Gagret",
  "Ghumarwin",
  "Hamirpur",
  "Jawala Mukhi",
  "Jogindarnagar",
  "Jubbal",
  "Jutogh",
  "Kalka",
  "Kangar",
  "Kangra",
  "Kasauli",
  "Kinnaur",
  "Kotkhai",
  "Kotla",
  "Kulu",
  "Kyelang",
  "Lahul and Spiti",
  "Manali",
  "Mandi",
  "Nadaun",
  "Nagar",
  "Nagrota",
  "Nahan",
  "Nalagarh",
  "Palampur",
  "Pandoh",
  "Paonta Sahib",
  "Parwanoo",
  "Rajgarh",
  "Rampur",
  "Rohru",
  "Sabathu",
  "Santokhgarh",
  "Sarahan",
  "Sarka Ghat",
  "Seoni",
  "Shimla",
  "Sirmaur",
  "Solan",
  "Sundarnagar",
  "Theog",
  "Tira Sujanpur",
  "Una",
  "Yol",

  "Jammu and Kashmir:",
  "Akhnur",
  "Anantnag",
  "Awantipur",
  "Badgam",
  "Bandipore",
  "Bandipura",
  "Banihal",
  "Baramula",
  "Batoti",
  "Bhadarwah",
  "Bijbehara",
  "Bishnah",
  "Doda",
  "Gandarbal",
  "Ganderbal",
  "Gho Brahmanan de",
  "Hajan",
  "Hiranagar",
  "Jammu",
  "Jaurian",
  "Kathua",
  "Katra",
  "Khaur",
  "Kishtwar",
  "Kud",
  "Kulgam",
  "Kupwara",
  "Ladakh",
  "Magam",
  "Nawanshahr",
  "Noria",
  "Padam",
  "Pahlgam",
  "Parol",
  "Pattan",
  "Pulwama",
  "Punch",
  "Qazigund",
  "Rajaori",
  "Rajauri",
  "Ramban",
  "Ramgarh",
  "Ramnagar",
  "Riasi",
  "Samba",
  "Shupiyan",
  "Sopur",
  "Soyibug",
  "Srinagar",
  "Sumbal",
  "Thang",
  "Thanna Mandi",
  "Tral",
  "Tsrar Sharif",
  "Udhampur",
  "Uri",

  "Jharkhand:",
  "Bagra",
  "Barka Kana",
  "Barki Saria",
  "Barwadih",
  "Bhojudih",
  "Bokaro",
  "Bundu",
  "Chaibasa",
  "Chakradharpur",
  "Chakulia",
  "Chandil",
  "Chas",
  "Chatra",
  "Chiria",
  "Daltonganj",
  "Deogarh",
  "Dhanbad",
  "Dhanwar",
  "Dugda",
  "Dumka",
  "Garhwa",
  "Ghatsila",
  "Giridih",
  "Gobindpur",
  "Godda",
  "Gomoh",
  "Gopinathpur",
  "Gua",
  "Gumia",
  "Gumla",
  "Hazaribag",
  "Hazaribagh",
  "Hesla",
  "Husainabad",
  "Jagannathpur",
  "Jamadoba",
  "Jamshedpur",
  "Jamtara",
  "Jasidih",
  "Jharia",
  "Jugsalai",
  "Jumri Tilaiya",
  "Kalikapur",
  "Kandra",
  "Kanke",
  "Katras",
  "Kenduadih",
  "Kharsawan",
  "Khunti",
  "Kodarma",
  "Kuju",
  "Latehar",
  "Lohardaga",
  "Madhupur",
  "Malkera",
  "Manoharpur",
  "Mugma",
  "Mushabani",
  "Neturhat",
  "Nirsa",
  "Noamundi",
  "Pakur",
  "Palamu",
  "Pashchim Singhbhum",
  "patamda",
  "Pathardih",
  "Purba Singhbhum",
  "Ramgarh",
  "Ranchi",
  "Ray",
  "Sahibganj",
  "Saraikela",
  "Sarubera",
  "Sijua",
  "Simdega",
  "Sini",
  "Topchanchi",

  "Karnataka:",
  "Afzalpur",
  "Ajjampur",
  "Aland",
  "Alnavar",
  "Alur",
  "Anekal",
  "Ankola",
  "Annigeri",
  "Arkalgud",
  "Arsikere",
  "Athni",
  "Aurad",
  "Badami",
  "Bagalkot",
  "Bagepalli",
  "Bail-Hongal",
  "Ballari",
  "Banavar",
  "Bengaluru",
  "Bangarapet",
  "Bannur",
  "Bantval",
  "Basavakalyan",
  "Basavana Bagevadi",
  "Belgaum",
  "Bellary",
  "Belluru",
  "Beltangadi",
  "Belur",
  "Bhadravati",
  "Bhalki",
  "Bhatkal",
  "Bidar",
  "Bijapur",
  "Bilgi",
  "Birur",
  "Byadgi",
  "Byndoor",
  "Canacona",
  "Challakere",
  "Chamrajnagar",
  "Channagiri",
  "Channapatna",
  "Channarayapatna",
  "Chik Ballapur",
  "Chikkaballapur",
  "Chikmagalur",
  "Chiknayakanhalli",
  "Chikodi",
  "Chincholi",
  "Chintamani",
  "Chitapur",
  "Chitradurga",
  "Closepet",
  "Coondapoor",
  "Dakshina Kannada",
  "Dandeli",
  "Davanagere",
  "Devanhalli",
  "Dharwad",
  "Dod Ballapur",
  "French Rocks",
  "Gadag",
  "Gadag-Betageri",
  "Gajendragarh",
  "Gangawati",
  "Gangolli",
  "Gokak",
  "Gokarna",
  "Goribidnur",
  "Gorur",
  "Gubbi",
  "Gudibanda",
  "Gulbarga",
  "Guledagudda",
  "Gundlupēt",
  "Gurmatkal",
  "Hadagalli",
  "Haliyal",
  "Hampi",
  "Hangal",
  "Harihar",
  "Harpanahalli",
  "Hassan",
  "Haveri",
  "Heggadadevankote",
  "Hirekerur",
  "Hiriyur",
  "Holalkere",
  "Hole Narsipur",
  "Homnabad",
  "Honavar",
  "Honnali",
  "Hosanagara",
  "Hosangadi",
  "Hosdurga",
  "Hoskote",
  "Hospet",
  "Hubli",
  "Hukeri",
  "Hungund",
  "Hunsur",
  "Ilkal",
  "Indi",
  "Jagalur",
  "Jamkhandi",
  "Jevargi",
  "Kadur",
  "Kalghatgi",
  "Kampli",
  "Kankanhalli",
  "Karkala",
  "Karwar",
  "Kavalur",
  "Kerur",
  "Khanapur",
  "Kodagu",
  "Kodigenahalli",
  "Kodlipet",
  "Kolar",
  "Kollegal",
  "Konanur",
  "Konnur",
  "Koppa",
  "Koppal",
  "Koratagere",
  "Kotturu",
  "Krishnarajpet",
  "Kudachi",
  "Kudligi",
  "Kumsi",
  "Kumta",
  "Kundgol",
  "Kunigal",
  "Kurgunta",
  "Kushalnagar",
  "Kushtagi",
  "Lakshmeshwar",
  "Lingsugur",
  "Londa",
  "Maddagiri",
  "Maddur",
  "Madikeri",
  "Magadi",
  "Mahalingpur",
  "Malavalli",
  "Malpe",
  "Malur",
  "Mandya",
  "Mangalore",
  "Manipal",
  "Manvi",
  "Mayakonda",
  "Melukote",
  "Mudbidri",
  "Muddebihal",
  "Mudgal",
  "Mudgere",
  "Mudhol",
  "Mulbagal",
  "Mulgund",
  "Mulki",
  "Mundargi",
  "Mundgod",
  "Munirabad",
  "Murudeshwara",
  "Mysore",
  "Nagamangala",
  "Nanjangud",
  "Narasimharajapura",
  "Naregal",
  "Nargund",
  "Navalgund",
  "Nelamangala",
  "Nyamti",
  "Pangala",
  "Pavugada",
  "Piriyapatna",
  "Ponnampet",
  "Puttur",
  "Rabkavi",
  "Raichur",
  "Ramanagara",
  "Ranibennur",
  "Raybag",
  "Robertsonpet",
  "Ron",
  "Sadalgi",
  "Sagar",
  "Sakleshpur",
  "Sandur",
  "Sanivarsante",
  "Sankeshwar",
  "Sargur",
  "Saundatti",
  "Savanur",
  "Seram",
  "Shahabad",
  "Shahpur",
  "Shiggaon",
  "Shikarpur",
  "Shimoga",
  "Shirhatti",
  "Shorapur",
  "Shrirangapattana",
  "Siddapur",
  "Sidlaghatta",
  "Sindgi",
  "Sindhnur",
  "Sira",
  "Sirsi",
  "Siruguppa",
  "Someshwar",
  "Somvarpet",
  "Sorab",
  "Sravana Belgola",
  "Sringeri",
  "Srinivaspur",
  "Sulya",
  "Suntikoppa",
  "Talikota",
  "Tarikere",
  "Tekkalakote",
  "Terdal",
  "Tiptur",
  "Tirthahalli",
  "Tirumakudal Narsipur",
  "Tumkur",
  "Turuvekere",
  "Udupi",
  "Ullal",
  "Uttar Kannada",
  "Vadigenhalli",
  "Virarajendrapet",
  "Wadi",
  "Yadgir",
  "Yelahanka",
  "Yelandur",
  "Yelbarga",
  "Yellapur",

  "Kerala:",
  "Adur",
  "Alappuzha",
  "Aluva",
  "Alwaye",
  "Angamali",
  "Aroor",
  "Arukutti",
  "Attingal",
  "Avanoor",
  "Azhikkal",
  "Badagara",
  "Beypore",
  "Changanacheri",
  "Chēlakara",
  "Chengannur",
  "Cherpulassery",
  "Cherthala",
  "Chetwayi",
  "Chittur",
  "Cochin",
  "Dharmadam",
  "Edakkulam",
  "Elur",
  "Erattupetta",
  "Ernakulam",
  "Ferokh",
  "Guruvayur",
  "Idukki",
  "Iringal",
  "Irinjalakuda",
  "Kadakkavoor",
  "Kalamassery",
  "Kalavoor",
  "Kalpatta",
  "Kannangad",
  "Kannavam",
  "Kannur",
  "Kasaragod",
  "Kasaragod District",
  "Kattanam",
  "Kayankulam",
  "Kizhake Chalakudi",
  "Kodungallur",
  "Kollam",
  "Kotamangalam",
  "Kottayam",
  "Kovalam",
  "Kozhikode",
  "Kumbalam",
  "Kunnamangalam",
  "Kunnamkulam",
  "Kunnumma",
  "Kutiatodu",
  "Kuttampuzha",
  "Lalam",
  "Mahē",
  "Malappuram",
  "Manjeri",
  "Manjēshvar",
  "Mannarakkat",
  "Marayur",
  "Mattanur",
  "Mavelikara",
  "Mavoor",
  "Muluppilagadu",
  "Munnar",
  "Muvattupula",
  "Muvattupuzha",
  "Nadapuram",
  "Naduvannur",
  "Nedumangad",
  "Neyyattinkara",
  "Nilēshwar",
  "Ottappalam",
  "Palackattumala",
  "Palakkad district",
  "Palghat",
  "Panamaram",
  "Pappinisshēri",
  "Paravur Tekkumbhagam",
  "Pariyapuram",
  "Pathanamthitta",
  "Pattanamtitta",
  "Payyannur",
  "Perumbavoor",
  "Perumpavur",
  "Perya",
  "Piravam",
  "Ponmana",
  "Ponnani",
  "Punalur",
  "Ramamangalam",
  "Shertallai",
  "Shōranur",
  "Talipparamba",
  "Tellicherry",
  "Thanniyam",
  "Thiruvananthapuram",
  "Thrissur",
  "Thrissur District",
  "Tirur",
  "Tiruvalla",
  "Vaikam",
  "Varkala",
  "Vayalar",
  "Vettur",
  "Wayanad",

  "Ladakh:",
  "Kargil",
  "Leh",

  "Lakshadweep:",
  "Kavaratti",
  "Lakshadweep",

  "Madhya Pradesh:",
  "Agar",
  "Ajaigarh",
  "Akodia",
  "Alampur",
  "Alirajpur",
  "Alot",
  "Amanganj",
  "Amarkantak",
  "Amarpatan",
  "Amarwara",
  "Ambah",
  "Amla",
  "Anjad",
  "Antri",
  "Anuppur",
  "Aron",
  "Ashoknagar",
  "Ashta",
  "Babai",
  "Badarwas",
  "Badnawar",
  "Bag",
  "Bagli",
  "Baihar",
  "Baikunthpur",
  "Bakshwaho",
  "Balaghat",
  "Baldeogarh",
  "Bamna",
  "Bamor Kalan",
  "Bamora",
  "Banda",
  "Barela",
  "Barghat",
  "Bargi",
  "Barhi",
  "Barwani",
  "Basoda",
  "Begamganj",
  "Beohari",
  "Berasia",
  "Betma",
  "Betul",
  "Betul Bazar",
  "Bhabhra",
  "Bhainsdehi",
  "Bhander",
  "Bhanpura",
  "Bhawaniganj",
  "Bhikangaon",
  "Bhind",
  "Bhitarwar",
  "Bhopal",
  "Biaora",
  "Bijawar",
  "Bijrauni",
  "Bodri",
  "Burhanpur",
  "Burhar",
  "Chanderi",
  "Chandia",
  "Chandla",
  "Chhatarpur",
  "Chhindwara",
  "Chichli",
  "Chorhat",
  "Daboh",
  "Dabra",
  "Damoh",
  "Datia",
  "Deori Khas",
  "Depalpur",
  "Dewas",
  "Dhamnod",
  "Dhana",
  "Dhar",
  "Dharampuri",
  "Dindori",
  "Etawa",
  "Gadarwara",
  "Garha Brahman",
  "Garhakota",
  "Gautampura",
  "Ghansor",
  "Gogapur",
  "Gohadi",
  "Govindgarh",
  "Guna",
  "Gurh",
  "Gwalior",
  "Harda",
  "Harda Khas",
  "Harpalpur",
  "Harrai",
  "Harsud",
  "Hatod",
  "Hatta",
  "Hindoria",
  "Hoshangabad",
  "Iawar",
  "Ichhawar",
  "Iklehra",
  "Indore",
  "Isagarh",
  "Itarsi",
  "Jabalpur",
  "Jaisinghnagar",
  "Jaithari",
  "Jamai",
  "Jaora",
  "Jatara",
  "Jawad",
  "Jhabua",
  "Jiran",
  "Jobat",
  "Kailaras",
  "Kaimori",
  "Kannod",
  "Kareli",
  "Karera",
  "Karrapur",
  "Kasrawad",
  "Katangi",
  "Katni",
  "Khachrod",
  "Khailar",
  "Khajuraho Group of Monuments",
  "Khamaria",
  "Khandwa",
  "Khandwa district",
  "Khargapur",
  "Khargone",
  "Khategaon",
  "Khilchipur",
  "Khirkiyan",
  "Khujner",
  "Khurai",
  "Kolaras",
  "Korwai",
  "Kotar",
  "Kothi",
  "Kotma",
  "Kotwa",
  "Kukshi",
  "Kumbhraj",
  "Lahar",
  "Lakhnadon",
  "Leteri",
  "Lodhikheda",
  "Machalpur",
  "Madhogarh",
  "Maheshwar",
  "Mahgawan",
  "Maihar",
  "Majholi",
  "Maksi",
  "Malhargarh",
  "Manasa",
  "Manawar",
  "Mandideep",
  "Mandla",
  "Mandleshwar",
  "Mandsaur",
  "Mangawan",
  "Manpur",
  "Mau",
  "Mauganj",
  "Mihona",
  "Mohgaon",
  "Morar",
  "Morena",
  "Multai",
  "Mundi",
  "Mungaoli",
  "Murwara",
  "Nagda",
  "Nagod",
  "Naigarhi",
  "Nainpur",
  "Namli",
  "Naraini",
  "Narayangarh",
  "Narsimhapur",
  "Narsinghgarh",
  "Narwar",
  "Nasrullahganj",
  "Neemuch",
  "Nepanagar",
  "Orchha",
  "Pachmarhi",
  "Palera",
  "Pali",
  "Panagar",
  "Panara",
  "Pandhana",
  "Pandhurna",
  "Panna",
  "Pansemal",
  "Parasia",
  "Patan",
  "Patharia",
  "Pawai",
  "Petlawad",
  "Piploda",
  "Pithampur",
  "Porsa",
  "Punasa",
  "Raghogarh",
  "Rahatgarh",
  "Raisen",
  "Rajgarh",
  "Rajnagar",
  "Rajpur",
  "Rampura",
  "Ranapur",
  "Ratangarh",
  "Ratlam",
  "Rehli",
  "Rehti",
  "Rewa",
  "Sabalgarh",
  "Sagar",
  "Sailana",
  "Sanawad",
  "Sanchi",
  "Sanwer",
  "Sarangpur",
  "Satna",
  "Satwas",
  "Saugor",
  "Sausar",
  "Sehore",
  "Sendhwa",
  "Seondha",
  "Seoni",
  "Seoni Malwa",
  "Shahdol",
  "Shahgarh",
  "Shahpur",
  "Shahpura",
  "Shajapur",
  "Shamgarh",
  "Sheopur",
  "Shivpuri",
  "Shujalpur",
  "Sidhi",
  "Sihora",
  "Simaria",
  "Singoli",
  "Singrauli",
  "Sirmaur",
  "Sironj",
  "Sitamau",
  "Sohagi",
  "Sohagpur",
  "Sultanpur",
  "Susner",
  "Tal",
  "Talen",
  "Tarana",
  "Tekanpur",
  "Tendukheda",
  "Teonthar",
  "Thandla",
  "Tikamgarh",
  "Tirodi",
  "Udaipura",
  "Ujjain",
  "Ukwa",
  "Umaria",
  "Umaria District",
  "Umri",
  "Unhel",
  "Vidisha",
  "Waraseoni",

  "Maharashtra:",
  "Achalpur",
  "Ahiri",
  "Ahmadnagar",
  "Ahmadpur",
  "Airoli",
  "Ajra",
  "Akalkot",
  "Akola",
  "Akot",
  "Alandi",
  "Alibag",
  "Allapalli",
  "Amalner",
  "Amarnath",
  "Ambad",
  "Ambajogai",
  "Amravati",
  "Amravati Division",
  "Anjangaon",
  "Anshing",
  "Arangaon",
  "Artist Village",
  "Arvi",
  "Ashta",
  "Ashti",
  "Aurangabad",
  "Ausa",
  "Badlapur",
  "Balapur",
  "Ballalpur",
  "Baramati",
  "Barsi",
  "Basmat",
  "Beed",
  "Bhandara",
  "Bhayandar",
  "Bhigvan",
  "Bhiwandi",
  "Bhor",
  "Bhudgaon",
  "Bhum",
  "Bhusaval",
  "Bid",
  "Biloli",
  "Boisar",
  "Borivli",
  "Buldana",
  "Chakan",
  "Chalisgaon",
  "Chanda",
  "Chandor",
  "Chandrapur",
  "Chandur",
  "Chandur Bazar",
  "Chicholi",
  "Chikhli",
  "Chinchani",
  "Chiplun",
  "Chopda",
  "Dabhol",
  "Dahanu",
  "Darwha",
  "Daryapur",
  "Dattapur",
  "Daulatabad",
  "Daund",
  "Dehu",
  "Deolali",
  "Deoli",
  "Deulgaon Raja",
  "Dharangaon",
  "Dharmabad",
  "Dharur",
  "Dhule",
  "Dhulia",
  "Diglur",
  "Digras",
  "Dombivli",
  "Dondaicha",
  "Dudhani",
  "Durgapur",
  "Erandol",
  "Faizpur",
  "Gadchiroli",
  "Gadhinglaj",
  "Gangakher",
  "Gangapur",
  "Gevrai",
  "Ghatanji",
  "Ghoti Budrukh",
  "Ghugus",
  "Gondiya",
  "Goregaon",
  "Guhagar",
  "Hadgaon",
  "Harnai",
  "Hinganghat",
  "Hingoli",
  "Hirapur Hamesha",
  "Ichalkaranji",
  "Igatpuri",
  "Indapur",
  "Jaisingpur",
  "Jalgaon",
  "Jalgaon Jamod",
  "Jalna",
  "Jawhar",
  "Jejuri",
  "Jintur",
  "Junnar",
  "Kagal",
  "Kalamb",
  "Kalamnuri",
  "Kalas",
  "Kalmeshwar",
  "Kalundri",
  "Kalyan",
  "Kamthi",
  "Kandri",
  "Kankauli",
  "Kannad",
  "Karad",
  "Karanja",
  "Karjat",
  "Karmala",
  "Kati",
  "Katol",
  "Khadki",
  "Khamgaon",
  "Khapa",
  "Kharakvasla",
  "Khed",
  "Khetia",
  "Khopoli",
  "Khuldabad",
  "Kinwat",
  "Kodoli",
  "Kolhapur",
  "Kondalwadi",
  "Kopargaon",
  "Koradi",
  "Koregaon",
  "Koynanagar",
  "Kudal",
  "Kurandvad",
  "Kurduvadi",
  "Lanja",
  "Lasalgaon",
  "Latur",
  "Lohogaon",
  "Lonar",
  "Lonavla",
  "Mahabaleshwar",
  "Mahad",
  "Maindargi",
  "Majalgaon",
  "Makhjan",
  "Malegaon",
  "Malkapur",
  "Malvan",
  "Manchar",
  "Mangrul Pir",
  "Manmad",
  "Manor",
  "Mansar",
  "Manwat",
  "Matheran",
  "Mehekar",
  "Mhasla",
  "Mhasvad",
  "Mohpa",
  "Moram",
  "Morsi",
  "Mowad",
  "Mudkhed",
  "Mukher",
  "Mul",
  "Mumbai",
  "Mumbai Suburban",
  "Murbad",
  "Murgud",
  "Murtajapur",
  "Murud",
  "Nagothana",
  "Nagpur",
  "Nagpur Division",
  "Naldurg",
  "Nanded",
  "Nandgaon",
  "Nandura Buzurg",
  "Nandurbar",
  "Nashik",
  "Nashik Division",
  "Navi Mumbai",
  "Neral",
  "Nilanga",
  "Nipani",
  "Osmanabad",
  "Ozar",
  "Pachora",
  "Paithan",
  "Palghar",
  "Panchgani",
  "Pandharpur",
  "Panhala",
  "Panvel",
  "Parbhani",
  "Parli Vaijnath",
  "Parola",
  "Partur",
  "Patan",
  "Pathardi",
  "Pathri",
  "Patur",
  "Pawni",
  "Pen",
  "Phaltan",
  "Pimpri",
  "Pipri",
  "Powai",
  "Pulgaon",
  "Pune",
  "Pune Division",
  "Purna",
  "Pusad",
  "Rahimatpur",
  "Rahuri",
  "Raigarh",
  "Rajapur",
  "Rajgurunagar",
  "Rajur",
  "Rajura",
  "Ramtek",
  "Ratnagiri",
  "Raver",
  "Revadanda",
  "Risod",
  "Roha",
  "Sangamner",
  "Sangli",
  "Sangola",
  "Saoner",
  "Sasvad",
  "Satana",
  "Satara",
  "Satara Division",
  "Savantvadi",
  "Savda",
  "Selu",
  "Shahada",
  "Shahapur",
  "Shegaon",
  "Shiraguppi",
  "Shirdi",
  "Shirgaon",
  "Shirpur",
  "Shirwal",
  "Shivaji Nagar",
  "Shrigonda",
  "Sillod",
  "Sindhudurg",
  "Sindi",
  "Sinnar",
  "Sirur",
  "Solapur",
  "Sonegaon",
  "Soygaon",
  "Srivardhan",
  "Surgana",
  "Talegaon Dabhade",
  "Taloda",
  "Tarapur",
  "Tasgaon",
  "Telhara",
  "Thane",
  "Trimbak",
  "Tuljapur",
  "Tumsar",
  "Udgir",
  "Ulhasnagar",
  "Umarga",
  "Umarkhed",
  "Umred",
  "Uran",
  "Vada",
  "Vaijapur",
  "Varangaon",
  "Vasind",
  "Vengurla",
  "Virar",
  "Vite",
  "Wadgaon",
  "Wai",
  "Wani",
  "Wardha",
  "Warora",
  "Warud",
  "Washim",
  "Yaval",
  "Yavatmal",
  "Yeola",

  "Manipur:",
  "Bishnupur",
  "Churachandpur",
  "Imphal",
  "Kakching",
  "Mayang Imphal",
  "Moirang",
  "Phek",
  "Senapati",
  "Tamenglong",
  "Thoubal",
  "Ukhrul",
  "Wangjing",
  "Yairipok",

  "Meghalaya:",
  "Cherrapunji",
  "East Garo Hills",
  "East Jaintia Hills",
  "East Khasi Hills",
  "Mairang",
  "Mankachar",
  "Nongpoh",
  "Nongstoin",
  "North Garo Hills",
  "Ri-Bhoi",
  "Shillong",
  "South Garo Hills",
  "South West Garo Hills",
  "South West Khasi Hills",
  "Tura",
  "West Garo Hills",
  "West Jaintia Hills",
  "West Khasi Hills",

  "Mizoram:",
  "Aizawl",
  "Champhai",
  "Darlawn",
  "Khawhai",
  "Kolasib",
  "Kolasib district",
  "Lawngtlai",
  "Lunglei",
  "Mamit",
  "North Vanlaiphai",
  "Saiha",
  "Sairang",
  "Saitlaw",
  "Serchhip",
  "Thenzawl",

  "Nagaland:",
  "Dimapur",
  "Kohima",
  "Mokokchung",
  "Mon",
  "Peren",
  "Phek",
  "Tuensang",
  "Tuensang District",
  "Wokha",
  "Zunheboto",

  "Odisha:",
  "Angul",
  "Angul District",
  "Asika",
  "Athagarh",
  "Bada Barabil",
  "Balangir",
  "Balasore",
  "Baleshwar",
  "Balimila",
  "Balugaon",
  "Banapur",
  "Banki",
  "Banposh",
  "Baragarh",
  "Barbil",
  "Bargarh",
  "Barpali",
  "Basudebpur",
  "Baud",
  "Baudh",
  "Belaguntha",
  "Bhadrak",
  "Bhadrakh",
  "Bhanjanagar",
  "Bhawanipatna",
  "Bhuban",
  "Bhubaneshwar",
  "Binka",
  "Birmitrapur",
  "Bolanikhodan",
  "Brahmapur",
  "Brajarajnagar",
  "Buguda",
  "Burla",
  "Champua",
  "Chandbali",
  "Chatrapur",
  "Chikitigarh",
  "Chittarkonda",
  "Cuttack",
  "Daitari",
  "Deogarh",
  "Dhenkanal",
  "Digapahandi",
  "Gajapati",
  "Ganjam",
  "Gopalpur",
  "Gudari",
  "Gunupur",
  "Hinjilikatu",
  "Hirakud",
  "Jagatsinghapur",
  "Jagatsinghpur",
  "Jajpur",
  "Jaleshwar",
  "Jatani",
  "Jeypore",
  "Jharsuguda",
  "Jharsuguda District",
  "Kaintragarh",
  "Kalahandi",
  "Kamakhyanagar",
  "Kandhamal",
  "Kantabanji",
  "Kantilo",
  "Kendrapara",
  "Kendraparha",
  "Kendujhar",
  "Kesinga",
  "Khallikot",
  "Kharhial",
  "Khordha",
  "Khurda",
  "Kiri Buru",
  "Kodala",
  "Konarka",
  "Koraput",
  "Kuchaiburi",
  "Kuchinda",
  "Malakanagiri",
  "Malkangiri",
  "Mayurbhanj",
  "Nabarangpur",
  "Nayagarh",
  "Nayagarh District",
  "Nilgiri",
  "Nimaparha",
  "Nowrangapur",
  "Nuapada",
  "Padampur",
  "Paradip Garh",
  "Patamundai",
  "Patnagarh",
  "Phulbani",
  "Pipili",
  "Polasara",
  "Puri",
  "Purushottampur",
  "Rambha",
  "Raurkela",
  "Rayagada",
  "Remuna",
  "Rengali",
  "Sambalpur",
  "Sonepur",
  "Sorada",
  "Soro",
  "Subarnapur",
  "Sundargarh",
  "Talcher",
  "Tarabha",
  "Titlagarh",
  "Udayagiri",

  "Puducherry:",
  "Karaikal",
  "Mahe",
  "Puducherry",
  "Yanam",

  "Punjab:",
  "Abohar",
  "Adampur",
  "Ajitgarh",
  "Ajnala",
  "Akalgarh",
  "Alawalpur",
  "Amloh",
  "Amritsar",
  "Anandpur Sahib",
  "Badhni Kalan",
  "Bagha Purana",
  "Bakloh",
  "Balachor",
  "Banga",
  "Banur",
  "Barnala",
  "Batala",
  "Begowal",
  "Bhadaur",
  "Bhatinda",
  "Bhawanigarh",
  "Bhikhi",
  "Bhogpur",
  "Bholath",
  "Budhlada",
  "Chima",
  "Dasuya",
  "Dera Baba Nanak",
  "Dera Bassi",
  "Dhanaula",
  "Dhariwal",
  "Dhilwan",
  "Dhudi",
  "Dhuri",
  "Dina Nagar",
  "Dirba",
  "Doraha",
  "Faridkot",
  "Fatehgarh Churian",
  "Fatehgarh Sahib",
  "Fazilka",
  "Firozpur",
  "Firozpur District",
  "Gardhiwala",
  "Garhshankar",
  "Ghanaur",
  "Giddarbaha",
  "Gurdaspur",
  "Guru Har Sahai",
  "Hajipur",
  "Hariana",
  "Hoshiarpur",
  "Ishanpur",
  "Jagraon",
  "Jaito",
  "Jalalabad",
  "Jalandhar",
  "Jandiala",
  "Jandiala Guru",
  "Kalanaur",
  "Kapurthala",
  "Kartarpur",
  "Khamanon",
  "Khanna",
  "Kharar",
  "Khemkaran",
  "Kot Isa Khan",
  "Kotkapura",
  "Laungowal",
  "Ludhiana",
  "Machhiwara",
  "Majitha",
  "Makhu",
  "Malaut",
  "Malerkotla",
  "Mansa",
  "Maur Mandi",
  "Moga",
  "Mohali",
  "Morinda",
  "Mukerian",
  "Nabha",
  "Nakodar",
  "Nangal",
  "Nawanshahr",
  "Nurmahal",
  "Nurpur Kalan",
  "Pathankot",
  "Patiala",
  "Patti",
  "Phagwara",
  "Phillaur",
  "Qadian",
  "Rahon",
  "Raikot",
  "Rajasansi",
  "Rajpura",
  "Ram Das",
  "Rampura",
  "Rupnagar",
  "Samrala",
  "Sanaur",
  "Sangrur",
  "Sardulgarh",
  "Shahid Bhagat Singh Nagar",
  "Shahkot",
  "Sham Churasi",
  "Sirhind-Fategarh",
  "Sri Muktsar Sahib",
  "Sultanpur Lodhi",
  "Sunam",
  "Talwandi Bhai",
  "Talwara",
  "Tarn Taran Sahib",
  "Zira",

  "Rajasthan:",
  "Abhaneri",
  "Abu",
  "Abu Road",
  "Ajmer",
  "Aklera",
  "Alwar",
  "Amet",
  "Anta",
  "Anupgarh",
  "Asind",
  "Bagar",
  "Bakani",
  "Bali",
  "Balotra",
  "Bandikui",
  "Banswara",
  "Baran",
  "Bari",
  "Bari Sadri",
  "Barmer",
  "Basi",
  "Basni",
  "Baswa",
  "Bayana",
  "Beawar",
  "Begun",
  "Behror",
  "Bhadasar",
  "Bhadra",
  "Bharatpur",
  "Bhasawar",
  "Bhilwara",
  "Bhindar",
  "Bhinmal",
  "Bhiwadi",
  "Bhuma",
  "Bikaner",
  "Bilara",
  "Bissau",
  "Borkhera",
  "Bundi",
  "Chaksu",
  "Chechat",
  "Chhabra",
  "Chhapar",
  "Chhoti Sadri",
  "Chidawa",
  "Chittaurgarh",
  "Churu",
  "Dariba",
  "Dausa",
  "Deoli",
  "Deshnoke",
  "Devgarh",
  "Dhaulpur",
  "Didwana",
  "Dig",
  "Dungarpur",
  "Fatehpur",
  "Galiakot",
  "Ganganagar",
  "Gangapur",
  "Govindgarh",
  "Gulabpura",
  "Hanumangarh",
  "Hindaun",
  "Jahazpur",
  "Jaipur",
  "Jaisalmer",
  "Jaitaran",
  "Jalor",
  "Jalore",
  "Jhalawar",
  "Jhalrapatan",
  "Jhunjhunun",
  "Jobner",
  "Jodhpur",
  "Kaman",
  "Kanor",
  "Kapren",
  "Karanpur",
  "Karauli",
  "Kekri",
  "Keshorai Patan",
  "Khandela",
  "Khanpur",
  "Khetri",
  "Kishangarh",
  "Kota",
  "Kotputli",
  "Kuchaman",
  "Kuchera",
  "Kumher",
  "Kushalgarh",
  "Lachhmangarh Sikar",
  "Ladnun",
  "Lakheri",
  "Lalsot",
  "Losal",
  "Mahwah",
  "Makrana",
  "Malpura",
  "Mandal",
  "Mandalgarh",
  "Mandawar",
  "Mangrol",
  "Manohar Thana",
  "Manoharpur",
  "Meethari Marwar",
  "Merta",
  "Mundwa",
  "Nadbai",
  "Nagar",
  "Nagaur",
  "Nainwa",
  "Napasar",
  "Naraina",
  "Nasirabad",
  "Nathdwara",
  "Nawa",
  "Nawalgarh",
  "Neem ka Thana",
  "Nimaj",
  "Nimbahera",
  "Niwai",
  "Nohar",
  "Nokha",
  "Padampur",
  "Pali",
  "Partapur",
  "Parvatsar",
  "Phalodi",
  "Phulera",
  "Pilani",
  "Pilibangan",
  "Pindwara",
  "Pipar",
  "Pirawa",
  "Pokaran",
  "Pratapgarh",
  "Pushkar",
  "Raipur",
  "Raisinghnagar",
  "Rajakhera",
  "Rajaldesar",
  "Rajgarh",
  "Rajsamand",
  "Ramganj Mandi",
  "Ramgarh",
  "Rani",
  "Ratangarh",
  "Rawatbhata",
  "Rawatsar",
  "Ringas",
  "Sadri",
  "Salumbar",
  "Sambhar",
  "Samdari",
  "Sanchor",
  "Sangaria",
  "Sangod",
  "Sardarshahr",
  "Sarwar",
  "Sawai Madhopur",
  "Shahpura",
  "Sheoganj",
  "Sikar",
  "Sirohi",
  "Siwana",
  "Sojat",
  "Sri Dungargarh",
  "Sri Madhopur",
  "Sujangarh",
  "Suket",
  "Sunel",
  "Surajgarh",
  "Suratgarh",
  "Takhatgarh",
  "Taranagar",
  "Tijara",
  "Todabhim",
  "Todaraisingh",
  "Tonk",
  "Udaipur",
  "Udpura",
  "Uniara",
  "Wer",

  "Sikkim:",
  "East District",
  "Gangtok",
  "Gyalshing",
  "Jorethang",
  "Mangan",
  "Namchi",
  "Naya Bazar",
  "North District",
  "Rangpo",
  "Singtam",
  "South District",
  "West District",

  "Tamil Nadu:",
  "Abiramam",
  "Adirampattinam",
  "Aduthurai",
  "Alagapuram",
  "Alandur",
  "Alanganallur",
  "Alangayam",
  "Alangudi",
  "Alangulam",
  "Alappakkam",
  "Alwa Tirunagari",
  "Ambasamudram",
  "Ambattur",
  "Ambur",
  "Ammapettai",
  "Anamalais",
  "Andippatti",
  "Annamalainagar",
  "Annavasal",
  "Annur",
  "Anthiyur",
  "Arakkonam",
  "Arantangi",
  "Arcot",
  "Arimalam",
  "Ariyalur",
  "Arni",
  "Arumbavur",
  "Arumuganeri",
  "Aruppukkottai",
  "Aruvankad",
  "Attayyampatti",
  "Attur",
  "Auroville",
  "Avadi",
  "Avinashi",
  "Ayakudi",
  "Ayyampettai",
  "Belur",
  "Bhavani",
  "Bodinayakkanur",
  "Chengam",
  "Chennai",
  "Chennimalai",
  "Chetput",
  "Chettipalaiyam",
  "Cheyyar",
  "Cheyyur",
  "Chidambaram",
  "Chingleput",
  "Chinna Salem",
  "Chinnamanur",
  "Chinnasekkadu",
  "Cholapuram",
  "Coimbatore",
  "Colachel",
  "Cuddalore",
  "Cumbum",
  "Denkanikota",
  "Desur",
  "Devadanappatti",
  "Devakottai",
  "Dhali",
  "Dharapuram",
  "Dharmapuri",
  "Dindigul",
  "Dusi",
  "Elayirampannai",
  "Elumalai",
  "Eral",
  "Eraniel",
  "Erode",
  "Erumaippatti",
  "Ettaiyapuram",
  "Gandhi Nagar",
  "Gangaikondan",
  "Gangavalli",
  "Gingee",
  "Gobichettipalayam",
  "Gudalur",
  "Gudiyatham",
  "Guduvancheri",
  "Gummidipundi",
  "Harur",
  "Hosur",
  "Idappadi",
  "Ilampillai",
  "Iluppur",
  "Injambakkam",
  "Irugur",
  "Jalakandapuram",
  "Jalarpet",
  "Jayamkondacholapuram",
  "Kadambur",
  "Kadayanallur",
  "Kalakkadu",
  "Kalavai",
  "Kallakkurichchi",
  "Kallidaikurichi",
  "Kallupatti",
  "Kalugumalai",
  "Kamuthi",
  "Kanadukattan",
  "Kancheepuram",
  "Kanchipuram",
  "Kangayam",
  "Kanniyakumari",
  "Karaikkudi",
  "Karamadai",
  "Karambakkudi",
  "Kariapatti",
  "Karumbakkam",
  "Karur",
  "Katpadi",
  "Kattivakkam",
  "Kattupputtur",
  "Kaveripatnam",
  "Kayalpattinam",
  "Kayattar",
  "Keelakarai",
  "Kelamangalam",
  "Kil Bhuvanagiri",
  "Kilvelur",
  "Kiranur",
  "Kodaikanal",
  "Kodumudi",
  "Kombai",
  "Konganapuram",
  "Koothanallur",
  "Koradachcheri",
  "Korampallam",
  "Kotagiri",
  "Kottaiyur",
  "Kovilpatti",
  "Krishnagiri",
  "Kulattur",
  "Kulittalai",
  "Kumaralingam",
  "Kumbakonam",
  "Kunnattur",
  "Kurinjippadi",
  "Kuttalam",
  "Kuzhithurai",
  "Lalgudi",
  "Madambakkam",
  "Madipakkam",
  "Madukkarai",
  "Madukkur",
  "Madurai",
  "Madurantakam",
  "Mallapuram",
  "Mallasamudram",
  "Mallur",
  "Manali",
  "Manalurpettai",
  "Manamadurai",
  "Manappakkam",
  "Manapparai",
  "Manavalakurichi",
  "Mandapam",
  "Mangalam",
  "Mannargudi",
  "Marakkanam",
  "Marandahalli",
  "Masinigudi",
  "Mattur",
  "Mayiladuthurai",
  "Melur",
  "Mettuppalaiyam",
  "Mettur",
  "Minjur",
  "Mohanur",
  "Mudukulattur",
  "Mulanur",
  "Musiri",
  "Muttupet",
  "Naduvattam",
  "Nagapattinam",
  "Nagercoil",
  "Namagiripettai",
  "Namakkal",
  "Nambiyur",
  "Nambutalai",
  "Nandambakkam",
  "Nangavalli",
  "Nangilickondan",
  "Nanguneri",
  "Nannilam",
  "Naravarikuppam",
  "Nattam",
  "Nattarasankottai",
  "Needamangalam",
  "Neelankarai",
  "Negapatam",
  "Nellikkuppam",
  "Nilakottai",
  "Nilgiris",
  "Odugattur",
  "Omalur",
  "Ooty",
  "Padmanabhapuram",
  "Palakkodu",
  "Palamedu",
  "Palani",
  "Palavakkam",
  "Palladam",
  "Pallappatti",
  "Pallattur",
  "Pallavaram",
  "Pallikondai",
  "Pallipattu",
  "Pallippatti",
  "Panruti",
  "Papanasam",
  "Papireddippatti",
  "Papparappatti",
  "Paramagudi",
  "Pattukkottai",
  "Pennadam",
  "Pennagaram",
  "Pennathur",
  "Peraiyur",
  "Perambalur",
  "Peranamallur",
  "Peranampattu",
  "Peravurani",
  "Periyakulam",
  "Periyanayakkanpalaiyam",
  "Periyanegamam",
  "Periyapatti",
  "Periyapattinam",
  "Perundurai",
  "Perungudi",
  "Perur",
  "Pollachi",
  "Polur",
  "Ponnamaravati",
  "Ponneri",
  "Poonamalle",
  "Porur",
  "Pudukkottai",
  "Puduppatti",
  "Pudur",
  "Puduvayal",
  "Puliyangudi",
  "Puliyur",
  "Pullambadi",
  "Punjai Puliyampatti",
  "Rajapalaiyam",
  "Ramanathapuram",
  "Rameswaram",
  "Rasipuram",
  "Saint Thomas Mount",
  "Salem",
  "Sathankulam",
  "Sathyamangalam",
  "Sattur",
  "Sayalkudi",
  "Seven Pagodas",
  "Sholinghur",
  "Singanallur",
  "Singapperumalkovil",
  "Sirkazhi",
  "Sirumugai",
  "Sivaganga",
  "Sivagiri",
  "Sivakasi",
  "Srimushnam",
  "Sriperumbudur",
  "Srivaikuntam",
  "Srivilliputhur",
  "Suchindram",
  "Sulur",
  "Surandai",
  "Swamimalai",
  "Tambaram",
  "Tanjore",
  "Taramangalam",
  "Tattayyangarpettai",
  "Thanjavur",
  "Tharangambadi",
  "Theni",
  "Thenkasi",
  "Thirukattupalli",
  "Thiruthani",
  "Thiruvaiyaru",
  "Thiruvallur",
  "Thiruvarur",
  "Thiruvidaimaruthur",
  "Thoothukudi",
  "Tindivanam",
  "Tinnanur",
  "Tiruchchendur",
  "Tiruchengode",
  "Tiruchirappalli",
  "Tirukkoyilur",
  "Tirumullaivasal",
  "Tirunelveli",
  "Tirunelveli Kattabo",
  "Tiruppalaikudi",
  "Tirupparangunram",
  "Tiruppur",
  "Tiruppuvanam",
  "Tiruttangal",
  "Tiruvannamalai",
  "Tiruvottiyur",
  "Tisaiyanvilai",
  "Tondi",
  "Turaiyur",
  "Udangudi",
  "Udumalaippettai",
  "Uppiliyapuram",
  "Usilampatti",
  "Uttamapalaiyam",
  "Uttiramerur",
  "Ūttukkuli",
  "V.S.K.Valasai (Dindigul-Dist.)",
  "Vadakku Valliyur",
  "Vadakku Viravanallur",
  "Vadamadurai",
  "Vadippatti",
  "Valangaiman",
  "Valavanur",
  "Vallam",
  "Valparai",
  "Vandalur",
  "Vandavasi",
  "Vaniyambadi",
  "Vasudevanallur",
  "Vattalkundu",
  "Vedaraniyam",
  "Vedasandur",
  "Velankanni",
  "Vellanur",
  "Vellore",
  "Velur",
  "Vengavasal",
  "Vettaikkaranpudur",
  "Vettavalam",
  "Vijayapuri",
  "Vikravandi",
  "Vilattikulam",
  "Villupuram",
  "Viraganur",
  "Virudhunagar",
  "Vriddhachalam",
  "Walajapet",
  "Wallajahbad",
  "Wellington",

  "Telangana:",
  "Adilabad",
  "Alampur",
  "Andol",
  "Asifabad",
  "Balapur",
  "Banswada",
  "Bellampalli",
  "Bhadrachalam",
  "Bhadradri Kothagudem",
  "Bhaisa",
  "Bhongir",
  "Bodhan",
  "Chandur",
  "Chatakonda",
  "Dasnapur",
  "Devarkonda",
  "Dornakal",
  "Farrukhnagar",
  "Gaddi Annaram",
  "Gadwal",
  "Ghatkesar",
  "Gopalur",
  "Gudur",
  "Hyderabad",
  "Jagtial",
  "Jangaon",
  "Jangoan",
  "Jayashankar Bhupalapally",
  "Jogulamba Gadwal",
  "Kagaznagar",
  "Kamareddi",
  "Kamareddy",
  "Karimnagar",
  "Khammam",
  "Kodar",
  "Koratla",
  "Kothapet",
  "Kottagudem",
  "Kottapalli",
  "Kukatpalli",
  "Kyathampalle",
  "Lakshettipet",
  "Lal Bahadur Nagar",
  "Mahabubabad",
  "Mahbubnagar",
  "Malkajgiri",
  "Mancheral",
  "Mandamarri",
  "Manthani",
  "Manuguru",
  "Medak",
  "Medchal",
  "Medchal Malkajgiri",
  "Mirialguda",
  "Nagar Karnul",
  "Nalgonda",
  "Narayanpet",
  "Narsingi",
  "Naspur",
  "Nirmal",
  "Nizamabad",
  "Paloncha",
  "Palwancha",
  "Patancheru",
  "Peddapalli",
  "Quthbullapur",
  "Rajanna Sircilla",
  "Ramagundam",
  "Ramgundam",
  "Rangareddi",
  "Sadaseopet",
  "Sangareddi",
  "Sathupalli",
  "Secunderabad",
  "Serilingampalle",
  "Siddipet",
  "Singapur",
  "Sirpur",
  "Sirsilla",
  "Sriramnagar",
  "Suriapet",
  "Tandur",
  "Uppal Kalan",
  "Vemalwada",
  "Vikarabad",
  "Wanparti",
  "Warangal",
  "Yellandu",
  "Zahirabad",

  "Tripura:",
  "Agartala",
  "Amarpur",
  "Ambasa",
  "Barjala",
  "Belonia",
  "Dhalai",
  "Dharmanagar",
  "Gomati",
  "Kailashahar",
  "Kamalpur",
  "Khowai",
  "North Tripura",
  "Ranir Bazar",
  "Sabrum",
  "Sonamura",
  "South Tripura",
  "Udaipur",
  "Unakoti",
  "West Tripura",

  "Uttar Pradesh:",
  "Achhnera",
  "Afzalgarh",
  "Agra",
  "Ahraura",
  "Ajodhya",
  "Akbarpur",
  "Aliganj",
  "Aligarh",
  "Allahabad",
  "Allahganj",
  "Amanpur",
  "Ambahta",
  "Ambedkar Nagar",
  "Amethi",
  "Amroha",
  "Anandnagar",
  "Antu",
  "Anupshahr",
  "Aonla",
  "Atarra",
  "Atrauli",
  "Atraulia",
  "Auraiya",
  "Auras",
  "Azamgarh",
  "Baberu",
  "Babina",
  "Babrala",
  "Babugarh",
  "Bachhraon",
  "Bachhrawan",
  "Baghpat",
  "Bah",
  "Baheri",
  "Bahjoi",
  "Bahraich",
  "Bahraigh",
  "Bahsuma",
  "Bahua",
  "Bajna",
  "Bakewar",
  "Baldev",
  "Ballia",
  "Balrampur",
  "Banat",
  "Banbasa",
  "Banda",
  "Bangarmau",
  "Bansdih",
  "Bansgaon",
  "Bansi",
  "Bara Banki",
  "Baragaon",
  "Baraut",
  "Bareilly",
  "Barkhera Kalan",
  "Barsana",
  "Basti",
  "Behat",
  "Bela",
  "Beniganj",
  "Beswan",
  "Bewar",
  "Bhadohi",
  "Bhagwantnagar",
  "Bharthana",
  "Bharwari",
  "Bhinga",
  "Bhongaon",
  "Bidhuna",
  "Bighapur Khurd",
  "Bijnor",
  "Bikapur",
  "Bilari",
  "Bilariaganj",
  "Bilaspur",
  "Bilgram",
  "Bilhaur",
  "Bilsanda",
  "Bilsi",
  "Bilthra",
  "Bindki",
  "Bisalpur",
  "Bisauli",
  "Bisenda Buzurg",
  "Bishunpur Urf Maharajganj",
  "Biswan",
  "Bithur",
  "Budaun",
  "Budhana",
  "Bulandshahr",
  "Captainganj",
  "Chail",
  "Chakia",
  "Chandauli",
  "Chandauli District",
  "Chandpur",
  "Chanduasi",
  "Charkhari",
  "Charthawal",
  "Chhaprauli",
  "Chharra",
  "Chhata",
  "Chhibramau",
  "Chhutmalpur",
  "Chillupar",
  "Chirgaon",
  "Chitrakoot",
  "Chopan",
  "Chunar",
  "Colonelganj",
  "Dadri",
  "Dalmau",
  "Dankaur",
  "Dasna",
  "Dataganj",
  "Daurala",
  "Dayal Bagh",
  "Deoband",
  "Deoranian",
  "Deoria",
  "Dewa",
  "Dhampur",
  "Dhanaura",
  "Dhaurahra",
  "Dibai",
  "Dohrighat",
  "Dostpur",
  "Dudhi",
  "Etah",
  "Etawah",
  "Faizabad",
  "Farah",
  "Faridnagar",
  "Faridpur",
  "Farrukhabad",
  "Fatehabad",
  "Fatehganj West",
  "Fatehgarh",
  "Fatehpur",
  "Fatehpur Chaurasi",
  "Fatehpur Sikri",
  "Firozabad",
  "Fyzabad",
  "Gajraula",
  "Gangoh",
  "Ganj Dundwara",
  "Ganj Muradabad",
  "Garautha",
  "Garhi Pukhta",
  "Garhmuktesar",
  "Gautam Buddha Nagar",
  "Gawan",
  "Ghatampur",
  "Ghaziabad",
  "Ghazipur",
  "Ghiror",
  "Ghorawal",
  "Ghosi",
  "Gohand",
  "Gokul",
  "Gola Bazar",
  "Gola Gokarannath",
  "Gonda",
  "Gonda City",
  "Gopamau",
  "Gorakhpur",
  "Goshainganj",
  "Govardhan",
  "Greater Noida",
  "Gulaothi",
  "Gunnaur",
  "Gursahaiganj",
  "Gursarai",
  "Gyanpur",
  "Haldaur",
  "Hamirpur",
  "Handia",
  "Hapur",
  "Haraiya",
  "Hardoi",
  "Harduaganj",
  "Hasanpur",
  "Hastinapur",
  "Hata",
  "Hathras",
  "Iglas",
  "Ikauna",
  "Indergarh",
  "Islamnagar",
  "Itaunja",
  "Itimadpur",
  "Jagdishpur",
  "Jagnair",
  "Jahanabad",
  "Jahangirabad",
  "Jahangirpur",
  "Jainpur",
  "Jais",
  "Jalalabad",
  "Jalali",
  "Jalalpur",
  "Jalaun",
  "Jalesar",
  "Jansath",
  "Jarwal",
  "Jasrana",
  "Jaswantnagar",
  "Jaunpur",
  "Jewar",
  "Jhalu",
  "Jhansi",
  "Jhinjhak",
  "Jhinjhana",
  "Jhusi",
  "Jyotiba Phule Nagar",
  "Kabrai",
  "Kachhwa",
  "Kadaura",
  "Kadipur",
  "Kaimganj",
  "Kairana",
  "Kakori",
  "Kakrala",
  "Kalinagar",
  "Kalpi",
  "Kamalganj",
  "Kampil",
  "Kandhla",
  "Kannauj",
  "Kanpur",
  "Kanpur Dehat",
  "Kant",
  "Kanth",
  "Karari",
  "Karhal",
  "Kasganj",
  "Katra",
  "Kaushambi District",
  "Kemri",
  "Khada",
  "Khaga",
  "Khair",
  "Khairabad",
  "Khalilabad",
  "Khanpur",
  "Kharela",
  "Khargupur",
  "Kharkhauda",
  "Khatauli",
  "Khekra",
  "Kheri",
  "Khudaganj",
  "Khurja",
  "Khutar",
  "Kirakat",
  "Kiraoli",
  "Kiratpur",
  "Kishanpur",
  "Kishni",
  "Kithor",
  "Konch",
  "Kopaganj",
  "Kosi",
  "Kota",
  "Kotra",
  "Kulpahar",
  "Kunda",
  "Kundarkhi",
  "Kurara",
  "Kushinagar",
  "Laharpur",
  "Lakhimpur",
  "Lakhna",
  "Lalganj",
  "Lalitpur",
  "Lar",
  "Lawar Khas",
  "Loni",
  "Lucknow",
  "Lucknow District",
  "Machhlishahr",
  "Madhoganj",
  "Madhogarh",
  "Maghar",
  "Mahaban",
  "Maharajganj",
  "Mahmudabad",
  "Mahoba",
  "Maholi",
  "Mahroni",
  "Mailani",
  "Mainpuri",
  "Malihabad",
  "Mandawar",
  "Maniar",
  "Manikpur",
  "Manjhanpur",
  "Mankapur",
  "Marahra",
  "Mariahu",
  "Mataundh",
  "Mathura",
  "Mau",
  "Mau Aimma",
  "Maudaha",
  "Mauranwan",
  "Mawana",
  "Meerut",
  "Mehnagar",
  "Mehndawal",
  "Milak",
  "Miranpur",
  "Miranpur Katra",
  "Mirganj",
  "Mirzapur",
  "Misrikh",
  "Mohan",
  "Mohanpur",
  "Moradabad",
  "Moth",
  "Mubarakpur",
  "Mughal Sarai",
  "Muhammadabad",
  "Muradnagar",
  "Mursan",
  "Musafir-Khana",
  "Muzaffarnagar",
  "Nadigaon",
  "Nagina",
  "Nagram",
  "Najibabad",
  "Nakur",
  "Nanauta",
  "Nandgaon",
  "Nanpara",
  "Narauli",
  "Naraura",
  "Nautanwa",
  "Nawabganj",
  "Nichlaul",
  "Nihtaur",
  "Niwari",
  "Nizamabad",
  "Noida",
  "Nurpur",
  "Obra",
  "Orai",
  "Oran",
  "Pachperwa",
  "Padrauna",
  "Pahasu",
  "Pali",
  "Palia Kalan",
  "Parichha",
  "Parichhatgarh",
  "Parshadepur",
  "Patiali",
  "Patti",
  "Pawayan",
  "Phalauda",
  "Phaphund",
  "Phariha",
  "Phulpur",
  "Pihani",
  "Pilibhit",
  "Pilkhua",
  "Pinahat",
  "Pipraich",
  "Pratapgarh",
  "Pukhrayan",
  "Puranpur",
  "Purwa",
  "Rabupura",
  "Radhakund",
  "Raebareli",
  "Rajapur",
  "Ramkola",
  "Ramnagar",
  "Rampur",
  "Rampura",
  "Ranipur",
  "Rasra",
  "Rasulabad",
  "Rath",
  "Raya",
  "Renukut",
  "Reoti",
  "Richha",
  "Robertsganj",
  "Rudarpur",
  "Rura",
  "Sadabad",
  "Sadat",
  "Safipur",
  "Saharanpur",
  "Sahaspur",
  "Sahaswan",
  "Sahawar",
  "Saidpur",
  "Sakit",
  "Salon",
  "Sambhal",
  "Samthar",
  "Sandi",
  "Sandila",
  "Sant Kabir Nagar",
  "Sant Ravi Das Nagar",
  "Sarai Akil",
  "Sarai Ekdil",
  "Sarai Mir",
  "Sarauli",
  "Sardhana",
  "Sarila",
  "Sasni",
  "Satrikh",
  "Saurikh",
  "Sector",
  "Seohara",
  "Shahabad",
  "Shahganj",
  "Shahi",
  "Shahjahanpur",
  "Shahpur",
  "Shamli",
  "Shamsabad",
  "Shankargarh",
  "Shergarh",
  "Sherkot",
  "Shikarpur",
  "Shikohabad",
  "Shishgarh",
  "Shrawasti",
  "Siddharthnagar",
  "Sidhauli",
  "Sidhpura",
  "Sikandarabad",
  "Sikandarpur",
  "Sikandra",
  "Sikandra Rao",
  "Sirathu",
  "Sirsa",
  "Sirsaganj",
  "Sirsi",
  "Sisauli",
  "Siswa Bazar",
  "Sitapur",
  "Sonbhadra",
  "Soron",
  "Suar",
  "Sultanpur",
  "Surianwan",
  "Tajpur",
  "Talbahat",
  "Talgram",
  "Tanda",
  "Thakurdwara",
  "Thana Bhawan",
  "Tikaitnagar",
  "Tikri",
  "Tilhar",
  "Tindwari",
  "Titron",
  "Tori-Fatehpur",
  "Tulsipur",
  "Tundla",
  "Ugu",
  "Ujhani",
  "Ūn",
  "Unnao",
  "Usehat",
  "Utraula",
  "Varanasi",
  "Vrindavan",
  "Wazirganj",
  "Zafarabad",
  "Zaidpur",
  "Zamania",

  "Uttarakhand:",
  "Almora",
  "Bageshwar",
  "Barkot",
  "Bazpur",
  "Bhim Tal",
  "Bhowali",
  "Birbhaddar",
  "Chakrata",
  "Chamoli",
  "Champawat",
  "Clement Town",
  "Dehradun",
  "Devaprayag",
  "Dharchula",
  "Doiwala",
  "Dugadda",
  "Dwarahat",
  "Garhwal",
  "Haldwani",
  "Harbatpur",
  "Haridwar",
  "Jaspur",
  "Joshimath",
  "Kaladhungi",
  "Kalagarh Project Colony",
  "Kashipur",
  "Khatima",
  "Kichha",
  "Kotdwara",
  "Laksar",
  "Lansdowne",
  "Lohaghat",
  "Manglaur",
  "Mussoorie",
  "Naini Tal",
  "Narendranagar",
  "Pauri",
  "Pipalkoti",
  "Pithoragarh",
  "Raipur",
  "Raiwala Bara",
  "Ramnagar",
  "Ranikhet",
  "Rishikesh",
  "Roorkee",
  "Rudraprayag",
  "Sitarganj",
  "Srinagar",
  "Sultanpur",
  "Tanakpur",
  "Tehri",
  "Tehri-Garhwal",
  "Udham Singh Nagar",
  "Uttarkashi",
  "Vikasnagar",

  "West Bengal:",
  "Ahmedpur",
  "Aistala",
  "Aknapur",
  "Alipurduar",
  "Amlagora",
  "Amta",
  "Amtala",
  "Andal",
  "Arambagh community development block",
  "Asansol",
  "Ashoknagar Kalyangarh",
  "Badkulla",
  "Baduria",
  "Bagdogra",
  "Bagnan",
  "Bagula",
  "Bahula",
  "Baidyabati",
  "Bakreswar",
  "Balarampur",
  "Bali Chak",
  "Bally",
  "Balurghat",
  "Bamangola community development block",
  "Baneswar",
  "Bangaon",
  "Bankra",
  "Bankura",
  "Bansberia",
  "Bansihari community development block",
  "Barabazar",
  "Baranagar",
  "Barasat",
  "Bardhaman",
  "Barjora",
  "Barrackpore",
  "Baruipur",
  "Basanti",
  "Basirhat",
  "Bawali",
  "Begampur",
  "Belda",
  "Beldanga",
  "Beliatore",
  "Berhampore",
  "Bhadreswar",
  "Bhandardaha",
  "Bhatpara",
  "Birbhum district",
  "Birpara",
  "Bishnupur",
  "Bolpur",
  "Budge Budge",
  "Canning",
  "Chakapara",
  "Chakdaha",
  "Champadanga",
  "Champahati",
  "Champdani",
  "Chandannagar",
  "Chandrakona",
  "Chittaranjan",
  "Churulia",
  "Contai",
  "Cooch Behar",
  "Cossimbazar",
  "Dakshin Dinajpur district",
  "Dalkola",
  "Dam Dam",
  "Darjeeling",
  "Daulatpur",
  "Debagram",
  "Debipur",
  "Dhaniakhali community development block",
  "Dhulagari",
  "Dhulian",
  "Dhupguri",
  "Diamond Harbour",
  "Digha",
  "Dinhata",
  "Domjur",
  "Dubrajpur",
  "Durgapur",
  "Egra",
  "Falakata",
  "Farakka",
  "Fort Gloster",
  "Gaighata community development block",
  "Gairkata",
  "Gangadharpur",
  "Gangarampur",
  "Garui",
  "Garulia",
  "Ghatal",
  "Giria",
  "Gobardanga",
  "Gobindapur",
  "Gopalpur",
  "Gopinathpur",
  "Gorubathan",
  "Gosaba",
  "Gosanimari",
  "Gurdaha",
  "Guskhara",
  "Habra",
  "Haldia",
  "Haldibari",
  "Halisahar",
  "Harindanga",
  "Haringhata",
  "Haripur",
  "Hasimara",
  "Hindusthan Cables Town",
  "Hooghly district",
  "Howrah",
  "Ichapur",
  "Indpur community development block",
  "Ingraj Bazar",
  "Islampur",
  "Jafarpur",
  "Jaigaon",
  "Jalpaiguri",
  "Jamuria",
  "Jangipur",
  "Jaynagar Majilpur",
  "Jejur",
  "Jhalida",
  "Jhargram",
  "Jhilimili",
  "Kakdwip",
  "Kalaikunda",
  "Kaliaganj",
  "Kalimpong",
  "Kalna",
  "Kalyani",
  "Kamarhati",
  "Kamarpukur",
  "Kanchrapara",
  "Kandi",
  "Karimpur",
  "Katwa",
  "Kenda",
  "Keshabpur",
  "Kharagpur",
  "Kharar",
  "Kharba",
  "Khardaha",
  "Khatra",
  "Kirnahar",
  "Kolkata",
  "Konnagar",
  "Krishnanagar",
  "Krishnapur",
  "Kshirpai",
  "Kulpi",
  "Kultali",
  "Kulti",
  "Kurseong",
  "Lalgarh",
  "Lalgola",
  "Loyabad",
  "Madanpur",
  "Madhyamgram",
  "Mahiari",
  "Mahishadal community development block",
  "Mainaguri",
  "Manikpara",
  "Masila",
  "Mathabhanga",
  "Matiali community development block",
  "Matigara community development block",
  "Medinipur",
  "Mejia community development block",
  "Memari",
  "Mirik",
  "Mohanpur community development block",
  "Monoharpur",
  "Muragacha",
  "Muri",
  "Murshidabad",
  "Nabadwip",
  "Nabagram",
  "Nadia district",
  "Nagarukhra",
  "Nagrakata",
  "Naihati",
  "Naksalbari",
  "Nalhati",
  "Nalpur",
  "Namkhana community development block",
  "Nandigram",
  "Nangi",
  "Nayagram community development block",
  "North 24 Parganas district",
  "Odlabari",
  "Paikpara",
  "Panagarh",
  "Panchla",
  "Panchmura",
  "Pandua",
  "Panihati",
  "Panskura",
  "Parbatipur",
  "Paschim Medinipur district",
  "Patiram",
  "Patrasaer",
  "Patuli",
  "Pujali",
  "Puncha community development block",
  "Purba Medinipur district",
  "Purulia",
  "Raghudebbati",
  "Raghunathpur",
  "Raiganj",
  "Rajmahal",
  "Rajnagar community development block",
  "Ramchandrapur",
  "Ramjibanpur",
  "Ramnagar",
  "Rampur Hat",
  "Ranaghat",
  "Raniganj",
  "Raypur",
  "Rishra",
  "Sahapur",
  "Sainthia",
  "Salanpur community development block",
  "Sankarpur",
  "Sankrail",
  "Santipur",
  "Santoshpur",
  "Santuri community development block",
  "Sarenga",
  "Serampore",
  "Serpur",
  "Shyamnagar, West Bengal",
  "Siliguri",
  "Singur",
  "Sodpur",
  "Solap",
  "Sonada",
  "Sonamukhi",
  "Sonarpur community development block",
  "South 24 Parganas district",
  "Srikhanda",
  "Srirampur",
  "Suri",
  "Swarupnagar community development block",
  "Takdah",
  "Taki",
  "Tamluk",
  "Tarakeswar",
  "Titagarh",
  "Tufanganj",
  "Tulin",
  "Uchalan",
  "Ula",
  "Uluberia",
  "Uttar Dinajpur district",
  "Uttarpara Kotrung",
];

export const TimingJob = ["Select", "Morning", "After-Noon", "Evening", "Night"];

export const From1 = ["Select","8am", "9am To 9pm"];

export const To1 = [
  "Select",
  "9am To 9pm"
];



// MAP DATA FOR JOB FILTER 



export const Petstypes =["Select","Bird", "Cat", "Dog", "Fish", "Other Pets"]

