import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { localUrl } from "../../../env";
import Spiner from "../../../Spiner";
import EmptyAdsMsg from "../DashAds/EmptyAdsMsg";
import "./Orders.css";
import { BiPrinter } from 'react-icons/bi';
import { MailOutlined } from '@ant-design/icons';
import { isMobile } from "react-device-detect";
import { font } from './../../../Dashboard/Sidebar';

const mobTable ={
  width:"250px",
  font:"16px",
  marginLeft:"-20px",
   
}
const mobTable1 ={
  font:"10px",
  paddingLeft:"-20px",
}
const deskTable={

}
const Orders = () => {

  const userData = useSelector((state) => state.userIdSlice);

  const [payment, setPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const getUserPaymentDetails = async () => {
    setLoading(true);
    try {
      let formdata = new FormData();
      formdata.append("userID", userData.data.id);
      let requestOptions = {
        method: "POST",
        redirect: "follow",
        body: formdata,
      };
      const res = await fetch(
        localUrl + "payment/userTransData",
        requestOptions
      );

      const data = await res.json();
      setLoading(false);
      setPayment(data);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserPaymentDetails();
  }, []);

  const handlePrintButtonClick = (payment) => {
    setSelectedPayment(payment);
  };

  const handleListItemClick = (payment) => {
    setSelectedPayment(payment);
  };

  let paymentMessage = {
    message: "You haven't bought any plan. ",
    title: "Please Buy A Plan",
    navigate: "/pricing",
    buttonName: "Buy Plan",
  };

  return (
    <>
      {loading && <Spiner />}

      {!loading && payment.length ? (
        <>
          <h1 style={{ textAlign: "center" }}>Payment Details</h1>
          <section className="profile-part" style={{ height: "400px", overflowY: 'scroll' }}>
            <div className="container pt-0">
              <div className="row">
                {payment
                  ?.sort((a, b) => b.pk - a.pk)
                  ?.map((payment, index) => (
                    <div className="col-lg-6" key={index}>
                      <div className="account-card gap-10">
                        <div className="account-title">
                          <h3>Payment Details<span className="print-buttonStart">
                            <button className="print-button1" onClick={() => handlePrintButtonClick(payment)}><BiPrinter /></button>
                          </span></h3>
                        </div>
                        <ul className="account-card-list gap-10">
                          <li onClick={() => handleListItemClick(payment)}>
                            <h5>Status:</h5>
                            {payment?.fields?.paymentStatus === "Success" ? (
                              <p className="text-white px-1" style={{ borderRadius: "3px", marginTop: "-20px", backgroundColor: "green" }}>{payment?.fields?.paymentStatus}</p>
                            ) : (
                              <p className="bg-danger text-white" style={{ borderRadius: "3px", marginTop: "-20px" }}>{payment?.fields?.paymentStatus}</p>
                            )}
                          </li>
                          <li>
                            <h5>Plan:</h5>
                            <p>{payment?.fields?.plan}</p>
                          </li>
                          {payment?.fields?.paymentStatus === "success" ? (
                            <li>
                              <h5>Transaction ID:</h5>
                              <p>{payment?.fields?.payment_id}</p>
                            </li>
                          ) : null}
                          <li>
                            <h5>Phone Number:</h5>
                            <p>{payment?.fields?.phoneNumber}</p>
                          </li>
                          <li>
                            <h5>Date:</h5>
                            <p>{payment?.fields?.ordrDate}</p>
                          </li>
                          <li>
                            <h5>Price(₹):</h5>
                            <p style={{ color: "green", fontWeight: "bold", textTransform: "capitalize" }}>{Math.floor(payment?.fields?.order_payment_amount)}.00</p>
                          </li>

                        </ul>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        </>
      ) : null}
      {!loading && payment.length === 0 ? (
        <div>
          <EmptyAdsMsg plan={paymentMessage} />
        </div>
      ) : null}
      {selectedPayment && (
        <PaymentPopup
          payment={selectedPayment}
          onClose={() => setSelectedPayment(null)}
        />
      )}
    </>
  );
};

const PaymentPopup = ({ payment, onClose }) => {
  return (
    <div className="popup" style={{ background: "#efefef" }}>
      <span className="close" onClick={onClose}>
        &times;
      </span><br></br>

      {/* reciept for profile start */}
      <div className="col-md-12">
        <div className="row">
          <div className="receipt-main">
            <div className="row">
              <div className="receipt-header">
                <div className="col-xs-6 col-sm-6 col-md-6">
                  <div className="receipt-left">
                    <img className="img-responsive" alt="iamgurdeeposahan" src="https://bootdey.com/img/Content/avatar/avatar6.png" style={{ width: "71px", borderRadius: "43px" }} />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 text-right">
                  <div className="receipt-right">
                    <h5>Hola9</h5>
                    <p>+1 3649-6589 <i className="fa fa-phone"></i></p>
                    <p>company@gmail.com <MailOutlined /></p>
                    <p>USA <i className="fa fa-location-arrow"></i></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="receipt-header receipt-header-mid">
                <div className="col-xs-8 col-sm-8 col-md-8 text-left">
                  <div className="receipt-right">
                    <h5>Customer Name </h5>
                    <p><b>Mobile :</b> +1 12345-4569</p>
                    <p><b>Email :</b> customer@gmail.com</p>
                    <p><b>Address :</b> New York, USA</p>
                  </div>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-4">
                  <div className="receipt-left">
                    <h6>INVOICE # 102</h6>
                  </div>
                </div>
              </div>
            </div>

            <div  >
            <style
  dangerouslySetInnerHTML={{
    __html:
      "\ntable{\nwidth: 100%;\nborder-collapse:collapse;\nborder: 1px solid black;\n}\ntable td, table th {border: 1px solid black;}\ntable td{line-height:25px;padding-left:15px;}\ntable th{background-color:#fbc403; color:#363636;}\n"
  }}
/>
             <table className="popupData" style={isMobile ? mobTable:deskTable} >
                  <thead>
                    <th style={{backgroundColor:"#fbc403"}}>
                    <center style={{marginLeft:"105px"}}><p style={isMobile ? mobTable1:deskTable}>Hola9-Payment</p></center>
                    </th>
                    <th></th>
                  </thead>         
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td> {payment?.fields?.paymentStatus}</td>
                  </tr>
                  <tr>
                    <td>Plan</td>
                    <td> {payment?.fields?.plan}</td>
                  </tr>
                  {payment?.fields?.paymentStatus === "success" && (
                    <tr>
                      <td>Transaction ID</td>
                      <td> {payment?.fields?.payment_id}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Phone Number</td>
                    <td> {payment?.fields?.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td> {payment?.fields?.ordrDate}</td>
                  </tr>
                  <tr>
                    <td>Price(₹)</td>
                    <td> {Math.floor(payment?.fields?.order_payment_amount)}.00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="row">
              <div className="receipt-header receipt-header-mid receipt-footer">
                <div className="col-xs-8 col-sm-8 col-md-8 text-left">
                  <div className="receipt-right">
                    <p><b>Date :</b> 15 Aug 2016</p>
                    <h5 style={{ color: "rgb(140, 140, 140)" }}>Thanks for shopping.!</h5>
                  </div>
                </div>
                <div className="">
                  <div className="receipt-left">
                    <h1>Stamp</h1>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* recipt for profile end */}
      <button className="print-button" onClick={() => window.print()} ><BiPrinter />Print</button>

    </div>
  );
};

export default Orders;
