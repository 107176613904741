import React from "react";
import Feature from "../../component/Ads/FeaturedAds/Feature";
import Owldemo1 from "../../component/Owldemo1";
import { isMobile } from "react-device-detect";
import AboveFooter from "../../component/Base/AboveFooter";
const OwlDemoMultiPle = (props) => {
const mobstyle={
  width: "auto"
}
const deskstyle={
   marginLeft: "11.5rem",
   marginRight: "11.5rem"
}
  return (
    <>
    <div>
     {props.props.subCategory.map((result) => {
        return (
          <div style={isMobile? mobstyle: deskstyle}>
          <Owldemo1 greeting={props.props.category} subcategory={result} />
          </div>

        );
      })}
    </div>
    <div><AboveFooter/></div>
    </>
  );
};

export default OwlDemoMultiPle;
