import { useDispatch } from "react-redux";
import ContentLoader, {
  List,
  BulletList,
  Facebook,
  Instagram,
} from "react-content-loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Tooltip, Button, Modal } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams, useNavigate, Link } from "react-router-dom";
import {
  All,
  localUrl,
  subcategoryRealEstateBuy,
  subcategoryRealEstateRent,
  subcategoryType1,
  url,
  location2,
  locationcurrent,
} from "../../env";
import { useInterval } from "react-interval-hook";
import "./ShowProduct.css";
import { MDBCol } from "mdbreact";
import { isMobile } from "react-device-detect";
import Spiner from "../../Spiner";
import { getFormLabelUtilityClasses, SliderValueLabel } from "@mui/material";
import SweetPagination from "sweetpagination";
// import Distance from "../../distance";
import DynamicFont from "react-dynamic-font";
import {
  BsReverseLayoutTextSidebarReverse,
  BsFillTagFill,
  BsFillBookmarkFill,
  BsFillClockFill,
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper,
  BsHeartFill,BsCoin
} from "react-icons/bs";
import { remove, add1 } from "../../../store/wishlistSlice";
import { add } from "../../../store/Track/trackUserSlice";
import { useSelector } from "react-redux";
import { removeSearchData } from "../../../store/SearchCategory/searchCategorySlice";
import { FilterBy } from "../../../FilterBy";
import {
  getExtraField,
  removeFiled,
  removeKeyPair,
} from "../../../store/ProductFilterSlice";
import { filterData } from "../../../store/getDataFeature";

import { UserContext } from "../../../App";
import {
  newProductValue,
  productValueRemove,
  productValueValue,
} from "../../../store/showProductsliceValue";

import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";

import Distance from "../../distance";
import NoDataFound from "../../datanotfound/NoDataFound";
import { searchApi } from "../../../store/ToggleSearchSlice";
// import Search from "antd/lib/input/Search";

// import { searchValue } from "../../../store/seachAdsArray";
import { Input, Space } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Delete } from "@mui/icons-material";
import { GiMoneyStack } from "react-icons/gi";
import verified from "./verified.jpg";
import Filter from "./Filter";
// import PoppupAd from "./popupAd/PoppupAd";
const mobstyle = {
  width: "auto",
  
};
const deskstyle = {
  marginRight: "11.5rem",
  marginLeft: "11.5rem",
};
const mobistyle = {
  width: "auto",
  marginLeft: "16px",
  marginRight: "16px",
  background: "transparent"
};
const deskistyle = {
  width: "14rem",
  background: "gray",
  //  borderRadius: "2px",
   border: "1px solid gray",
};
const mobilestyle={
  height: "140px",
  width: "350px",
  borderRadius:"20px",
}
const desktopstyle={
 height: "165px",
  width: "350px",
}
const JsonSearch = require("search-array").default;

const ShowProduct = () => {
  const { Search } = Input;
  const dispatch = useDispatch();
  const wishlistData = useSelector((state) => state.wishlistSlice);
  // const searchAds = useSelector((state) => state.searchAds);
  const { data } = useSelector((state) => state.sortFilter);

  console.log("search", data);
  const [wishlistID] = ["wishlistId"].map(
    document.getElementById.bind(document)
  );
  const navigate = useNavigate();

  const [currentPageData, setCurrentPageData] = useState(new Array(2).fill());
  const [products, setProducts] = useState([]);
  const [popularity, setpopularity] = useState(null);
  const [featured, setfeatured] = useState(null);
  const [grid, setgrid] = useState(true);
  const [productvalue, setproductvalue] = useState(12);
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(getFormLabelUtilityClasses);
  const [isloading, setIsLoading] = useState(true);

  const [condition, setCondition] = useState(null);
  const [error, setError] = useState(null);
  const [lockInterval, setLockInterval] = useState(true);
  const [interval, setInterval] = useState({ start: 1, end: 8 });
  const [loaderAllAds, setloaderAllAds] = useState(true);
  const [SearchValue, setSearchValue] = useState("");
  const location1 = useParams().location;
  const [location, setlocation] = useState(
    useParams().location ? location1 : null
  );
  const [productnotSearch, setproductnotSearch] = useState(null);
  const category1 = useParams()?.category;
  const subcategory1 = useParams()?.subcategory;
  const [filterOnClick, setefilterOnClick] = useState(false);
  const [category, setcategory] = useState(
    useParams().category ? category1 : null
  );
  const [subcategoryfromHome, setsubcategoryfromHome] = useState(
    useParams().subcategory ? subcategory1 : null
  );
  const [tempProduct, setTempProduct] = useState([]);
  const [featureTrue, setfeatureTrue] = useState(null);
  const [filterFinal, setFilterFinal] = useState(null);

  let locationTemp = useParams().location;
  const [location111, setlocation1111] = useState(
    useParams().location ? locationTemp : null
  );
  const sortObjectsArray = require("sort-objects-array");

  const userdata = useSelector((state) => state.userIdSlice);
  const [data1, setData1] = useState([]);

  useEffect(() => {
    // Fetch data from your API
    fetch('https://script.google.com/macros/s/'+ locationcurrent)
      .then(response => response.json())
      .then(data => setData1(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

 
  //-----------------------------
  useEffect(() => {
    // console.log("userdata showproduct", userdata);
    dispatch(add({ view: ["ShowProduct"] }));

    let obj = {};

    if (location111) {
      obj["City"] = location111;
      obj["extraField"] = {};
      dispatch(getExtraField(obj));
    }
  }, []);

  const selector = useSelector((state) => state.filter);

  const productValue = useSelector((state) => state.showProductsliceValue);

  const fetchFromRedux = useSelector(
    (state) => state?.sortFilter?.data?.valueone
  );
  // console.log("fetch from redux", fetchFromRedux);

  const [valueTemp, setValueTemp] = useState(1);

  // useEffect(() => {
  //   setproductnotSearch(productValue);
  // }, [productValue]);
  useEffect(() => {
    if (
      selector.filter &&
      Object?.keys(selector.filter).length == 1 &&
      "extraField" in selector.filter &&
      Object?.keys(selector.filter?.extraField).length == 0
    ) {
      setProducts([]);
      setInterval({ start: 0, end: 8 });
      dispatch(removeFiled());
    }
  }, [selector.filter]);

  const [flagvalue, setFlagvalue] = useState(false);
  useEffect(() => {
    var flag = true;
    let obj = {
      product: [],
      intervals: { start: 0, end: 8 },
    };
    dispatch(productValueRemove(obj));
    setValueTemp(valueTemp + 1);
    setTimeout(() => {}, 100);
  }, [selector.filter]);

  const [filterMostSearch, setFilterMostSearch] = useState(null);

  useEffect(() => {
    if (productValue.intervals.start === 0) {
      setFlagvalue(true);
      console.log("selector product", productValue);
      let selctorObj1 = selector?.filter
        ? Object.keys(selector?.filter).length
        : null;

      if (
        selector?.filter &&
        (selctorObj1 === 1
          ? !Object?.keys(selector?.filter["extraField"]).length !== 0
          : true)
      ) {
        setFilterFinal(selector?.filter);
        getProductLoc();
      } else {
        getProducts();
      }
    }
  }, [selector.filter, valueTemp]);

  useInterval((newvalue) => {
    if (productValue.product?.length > 0 && flagvalue) {
      let selctorObj1 = selector?.filter
        ? Object.keys(selector?.filter).length
        : null;
      if (
        selector?.filter &&
        (selctorObj1 === 1
          ? !Object?.keys(selector?.filter["extraField"]).length !== 0
          : true)
      ) {
        setFilterFinal(selector?.filter);
        getProductLoc();
      } else {
        getProducts();
      }
    }
  }, 2000);
  const getProductLoc = () => {
    setloaderAllAds(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Origin", "http://localhost:3000");
    myHeaders.append("Referer", "http://localhost:3000/");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36"
    );
    // myHeaders.append(
    //   "authorization",
    //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkxMTQ1MzkwLCJpYXQiOjE2NTk2MDkzOTAsImp0aSI6IjM5MzJjZjJkZTYyNTQwMjRiNTEyZDk4ZTE4ZDM1Mjk3IiwidXNlcl9pZCI6MjJ9.6uTC3ZTlxdqyhBewkVN5O4MduQ6O6YCNB6p9QJhOF3w"
    // );

    var formdata = new FormData();

    let value1 = selector.filter;

    const stringValue1 = JSON.stringify(value1).replace("location", "City");
    value1 = JSON.parse(stringValue1);

    formdata.append("start", productValue.intervals.start);
    formdata.append("end", productValue.intervals.end);
    // value1["extraField"]=JSON.stringify(value1["extraField"])
    // console.log(value1)
    formdata.append("requestData", JSON.stringify(value1));

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        start: productValue.intervals.start,
        end: productValue.intervals.end,
        requestData: value1,
      }),
      // redirect: "follow",
    };

    fetch(url + "api/adsapi/AdsDataFilter", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (false) {
          throw Error("Could not fetch data");
        } else {
          let s;
          let value = result.map((result) => {
            let a = { ...result.fields };
            a["pk"] = result.pk;
            return a;
          });
          if (localStorage.getItem("lat")) {
            s = Distance(value);

            // s = value;
            // this line was assing empty array that's why i have commented
          } else {
            s = value;
          }

          if (productValue?.product?.length === 0) {
            let obj = {
              product: s,
              intervals: {
                start: productValue.intervals.end + 1,
                end: productValue.intervals.end + 20,
              },
            };
            dispatch(productValueValue(obj));
            // dispatch(searchValue(s));
            console.log("@@product selector value", productValue);
          } else {
            if (result?.length !== 0) {
              let obj = {
                product: s,
                intervals: {
                  start: productValue.intervals.end + 1,
                  end: productValue.intervals.end + 20,
                },
              };
              let valueone = [
                ...productValue.product,
                ...s.map((result) => {
                  return result;
                }),
              ];
              console.log(valueone);
              obj.product = valueone;
              dispatch(productValueValue(obj));
              // dispatch(searchValue(valueone));
              setloaderAllAds(false);
            } else {
              setLockInterval(false);
              setloaderAllAds(false);
            }
          }
        }
        setloaderAllAds(false);
      })
      .catch((error) => console.log("error", error));
    // setloaderAllAds(false)
    setLoading(false);
    // setProducts([]);
  };
  // const productMaxMin=(array)=>{
  //   ;
  //   var obj={
  //   product:array,
  //   intervals:{start:0,end:8}
  // }

  //    obj={
  //     product:array,
  //     intervals:{start: obj.intervals.end + 1, end: obj.intervals.end + 20}
  //   }
  //   dispatch(newProductValue(obj))
  // }
  // const [apiData, setApiData] = useState([]);
  const getProducts = () => {
    // console.log("call the function ");
    setloaderAllAds(true);

    if (productValue?.product?.length === 0) {
      setLoading(true);
    }
    try {
      var formdata = new FormData();
      formdata.append("start", productValue.intervals.start);
      formdata.append("end", productValue.intervals.end);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(url + "api/adsapi/allAdsByInerval", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (false) {
            throw Error("Could not fetch data");
          } else {
            let s;
            let value = result.map((result) => {
              let a = { ...result.fields };
              a["pk"] = result.pk;
              return a;
            });
            if (localStorage.getItem("lat")) {
              s = Distance(value);
            } else {
              s = value;
            }

            // for search purpose storing data into a state
            // if (s.length) {
            //   setApiData(s);
            // }
            if (productValue?.product?.length == 0) {
              let obj = {
                product: s,
                intervals: {
                  start: productValue.intervals.end + 1,
                  end: productValue.intervals.end + 20,
                },
              };
              setproductnotSearch(obj);
              dispatch(productValueValue(obj));
              // dispatch(searchValue(s));
              console.log("@@product selector value", productValue);
              setLoading(false);
            } else {
              let curretProduct = result;
              if (result?.length != 0) {
                let obj = {
                  product: s,
                  intervals: {
                    start: productValue.intervals.end + 1,
                    end: productValue.intervals.end + 20,
                  },
                };
                console.log("sssss", s);
                // dispatch(searchValue(s));

                let valueone = [
                  ...productValue.product,
                  ...s.map((result) => {
                    return result;
                  }),
                ];

                console.log(valueone, "value one");
                dispatch(filterData({ valueone }));
                // dispatch(searchValue(...valueone));

                let valueone2 = [
                  ...productnotSearch.product,
                  ...s.map((result) => {
                    return result;
                  }),
                ];

                // console.log(valueone);
                var sliceArray = valueone.slice();
                let feturedvalue;
                if (filterMostSearch == "featured") {
                  feturedvalue = valueone.filter((product) => {
                    if (product.PlanCategory === "Featured") {
                      return product;
                    }
                  });
                  console.log("feature", valueone);
                  obj.product = feturedvalue;
                  dispatch(productValueValue(obj));
                  setloaderAllAds(false);
                } else if (filterMostSearch === "priceMinMax") {
                  let minmax = valueone
                    .slice()
                    .sort((a, b) => a.price - b.price)
                    .map((value) => value);
                  obj.product = minmax;
                  console.log("priceminmax", minmax);
                  dispatch(productValueValue(obj));
                } else if (filterMostSearch === "priceMaxMin") {
                  let maxmin = valueone
                    .slice()
                    .sort((a, b) => b.price - a.price);
                 
                  console.log("priceMaxMin", maxmin);
                  obj.product = maxmin;
                  dispatch(productValueValue(obj));
                } else if (filterMostSearch === "recentlyadded") {
                  let minmax = valueone
                    .slice()
                    .sort((a, b) =>new Date(b.date_created) - new Date(a.date_created))
                  obj.product = minmax;
                  console.log("recentlyadded", minmax);
                  dispatch(productValueValue(obj));
                } else if (filterMostSearch === "Older") {
                  let minmax = valueone
                    .slice()
                    .sort((a, b) =>new Date(a.date_created) - new Date(b.date_created))
                  obj.product = minmax;
                  console.log("Older", minmax);
                  dispatch(productValueValue(obj));
                } else {
                  obj.product = sliceArray;
                  dispatch(productValueValue(obj));
                  // dispatch(searchValue(sliceArray));
                }
                dispatch(filterData({ valueone }));
                // dispatch(searchValue(valueone));
              } else {
                setLockInterval(false);
              }
            }
          }
        })
        .catch((error) => console.log("error", error));
      // setloaderAllAds(false)
    } catch (error) {
      setLoading(false);
      setError("Something went wrong!! Please wait for sometimes!!");
    }
    setLoading(false);
  };

  // console.log("!!!api data", apiData);
  // useEffect(() => {
  //   console.log("hello");
  // }, [filterMostSearch]);
  const filerMostSearch = (value) => {
    setTimeout(() => {
      setloaderAllAds(false);
      setefilterOnClick(false);
    }, 2000);
    setloaderAllAds(true);
    setefilterOnClick(true);
    let value1 = value;
    console.log(value1, "vl");
    //after stop interval
    if (!lockInterval) {
      let array = productValue?.product?.slice();
      if (value1 === "default") {
        console.log("call all ", array);

        let obj = {
          product: fetchFromRedux,
          intervals: {
            start: productValue.intervals.end + 1,
            end: productValue.intervals.end + 20,
          },
        };

        dispatch(productValueValue(obj));
      } else if (value1 === "priceMinMax") {
        var minMax = productValue.product
          .slice()
          .sort((a, b) => a.price - b.price);
        console.log("call after minMax", minMax);
        let obj = {
          product: minMax,
          intervals: {
            start: productValue.intervals.end + 1,
            end: productValue.intervals.end + 20,
          },
        };
        dispatch(productValueValue(obj));
        console.log("call after interval", value1);
      } else if (value1 === "priceMaxMin") {
        var maxMin = productValue.product
          .slice()
          .sort((a, b) => b.price - a.price);
        let obj = {
          product: maxMin,
          intervals: {
            start: productValue.intervals.end + 1,
            end: productValue.intervals.end + 20,
          },
        };
        dispatch(productValueValue(obj));
      }else if (value1 === "recentlyadded") {
        var maxMin = productValue.product
          .slice()
          .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
        let obj = {
          product: maxMin,
          intervals: {
            start: productValue.intervals.end + 1,
            end: productValue.intervals.end + 20,
          },
        };
        dispatch(productValueValue(obj));
      }else if (value1 === "Older") {
        var maxMin = productValue.product
          .slice()
          .sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
        let obj = {
          product: maxMin,
          intervals: {
            start: productValue.intervals.end + 1,
            end: productValue.intervals.end + 20,
          },
        };
        dispatch(productValueValue(obj));
      } else if (value1 === "featured") {
        let feturedValue = productValue.product.filter((product) => {
          if (product.PlanCategory === "Featured") {
            return product;
          }
        });
        let obj = {
          product: feturedValue,
          intervals: {
            start: productValue.intervals.end + 1,
            end: productValue.intervals.end + 20,
          },
        };
        dispatch(productValueValue(obj));
      } else {
        let obj = {
          product: array,
          intervals: {
            start: productValue.intervals.end + 1,
            end: productValue.intervals.end + 20,
          },
        };
        dispatch(productValueValue(obj));
      }
    }
    if (value1 === "default") {
      setFilterMostSearch("default");
    }  else if (value1 === "priceMinMax") {
      setFilterMostSearch("priceMinMax");
    } else if (value1 == "priceMaxMin") {
      setFilterMostSearch("priceMaxMin");
    }else if (value1 == "recentlyadded") {
      setFilterMostSearch("recentlyadded");
    }else if (value1 == "Older") {
      setFilterMostSearch("Older");
    }
  };

  const dynamicFont = {
    width: 400,
    fontSize: 30,
    lineHeight: 30,
    overflow: "hidden",
  };

  useEffect(() => {
    const t = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => {
      clearTimeout(t);
    };
  }, []);

  // useEffect(() => {
  //   console.log(wishlistData)
  //   console.log("@@@array", wishlistData)

  //   getProducts()

  // }, [products])
  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add1({ value: value }));
    }

    console.log("wishlist value", value, wishlistData);
  };

  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };

  useEffect(() => {
    // searchArray();
  }, [selector.filter]);
  document.title = "Hola9 - ShowPropduct";
  // document.body.style = "background: #d1ced6;";
  return (
    <>
      <section className="inner-section ad-list-part mb-2" id="scroller">
        <div className="mx-2">
          <div className="row content-reverse">
            <div className="col-lg-12 col-md-12 col-xl-12">
              <div className="row container-fluid">
                <div className="col-lg-12">
                  <div className="fildivshow">
                    <div className="filter-short">
                    <Filter data={data1} />
                    <div className="d-flex" style={{ borderLeftRadius: 0, background: "white", marginLeft: "3cm" }}>
  <input
    className="searchfilinp"
    placeholder="Search Ads"
    onChange={(e) => setSearchValue(e.target.value.trim())}
    style={{
      display: "flex",
      justifyContent: "center",
      borderBottom: "1px solid black",
      padding: "8px"
    }}
  />
  <Link to="/ads-listing1/">
    <button className="px-3 py-2" onClick={searchArray} style={{backgroundColor:"black",color:"white" }}>
      <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px'}} />
    </button>
  </Link>
</div>
                    </div>

{/* here popup  for ad */}
{/* <div>
    {
     <PoppupAd />
    }
</div> */}



                   
                    <br/>
                    <div className="d-flex justify-content-between">
                    <div className="filter-short">
                      <select
                        placeholder="Sort by"
                        className="custom-select filter-select"
                        onChange={(e) => {
                          filerMostSearch(e.target.value);
                        }}
                        // onClick={(e) => {
                        //   filerMostSearch(e.target.value);
                        // }}
                      >
                        <option value="">choose</option>
                        <option value="recentlyadded">RecentlyAdded</option>
                        <option value="Older">Older</option>
                        <option value="priceMinMax">Price(Min-Max)</option>
                        <option value="priceMaxMin">Price(Max-Min)</option>
                        {/* <option value="featured">Featured</option> */}
                        {/* <option value="recommend">recommend</option> */}
                      </select>
                    </div>
                    <div className="filter-short">
                      <FilterBy />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  {selector?.filter ? (
                    <div className="d-flex mx-3  my-sm-2 flex-wrap">
                      {Object?.keys(selector?.filter).map((key) => {
                        let value = selector?.filter;

                        console.log(value, key, value[key]);
                        if (key === "extraField") {
                          console.log(
                            value,
                            value[key],
                            typeof value[key],
                            selector?.filter["extraField"],
                            value["extraField"]
                          );
                          Object?.keys(value[key]).map((key1) => {
                            return (
                              <div
                                className="mx-2"
                                style={{
                                  borderRadius: "3px",
                                  color: "#2b5876",
                                  background: "white",
                                  border: "1px solid #2b5876",
                                  padding: "1px 6px",
                                  marginBottom: "10px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  onClick={() => {}}
                                ></button>
                                {value[key][key1]}
                              </div>
                            );
                          });
                        } else {
                          return (
                            <div
                              className="mx-2"
                              style={{
                                borderRadius: "3px",
                                color: "#2b5876",
                                background: "white",
                                border: "1px solid #2b5876",
                                padding: "1px 6px",
                                marginBottom: "10px",
                              }}
                            >
                              <button
                                type="button"
                                class="btn-close"
                                aria-label="Close"
                                onClick={() => {
                                  // setsubcategoryfromHome(null);

                                  setProducts([]);
                                  setInterval({ start: 0, end: 8 });
                                  dispatch(removeKeyPair(key));

                                  // dispatch(getExtraField(value))
                                }}
                              ></button>
                              {value[key]}
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : null}
                </div>
                <div className="col-lg-3 col-sm-12">
                  {selector?.filter ? (
                    <div>
                      <div className="d-flex px-4 my-2 flex-wrap">
                        {selector?.filter["extraField"] &&
                          Object.keys(selector?.filter["extraField"]).map(
                            (key1) => {
                              if (true) {
                                let value = selector?.filter["extraField"];
                                console.log(value);
                                return (
                                  <div
                                    className="mx-2"
                                    style={{
                                      borderRadius: "10px",
                                      color: "white",
                                      background: "navy",
                                      border: "1px solid #2b5876",
                                      padding: "1px 6px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      class="btn-close"
                                      aria-label="Close"
                                      onClick={() => {
                                        setProducts([]);
                                        setInterval({ start: 0, end: 8 });
                                        dispatch(
                                          removeKeyPair({ extra: key1 })
                                        );
                                      }}
                                    ></button>
                                    {value[key1]}
                                  </div>
                                );
                              }
                            }
                          )}
                      </div>
                      <div
                        className="text-center"
                        style={{ marginTop: "-20px" }}
                      >
                        <button
                          onClick={() => {
                            setProducts([]);
                            setInterval({ start: 0, end: 8 });
                            dispatch(removeFiled());
                          }}
                        >
                         <Delete style={{color:"red"}}/> Clear Filter
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Row style={{ margin: "1px 30px" }}>
                <Col
                  span={24}
                  style={{
                    color: "black",
                    fontSize: "18px",
                    fontFamily: "Georgia",
                  }}
                >
                  <ins>Filtered By :</ins>
                </Col>
              </Row>
              <div>
                {loaderAllAds &&
                (productValue?.product?.length == 0 || filterOnClick) ? (
                  <YoutubeMagic1 />
                ) : null}
                {!loaderAllAds && productValue?.product?.length == 0 ? (
                  <div>
                    <NoDataFound />
                  </div>
                ) : null}
              </div>
              {/* isloading ? <YoutubeMagic1/>: */}
              <div className="row " style={isMobile ? mobstyle : deskstyle}>
              <div className="ads-container">
        <div className="card-grid">
                {error && <div className="alert alert-warning">{error}</div>}
                {loading && <Spiner />}
                {!loading && grid && productValue?.product?.length > 0 ? (
                  currentPageData?.map((ad, index) =>
                    currentPageData?.length > 0 ? (
                      <div key={ad.pk} className="ad-card" onClick={() => navigate(`/ads-listing/${
                        ad?.pk === undefined ? ad?.pk : ad.pk
                      }/`)}>
                        <div className="glassmorphism">
                          <div>
                          <div className="ad-image">
                          <div className="wtrmrk">Hola9.com</div>
                          <img className="imghover-scale"
                                            src={
                                              !ad?.image
                                                ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                                : ad.image
                                            }
                                            alt="ad"
                                            onError={(e) => {
                                              e.target.src =
                                                "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                                              e.onerror = null;
                                            }}
                                          />
                          </div>
                          <div className="product-type">
                          <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                            <span className="flat-badge booking" >
                                            <b>
                                                  <span
                                                    className="flat-badge booking "
                                                    style={{
                                                      background:
                                                      ad?.plan ==="Silver"
                                                          ? "#cce6ff"
                                                          : ad?.plan === "Gold"
                                                          ? "gold"
                                                          : ad?.plan === "Platinum"
                                                          ? "green"
                                                          : ad?.plan === "Premium"
                                                          ? "linear-gradient(to right, #800080, #8A2BE2)"
                                                          : ad?.plan === "Featured"
                                                          ? "linear-gradient(to right, #090979, #00d4ff)"
                                                          // : ad?.plan === "Free"
                                                          // ? "green"
                                                          : "transparent",
                                                      color:
                                                      ad?.plan ==="Silver"
                                                          ? "blue"
                                                          : ad?.plan === "Gold"
                                                          ? "black"
                                                          : ad?.plan === "Platinum"
                                                          ? "white"
                                                          : ad?.plan === "Premium"
                                                          ? "white"
                                                          : ad?.plan === "Featured"
                                                          ? "white"
                                                          // : ad?.plan === "Free"
                                                          // ? "white"
                                                          : "transparent",
                                                          padding:"5px",
                                                         
                                                      // backgroundColor: "white",
                                                      // float:"left",
                                                      // border: "2px solid black",
                                                    }}
                                                  >
                                                    {ad?.plan}
                                                  </span>
                                                </b>
                                            </span>
                                            <img
                                      src={verified}
                                      width={22}
                                      height={22}
                                      style={{
                                        marginRight: "-10px",
                                        borderRadius: "50%",
                                      }} />
                                </div>
                                           
                                          </div>
                                          <ul className="viewsub">
                                          <li className="view">
                                            <i
                                              className="fas fa-eye"
                                              style={{ color: "white" }}
                                            />
                                            <span style={{ color: "white" }}>
                                              {ad?.viewsproduct}
                                            </span>
                                          </li>
                                          {/* <li className="click">
                                            <i className="fas fa-mouse" />
                                            <span>134</span>
                                          </li> */}
                                          <li className="rating">
                                            <i className="" style={{ color: "white" }} />
                                            <span style={{ color: "white" }}>
                                              <BsNewspaper />
                                              &nbsp;  {ad?.subCategoryValue?.slice(0,12)+"..."}
                                            </span>
                                          </li>
                                        </ul>
                                        </div>
                                        <div className="ad-details  m-0 p-0">
                                           {/* DESKTOP VIEW */}
                                           <p className="titlefont hidden-sm hidden-xs  m-0 p-0">
                                             <Tooltip
                                               placement="topLeft"
                                               title={ad?.title}
                                             >
                                               {ad?.title?.length > 17 ? (
                                                 <p className="titlefont">
                                                   {ad?.title?.slice(0, 17)}
                                                   {ad?.title?.length > 17
                                                     ? "..."
                                                     : null}
                                                 </p>
                                               ) : (
                                                 <div>
                                                   <p className="titlefont">
                                                     <DynamicFont
                                                       content={ad?.title}
                                                     />
                                                   </p>
                                                 </div>
                                               )}
                                             </Tooltip>
                                           </p>
                                           {/* DESKTOP VIEW */}
           
                                           {/* MOBILE VIEW */}
                                           <p className="titlefont d-lg-none">
                                             <Tooltip
                                               placement="topLeft"
                                               title={ad?.title}
                                             >
                                               {ad?.title?.length > 12 ? (
                                                 <p className="titlefont">
                                                   {ad?.title?.slice(0, 12)}
                                                   {ad?.title?.length > 12
                                                     ? "..."
                                                     : null}
                                                 </p>
                                               ) : (
                                                 <div style={{}}>
                                                   <p className="titlefont">
                                                     <DynamicFont
                                                       content={ad?.title}
                                                     />
                                                   </p>
                                                 </div>
                                               )}
                                             </Tooltip>
                                           </p>
                                           {/* MOBILE VIEW */}
                                           <div
                                             className="condiv justify-content-between "
                                             style={{ marginTop: "-15px", alignItems:'center', marginBottom:'6px', gap:'10px' }}
                                           >
                                             <div className="widhdiv d-flex justify-content-between m-0 p-0  mob-v-space">
                                               <p
                                                 className="fs-10 m-0 p-0 mt-1"
                                                 style={{
                                                   color: "#062544",
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                 }}
                                               >
                                                 <BsGeoAltFill
                                                   className="mr-1 "
                                                   style={{ color: "red" }}
                                                 />
                                                 <b>{ad?.City}</b>
                                               </p>
                                               <BsFillBookmarkFill
                                                 id="wishlibyn"
                                                 style={{
                                                   fontSize: "20px",
                                                   marginTop: "6px",
                                                 }}
                                                 className={
                                                   wishlistData?.data?.indexOf(ad.pk) !==
                                                   -1
                                                     ? "removemob"
                                                     : "heartmob"
                                                 }
                                                 onClick={(e) => {
                                                   e.stopPropagation();
                                                   e.preventDefault();
                                                   addingWishlist(ad.pk);
                                                 }}
                                               />
                                             </div>
                                             <div className="mob-v-space">
                                             {ad?.category == "RealEstate" ? (
                                               <p
                                                 className="conpir fs-14"
                                                 style={{
                                                   color: "gray",
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                   fontWeight: "bold",
                                                   //  margin:'0'
                                                 }}
                                               >
                                                 <BsBuilding
                                                   className="mr-2"
                                                   style={{
                                                     color: "green",
                                                     fontSize: "90%",
                                                   }}
                                                 />
                                                 {ad.subCategoryType?.slice(0, 9)}
                                               </p>
                                             ) : (
                                               <p
                                                 className="conpir fs-14"
                                                 style={{
                                          
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                   margin:'0',
                                                   transform:'unset'
                                                   
                                                 }}
                                               >
                                                 <BsFillCheckSquareFill
                                                   className="mr-1"
                                                   style={{
                                                     color: "green",
                                                     fontSize: "90%",
                                                   }}
                                                 />
                                                 {ad?.condition?.slice(0, 9)}
                                               </p>
                                             )}
                                                <span className="mob-star">
                                <i className="fa fa-star" style={{color:'gold'}}> <span style={{color:'gray'}}>4.5</span></i>
                              </span>
                                </div>
                                </div>
                                <div className="price-bot-mob">
                                           {ad?.category != "Jobs" ?
                                (
                                <p
                                  className="ad-price mr-1"
                                  style={{
                                    fontSize: "90%",
                                    fontFamily: "verdana",
                                    // marginTop: "-15px",
                                    margin:'0'
                                  }}
                                >
                                  &nbsp;₹ <b>{ad?.price}</b>
                                </p>):(<>
                                  {ad?.extraField && (
 <p  className="ad-price mr-1"
 style={{
   fontSize: "90%",
   fontFamily: "verdana",
  //  marginTop: "-15px",
 }}>

&nbsp;<GiMoneyStack /><b>{(JSON?.parse(ad?.extraField)["MaxSalery"])}</b>
</p>
)} </>)}
<span className="desk-star">
                                <i className="fa fa-star" style={{color:'gold'}}> <span style={{color:'gray'}}>4.5</span></i>
                              </span>
                              </div>
                                           <button
                                             id="wishbbt"
                                             style={{ marginTop: "-15px" }}
                                             className={
                                               wishlistData?.data?.indexOf(ad.pk) !== -1
                                                 ? "remove-button"
                                                 : "wishlist-button"
                                             }
                                             onClick={(e) => {
                                               e.stopPropagation();
                                               e.preventDefault();
                                               addingWishlist(ad.pk);
                                             }}
                                           >
                                             {wishlistData?.data?.indexOf(ad.pk) !== -1 ? (
                                               <>
                                                 <AiFillDelete />
                                                 &nbsp;&nbsp;Remove Wishlist
                                               </>
                                             ) : (
                                               <>
                                                 <BsFillBookmarkFill />
                                                 &nbsp;&nbsp;Add To Wishlist
                                               </>
                                             )}
                                           </button>
                                         </div>
                        </div>
                      </div>
                    ) : null
                  )
                ) : loading ? (
                  <div>{/* Loading.... */}</div>
                ) : null}
                </div>
                </div>
              </div>
              <br></br>
              <SweetPagination
                navigation={true}
                currentPageData={setCurrentPageData}
                dataPerPage={productvalue}
                getData={productValue?.product}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ShowProduct;
