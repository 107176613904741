import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { UserContext } from "../App";
import Axios from "axios";
import { Modal } from "antd";
import PhoneInput from "react-phone-input-2";
import { Col, Row } from "antd";
import "./bussinesspricing.css"
import {
  adsFilter,
  businessPrimiumAds,
  businessPrimiumValidity,
  localUrl,
  metroCity,
  validateFilter,
} from "../component/env";

const BusinessPricing = () => {
  const user1 = useContext(UserContext);
  const objPricingDependsOnAds={
    "Furnitures":3,
    "realestate":4,
    "bikes":5,
    "pets":6,
    "electronics":7,
    "cars":8,
    "Education":6,
    "mobiles":4,
    "services":6,
  }
  // for premium plan
  const value=useParams()
  console.log("value",value)
  const [adsCategory,setadsCategory]=useState(value?.id)
  const [totalPrice,setTotalPrice]=useState(value?.id)
  const [totalAds,settotalAds]=useState(value?.id)
  const [totalAdsMonth,settotalAdsMonth]=useState(value?.id)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [primiumPrice, setPrimiumPrice] = useState(
    adsFilter[0].price * validateFilter[0].price * objPricingDependsOnAds[value?.id]
  );
  const [planValue, setPlanValue] = useState(null);
  const [dataValue, setDataValue] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [phone, setPhoneNumber] = useState(null);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [finalObj, setFinalObj] = useState(null);
  const [noOfAds, setNoOfAds] = useState({
    ads: adsFilter[0].ads,
    price: adsFilter[0].price,
  });
  const [noOfMonth, setNoOfMonth] = useState({
    days: validateFilter[0].days,
    price: validateFilter[0].price,
  });
  const [premiumCity, setPremiumCity] = useState("");
  const [phoneError, setPhoneError] = useState("");

  // for featured plan

  const [featuredPrice, setFeaturedPrice] = useState(
    adsFilter[0].price * validateFilter[0].price * objPricingDependsOnAds[value?.id]
  );
  const [noOfFeaturedAds, setNoOfFeaturedAds] = useState({
    ads: adsFilter[0].ads,
    price: adsFilter[0].price,
  });
  const [noOfFeaturedMonth, setNoOfFeaturedMonth] = useState({
    days: validateFilter[0].days,
    price: validateFilter[0].price,
  });
  const [featuredCity, setFeaturedCity] = useState("");
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = (props) => {
    if (phoneError) {
      console.error("Please fix the errors before submitting the form.");
      return;
    }
    setIsModalOpen(false);
    console.log("props", props);
    // let idValue = user1.userid ? user1.userid : Navigate("/login");
    let idValue=userid;
    var formdata = new FormData();
    // formdata =
    //   props === "Free"
    //     ? Free
    //     : props === "Silver"
    //     ? Silver
    //     : props === "Gold"
    //     ? Gold
    //     : Platinum;
    formdata["price"] = 5;
    let priceValue = formdata["price"];
    setName(idValue);
    setAmount(priceValue);
    let tempFinalObj = {};
    tempFinalObj["plan"] = formdata;
    tempFinalObj["price"] = priceValue;
    tempFinalObj["user"] = idValue;
    setFinalObj(tempFinalObj);
    let planValue = props;
    let outputValue = showRazorpay(
      priceValue,
      idValue,
      tempFinalObj,
      planValue
    );
  };
  const userid = localStorage.getItem("userid");
  
  const showRazorpay = async (v1, v2, tempFinalObj, planValue) => {
    console.log("temp final", tempFinalObj);

    console.log('Featured',noOfFeaturedAds.ads+" "+noOfFeaturedMonth.days+" "+featuredCity);
    // const res = await loadScript();

    if(currentPlan == 'Premium'){
    let amount = v1;
    let name = v2;
    console.log(v1, v2, amount, name);
    let bodyData = new FormData();
    // we will pass the amount and product name to the backend using form data
    // bodyData.append("amount", amount);

    console.log('values',featuredPrice+" "+noOfFeaturedAds.ads+" "+noOfFeaturedMonth.days+" "+featuredCity);
   
    bodyData.append("IdValue", userid);
    bodyData.append("planValue", currentPlan);
    bodyData.append("Phone", phone);
    bodyData.append("adsCategory",adsCategory);
    bodyData.append("PriceValue",currentPlan == "Premium"? primiumPrice:featuredPrice);
    bodyData.append("adsValue",currentPlan == "Premium"? noOfAds.ads : noOfFeaturedAds.ads);
    bodyData.append("monthsVale",currentPlan == "Premium"? noOfMonth.days:noOfFeaturedMonth.days);
    bodyData.append("city",currentPlan == "Premium"?premiumCity:featuredCity);
    // bodyData.append('userID','5')
    // bodyData.append('PlanID','12')
    const data = await Axios({
      // url: `${server}/razorpay/pay/`,
      url: localUrl + "payment/create_token",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: bodyData,
    }).then((res) => {
      // finalObjOnce = res.data.order;
      debugger
      document.getElementById("plan").value = JSON.stringify(res);
      localStorage.setItem("planValue", JSON.stringify(res));
      setDataValue(res);
      console.log(res);
      document.getElementById("paymentCalling").click();
      debugger;

      // setOrder(res?.data?.order);
      return res;
    });
  }
  if(currentPlan == 'Featured'){
     let name = v2;
    let bodyData = new FormData();
    // we will pass the amount and product name to the backend using form data
    // bodyData.append("amount", amount);

    console.log('values',featuredPrice+" "+noOfFeaturedAds.ads+" "+noOfFeaturedMonth.days+" "+featuredCity);
   const plan="Featured";
   const ads=noOfFeaturedAds.ads;
   const days=noOfFeaturedMonth.days;
   const planValue = plan.substring(0, plan.length );
   const phoneNumber=phone.substring(0,phone.length);
   const adsCategory2=adsCategory.substring(0,adsCategory.length);
   const price=featuredPrice;
   const adsValue=ads.substring(0,ads.length);
   const monthsVale = days.substring(0,days.length);
   const city = featuredCity.substring(0,featuredCity.length);

   console.log('Values',planValue+" "+phoneNumber+" "+adsCategory2+" "+price+" "+adsValue+" "+monthsVale+" "+city);
    bodyData.append("IdValue", userid);
    bodyData.append("planValue", planValue);
    bodyData.append("Phone", phoneNumber);
    bodyData.append("adsCategory",adsCategory2);
    bodyData.append("PriceValue",price);
    bodyData.append("adsValue",adsValue);
    bodyData.append("monthsVale",monthsVale);
    bodyData.append("city",city);
    // bodyData.append('userID','5')
    // bodyData.append('PlanID','12')
    const data = await Axios({
      // url: `${server}/razorpay/pay/`,
      url: localUrl + "payment/create_token",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: bodyData,
    }).then((res) => {
      // finalObjOnce = res.data.order;
      debugger
      document.getElementById("plan").value = JSON.stringify(res);
      localStorage.setItem("planValue", JSON.stringify(res));
      setDataValue(res);
      console.log(res);
      document.getElementById("paymentCalling").click();
      debugger;

      // setOrder(res?.data?.order);
      return res;
    });
    
  }
}

 
const [activeTab, setActiveTab] = useState("tab-11")
  
const handleActiveTab = (event) => {
    setActiveTab(event.target.id)
  }
  
  useEffect(()=>{
   
    console.log("value useEffect",value,objPricingDependsOnAds[value?.id])
  },[]);
  
  useEffect(() => {
    setPrimiumPrice(noOfAds.price * noOfMonth.price * objPricingDependsOnAds[value?.id]);
  }, [noOfMonth.days, noOfAds.ads]);

  useEffect(() => {
    setFeaturedPrice(noOfFeaturedAds.price * noOfFeaturedMonth.price * objPricingDependsOnAds[value?.id]);
  }, [noOfFeaturedMonth.days, noOfFeaturedAds.ads]);
  console.log("Price", primiumPrice);
  const handleFreePlan = (e) => {
    console.log("eeeee",e)
    showModal(e)
    // alert("Checking");
  };
  const showModal = (e) => {
    setIsModalOpen(true);
    setCurrentPlan(e);
    if (e == "Premium") {
      setPlanValue(primiumPrice);
    } else if (e == "Featured") {
      setPlanValue(featuredPrice);
    }

    console.log(e);
  };

  const validatePhoneNumber = (value) => {
    const phoneNumberRegex = /^\d{12}$/;
    return phoneNumberRegex.test(value);
  };

  // Handler for phone number input change
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    if (validatePhoneNumber(value)) {
      setPhoneError("");
    } else {
      setPhoneError("Please enter a valid 10-digit phone number.");
    }
  };

const isButtonEnabled = () => {
    return (noOfMonth.days && noOfAds.ads && premiumCity !== '');
  };
  return (
    <div>
         <Modal
        title="Payment Now"
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText="Pay Now"
      >
        <Row>
          <Col span={8}> Phone Number</Col>
          <Col span={16}>
            {" "}
            <PhoneInput
              country={"in"}
              onlyCountries={["in"]}
              value={phone}
              onChange={handlePhoneChange}            />
                        {phoneError && <span style={{ color: "red" }}>{phoneError}</span>}
          </Col>
          <Col span={12}>Plan</Col>
          <Col span={12}>{planValue}</Col>
          <Col span={12}>GST</Col>
          <Col span={12}>18%</Col>
          <Col span={12}>Total</Col>
          <Col span={12}>
            {Math.floor(planValue + (planValue * 18) / 100)}
            .00
          </Col>
        </Row>
      </Modal>
      {" "}
      
     
      <div className="cardcomp1">
    <div className="wrappercard1">
      <input type="radio" name="slider" id="tab-11" defaultChecked="" onChange={handleActiveTab} />
      <input type="radio" name="slider" id="tab-21" onChange={handleActiveTab} />
    <h5>Get New Customer's Faster</h5>
      <header>
        <label htmlFor="tab-11" className={`tab-11 ${activeTab === "tab-11" ? "active-tab" : ""}`}
              style={{
                backgroundColor: activeTab === "tab-11" ? "#082144" : "",
                color: activeTab === "tab-11" ? "white" : "",
                borderRadius: activeTab === "tab-11" ? "20px" : "",
              }}>
          Premium
        </label>

        <label htmlFor="tab-21" className={`tab-21 ${activeTab === "tab-21" ? "active-tab" : ""}`}
              style={{
                backgroundColor: activeTab === "tab-21" ? "#082144" : "",
                color: activeTab === "tab-21" ? "white" : "",
                borderRadius: activeTab === "tab-21" ? "20px" : "",
              }}>
          Feature
        </label>

      

        <div className="slider1" />
      </header>
      <div className="card-area1">
        <div className="cardsd1">
          <div className="rowsd1 row-1">
            <div className="price-details1"><br/>
              <span className="price1">₹{primiumPrice}</span>
               <ul className="d-grid justify-content-center">
                      <li className="vis"><h6>
                        <i className="vis fas fa-check text-success" />
                        Premium ads 2 times visiblity of free ads</h6>
                      </li>
                      <div className="planfa d-flex">
                      <div className="vis d-flex my-2 ">
                      <h6>
                        No. of Ads :
                        {businessPrimiumAds?.map((val, index) => (
                          <li key={val} className="p-1 fs-14">
                            <i className="fas fa-check text-success" />
                            {val} Ads
                          </li>
                        ))}</h6>
                      </div>
                      <div className="gap-div" />
                      <div className="vis d-flex my-2 ">
                        <h6>
                        No. of Months :
                        {businessPrimiumValidity?.map((val, index) => (
                          <li key={val} className="p-1 fs-14">
                            <i className="fas fa-check text-success" />
                            {val} Months
                          </li>
                        ))}</h6>
                      </div>
                      </div>
                    </ul>
            </div>

            <div className="row my-2 ">
                      <div className="col-4">
                      <span style={{color: 'red'}}>*</span>
                        <span> Select Ads : </span>
                      </div>
                      <div className="col-8 text-start">
                        <Select 
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          defaultValue="Choose"
                          value={noOfAds.ads}
                          style={{
                            width: 150,
                            marginLeft: "1rem"
                          }}
                          onChange={(value, { label }) => {
                            console.log(value, "check", label);
                            setNoOfAds((pre) => ({
                              ...pre,
                              ads: label,
                              price: value,
                            }));
                          }}
                          options={adsFilter?.map((val) => {
                            return { value: val.price, label: val.ads };
                          })}
                        ></Select>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-4">
                      <span style={{color: 'red'}}>*</span>
                        <span>Select Months :</span>
                      </div>
                      <div className="col-8  text-start">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          defaultValue="Choose"
                          value={noOfMonth.days}
                          style={{
                            width: 150,
                            marginLeft: "1rem"
                            
                          }}
                          onChange={(value, { label }) => {
                            console.log(value, "checkmonth");
                            setNoOfMonth((pre) => ({
                              ...pre,
                              days: label,
                              price: value,
                            }));
                          }}
                          options={validateFilter?.map((val) => {
                            return { value: val.price, label: val.days };
                          })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                      <span style={{color: 'red'}}>*</span>
                        <span> Select City : </span>
                      </div>
                      <div className="col-8  text-start">
                        <Select aria-required
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          defaultValue="Choose"
                            value={premiumCity}
                          style={{
                            width: 150,
                            marginLeft: "1rem"
                          }}
                          onChange={(value) => {
                            setPremiumCity(value);
                          }}
                          options={metroCity?.map((val) => {
                            return { value: val, label: val };
                          })}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      id="plan"
                      onClick={() => handleFreePlan("Premium")}
                    >
                      Buy Now
                    </button>
          </div>
          <div className="rowsd1  ">
            <div className="price-details1"><br/>
            <span className="price1"> ₹{featuredPrice}</span>
            <ul className="d-grid justify-content-center">
                      <li className="vis"><h6>
                        <i className="vis fas fa-check text-success" />
                        Featured ads 5 times visiblity of free ads</h6>
                      </li>
                      <div className="planfa d-flex">
                      <div className="vis d-flex my-2 ">
                      <h6>
                        No. of Ads :
                        {businessPrimiumAds?.map((val, index) => (
                          <li key={val} className="p-1 fs-14">
                            <i className="fas fa-check text-success" />
                            {val} Ads
                          </li>
                        ))}</h6>
                      </div>
                      <div className="gap-div" />
                      <div className="vis d-flex my-2 ">
                        <h6>
                        No. of Months :
                        {businessPrimiumValidity?.map((val, index) => (
                          <li key={val} className="p-1 fs-14">
                            <i className="fas fa-check text-success" />
                            {val} Months
                          </li>
                        ))}</h6>
                      </div>
                      </div>
                    </ul>
            </div>
            <div className="row my-2">
                      <div className="col-4">
                      <span style={{color: 'red'}}>*</span>
                        <span> Select Ads : </span>
                      </div>
                      <div className="col-8 text-start">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          defaultValue="Choose"
                          value={noOfFeaturedAds.ads}
                          style={{
                            width: 150,
marginLeft: "1rem"
                          }}
                          onChange={(value, { label }) => {
                            console.log(value, "check", label);
                            setNoOfFeaturedAds((pre) => ({
                              ...pre,
                              ads: label,
                              price: value,
                            }));
                          }}
                          options={adsFilter?.map((val) => {
                            return { value: val.price, label: val.ads };
                          })}
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-4">
                      <span style={{color: 'red'}}>*</span>
                        <span>Select Months :</span>
                      </div>
                      <div className="col-8  text-start">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          defaultValue="Choose"
                          value={noOfFeaturedMonth.days}
                          style={{
                            width: 150,
marginLeft: "1rem"
                          }}
                          onChange={(value, { label }) => {
                            console.log(value, "checkmonth");
                            setNoOfFeaturedMonth((pre) => ({
                              ...pre,
                              days: label,
                              price: value,
                            }));
                          }}
                          options={validateFilter?.map((val) => {
                            return { value: val.price, label: val.days };
                          })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                      <span style={{color: 'red'}}>*</span>
                        <span> Select City : </span>
                      </div>
                      <div className="col-8  text-start">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          defaultValue="Choose"
                          // value={featuredCity}
                          style={{
                            width: 150,
marginLeft: "1rem"
                          }}
                          onChange={(value, { label }) => {
                            setFeaturedCity(value);
                          }}
                          options={metroCity?.map((val) => {
                            return { value: val, label: val };
                          })}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleFreePlan("Featured")}
                    >
                      Buy Now
                    </button>
          </div>
        </div>
      </div>
     
    </div>
    </div>

    </div>
  );
};

export default BusinessPricing;