import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../component/Ads/category/Scroll.css";
import { isMobile } from "react-device-detect";
import "./petsSlider.css"

const Petslider= () => {
  const mobileStyle = {
    width: "80px",
    height: "80px",
    
  };
  const desktopStyle = {
    backgroundSize: "cover",
    width: "130px",
    height: "160px",
  };
  const mobilestyle1= {
    width :"auto",
    flexDirection: "row",
   
  };
  const desktopstyle1 = {
    width :"70%",
    flexDirection: "row"
  };
  const mobilestyle2= {
    display: "flex",
    flexDirection: "column",
  };
  const desktopstyle2 = {
    display: "flex",
    flexDirection: "column",
    width :"30%",
  };
  const mobilestyle3= {
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem"
  };
  const desktopstyle3 = {
    display: "flex",
    flexDirection: "row",
  };
  const mobilestyle4= {
    fontSize: "12px",textAlign: "center",color: "white", backgroundColor: "gray"
  };
  const desktopstyle4 = {
    textAlign: "center",color: "white", backgroundColor: "gray",fontSize:"16px" 
  };
  return (
    <>
    <h3 style={{backgroundColor: "#082a3b",textAlign: "center", color :"white", padding: "1rem",fontFamily: "verdana"}}>Welcome To Our Pets World</h3>
    <div style={isMobile? mobilestyle3 : desktopstyle3}>
      <div className="container1"
        style={isMobile? mobilestyle1 : desktopstyle1}
      >
          <div style={{border :'2px solid gray',}} className="mx-1">
          <center>
            <Link to={`/ads-listing/location/${"Bengaluru"}/`}>
              <img
                alt="imag"
                src="https://img.freepik.com/premium-vector/cute-pet-care-icon-illustration_278222-4771.jpg"
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
                // height="80px"
              />
              <h5
                className="mt-2 mb-2"
                style={isMobile ? mobilestyle4 : desktopstyle4}
              >
                Pets Care
              </h5>
            </Link>
            </center>
          </div>
          <div style={{border :'2px solid gray'}} className="mx-1">
          <center>
            <Link to={`/ads-listing/location/${"Bengaluru"}/`}>
          
              <img
                alt="imag"
                src="https://static.vecteezy.com/system/resources/previews/009/762/188/non_2x/pet-clinic-icon-isolated-contour-symbol-illustration-vector.jpg"
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
               
              />
              <h5
                className=" mt-2 mb-2"
                style={isMobile ? mobilestyle4 : desktopstyle4}
              >
                Pets Clinic
              </h5>
            </Link>
            </center>
          </div>
          <div style={{border :'2px solid gray'}} className="mx-1">
          <center>
            <Link to={`/ads-listing/location/${"Bengaluru"}/`}>
          
              <img
                alt="imag"
                src="https://thumbs.dreamstime.com/b/line-art-black-white-full-pet-food-bowl-line-art-black-white-full-pet-food-bowl-cat-dog-care-themed-vector-illustration-111828018.jpg"
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
                // height="80px"
              />
              <h5
                className=" mt-2 mb-2"
                style={isMobile ? mobilestyle4 : desktopstyle4}
              >
                Pets Food
              </h5>
            </Link>
            </center>
          </div>
          <div style={{border :'2px solid gray'}} className="mx-1">
          <center>
            <Link to={`/ads-listing/category/${"Pets"}/subcategory/${"PetFood"}`}>
              <img
                alt="imag"
                src="https://static.vecteezy.com/system/resources/previews/007/836/685/non_2x/pet-shop-logo-illustration-vector.jpg"
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
                // height="80px"
              />
              <h5
                className="mt-2 mb-2"
                style={isMobile ? mobilestyle4 : desktopstyle4}
              >
                Pets Shop
              </h5>
            </Link>
            </center>
          </div>
          <div style={{border :'2px solid gray'}} className="mx-1">
          <center>
            <Link to={`/ads-listing/location/${"Bengaluru"}/`}>
          
              <img
                alt="imag"
                src="https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX50153865.jpg"
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
                // height="80px"
              />
              <h5
                className=" mt-2 mb-2"
                style={isMobile ? mobilestyle4 : desktopstyle4}
              >
                Pets Training
              </h5>
            </Link>
            </center>
          </div>
      </div>
    
      <div className="pets mt-3 mr-2 mb-3" 
      style={isMobile? mobilestyle2 : desktopstyle2}>
      <div className="gallery1">
        <img
          src="https://www.dailypaws.com/thmb/B6yWhzGpQZsg3kxMzLn-hvGIF7M=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/siberian-husky-100800827-2000-9449ca147e0e4b819bce5189c2411188.jpg"
          alt="a lovely kiss in the night"
        />
        <img
          src="https://cdn.pixabay.com/photo/2021/01/21/16/17/english-cocker-spaniel-5937757_1280.jpg"
          alt="a women inside a car"
        />
        <img
          src="https://cdn.pixabay.com/photo/2016/02/19/15/46/labrador-retriever-1210559_1280.jpg"
          alt="a baby"
        />
        <img
          src="https://t3.ftcdn.net/jpg/00/33/76/64/360_F_33766426_7IWdoKFDhIDVFsaxC43zKH0LhyN5z8Kw.jpg"
          alt="a baby"
        />
        <img
          src="https://media.istockphoto.com/id/1196201462/photo/shih-tzu.jpg?s=612x612&w=0&k=20&c=LGQzq2Y5ZPVD9EzXEV9bh1So6G05vA585sNzbO41HcY="
          alt="a baby"
        />
        <img
          src="https://d.newsweek.com/en/full/2195249/german-shepherd-eating-ice-cream.jpg?w=1600&h=1200&q=88&f=d3c379273d7472d31cc9e5f336a80a12"
          alt="a baby"
        />
      </div>
      <div className="gallery1">
        <img
          src="https://cdn.pixabay.com/photo/2016/02/10/16/37/cat-1192026_640.jpg"
          alt="a lovely kiss in the night"
        />
        <img
          src="https://cdn.pixabay.com/photo/2016/02/10/16/37/cat-1192026_640.jpg"
          alt="a women inside a car"
        />
        <img
          src="https://images.all-free-download.com/images/graphiclarge/cat_cute_cat_cat_face_271618.jpg"
          alt="a baby"
        />
        <img
          src="https://www.petsworld.in/blog/wp-content/uploads/2014/09/lovely-white-cat-image.jpg"
          alt="a baby"
        />
        <img
          src="https://cdn.wamiz.fr/cdn-cgi/image/format=auto,quality=80,width=776,fit=contain/article/images/UK%20Image/CAT/tabby-maine-coon-cat.jpg"
          alt="a baby"
        />
       
      </div>
    </div>
    </div>
    
    </>
  );
};

export default Petslider;
