import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./Scroll.css";
import sofa from "../../images/sofa.webp";
import ghar from "../../images/ghar.png";
import Dog from "../../images/Dog.png";
import bike from "../../images/bike.webp";
import car from "../../images/car.png";
import electronics from "../../images/electronics.webp";
import mobiles from "../../images/mobiles.png";
import Services from "../../images/Service1.png";
import Education from "../../images/Education.png";
import Job from "../../images/job111.png";
import { isMobile } from "react-device-detect";
import { Jobs } from "../../env";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import TrendingAds from "../Trending Ads/TrendingAds";

const Scroll = () => {
  const mobileStyle = {
    backgroundSize: "cover",
    width: "60px",
    height: "60px",
  };
  const desktopStyle = {
    backgroundSize: "cover",
    width: "70px",
    height: "70px",
  };

  const images = [
    {
      url:
        "https://img.freepik.com/free-photo/close-up-hand-holding-smartphone_23-2149148857.jpg",
      heading: "Nature",
    },
    {
      url:
        "https://s.yimg.com/uu/api/res/1.2/8clWz6qZzhCb3idQpmwmRA--~B/Zmk9ZmlsbDtoPTYzMDtweW9mZj0wO3c9MTIwMDthcHBpZD15dGFjaHlvbg--/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2022-09/1dd547a0-349d-11ed-9eeb-9d420115ca52.cf.jpg",
      heading: "Animals",
    },
    {
      url:
        "https://assets.mspimages.in/gear/wp-content/uploads/2022/02/oppo-launched.jpg",
      heading: "Architecture",
    },
    // Add more dummy image URLs and headings as needed
  ];

  // Slider settings
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 1000, // Adjust the speed of animation (lower value for faster animation)
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Set to true to enable automatic sliding
    autoplaySpeed: 3000, // Set the interval time (in milliseconds) for each slide
    fade: true, // Use fade effect for transition
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 9,
    initialSlide: 0,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 7,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
    ],
  };
  SwiperCore.use([Navigation]);
  return (
    <div>
      {/* <div className="p-2">
        <Slider {...settings}>
            {arr.map((images,index)=>{
                console.log("images",images)
                return(
                    <div >
                   <img
                   alt="imag" src={images.img}  style={{backgroundImage:`url(${images.img})`,backgroundSize:'cover',
                    borderRadius:'50px',width:'100px',height:'100px'}}/>
                    <p className="text-center" >{images.text}</p>
                    </div>
                )
     
            })}
         </Slider>
      </div> */}
      <div
        className="mainwrapper"
        style={{
          marginBottom: "-20px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <h3
          className="mb-4 mt-2"
        >
          <b>
            <ins>OUR TOP CATEGORIES</ins>
          </b>
        </h3>

        {/*   <>     
     <div className="slider-container">
        <Slider {...settings1}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image.url} alt={`Slide ${index + 1}`} />
              <h2 className={`heading-animation-${index + 1}`}>{image.heading}</h2>
            </div>
          ))}
        </Slider>
      </div>

        </>*/}
        <div className="wrapper2001">
          <Slider {...settings}>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/RealEstate" title="Category RealEstates">
                  <img
                    alt="imag"
                    // src="https://freesvg.org/img/7c3dc245.png"
                    src="https://thumbs.dreamstime.com/b/d-render-modern-house-isolated-white-background-modern-house-d-rendering-luxury-style-isolated-white-background-131073093.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Real Estate</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/furniture" title="Category Furnitures">
                  <img
                    alt="imag"
                    // src={sofa}
                    src="https://images2.imgix.net/p4dbimg/11/images/030c-10_650-72_fx9219.jpg?fit=fill&bg=FFFFFF&trim=color&trimtol=5&trimcolor=FFFFFF&w=384&h=288&fm=pjpg&auto=format"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Furnitures</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/Pets" title="Category Pets">
                  <img
                    alt="imag"
                    // src={Dog}                   
                   src="https://media.istockphoto.com/id/513133900/photo/golden-retriever-sitting-in-front-of-a-white-background.webp?b=1&s=170667a&w=0&k=20&c=ENgM9IIlC7-7xH_4WHBWoQNeoO0u2TUnlhYsqka694c=" 
                   style={isMobile ? mobileStyle : desktopStyle}
                  // className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Pets</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/Bikes" title="Category Bikes">
                  <img
                    alt="imag"
                    // src={bike}
                    src="https://static.autox.com/uploads/2024/02/Kawasaki-Z900-Ebony-Metallic-Matte-Graphene-Steel-Gray-500x261.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Bikes</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/Cars" title="Category Cars">
                  <img
                    alt="imag"
                    // src={car}
                    src="https://media.zigcdn.com/media/model/2021/Apr/polo-2022_360x240.jpg"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Cars</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/electronics" title="Category Electronics">
                  <img
                    alt="imag"
                    // src={electronics} 
                    src="https://media.istockphoto.com/id/1265168245/photo/home-appliances.jpg?s=612x612&w=0&k=20&c=agZt_DSpigHeKjLLC8_vmeV-cXJKH1ZstjYLLTiL7cY="
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Electronics</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/Mobiles" title="Category Mobiles">
                  <img
                    alt="imag"
                    // src={mobiles}
                    src="https://images.samsung.com/is/image/samsung/assets/uk/mobile-phone-buying-guide/best-samsung-a-series-phone/Section1_featurebenefit2_720x304.jpg?$FB_TYPE_B_JPG$"
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Mobiles</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/education1" title="Category Jobs">
                  <img
                    alt="imag"
                    src={Education}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Education</p>
                </Link>
              </div>
            </center>
            <center>
              <div className="wrapper pt-3">
                <Link to="/cat/jobs" title="Category Jobs">
                  <img
                    alt="imag"
                    src={Job}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Jobs</p>
                </Link>
              </div>
            </center>

            <center>
              <div className="wrapper pt-3">
                <Link to="/Cat/Services1" title="Category Services">
                  <img
                    alt="imag"
                    src={Services}
                    style={isMobile ? mobileStyle : desktopStyle}
                    className="circleHover"
                  />
                  <p className="text-center fs-12 mb-0">Services</p>
                </Link>
              </div>
            </center>

            {/* <div>
            <Link to="/cat/Education" title="Category Education">
              <img
                alt="imag"
                src={Education}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p className="text-center fs-12 mb-0">Education</p>
            </Link>
          </div> */}
          </Slider>
        </div>

        <div><h1></h1>
        </div>
        <div><h1></h1>
        </div>
      </div>
    </div>
  );
};

export default Scroll;
