// import axios from "axios";
// import React, { useContext, useEffect, useState } from "react";
// import "./Feature.css";
// import Slider from "react-slick";
// import { NavLink, useNavigate } from "react-router-dom";
// import { Margin } from "@mui/icons-material";
// import Wishlist from "../../Account/Wishlist/Wishlist";
// import { BsFillCartPlusFill } from "react-icons/bs";
// import {
//   BsFillTagFill,
//   BsFillBookmarkFill,
//   BsFillClockFill,
//   BsFillCheckSquareFill,
//   BsGeoAltFill,
//   BsBuilding,
//   BsNewspaper,BsHeartFill
// } from "react-icons/bs";
// import DynamicFont from "react-dynamic-font";
// import { Button, Tooltip, Popover, Spin } from "antd";
// import { url } from "../../env";
// import { decrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
// import { UserContext } from "../../../App";
// import { useInterval } from "react-interval-hook";
// import { useDispatch, useSelector } from "react-redux";
// import { add, remove } from "../../../store/wishlistSlice";
// import Distance from "../../distance";
// import YoutubeMagic from "../../ContentLoader/YoutubeMagic";
// import { SliderContainer } from "../../../Utils/SliderContainer";
// import { isMobile } from "react-device-detect";
// import { AiFillDelete } from "react-icons/ai";
// import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";
// // Configuration for the slider component
// var settings = {
//   // dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 4,
//   slidesToScroll: 4,
//   initialSlide: 0,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         infinite: true,
//         dots: true,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         initialSlide: 2,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//       },
//     },
//   ],
// };

// const mobstyle={
// width:"auto",
// background: "transparent"
// }
// const deskstyle={
//   background: "gray",
//   //  borderRadius: "2px",
//    border: "1px solid gray",
// }
// const mobilestyle={
//   height: "140px",
//   width: "350px",
//   borderRadius:"20px",
// }
// const desktopstyle={
//  height: "165px",
//   width: "350px",
// }

// const Recentads = (props) => {
//   // Fetching user context
//   console.log('props',props);
//   const adsData = useContext(UserContext);
//    // State variables
//   const [category, setCateory] = useState(null);
//   const [isloading, setIsLoading] = useState(true);
//   useEffect(() => {
//       // Setting category from props
//     if (props?.value) {
//       setCateory(props?.value);
//     }
//   }, []);
//  //  Several state variables are initialized using the useState hook, including category, 
//   // products, lockInterval, and interval. 
//   const [products, setProducts] = useState([]);
//   const [lockInterval, setLockInterval] = useState(true);
//   const [interval, setInterval] = useState({ start: 0, end: 10 });
//     // Redux state and actions
//     // Get wishlist data from Redux store
//   const wishlistData = useSelector((state) => state.wishlistSlice);
//   const [wishlistID] = ["wishlistId"].map(
//     document.getElementById.bind(document)
//   );
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//     // Adding or removing item from wishlist

//   const addingWishlist = (value, e) => {
//         // Check if user is authenticated
        
//     if (localStorage.getItem("access_token") == null) {
//       navigate("/login");
//     }
//         // Dispatching add or remove action based on wishlist status
//     if (wishlistData?.data?.indexOf(value) !== -1) {
//       dispatch(remove({ value: value }));
//     } else {
//       dispatch(add({ value: value }));
//     }

//     // wishlistID.addEventListener('click', e => e.stopPropagation());
//   };

 
//   const userid = localStorage.getItem("userid");

//   useEffect(() => {
      
//       // Fetch data from the API
//       const fetchData = async () => {
//           try {
//               const response = await axios.get(`https://databytess.com/api/adsapi/useradsvisited?user=${userid}`);
//               setProducts(response.data);
//               setIsLoading(false); // Set loading to false when data is loaded


//           } catch (error) {
//               console.error('Error fetching data:', error);
//           }
//       };


//       fetchData();
//   },);

//   return (
//     <>
    
//     {/* this products getting from ads api */}
//       {products.length ? (
       
//         <div>
//           <h2
//             className="text-decoration-underline"
//             style={{ textAlign: "center", fontSize: "24px", fontFamily: "areal", fontWeight: "bold" }}
//           >
//             Recent Ads
//           </h2>
//           {isloading ? <YoutubeMagic1 /> : null}
//           {/* {products.length===0 && <YoutubeMagic1/>} */}
//           <div className="row ">
//             <SliderContainer>
//               <Slider {...settings}>
//         {/* the product object is being used to access properties and values associated with each product in the products array. */}
//                 {products
//                   .sort((a, b) => b.id - a.id)
//                   .map((ad, index) => {
//                     // products.map((product, index) => {

//                     return (
//                       <>
//                       <div key={ad.id} className="ad-card m-2" onClick={() => navigate(`/ads-listing/${
//                             ad?.ad_details.id === undefined ? ad?.ad_details.id : ad?.ad_details.id
//                           }/`)} style={{marginRight:"50px"}}>
//                             <div className="glassmorphism">
//                               <div>
//                               <div className="ad-image">
//                               <div className="wtrmrk">Hola9.com</div>
//                               <img className="imghover-scale"
//                                                 src={
//                                                   !ad?.ad_details.image
//                                                     ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
//                                                     : ad?.ad_details.image
//                                                 }
//                                                 alt="ad"
//                                                 onError={(e) => {
//                                                   e.target.src =
//                                                     "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
//                                                   e.onerror = null;
//                                                 }}
//                                               />
//                               </div>
//                               <div className="product-type">
//                                                 <span className="flat-badge booking" >
//                                                 <b>
//                                                       <span
//                                                         className="flat-badge booking "
//                                                         style={{
//                                                           backgroundColor:
//                                                           ad?.ad_details.plan ==="Silver"
//                                                               ? "#cce6ff"
//                                                               : ad?.ad_details.plan === "Gold"
//                                                               ? "gold"
//                                                               : ad?.ad_details.plan === "Platinum"
//                                                               ? "green"
//                                                               : ad?.ad_details.plan === "Premium"
//                                                               ? "linear-gradient(to right, #800080, #8A2BE2)"
//                                                 : ad?.ad_details.plan === "Featured"
//                                                 ? "linear-gradient(to right, #090979, #00d4ff)"
//                                                               // : ad?.plan === "Free"
//                                                               // ? "green"
//                                                               : "transparent",
//                                                           color:
//                                                           ad?.ad_details.plan ==="Silver"
//                                                               ? "blue"
//                                                               : ad?.ad_details.plan === "Gold"
//                                                               ? "black"
//                                                               : ad?.ad_details.plan === "Platinum"
//                                                               ? "white"
//                                                               : ad?.ad_details.plan === "Premium"
//                                                               ? "black"
//                                                               : ad?.ad_details.plan === "Featured"
//                                                               ? "black"
//                                                               // : ad?.plan === "Free"
//                                                               // ? "white"
//                                                               : "transparent",
//                                                               padding:"5px",
                                                             
//                                                           // backgroundColor: "white",
//                                                           // float:"left",
//                                                           // border: "2px solid black",
//                                                         }}
//                                                       >
//                                                         {ad?.ad_details.plan}
//                                                       </span>
//                                                     </b>
//                                                 </span>
                                               
//                                               </div>
//                                               <ul className="viewsub">
//                                               <li className="view">
//                                                 <i
//                                                   className="fas fa-eye"
//                                                   style={{ color: "white" }}
//                                                 />
//                                                 <span style={{ color: "white" }}>
//                                                   {ad?.ad_details.viewsproduct}
//                                                 </span>
//                                               </li>
//                                               {/* <li className="click">
//                                                 <i className="fas fa-mouse" />
//                                                 <span>134</span>
//                                               </li> */}
//                                               <li className="rating">
//                                                 <i className="" style={{ color: "white" }} />
//                                                 <span style={{ color: "white" }}>
//                                                   <BsNewspaper />
//                                                   &nbsp;  {ad?.ad_details.subCategoryValue?.slice(0,12)+"..."}
//                                                 </span>
//                                               </li>
//                                             </ul>
//                                             </div>
//                                             <div className="ad-details  m-0 p-0">
//                                            {/* DESKTOP VIEW */}
//                                            <p className="titlefont hidden-sm hidden-xs  m-0 p-0">
//                                              <Tooltip
//                                                placement="topLeft"
//                                                title={ad?.ad_details.title}
//                                              >
//                                                {ad?.title?.length > 17 ? (
//                                                  <p className="titlefont">
//                                                    {ad?.ad_details.title?.slice(0, 17)}
//                                                    {ad?.ad_details.title?.length > 17
//                                                      ? "..."
//                                                      : null}
//                                                  </p>
//                                                ) : (
//                                                  <div>
//                                                    <p className="titlefont">
//                                                      <DynamicFont
//                                                        content={ad?.ad_details.title}
//                                                      />
//                                                    </p>
//                                                  </div>
//                                                )}
//                                              </Tooltip>
//                                            </p>
//                                            {/* DESKTOP VIEW */}
           
//                                            {/* MOBILE VIEW */}
//                                            <p className="titlefont d-lg-none">
//                                              <Tooltip
//                                                placement="topLeft"
//                                                title={ad?.ad_details.title}
//                                              >
//                                                {ad?.title?.length > 12 ? (
//                                                  <p className="titlefont">
//                                                    {ad?.ad_details.title?.slice(0, 12)}
//                                                    {ad?.ad_details.title?.length > 12
//                                                      ? "..."
//                                                      : null}
//                                                  </p>
//                                                ) : (
//                                                  <div style={{}}>
//                                                    <p className="titlefont">
//                                                      <DynamicFont
//                                                        content={ad?.ad_details.title}
//                                                      />
//                                                    </p>
//                                                  </div>
//                                                )}
//                                              </Tooltip>
//                                            </p>
//                                            {/* MOBILE VIEW */}
//                                            <div
//                                              className="condiv justify-content-between "
//                                              style={{ marginTop: "-15px" }}
//                                            >
//                                              <div className="widhdiv d-flex justify-content-between m-0 p-0">
//                                                <p
//                                                  className="fs-10 m-0 p-0 mt-1"
//                                                  style={{
//                                                    color: "#062544",
//                                                    fontSize: "90%",
//                                                    fontFamily: "sans-serif",
//                                                  }}
//                                                >
//                                                  <BsGeoAltFill
//                                                    className="mr-1 "
//                                                    style={{ color: "red" }}
//                                                  />
//                                                  <b>{ad?.ad_details.City}</b>
//                                                </p>
//                                                <BsFillBookmarkFill
//                                                  id="wishlibyn"
//                                                  style={{
//                                                    fontSize: "20px",
//                                                    marginTop: "6px",
//                                                  }}
//                                                  className={
//                                                    wishlistData?.data?.indexOf(ad?.id) !==
//                                                    -1
//                                                      ? "removemob"
//                                                      : "heartmob"
//                                                  }
//                                                  onClick={(e) => {
//                                                    e.stopPropagation();
//                                                    e.preventDefault();
//                                                    addingWishlist(ad?.id);
//                                                  }}
//                                                />
//                                              </div>
//                                              {ad?.category == "RealEstate" ? (
//                                                <p
//                                                  className="conpir fs-14"
//                                                  style={{
//                                                    color: "gray",
//                                                    fontSize: "90%",
//                                                    fontFamily: "sans-serif",
//                                                    fontWeight: "bold",
//                                                  }}
//                                                >
//                                                  <BsBuilding
//                                                    className="mr-2"
//                                                    style={{
//                                                      color: "green",
//                                                      fontSize: "90%",
//                                                    }}
//                                                  />
//                                                  {ad?.ad_details.subCategoryType?.slice(0, 9)}
//                                                </p>
//                                              ) : (
//                                                <p
//                                                  className="conpir fs-14"
//                                                  style={{
                                          
//                                                    fontSize: "90%",
//                                                    fontFamily: "sans-serif",
                                                   
//                                                  }}
//                                                >
//                                                  <BsFillCheckSquareFill
//                                                    className="mr-1"
//                                                    style={{
//                                                      color: "green",
//                                                      fontSize: "90%",
//                                                    }}
//                                                  />
//                                                  {ad?.ad_details.condition?.slice(0, 9)}
//                                                </p>
//                                              )}
//                                            </div>
//                                            <p
//                                              className="ad-price mr-1"
//                                              style={{
//                                                fontSize: "90%",
//                                                fontFamily: "verdana",
//                                                marginTop: "-15px",
//                                              }}
//                                            >
//                                              &nbsp;₹ <b>{ad?.ad_details.price}</b>
//                                            </p>
//                                            <button
//                                              id="wishbbt"
//                                              style={{ marginTop: "-15px" }}
//                                              className={
//                                                wishlistData?.data?.indexOf(ad?.id) !== -1
//                                                  ? "remove-button"
//                                                  : "wishlist-button"
//                                              }
//                                              onClick={(e) => {
//                                                e.stopPropagation();
//                                                e.preventDefault();
//                                                addingWishlist(ad?.id);
//                                              }}
//                                            >
//                                              {wishlistData?.data?.indexOf(ad?.id) !== -1 ? (
//                                                <>
//                                                  <AiFillDelete />
//                                                  &nbsp;&nbsp;Remove Wishlist
//                                                </>
//                                              ) : (
//                                                <>
//                                                  <BsFillBookmarkFill />
//                                                  &nbsp;&nbsp;Add To Wishlist
//                                                </>
//                                              )}
//                                            </button>
//                                          </div>
//                             </div>
//                       </div>
//                       </>
//                     );
//                   })}
//               </Slider>
//             </SliderContainer>
//           </div>
//           {/* </div> */}
//         </div>
//       ) : null}
//     </>
//   );
// };

// export default Recentads;




import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import "./Feature.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Margin } from "@mui/icons-material";
import Wishlist from "../../Account/Wishlist/Wishlist";
import { BsFillCartPlusFill } from "react-icons/bs";
import './RecentAds24.css'

import {
  BsFillTagFill,
  BsFillBookmarkFill,
  BsFillClockFill,
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper,
  BsHeartFill
} from "react-icons/bs";
import DynamicFont from "react-dynamic-font";
import { Button, Tooltip, Popover, Spin } from "antd";
import { url } from "../../env";
import { decrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
import { UserContext } from "../../../App";
import { useInterval } from "react-interval-hook";
import { useDispatch, useSelector } from "react-redux";
import { add, remove } from "../../../store/wishlistSlice";
import Distance from "../../distance";
import YoutubeMagic from "../../ContentLoader/YoutubeMagic";
import { SliderContainer } from "../../../Utils/SliderContainer";
import { isMobile } from "react-device-detect";
import { AiFillDelete } from "react-icons/ai";
import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";

import { MdDoubleArrow } from "react-icons/md";


const Recentads = (props) => {
  const [products, setProducts] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [wishlistData] = useSelector((state) => [state.wishlistSlice.data]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
    if (wishlistData?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add({ value: value }));
    }
  };
  const name =localStorage.getItem("name");
  const userid = localStorage.getItem("userid");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://databytess.com/api/adsapi/useradsvisited?user=${userid}`);
        setProducts(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
    
      {products.length ? (
        <div>
        {/* style={{ textAlign:"left", fontSize: "24px", fontFamily: "areal", fontWeight: "bold" }} */}
          <h2        style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#007BFF",
                  textTransform: "capitalize",
                  letterSpacing: "1px",
                  textAlign: "left",
                  background: "linear-gradient(to right, #007BFF, #00BFFF)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4))",
                  transition: "transform 0.3s ease-in-out",
                  cursor: "pointer",
                }} >
           Recently Visited Ads
          </h2>
          {isloading ? <YoutubeMagic1 /> : null}
          {/* {(name !==" ") ?
          <> */}
          <div className="row">
            <div className="scroll-container">
              {products
                .sort((a, b) => b.id - a.id)
                .map((ad, index) => (
                  <div key={ad.id} className="ad-card ad-card-awi m-2" onClick={() => navigate(`/ads-listing/${ad?.ad_details.id}/`)} style={{ marginRight: "50px" }}>
                    <div className="glassmorphism1">
                      <div>
                        <div className="ad-image1">
                          <img className="imghover-scale" src={!ad?.ad_details.image ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1" : ad?.ad_details.image} alt="ad" onError={(e) => {
                            e.target.src = "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                            e.onerror = null;
                          }} />
                        </div>
                        <div className="product-type">
                                                <span className="flat-badge booking" >
                                                <b>
                                                      <span
                                                        className="flat-badge booking "
                                                        style={{
                                                          backgroundColor:
                                                          ad?.ad_details.plan ==="Silver"
                                                              ? "#cce6ff"
                                                              : ad?.ad_details.plan === "Gold"
                                                              ? "gold"
                                                              : ad?.ad_details.plan === "Platinum"
                                                              ? "green"
                                                              : ad?.ad_details.plan === "Premium"
                                                              ? "linear-gradient(to right, #800080, #8A2BE2)"
                                                : ad?.ad_details.plan === "Featured"
                                                ? "linear-gradient(to right, #090979, #00d4ff)"
                                                              // : ad?.plan === "Free"
                                                              // ? "green"
                                                              : "transparent",
                                                          color:
                                                          ad?.ad_details.plan ==="Silver"
                                                              ? "blue"
                                                              : ad?.ad_details.plan === "Gold"
                                                              ? "black"
                                                              : ad?.ad_details.plan === "Platinum"
                                                              ? "white"
                                                              : ad?.ad_details.plan === "Premium"
                                                              ? "black"
                                                              : ad?.ad_details.plan === "Featured"
                                                              ? "black"
                                                              // : ad?.plan === "Free"
                                                              // ? "white"
                                                              : "transparent",
                                                              padding:"5px",
                                                             
                                                          // backgroundColor: "white",
                                                          // float:"left",
                                                          // border: "2px solid black",
                                                        }}
                                                      >
                                                        {ad?.ad_details.plan}
                                                      </span>
                                                    </b>
                                                </span>
                                               
                                              </div>
                        <ul className="viewsub1">
                          <li className="view iSty">
                            <i className="fas fa-eye" style={{ color: "black" }} />
                            <span style={{ color: "black", marginLeft: "5px" }}>{ad?.ad_details.viewsproduct}</span>
                          </li>
                          <div className="ad-details  m-0 p-0">
                            <div className="condiv justify-content-between " style={{ marginTop: "-10px" }}>
                              <div className="widhdiv d-flex justify-content-between m-0 p-0">
                                <p className="fs-10 m-0 p-0 mt-1" style={{ color: "#062544", fontSize: "90%", fontFamily: "sans-serif" }}>
                                  <BsGeoAltFill className="mr-1 " style={{ color: "black" }} />
                                  <b style={{ color: "black" }}>{ad?.ad_details.City}</b>
                                </p>
                                <BsFillBookmarkFill id="wishlibyn" style={{ fontSize: "20px", marginTop: "6px" }} className={wishlistData?.indexOf(ad?.id) !== -1 ? "removemob" : "heartmob"} onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  addingWishlist(ad?.id);
                                }} />
                              </div>
                            </div>
                            <p className="ad-price mr-1" style={{ fontSize: "90%", fontFamily: "verdana", marginTop: "0.25px", color: "black" }}>
                              &nbsp;₹ <b style={{ color: "black" }}>{ad?.ad_details.price}</b>
                            </p>
                          </div>
                          <button id="wishbbt" style={{ marginTop: "-35px" }} className={wishlistData?.indexOf(ad?.id) !== -1 ? "remove-button" : "wishlist-button"} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            addingWishlist(ad?.id);
                          }}>
                            {wishlistData?.indexOf(ad?.id) !== -1 ? (
                              <>
                                <AiFillDelete />
                                &nbsp;&nbsp;Remove Wishlist
                              </>
                            ) : (
                              <>
                                <BsFillBookmarkFill />
                                &nbsp;&nbsp;Add To Wishlist
                              </>
                            )}
                          </button>
                        </ul>

     
                      </div>
                     
                      <div className="ad-details  m-0 p-0"></div>
                    </div>
     
                  </div>
                ))}
            </div>
  
          </div>
 
          <div className="arrow-styling24"> 
           <div class="arrow-int-24"></div>
           <div class="arrow-int-24"></div>
           <div class="arrow-int-24"></div>
          </div>
          {/* </>:null
          } */}
        </div>



   
  
      ) : null}



    </>
  );
};

export default Recentads;
