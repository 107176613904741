import React, { useState } from "react";
import Sidebar from "./Components/Sidebar";
import Chat from "./Components/Chat";
import "./style.scss";
import { isMobile } from "react-device-detect";

const ChatHome = (props) => {
  const [loading, setLoading] = useState(false);
  const [popups, setPopups] = useState(true);
  const mobilestyle = {
    display: "none",
  };
  const desktopstyle = {};
  const mobilestyle1 = {};
  const desktopstyle1 = {
    display: "none",
  };

  const handleSidebarClick = () => {
    setPopups(false); 
  };

  return (
    <div className="home">
      <>
        <div
          className="chat-container"
          style={isMobile ? mobilestyle : desktopstyle}
        >
          <Sidebar loading={loading} setLoading={setLoading} />
          <Chat loading={loading} />
        </div>
      </>
      <>
        <div
          className="chat-container"
          style={isMobile ? mobilestyle1 : desktopstyle1}
        >
          {popups && (
            <div onClick={handleSidebarClick}>
              <Sidebar loading={loading} setLoading={setLoading} id="sidebarri"/>
            </div>
          )}
          <Chat loading={loading} 

          />
        </div>
      </>
      
    </div>
  );
};

export default ChatHome;
