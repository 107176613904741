import React, { useEffect, useState } from 'react'
import DocumentViewer from "./Docview";
import { decrypt } from '../../Base/encryptDecrypt/encryptDecrypt';

const AppliedYou = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [name1, setName1] = useState("hii");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://databytess.com/api/user/contactenquiry", {
                    method: "POST",
                    body: JSON.stringify({
                        name:name1
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                });

                if (!response.ok) {
                    throw new Error("Data not found");
                }

                const result = await response.json();
                setData(result);
                setLoading(false);
            } catch (error) {
                console.error(error.message);
            }
        };

        fetchData(); // Call the fetchData function when the component mounts
    },[name1]); // Include name1 in the dependency array to trigger a re-fetch when it changes


    let barererToken = "Bearer " + localStorage.getItem("access_token");

    useEffect(() => {
      if (localStorage.getItem("access_token") != null) {
        let result = decrypt("userdata");
        setName1(result.name);
        // setName(result.email);
      }
    }, []);
    return (
        <div>
            <h1 style={{ textAlign: "center" }}>Jobs Applied By You:</h1>
            <section className="profile-part">
                <div className="container pt-0">
                    <div className="jcar-container">
                        {loading ? (
                            <div className="loading">Loading...</div>
                        ) : (
                            data.map((contact) =>  (
                                <div key={contact.id} className="jcar">
                                    <h2>{contact.name}</h2>
                                    <p>Qualification: {contact.qualification}</p>
                                    <p>Posted By: {contact.posted_by || "N/A"}</p>
                                    <p>Percentage: {contact.percentage || "N/A"}</p>
                                    <p>Date of Birth: {contact.date_of_birth || "N/A"}</p>
                                    <p>Phone Number: {contact.phone_number || "N/A"}</p>
                                    {contact.resume_base64 && (
                                        <DocumentViewer documentData={contact} />
                                    )}
                                </div>
                            ) )
                        )}
                    </div>
                </div>
            </section>{" "}
        </div>
    )
}

export default AppliedYou
