import styled from "styled-components";

export const SliderContainer = styled.div`
  .slick-list {
    overflow: hidden;
  }
  .slick-track {
    margin: 0;
  }
`;
