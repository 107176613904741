import React, { useState, useEffect } from 'react';
import "./CarsSlider.css"
const images = [
  'https://images.unsplash.com/photo-1617153045090-131feca88fae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEwfHx8ZW58MHx8fHx8&w=1000&q=80',
  'https://imageio.forbes.com/specials-images/imageserve/5d35eacaf1176b0008974b54/0x0.jpg?format=jpg&crop=4560,2565,x790,y784,safe&width=1200',
  'https://www.mad4wheels.com/img/free-car-images/mobile/20542/mclaren-750s-spectrum-theme-by-mso-2023-thumb.jpg',
  'https://www.mad4wheels.com/img/free-car-images/mobile/20555/bugatti-chiron-super-sport-golden-era-2023-thumb.jpg',
  'https://images.hgmsites.net/lrg/maserati-levante_100711203_l.jpg',
  'https://www.cnet.com/a/img/resize/fe9cd45baf8a0c8f4cb55ae5da8a6730f49ed63b/hub/2022/04/28/3fdd60b4-88bb-47cc-9fb1-ed7532f9a4b4/levante-ogi.jpg?auto=webp&fit=crop&height=900&width=1200',
];

const CarsSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return ( 
    <div className="carslider-container">
      <div
        className="carimage-slider"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
          transition: 'transform 0.5s ease-in-out'
        }}
      >
        {images.map((image, index) => (
          <div key={index} className="carslide">
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarsSlider;

