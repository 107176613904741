import React, { useState } from 'react';
import './JobRequirements.css';

const JobRequirements = () => {

  const [formData, setFormData] = useState({
    profile: '',
    openings: '',
    createdAt: '',
    jobTitle: '',
    jobDescription: '',
    job_responsiblity: '',
    technical_skills: '',
    preferredQualification: '',
    education: '',
  });

  const [errors, setErrors] = useState({});

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;
    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        isValid = false;
        errors[key] = 'This field is required';
      }
    });
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {

      const response = await fetch('https://databytess.com/api/user/jobsRequired', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        alert('Job requirement posted successfully');
        console.log("alldata", formData)
      } else {
        alert('Failed to post job requirement');
      }

    } catch (error) {
      console.error('Error:', error);
      alert('Failed to post job requirement');
    }

    console.log("alldata", formData)
  };

  return (
    <>
      <div className='Requirements-m-d'>
        <form className='Requirements-form' onSubmit={handleSubmit}>
          <div className='Requirements-form-span'>
            <span className='Requirements-form-span-h'>
              <h5>Job Requirement</h5>
            </span>
            <span className='Requirements-form-span-i'>
              <span>
                {errors.profile && <i>{errors.profile}</i>}
                <input
                  type='text'
                  name='profile'
                  value={formData.profile}
                  onChange={handleInput}
                />
                <label>Profile</label>
              </span>
              <span>
                {errors.openings && <i>{errors.openings}</i>}
                <input
                  type='number'
                  name='openings'
                  value={formData.openings}
                  onChange={handleInput}
                />
                <label>No.of Openings</label>
              </span>
              <span>
                {errors.createdAt && <i>{errors.createdAt}</i>}
                <input
                  type='text'
                  name='createdAt'
                  value={formData.createdAt}
                  onChange={handleInput}
                />
                <label>Created at</label>
              </span>
            </span>
          </div>

          <div className='Requirements-form-span'>
            <span className='Requirements-form-span-h'>
              <h5>Job Description</h5>
            </span>
            <span className='Requirements-form-span-i'>
              <span>
                {errors.jobTitle && <i>{errors.jobTitle}</i>}
                <input
                  type='text'
                  name='jobTitle'
                  value={formData.jobTitle}
                  onChange={handleInput}
                />
                <label>Job Title</label>
              </span>
              <span>
                {errors.jobDescription && <i>{errors.jobDescription}</i>}
                <textarea
                  name='jobDescription'
                  value={formData.jobDescription}
                  onChange={handleInput}
                />
                <label>Job Description</label>
              </span>
              <span>
                {errors.job_responsiblity && <i>{errors.job_responsiblity}</i>}
                <textarea
                  name='job_responsiblity'
                  value={formData.job_responsiblity}
                  onChange={handleInput}
                />
                <label>Job Responsibility</label>
              </span>
            </span>
          </div>

          <div className='Requirements-form-span' style={{ border: 'none' }}>
            <span className='Requirements-form-span-h'>
              <h5>Skills</h5>
            </span>
            <span className='Requirements-form-span-i'>
              <span>
                {errors.technical_skills && <i>{errors.technical_skills}</i>}
                <input
                  type='text'
                  name='technical_skills'
                  value={formData.technical_skills}
                  onChange={handleInput}
                />
                <label>Technical Skills</label>
              </span>
              <span>
                {errors.preferredQualification && <i>{errors.preferredQualification}</i>}
                <input
                  type='text'
                  name='preferredQualification'
                  value={formData.preferredQualification}
                  onChange={handleInput}
                />
                <label>Preferred Qualification</label>
              </span>
              <span>
                {errors.education && <i>{errors.education}</i>}
                <input
                  type='text'
                  name='education'
                  value={formData.education}
                  onChange={handleInput}
                />
                <label>Education</label>
              </span>
            </span>
          </div>

          <button className='Requirements-form-button' type='submit'>
            Post Job Requirement
          </button>
        </form>
      </div>
    </>
  );
};

export default JobRequirements;
