// import React, { useState, useEffect } from "react";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./Help.css";
// import Slider from 'react-slick';

// const Help = () => {

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 6, // Display 5 cards at a time
//     slidesToScroll: 6, // Scroll 3 cards at a time
//     responsive: [
//       {
//         breakpoint: 1200, // Apply different settings for large screens
//         settings: {
//           slidesToShow: 4,
//           slidesToScroll: 3,
//         },
//       },
//       {
//         breakpoint: 992, // Apply different settings for medium screens
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//         },
//       },
//       {
//         breakpoint: 768, // Apply different settings for small screens
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 576, // Apply different settings for extra small screens
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <>
//     <div>
//       <h4><ins>TEAM OF DEVELOPERS :</ins></h4>
//       <div className="container">
//       <Slider {...settings}>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team ">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//             <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//             <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>

//       </Slider>
//     </div>
//     </div>
//     <div>
//     <h4><ins>TEAM OF TEST ENGINEERS :</ins></h4>
//       <div className="container">
//       <Slider {...settings}>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team ">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//             <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//             <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>
//         <div className="col-12 col-sm-6 col-md-4 col-lg-3">
//           <div className="our-team">
//             <div className="picture">
//               <img
//                 className="img-fluid"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8K9Q8kSZiGe0dwf5shjpN_175RaakQas4Q&usqp=CAU"
//                 alt="Team Member"
//               />
//             </div>
//             <div className="team-content">
//            <h5 className="name mb-0">Michele Miller</h5>
//               <h4 className="title">Web Developer</h4>
//             </div>
//           </div>
//         </div>

//       </Slider>
//     </div>
//     </div>

//  </>

//   );
// };

// export default Help;

// Help.js

// import React from 'react'
// import "./Help.css";

// const Help = () => {
//   return (
//     <div className='body1'>

//   <div className="hexagon-wrapper">
//     <div className="hexagon">
//       <i className="fab fa-facebook" />
//     </div>
//   </div>
//   <div className="hexagon-wrapper">
//     <div className="hexagon">
//       <i className="fab fa-twitter" />
//     </div>
//   </div>
//   <div className="hexagon-wrapper">
//     <div className="hexagon">
//       <i className="fab fa-instagram" />
//     </div>
//   </div>
//   <div className="hexagon-wrapper">
//     <div className="hexagon">
//       <i className="fab fa-linkedin" />
//     </div>
//   </div>
//   <div className="hexagon-wrapper">
//     <div className="hexagon">
//       <i className="fab fa-telegram" />
//     </div>
//   </div>
//     </div>
//   )
// }

// export default Help
import React, { useEffect, useState } from "react";
import { Select, Checkbox } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import "./Help.css";
import optionsData from "./HelpChekbox";
import { Citys } from "../Location";
import { getExtraField } from "../../store/ProductFilterSlice";
import { useDispatch } from "react-redux";

const { Option } = Select;

function FilteringData() {
  // State for selected option from the dropdown
  const [selectedOption, setSelectedOption] = useState();

  // State for selected checkboxes
  const [selectedItems, setSelectedItems] = useState([]);

  // State for selected city from the second dropdown
  const [city, setCity] = useState();

  // State to manage the visibility of the second dropdown
  const [visible1, setVisible1] = useState(false);

  // State to manage extra field data
  const [extraFieldCopy, setExtraFieldCopy] = useState();
  const [extraFieldCopy1, setExtraFieldCopy1] = useState({});

  // State to manage selected category filter
  const [selectedFilter, setSelectedFilter] = useState(null);

  // Handle dropdown visibility change
  const handleDropdownVisibleChange = (open) => {
    setVisible1(open);
  };

  // Handle dropdown option change
  const handleDropdownChange = (value) => {
    setSelectedOption(value);
    setSelectedItems([]); // Clear selected items when the dropdown changes
  };

  // Handle checkbox change
  const handleCheckboxChange = (item) => {
    // Check if the item is already in the selectedItems array
    const isSelected = selectedItems.includes(item);

    // Update selectedItems based on whether the checkbox is checked or unchecked
    if (isSelected) {
      setSelectedItems(selectedItems.filter((category) => category !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  // Redux dispatch
  const dispatch = useDispatch();

  // State for search form data
  const [searchSending, setSearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    searchValue: null,
    category: "Education",
    minPrice: null,
    maxPrice: null,
  });

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
  };

  // Update searchSending state with the selected filter value
  useEffect(() => {
    if (selectedFilter !== null) {
      setSearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);

  // Function to render checkboxes
  const renderCheckboxes = () => {
    const selectedData = optionsData.find(
      (option) => option.value === selectedOption
    );

    if (!selectedData) {
      return null;
    }

    return selectedData.subcategories.map((subcategory) => (
      <div key={subcategory.value}>
        <h6 style={{ color: "#014f75", fontWeight: "bold" }}>
          {subcategory.label}
        </h6>
        {subcategory.items.map((item) => {
          const label = subcategory.label;
          return (
            <div className="checkbox-row" key={item}>
              <Checkbox
                value={item}
                checked={selectedItems.includes(item)} // Check if the item is in selectedItems
                onChange={() => handleCheckboxChange(item)}
                style={{ color: "white", fontWeight: "normal" }}
              >
                {item}
              </Checkbox>
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    <>
      <div className="filtering-data-container">
        <form className="filter-form">
          <h6 style={{ color: "white", fontWeight: "bold" }}>
            Select an option:
          </h6>
          {/* Dropdown to select an option */}
          <Select
            value={selectedOption}
            onChange={handleDropdownChange}
            className="dropdropdrop"
            placeholder="-- Select --"
          >
            {optionsData.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>

          {/* Render checkboxes if an option is selected */}
          {selectedOption && (
            <div className="checkbox-container">{renderCheckboxes()}</div>
          )}

          <div className="form-group mt-3">
            <h6
              style={{
                fontFamily: "verdana",
                color: "white",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Select City:
            </h6>
            {/* Dropdown to select a city */}
            <Select
              open={visible1}
              onDropdownVisibleChange={handleDropdownVisibleChange}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              style={{
                width: "13rem",
                padding: "5px 0px",
              }}
              placeholder="Select City"
              value={city || null}
              onChange={(value) => setCity(value)}
              options={Citys.map((value) => ({
                value: value,
                label: value,
              }))}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              }
            />
          </div>

          {/* Submit button with a link to the ads-listing page */}
          <Link
            to={`/ads-listing/`}
            onClick={() => {
              console.log(searchSending);
              for (const key in searchSending) {
                if (
                  searchSending[key]?.trim() === "" ||
                  searchSending[key] === null
                ) {
                  delete searchSending[key];
                }
              }
              searchSending["subCategoryValue"] = selectedOption;
              searchSending["extraField"] = {
                ...extraFieldCopy,
                ...extraFieldCopy1,
              };
              searchSending["City"] = city;
              dispatch(getExtraField(searchSending));
            }}
          >
            <center>
              <button type="button" className="submit-button">
                Submit
              </button>
            </center>
          </Link>
        </form>
      </div>
    </>
  );
}

export default FilteringData;


// FilterPopup.js
// FilterPopup.js
// import React, { useState } from 'react';
// import { Button, Modal, Checkbox, Radio, Select } from 'antd'; // Import Ant Design components
// import { Category } from '../env';
// import "./Help.css";
// import { Citys } from '../Location';

// function FilterPopup() {
//   const [modalVisible, setModalVisible] = useState(false);
//   const [activeTab, setActiveTab] = useState('tab1');

//   const openModal = () => {
//     setModalVisible(true);
//   };

//   const closeModal = () => {
//     setModalVisible(false);
//   };

//   const switchTab = (tabId) => {
//     setActiveTab(tabId);
//   };

//   return (
//     <div className="filter-popup">
//       <Button onClick={openModal} className="filter-button">
//         Filters
//       </Button>

//       <Modal
//         title="Filter Popup"
//         visible={modalVisible}
//         onOk={closeModal}
//         onCancel={closeModal}
//         width={600}
//       >
//         <div className="tabs">
//           <Button onClick={() => switchTab('tab1')} className="tab-button">
//             Tab 1
//           </Button>
//           <Button onClick={() => switchTab('tab2')} className="tab-button">
//             Tab 2
//           </Button>
//           <Button onClick={() => switchTab('tab3')} className="tab-button">
//             Tab 3
//           </Button>
//         </div>

//         <div className="tab-content">
//           {activeTab === 'tab1' && (
//             <div>
//               <h4 className="section-heading">Tab 1</h4>
//               <Select
//               style={{
//                 width: "15rem",
//                 padding: "5px 0px",
//               }}
//               placeholder="Select City"
//               options={Citys.map((value) => ({
//                 value: value,
//                 label: value,
//               }))}
//               filterOption={(inputValue, option) =>
//                 option.label.toLowerCase().includes(inputValue.toLowerCase())
//               }

//             />

//               <div className="checkbox-group">
//                 <Checkbox className="checkbox-input">Checkbox 1</Checkbox>
//                 <Checkbox className="checkbox-input">Checkbox 2</Checkbox>
//               </div>
//               <div className="radio-group">
//                 <Radio className="radio-input">Radio 1</Radio>
//                 <Radio className="radio-input">Radio 2</Radio>
//               </div>
//             </div>
//           )}

//           {activeTab === 'tab2' && (
//             <div>
//               <h2 className="section-heading">Tab 2</h2>
//               {/* Add content for Tab 2 */}
//             </div>
//           )}

//           {activeTab === 'tab3' && (
//             <div>
//               <h2 className="section-heading">Tab 3</h2>
//               {/* Add content for Tab 3 */}
//             </div>
//           )}
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default FilterPopup;
