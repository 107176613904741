// // import React, { useEffect, useState } from "react"
// // import FeaturedAds from '../../../Ads/FeaturedAds/featuredAds';
// import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
// import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { SearchOutlined } from "@ant-design/icons";
// import Select1 from 'react-select';
// import { InputLabel } from "@material-ui/core";
// import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
// import MyGallery from "./MyGallery";
// import { YearOfRegistrationCar } from "../../../env";
// import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
// import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
// import { Input, Spin } from "antd";
// import { useDispatch } from "react-redux";
// import { add } from "../../../../store/Track/trackUserSlice";
// import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
// import { stateMain, locationData } from "../../../Location";
// import "../searchBox/SearchBox.css";
// import { Link } from "react-router-dom";
// import { subCategoryInput } from "../../../../Model/SubCategoryCons";
// import BikeSlider from "../../../../Shared/CategorySlider/BikeSlider";
// import { Carousel } from "antd";
// import { FitScreen } from "@mui/icons-material";
// import "./allfilter.css";
// import { isMobile } from "react-device-detect";
// import { searchApi } from "../../../../store/ToggleSearchSlice";
// import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
// import CarsSlider from "../../../../Shared/CategorySlider/CarsSlider";
// import Allcatads from "../../../Allcatads";
// import { BsSearch } from "react-icons/bs";
// import { FcSearch } from "react-icons/fc";

// const mobstyle = {
//   width: "auto",
//   marginLeft: "5px",
//   marginRight: "5px",
// };
// const deskstyle = {
//   padding: "20px",
//   marginBottom: "-10px",
//   marginRight: "10rem",
//   marginLeft: "10rem",
// };
// const mobi={


  
// }
// const deski={
//   marginLeft:"7rem",marginRight:"7rem"
// }
// const Carscat = () => {
//   const [selectedFilter, setSelectedFilter] = useState(null);
//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [extraFieldCopy1, setExtraFieldCopy1]=useState({});
//   const [extraFieldCopy2, setExtraFieldCopy2]=useState({});
//   const [extraFieldCopy3,setExtraFieldCopy3]=useState({});
//   const [active1,setActive1]=useState(null);
//   const [years,setYears]=useState();
//   const [kms,setKms]=useState();
//   const [brandName,setBrandName] = useState(null);
//   const [budget,setBudget]=useState(null);
//   const [type,setType]=useState(null);
//    const [selectedOption,setSelectedOption] = useState();


 
//   let year = [];
//   var date = new Date().getFullYear();
//   for (let i = 1980; i <= date; i++) {
//     year.push(i);
//   }

//   let km=[];
//   var Driven = subCategoryInput.Cars.Cars.KM_Driven;
//   for (let i=0;i<Driven.length;i++){
//     km.push(Driven[i])
//   }

//   const handleChanges = (selectedOption) => {
//     console.log('hi Guys',selectedOption);
//     setSelectedOption(selectedOption);
//       console.log(`Option selected:`, selectedOption);
  
//   };
//   // const [selectedFilter1, setSelectedFilter1] = useState(null);

//   const handleFilterButtonClick = (filterType) => {
//     setSelectedFilter(filterType);
//   };
//   const greeting = "Cars";

//   const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
//   const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
//   const disptach = useDispatch();
//   const dispatch = useDispatch();
//   // Accessing the Redux dispatch function

//   useEffect(() => {
//     disptach(add({ view: ["Carscat"] })); // Dispatching an action using Redux to add the view to the user's track
//   }, []);

//   const [searchSending, setsearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: "Cars",
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook
//   const [SearchValue, setSearchValue] = useState("Car");
//   const searchArray = () => {
//     if (SearchValue?.trim()) {
//       dispatch(searchApi(SearchValue));
//     }
//   };
//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setsearchSending({ ...searchSending, [name]: value });
//     disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   useEffect(() => {
//     // Update searchSending state with the selected filter value
//     if (selectedFilter !== null) {
//       setsearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);
//   const sliderStyle = {
//     width: "100%",
//     // marginTop:"2cm",
//     // maxWidth: "100vw",
//   };
//   const [selectedButton, setSelectedButton] = useState();

//   // Step 2: Create CSS classes to control modal positioning
//   const modalContainerStyle = {
//     top: "-50%", // Adjust this value to control the distance from the button

//     // width:"100%"
//   };

//   const modalStyle = {
//     // position: "relative",

//     // display:"flex",
//     // width:"100%",
//     top: "-50%", // Adjust this value to control the distance from the button
//     left:0,
//     zIndex: 10, // Ensure the modal is above other elements
//     boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
//   };

//   const renderModalContent = () => {
//     if (selectedButton === "button1") { 
//       return (
//         <div  className="brandimgcar">
//           <img
//             src="https://thumbs.dreamstime.com/b/web-184786266.jpg"
//             alt=""
//             className={`brandimage ${brandName == 'Audi'? "brand":""}`}
//             // onClick={() => handleFilterButtonClick("Audi")}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Audi" });
//               setBrandName("Audi");
//             }}

//           />
//           <img
//            className={`brandimage ${brandName == 'Mercedes Benz'? "brand":""}`}
//             src="https://static.vecteezy.com/system/resources/thumbnails/020/498/727/small_2x/mercedes-benz-logo-brand-car-symbol-with-name-black-design-german-automobile-illustration-free-vector.jpg"
//             alt=""
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Mercedes Benz" });
//               setBrandName("Mercedes Benz")
//             }}
//           />    
//           <img
//             className={`brandimage ${brandName == "Tesla" ? "brand":""}`}
//             src="https://cutewallpaper.org/21/tesla-logo-images/Tesla-Black-Logo-Poster-by-Robert-Dyer.jpg"
//             alt=""
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Tesla" });
//               setBrandName("Tesla")
//             }}
//           />
//           <img
//           className={`brandimage ${brandName == "Bugatti" ? "brand":""}`}
//             src="https://www.carlogos.org/logo/Bugatti-logo-2560x1440.png"
//             alt=""
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Bugatti" });
//               setBrandName("Bugatti")
//             }}
//           />
//           {/* <img src="https://imgd.aeplcdn.com/0X0/bw/makes/royal-enfield20200508193112.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("Royal Enfield")}/>
//     <img src="https://imgd.aeplcdn.com/0X0/bw/makes/hero20200508192826.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("hero")}/>
//     <img src="https://imgd.aeplcdn.com/0X0/bw/makes/yamaha20200508193220.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("yamaha")}/>
//     <img src="https://imgd.aeplcdn.com/0X0/bw/makes/bajaj20200508192534.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("bajaj")}/>
//     <img src="https://imgd.aeplcdn.com/0X0/bw/makes/tvs20200508193203.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("tvs")}/> */}
//           <img
//           className={`brandimage ${brandName == "Skoda"?"brand":""}`}
//             src="https://i.pinimg.com/originals/0a/8e/38/0a8e3852954dd0a8b0d2602e9e5de08b.jpg"
//             alt=""
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Skoda" });
//               setBrandName("Skoda");
//             }}
//           />
//           <img
//           className={`brandimage ${brandName == 'MG Motors'?"brand":""}`}
//             src="https://i.etsystatic.com/26112859/r/il/134807/2854422983/il_fullxfull.2854422983_7caz.jpg"
//             alt=""
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "MG Motors" });
//               setBrandName("MG Motors");
//             }}
//           />
//           <img
//           className={`brandimage ${brandName == "BMW"?"brand":""}`}
//             src="https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg"
//             alt=""
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "BMW" });
//               setBrandName("BMW");
//             }}
//           />
//           <img
//           className={`brandimage ${brandName == "Honda"?"brand":""}`}
//             src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Honda.svg/1280px-Honda.svg.png"
//             alt=""
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Honda" });
//               setBrandName("Honda")
//             }}
            
//           />
//         </div>
//       );
//     } else if (selectedButton === "button2") {
//       return (
//         <div style={modalStyle} className="brandimgcar">
//           <p
//             className={`pricep ${budget === 200000 ? "budget":""}`}
//             onClick={() =>  {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "200000",
//               }));
//               setBudget(200000)
//             }}
//           >
//             Under 20lakh
//           </p>
//           <p
//             className={`pricep ${budget === 500000 ? "budget":""}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "500000",
//               }));
//               setBudget(500000)
//             }}
//           >
//             Under 50lakh
//           </p>
//           <p
//             className={`pricep ${budget == 700000 ? "budget":""}`} 
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "700000",
//               }));
//               setBudget(700000)
//             }}
//           >
//             Under 70lakh
//           </p>
//           <p
//             className={`pricep ${budget == 900000 ? "budget":""}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "900000",
//               }));
//               setBudget(900000)
//             }}
//           >
//             Under 90lakh
//           </p>
//           <p
//             className={`pricep ${budget == 12000000 ? "budget":""}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "12000000",
//               }));
//               setBudget(12000000)
//             }}
//           >
//             Under 1.2 cr
//           </p>
//           <p
//             className={`pricep ${budget == 15000000 ? "budget":""}`}
//             onClick={() => { 
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "15000000",
//               }));
//                setBudget(15000000)
//             }}
//           >
//             Under 1.5 cr
//           </p>
//           <p
//             className={`pricep ${budget == 17000000 ? "budget":""}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "17000000",
//               }));
//               setBudget(17000000)
//             }}
//           >
//             Under 1.7 cr
//           </p>
//           <p
//             className={`pricep ${budget == 20000000 ? "budget":""}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "20000000",
//                 maxPrice: "999999999999",
//               }));
//               setBudget(20000000)
//             }}
//           >
//             Above 2 cr
//           </p>
//         </div>
//       );
//     } else if (selectedButton === "button3") {
//       return (
//         <div className="biky"
//         style={{ width: '90%', display: 'flex', gap:'5%' }}
//         >
//         <div  className="brandimg"
//         style={{
//           position: 'relative',
//           top: '-50%',
//           left: '0',
//           zIndex: '10',
//           boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
//           padding: '15px',
//           borderRadius: '10px',
//           backgroundColor: '#fff',
//           textAlign: 'center',
//           display:'flex',
//           width:'45%',
//           flexDirection: 'column',
//         }}
//         >
//           {/* <img src="https://i.pinimg.com/originals/a6/35/b8/a635b8695c08306572795354576cdcb0.jpg" height={80} width={80} alt=""/>
//          <img src="https://cdnb.artstation.com/p/assets/images/images/010/097/845/large/car-design-pro-arrinera-polonya1b.jpg?1522572446" height={80} width={80} alt=""/>
//          <img src="https://i.pinimg.com/originals/cb/b5/f6/cbb5f68c82a6736424011fa7806364de.jpg" title="moped" height={80} width={80} alt=""/>
//          <img src="https://png.pngtree.com/png-clipart/20230602/original/pngtree-jeep-coloring-pages-for-kids-png-image_9177267.png" height={80} width={80} alt=""/>
//          <img src="https://i.pinimg.com/originals/22/41/7d/22417de76df0cb996b4abcf47b0ce0d1.jpg" height={80} width={80} alt=""/>
//          <img src="https://th.bing.com/th/id/OIP.yMPIhue19Iq1gZaDoCupEgHaFj?pid=ImgDet&rs=1" height={80} width={80} alt=""/>
//          <img src="https://th.bing.com/th/id/OIP.mXXaO5Q22zYTiPxgh-RCdgHaFs?pid=ImgDet&rs=1" height={80} width={80} alt=""/> */}
//          <h6 style={{display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'600'}} >Condition</h6>
//          <div>
//          <p
//             className={`pricep ${type === "New Car"? "type":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//               marginRight:'5%',
//             }}
//             onClick={() => { 
//               setExtraFieldCopy1({ "Car Type": "New Car" });
//               setType("New Car");
//             }}>
          
//            New Car
//           </p>
//          <p
//             className={`pricep ${type === 'Used Car'?"type":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//             }}
//             onClick={() => {
//               setExtraFieldCopy1({ "Car Type": "Used Car" });
//               setType("Used Car");
//             }}>
          
//            Used Car
//           </p>
//           </div>
//         </div>
//         <div  className="brandimg"
//         style={{
//           position: 'relative',
//           top: '-50%',
//           left: '0',
//           zIndex: '10',
//           boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
//           padding: '15px',
//           borderRadius: '10px',
//           backgroundColor: '#fff',
//           textAlign: 'center',
//           display:'flex',
//           width:'45%',
//           flexDirection: 'column',
//         }}
//         >
//         <h6 style={{display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'600'}}>Fuel Type</h6>
//           <div style={{display:'flex', flexFlow:'wrap'}}>
//           <p
//             className={`pricep ${type === "Electric"?"type":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//               marginRight:'5%',
//             }}
//             onClick={() => {
//               setExtraFieldCopy1({ "Fuel Type ": "Electric" });
//               setType("Electric")
//             }}>
//            Electric
//           </p>
//           <p
//             className={`pricep ${type==="Petrol"?"type":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//               marginRight:'5%',
//             }}
//             onClick={() => {
//               setExtraFieldCopy1({ "Fuel Type ": "Petrol" });
//               setType("Petrol")
//             }}>
//            Petrol
//           </p>
//           <p
//             className={`pricep ${type === 'Diesel'?"type":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//               marginRight:'5%',
//             }}
//             onClick={() => {
//               setExtraFieldCopy1({ "Fuel Type ": "Diesel" });
//               setType("Diesel")
//             }}>
//            Diesel
//           </p>
//           <p
//             className={`pricep ${type === "CNG"?"type":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//               marginRight:'5%',
//             }}
//             onClick={() => {
//               setExtraFieldCopy1({ "Fuel Type ": "CNG" });
//               setType("CNG")
//             }}>
//            CNG
//           </p>
//           <p
//             className={`pricep ${type==='LPG'?"type":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//               marginRight:'5%',
//             }}
//             onClick={() => {
//               setExtraFieldCopy1({ "Fuel Type ": "LPG" });
//               setType("LPG")
//             }}>
//            LPG
//           </p>
//           </div>
//         </div>
//         </div>

//       );
//     } else if(selectedButton === "button4"){
//       return (
//         <div className="biky">
//         <div style={{modalStyle}} id="Km123" className="brandimg" >
//         <InputLabel id="demo-simple-select-label2" style={{marginTop:'4%'}}>Year Of Registration</InputLabel>
//          <select onChange={(e)=>{setExtraFieldCopy2({"year":e.target.value});setYears(e.target.value)}}>
//           {
//             year.map((ye)=> <option>{ye.toString()}</option>)
//           }
//          </select>
//         </div>
//         </div>)
//     } else if(selectedButton === "button5"){
//       return(
//         <div className="biky">
//         <div style={{modalStyle}} id="Km123" className="brandimg" >
//         <InputLabel 
//         style={{alignItems:'center', marginTop:'4%'}}
//         id="demo-simple-select-label2">KM Driven</InputLabel>
//         <select onChange={(e)=>{setExtraFieldCopy3({"KM Driven":e.target.value});
//       setKms(e.target.value)}}>
//          {
//           km.map((k)=>{
//             return <option>{k}</option>
//           })
//          }
//         </select>
//         </div>
//         </div>
//       )
//     }

//     return null;
//   };




//   // AdsCards logic start

//   const [advClose, setadvClose] = useState(true);
//   useEffect(() => {
//     const isPopupClosed = sessionStorage.getItem('popupClosed');
//     const name = localStorage.getItem('name')
//     if (isPopupClosed && name) {
//       setadvClose(false);
//     }
//   }, []);
//   const handleCloseadv = () => {
//     setadvClose(false);
//     sessionStorage.setItem('popupClosed', 'true');
//   };
//   // AdsCards logic end



  
//   return (
//     <>


//      {/* AdsCards logic start */}
//      {advClose ? (
//       <div style={{ width: '400px', height:'230px', position:'fixed', bottom:"2%", right:"3%", padding: '20px', background: 'radial-gradient(circle, #3f8bff, #0059ff)', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', transition: 'box-shadow 0.3s ease-in-out',zIndex:"3" }}>
//      <span style={{ float: 'right', padding: '6px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"24px" }} onClick={handleCloseadv}>x</span>
//   {/* <img src="real-estate-app-icon.png" alt="Real Estate App Icon" style={{ width: '150px', height: '35px', marginBottom: '5px' }} /> */}
//   <div style={{ textAlign: 'center' }}>
//     <h2 style={{ color: '#fff', fontSize: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Elevate your drive to new heights.</h2>
//     <p style={{ color: '#f0f0f0', fontSize: '16px', marginBottom: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Discover thousands of listings and make your dream of owning a Car.</p>
//     <div style={{ marginTop: '50px' }}>
//       <div style={{ height: '60px', width: '130px', borderRadius: '10px', padding: '14px 0px 8px', fontSize: '22px', fontWeight: 'bolder', color: '#ffb300', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', background: '#1ad09a', textAlign: 'center', position: 'absolute', left: '15%', top: '72%', zIndex: '1' }}>1,000+<span style={{ fontSize: '14px' }}>subs</span></div>
//      <Link to="/Pricing"><span id="quote" style={{ fontStyle: 'normal',fontWeight:"bolder", fontSize: '12px', height: '30px', width: '170px', backgroundColor: '#eae436', position: 'absolute', left: '45%', top: '77%', zIndex: '2', padding: '4px', color:"green" }}>Upgrade your plan now!</span></Link>
//       {/* <button className="download-btn">Download Now</button> */}
//     </div>
//   </div>
// </div>

//       ) : null}
//       {/* AdsCards logic end */}



//       <div style={sliderStyle}>
//         {/* <Carousel autoplay>
//       {images.map((image, index) => (
//         <div key={index}>
//           <img src={image.url} alt={`Image ${index + 1}`} style={{ width: "100%" }} height={300}/>
//         </div>
//       ))}
//     </Carousel>  */}
//         <CarsSlider/>
//         <div className="filetr22">
//           <input
     
//             name="brand"
//             required
//             className="custom-select2 custom-select2-awi"
//             style={{width:"50%",backgroundColor:"white", boxShadow:"12px 12px 10px rgba(0, 0, 0, 0.2)"}}
//             placeholder="Search your Cars or Type model (Innova, mt-15)"
//             onChange={(e) => setSearchValue(e.target.value.trim())}
//             // dropdownClassName="select-dropdown"
//           />
//           <Link to={`/ads-listing1/`} onClick={searchArray}>
//             <button
//               style={{
//                 color: "white",
//                 backgroundColor: "rgb(9, 25, 56)",
//                 fontSize: "30px",
//                 height: "37px",
//                 width: "35px",
//                 display: "block",
//                 // padding: "8px",
//                marginLeft:"-40px",
//                  paddingBottom:"-25px",
//                  marginTop:"7px",
//                 borderRadius:"50%",
//                 position:"relative",
//                 zIndex:"1"
//                 //borderTopRightRadius: "4px",
//                 // borderBottomRightRadius: "4px",
//               }}
//             >
//               <SearchOutlined style={{fontSize:"20px",paddingBottom:"20px"}}/>
//             </button>
//           </Link>
//         </div>
        








        
//       </div>
//       <div className="wholefil mt-0">
//       {/* filter btn start */}
//       <div>
//           <center>
//             <div className="headdingforfind">
//             <div className="caption"> 
//               <h3 >
//                 Find Your Dream Car:
//               </h3>
//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
                  
//                   searchSending["extraField"] = { ...extraFieldCopy , ...extraFieldCopy1,...extraFieldCopy2,...extraFieldCopy3}; // Object Assign to searchSending object
//                   searchSending["subCategoryValue"] = "Cars" 
//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <button
//                   // className="btn houzez-search-button elementor-button elementor-size-sm"
//                   // style={{
//                   //   background: "white",
//                   //   color: "#a71616bf",

//                   // }}
//                   className="button-CARCAT-24"
//                   style={{marginBottom:"10px", padding: '10px', backgroundColor: 'whitesmoke', border: '2px double rgb(9, 25, 56)', boxShadow: "6px 6px 14px rgba(0, 0, 0, 0.2)", color: "rgb(9, 25, 56)" }}
//                   id="serbtn321"
//                 >
                    
// {/* <BsSearch style={{fontSize:"18px"}}/>           */}
//                   <FcSearch  style={{fontSize:"18px"}}/>
//                   Search
//                 </button>
                
//               </Link>
//             </div>
            
//               <div className="Button231">
//                 <div className="mainfilbtn">
//                 <button
//                   onClick={() => {setSelectedButton("button1");
//                   setActive1("act")} }
//                   className={`button321 ${active1 ==="act" ? 'act':''}`}
//                 >
//                   Brand Name
//                 </button>
//                 <button
//                   onClick={() => {setSelectedButton("button2");
//                   setActive1("act1")} }
//                   className={`button321 ${active1 ==="act1" ? 'act':''}`}

//                 >
//                   Budget
//                 </button>
//                 <button
//                   onClick={() => {setSelectedButton("button3");
//                   setActive1("act2")} }
//                   className={`button321 ${active1 ==="act2" ? 'act':''}`}
//                 >
//                   CarTYpe
//                 </button>
//                 <button
//                   onClick={() => {setSelectedButton("button4");
//                   setActive1("act3")} }
//                   className={`button321 ${active1 ==="act3" ? 'act':''}`}
//                 >
//                  Year Of Reg
//                 </button>
//                 <button
//                   onClick={() => {setSelectedButton("button5");
//                   setActive1("act4")} }
//                   className={`button321 ${active1 ==="act4" ? 'act':''}`}
//                 >
//                   KM Driven
//                 </button>
                
//                 </div>
//                 &nbsp; &nbsp;
//               </div>
//               <br />
//               <div style={{ width: "90%" }}>
//                 {selectedButton && renderModalContent()}
//               </div>
//             </div>
//           </center>
//         </div>
//       {/* filter btn end */}


//         {/* bike cat head end */}
//         {/* search end */}
//         {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
//           <div>
//             <h5>
//               {" "}
//               <div className="example">
//                 <Spin />
//               </div>{" "}
//             </h5>{" "}
//           </div>
//         )}
//         {!loading && ( // Conditionally rendering the content if 'loading' is false
//           <>
//           <div className="mb-3" style={isMobile ? mobstyle : deskstyle}>
//             <Feature value="Cars" />{" "}
//             {/* Rendering the 'Feature' component with value "RealEstate" */}
//             <div>
//               <Allcatads greeting={greeting} subcategory={"Cars"}/>
//               <TrendingAds />
//               {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
//             </div>
//           </div>
//           <div style={isMobile? mobi: deski}>
//       <TrendingAds/>
//       </div>
//       </>
//         )}
//         <NewsArticles category={"Cars"} />{" "}
//         {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
//         <MyGallery selectedCategory="Cars" />
//       </div>
//       {/* <NewsArticles category={"RealEstate"}/> */}
//     </>
//   );
// };

// export default Carscat;








// import React, { useEffect, useState } from "react"
// import FeaturedAds from '../../../Ads/FeaturedAds/featuredAds';
import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SearchOutlined } from "@ant-design/icons";
import Select1 from 'react-select';
import { InputLabel } from "@material-ui/core";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { YearOfRegistrationCar } from "../../../env";
import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import { stateMain, locationData } from "../../../Location";
import "../searchBox/SearchBox.css";
import { Link } from "react-router-dom";
import { subCategoryInput } from "../../../../Model/SubCategoryCons";
import BikeSlider from "../../../../Shared/CategorySlider/BikeSlider";
import { Carousel } from "antd";
import { FitScreen } from "@mui/icons-material";
import "./allfilter.css";
import { isMobile } from "react-device-detect";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import CarsSlider from "../../../../Shared/CategorySlider/CarsSlider";
import Allcatads from "../../../Allcatads";
import { BsSearch } from "react-icons/bs";
import { FcSearch } from "react-icons/fc";

const mobstyle = {
  width: "auto",
  marginLeft: "5px",
  marginRight: "5px",
};
const deskstyle = {
  padding: "20px",
  marginBottom: "-10px",
  marginRight: "10rem",
  marginLeft: "10rem",
};
const mobi={


  
}
const deski={
  marginLeft:"7rem",marginRight:"7rem"
}
const Carscat = () => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [extraFieldCopy1, setExtraFieldCopy1]=useState({});
  const [extraFieldCopy2, setExtraFieldCopy2]=useState({});
  const [extraFieldCopy3,setExtraFieldCopy3]=useState({});
  const [active1,setActive1]=useState(null);
  const [years,setYears]=useState();
  const [kms,setKms]=useState();
  const [brandName,setBrandName] = useState(null);
  const [budget,setBudget]=useState(null);
  const [type,setType]=useState(null);
   const [selectedOption,setSelectedOption] = useState();


 
  let year = [];
  var date = new Date().getFullYear();
  for (let i = 1980; i <= date; i++) {
    year.push(i);
  }

  let km=[];
  var Driven = subCategoryInput.Cars.Cars.KM_Driven;
  for (let i=0;i<Driven.length;i++){
    km.push(Driven[i])
  }

  const handleChanges = (selectedOption) => {
    console.log('hi Guys',selectedOption);
    setSelectedOption(selectedOption);
      console.log(`Option selected:`, selectedOption);
  
  };
  // const [selectedFilter1, setSelectedFilter1] = useState(null);

  const handleFilterButtonClick = (filterType) => {
    setSelectedFilter(filterType);
  };
  const greeting = "Cars";

  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch();
  const dispatch = useDispatch();
  // Accessing the Redux dispatch function

  useEffect(() => {
    disptach(add({ view: ["Carscat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);

  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    searchValue: null,
    category: "Cars",
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook
  const [SearchValue, setSearchValue] = useState("Car");
  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };
  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setsearchSending({ ...searchSending, [name]: value });
    disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  const sliderStyle = {
    width: "100%",
    // marginTop:"2cm",
    // maxWidth: "100vw",
  };
  const [selectedButton, setSelectedButton] = useState();

  // Step 2: Create CSS classes to control modal positioning
  const modalContainerStyle = {
    top: "-50%", // Adjust this value to control the distance from the button

    // width:"100%"
  };

  const modalStyle = {
    // position: "relative",

    // display:"flex",
    // width:"100%",
    top: "-50%", // Adjust this value to control the distance from the button
    left:0,
    zIndex: 10, // Ensure the modal is above other elements
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const renderModalContent = () => {
    if (selectedButton === "button1") { 
      return (
        <div  className="brandimgcar">
          <img
            src="https://thumbs.dreamstime.com/b/web-184786266.jpg"
            alt=""
            className={`brandimage ${brandName == 'Audi'? "brand":""}`}
            // onClick={() => handleFilterButtonClick("Audi")}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Audi" });
              setBrandName("Audi");
            }}

          />
          <img
           className={`brandimage ${brandName == 'Mercedes Benz'? "brand":""}`}
            src="https://static.vecteezy.com/system/resources/thumbnails/020/498/727/small_2x/mercedes-benz-logo-brand-car-symbol-with-name-black-design-german-automobile-illustration-free-vector.jpg"
            alt=""
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Mercedes Benz" });
              setBrandName("Mercedes Benz")
            }}
          />    
          <img
            className={`brandimage ${brandName == "Tesla" ? "brand":""}`}
            src="https://cutewallpaper.org/21/tesla-logo-images/Tesla-Black-Logo-Poster-by-Robert-Dyer.jpg"
            alt=""
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Tesla" });
              setBrandName("Tesla")
            }}
          />
          <img
          className={`brandimage ${brandName == "Bugatti" ? "brand":""}`}
            src="https://www.carlogos.org/logo/Bugatti-logo-2560x1440.png"
            alt=""
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Bugatti" });
              setBrandName("Bugatti")
            }}
          />
          {/* <img src="https://imgd.aeplcdn.com/0X0/bw/makes/royal-enfield20200508193112.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("Royal Enfield")}/>
    <img src="https://imgd.aeplcdn.com/0X0/bw/makes/hero20200508192826.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("hero")}/>
    <img src="https://imgd.aeplcdn.com/0X0/bw/makes/yamaha20200508193220.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("yamaha")}/>
    <img src="https://imgd.aeplcdn.com/0X0/bw/makes/bajaj20200508192534.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("bajaj")}/>
    <img src="https://imgd.aeplcdn.com/0X0/bw/makes/tvs20200508193203.jpg?q=75" alt="" height={100} width={100}  onClick={() => handleFilterButtonClick("tvs")}/> */}
          <img
          className={`brandimage ${brandName == "Skoda"?"brand":""}`}
            src="https://i.pinimg.com/originals/0a/8e/38/0a8e3852954dd0a8b0d2602e9e5de08b.jpg"
            alt=""
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Skoda" });
              setBrandName("Skoda");
            }}
          />
          <img
          className={`brandimage ${brandName == 'MG Motors'?"brand":""}`}
            src="https://i.etsystatic.com/26112859/r/il/134807/2854422983/il_fullxfull.2854422983_7caz.jpg"
            alt=""
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "MG Motors" });
              setBrandName("MG Motors");
            }}
          />
          <img
          className={`brandimage ${brandName == "BMW"?"brand":""}`}
            src="https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg"
            alt=""
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "BMW" });
              setBrandName("BMW");
            }}
          />
          <img
          className={`brandimage ${brandName == "Honda"?"brand":""}`}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Honda.svg/1280px-Honda.svg.png"
            alt=""
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Honda" });
              setBrandName("Honda")
            }}
            
          />
        </div>
      );
    } else if (selectedButton === "button2") {
      return (
        <div style={modalStyle} className="brandimgcar">
          <p
            className={`pricep ${budget === 200000 ? "budget":""}`}
            onClick={() =>  {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "200000",
              }));
              setBudget(200000)
            }}
          >
            Under 20lakh
          </p>
          <p
            className={`pricep ${budget === 500000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "500000",
              }));
              setBudget(500000)
            }}
          >
            Under 50lakh
          </p>
          <p
            className={`pricep ${budget == 700000 ? "budget":""}`} 
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "700000",
              }));
              setBudget(700000)
            }}
          >
            Under 70lakh
          </p>
          <p
            className={`pricep ${budget == 900000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "900000",
              }));
              setBudget(900000)
            }}
          >
            Under 90lakh
          </p>
          <p
            className={`pricep ${budget == 12000000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "12000000",
              }));
              setBudget(12000000)
            }}
          >
            Under 1.2 cr
          </p>
          <p
            className={`pricep ${budget == 15000000 ? "budget":""}`}
            onClick={() => { 
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "15000000",
              }));
               setBudget(15000000)
            }}
          >
            Under 1.5 cr
          </p>
          <p
            className={`pricep ${budget == 17000000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "17000000",
              }));
              setBudget(17000000)
            }}
          >
            Under 1.7 cr
          </p>
          <p
            className={`pricep ${budget == 20000000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "20000000",
                maxPrice: "999999999999",
              }));
              setBudget(20000000)
            }}
          >
            Above 2 cr
          </p>
        </div>
      );
    } else if (selectedButton === "button3") {
      return (
        <div className="biky"
        style={{ width: '90%', display: 'flex', gap:'5%' }}
        >
        <div  className="brandimg"
        style={{
          position: 'relative',
          top: '-50%',
          left: '0',
          zIndex: '10',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
          padding: '15px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          textAlign: 'center',
          display:'flex',
          width:'45%',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        >
          {/* <img src="https://i.pinimg.com/originals/a6/35/b8/a635b8695c08306572795354576cdcb0.jpg" height={80} width={80} alt=""/>
         <img src="https://cdnb.artstation.com/p/assets/images/images/010/097/845/large/car-design-pro-arrinera-polonya1b.jpg?1522572446" height={80} width={80} alt=""/>
         <img src="https://i.pinimg.com/originals/cb/b5/f6/cbb5f68c82a6736424011fa7806364de.jpg" title="moped" height={80} width={80} alt=""/>
         <img src="https://png.pngtree.com/png-clipart/20230602/original/pngtree-jeep-coloring-pages-for-kids-png-image_9177267.png" height={80} width={80} alt=""/>
         <img src="https://i.pinimg.com/originals/22/41/7d/22417de76df0cb996b4abcf47b0ce0d1.jpg" height={80} width={80} alt=""/>
         <img src="https://th.bing.com/th/id/OIP.yMPIhue19Iq1gZaDoCupEgHaFj?pid=ImgDet&rs=1" height={80} width={80} alt=""/>
         <img src="https://th.bing.com/th/id/OIP.mXXaO5Q22zYTiPxgh-RCdgHaFs?pid=ImgDet&rs=1" height={80} width={80} alt=""/> */}
         <b>  <h6 style={{display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'600'}} >Condition</h6></b>
         <div>
         <p
            className={`pricep ${type === "New Car"? "type":""}`}
            style={{
              display: 'inline-block',
              padding: '8px 15px',
              margin: '5px 0',
              border: '1px solid rgb(9, 25, 56)',
              borderRadius: '5px',
              color: '#333',
              marginRight:'5%',
            }}
            onClick={() => { 
              setExtraFieldCopy1({ "Car Type": "New Car" });
              setType("New Car");
            }}>
          
           New Car
          </p>
         <p
            className={`pricep ${type === 'Used Car'?"type":""}`}
            style={{
              display: 'inline-block',
              padding: '8px 15px',
              margin: '5px 0',
              border: '1px solid rgb(9, 25, 56)',
              borderRadius: '5px',
              color: '#333',
            }}
            onClick={() => {
              setExtraFieldCopy1({ "Car Type": "Used Car" });
              setType("Used Car");
            }}>
          
           Used Car
          </p>
          </div>
        </div>
        <div  className="brandimg"
        style={{
          position: 'relative',
          top: '-50%',
          left: '0',
          zIndex: '10',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
          padding: '15px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          textAlign: 'center',
          display:'flex',
          width:'45%',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        >
       <b><h6 style={{display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'600'}}>Fuel Type</h6></b>
          {/* <div style={{display:'flex', flexFlow:'wrap'}}> */}
          <div >
          <p
            className={`pricep ${type === "Electric"?"type":""}`}
            style={{
              display: 'inline-block',
              padding: '8px 15px',
              margin: '5px 0',
              border: '1px solid rgb(9, 25, 56)',
              borderRadius: '5px',
              color: '#333',
              marginRight:'5%',
            }}
            onClick={() => {
              setExtraFieldCopy1({ "Fuel Type ": "Electric" });
              setType("Electric")
            }}>
           Electric
          </p>
          <p
            className={`pricep ${type==="Petrol"?"type":""}`}
            style={{
              display: 'inline-block',
              padding: '8px 15px',
              margin: '5px 0',
              border: '1px solid rgb(9, 25, 56)',
              borderRadius: '5px',
              color: '#333',
              marginRight:'5%',
            }}
            onClick={() => {
              setExtraFieldCopy1({ "Fuel Type ": "Petrol" });
              setType("Petrol")
            }}>
           Petrol
          </p>
          <p
            className={`pricep ${type === 'Diesel'?"type":""}`}
            style={{
              display: 'inline-block',
              padding: '8px 15px',
              margin: '5px 0',
              border: '1px solid rgb(9, 25, 56)',
              borderRadius: '5px',
              color: '#333',
              marginRight:'5%',
            }}
            onClick={() => {
              setExtraFieldCopy1({ "Fuel Type ": "Diesel" });
              setType("Diesel")
            }}>
           Diesel
          </p>
          <p
            className={`pricep ${type === "CNG"?"type":""}`}
            style={{
              display: 'inline-block',
              padding: '8px 15px',
              margin: '5px 0',
              border: '1px solid rgb(9, 25, 56)',
              borderRadius: '5px',
              color: '#333',
              marginRight:'5%',
            }}
            onClick={() => {
              setExtraFieldCopy1({ "Fuel Type ": "CNG" });
              setType("CNG")
            }}>
           CNG
          </p>
          <p
            className={`pricep ${type==='LPG'?"type":""}`}
            style={{
              display: 'inline-block',
              padding: '8px 15px',
              margin: '5px 0',
              border: '1px solid rgb(9, 25, 56)',
              borderRadius: '5px',
              color: '#333',
              marginRight:'5%',
            }}
            onClick={() => {
              setExtraFieldCopy1({ "Fuel Type ": "LPG" });
              setType("LPG")
            }}>
           LPG
          </p>
          </div>
        </div>
        </div>

      );
    } else if(selectedButton === "button4"){
      return (
        <div className="biky">
        <div style={{modalStyle}} id="Km123" className="brandimg regest-BIKE-fil-but" >
        <b><h6 className="regest-BIKE-fil-header">Select Year</h6></b>
         <select  className="regest-BIKE-fil-SELECT"
          onChange={(e)=>{setExtraFieldCopy2({"year":e.target.value});setYears(e.target.value)}}>
          {
            year.map((ye)=> <option>{ye.toString()}</option>)
          }
         </select>
        </div>
        </div>)
    } else if(selectedButton === "button5"){
      return(
        <div className="biky">
        <div style={{modalStyle}} id="Km123" className="brandimg regest-BIKE-fil-but" >
        {/* <InputLabel 
        style={{alignItems:'center', marginTop:'4%'}}
        id="demo-simple-select-label2">KM Driven</InputLabel> */}
        <b><h6 className="regest-BIKE-fil-header" >KM Driven</h6></b> 
        <select  className="regest-BIKE-fil-SELECT" onChange={(e)=>{setExtraFieldCopy3({"KM Driven":e.target.value});
      setKms(e.target.value)}}>
         {
          km.map((k)=>{
            return <option>{k}</option>
          })
         }
        </select>
        </div>
        </div>
      )
    }

    return null;
  };




  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);
  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end



  
  return (
    <>


     {/* AdsCards logic start */}
     {advClose ? (
      <div style={{ width: '400px', height:'230px', position:'fixed', bottom:"2%", right:"3%", padding: '20px', background: 'radial-gradient(circle, #3f8bff, #0059ff)', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', transition: 'box-shadow 0.3s ease-in-out',zIndex:"3" }}>
     <span style={{ float: 'right', padding: '6px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"24px" }} onClick={handleCloseadv}>x</span>
  {/* <img src="real-estate-app-icon.png" alt="Real Estate App Icon" style={{ width: '150px', height: '35px', marginBottom: '5px' }} /> */}
  <div style={{ textAlign: 'center' }}>
    <h2 style={{ color: '#fff', fontSize: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Elevate your drive to new heights.</h2>
    <p style={{ color: '#f0f0f0', fontSize: '16px', marginBottom: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Discover thousands of listings and make your dream of owning a Car.</p>
    <div style={{ marginTop: '50px' }}>
      <div style={{ height: '60px', width: '130px', borderRadius: '10px', padding: '14px 0px 8px', fontSize: '22px', fontWeight: 'bolder', color: '#ffb300', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', background: '#1ad09a', textAlign: 'center', position: 'absolute', left: '15%', top: '72%', zIndex: '1' }}>1,000+<span style={{ fontSize: '14px' }}>subs</span></div>
     <Link to="/Pricing"><span id="quote" style={{ fontStyle: 'normal',fontWeight:"bolder", fontSize: '12px', height: '30px', width: '170px', backgroundColor: '#eae436', position: 'absolute', left: '45%', top: '77%', zIndex: '2', padding: '4px', color:"green" }}>Upgrade your plan now!</span></Link>
      {/* <button className="download-btn">Download Now</button> */}
    </div>
  </div>
</div>

      ) : null}
      {/* AdsCards logic end */}



      <div style={sliderStyle}>
        {/* <Carousel autoplay>
      {images.map((image, index) => (
        <div key={index}>
          <img src={image.url} alt={`Image ${index + 1}`} style={{ width: "100%" }} height={300}/>
        </div>
      ))}
    </Carousel>  */}
        <CarsSlider/>
        <div className="filetr22">
          <input
     
            name="brand"
            required
            className="custom-select2 custom-select2-awi"
            style={{width:"50%",backgroundColor:"white", boxShadow:"12px 12px 10px rgba(0, 0, 0, 0.2)"}}
            placeholder="Search your Cars or Type model (Innova, mt-15)"
            onChange={(e) => setSearchValue(e.target.value.trim())}
            // dropdownClassName="select-dropdown"
          />
          <Link to={`/ads-listing1/`} onClick={searchArray}>
            <button
              style={{
                color: "white",
                backgroundColor: "rgb(9, 25, 56)",
                fontSize: "30px",
                height: "37px",
                width: "35px",
                display: "block",
                // padding: "8px",
               marginLeft:"-40px",
                 paddingBottom:"-25px",
                 marginTop:"7px",
                borderRadius:"50%",
                position:"relative",
                zIndex:"1"
                //borderTopRightRadius: "4px",
                // borderBottomRightRadius: "4px",
              }}
            >
              <SearchOutlined style={{fontSize:"20px",paddingBottom:"20px"}}/>
            </button>
          </Link>
        </div>
        








        
      </div>
      <div className="wholefil mt-0">
      {/* filter btn start */}
      <div>
          <center>
            <div className="headdingforfind">
            <div className="caption caption-CARCAT-24"> 
              <h3 >
                Find Your Dream Car:
              </h3>
              <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  
                  searchSending["extraField"] = { ...extraFieldCopy , ...extraFieldCopy1,...extraFieldCopy2,...extraFieldCopy3}; // Object Assign to searchSending object
                  searchSending["subCategoryValue"] = "Cars" 
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
                <button
                  // className="btn houzez-search-button elementor-button elementor-size-sm"
                  // style={{
                  //   background: "white",
                  //   color: "#a71616bf",

                  // }}
                  className="button-CARCAT-24"
                  style={{marginBottom:"10px", padding: '10px', backgroundColor: 'whitesmoke', border: '2px double rgb(9, 25, 56)', boxShadow: "6px 6px 14px rgba(0, 0, 0, 0.2)", color: "rgb(9, 25, 56)" }}
                  id="serbtn321"
                >
                    
{/* <BsSearch style={{fontSize:"18px"}}/>           */}
                  <FcSearch  style={{fontSize:"18px"}}/>
                  Search
                </button>
                
              </Link>
            </div>
            
              <div className="Button231 but-BIKE-whole-cont">
                <div className="mainfilbtn">
                <button
                  onClick={() => {setSelectedButton("button1");
                  setActive1("act")} }
                  className={`button321 ${active1 ==="act" ? 'act':''}`}
                >
                  Brand Name
                </button>
                <button
                  onClick={() => {setSelectedButton("button2");
                  setActive1("act1")} }
                  className={`button321 ${active1 ==="act1" ? 'act':''}`}

                >
                  Budget
                </button>
                <button
                  onClick={() => {setSelectedButton("button3");
                  setActive1("act2")} }
                  className={`button321 ${active1 ==="act2" ? 'act':''}`}
                >
                  CarTYpe
                </button>
                <button
                  onClick={() => {setSelectedButton("button4");
                  setActive1("act3")} }
                  className={`button321 ${active1 ==="act3" ? 'act':''}`}
                >
                 Year Of Reg
                </button>
                <button
                  onClick={() => {setSelectedButton("button5");
                  setActive1("act4")} }
                  className={`button321 ${active1 ==="act4" ? 'act':''}`}
                >
                  KM Driven
                </button>
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  
                  searchSending["extraField"] = { ...extraFieldCopy , ...extraFieldCopy1,...extraFieldCopy2,...extraFieldCopy3}; // Object Assign to searchSending object
                  searchSending["subCategoryValue"] = "Cars" 
                  disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                }}
              >
             <button className='button321 button-BIKECAT-24'>

             <BsSearch  className="Fcsearch-class" />
                      &nbsp;  Search
                </button>
                </Link>
                </div>
                &nbsp; &nbsp;
              </div>
              <br />
              <div style={{ width: "90%" }}>
                {selectedButton && renderModalContent()}
              </div>
            </div>
          </center>
        </div>
      {/* filter btn end */}


        {/* bike cat head end */}
        {/* search end */}
        {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
          <div>
            <h5>
              {" "}
              <div className="example">
                <Spin />
              </div>{" "}
            </h5>{" "}
          </div>
        )}
        {!loading && ( // Conditionally rendering the content if 'loading' is false
          <>
          <div className="mb-3" style={isMobile ? mobstyle : deskstyle}>
            <Feature value="Cars" />{" "}
            {/* Rendering the 'Feature' component with value "RealEstate" */}
            <div>
              <Allcatads greeting={greeting} subcategory={"Cars"}/>
              <TrendingAds />
              {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
            </div>
          </div>
          <div style={isMobile? mobi: deski}>
      <TrendingAds/>
      </div>
      </>
        )}
        <NewsArticles category={"Cars"} />{" "}
        {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
        <MyGallery selectedCategory="Cars" />
      </div>
      {/* <NewsArticles category={"RealEstate"}/> */}
    </>
  );
};

export default Carscat;