import React, { useState } from 'react';
import './allfilter.css';

const Jobsubcat = () => {
  const [showFullText, setShowFullText] = useState(false);
  const [showFullText1, setShowFullText1] = useState(false);
  const [showFullText2, setShowFullText2] = useState(false);

  const toggleShowText = () => {
    setShowFullText(!showFullText);
  };

  const toggleShowText1 = () => {
    setShowFullText1(!showFullText1);
  };

  const toggleShowText2 = () => {
    setShowFullText2(!showFullText2);
  };

  const sampleText = `
    Contract jobs are a form of employment where individuals are hired 
    by a company or organization for a specific task, project, or duration, 
    rather than as permanent, full-time employees. These engagements may vary
    in length, ranging from short-term assignments lasting a few weeks to 
    long-term projects spanning several months or even years. The defining 
    characteristic of contract jobs is their finite duration, making them an
    attractive option for professionals seeking flexible work arrangements.
    Contract jobs come with several advantages that make them an attractive 
    choice for both job seekers and employers.
  `;

  const sampleText1 = `
    A full-time job is a form of employment where individuals commit to working
    a standard number of hours per week, typically around 35 to 40 hours, for 
    a specific employer. Unlike contract or part-time positions, full-time
    employees often enjoy a range of benefits and employment perks, including
    health insurance, retirement plans, paid time off, and job security. These
    positions are found across various industries and encompass a broad spectrum 
    of roles, from administrative positions to technical and managerial positions.
    Full-time jobs offer a myriad of advantages that contribute to career stability and personal growth.
  `;

  const sampleText2 = `
    Walk-in jobs, as the name suggests, involve a job application process that
    doesn't require prior scheduling or appointments. Instead, candidates have 
    the freedom to visit a potential employer's physical location, typically a 
    business or organization, during specified hours to inquire about job openings, 
    submit their resumes, and even participate in on-the-spot interviews.
    The defining feature of walk-in jobs is their swiftness. Unlike traditional 
    hiring processes that can involve weeks or even months of waiting, walk-in jobs
    offer the possibility of immediate employment. If you meet the employer's criteria 
    and impress during the on-the-spot interview, you might leave with a job offer in hand, 
    ready to start work almost immediately.
  `;

  return (
    <div>
      <div>
        <div>
          <>
            <div className="clear" />

            <div className="clear" />
            <div className="grey-light-top">
              <div className="inner-wrapper">
                <div class="inner-wrapper-l-1">
                  <img
                    src="https://img.favpng.com/11/0/4/employment-contract-conveyancing-portable-network-graphics-clip-art-png-favpng-xbnupkjieP5Jt9EvzsTn82Ema.jpg"
                    alt="Free Matrimony Site"
                    height={229}
                    width={400}
                    style={{ borderRadius: '50%' }}
                  />
                </div>
                <div className="inner-wrapper-l-r">
                  <h2>
                    "Unlock Opportunities: Explore the World of Contract Jobs"
                    {!showFullText && (
                      <button onClick={toggleShowText} style={{ color: 'blue' }}>
                        Show More
                      </button>
                    )}
                  </h2>
                  {showFullText ? (
                    <p>
                      {sampleText}
                      <button onClick={toggleShowText} style={{ color: 'blue' }}>
                        Show Less
                      </button>
                    </p>
                  ) : null}
                </div>
                <div className="clear" />
              </div>
            </div>
          </>
        </div>

        <div>
          <>
            <div className="clear" />

            <div className="clear" />
            <div className="grey-light-top">
              <div className="inner-wrapper">
                <div className="inner-wrapper-l-2">
                  <img
                    src="https://c8.alamy.com/comp/2ATT79Y/full-time-job-label-full-time-jobround-band-sign-full-time-job-stamp-2ATT79Y.jpg"
                    alt="Free Matrimony Site"
                    height={229}
                    width={400}
                    style={{ borderRadius: '50%' }}
                  />
                </div>
                <div className="inner-wrapper-l-r">
                  <h2>
                    "Embrace Stability: Your Path to Success with Full-Time Employment"
                    {!showFullText1 && (
                      <button onClick={toggleShowText1} style={{ color: 'blue' }}>
                        Show More
                      </button>
                    )}
                  </h2>
                  {showFullText1 ? (
                    <p>
                      {sampleText1}
                      <button onClick={toggleShowText1} style={{ color: 'blue' }}>
                        Show Less
                      </button>
                    </p>
                  ) : null}
                </div>
                <div className="clear" />
              </div>
            </div>
          </>
        </div>

        <div>
          <>
            <div className="clear" />

            <div className="clear" />
            <div className="grey-light-top">
              <div className="inner-wrapper">
                <div className="inner-wrapper-l-3">
                  <img
                    src="https://www.gcreddy.com/wp-content/uploads/2019/05/IT-Walkin-Jobs-1024x576.jpg"
                    alt="Free Matrimony Site"
                    height={229}
                    width={400}
                    style={{ borderRadius: '45%' }}
                  />
                </div>
                <div className="inner-wrapper-l-r">
                  <h2>
                    "Walk-In Jobs: Your Shortcut to Immediate Employment Opportunities"
                    {!showFullText2 && (
                      <button onClick={toggleShowText2} style={{ color: 'blue' }}>
                        Show More
                      </button>
                    )}
                  </h2>
                  {showFullText2 ? (
                    <p>
                      {sampleText2}
                      <button onClick={toggleShowText2} style={{ color: 'blue' }}>
                        Show Less
                      </button>
                    </p>
                  ) : null}
                </div>
                <div className="clear" />
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Jobsubcat;
