import React from 'react'
import "./businessverify.css"
import CategoryEnquiryForm from './CategoryEnquiryForm'
import Contact from '../component/Pages/Contact/Bussinessform'
import { isMobile } from "react-device-detect";
import Owldemo2 from '../component/Owldemo2';

const mobuse = {
  marginLeft: "5px", marginRight: "5px"
}
const deskuse = {
  marginLeft: "11.5rem", marginRight: "11.5rem"
}

  // allAds geetings start
  const greeting = "RealEstate";
  const greeting1 = "Furniture";
  const greeting2 = "Electronics";
  const greeting3 = "Cars";
  const greeting4 = "Bikes";
  const greeting5 = "Pets";
  const greeting6 = "Mobiles";
  const greeting7 = "Services";
  const greeting8 = "Education";
  const greeting9 = "Jobs";
  //  allAds geetings end


const Bussinessverify = () => {
  return (
    <div> 
      <div className="cardcomp2">
    <div className="wrappercard2">
      <input type="radio" name="slider" id="tab-111" defaultChecked="" />
      <input type="radio" name="slider" id="tab-211"  />
      
      <header>
        <label htmlFor="tab-111" className="tab-111">
          For Business
        </label>

        <label htmlFor="tab-211" className="tab-211">
          Bussiness Enquiry
        </label>


        <div className="slider11" />
      </header>
      <div className="card-area1">
        <div className="cardsd1">
          <div className="rowsd1 row-1">
           <Contact/>
          </div>
          <div className="rowsd1  ">
         <CategoryEnquiryForm/>
          </div>
        </div>
      </div>
    </div>
    </div>
   
    </div>
  )
}

export default Bussinessverify