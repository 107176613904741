// import React, { useEffect, useState } from "react";
// // import FeaturedAds from '../../../Ads/FeaturedAds/featuredAds';
// import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
// import CategoryUiSection from "../CategoryUiScetion/CategoryUiSection"; // Importing the 'CategoryUiSection' component
// import SearchBox from "../searchBox/SearchBox"; // Importing the 'SearchBox' component
// import RealEstateSection from "../section/realEstateSection"; // Importing the 'RealEstateSection' component
// import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import { Citys ,location1} from "../../../Location";
// import FormControl from '@mui/material/FormControl';
// import Select,{ SelectChangeEvent } from '@mui/material/Select';
// import Select1 from 'react-select';
// import { SearchOutlined } from "@ant-design/icons";
// import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
// import MyGallery from "./MyGallery";
// import { useContext } from "react"; // Importing the necessary hooks from React
// import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
// import { Input, Spin } from "antd";
// import { useDispatch } from "react-redux";
// import { add } from "../../../../store/Track/trackUserSlice";
// import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
// import apple from '../../../images/apple.png';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';
// import blackberry from '../../../images/blackberry.png';
// import blueberry from '../../../images/blueberry.jpeg';
// import centric from '../../../images/centric.jpeg';
// import creo from '../../../images/creo.png';
// import htc from '../../../images/htc.png';
// import lava from '../../../images/lava.png';
// import redmi from '../../../images/Redmi.png';
// import {
//   All,
//   subcategoryRealEstateBuy, // Imported from "../../../env"
//   subcategoryRealEstateRent, // Imported from "../../../env"
//   subcategoryType1, // Imported from "../../../env"
// } from "../../../env";
// import { stateMain, locationData } from "../../../Location";
// import "../searchBox/SearchBox.css";
// import { Link } from "react-router-dom";
// import MobileSlider from "../../../../Shared/CategorySlider/MobileSlider";
// import { Carousel } from "antd";
// import { FitScreen } from "@mui/icons-material";
// import "./allfilter.css";
// import { isMobile } from "react-device-detect";
// import ElectronicsSlider from "../../../../Shared/CategorySlider/ElectronicsSlider";
// import { categoryModel } from "../../../../Model/categoryCons";
// import { searchApi } from "../../../../store/ToggleSearchSlice";
// import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
// import { setPersistence } from "firebase/auth";


// const mobstyle = {
//   width: "auto",
//   marginLeft: "16px",
//   marginRight: "16px",
// };
// const deskstyle = {
//   padding: "20px",
//   marginBottom: "-10px",
//   marginRight: "10rem",
//   marginLeft: "10rem",
// };
// const mobi={



// }
// const deski={
//   marginLeft:"7rem",marginRight:"7rem"
// }
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 700,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };


// const Electronicscat = () => {
//   const [selectedFilter, setSelectedFilter] = useState(null);
//   // const [selectedFilter1, setSelectedFilter1] = useState(null);
//   const [visible1, setVisible1] = useState(false);
//   const handleDropdownVisibleChange = () => {
//     setVisible1(true);
//   };
//   const [selectedOption,setSelectedOption]=useState({});
//   const [SearchValue, setSearchValue] = useState("");
//   const [brand,setBrand]=useState(null);
//   const [city, setCity] = useState();
//   const [acType,setAcType]=useState(null);
//   const [condition,setCondition]=useState(null);
//   const [capacity,setCapacity]=useState(null);
//   const [productType,setProductType]=useState(null);
//   const [ovenType,setOvenType]=useState(null);
//   const [speakerType,setSpeakerType]=useState(null);
//   const [doorStyle,setDoorStyle]=useState(null);
//   const [type,setType]=useState(null);
//   const [open, setOpen] = React.useState(false);
//   const [budget,setBudget]=useState(null);

//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [extraFieldCopy1, setExtraFieldCopy1] = useState({});
//   const [extraFieldCopy2,setExtraFieldCopy2]=useState({});
//   const [extraFieldCopy3,setExtraFieldCopy3]=useState({});
//   const [extraFieldCopy4,setExtraFieldCopy4]=useState("");

//   const searchArray = () => {
//     if (SearchValue?.trim()) {
//       dispatch(searchApi(SearchValue));
//     }
//   };
//   const handleFilterButtonClick = (filterType) => {
//     setSelectedFilter(filterType);
//   };
//   const greeting = "Electronics";


//   let cities=[];

//     Citys.map((value)=>{
//      return cities.push({label: value, value:value});
//     });




//   const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
//   const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
//   const disptach = useDispatch(); // Accessing the Redux dispatch function
//   const dispatch = useDispatch();

//   const [subCategory, setSubCategory] = useState();

//   const handleChange2 = (e) => {
//     setSubCategory(e.target.value);
//     setOpen(true);
//   };



//   const handleClose = () => {
//     setOpen(false);
//   }

//   useEffect(() => {
//     disptach(add({ view: ["Bikescat"] })); // Dispatching an action using Redux to add the view to the user's track
//   }, []);

//   const [searchSending, setsearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: "Electronics",
//     minPrice: null,
//     maxPrice: null,
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook

//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setExtraFieldCopy({ [name]: value });
//     // setsearchSending({ ...searchSending, [name]: value });
//     // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   const handleChanges = (selectedOption) => {
//     console.log('hi Guys',selectedOption.value);
//     setSelectedOption(selectedOption);
//       console.log(`Option selected:`, selectedOption);
//   };

//   useEffect(() => {
//     // Update searchSending state with the selected filter value
//     if (selectedFilter !== null) {
//       setsearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);
//   const sliderStyle = {
//     width: "100%",
//     // marginTop:"2cm",
//     // maxWidth: "100vw",
//   };
//   const [selectedButton, setSelectedButton] = useState("button1", "");

//   // Step 2: Create CSS classes to control modal positioning
//   const modalContainerStyle = {

//     top: "-50%", // Adjust this value to control the distance from the button

//     // width:"100%"
//   };

//   const modalStyle = {
//     // position: "relative",

//     // display:"flex",
//     // width:"100%",
//     top: "-50%", // Adjust this value to control the distance from the button
//     left: 0,
//     zIndex: 10, // Ensure the modal is above other elements
//     backgroundColor: "white",
//     boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
//   };

//   const renderModalContent = () => {
//     if (subCategory == "Air Conditioners - AC") {
//       return (
//         <div>
//          <FormControl fullWidth>

//   <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//   <Select
//     labelId="demo-simple-select-label"
//     id="demo-simple-select"
//     value={brand}
//     label="Brand"
//     onChange={(e)=>{

//       var brand1 = e.target.value;
//       setBrand(brand1)
//       setExtraFieldCopy({"Brand Name": ` ${brand1}`})}}
//   >
//     <MenuItem value="Bosch">Bosch</MenuItem>
//     <MenuItem value="Buyerzone">Buyerzone</MenuItem>
//     <MenuItem value="DPex">D Pex</MenuItem>
//     <MenuItem value="Intex">Intex</MenuItem>
//     <MenuItem value="LG">LG</MenuItem>
//     <MenuItem value="Onida">Onida</MenuItem>
//      <MenuItem value="Panasonic">Panasonic</MenuItem>
//      <MenuItem value="Sony">Sony</MenuItem>
//   </Select>
// </FormControl>

// <InputLabel id="demo-simple-select-label2">AC Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//               setAcType(20000);
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "20000",
//               })) }}/>} label="Window" />
// <FormControlLabel required control={<Checkbox onClick={() => {

//                     setAcType(50000);
//                     setsearchSending((prev) => ({
//                       ...prev,
//                       minPrice: "0",
//                       maxPrice: "50000",
//                     }));
//                   }}/>} label="Split" />

// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : " Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : " Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : " Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : " Unboxed" })}}
//                     />} label="Unboxed" />


// </div>)

//     }
//    else if(subCategory == 'Air Coolers')
//     {
//       return(<div>
//       <FormControl fullWidth>

//   <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//   <Select
//     labelId="demo-simple-select-label"
//     id="demo-simple-select"
//     value={brand}
//     label="Brand"
//     onChange={(e)=> {  
//       var brand1 = e.target.value;
//       setBrand(brand1);
//       setExtraFieldCopy({ "Brand Name" : brand1 })}}
//   >
//     <MenuItem value="BMQ">BMQ</MenuItem>
//     <MenuItem value=" Bajaj">Bajaj</MenuItem>
//     <MenuItem value=" Blue Star">Blue Star</MenuItem>
//     <MenuItem value=" Cello">Cello</MenuItem>
//     <MenuItem value="Hindware">Hindware</MenuItem>
//     <MenuItem value=" Hotstar">Hotstar</MenuItem>
//      <MenuItem value="kelvinator">kelvinator</MenuItem>
//      <MenuItem value="Havells">Havells</MenuItem>
//   </Select>
// </FormControl>

// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : " Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : " Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : " Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : " Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCapacity(11);
//                setExtraFieldCopy2({ "Capacity" : 11 })}}/>} label="11L-15L" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCapacity(16);
//                  setExtraFieldCopy2({ "Capacity" : 16 })}}
//                     />} label="16L-20L" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCapacity(21);
//                  setExtraFieldCopy2({ "Capacity" :  21 })}}
//                     />} label="21L-40L" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCapacity(40);
//                  setExtraFieldCopy2({ "Condition" : 40 })}}
//                     />} label="40L & above" />

// {/* <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "2000",
//                 maxPrice: "3000",
//               }))}}/>} label="2000-3000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice: "3000",
//                   maxPrice: "4000",
//                 }))}}
//                     />} label="3000-4000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice: "4000",
//                   maxPrice: "5000",
//                 }))}}
//                     />} label="4000-5000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice: "5000",
//                   maxPrice: "6000",
//                 }))}}
//                     />} label="5000-6000" /> */}
// </div>)
//     }
//    else if(subCategory == 'Camera Accessories')
//    {
//     return(<div>
//       <FormControl fullWidth>

//     <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//     <Select
//       labelId="demo-simple-select-label"
//       id="demo-simple-select"
//       value={brand}
//       label="Brand"
//       onChange={(e)=> {  
//         var brand1 = e.target.value;
//         setBrand(brand1);
//         setExtraFieldCopy({ "Accessories Type" : brand1})}}
//     >
//       <MenuItem value="Camera Bag">Camera Bag</MenuItem>
//       <MenuItem value="Filter">Filter</MenuItem>
//       <MenuItem value="Flash">Flash</MenuItem>
//       <MenuItem value="Lens Cap">Lens Cap</MenuItem>
//       <MenuItem value="Lens Cleaner">Lens Cleaner</MenuItem>
//       <MenuItem value="Memory Card">Memory Card</MenuItem>
//        <MenuItem value="Others">Others</MenuItem>
//        <MenuItem value="Strap">Strap</MenuItem>
//     </Select>
//   </FormControl>

//   <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : " Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : " Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : " Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : " Unboxed" })}}
//                     />} label="Unboxed" />


// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {

//    setsearchSending((prev) => ({
//     ...prev,
//     minPrice:"0",
//     maxPrice: "20000",

//   })) 
//   setBudget("20000")
//                }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {

//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 }))
//                 setBudget("40000")
//                }}

//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"40000",
//                     maxPrice: "60000",
//                   }))
//                   setBudget("60000") }}
//                     />} label="40000-60000"/>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"60000",
//                   maxPrice: "80000",
//                 })) 
//                 setBudget("80000")}}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />

//   </div>)
//    }
//        else if(subCategory == 'Cameras')
//        {
//         return(<div>
//           <FormControl fullWidth>

//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Brand"
//             onChange={(e)=> {  
//               var brand1 = e.target.value;
//               setBrand(brand1);
//               setExtraFieldCopy({ "Brand Name" : brand1 })}}
//           >
//             <MenuItem value="Casio">Casio</MenuItem>
//             <MenuItem value="Flip">Flip</MenuItem>
//             <MenuItem value="Kodak">Kodak</MenuItem>
//             <MenuItem value="Olympus">Olympus</MenuItem>
//             <MenuItem value="Panasonic">Panasonic</MenuItem>
//             <MenuItem value="Canon">Canon</MenuItem>
//              <MenuItem value="Leica">Leica</MenuItem>
//              <MenuItem value="Nikon">Nikon</MenuItem>
//           </Select>
//         </FormControl>

//         <InputLabel id="demo-simple-select-label2">Product Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setExtraFieldCopy1({ "Product Type": " Camcorders" })
//                setProductType("Camcoders")}}/>} label="Camcoders" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({"Product Type": "Binocular"})
//                  setProductType("Binocular")}}
//                     />} label="Binocular" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({"Product Type": "DigitalSLR"})
//                  setProductType("DigitalSLR")}}
//                     />} label="DigitalSLR" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({"Product Type": "Film Cameras"})
//                  setProductType("Film Cameras")}}
//                     />} label="Film Cameras" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({"Product Type": "Telescope"})
//                  setProductType("Telescope")}}
//                     />} label="Telescope" />


// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy2({ "Condition" : " Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy2({ "Condition" : " Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy2({ "Condition" : " Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy2({ "Condition" : " Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />


//             <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />


//          </div>
//         )
//        }


//        else if(subCategory == 'Gas Stove'){
//         return(
//    <div>
//           <FormControl fullWidth>

//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Brand"
//             onChange={(e)=> {  
//               var brand1= e.target.value;
//               setBrand(brand1);
//               setExtraFieldCopy({ "Brand Name" : brand1 })}}
//           >
//             <MenuItem value=" Apex">Apex</MenuItem>
//             <MenuItem value=" Longer">Longer</MenuItem>
//             <MenuItem value=" Kodak">Kodak</MenuItem>
//             <MenuItem value=" Orbit">Orbit</MenuItem>
//             <MenuItem value=" Oster">Oster</MenuItem>
//             <MenuItem value=" Others">Others</MenuItem>
//              <MenuItem value=" Ovastar">Ovastar</MenuItem>
//              <MenuItem value="Phillips">Phillips</MenuItem>
//           </Select>
//         </FormControl>

//         <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : " Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : " Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : " Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : " Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />

//    </div>
//         )
//        }

//        else if(subCategory == 'Induction Cook Tops')
//        {
//         return(<div>
//         <FormControl fullWidth>

//         <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           value={brand}
//           label="Brand"
//           onChange={(e)=> {
//             var brand1=e.target.value;
//             setBrand(brand1);
//             setExtraFieldCopy({ "Brand Name" : brand1 })}}
//         >
//           <MenuItem value="Greenchef">Greenchef</MenuItem>
//           <MenuItem value="Havells">Havells</MenuItem>
//           <MenuItem value="Hilex">Hilex</MenuItem>
//           <MenuItem value="Hindware">Hindware</MenuItem>
//           <MenuItem value="Hotstar">Hotstar</MenuItem>
//           <MenuItem value="Impex">Impex</MenuItem>
//            <MenuItem value="Jaipan">Jaipan</MenuItem>
//            <MenuItem value="Jindal">Jindal</MenuItem>
//         </Select>
//       </FormControl>
//       <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />



// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//    </div>)
//        }
//        else if(subCategory == 'Laptops Or (Desktops - Computers)'){
//         return(
//           <div>
//              <FormControl fullWidth>

//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Brand"
//             onChange={(e)=> {  
//               var brand1 = e.target.value;
//               setBrand(brand1);
//               setExtraFieldCopy({ "Brand Name" : brand1 })}}
//           >
//             <MenuItem value="Hp">Hp</MenuItem>
//             <MenuItem value="IBM">IBM</MenuItem>
//             <MenuItem value="Lenova">Lenova</MenuItem>
//             <MenuItem value="Samsung">Samsung</MenuItem>
//             <MenuItem value="Wipro">Wipro</MenuItem>
//             <MenuItem value="Zenith">Zenith</MenuItem>
//              <MenuItem value="Asus">Asus</MenuItem>
//              <MenuItem value="Dell">Dell</MenuItem>
//           </Select>
//         </FormControl>

//         <InputLabel id="demo-simple-select-label2">Product Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setProductType("Desktop")
//                setExtraFieldCopy1({ "Product Type" :"Desktop"})}}/>} label="Desktop" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setProductType("Laptop")
//                  setExtraFieldCopy1({ "Product Type" :"Laptop"})}}
//                     />} label="Laptop" />

// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy2({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy2({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy2({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy2({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//           </div>
//         )
//        }
//   else if(subCategory == 'Microwave Ovens'){
//     return(<div>
//       <FormControl fullWidth>

//       <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//       <Select
//         labelId="demo-simple-select-label"
//         id="demo-simple-select"
//         value={brand}
//         label="Brand"
//         onChange={(e)=> {  
//           var brand1 = e.target.value;
//           setBrand(brand1);
//           setExtraFieldCopy({ "Brand Name" : brand1 })}}
//       >
//         <MenuItem value="LG">LG</MenuItem>
//         <MenuItem value="Onida">Onida</MenuItem>
//         <MenuItem value="Others">Others</MenuItem>
//         <MenuItem value="Panasonic">Panasonic</MenuItem>
//         <MenuItem value="Samsung">Samsung</MenuItem>
//       </Select>
//     </FormControl>

//     <InputLabel id="demo-simple-select-label2">Oven Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setExtraFieldCopy1({"Oven Type": "Convection"})
//                 setOvenType("Convection")}}/>} label="Convection" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setExtraFieldCopy1({"Oven Type": "Grill"})
//                   setOvenType("Grill")}}
//                     />} label="Grill" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({"Oven Type": "Solo"})
//                  setOvenType("Solo")}}
//                     />} label="Solo" />

// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy2({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy2({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy2({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy2({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setExtraFieldCopy3({ Capacity : "Upto 20L"})
//                setCapacity(20)}}/>} label="Upto 20L" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy3({ Capacity : "Upto 20L"})
//                  setCapacity(21)}}
//                     />} label="21L-25L" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setExtraFieldCopy3({ Capacity : "Upto 20L"})
//                 setCapacity(26)}}
//                     />} label="26L-30L" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy3({ Capacity : "Upto 20L"})
//                  setCapacity(31)}}
//                     />} label="31L & Above" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//  </div>
//     )

//   }

//   else if(subCategory == 'Mixer - Grinder - Juicer'){
//     return(
//       <div>
//         <FormControl fullWidth>

//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Brand"
//             onChange={(e)=> {
//               var brand1 = e.target.value;
//               setBrand(brand1);
//               setExtraFieldCopy({ "Brand Name" : ` ${brand1}` })}}
//           >
//             <MenuItem value="Activa">Activa</MenuItem>
//             <MenuItem value="Aditi">Aditi</MenuItem>
//             <MenuItem value="Amazing Bullet">Amazing Bullet</MenuItem>
//             <MenuItem value="Anjani">Anjani</MenuItem>
//             <MenuItem value="Apex">Apex</MenuItem>
//             <MenuItem value="Arise">Arise</MenuItem>
//             <MenuItem value="Asian">Asian</MenuItem>
//           </Select>
//         </FormControl>

//         <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />


// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",

//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//         </div>
//     )
//   }

//   else if(subCategory == 'Monitor Or Printers Or Scanners')
//   {
//     return(
//       <div>
//         <FormControl fullWidth>
//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Brand"
//             onChange={(e)=> {   setExtraFieldCopy({ "Peripheral Type": brand})
//             setBrand(e.target.value)}}
//           >
//             <MenuItem value="Access Point">Access Point</MenuItem>
//             <MenuItem value="Battery">Battery</MenuItem>
//             <MenuItem value="CPu">CPU</MenuItem>
//             <MenuItem value="Cooling Pad">Cooling Pad</MenuItem>
//             <MenuItem value="Head Phones">Head Phones</MenuItem>
//             <MenuItem value="Headset">Headset</MenuItem>
//             <MenuItem value="Keyboard">Keyboard</MenuItem>
//             <MenuItem value="Mouse">Mouse</MenuItem>
//           </Select>
//         </FormControl>

//         <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />


// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//       </div>
//     )
//   }

//    if(subCategory == 'Music Systems - Home Theatre'){
//     return(

//       <div>
//          <FormControl fullWidth>
//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Brand"
//             onChange={(e)=> {    
//               var brand1 = e.target.value;
//               setBrand(brand1)
//               setExtraFieldCopy({"Brand Name": ` ${brand1}`})
//             }}
//           >
//             <MenuItem value="Canon">Canon</MenuItem>
//             <MenuItem value="Casio">Casio</MenuItem>
//             <MenuItem value="Flip">Flip</MenuItem>
//             <MenuItem value="Kodak">Kodak</MenuItem>
//             <MenuItem value="Leica">Leica</MenuItem>
//             <MenuItem value="Nikon">Nikon</MenuItem>
//             <MenuItem value="Olympus">Olympus</MenuItem>
//             <MenuItem value="Others">Others</MenuItem>
//           </Select>
//         </FormControl>

//         <InputLabel id="demo-simple-select-label2">Speaker Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setExtraFieldCopy1({ "Speaker Type":"Blueshelf speakers"})
//                setSpeakerType("Blueshelf Speakers")}}/>} label="Blueshelf speakers" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({ "Speaker Type":"Bluetooth speakers"})
//                  setSpeakerType("Bluetooth Speakers")}}
//                     />} label="Bluetooth Speakers" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setExtraFieldCopy1({ "Speaker Type":"Home Theatre Systems"})
//                 setSpeakerType("Home Theatre Systems")}}
//                     />} label="Home Theatre Systems" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({ "Speaker Type":"Outdoor Speakers"})
//                  setSpeakerType("Outdoor Speakers")}}
//                     />} label="Outdoor Speakers" />
//                     <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({ "Speaker Type":"Over - Ear Head Phones"})
//                  setSpeakerType("Over - Ear Head Phones")}}
//                     />} label="Over - Ear Head Phones" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setExtraFieldCopy1({ "Speaker Type":"Sound Bar Speakers"})
//                  setSpeakerType("Sound Bar Speakers")}}
//                     />} label="Sound Bar Speakers" />

// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy2({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy2({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy2({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy2({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",

//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//       </div>

//     )
//    }

//    else if(subCategory == 'Refrigerators')
//    {
//     return(
//       <div>

// <FormControl fullWidth>
// <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//   <Select
//     labelId="demo-simple-select-label"
//     id="demo-simple-select"
//     value={brand}
//     label="Brand"
//     onChange={(e)=> {  
//       var brand1 = e.target.value;
//       setBrand(brand1);
//       setExtraFieldCopy({ "Brand Name" : ` ${brand1}` })}}
//   >
//     <MenuItem value="Bosch">Bosch</MenuItem>
//     <MenuItem value="Buyerzone">Buyerzone</MenuItem>
//     <MenuItem value="DPex">D Pex</MenuItem>
//     <MenuItem value="Intex">Intex</MenuItem>
//     <MenuItem value="LG">LG</MenuItem>
//     <MenuItem value="Onida">Onida</MenuItem>
//      <MenuItem value="Panasonic">Panasonic</MenuItem>
//      <MenuItem value="Sony">Sony</MenuItem>
//   </Select>
// </FormControl>
//         <InputLabel id="demo-simple-select-label2">Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setType("Freezer On Top");
//                setExtraFieldCopy1({ "Type" : "Freezer On Top" })}}/>} label="Freezer On Top" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setType("Side By side");
//                  setExtraFieldCopy1({ "Type" : "Side By side" })}}
//                     />} label="Side By side" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setType("Single Door");
//                  setExtraFieldCopy1({ "Type" : "Single Door" })}}
//                     />} label="Single Door" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setType("Triple Door");
//                  setExtraFieldCopy1({ "Type" : "Triple Door" })}}
//                     />} label="Triple Door" />
//                     <FormControlLabel required control={<Checkbox onClick={() => {
//                  setType("Multi Door");
//                  setExtraFieldCopy1({ "Type" : "Multi Door"})}}
//                     />} label="Multi Door" />

// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy2({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy2({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy2({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy2({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />



// <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={(e)=>{
//   setCapacity(120);
//   setExtraFieldCopy3({ "Capacity"  : 120})
// }}/>} label="Upto 120L" />
// <FormControlLabel required control={<Checkbox onClick={(e)=>{
//   setCapacity(120);
//   setExtraFieldCopy3({ "Capacity"  : 120})
// }}/>} label="120-200L" />
// <FormControlLabel required control={<Checkbox onClick={(e)=>{
//   setCapacity(200);
//   setExtraFieldCopy3({ "Capacity"  : 200})
// }} />} label="200-300L" />
// <FormControlLabel required control={<Checkbox onClick={(e)=>{
//   setCapacity(300);
//   setExtraFieldCopy3({ "Capacity"  : 300})
// }} />} label="300-400L" />
//  <FormControlLabel required control={<Checkbox onClick={(e)=>{
//   setCapacity(400);
//   setExtraFieldCopy3({ "Capacity"  : 400})
// }} />} label="400L & Above" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",

//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />
// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />


//         </div>
//     )
//    }

//    else if(subCategory == 'TV'){
//     return(
//       <div>
//         <FormControl fullWidth>
//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Brand"
//             onChange={(e)=>{
//           var brand1 = e.target.value;
//           setBrand(brand1);
//           setExtraFieldCopy({ "Brand Name": ` ${brand1}`})
//             } 
//             }
//           >
//             <MenuItem value="BPL">BPL</MenuItem>
//             <MenuItem value="BenQ">BenQ</MenuItem>
//             <MenuItem value="LG">LG</MenuItem>
//             <MenuItem value="Onida">Onida</MenuItem>
//             <MenuItem value="Panasonic">Panasonic</MenuItem>
//             <MenuItem value="Phillips">Phillips</MenuItem>
//             <MenuItem value="Samsung">Samsung</MenuItem>
//             <MenuItem value="Sony">Sony</MenuItem>
//           </Select>
//         </FormControl>

//           <InputLabel id="demo-simple-select-label2">Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setType("TV");
//   setExtraFieldCopy1({ "Type":"TV"})
// }}/>} label="TV" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setType("DVD Player");
//   setExtraFieldCopy1({ "Type":"DVD Player"})
// }} />} label="DVD Player" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setType("Other");
//   setExtraFieldCopy1({ "Type":"Other"})
// }} />} label="Other" />

// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy2({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy2({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy2({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy2({ "Condition" : "Heavily Used" })}}
//                  />} label="Unboxed" />



//         <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(23);
//   setExtraFieldCopy3({"Capacity":23})
// }}/>} label="Upto 23" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(24);
//   setExtraFieldCopy3({"Capacity":24})
// }}/>} label="24-31" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(32);
//   setExtraFieldCopy3({"Capacity":32})
// }}/>} label="32-39" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(40);
//   setExtraFieldCopy3({"Capacity":40})
// }}/>} label="40-47" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(48);
//   setExtraFieldCopy3({"Capacity":48})
// }} />} label="48-54" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(55);
//   setExtraFieldCopy3({"Capacity":55})
// }}/>} label="55 & Above" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//         </div>
//     )
//    }
//    else if(subCategory == 'Video Games'){
//     return(
//       <div>

// <FormControl fullWidth>
//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Brand"
//             onChange={(e)=>{
//               var brand1 = e.target.value;
//               setBrand(brand1);
//               setExtraFieldCopy({ "Brand Name":` ${brand1}`})
//             }}
//           >
//             <MenuItem value="Nintendo wii">Nintendo wii</MenuItem>
//             <MenuItem value="SonyPSP">SonyPSP</MenuItem>
//             <MenuItem value="Others">Others</MenuItem>
//             <MenuItem value="Microsoft Xbox">Microsoft Xbox</MenuItem>
//             <MenuItem value="Nintendo Game Boy">Nintendo Game Boy</MenuItem>
//             <MenuItem value="Nokia N-gage">Nokia N-gage</MenuItem>
//             <MenuItem value="PC games">PC games</MenuItem>
//             <MenuItem value="Sony Play Station">Sony Play Station</MenuItem>
//           </Select>
//         </FormControl>

//         <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//         </div>
//     )
//    }
//    else if(subCategory == 'Washing Machine')  {
//     return(<div>
//       <FormControl fullWidth>

//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Age"
//             onChange={(e)=> {  
//               var brand1=e.target.value;
//               setBrand(brand1);
//               setExtraFieldCopy({ "Brand Name" : ` ${brand1}` })}}
//           >
//             <MenuItem value="Bosch">Bosch</MenuItem>
//             <MenuItem value="Buyerzone">Buyerzone</MenuItem>
//             <MenuItem value="DPex">D Pex</MenuItem>
//             <MenuItem value="Intex">Intex</MenuItem>
//             <MenuItem value="LG">LG</MenuItem>
//             <MenuItem value="Onida">Onida</MenuItem>
//              <MenuItem value="Panasonic">Panasonic</MenuItem>
//              <MenuItem value="Sony">Sony</MenuItem>
//           </Select>
//         </FormControl>


// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("New");
//                setExtraFieldCopy1({ "Condition" : "New" })}}/>} label="New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Good");
//                  setExtraFieldCopy1({ "Condition" : "Good" })}}
//                     />} label="Good" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Fair");
//                  setExtraFieldCopy1({ "Condition" : "Fair" })}}
//                     />} label="Fair" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(4);
//   setExtraFieldCopy2({"capacity":4})
// }}/>} label="4 to 6 kg" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(6);
//   setExtraFieldCopy2({"capacity":6})
// }} />} label="6 to 8kg" />
// <FormControlLabel required control={<Checkbox  onClick={()=>{
//   setCapacity(8);
//   setExtraFieldCopy2({"capacity":8})
// }} />} label="8 to 10kg" />
// <FormControlLabel required control={<Checkbox  onClick={()=>{
//   setCapacity(10);
//   setExtraFieldCopy2({"capacity":10})
// }}/>} label="10 to 12 kg" />
// <FormControlLabel required control={<Checkbox  onClick={()=>{
//   setCapacity(12);
//   setExtraFieldCopy2({"capacity":12})
// }} />} label="12 to 16 kg" />
// <FormControlLabel required control={<Checkbox  onClick={()=>{
//   setCapacity(16);
//   setExtraFieldCopy2({"capacity":16})
// }}/>} label="16 to 20 kg" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(20);
//   setExtraFieldCopy2({"capacity":20})
// }}/>} label="20 to 24 kg" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(24);
//   setExtraFieldCopy2({"capacity":24})
// }}/>} label="Above 24 kg" />

// <InputLabel id="demo-simple-select-label2">Load Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setType("Front Load");
//   setExtraFieldCopy3({"type": "Front Load"})
// }}/>} label="Front Load" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setType("Top Load");
//   setExtraFieldCopy3({"type": "Top Load"})
// }}/>} label="Top Load" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                   minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//     </div>
//     )
//    }
//     else if(subCategory == 'Water Heaters'){
//       return(<div>
//         <FormControl fullWidth>

//         <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           value={brand}
//           label="Age"
//           onChange={(e)=> {  
//             var brand1 = e.target.value;
//             setBrand(brand1);
//             setExtraFieldCopy({ "Brand Name" : ` ${brand1}` })}}
//         >
//           <MenuItem value="Apson">Apson</MenuItem>
//           <MenuItem value="Arise">Arise</MenuItem>
//           <MenuItem value="Bala">Bala</MenuItem>
//           <MenuItem value="Blue Me">Blue Me</MenuItem>
//           <MenuItem value="Havells">Havells</MenuItem>
//           <MenuItem value="LG">LG</MenuItem>
//            <MenuItem value="Onida">Onida</MenuItem>
//            <MenuItem value="Panasonic">Panasonic</MenuItem>
//         </Select>
//       </FormControl>

//       <InputLabel id="demo-simple-select-label2">Heater Type</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setType("Instant Water Heater");
//   setExtraFieldCopy1({"type":"Instant Water Heater" })
// }}/>} label="Instant Water Heater" />
// <FormControlLabel required control={<Checkbox   onClick={()=>{
//   setType("Storage Water Heater");
//   setExtraFieldCopy1({"type":"Storage Water Heater" })
// }}/>} label="Storage Water Heater" />


// <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("New");
//                setExtraFieldCopy2({ "Condition" : "New" })}}/>} label="New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Good");
//                  setExtraFieldCopy2({ "Condition" : "Good" })}}
//                     />} label="Good" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Fair");
//                  setExtraFieldCopy2({ "Condition" : "Fair" })}}
//                     />} label="Fair" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy2({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(11);
//   setExtraFieldCopy3({"capacity":11})
// }}/>} label="11L-15L" />
// <FormControlLabel required control={<Checkbox  onClick={()=>{
//   setCapacity(16);
//   setExtraFieldCopy3({"capacity":16})
// }}/>} label="16L-20L" />
// <FormControlLabel required control={<Checkbox   onClick={()=>{
//   setCapacity(21);
//   setExtraFieldCopy3({"capacity":21})
// }}/>} label="21L-50L" />
// <FormControlLabel required control={<Checkbox  onClick={()=>{
//   setCapacity(50);
//   setExtraFieldCopy3({"capacity":50})
// }}/>} label="50L-100L" />
// <FormControlLabel required control={<Checkbox onClick={()=>{
//   setCapacity(100);
//   setExtraFieldCopy3({"capacity":100})
// }} />} label="100L & Above" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"20000",
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//    </div>
//       )
//     }


//         else if(subCategory == 'Water Purifiers'){
//             return(  <div >

// <FormControl fullWidth>

//           <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={brand}
//             label="Age"
//             onChange={(e)=> {  
//               var brand1= e.target.value;
//               setBrand(brand1);
//               setExtraFieldCopy({ "Brand Name" : ` ${brand1}` })}}
//           >
//             <MenuItem value="Aquaguard">Aquaguard</MenuItem>
//             <MenuItem value="Bala">Bala</MenuItem>
//             <MenuItem value="Blue Me">Blue Me</MenuItem>
//             <MenuItem value="Havells">Havells</MenuItem>
//             <MenuItem value="LG">LG</MenuItem>
//              <MenuItem value="Others">Others</MenuItem>
//              <MenuItem value="Panasonic">Panasonic</MenuItem>
//              <MenuItem value="Kent">Kent</MenuItem>
//           </Select>
//         </FormControl>

//         <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setCondition("Brand New");
//                setExtraFieldCopy1({ "Condition" : "Brand New" })}}/>} label="Brand New" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Gently Used");
//                  setExtraFieldCopy1({ "Condition" : "Gently Used" })}}
//                     />} label="Gently Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Heavily Used");
//                  setExtraFieldCopy1({ "Condition" : "Heavily Used" })}}
//                     />} label="Heavily Used" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setCondition("Unboxed");
//                  setExtraFieldCopy1({ "Condition" : "Unboxed" })}}
//                     />} label="Unboxed" />

// <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
// <FormControlLabel required control={<Checkbox onClick={() => {
//                setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice:"0",
//                 maxPrice: "20000",
//               })) }}/>} label="0-20000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                 setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:'20000',
//                   maxPrice: "40000",
//                 })) }}
//                     />} label="20000-40000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                  setsearchSending((prev) => ({
//                   ...prev,
//                   minPrice:"40000",
//                   maxPrice: "60000",
//                 })) }}
//                     />} label="40000-60000" />
// <FormControlLabel required control={<Checkbox onClick={() => {
//                   setsearchSending((prev) => ({
//                     ...prev,
//                     minPrice:"60000",
//                     maxPrice: "80000",
//                   })) }}
//                     />} label="60000-80000" />

// <InputLabel id="demo-simple-select-label2">City</InputLabel>
// <Select1 
// value={selectedOption}
// onChange={handleChanges}
//  options={cities}
// />
//         </div>




//             )
//         }

//       else {
//         return(
//             <div style={modalStyle} className="brandimg">
//             <p>1Ton</p>
//             <p>2.5Ton</p>
//             <p>5Ton</p>
//             <p>8Ton</p>
//             <p>8Ton & Above</p>
//             </div>
//         );
//       }

//   };

//   return (
//     <>
//       <div style={sliderStyle}>
//         {/* <Carousel autoplay>
//       {images.map((image, index) => (
//         <div key={index}>
//           <img src={image.url} alt={`Image ${index + 1}`} style={{ width: "100%" }} height={300}/>
//         </div>
//       ))}
//     </Carousel>  */}
//         <ElectronicsSlider/>
//       </div>
//       <div className="wholefil mt-0">
//         <div>
//           <div style={modalContainerStyle} className="bikefilcon">
//            <div>
//             <FormControl fullWidth>
//   <InputLabel id="demo-simple-select-label">SubCategory</InputLabel>
//   <Select
//     labelId="demo-simple-select-label"
//     id="demo-simple-select"
//     label="SubCategory"
//     value={subCategory}
//     onChange={handleChange2}
//   >
//     <MenuItem value="Air Conditioners - AC" >Air Conditioners - AC</MenuItem>
//     <MenuItem value="Air Coolers">Air Coolers</MenuItem>
//     <MenuItem value="Camera Accessories">Camera Accessories</MenuItem>
//     <MenuItem value="Cameras">Cameras</MenuItem>
//     <MenuItem value='Gas Stove'>Gas Stove</MenuItem>
//     <MenuItem value='Induction Cook Tops'>Induction Cook Tops</MenuItem>
//     <MenuItem value='Laptops Or (Desktops - Computers)'>Laptops Or (Desktops - Computers)</MenuItem>
//     <MenuItem value='Microwave Ovens'>Microwave Ovens</MenuItem>
//     <MenuItem value='Mixer - Grinder - Juicer'>Mixer - Grinder - Juicer</MenuItem>
//     <MenuItem value='Monitor Or Printers Or Scanners'>Monitor Or Printers Or Scanners</MenuItem>
//     <MenuItem value='Music Systems - Home Theatre'>Music Systems - Home Theatre</MenuItem>
//     <MenuItem value='Refrigerators'>Refrigerators</MenuItem>
//     <MenuItem value='TV'>TV</MenuItem>
//     <MenuItem value='Video Games'>Video Games</MenuItem>
//     <MenuItem value='Washing Machine'>Washing Machine</MenuItem>
//     <MenuItem value='Water Heaters'>Water Heaters</MenuItem>
//     <MenuItem value='Water Purifiers'>Water Purifiers</MenuItem>
//   </Select>
//             </FormControl>
//            </div>
//             {/* <div style={{ width: "90%",display:"flex",flexDirection:"row"}}>
//               <button
//                 onClick={() => setSelectedButton("button1")}
//                 className="button123"
//               >
//                 {subCategory=='Air Conditioners - AC' && 'Brand Name'}
//                 {subCategory=='Air Coolers' && 'Brand Name'}
//                 {subCategory =='Camera Accessories' && 'Accessories Type'}
//                 {subCategory =='Cameras' && 'Brand Name'}
//                 {subCategory =='Gas Stove' && 'Brand Name'}
//                 {subCategory =='Induction Cook Tops' && 'Brand Name'}
//                 {subCategory =='Laptops Or (Desktops - Computers)' && 'Brand Name'}
//                 {subCategory =='Microwave Ovens' && 'Brand Name'}
//                 {subCategory =='Mixer - Grinder - Juicer' && 'Brand Name'}
//                 {subCategory =='Monitor Or Printers Or Scanners' && 'Peripheral Type'}
//                 {subCategory =='Music Systems - Home Theatre' && 'Brand Name'}
//                 {subCategory =='Refrigerators' && 'Door Style'}
//                 {subCategory =='TV' && 'Product Type'}
//                 {subCategory =='Video Games' && 'Brand Name'}
//                 {subCategory =='Washing Machine' && 'Brand Name'}
//                 {subCategory =='Water Heaters' && 'Brand Name'}
//                 {subCategory =='Water Purifiers' && 'Brand Name'}
//               </button>{" "}
//               &nbsp;&nbsp;&nbsp;
//               <button
//                 onClick={() => setSelectedButton("button2")}
//                 className="button123"
//               >
//                 {subCategory=='Air Conditioners - AC' && 'AC Type'}
//                 {subCategory=='Air Coolers' && 'Condition'}
//                 {subCategory =='Camera Accessories' && 'Condition'}
//                 {subCategory =='Cameras' && 'Product Type'}
//                 {subCategory =='Gas Stove' && 'Condition'}
//                 {subCategory =='Induction Cook Tops' && 'Condition'}
//                 {subCategory =='Laptops Or (Desktops - Computers)' && 'Product Type'}
//                 {subCategory =='Microwave Ovens' && 'Oven Type'}
//                 {subCategory =='Mixer - Grinder - Juicer' && 'Condition'}
//                 {subCategory =='Monitor Or Printers Or Scanners' && 'Condition'}
//                 {subCategory =='Music Systems - Home Theatre' && 'Speaker Type'}
//                 {subCategory =='Refrigerators' && 'Condition'}
//                 {subCategory =='TV' && 'Condition'}
//                 {subCategory =='Video Games' && 'Condition'}
//                 {subCategory =='Washing Machine' && 'Capacity'}
//                 {subCategory =='Water Heaters' && 'Heater Type'}
//                 {subCategory =='Water Purifiers' && 'Condition'}
//               </button>{" "}
//               &nbsp;&nbsp;&nbsp;
//               {subCategory != "Camera Accessories" && subCategory != 'Water Purifiers' && subCategory != "Video Games" && subCategory != "Monitor Or Printers Or Scanners" && subCategory !='Mixer - Grinder - Juicer' && subCategory != "Induction Cook Tops" && subCategory != "Gas Stove" && <button
//                 onClick={() => setSelectedButton("button3")}
//                 className="button123"
//               >
//                {subCategory=='Air Conditioners - AC' && 'Condition'}
//                {subCategory =='Air Coolers' && 'Cooler Capacity'}
//                {subCategory =='Cameras' && 'Condition'}
//                {subCategory =='Laptops Or (Desktops - Computers)' && 'Condition'}
//                {subCategory =='Microwave Ovens' && 'Condition'}
//                {subCategory =='Music Systems - Home Theatre' && 'Condition'}
//                {subCategory =='Refrigerators' && 'Brand Name'}
//                {subCategory =='TV' && 'Brand Name'}
//                {subCategory =='Washing Machine' && 'Condition'}
//                {subCategory =='Water Heaters' && 'Condition'}
//               </button>}{" "}
//               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//               {subCategory != 'Air Coolers' && subCategory != 'Water Purifiers' && subCategory != "Video Games" && subCategory != "Music Systems - Home Theatre" && subCategory != "Monitor Or Printers Or Scanners" && subCategory !='Mixer - Grinder - Juicer' && subCategory != 'Laptops Or (Desktops - Computers)' && subCategory != "Induction Cook Tops" && subCategory != "Gas Stove" && subCategory != "Camera Accessories" && subCategory != "Cameras" && <button
//                 onClick={() => setSelectedButton("button4")}
//                 className="button123"
//               >
//                  {subCategory=='Air Conditioners - AC' && 'Tonnage'}
//                  {subCategory =='Microwave Ovens' && 'Capacity'}
//                  {subCategory =='Refrigerators' && 'Capacity'}
//                  {subCategory =='TV' && 'Screen Size'}
//                  {subCategory =='Washing Machine' && 'Loading'}
//                  {subCategory =='Water Heaters' && 'Heater Capacity'}
//               </button>}
//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   debugger;
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["subCategoryValue"] = subCategory;
//                   searchSending["extraField"] = {...extraFieldCopy , ...extraFieldCopy1, ...extraFieldCopy2, ...extraFieldCopy3}; // Object Assign to searchSending object

//                   dispatch(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <button
//                   className="btn houzez-search-button elementor-button elementor-size-sm"
//                   style={{
//                     background: "#a71616bf",
//                     color: "white",
//                   }}
//                   id="serbtn"
//                 >
//                   Search
//                 </button>
//               </Link>
//             </div> */}
//             <br />

//             <Modal
//   open={open}
//   onClose={handleClose}
//   aria-labelledby="modal-modal-title"
//   aria-describedby="modal-modal-description"
// >
//   <Box sx={style}>
//     <Typography id="modal-modal-title" variant="h6" component="h2">
//       {subCategory}
//     </Typography>
//     <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//     <div style={{ width: "90%",display:"flex",flexDirection:"row"}}>

//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   debugger;
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["subCategoryValue"] = subCategory;
//                   searchSending["City"] = selectedOption.value;
//                   searchSending["extraField"] = {...extraFieldCopy , ...extraFieldCopy1, ...extraFieldCopy2, ...extraFieldCopy3}; // Object Assign to searchSending object

//                   dispatch(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <button
//                   className="btn houzez-search-button elementor-button elementor-size-sm"
//                   style={{
//                     background: "#a71616bf",
//                     color: "white",
//                   }}
//                   id="serbtn"
//                 >
//                   Search
//                 </button>
//               </Link>
//             </div>
//             {selectedButton && renderModalContent()}
//     </Typography>
//   </Box>
// </Modal>
//           </div>
//         </div>
//         {/* bike cat head end */}
//         {/* search end */}
//         {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
//           <div>
//             <h5>
//               {" "}
//               <div className="example">
//                 <Spin />
//               </div>{" "}
//             </h5>{" "}
//           </div>
//         )}
//         {!loading && ( 
//           <>
//           <div className="mb-3" style={isMobile ? mobstyle : deskstyle}>
//             <Feature value="Electronics" />{" "}
//             {/* Rendering the 'Feature' component with value "RealEstate" */}

//               <Owldemo1 greeting={greeting} subcategory={"TV"} />{" "}
//               {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "PG-Hostel" */}
//               <Owldemo1
//                 greeting={greeting}
//                 subcategory={"Gas Stove"}
//               />{" "}
//               <Owldemo1 greeting={greeting} subcategory={"Camera Accessories"}/>{" "}
//               <Owldemo1 greeting={greeting} subcategory={"Microwave Ovens"}/>{" "}
//               <Owldemo1 greeting={greeting} subcategory={"Induction Cook Top"}/>{" "}
//               <Owldemo1 greeting={greeting} subcategory={"Water Purifiers"}/>{" "} 
//               <Owldemo1 greeting={greeting} subcategory={"Air Coolers"}/>{" "}
//               {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
//           </div>
//           <div style={isMobile? mobi: deski}>
//       <TrendingAds/>
//       </div></>
//         )}
//         <NewsArticles category={"Bikes"} />{" "}
//         {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
//         <MyGallery />

//       </div>
//       {/* <NewsArticles category={"RealEstate"}/> */}
//     </>
//   );
// };

// export default Electronicscat;
































import React, { useEffect, useState } from "react";
// import FeaturedAds from '../../../Ads/FeaturedAds/featuredAds';
import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import CategoryUiSection from "../CategoryUiScetion/CategoryUiSection"; // Importing the 'CategoryUiSection' component
import SearchBox from "../searchBox/SearchBox"; // Importing the 'SearchBox' component
import RealEstateSection from "../section/realEstateSection"; // Importing the 'RealEstateSection' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Citys, location1 } from "../../../Location";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Select1 from 'react-select';
import { SearchOutlined } from "@ant-design/icons";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { useContext } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import apple from '../../../images/apple.png';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import blackberry from '../../../images/blackberry.png';
import blueberry from '../../../images/blueberry.jpeg';
import centric from '../../../images/centric.jpeg';
import creo from '../../../images/creo.png';
import htc from '../../../images/htc.png';
import lava from '../../../images/lava.png';
import redmi from '../../../images/Redmi.png';
import {
  All,
  subcategoryRealEstateBuy, // Imported from "../../../env"
  subcategoryRealEstateRent, // Imported from "../../../env"
  subcategoryType1, // Imported from "../../../env"
} from "../../../env";
import { stateMain, locationData } from "../../../Location";
import "../searchBox/SearchBox.css";
import { Link } from "react-router-dom";
import MobileSlider from "../../../../Shared/CategorySlider/MobileSlider";
import { Carousel } from "antd";
import { FitScreen } from "@mui/icons-material";
import "./allfilter.css";
import { isMobile } from "react-device-detect";
import ElectronicsSlider from "../../../../Shared/CategorySlider/ElectronicsSlider";
import { categoryModel } from "../../../../Model/categoryCons";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import { setPersistence } from "firebase/auth";
import Allcatads from "../../../Allcatads";
import '../Allfilter/Furniturecat.css'
// import CloseIcon from "@mui/icons-material/Close";


const mobstyle = {
  width: "auto",
  marginLeft: "16px",
  marginRight: "16px",
};
const deskstyle = {
  padding: "20px",
  marginBottom: "-10px",
  // marginRight: "10rem",
  // marginLeft: "10rem",
};
const mobi = {



}
const deski = {
  marginLeft: "7rem", marginRight: "7rem"
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Electronicscat = () => {


  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => {
    setPopupOpen(true);
  };


  const [selectedFilter, setSelectedFilter] = useState(null);
  // const [selectedFilter1, setSelectedFilter1] = useState(null);
  const [visible1, setVisible1] = useState(false);
  const handleDropdownVisibleChange = () => {
    setVisible1(true);
  };
  const [selectedOption, setSelectedOption] = useState({});
  const [SearchValue, setSearchValue] = useState("");
  const [brand, setBrand] = useState(null);
  const [city, setCity] = useState();
  const [acType, setAcType] = useState(null);
  const [condition, setCondition] = useState(null);
  const [capacity, setCapacity] = useState(null);
  const [productType, setProductType] = useState(null);
  const [ovenType, setOvenType] = useState(null);
  const [speakerType, setSpeakerType] = useState(null);
  const [doorStyle, setDoorStyle] = useState(null);
  const [type, setType] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [budget, setBudget] = useState(null);

  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [extraFieldCopy1, setExtraFieldCopy1] = useState({});
  const [extraFieldCopy2, setExtraFieldCopy2] = useState({});
  const [extraFieldCopy3, setExtraFieldCopy3] = useState({});
  const [extraFieldCopy4, setExtraFieldCopy4] = useState("");

  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };
  const handleFilterButtonClick = (filterType) => {
    setSelectedFilter(filterType);
  };
  const greeting = "Electronics";


  let cities = [];

  Citys.map((value) => {
    return cities.push({ label: value, value: value });
  });




  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch(); // Accessing the Redux dispatch function
  const dispatch = useDispatch();

  const [subCategory, setSubCategory] = useState();

  const handleChange2 = (e) => {
    setSubCategory(e.target.value);
    setOpen(true);
  };





  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    disptach(add({ view: ["Bikescat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);

  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    searchValue: null,
    category: "Electronics",
    minPrice: null,
    maxPrice: null,
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  const handleChanges = (selectedOption) => {
    console.log('hi Guys', selectedOption.value);
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  const sliderStyle = {
    width: "100%",
    // marginTop:"2cm",
    // maxWidth: "100vw",
  };
  const [selectedButton, setSelectedButton] = useState("button1", "");

  // Step 2: Create CSS classes to control modal positioning
  const modalContainerStyle = {

    top: "-50%", // Adjust this value to control the distance from the button

    // width:"100%"
  };

  const modalStyle = {
    // position: "relative",

    // display:"flex",
    // width:"100%",
    top: "-50%", // Adjust this value to control the distance from the button
    left: 0,
    zIndex: 10, // Ensure the modal is above other elements
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const renderModalContent = () => {
    if
      (subCategory == "Air Conditioners - AC") {
      return (
        <div>
          <FormControl fullWidth >

            <InputLabel id="demo-simple-select-label" style={{ fontSize: "14px" }}>Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {

                var brand1 = e.target.value;
                setBrand(brand1)
                setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
              }}
            >
              <MenuItem value="Bosch">Bosch</MenuItem>
              <MenuItem value="Buyerzone">Buyerzone</MenuItem>
              <MenuItem value="DPex">D Pex</MenuItem>
              <MenuItem value="Intex">Intex</MenuItem>
              <MenuItem value="LG">LG</MenuItem>
              <MenuItem value="Onida">Onida</MenuItem>
              <MenuItem value="Panasonic">Panasonic</MenuItem>
              <MenuItem value="Sony">Sony</MenuItem>
            </Select>
          </FormControl>
          <InputLabel style={{marginTop:'10px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
            className="centered-dropdown"
            styles={{
              position: "fixed",
              left: "150%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <InputLabel   style={{marginTop:'15px',marginBottom:"0px"}} id="demo-simple-select-label2">AC Type</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setAcType(20000);
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="Window" />
          <FormControlLabel required control={<Checkbox onClick={() => {

            setAcType(50000);
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "50000",
            }));
          }} />} label="Split" />

          <InputLabel style={{marginTop:'15px',marginBottom:"0px"}}   id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy1({ "Condition": " Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy1({ "Condition": " Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy1({ "Condition": " Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy1({ "Condition": " Unboxed" })
          }}
          />} label="Unboxed" />

          {/* <InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select1 
value={selectedOption}
onChange={handleChanges}
 options={cities}
/> */}


          {/* <InputLabel id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
            className="centered-dropdown"
            styles={{
              position: "fixed",
              left: "150%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          /> */}




        </div>)

    }
    else if (subCategory == 'Air Coolers') {
      return (<div>
        <FormControl fullWidth>

          <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Brand"
            onChange={(e) => {
              var brand1 = e.target.value;
              setBrand(brand1);
              setExtraFieldCopy({ "Brand Name": brand1 })
            }}
          >
            <MenuItem value="BMQ">BMQ</MenuItem>
            <MenuItem value=" Bajaj">Bajaj</MenuItem>
            <MenuItem value=" Blue Star">Blue Star</MenuItem>
            <MenuItem value=" Cello">Cello</MenuItem>
            <MenuItem value="Hindware">Hindware</MenuItem>
            <MenuItem value=" Hotstar">Hotstar</MenuItem>
            <MenuItem value="kelvinator">kelvinator</MenuItem>
            <MenuItem value="Havells">Havells</MenuItem>
          </Select>
        </FormControl>


        <InputLabel style={{marginTop:'5px',marginBottom:"5px"}} id="demo-simple-select-label2">City</InputLabel>
        <Select1
          value={selectedOption}
          onChange={handleChanges}
          options={cities}
        /> 

        <InputLabel  style={{marginTop:'5px',marginBottom:"-10px"}} id="demo-simple-select-label2">Condition</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Brand New");
          setExtraFieldCopy1({ "Condition": " Brand New" })
        }} />} label="Brand New" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Gently Used");
          setExtraFieldCopy1({ "Condition": " Gently Used" })
        }}
        />} label="Gently Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Heavily Used");
          setExtraFieldCopy1({ "Condition": " Heavily Used" })
        }}
        />} label="Heavily Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Unboxed");
          setExtraFieldCopy1({ "Condition": " Unboxed" })
        }}
        />} label="Unboxed" />

        <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(11);
          setExtraFieldCopy2({ "Capacity": 11 })
        }} />} label="11L-15L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(16);
          setExtraFieldCopy2({ "Capacity": 16 })
        }}
        />} label="16L-20L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(21);
          setExtraFieldCopy2({ "Capacity": 21 })
        }}
        />} label="21L-40L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(40);
          setExtraFieldCopy2({ "Condition": 40 })
        }}
        />} label="40L & above" />



      </div>)
    }
    else if (subCategory == 'Camera Accessories') {
      return (<div>
        <FormControl fullWidth>

          <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Brand"
            onChange={(e) => {
              var brand1 = e.target.value;
              setBrand(brand1);
              setExtraFieldCopy({ "Accessories Type": brand1 })
            }}
          >
            <MenuItem value="Camera Bag">Camera Bag</MenuItem>
            <MenuItem value="Filter">Filter</MenuItem>
            <MenuItem value="Flash">Flash</MenuItem>
            <MenuItem value="Lens Cap">Lens Cap</MenuItem>
            <MenuItem value="Lens Cleaner">Lens Cleaner</MenuItem>
            <MenuItem value="Memory Card">Memory Card</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
            <MenuItem value="Strap">Strap</MenuItem>
          </Select>
        </FormControl>


        <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
        <Select1
          value={selectedOption}
          onChange={handleChanges}
          options={cities}
        />



        <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Condition</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Brand New");
          setExtraFieldCopy1({ "Condition": " Brand New" })
        }} />} label="Brand New" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Gently Used");
          setExtraFieldCopy1({ "Condition": " Gently Used" })
        }}
        />} label="Gently Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Heavily Used");
          setExtraFieldCopy1({ "Condition": " Heavily Used" })
        }}
        />} label="Heavily Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Unboxed");
          setExtraFieldCopy1({ "Condition": " Unboxed" })
        }}
        />} label="Unboxed" />


        <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {

          setsearchSending((prev) => ({
            ...prev,
            minPrice: "0",
            maxPrice: "20000",

          }))
          setBudget("20000")
        }} />} label="0-20000" />
        <FormControlLabel required control={<Checkbox onClick={() => {

          setsearchSending((prev) => ({
            ...prev,
            minPrice: "20000",
            maxPrice: "40000",
          }))
          setBudget("40000")
        }}

        />} label="20000-40000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "40000",
            maxPrice: "60000",
          }))
          setBudget("60000")
        }}
        />} label="40000-60000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "60000",
            maxPrice: "80000",
          }))
          setBudget("80000")
        }}
        />} label="60000-80000" />



      </div>)
    }
    else if (subCategory == 'Cameras') {
      return (<div>
        <FormControl fullWidth>

          <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Brand"
            onChange={(e) => {
              var brand1 = e.target.value;
              setBrand(brand1);
              setExtraFieldCopy({ "Brand Name": brand1 })
            }}
          >
            <MenuItem value="Casio">Casio</MenuItem>
            <MenuItem value="Flip">Flip</MenuItem>
            <MenuItem value="Kodak">Kodak</MenuItem>
            <MenuItem value="Olympus">Olympus</MenuItem>
            <MenuItem value="Panasonic">Panasonic</MenuItem>
            <MenuItem value="Canon">Canon</MenuItem>
            <MenuItem value="Leica">Leica</MenuItem>
            <MenuItem value="Nikon">Nikon</MenuItem>
          </Select>
        </FormControl>


        <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
        <Select1
          value={selectedOption}
          onChange={handleChanges}
          options={cities}
        />


        <InputLabel  style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Product Type</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy1({ "Product Type": " Camcorders" })
          setProductType("Camcoders")
        }} />} label="Camcoders" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy1({ "Product Type": "Binocular" })
          setProductType("Binocular")
        }}
        />} label="Binocular" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy1({ "Product Type": "DigitalSLR" })
          setProductType("DigitalSLR")
        }}
        />} label="DigitalSLR" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy1({ "Product Type": "Film Cameras" })
          setProductType("Film Cameras")
        }}
        />} label="Film Cameras" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy1({ "Product Type": "Telescope" })
          setProductType("Telescope")
        }}
        />} label="Telescope" />


        <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Brand New");
          setExtraFieldCopy2({ "Condition": " Brand New" })
        }} />} label="Brand New" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Gently Used");
          setExtraFieldCopy2({ "Condition": " Gently Used" })
        }}
        />} label="Gently Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Heavily Used");
          setExtraFieldCopy2({ "Condition": " Heavily Used" })
        }}
        />} label="Heavily Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Unboxed");
          setExtraFieldCopy2({ "Condition": " Unboxed" })
        }}
        />} label="Unboxed" />

        <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "0",
            maxPrice: "20000",
          }))
        }} />} label="20000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "20000",
            maxPrice: "40000",
          }))
        }}
        />} label="20000-40000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "40000",
            maxPrice: "60000",
          }))
        }}
        />} label="40000-60000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "60000",
            maxPrice: "80000",
          }))
        }}
        />} label="60000-80000" />


 


      </div>
      )
    }


    else if (subCategory == 'Gas Stove') {
      return (
        <div>
          <FormControl fullWidth>

            <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                var brand1 = e.target.value;
                setBrand(brand1);
                setExtraFieldCopy({ "Brand Name": brand1 })
              }}
            >
              <MenuItem value=" Apex">Apex</MenuItem>
              <MenuItem value=" Longer">Longer</MenuItem>
              <MenuItem value=" Kodak">Kodak</MenuItem>
              <MenuItem value=" Orbit">Orbit</MenuItem>
              <MenuItem value=" Oster">Oster</MenuItem>
              <MenuItem value=" Others">Others</MenuItem>
              <MenuItem value=" Ovastar">Ovastar</MenuItem>
              <MenuItem value="Phillips">Phillips</MenuItem>
            </Select>
          </FormControl>


          <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
          />

          <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy1({ "Condition": " Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy1({ "Condition": " Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy1({ "Condition": " Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy1({ "Condition": " Unboxed" })
          }}
          />} label="Unboxed" />

          <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="20000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "20000",
              maxPrice: "40000",
            }))
          }}
          />} label="20000-40000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "40000",
              maxPrice: "60000",
            }))
          }}
          />} label="40000-60000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "60000",
              maxPrice: "80000",
            }))
          }}
          />} label="60000-80000" />

     

        </div>
      )
    }

    else if (subCategory == 'Induction Cook Tops') {
      return (<div>
        <FormControl fullWidth>

          <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Brand"
            onChange={(e) => {
              var brand1 = e.target.value;
              setBrand(brand1);
              setExtraFieldCopy({ "Brand Name": brand1 })
            }}
          >
            <MenuItem value="Greenchef">Greenchef</MenuItem>
            <MenuItem value="Havells">Havells</MenuItem>
            <MenuItem value="Hilex">Hilex</MenuItem>
            <MenuItem value="Hindware">Hindware</MenuItem>
            <MenuItem value="Hotstar">Hotstar</MenuItem>
            <MenuItem value="Impex">Impex</MenuItem>
            <MenuItem value="Jaipan">Jaipan</MenuItem>
            <MenuItem value="Jindal">Jindal</MenuItem>
          </Select>
        </FormControl>


        <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
        <Select1
          value={selectedOption}
          onChange={handleChanges}
          options={cities}
        />


        <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Condition</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Brand New");
          setExtraFieldCopy1({ "Condition": "Brand New" })
        }} />} label="Brand New" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Gently Used");
          setExtraFieldCopy1({ "Condition": "Gently Used" })
        }}
        />} label="Gently Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Heavily Used");
          setExtraFieldCopy1({ "Condition": "Heavily Used" })
        }}
        />} label="Heavily Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Unboxed");
          setExtraFieldCopy1({ "Condition": "Unboxed" })
        }}
        />} label="Unboxed" />



        <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "0",
            maxPrice: "20000",
          }))
        }} />} label="0-20000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "20000",
            maxPrice: "40000",
          }))
        }}
        />} label="20000-40000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "40000",
            maxPrice: "60000",
          }))
        }}
        />} label="40000-60000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "60000",
            maxPrice: "80000",
          }))
        }}
        />} label="60000-80000" />


      </div>)
    }
    else if (subCategory == 'Laptops Or (Desktops - Computers)') {
      return (
        <div>
          <FormControl fullWidth>

            <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                var brand1 = e.target.value;
                setBrand(brand1);
                setExtraFieldCopy({ "Brand Name": brand1 })
              }}
            >
              <MenuItem value="Hp">Hp</MenuItem>
              <MenuItem value="IBM">IBM</MenuItem>
              <MenuItem value="Lenova">Lenova</MenuItem>
              <MenuItem value="Samsung">Samsung</MenuItem>
              <MenuItem value="Wipro">Wipro</MenuItem>
              <MenuItem value="Zenith">Zenith</MenuItem>
              <MenuItem value="Asus">Asus</MenuItem>
              <MenuItem value="Dell">Dell</MenuItem>
            </Select>
          </FormControl>

          <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
          />

          <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Product Type</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setProductType("Desktop")
            setExtraFieldCopy1({ "Product Type": "Desktop" })
          }} />} label="Desktop" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setProductType("Laptop")
            setExtraFieldCopy1({ "Product Type": "Laptop" })
          }}
          />} label="Laptop" />

          <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy2({ "Condition": "Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy2({ "Condition": "Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy2({ "Condition": "Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy2({ "Condition": "Unboxed" })
          }}
          />} label="Unboxed" />

          <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="0-20000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "20000",
              maxPrice: "40000",
            }))
          }}
          />} label="20000-40000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "40000",
              maxPrice: "60000",
            }))
          }}
          />} label="40000-60000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "60000",
              maxPrice: "80000",
            }))
          }}
          />} label="60000-80000" />

  
        </div>
      )
    }
    else if (subCategory == 'Microwave Ovens') {
      return (<div>
        <FormControl fullWidth>

          <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Brand"
            onChange={(e) => {
              var brand1 = e.target.value;
              setBrand(brand1);
              setExtraFieldCopy({ "Brand Name": brand1 })
            }}
          >
            <MenuItem value="LG">LG</MenuItem>
            <MenuItem value="Onida">Onida</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
            <MenuItem value="Panasonic">Panasonic</MenuItem>
            <MenuItem value="Samsung">Samsung</MenuItem>
          </Select>
        </FormControl>



        <InputLabel  style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
        <Select1
          value={selectedOption}
          onChange={handleChanges}
          options={cities}
        />

        <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Oven Type</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy1({ "Oven Type": "Convection" })
          setOvenType("Convection")
        }} />} label="Convection" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy1({ "Oven Type": "Grill" })
          setOvenType("Grill")
        }}
        />} label="Grill" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy1({ "Oven Type": "Solo" })
          setOvenType("Solo")
        }}
        />} label="Solo" />

        <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Brand New");
          setExtraFieldCopy2({ "Condition": "Brand New" })
        }} />} label="Brand New" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Gently Used");
          setExtraFieldCopy2({ "Condition": "Gently Used" })
        }}
        />} label="Gently Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Heavily Used");
          setExtraFieldCopy2({ "Condition": "Heavily Used" })
        }}
        />} label="Heavily Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Unboxed");
          setExtraFieldCopy2({ "Condition": "Unboxed" })
        }}
        />} label="Unboxed" />

        <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy3({ Capacity: "Upto 20L" })
          setCapacity(20)
        }} />} label="Upto 20L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy3({ Capacity: "Upto 20L" })
          setCapacity(21)
        }}
        />} label="21L-25L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy3({ Capacity: "Upto 20L" })
          setCapacity(26)
        }}
        />} label="26L-30L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setExtraFieldCopy3({ Capacity: "Upto 20L" })
          setCapacity(31)
        }}
        />} label="31L & Above" />

        <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "0",
            maxPrice: "20000",
          }))
        }} />} label="0-20000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "20000",
            maxPrice: "40000",
          }))
        }}
        />} label="20000-40000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "40000",
            maxPrice: "60000",
          }))
        }}
        />} label="40000-60000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "60000",
            maxPrice: "80000",
          }))
        }}
        />} label="60000-80000" />

      </div>
      )

    }

    else if (subCategory == 'Mixer - Grinder - Juicer') {
      return (
        <div>
          <FormControl fullWidth>

            <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                var brand1 = e.target.value;
                setBrand(brand1);
                setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
              }}
            >
              <MenuItem value="Activa">Activa</MenuItem>
              <MenuItem value="Aditi">Aditi</MenuItem>
              <MenuItem value="Amazing Bullet">Amazing Bullet</MenuItem>
              <MenuItem value="Anjani">Anjani</MenuItem>
              <MenuItem value="Apex">Apex</MenuItem>
              <MenuItem value="Arise">Arise</MenuItem>
              <MenuItem value="Asian">Asian</MenuItem>
            </Select>
          </FormControl>


          <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
          />

          <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy1({ "Condition": "Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy1({ "Condition": "Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy1({ "Condition": "Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy1({ "Condition": "Unboxed" })
          }}
          />} label="Unboxed" />


          <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="0-20000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "20000",
              maxPrice: "40000",

            }))
          }}
          />} label="20000-40000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "40000",
              maxPrice: "60000",
            }))
          }}
          />} label="40000-60000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "60000",
              maxPrice: "80000",
            }))
          }}
          />} label="60000-80000" />

  
        </div>
      )
    }

    else if (subCategory == 'Monitor Or Printers Or Scanners') {
      return (
        <div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                setExtraFieldCopy({ "Peripheral Type": brand })
                setBrand(e.target.value)
              }}
            >
              <MenuItem value="Access Point">Access Point</MenuItem>
              <MenuItem value="Battery">Battery</MenuItem>
              <MenuItem value="CPu">CPU</MenuItem>
              <MenuItem value="Cooling Pad">Cooling Pad</MenuItem>
              <MenuItem value="Head Phones">Head Phones</MenuItem>
              <MenuItem value="Headset">Headset</MenuItem>
              <MenuItem value="Keyboard">Keyboard</MenuItem>
              <MenuItem value="Mouse">Mouse</MenuItem>
            </Select>
          </FormControl>


          <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
          />

          <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy1({ "Condition": "Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy1({ "Condition": "Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy1({ "Condition": "Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy1({ "Condition": "Unboxed" })
          }}
          />} label="Unboxed" />


          <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="0-20000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "20000",
              maxPrice: "40000",
            }))
          }}
          />} label="20000-40000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "40000",
              maxPrice: "60000",
            }))
          }}
          />} label="40000-60000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "60000",
              maxPrice: "80000",
            }))
          }}
          />} label="60000-80000" />

  
        </div>
      )
    }

    if (subCategory == 'Music Systems - Home Theatre') {
      return (

        <div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                var brand1 = e.target.value;
                setBrand(brand1)
                setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
              }}
            >
              <MenuItem value="Canon">Canon</MenuItem>
              <MenuItem value="Casio">Casio</MenuItem>
              <MenuItem value="Flip">Flip</MenuItem>
              <MenuItem value="Kodak">Kodak</MenuItem>
              <MenuItem value="Leica">Leica</MenuItem>
              <MenuItem value="Nikon">Nikon</MenuItem>
              <MenuItem value="Olympus">Olympus</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>



          <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
          />


          <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Speaker Type</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setExtraFieldCopy1({ "Speaker Type": "Blueshelf speakers" })
            setSpeakerType("Blueshelf Speakers")
          }} />} label="Blueshelf speakers" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setExtraFieldCopy1({ "Speaker Type": "Bluetooth speakers" })
            setSpeakerType("Bluetooth Speakers")
          }}
          />} label="Bluetooth Speakers" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setExtraFieldCopy1({ "Speaker Type": "Home Theatre Systems" })
            setSpeakerType("Home Theatre Systems")
          }}
          />} label="Home Theatre Systems" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setExtraFieldCopy1({ "Speaker Type": "Outdoor Speakers" })
            setSpeakerType("Outdoor Speakers")
          }}
          />} label="Outdoor Speakers" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setExtraFieldCopy1({ "Speaker Type": "Over - Ear Head Phones" })
            setSpeakerType("Over - Ear Head Phones")
          }}
          />} label="Over - Ear Head Phones" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setExtraFieldCopy1({ "Speaker Type": "Sound Bar Speakers" })
            setSpeakerType("Sound Bar Speakers")
          }}
          />} label="Sound Bar Speakers" />

          <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy2({ "Condition": "Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy2({ "Condition": "Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy2({ "Condition": "Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy2({ "Condition": "Unboxed" })
          }}
          />} label="Unboxed" />

          <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="0-20000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "20000",
              maxPrice: "40000",
            }))
          }}
          />} label="20000-40000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "40000",
              maxPrice: "60000",

            }))
          }}
          />} label="40000-60000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "60000",
              maxPrice: "80000",
            }))
          }}
          />} label="60000-80000" />

        </div>

      )
    }

    else if (subCategory == 'Refrigerators') {
      return (
        <div>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                var brand1 = e.target.value;
                setBrand(brand1);
                setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
              }}
            >
              <MenuItem value="Bosch">Bosch</MenuItem>
              <MenuItem value="Buyerzone">Buyerzone</MenuItem>
              <MenuItem value="DPex">D Pex</MenuItem>
              <MenuItem value="Intex">Intex</MenuItem>
              <MenuItem value="LG">LG</MenuItem>
              <MenuItem value="Onida">Onida</MenuItem>
              <MenuItem value="Panasonic">Panasonic</MenuItem>
              <MenuItem value="Sony">Sony</MenuItem>
            </Select>
          </FormControl>

          <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
          />


          <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Type</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setType("Freezer On Top");
            setExtraFieldCopy1({ "Type": "Freezer On Top" })
          }} />} label="Freezer On Top" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setType("Side By side");
            setExtraFieldCopy1({ "Type": "Side By side" })
          }}
          />} label="Side By side" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setType("Single Door");
            setExtraFieldCopy1({ "Type": "Single Door" })
          }}
          />} label="Single Door" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setType("Triple Door");
            setExtraFieldCopy1({ "Type": "Triple Door" })
          }}
          />} label="Triple Door" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setType("Multi Door");
            setExtraFieldCopy1({ "Type": "Multi Door" })
          }}
          />} label="Multi Door" />

          <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy2({ "Condition": "Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy2({ "Condition": "Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy2({ "Condition": "Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy2({ "Condition": "Unboxed" })
          }}
          />} label="Unboxed" />



          <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={(e) => {
            setCapacity(120);
            setExtraFieldCopy3({ "Capacity": 120 })
          }} />} label="Upto 120L" />
          <FormControlLabel required control={<Checkbox onClick={(e) => {
            setCapacity(120);
            setExtraFieldCopy3({ "Capacity": 120 })
          }} />} label="120-200L" />
          <FormControlLabel required control={<Checkbox onClick={(e) => {
            setCapacity(200);
            setExtraFieldCopy3({ "Capacity": 200 })
          }} />} label="200-300L" />
          <FormControlLabel required control={<Checkbox onClick={(e) => {
            setCapacity(300);
            setExtraFieldCopy3({ "Capacity": 300 })
          }} />} label="300-400L" />
          <FormControlLabel required control={<Checkbox onClick={(e) => {
            setCapacity(400);
            setExtraFieldCopy3({ "Capacity": 400 })
          }} />} label="400L & Above" />

          <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="0-20000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "20000",
              maxPrice: "40000",
            }))
          }}
          />} label="20000-40000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "40000",
              maxPrice: "60000",

            }))
          }}
          />} label="40000-60000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "60000",
              maxPrice: "80000",
            }))
          }}
          />} label="60000-80000" />



        </div>
      )
    }

    else if (subCategory == 'TV') {
      return (
        <div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                var brand1 = e.target.value;
                setBrand(brand1);
                setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
              }
              }
            >
              <MenuItem value="BPL">BPL</MenuItem>
              <MenuItem value="BenQ">BenQ</MenuItem>
              <MenuItem value="LG">LG</MenuItem>
              <MenuItem value="Onida">Onida</MenuItem>
              <MenuItem value="Panasonic">Panasonic</MenuItem>
              <MenuItem value="Phillips">Phillips</MenuItem>
              <MenuItem value="Samsung">Samsung</MenuItem>
              <MenuItem value="Sony">Sony</MenuItem>
            </Select>
          </FormControl>

          <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
          />


          <InputLabel  style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Type</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setType("TV");
            setExtraFieldCopy1({ "Type": "TV" })
          }} />} label="TV" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setType("DVD Player");
            setExtraFieldCopy1({ "Type": "DVD Player" })
          }} />} label="DVD Player" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setType("Other");
            setExtraFieldCopy1({ "Type": "Other" })
          }} />} label="Other" />

          <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy2({ "Condition": "Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy2({ "Condition": "Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy2({ "Condition": "Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy2({ "Condition": "Heavily Used" })
          }}
          />} label="Unboxed" />



          <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCapacity(23);
            setExtraFieldCopy3({ "Capacity": 23 })
          }} />} label="Upto 23" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCapacity(24);
            setExtraFieldCopy3({ "Capacity": 24 })
          }} />} label="24-31" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCapacity(32);
            setExtraFieldCopy3({ "Capacity": 32 })
          }} />} label="32-39" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCapacity(40);
            setExtraFieldCopy3({ "Capacity": 40 })
          }} />} label="40-47" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCapacity(48);
            setExtraFieldCopy3({ "Capacity": 48 })
          }} />} label="48-54" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCapacity(55);
            setExtraFieldCopy3({ "Capacity": 55 })
          }} />} label="55 & Above" />

          <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="0-20000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "20000",
              maxPrice: "40000",
            }))
          }}
          />} label="20000-40000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "40000",
              maxPrice: "60000",
            }))
          }}
          />} label="40000-60000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "60000",
              maxPrice: "80000",
            }))
          }}
          />} label="60000-80000" />

 
        </div>
      )
    }
    else if (subCategory == 'Video Games') {
      return (
        <div>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                var brand1 = e.target.value;
                setBrand(brand1);
                setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
              }}
            >
              <MenuItem value="Nintendo wii">Nintendo wii</MenuItem>
              <MenuItem value="SonyPSP">SonyPSP</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
              <MenuItem value="Microsoft Xbox">Microsoft Xbox</MenuItem>
              <MenuItem value="Nintendo Game Boy">Nintendo Game Boy</MenuItem>
              <MenuItem value="Nokia N-gage">Nokia N-gage</MenuItem>
              <MenuItem value="PC games">PC games</MenuItem>
              <MenuItem value="Sony Play Station">Sony Play Station</MenuItem>
            </Select>
          </FormControl>

          <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
          <Select1
            value={selectedOption}
            onChange={handleChanges}
            options={cities}
          />


          <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Condition</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Brand New");
            setExtraFieldCopy1({ "Condition": "Brand New" })
          }} />} label="Brand New" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Gently Used");
            setExtraFieldCopy1({ "Condition": "Gently Used" })
          }}
          />} label="Gently Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Heavily Used");
            setExtraFieldCopy1({ "Condition": "Heavily Used" })
          }}
          />} label="Heavily Used" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setCondition("Unboxed");
            setExtraFieldCopy1({ "Condition": "Unboxed" })
          }}
          />} label="Unboxed" />

          <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "0",
              maxPrice: "20000",
            }))
          }} />} label="0-20000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "20000",
              maxPrice: "40000",
            }))
          }}
          />} label="20000-40000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "40000",
              maxPrice: "60000",
            }))
          }}
          />} label="40000-60000" />
          <FormControlLabel required control={<Checkbox onClick={() => {
            setsearchSending((prev) => ({
              ...prev,
              minPrice: "60000",
              maxPrice: "80000",
            }))
          }}
          />} label="60000-80000" />

 
        </div>
      )
    }
    else if (subCategory == 'Washing Machine') {
      return (<div>
        <FormControl fullWidth>

          <InputLabel  id="demo-simple-select-label">Brand Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Age"
            onChange={(e) => {
              var brand1 = e.target.value;
              setBrand(brand1);
              setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
            }}
          >
            <MenuItem value="Bosch">Bosch</MenuItem>
            <MenuItem value="Buyerzone">Buyerzone</MenuItem>
            <MenuItem value="DPex">D Pex</MenuItem>
            <MenuItem value="Intex">Intex</MenuItem>
            <MenuItem value="LG">LG</MenuItem>
            <MenuItem value="Onida">Onida</MenuItem>
            <MenuItem value="Panasonic">Panasonic</MenuItem>
            <MenuItem value="Sony">Sony</MenuItem>
          </Select>
        </FormControl>

        <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
        <Select1
          value={selectedOption}
          onChange={handleChanges}
          options={cities}
        />

        <InputLabel  style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Condition</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("New");
          setExtraFieldCopy1({ "Condition": "New" })
        }} />} label="New" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Good");
          setExtraFieldCopy1({ "Condition": "Good" })
        }}
        />} label="Good" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Fair");
          setExtraFieldCopy1({ "Condition": "Fair" })
        }}
        />} label="Fair" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Unboxed");
          setExtraFieldCopy1({ "Condition": "Unboxed" })
        }}
        />} label="Unboxed" />

        <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(4);
          setExtraFieldCopy2({ "capacity": 4 })
        }} />} label="4 to 6 kg" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(6);
          setExtraFieldCopy2({ "capacity": 6 })
        }} />} label="6 to 8kg" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(8);
          setExtraFieldCopy2({ "capacity": 8 })
        }} />} label="8 to 10kg" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(10);
          setExtraFieldCopy2({ "capacity": 10 })
        }} />} label="10 to 12 kg" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(12);
          setExtraFieldCopy2({ "capacity": 12 })
        }} />} label="12 to 16 kg" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(16);
          setExtraFieldCopy2({ "capacity": 16 })
        }} />} label="16 to 20 kg" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(20);
          setExtraFieldCopy2({ "capacity": 20 })
        }} />} label="20 to 24 kg" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(24);
          setExtraFieldCopy2({ "capacity": 24 })
        }} />} label="Above 24 kg" />

        <InputLabel id="demo-simple-select-label2">Load Type</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setType("Front Load");
          setExtraFieldCopy3({ "type": "Front Load" })
        }} />} label="Front Load" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setType("Top Load");
          setExtraFieldCopy3({ "type": "Top Load" })
        }} />} label="Top Load" />

        <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "0",
            maxPrice: "20000",
          }))
        }} />} label="0-20000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "20000",
            maxPrice: "40000",
          }))
        }}
        />} label="20000-40000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "40000",
            maxPrice: "60000",
          }))
        }}
        />} label="40000-60000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "60000",
            maxPrice: "80000",
          }))
        }}
        />} label="60000-80000" />


      </div>
      )
    }
    else if (subCategory == 'Water Heaters') {
      return (<div>
        <FormControl fullWidth>

          <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Age"
            onChange={(e) => {
              var brand1 = e.target.value;
              setBrand(brand1);
              setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
            }}
          >
            <MenuItem value="Apson">Apson</MenuItem>
            <MenuItem value="Arise">Arise</MenuItem>
            <MenuItem value="Bala">Bala</MenuItem>
            <MenuItem value="Blue Me">Blue Me</MenuItem>
            <MenuItem value="Havells">Havells</MenuItem>
            <MenuItem value="LG">LG</MenuItem>
            <MenuItem value="Onida">Onida</MenuItem>
            <MenuItem value="Panasonic">Panasonic</MenuItem>
          </Select>
        </FormControl>



        <InputLabel  style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
        <Select1
          value={selectedOption}
          onChange={handleChanges}
          options={cities}
        />



        <InputLabel   style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Heater Type</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setType("Instant Water Heater");
          setExtraFieldCopy1({ "type": "Instant Water Heater" })
        }} />} label="Instant Water Heater" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setType("Storage Water Heater");
          setExtraFieldCopy1({ "type": "Storage Water Heater" })
        }} />} label="Storage Water Heater" />


        <InputLabel id="demo-simple-select-label2">Condition</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("New");
          setExtraFieldCopy2({ "Condition": "New" })
        }} />} label="New" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Good");
          setExtraFieldCopy2({ "Condition": "Good" })
        }}
        />} label="Good" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Fair");
          setExtraFieldCopy2({ "Condition": "Fair" })
        }}
        />} label="Fair" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Unboxed");
          setExtraFieldCopy2({ "Condition": "Unboxed" })
        }}
        />} label="Unboxed" />

        <InputLabel id="demo-simple-select-label2">Capacity</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(11);
          setExtraFieldCopy3({ "capacity": 11 })
        }} />} label="11L-15L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(16);
          setExtraFieldCopy3({ "capacity": 16 })
        }} />} label="16L-20L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(21);
          setExtraFieldCopy3({ "capacity": 21 })
        }} />} label="21L-50L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(50);
          setExtraFieldCopy3({ "capacity": 50 })
        }} />} label="50L-100L" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCapacity(100);
          setExtraFieldCopy3({ "capacity": 100 })
        }} />} label="100L & Above" />

        <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "0",
            maxPrice: "20000",
          }))
        }} />} label="0-20000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "20000",
            maxPrice: "40000",
          }))
        }}
        />} label="20000-40000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "40000",
            maxPrice: "60000",
          }))
        }}
        />} label="40000-60000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "60000",
            maxPrice: "80000",
          }))
        }}
        />} label="60000-80000" />


      </div>
      )
    }


    else if (subCategory == 'Water Purifiers') {
      return (<div >

        <FormControl fullWidth>

          <InputLabel id="demo-simple-select-label">Brand Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Age"
            onChange={(e) => {
              var brand1 = e.target.value;
              setBrand(brand1);
              setExtraFieldCopy({ "Brand Name": ` ${brand1}` })
            }}
          >
            <MenuItem value="Aquaguard">Aquaguard</MenuItem>
            <MenuItem value="Bala">Bala</MenuItem>
            <MenuItem value="Blue Me">Blue Me</MenuItem>
            <MenuItem value="Havells">Havells</MenuItem>
            <MenuItem value="LG">LG</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
            <MenuItem value="Panasonic">Panasonic</MenuItem>
            <MenuItem value="Kent">Kent</MenuItem>
          </Select>
        </FormControl>

        <InputLabel style={{marginTop:'5px',marginBottom:"0px"}} id="demo-simple-select-label2">City</InputLabel>
        <Select1
          value={selectedOption}
          onChange={handleChanges}
          options={cities}
        />


        <InputLabel style={{marginTop:'5px',marginBottom:"-2px"}} id="demo-simple-select-label2">Condition</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Brand New");
          setExtraFieldCopy1({ "Condition": "Brand New" })
        }} />} label="Brand New" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Gently Used");
          setExtraFieldCopy1({ "Condition": "Gently Used" })
        }}
        />} label="Gently Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Heavily Used");
          setExtraFieldCopy1({ "Condition": "Heavily Used" })
        }}
        />} label="Heavily Used" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setCondition("Unboxed");
          setExtraFieldCopy1({ "Condition": "Unboxed" })
        }}
        />} label="Unboxed" />

        <InputLabel id="demo-simple-select-label2">Price Range</InputLabel>
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "0",
            maxPrice: "20000",
          }))
        }} />} label="0-20000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: '20000',
            maxPrice: "40000",
          }))
        }}
        />} label="20000-40000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "40000",
            maxPrice: "60000",
          }))
        }}
        />} label="40000-60000" />
        <FormControlLabel required control={<Checkbox onClick={() => {
          setsearchSending((prev) => ({
            ...prev,
            minPrice: "60000",
            maxPrice: "80000",
          }))
        }}
        />} label="60000-80000" />

 
      </div>
      )
    }

    else {
      return (
        <div style={modalStyle} className="brandimg">
          <p>1Ton</p>
          <p>2.5Ton</p>
          <p>5Ton</p>
          <p>8Ton</p>
          <p>8Ton & Above</p>
        </div>
      );
    }

  };

  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);
  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end




  return (
    <>


      {/* AdsCards logic start */}
      {advClose ? (
        <div
          style={{
            position: "fixed",
            bottom: "2%",
            right: "2%",
            width: "350px",
            height: "170px",
            zIndex: "3",
            border: "0.5px solid gray",
            background:
              // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
              'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
            backgroundSize: "100% 100%",
            borderRadius: '7px'
          }}
        >
          <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px', fontSize: "18px", marginRight: '6px' }} onClick={handleCloseadv}>x</span>
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
            <div style={{ color: 'white' }}>
              Get the best <br />
              <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
            </div>
            <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
              Grow Your Business
            </div>
            <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
          </div>
        </div>
      ) : null}
      {/* AdsCards logic end */}




      <div style={sliderStyle}>
        {/* <Carousel autoplay>
      {images.map((image, index) => (
        <div key={index}>
          <img src={image.url} alt={`Image ${index + 1}`} style={{ width: "100%" }} height={300}/>
        </div>
      ))}
    </Carousel>  */}
        <ElectronicsSlider />
      </div>
      <div className="wholefil mt-0">
        <div>
          <div style={modalContainerStyle} className="bikefilcon">
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="SubCategory"
                  value={subCategory}
                  onChange={handleChange2}
                >
                  <MenuItem value="Air Conditioners - AC" >Air Conditioners - AC</MenuItem>
                  <MenuItem value="Air Coolers">Air Coolers</MenuItem>
                  <MenuItem value="Camera Accessories">Camera Accessories</MenuItem>
                  <MenuItem value="Cameras">Cameras</MenuItem>
                  <MenuItem value='Gas Stove'>Gas Stove</MenuItem>
                  <MenuItem value='Induction Cook Tops'>Induction Cook Tops</MenuItem>
                  <MenuItem value='Laptops Or (Desktops - Computers)'>Laptops Or (Desktops - Computers)</MenuItem>
                  <MenuItem value='Microwave Ovens'>Microwave Ovens</MenuItem>
                  <MenuItem value='Mixer - Grinder - Juicer'>Mixer - Grinder - Juicer</MenuItem>
                  <MenuItem value='Monitor Or Printers Or Scanners'>Monitor Or Printers Or Scanners</MenuItem>
                  <MenuItem value='Music Systems - Home Theatre'>Music Systems - Home Theatre</MenuItem>
                  <MenuItem value='Refrigerators'>Refrigerators</MenuItem>
                  <MenuItem value='TV'>TV</MenuItem>
                  <MenuItem value='Video Games'>Video Games</MenuItem>
                  <MenuItem value='Washing Machine'>Washing Machine</MenuItem>
                  <MenuItem value='Water Heaters'>Water Heaters</MenuItem>
                  <MenuItem value='Water Purifiers'>Water Purifiers</MenuItem>
                </Select>
              </FormControl>
            </div>

            <br />

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="pop-box-style">
                <span onClick={handleClose} style={{ float: 'right' }}>❌</span>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {subCategory}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div style={{ width: "10%", display: "flex", flexDirection: "row" }}>

                    <Link
                      to={`/ads-listing/`}
                      onClick={() => {
                        debugger;
                        console.log(searchSending);
                        for (const key in searchSending) {
                          if (
                            searchSending[key]?.trim() === "" ||
                            searchSending[key] === null
                          ) {
                            delete searchSending[key];
                          }
                        }
                        searchSending["subCategoryValue"] = subCategory;
                        searchSending["City"] = selectedOption.value;
                        searchSending["extraField"] = { ...extraFieldCopy, ...extraFieldCopy1, ...extraFieldCopy2, ...extraFieldCopy3 }; // Object Assign to searchSending object

                        dispatch(getExtraField(searchSending)); // Dispatching getExtraField action
                      }}
                    >
                      {/* <button
                  className="btn houzez-search-button elementor-button elementor-size-sm"
                  style={{
                    background: "#a71616bf",
                    color: "white",
                  }}
                  id="serbtn"
                >
                  Search
                </button> */}

                      <button class="search-button"
                        style={{
                          backgroundColor: "#007bff",
                          color: "#fff",
                          padding: "10px 20px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                          marginLeft: "542px"

                        }}
                        id="serbtn"> Search</button>




                    </Link>
                  </div>
                  {selectedButton && renderModalContent()}
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        {/* bike cat head end */}
        {/* search end */}
        {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
          <div>
            <h5>
              {" "}
              <div className="example">
                <Spin />
              </div>{" "}
            </h5>{" "}
          </div>
        )}
        {!loading && (
          <>
           <div >
            <Allcatads onClick={openPopup} greeting={greeting} subcategory={"TV"}/>
            </div>
            <div className="mb-3 mgntp-mnd" style={isMobile ? mobstyle : deskstyle}>
              <Feature value="Electronics" />{" "}
              {/* Rendering the 'Feature' component with value "RealEstate" */}

              <Owldemo1 greeting={greeting} subcategory={"TV"} />{" "}
              {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "PG-Hostel" */}
              <Owldemo1
                greeting={greeting}
                subcategory={"Gas Stove"}
              />{" "}
              <Owldemo1 greeting={greeting} subcategory={"Camera Accessories"} />{" "}
              <Owldemo1 greeting={greeting} subcategory={"Microwave Ovens"} />{" "}
              <Owldemo1 greeting={greeting} subcategory={"Induction Cook Top"} />{" "}
              <Owldemo1 greeting={greeting} subcategory={"Water Purifiers"} />{" "}
              <Owldemo1 greeting={greeting} subcategory={"Air Coolers"} />{" "}
              {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
            </div>
            
            {/* <div style={isMobile ? mobi : deski}> */}
              <TrendingAds />
            {/* </div> */}
            </>
        )}
        <NewsArticles category={"Bikes"} />{" "}
        {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
        <MyGallery selectedCategory="Electronics" />

      </div>
      {/* <NewsArticles category={"RealEstate"}/> */}
    </>
  );
};

export default Electronicscat;