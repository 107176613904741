import React, { useState } from "react";
import { localUrl } from "../../env";
import { isMobile } from "react-device-detect";
import { AutoComplete, Button, Form, Input, Select, message } from "antd";
import { add } from "../../../store/Track/trackUserSlice";
import { useDispatch } from "react-redux";

const mobileStyle = {
  width: "100%",
  padding: "10px",
  justifyContent: "center",
};

const desktopStyle = {
  width: "100%",
  paddingRight: "2rem",
  paddingLeft: "-3rem",
};

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Cwe = () => {
  const dispatch = useDispatch(); // Initialize the 'dispatch' function from the 'useDispatch' hook
  const [values, setValues] = useState(); // Initialize the 'values' state and the 'setValues' function with the 'useState' hook
  const [submitted, setSubmitted] = useState(false); // Initialize the 'submitted' state and the 'setSubmitted' function with the 'useState' hook
  const [form] = Form.useForm(); // Create a form instance with the 'useForm' hook from the 'Form' component in the 'antd' library

  const onFinish = (values) => {
    // Function called when the form is submitted
    dispatch(add({ form: ["contact", "contactPage", values] })); // Dispatch the 'add' action with the form values

    form.resetFields(); // Reset the form fields
    setSubmitted(true); // Set the 'submitted' state to true

    var requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
      redirect: "follow",
    };

    fetch(localUrl + "contactus/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Handle the response/result of the fetch request

        const options = {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "X-RapidAPI-Key":
              "7fded38e7emsh3c4fb60f3b8017cp1c084bjsn32180c499f5f",
            "X-RapidAPI-Host": "hourmailer.p.rapidapi.com",
          },
        };

        // Fetch data from a different API endpoint
        // fetch('https://hourmailer.p.rapidapi.com/send', options)
        //   .then(response => response.json())
        //   .then(response => { console.log(response) })
        //   .catch(err => console.error(err));
      })
      .catch((error) => console.log("@@error", error));
  };

  const successMessage = () => {
    // Function to display a success message
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <h5 className="text-success bg-white" style={{ textAlign: "center" }}>
          Submitted
        </h5>
      </div>
    );
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  return (
    <>
      {" "}
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        style={isMobile ? mobileStyle : desktopStyle}
      >
        <Form.Item
          name="Email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "It is not a valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input placeholder="Enter Your Email" />
        </Form.Item>
        <Form.Item
          name="Name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input your name",
              whitespace: true,
            },
            {
              pattern: new RegExp("^[A-Za-z ]*$"),
              message: "Wrong format!",
            },
          ]}
        >
          {/* <Input /> */}
          <Input placeholder="Enter your Name" />
        </Form.Item>
        <Form.Item
          name="PhoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
            {
              pattern: new RegExp("^[0-9]*$"),
              message: "Wrong format!",
            },
          ]}
        >
          <Input
            placeholder="Enter Phone Number"
            maxLength={10}
            minLength={10}
            // addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="Message"
          label="Message"
          rules={[
            {
              required: true,
              message: "Enter Your Message",
            },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={100}
            placeholder="Enter Message Here"
          />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "0px" }}
          >
            Submit
          </Button>
        </Form.Item>

        <h4 className="text-success">{successMessage()}</h4>
      </Form>
    </>
  );
};

export default Cwe;
