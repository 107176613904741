import React, { useContext, useState } from "react";
import Img from "./Img.png";
import Attach from "./attach.png";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../../../../firebase";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import axios from "axios";
import { Toast } from "bootstrap";
// import { onMessageListener } from "../../../../../firebase";
import { useEffect } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { MdSend, MdAttachFile } from "react-icons/md";

const Input = () => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [empty, setEmpty] = useState("");
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const [loading, setLoading] = useState(false);

  const handleSend = async (img) => {
    setLoading(true);
    if (text) {
      await updateDoc(doc(db, "chats", data.chatId), {
        messages: arrayUnion({
          id: uuid(),
          text,
          senderId: currentUser.uid,
          date: Timestamp.now(),
          readBy: {
            [currentUser?.uid]: true,
          },
        }),
      });

      await updateDoc(doc(db, "userChats", currentUser.uid), {
        [data.chatId + ".lastMessage"]: {
          text,
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });

      await updateDoc(doc(db, "userChats", data.user.uid), {
        [data.chatId + ".lastMessage"]: {
          text,
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });
    }
    if (img) {
      const storageRef = ref(storage, uuid());
      const uploadTask = uploadBytesResumable(storageRef, img);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await updateDoc(doc(db, "chats", data.chatId), {
              messages: arrayUnion({
                id: uuid(),
                text: "",
                senderId: currentUser.uid,
                date: Timestamp.now(),
                imageUrl: downloadURL,
              }),
            });
          });
        }
      );
      await updateDoc(doc(db, "userChats", currentUser.uid), {
        [data.chatId + ".lastMessage"]: {
          imageUrl: "",
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });

      await updateDoc(doc(db, "userChats", data.user.uid), {
        [data.chatId + ".lastMessage"]: {
          imageUrl: "",
        },
        [data.chatId + ".date"]: serverTimestamp(),
      });
    } else {
      setEmpty("please write something");
    }

    setText("");
    setImg(null);
    setLoading(false);
  };
  return (
    <>
      <div className="input">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          // accept="image/x-png,image/jpeg,image/jpg"
          onChange={(e) => {
            setImg(e.target.files[0]);
            handleSend(e.target.files[0]);
          }}
        />
        <label htmlFor="file" style={{ marginBottom: 0, paddingBottom: 0 }}>
          <MdAttachFile
            fontSize={"17px"}
            color="gray"
            style={{ transform: "rotate(45deg)", cursor: "pointer" }}
          />
        </label>
        <input
          type="text"
          placeholder="Type something..."
          onChange={(e) => {
            setText(e.target.value);
          }}
          disabled={loading}
          value={text}
          onKeyDown={(e) => e.code === "Enter" && handleSend()}
          style={{
            fontSize: "14px",
            padding: "5px",
          }}
        />
        <div className="send">
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
              width: "fit-content",
              color: "white",
              backgroundColor: "#8da4f1",
              cursor: "pointer",
              borderRadius: "50%",
              padding: 10,
            }}
            loading={loading}
            icon={<MdSend />}
            disabled={loading}
            onClick={() => handleSend()}
          />
        </div>
      </div>
      {empty ? <h4 className="text-danger">{empty}</h4> : null}
    </>
  );
};

export default Input;
