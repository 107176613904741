import React, { useEffect } from 'react';

const Loader = ({ data }) => {
  useEffect(() => {
    // Check if data has loaded
    if (data.length > 0) {
      // Navigate automatically based on the first entry
      const firstEntry = data[3];
      if (firstEntry[1] === true) {
        window.location.href = firstEntry[3];
    }
    }
  }, [data]);

  // Return JSX here if needed
// return(
//     firstEntry[1]
// )
};

export default Loader;
