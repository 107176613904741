import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import "./Feature.css";
import Slider from "react-slick";
import { NavLink, useNavigate } from "react-router-dom";
import { Margin } from "@mui/icons-material";
import Wishlist from "../../Account/Wishlist/Wishlist";
import { BsFillCartPlusFill } from "react-icons/bs";
import {
  BsFillTagFill,
  BsFillBookmarkFill,
  BsFillClockFill,
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper,BsHeartFill
} from "react-icons/bs";
import DynamicFont from "react-dynamic-font";
import { Button, Tooltip, Popover, Spin } from "antd";
import { url } from "../../env";
import { decrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
import { UserContext } from "../../../App";
import { useInterval } from "react-interval-hook";
import { useDispatch, useSelector } from "react-redux";
import { add, remove } from "../../../store/wishlistSlice";
import Distance from "../../distance";
import YoutubeMagic from "../../ContentLoader/YoutubeMagic";
import { SliderContainer } from "../../../Utils/SliderContainer";
import { isMobile } from "react-device-detect";
import { AiFillDelete } from "react-icons/ai";
import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";

import verified from '../Allads/verified.jpg'
// Configuration for the slider component
var settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const mobstyle={
width:"auto",
background: "transparent"
}
const deskstyle={
  background: "gray",
  //  borderRadius: "2px",
   border: "1px solid gray",
}
const mobilestyle={
  height: "140px",
  width: "350px",
  borderRadius:"20px",
}
const desktopstyle={
 height: "165px",
  width: "350px",
}

const Feature = (props) => {
  // Fetching user context
  console.log('props',props);
  const adsData = useContext(UserContext);
   // State variables
  const [category, setCateory] = useState(null);
  const [isloading, setIsLoading] = useState(true);
  useEffect(() => {
      // Setting category from props
    if (props?.value) {
      setCateory(props?.value);
    }
  }, []);
 //  Several state variables are initialized using the useState hook, including category, 
  // products, lockInterval, and interval. 
  const [products, setProducts] = useState([]);
  const [lockInterval, setLockInterval] = useState(true);
  const [interval, setInterval] = useState({ start: 0, end: 10 });
    // Redux state and actions
    // Get wishlist data from Redux store
  const wishlistData = useSelector((state) => state.wishlistSlice);
  const [wishlistID] = ["wishlistId"].map(
    document.getElementById.bind(document)
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
    // Adding or removing item from wishlist

  const addingWishlist = (value, e) => {
        // Check if user is authenticated
        
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
        // Dispatching add or remove action based on wishlist status
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add({ value: value }));
    }

    // wishlistID.addEventListener('click', e => e.stopPropagation());
  };

  const getProducts = () => {
        // Creating form data for API request
    var formdata = new FormData();
    formdata.append("start", interval.start);
    formdata.append("end", interval.end);

    if (props?.value) {
      formdata.append("category", props.value);
    }

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    // Fetching products from API
    fetch(url + "api/adsapi/featuredAdsData", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let value = result.map((result) => {
          let a = { ...result.fields };
          a["pk"] = result.pk;
          return a;
        });

        if (value.length == 0) {
          setLockInterval(false);
        }
        if (products.length == 0) {
          if (localStorage.getItem("lat")) {
            const result1 = Distance(value);
            setProducts(result1);
          } else {
            setProducts(value);
          }
        } else {
          value.map((result) => {
            let newproductValuie = products;
            newproductValuie.push(result);
            if (localStorage.getItem("lat")) {
              const result1 = Distance(newproductValuie);
              setProducts(result1);
            } else {
              setProducts(newproductValuie);
            }
          });
        }
        setInterval({ start: interval.end + 1, end: interval.end + 16 });
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getProducts();
  }, []);

  useInterval(() => {
    if (products.length > 0 && lockInterval && products.length < 40) {
      getProducts();
    }
  }, 5000);

  return (
    <>
    
    {/* this products getting from ads api */}
      {products.length ? (
       
        <div>
          <h2
            className="text-decoration-underline"
            style={{ textAlign: "center", fontSize: "24px", fontFamily: "areal", fontWeight: "bold" }}
          >
            Recent Ads
          </h2>
          {isloading ? <YoutubeMagic1 /> : null}
          {/* {products.length===0 && <YoutubeMagic1/>} */}
          <div className="row ">
            <SliderContainer>
              <Slider {...settings}>
        {/* the product object is being used to access properties and values associated with each product in the products array. */}
                {products
                  .sort((a, b) => b.pk - a.pk)
                  .map((ad, index) => {
                    // products.map((product, index) => {

                    return (
                      <>
                      <div key={ad.pk} className="ad-card m-2" onClick={() => navigate(`/ads-listing/${
                            ad?.pk === undefined ? ad?.pk : ad.pk
                          }/`)} style={{marginRight:"50px"}}>
                            <div className="glassmorphism">
                              <div>
                              <div className="ad-image">
                              <div className="wtrmrk">Hola9.com</div>
                              <img className="imghover-scale"
                                                src={
                                                  !ad?.image
                                                    ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                                    : ad?.image
                                                }
                                                alt="ad"
                                                onError={(e) => {
                                                  e.target.src =
                                                    "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                                                  e.onerror = null;
                                                }}
                                              />
                              </div>
                              <div className="product-type">
                          <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                                <span className="flat-badge booking" >
                                                <b>
                                                      <span
                                                        className="flat-badge booking "
                                                        style={{
                                                          backgroundColor:
                                                          ad?.plan ==="Silver"
                                                              ? "#cce6ff"
                                                              : ad?.plan === "Gold"
                                                              ? "gold"
                                                              : ad?.plan === "Platinum"
                                                              ? "green"
                                                              : ad?.plan === "Premium"
                                                              ? "linear-gradient(to right, #800080, #8A2BE2)"
                                                : ad?.fields?.plan === "Featured"
                                                ? "linear-gradient(to right, #090979, #00d4ff)"
                                                              // : ad?.plan === "Free"
                                                              // ? "green"
                                                              : "transparent",
                                                          color:
                                                          ad?.plan ==="Silver"
                                                              ? "blue"
                                                              : ad?.plan === "Gold"
                                                              ? "black"
                                                              : ad?.plan === "Platinum"
                                                              ? "white"
                                                              : ad?.plan === "Premium"
                                                              ? "black"
                                                              : ad?.plan === "Featured"
                                                              ? "black"
                                                              // : ad?.plan === "Free"
                                                              // ? "white"
                                                              : "transparent",
                                                              padding:"5px",
                                                             
                                                          // backgroundColor: "white",
                                                          // float:"left",
                                                          // border: "2px solid black",
                                                        }}
                                                      >
                                                        {ad?.plan}
                                                      </span>
                                                    </b>
                                                </span>
                                                <img
                                      src={verified}
                                      style={{
                                        marginRight: "-10px",
                                        borderRadius: "50%",
                                        width:'22px',
                                        height:'22px'
                                      }} />
                                </div>
                                               
                                              </div>
                                              <ul className="viewsub">
                                              <li className="view">
                                                <i
                                                  className="fas fa-eye"
                                                  style={{ color: "white" }}
                                                />
                                                <span style={{ color: "white" }}>
                                                  {ad?.viewsproduct}
                                                </span>
                                              </li>
                                              {/* <li className="click">
                                                <i className="fas fa-mouse" />
                                                <span>134</span>
                                              </li> */}
                                              <li className="rating">
                                                <i className="" style={{ color: "white" }} />
                                                <span style={{ color: "white" }}>
                                                  <BsNewspaper />
                                                  &nbsp;  {ad?.subCategoryValue?.slice(0,12)+"..."}
                                                </span>
                                              </li>
                                            </ul>
                                            </div>
                                            <div className="ad-details  m-0 p-0">
                                           {/* DESKTOP VIEW */}
                                           <p className="titlefont hidden-sm hidden-xs  m-0 p-0">
                                             <Tooltip
                                               placement="topLeft"
                                               title={ad?.title}
                                             >
                                               {ad?.title?.length > 17 ? (
                                                 <p className="titlefont">
                                                   {ad?.title?.slice(0, 17)}
                                                   {ad?.title?.length > 17
                                                     ? "..."
                                                     : null}
                                                 </p>
                                               ) : (
                                                 <div>
                                                   <p className="titlefont">
                                                     <DynamicFont
                                                       content={ad?.title}
                                                     />
                                                   </p>
                                                 </div>
                                               )}
                                             </Tooltip>
                                           </p>
                                           {/* DESKTOP VIEW */}
           
                                           {/* MOBILE VIEW */}
                                           <p className="titlefont d-lg-none">
                                             <Tooltip
                                               placement="topLeft"
                                               title={ad?.title}
                                             >
                                               {ad?.title?.length > 12 ? (
                                                 <p className="titlefont">
                                                   {ad?.title?.slice(0, 12)}
                                                   {ad?.title?.length > 12
                                                     ? "..."
                                                     : null}
                                                 </p>
                                               ) : (
                                                 <div style={{}}>
                                                   <p className="titlefont">
                                                     <DynamicFont
                                                       content={ad?.title}
                                                     />
                                                   </p>
                                                 </div>
                                               )}
                                             </Tooltip>
                                           </p>
                                           {/* MOBILE VIEW */}
                                           <div
                                             className="condiv justify-content-between "
                                             style={{ marginTop: "-15px", alignItems:'center', marginBottom:'6px', gap:'10px' }}
                                           >
                                             <div className="widhdiv d-flex justify-content-between m-0 p-0  mob-v-space">
                                               <p
                                                 className="fs-10 m-0 p-0 mt-1"
                                                 style={{
                                                   color: "#062544",
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                 }}
                                               >
                                                 <BsGeoAltFill
                                                   className="mr-1 "
                                                   style={{ color: "red" }}
                                                 />
                                                 <b>{ad?.City}</b>
                                               </p>
                                               <BsFillBookmarkFill
                                                 id="wishlibyn"
                                                 style={{
                                                   fontSize: "20px",
                                                   marginTop: "6px",
                                                 }}
                                                 className={
                                                   wishlistData?.data?.indexOf(ad.pk) !==
                                                   -1
                                                     ? "removemob"
                                                     : "heartmob"
                                                 }
                                                 onClick={(e) => {
                                                   e.stopPropagation();
                                                   e.preventDefault();
                                                   addingWishlist(ad.pk);
                                                 }}
                                               />
                                             </div>
                                             <div className="mob-v-space">
                                             {ad?.category == "RealEstate" ? (
                                               <p
                                                 className="conpir fs-14"
                                                 style={{
                                                   color: "gray",
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                   fontWeight: "bold",
                                                   margin:'0',
                                                   transform:'unset'
                                                 }}
                                               >
                                                 <BsBuilding
                                                   className="mr-2"
                                                   style={{
                                                     color: "green",
                                                     fontSize: "90%",
                                                   }}
                                                 />
                                                 {ad.subCategoryType?.slice(0, 9)}
                                               </p>
                                             ) : (
                                               <p
                                                 className="conpir fs-14"
                                                 style={{
                                          
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                   margin:'0',
                                                   transform:'unset'
                                                   
                                                 }}
                                               >
                                                 <BsFillCheckSquareFill
                                                   className="mr-1"
                                                   style={{
                                                     color: "green",
                                                     fontSize: "90%",
                                                   }}
                                                 />
                                                 {ad?.condition?.slice(0, 9)}
                                               </p>
                                             )}
                                             <span className="mob-star">
                                <i className="fa fa-star" style={{color:'gold'}}> <span style={{color:'gray'}}>4.5</span></i>
                              </span>
                                </div>
                                           </div>
                                           <div className="price-bot-mob">
                                           <p
                                             className="ad-price mr-1"
                                             style={{
                                               fontSize: "90%",
                                               fontFamily: "verdana",
                                              //  marginTop: "-15px",
                                              margin:'0'
                                             }}
                                           >
                                             &nbsp;₹ <b>{ad?.price}</b>
                                           </p>
                                           <span className="desk-star">
                                <i className="fa fa-star" style={{color:'gold'}}> <span style={{color:'gray'}}>4.5</span></i>
                              </span>
                              </div>
                                           <button
                                             id="wishbbt"
                                             style={{ marginTop: "-15px" }}
                                             className={
                                               wishlistData?.data?.indexOf(ad.pk) !== -1
                                                 ? "remove-button"
                                                 : "wishlist-button"
                                             }
                                             onClick={(e) => {
                                               e.stopPropagation();
                                               e.preventDefault();
                                               addingWishlist(ad.pk);
                                             }}
                                           >
                                             {wishlistData?.data?.indexOf(ad.pk) !== -1 ? (
                                               <>
                                                 <AiFillDelete />
                                                 &nbsp;&nbsp;Remove Wishlist
                                               </>
                                             ) : (
                                               <>
                                                 <BsFillBookmarkFill />
                                                 &nbsp;&nbsp;Add To Wishlist
                                               </>
                                             )}
                                           </button>
                                         </div>
                            </div>
                      </div>
                      </>
                    );
                  })}
              </Slider>
            </SliderContainer>
          </div>
          {/* </div> */}
        </div>
      ) : null}
    </>
  );
};

export default Feature;
