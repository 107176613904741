import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "./TopSellers.css";
import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";
import { localUrl, url } from "../../env";
import { FaUser } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
// import { FcPortraitMode} from "react-icons/fc";
import { isMobile } from "react-device-detect";

function TopSellers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState();
  const [ratings, setRatings] = useState({});

  const id = "";
  const mobstyle = {
    margin: "auto 0rem",
    width:"100%"
  };
  const deskstyle = {
    margin: "1rem 2rem",
  };
  const [userID, setUserId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = "https://databytess.com/api/adsapi/topsellers";
      try {
        const response = await fetch(apiUrl);
        if (response.ok) {
          const result = await response.json();
          setData(result);
        console.log("hi am avinash", result)
        // Fetch ratings for each top seller
        const ratingPromises = result.map(async (user) => {
          const ratingResponse = await fetch(
            url + `api/sellerreviews/?seller_id=${user.id}`
          );
          if (ratingResponse.ok) {
            const ratingData = await ratingResponse.json();
            const lastItem = ratingData[ratingData.length - 1];
            setRatings((prevRatings) => ({
              ...prevRatings,
              [user.id]: lastItem?.average_rating_value || 0,
            }));
          } else {
            console.error(`Failed to fetch rating for user ${user.id}`);
            // If the rating field is not present, set the rating to 0
            setRatings((prevRatings) => ({
              ...prevRatings,
              [user.id]: 0,
            }));
          }
        });

        await Promise.all(ratingPromises);
        } else {
          console.error("Failed to fetch data from the API");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const calculateSlidesToShow = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      // Small screens like mobiles
      return 2;
    } else if (screenWidth < 1024) {
      // Medium screens like tablets
      return 3;
    } else {
      return 5; // Larger screens like desktops
    }
  };



 

  return (
    <div>
      {loading ? (
        <YoutubeMagic1 />
      ) : (
        <div>
          <h2 className="headrev">Our Top Seller</h2>
          <Slider 
            slidesToShow={calculateSlidesToShow()}
            infinite={true}
            style={isMobile ? mobstyle : deskstyle}
            className="mainwrapper"
          >
            {data.map((user, index) => (
              <div key={index}>
                <Link to={`activeUserAds/${user.id}/`}>
                  <div className="topcard">
                    <div className="profile-icon" style={{paddingTop:"15px",display:"flex"}}>
                    <span style={{marginLeft:"42%"}}>
                    {user.image ? (
          <img src={user.image} alt="User Avatar" style={{
            height: "40px",
            border: "1px solid gray",
            borderRadius: "10px",
          }} />
        ) : (
          <FaUser style={{
            fontSize: "40px",
            color: "#b8b894",
            borderRadius: "10px",
          }} />
        )}
                    </span>
                    <span style={{marginLeft:"27px"}}>
                        <button style={{color:"#fff",background:"green",padding:"2px 6px",borderRadius:"10px",alignItems:"center",display:"flex"}}>{Math.floor(ratings[user.id])} <FaStar /></button>
                    </span>
                    </div>
                    <div className="card-content">
                      {/* <p className="user-id">User Id: {user.id}</p> */}
                      <span style={{ color: "black" }}>{user.name}</span>

                      <Link to={`activeUserAds/${user.id}/`} className="seemore">
                        See More
                      </Link>
                      <span style={{ color: "gray", fontSize: "70%",textAlign:"end"}}>Since:{user.created_at}</span>

                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
}

export default TopSellers;
