// import React, { useContext, useEffect, useState } from "react";
// import "./realEstateSection.css";
// import { useDispatch } from "react-redux";
// import { add } from "../../../../store/Track/trackUserSlice";
// import { getExtraField } from "../../../../store/ProductFilterSlice";
// import { Link } from "react-router-dom";
// import { isMobile } from "react-device-detect";
// import { size } from "../../../env";


// const mob = {
//   display: "flex",
//   flexDirection: "column",
//   Width:"30px",
//   marginTop:"-50px",
//   marginLeft:"10px",
//   marginRight : '0px',
//   // Height:"10px"

// };
// const desk = {};

// const mob2 = {
// // marginLeft:"100px",
// // marginTop:"-100px",
// }

// const desk2 = {}

// // const mob3 ={
// // marginTop:"500px",
// // marginLeft:"-100px"
// // }
// // const desk3 ={}

// const mob1 = {
//   display: "flex",
//   flexDirection: "row",
//   marginTop:"-10px",
//   marginLeft:"4.2cm"
// };
// const desk1 = {};

// const RealEstateSection = () => {
//   const [selectedFilter, setSelectedFilter] = useState(null);
//   const disptach = useDispatch();
//   const dispatch = useDispatch();
//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [sub, setsub] = useState();

//   const [searchSending, setsearchSending] = useState({
//     subCategoryValue: null,
//     category: "RealEstate",
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook

//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setExtraFieldCopy({ [name]: value });
//     // setsearchSending({ ...searchSending, [name]: value });
//     // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   return (
//     <div>
      
//         <div className="headiv" style={{justifyContent:"space-around"}}>
//           <section className="hero"  style={isMobile ? mob : desk}>
//             <h2>Welcome to Your Dream Home</h2>
//             <p>Find the perfect property for you.</p>
//             <a href="#" className="btntgh" style={{fontSize: '90%'}}>
//               Explore Properties
//             </a>
//           </section>

//           <section className="hero"  style={isMobile ? mob2 : desk2} >
//             <img
//               src="https://www.build-review.com/wp-content/uploads/2020/07/luxury-real-estate.jpg"
//               alt=""
//               className="imgreak"
//             />
//           </section>
          
//       </div>
//       {/* <div className="roundim"  style={isMobile ? mob1 : desk1}>
        
//           <img
//             className="logoreale"
//             src="https://static.vecteezy.com/system/resources/previews/000/585/815/original/vector-real-estate-and-home-buildings-logo-icons-template.jpg"
//           />
       
//       </div> */}
   
     
//     </div>
//   );
// };

// export default RealEstateSection;



import React, { useContext, useEffect, useState } from "react";

import "./realEstateSection.css";

import { useDispatch } from "react-redux";

import { add } from "../../../../store/Track/trackUserSlice";

import { getExtraField } from "../../../../store/ProductFilterSlice";

import { Link } from "react-router-dom";

import { isMobile } from "react-device-detect";

import { size } from "../../../env";

 

 

const mob = {

  display: "flex",

  flexDirection: "column",

  Width: "30px",

  marginTop: "-50px",

  marginLeft: "10px",

  marginRight: '0px',

  // Height:"10px"

 

};

const desk = {};

 

const mob2 = {

  // marginLeft:"100px",

  // marginTop:"-100px",

}

 

const desk2 = {}

 

// const mob3 ={

// marginTop:"500px",

// marginLeft:"-100px"

// }

// const desk3 ={}

 

const mob1 = {

  display: "flex",

  flexDirection: "row",

  marginTop: "-10px",

  marginLeft: "4.2cm"

};

const desk1 = {};

 

const RealEstateSection = () => {

  const [selectedFilter, setSelectedFilter] = useState(null);

  const disptach = useDispatch();

  const dispatch = useDispatch();

  const [extraFieldCopy, setExtraFieldCopy] = useState({});

  const [sub, setsub] = useState();

 

  const [searchSending, setsearchSending] = useState({

    subCategoryValue: null,

    category: "RealEstate",

  }); // State for search form data

 

  //   const disptach = useDispatch(); // Initializing useDispatch hook

 

  // Event handler for input field changes

  const handleChange = (e) => {

    var name = e.target.name;

    var value = e.target.value;

    setExtraFieldCopy({ [name]: value });

    // setsearchSending({ ...searchSending, [name]: value });

    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action

  };

 

  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);

 

  useEffect(() => {

    const isPopupClosed = sessionStorage.getItem('popupClosed');

    const name = localStorage.getItem('name')

    if (isPopupClosed && name) {

      setadvClose(false);

    }

  }, []);

 

  const handleCloseadv = () => {

    setadvClose(false);

    sessionStorage.setItem('popupClosed', 'true');

  };

  // AdsCards logic end

 

  const dispa = useDispatch();
  const handleClick = (category, subCategoryType, subCategoryValue) => {
    const obj = {
      category,
      ...(subCategoryType !== undefined ? { subCategoryType } : {}),
      ...(subCategoryValue !== undefined ? { subCategoryValue } : {}),
      extraField: {},
    };
    dispa(getExtraField(obj));
  };

  return (

    <>

      <div>

 

        <div className="headiv" style={{ justifyContent: "space-around" }}>

          <section className="hero" style={isMobile ? mob : desk}>

            <h2>Welcome to Your Dream Home</h2>

            <p>Find the perfect property for you.</p>

            <Link onClick={() => handleClick("RealEstate")}
              to={`/ads-listing/category/${"RealEstate"}/`} className="btntgh" style={{ fontSize: '90%' }}>

              Explore Properties
            </Link>
          </section>

          {/* AdsCards logic start */}

          {advClose ? (

 

            <div

              style={{

                position: "fixed",

                bottom: "2%",

                right: "2%",

                width: "350px",

                height: "170px",

                zIndex: "3",

                border: "0.5px solid gray",

                background:

                  // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",

                  'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',

                backgroundSize: "100% 100%",

                borderRadius: '7px'

              }}

            >

              <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"18px",  marginRight:'6px' }} onClick={handleCloseadv}>x</span>

              <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>

                <div style={{ color: 'white' }}>

                  Get the best <br />

                  <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>

                </div>

                <div style={{ color: 'whitesmoke', fontWeight: '600' }}>

                  Grow Your Business

                </div>

                <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>

              </div>

            </div>

          ) : null}

          {/* AdsCards logic end */}

          <section className="hero" style={isMobile ? mob2 : desk2} >

            <img

              src="https://www.build-review.com/wp-content/uploads/2020/07/luxury-real-estate.jpg"

              alt=""

              className="imgreak"

            />

          </section>

 

        </div>

        {/* <div className="roundim"  style={isMobile ? mob1 : desk1}>

       

          <img

            className="logoreale"

            src="https://static.vecteezy.com/system/resources/previews/000/585/815/original/vector-real-estate-and-home-buildings-logo-icons-template.jpg"

          />

       

      </div> */}

 

 

      </div>

    </>

  );

};

 

export default RealEstateSection;

 

