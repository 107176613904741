import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SearchOutlined } from "@ant-design/icons";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import "../searchBox/SearchBox.css";
import { Link } from "react-router-dom";
import "./allfilter.css";
import { isMobile } from "react-device-detect";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import ServiceSlider from "../../../../Shared/CategorySlider/ServiceSlider";
import Allcatads from "../../../Allcatads";

const mobstyle = {
  width: "auto",
  marginLeft: "25px",
  marginRight: "25px",
};
const deskstyle = {
  padding: "20px",
  marginBottom: "-10px",
  marginRight: "10rem",
  marginLeft: "10rem",
};
const mobi={
}
const deski={
  marginLeft:"7rem",marginRight:"7rem"
}
const mobileStyle = {
  width: "80px",
  height: "80px",
  backgroundSize: "cover",
};
const desktopStyle = {
  backgroundSize: "cover",
  paddingTop: "0.5rem",
  width: "150px",
  height: "120px",
};
const mobileview = {
  border: "1px solid gray",
  margin: "0.2rem 5px",
};
const desktopview = {
  border: "2px solid gray",
  width: "10rem",
  height: "11rem",
  marginBottom: "2rem",
  marginLeft: "1rem",
  marginRight: "1rem",

};
const mobilestyle1 = {
  width: "auto",
  flexDirection: "row",
};
const desktopstyle1 = {
  width: "100%",
  flexDirection: "row",
};

const mobilestyle3 = {
  display: "flex",
  flexDirection: "column",
  margin: "0.5rem",
};
const desktopstyle3 = {
  display: "flex",
  flexDirection: "row",
};
const mobilestyle4 = {
  fontSize: "10px",
  textAlign: "center",
  color: "white",
  backgroundColor: "gray",
};
const desktopstyle4 = {
  textAlign: "center",
  color: "white",
  backgroundColor: "gray",
  fontSize: "16px",
};

const ServicesCat = () => {
 const [selectedFilter, setSelectedFilter] = useState(null);
 
 const [isPopupOpen, setPopupOpen] = useState(false);
 const openPopup = () => {
   setPopupOpen(true);
 };


  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  
  const greeting = "Services";

  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook

  const sliderStyle = {
    width: "100%",
    // marginTop:"2cm",
    // maxWidth: "100vw",
  };
  const disptach = useDispatch();
  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [searchSending, setsearchSending] = useState({
    subCategoryValue: "",
    category: "Services",
  });
  useEffect(() => {
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  useEffect(() => {
    disptach(add({ view: ["ServicesCat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);

  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);
  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end



  
  return (
    <>


     {/* AdsCards logic start */}
     {advClose ? (
        <div
          style={{
            position: "fixed",
            bottom: "2%",
            right: "2%",
            width: "350px",
            height: "170px",
            zIndex: "3",
            border: "0.5px solid gray",
            background:
              // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
              'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
            backgroundSize: "100% 100%",
            borderRadius: '7px'
          }}
        >
          <span style={{ float: 'right', padding: '2px', background: 'transparent',color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"18px", marginRight:'6px' }} onClick={handleCloseadv}>x</span>
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
            <div style={{ color: 'white' }}>
              Get the best <br />
              <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
            </div>
            <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
              Grow Your Business
            </div>
            <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
          </div>
        </div>
      ) : null}
      {/* AdsCards logic end */}



      <div>
  <ServiceSlider/>
  <h3
  style={{
    textAlign: "center",
    background: "gray",
    color: "white",
    padding: "0.5rem",
    fontFamily: "verdana",
    
  }}
>
 Our Services
</h3>
        <div style={isMobile ? mobilestyle3 : desktopstyle3}>
      
          <div
            className="container1"
            style={isMobile ? mobilestyle1 : desktopstyle1}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }} >
                <div
                  style={isMobile ? mobileview : desktopview}
                 
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Appliance Repair"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a >
                      <img
                        alt="imag"
                        src="https://media.istockphoto.com/id/1256852529/vector/washing-machine-repair-service-vector-illustration-in-flat-style-plumbing-services-household.jpg?s=612x612&w=0&k=20&c=RvyhNc0zJWZA7TF-pTTm0tMijYMB9WjphTi5WAnjb6M="
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                      />
                      <h5
                        className=" mt-3  "
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Appliance Repair
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>

                <div
                  style={isMobile ? mobileview : desktopview}
                  
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Computer Repair"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a>
                      <img
                        alt="imag"
                        src="https://static.vecteezy.com/system/resources/previews/016/223/089/non_2x/computer-and-laptop-repair-logo-template-icon-illustration-design-vector.jpg"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                      />
                      <h5
                        className=" mt-3  "
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Computer Repair
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
                <div
                  style={isMobile ? mobileview : desktopview}
                  
                > <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Home Cleaning"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a>
                      <img
                        alt="imag"
                        src="https://static.vecteezy.com/system/resources/previews/004/590/847/non_2x/clean-house-icon-home-wash-cleaning-house-simple-design-editable-design-template-vector.jpg"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className=" mt-3  "
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Home Cleaning
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
                <div
                  style={isMobile ? mobileview : desktopview}
                  
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Home Help"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a >
                      <img
                        alt="imag"
                        src="https://static.vecteezy.com/system/resources/previews/005/863/334/non_2x/home-office-icon-concept-man-working-on-computer-in-the-house-illustration-isolated-on-white-background-free-vector.jpg"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className="mt-3  "
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Home Help
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
                <div
                  style={isMobile ? mobileview : desktopview}
                  
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Home Repair"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a >
                      <img
                        alt="imag"
                        src="https://www.freeiconspng.com/uploads/home-repair-service-icon-32.jpg"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className=" mt-3  "
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Home Repair
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
                <div
                  style={isMobile ? mobileview : desktopview}
                  
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Interior Designing & Home Improvements"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a>
                      <img
                        alt="imag"
                        src="https://img.freepik.com/premium-photo/interior-design-with-living-room-black-line-sketch-with-shadows-white-texture-paper-illustration_263512-1760.jpg?w=2000"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className=" mt-3  "
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Interior Designing
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={isMobile ? mobileview : desktopview}
                  
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Inverter & Batteries"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a>
                      <img
                        alt="imag"
                        src="https://static.vecteezy.com/system/resources/previews/025/086/527/non_2x/inverter-battery-icon-in-gray-and-white-color-vector.jpg"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className=" mt-3  "
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Inverter & Batteries
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
                <div
                  style={isMobile ? mobileview : desktopview}
                  
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Kitchen Aplience Repair"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a>
                      <img
                        alt="imag"
                        src="https://media.istockphoto.com/id/1401398136/vector/kettle-on-gas-stove-sketch-vector.jpg?s=612x612&w=0&k=20&c=sr8dcccIkpFZkCvOVML5oidDjMJIwHJd4dH3RyxZkic="
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className="mt-3  "
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Kitchen Aplience Repair
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
                <div
                  style={isMobile ? mobileview : desktopview}
                  
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Mobile & Tablet Repair"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a>
                      <img
                        alt="imag"
                        src="https://img.freepik.com/premium-vector/mobile-phone-repair-shop-disassembly-information-cell-phones-service-guide-wrench-icon-vector-illustration_642540-114.jpg?w=2000"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className="   mt-3"
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Mobile & Tablet Repair
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
                <div
                  
                  style={isMobile ? mobileview : desktopview}
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Packers & Movers"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a >
                      <img
                        alt="imag"
                        src="https://www.clipartkey.com/mpngs/m/140-1407042_movers-and-packers-icon.png"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className="  mt-3"
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Packers & Movers
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
                <div
                  style={isMobile ? mobileview : desktopview}
                  
                >
                <Link
                to={`/ads-listing/`}
                onClick={() => {
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }
                  searchSending["extraField"] = { ...extraFieldCopy };
                  searchSending["subCategoryValue"] = "Pest Control"; 
                  disptach(getExtraField(searchSending)); 
                }}
              >
                  <center>
                    <a>
                      <img
                        alt="imag"
                        src="https://img.freepik.com/premium-vector/pest-control-icon-set-silhouettes-about-fight-against-cockroaches_703715-179.jpg?w=2000"
                        style={isMobile ? mobileStyle : desktopStyle}
                        className="circleHover"
                        // height="80px"
                      />
                      <h5
                        className="   mt-3"
                        style={isMobile ? mobilestyle4 : desktopstyle4}
                      >
                        Pest Control
                      </h5>
                    </a>
                  </center>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wholefil mt-0">
          {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
            <div>
              <h5>
                {" "}
                <div className="example">
                  <Spin />
                </div>{" "}
              </h5>{" "}
            </div>
          )}
          {!loading && ( 
           <> 
             <div >
            <Allcatads onClick={openPopup} greeting={greeting} subcategory={"Services"}/>
            </div>
            <div className="mt-4" style={isMobile ? mobstyle : deskstyle}>
              <Feature value="Services" />{" "}
              <div id="appliencerepair">
                <Owldemo1
                  greeting={greeting}
                  subcategory={"Appliance Repair"}
                />{" "}
              </div>
              <div id="computerrepair">
                <Owldemo1 greeting={greeting} subcategory={"Computer Repair"} />{" "}
              </div>
              <div id="homecleaning">
                <Owldemo1 greeting={greeting} subcategory={"Home Cleaning"} />{" "}
              </div>
              <div id="homehelp">
                <Owldemo1 greeting={greeting} subcategory={"Home Help"} />{" "}
              </div>
              <div id="homerepair">
                <Owldemo1 greeting={greeting} subcategory={"Home Repair​​"} />{" "}
              </div>
              <div id="interior">
                <Owldemo1
                  greeting={greeting}
                  subcategory={"Interior Designing & Home improvements"}
                />{" "}
              </div>
              <div id="invertor">
                <Owldemo1
                  greeting={greeting}
                  subcategory={"Invertor & Batteries"}
                />{" "}
              </div>
              <div id="kitchen">
                <Owldemo1
                  greeting={greeting}
                  subcategory={"Kitchen Appliance Repair"}
                />{" "}
              </div>
              <div id="mobile">
                <Owldemo1
                  greeting={greeting}
                  subcategory={"Mobile & Tablet Repair"}
                />{" "}
              </div>
              <div id="packers">
                <Owldemo1
                  greeting={greeting}
                  subcategory={"Packers and Movers"}
                />{" "}
              </div>
              <div id="pest">
                <Owldemo1 greeting={greeting} subcategory={"Pest Control"} />{" "}
              </div>
            </div>
            <div style={isMobile? mobi: deski}>
      <TrendingAds/>
      </div></>
          )}
          <NewsArticles category={"Services"} />
          <MyGallery selectedCategory="Services" />
        </div>
      </div>
    </>
  );
};

export default ServicesCat;
