import React from "react";
import { Col, Row, Tooltip } from "antd";
import {
  BsBuilding,
  BsFillCheckSquareFill,
  BsFillTagFill,
  BsGeoAltFill,
  BsHeartFill,
  BsNewspaper,BsCoin, BsFillBookmarkFill
} from "react-icons/bs";
import DynamicFont from "react-dynamic-font";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { remove, add1 } from "../../../store/wishlistSlice";
import { Input, Space } from "antd";
import {
  filterValue,
  reset,
  searchApi,
} from "../../../store/ToggleSearchSlice";
import { useState } from "react";
import { FilterBy } from "../../../FilterBy";
import { productValueValue } from "../../../store/showProductsliceValue";
import NoDataFound from "../../datanotfound/NoDataFound";
import { isMobile } from "react-device-detect";
import { AiFillDelete } from "react-icons/ai";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const mobstyle = {
  width:"auto",
  marginLeft:"10px",
  marginRight:"10px",
 
};
const deskstyle = {
  marginRight: "11rem",
  marginLeft: "11rem",
};
const mobistyle = { background: "transparent"};
const deskistyle = {
  width: "14rem",
  background: "gray",
  //  borderRadius: "2px",
   border: "1px solid gray",
};
const mobilestyle={
  height: "140px",
  width: "350px",
  borderRadius:"20px",
}
const desktopstyle={
 height: "165px",
  width: "350px",
}

const SearchProducts = ({ data }) => {
  const { Search } = Input;
  const { searchVal } = useSelector((state) => state.ToggleSearchSlice);
  console.log("!!!data", searchVal);
  const wishlistData = useSelector((state) => state.wishlistSlice);
  const dispatch = useDispatch();

  const filterMost = (value) => {
    dispatch(filterValue(value));
  };

  const dynamicFont = {
    width: 400,
    fontSize: 30,
    lineHeight: 30,
    overflow: "hidden",
  };
  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      Navigate("/login");
    }
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add1({ value: value }));
    }

    console.log("wishlist value", value, wishlistData);
  };
  const [search, setSearch] = useState({
    text: searchVal,
  });
  console.log(search.text, "@@@@@@");
  const searchArray = () => {
    if (search.text.trim()) {
      dispatch(searchApi(search.text));
    } else {
      dispatch(searchApi(""));
    }
  };
  const navigate = useNavigate();

  return (
    <div>
      {/* <Search
        placeholder="input search text"
        allowClear
        enterButton="Search"
        size="medium"
      
        value={search.text}
        onSearch={searchArray}
      /> */}
<div className="fildivshow" style={{marginBottom:"40px",marginTop:"20px"}}>
                    <div className="filter-short">
                     
                    <div className="d-flex" style={{ borderLeftRadius: 0, background: "white", marginLeft: "3cm" }}>
  <input
    className="searchfilinp"
    placeholder="Search Ads"
    value={search.text}
            onChange={(e) =>
              setSearch((prev) => ({ ...prev, text: e.target.value }))
            }
    style={{
      display: "flex",
      justifyContent: "center",
      borderBottom: "1px solid black",
      padding: "8px"
    }}
  />
    <button className="px-3 py-2" onClick={searchArray} style={{backgroundColor:"black",color:"white" }}>
      <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px'}} />
    </button>
</div>
                    </div>
                    <br/>
                    <div className="d-flex justify-content-between">
                    <div className="filter-short">
                      <select
                        placeholder="Sort by"
                        className="custom-select filter-select"
                        onChange={(e) => {
                          // filerMostSearch(e.target.value);
                          filterMost(e.target.value);
                        }}
                        // onClick={(e) => {
                        //   filerMostSearch(e.target.value);
                        // }}
                      >
                       <option value="">choose</option>
            <option value="recentlyadded">RecentlyAdded</option>
            <option value="Older">Older</option>
            <option value="priceMinMax">Price(Min- Max)</option>
            <option value="priceMaxMin">Price(Max- Min)</option>
                        {/* <option value="featured">Featured</option> */}
                        {/* <option value="recommend">recommend</option> */}
                      </select>
                    </div>
                    <div className="filter-short">
                      <FilterBy />
                    </div>
                    </div>
                  </div>
                  <Row style={{ margin: "1px 30px" }}>
                <Col
                  span={24}
                  style={{
                    color: "black",
                    fontSize: "18px",
                    fontFamily: "Georgia",
                  }}
                >
                  <ins>Filtered By :</ins>
                </Col>
              </Row>
      <div className="row" style={isMobile ? mobstyle : deskstyle}>
      <div className="ads-container">
        <div className="card-grid">
        {data?.message ? (
          <NoDataFound />
        ) : (
          data?.map((ad) => {
            return (
              <div key={ad.pk} className="ad-card" onClick={() => navigate(`/ads-listing/${
                ad?.pk === undefined ? ad?.pk : ad.pk
              }/`)}>
                <div className="glassmorphism">
                  <div>
                  <div className="ad-image">
                  <div className="wtrmrk">Hola9.com</div>
                  <img className="imghover-scale"
                                    src={
                                      !ad?.fields?.image
                                        ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                        : ad.fields?.image
                                    }
                                    alt="ad"
                                    onError={(e) => {
                                      e.target.src =
                                        "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                                      e.onerror = null;
                                    }}
                                  />
                  </div>
                  <div className="product-type">
                                    <span className="flat-badge booking" >
                                    <b>
                                          <span
                                            className="flat-badge booking "
                                            style={{
                                              background:
                                              ad?.fields?.plan ==="Silver"
                                                  ? "#cce6ff"
                                                  : ad?.fields?.plan === "Gold"
                                                  ? "gold"
                                                  : ad?.fields?.plan === "Platinum"
                                                  ? "green"
                                                  : ad?.fields?.plan === "Premium"
                                                  ? "linear-gradient(to right, #800080, #8A2BE2)"
                                                  : ad?.fields?.plan === "Featured"
                                                  ? "linear-gradient(to right, #090979, #00d4ff)"
                                                  // : ad?.fields?.plan === "Free"
                                                  // ? "green"
                                                  : "transparent",
                                              color:
                                              ad?.fields?.plan ==="Silver"
                                                  ? "blue"
                                                  : ad?.fields?.plan === "Gold"
                                                  ? "black"
                                                  : ad?.fields?.plan === "Platinum"
                                                  ? "white"
                                                  : ad?.fields?.plan === "Premium"
                                                  ? "white"
                                                  : ad?.fields?.plan === "Featured"
                                                  ? "white"
                                                  // : ad?.fields?.plan === "Free"
                                                  // ? "white"
                                                  : "transparent",
                                                  padding:"5px",
                                                 
                                              // backgroundColor: "white",
                                              // float:"left",
                                              // border: "2px solid black",
                                            }}
                                          >
                                            {ad?.fields?.plan}
                                          </span>
                                        </b>
                                    </span>
                                   
                                  </div>
                                  <ul className="viewsub">
                                  <li className="view">
                                    <i
                                      className="fas fa-eye"
                                      style={{ color: "white" }}
                                    />
                                    <span style={{ color: "white" }}>
                                      {ad?.fields?.viewsproduct}
                                    </span>
                                  </li>
                                  {/* <li className="click">
                                    <i className="fas fa-mouse" />
                                    <span>134</span>
                                  </li> */}
                                  <li className="rating">
                                    <i className="" style={{ color: "white" }} />
                                    <span style={{ color: "white" }}>
                                      <BsNewspaper />
                                      &nbsp;  {ad?.fields?.subCategoryValue?.slice(0,12)+"..."}
                                    </span>
                                  </li>
                                </ul>
                                </div>
                                <div className="ad-details  m-0 p-0">
                                {/* DESKTOP VIEW */}
                                <p className="titlefont hidden-sm hidden-xs  m-0 p-0">
                                  <Tooltip
                                    placement="topLeft"
                                    title={ad?.fields?.title}
                                  >
                                    {ad?.fields?.title?.length > 17 ? (
                                      <p className="titlefont">
                                        {ad?.fields?.title?.slice(0, 17)}
                                        {ad?.fields?.title?.length > 17
                                          ? "..."
                                          : null}
                                      </p>
                                    ) : (
                                      <div>
                                        <p className="titlefont">
                                          <DynamicFont
                                            content={ad?.fields?.title}
                                          />
                                        </p>
                                      </div>
                                    )}
                                  </Tooltip>
                                </p>
                                {/* DESKTOP VIEW */}

                                {/* MOBILE VIEW */}
                                <p className="titlefont d-lg-none">
                                  <Tooltip
                                    placement="topLeft"
                                    title={ad.fields?.title}
                                  >
                                    {ad?.fields?.title?.length > 12 ? (
                                      <p className="titlefont">
                                        {ad?.fields?.title?.slice(0, 12)}
                                        {ad?.fields?.title?.length > 12
                                          ? "..."
                                          : null}
                                      </p>
                                    ) : (
                                      <div style={{}}>
                                        <p className="titlefont">
                                          <DynamicFont
                                            content={ad?.fields?.title}
                                          />
                                        </p>
                                      </div>
                                    )}
                                  </Tooltip>
                                </p>
                                {/* MOBILE VIEW */}
                                <div
                                  className="condiv justify-content-between "
                                  style={{ marginTop: "-15px" }}
                                >
                                  <div className="widhdiv d-flex justify-content-between m-0 p-0">
                                    <p
                                      className="fs-10 m-0 p-0 mt-1"
                                      style={{
                                        color: "#062544",
                                        fontSize: "90%",
                                        fontFamily: "sans-serif",
                                      }}
                                    >
                                      <BsGeoAltFill
                                        className="mr-1 "
                                        style={{ color: "red" }}
                                      />
                                      <b>{ad?.fields?.City}</b>
                                    </p>
                                    <BsFillBookmarkFill
                                      id="wishlibyn"
                                      style={{
                                        fontSize: "20px",
                                        marginTop: "6px",
                                      }}
                                      className={
                                        wishlistData?.data?.indexOf(ad.pk) !==
                                        -1
                                          ? "removemob"
                                          : "heartmob"
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        addingWishlist(ad.pk);
                                      }}
                                    />
                                  </div>
                                  {ad?.fields?.category == "RealEstate" ? (
                                    <p
                                      className="conpir fs-14"
                                      style={{
                                        color: "gray",
                                        fontSize: "90%",
                                        fontFamily: "sans-serif",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <BsBuilding
                                        className="mr-2"
                                        style={{
                                          color: "green",
                                          fontSize: "90%",
                                        }}
                                      />
                                      {ad.subCategoryType?.slice(0, 9)}
                                    </p>
                                  ) : (
                                    <p
                                      className="conpir fs-14"
                                      style={{
                               
                                        fontSize: "90%",
                                        fontFamily: "sans-serif",
                                        
                                      }}
                                    >
                                      <BsFillCheckSquareFill
                                        className="mr-1"
                                        style={{
                                          color: "green",
                                          fontSize: "90%",
                                        }}
                                      />
                                      {ad?.fields?.condition?.slice(0, 9)}
                                    </p>
                                  )}
                                </div>
                                <p
                                  className="ad-price mr-1"
                                  style={{
                                    fontSize: "90%",
                                    fontFamily: "verdana",
                                    marginTop: "-15px",
                                  }}
                                >
                                  &nbsp;₹ <b>{ad?.fields?.price}</b>
                                </p>
                                <button
                                  id="wishbbt"
                                  style={{ marginTop: "-15px" }}
                                  className={
                                    wishlistData?.data?.indexOf(ad.pk) !== -1
                                      ? "remove-button"
                                      : "wishlist-button"
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    addingWishlist(ad.pk);
                                  }}
                                >
                                  {wishlistData?.data?.indexOf(ad.pk) !== -1 ? (
                                    <>
                                      <AiFillDelete />
                                      &nbsp;&nbsp;Remove Wishlist
                                    </>
                                  ) : (
                                    <>
                                      <BsFillBookmarkFill />
                                      &nbsp;&nbsp;Add To Wishlist
                                    </>
                                  )}
                                </button>
                              </div>
                </div>
              </div>
            );
          })
        )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default SearchProducts;
