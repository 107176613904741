// // src/components/Popup.js
// import React, { useEffect } from 'react';

// const Popup = () => {
//   useEffect(() => {
//     // Load the Google AdSense script dynamically
//     const script = document.createElement('script');
//     script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5915808595950330';
//     script.async = true;
//     document.head.appendChild(script);

//     // Initialize the ad
//     script.onload = () => {
//       (window.adsbygoogle = window.adsbygoogle || []).push({});
//     };
//   }, []);

//   return (
//     <div className="ad-container">
//       <ins
//         className="adsbygoogle"
//         style={{ display: 'block' }}
//         data-ad-client="your-ad-client-id"
//         data-ad-slot="your-ad-slot-id"
//         data-ad-format="auto"
//       ></ins>
//     </div>
//   );
// };

// export default Popup;

// import { Close } from '@mui/icons-material';
// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';

// const VideoContainer = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
// `;
// const PlayButton = styled.button`
//   position: relative;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -250%);
//   background-color: #0074d9;
//   color: white;
//   border: none;
  
//   width: 50px;
//   height: 50px;
//   font-size: 24px;
//   cursor: pointer;
// `;
// const OfflineContent = styled.div`
//   text-align: center;
//   padding: 20px;
//   background-color: #f2f2f2;
// `;
// const VideoPlayer = ({ videoIds, onClose }) => {
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
//   const [isPlaying, setIsPlaying] = useState(true);

//   useEffect(() => {
//     const video = document.getElementById('video');

//     if (isPlaying) {
//       // Set the source of the iframe to the current video URL and play it.
//       video.src = `https://www.youtube.com/embed/${videoIds[currentVideoIndex]}?autoplay=1`;
//     } else {
//       // Pause the video when not playing.
//       video.src = `https://www.youtube.com/embed/${videoIds[currentVideoIndex]}`;
//     }

//     const handleVideoEnd = () => {
//       if (currentVideoIndex < videoIds.length - 1) {
//         setCurrentVideoIndex(currentVideoIndex + 1);
//       } else {
//         // All videos have been played, close the player.
//         onClose();
//       }
//     };

//     video.addEventListener('ended', handleVideoEnd);

//     return () => {
//       video.removeEventListener('ended', handleVideoEnd);
//     };
//   }, [currentVideoIndex, isPlaying, videoIds, onClose]);

//   const handleTogglePlay = () => {
//     setIsPlaying(!isPlaying);
//   };

//   return (
//     <VideoContainer>
//       <button onClick={onClose} style={{color:"red",float:"right"}}><Close/></button>
//       {!navigator.onLine ? (
//         <OfflineContent>
//           <p>You are currently offline. Some content may not be available.</p>
//         </OfflineContent>
//       ) :(
//       <iframe 
//         id="video"
//         width="100%"
//         height="200"
//         src={`https://www.youtube.com/embed/${videoIds[currentVideoIndex]}?autoplay=1`}
//         frameBorder="0"
//         allowFullScreen
//       ></iframe>
//   )}
//     </VideoContainer>
//   );
// };
import { Close } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import internetImage from '../images/internet.avif';

const VideoContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const OfflineContent = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #f2f2f2;
`;

const ImageComponent = () => {
  return (
    <></>
  );
};

const VideoPlayer = ({ videoIds, onClose }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(true);
    };

    const handleOfflineStatus = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOfflineStatus);
    };
  }, []);

  return (
    <VideoContainer>
      <button onClick={onClose} style={{ color: "red", float: "right" }}><Close /></button>
      
      {!isOnline ? (
        <OfflineContent>
          
          <p><span role="img" aria-label="Warning" style={{ fontSize: '35px' }}>⚠️</span>You are currently offline. Some content may not be available.</p>
          <div>
          

      <img
        src={internetImage}
        alt="An awesome image"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      
    </div>
        </OfflineContent>
      ) : (
        <div>
          <iframe
            id="video"
            width="100%"
            height="300"
            src={`https://www.youtube.com/embed/${videoIds[0]}?autoplay=1`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )}

      {!isOnline && <ImageComponent />}
    </VideoContainer>
  );
};

function Adsvid() {
  const [isPlayerOpen, setIsPlayerOpen] = useState(true);

  const handleClosePlayer = () => {
    setIsPlayerOpen(false);
  };

  const videoIds = ['rzsdHX4xAc4?si=cOOi8xgSkWhWAk7f', 'pxn0wL_uSm4?si=16dNgpGUFYfS1KHd'];

  return (
    <div>
      {isPlayerOpen && <VideoPlayer videoIds={videoIds} onClose={handleClosePlayer} />}
    </div>
  );
}

export default Adsvid;
