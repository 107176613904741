// import React, { useContext, useEffect, useRef, useState } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import { Checkbox } from "antd";
// import Payments from "../Payment/Payments";
// import "./AddProducts.css";
// import validator from "validator";
// import { Image, Container, Row, Col } from "react-bootstrap";
// import "../Allads/ImageUpload/ImageUpload.css";
// import { FaCut, FaAdn } from "react-icons/fa";
// import "./AddProducts.css";
// import { DatePicker, Space, Select, Spin, Alert } from "antd";
// import { CheckCircleFilled } from "@ant-design/icons";
// import BiCurrentLocation from "react-icons/bi";
// import { useGeolocated } from "react-geolocated";
// import { useParams } from "react-router-dom";
// import { Button, Modal } from "antd";
// import {
//   Category,
//   Electronics,
//   Furniture,
//   Pets,
//   Cars,
//   Bikes,
//   Services,
//   Mobiles,
//   localUrl,
//   Workinghours,
//   WashingMachineBrand,
//   subcategoryRealEstateBuy,
//   subcategoryRealEstateRent,
//   subcategoryType1,
//   url,
//   Engine,
//   Year,
//   Jobs,
//   Education,
//   countryOptions,
//   colorOptions,
//   fruitOptions,
//   careerStream,
//   deliveryMode,
//   careerDomain,
//   deliveryMode1,
//   listedBy,
//   programType,
//   deliveryMode2,
//   careerStream2,
//   deliveryMode3,
//   hobby,
//   eligibility,
//   classesHeldOn,
//   classTimings,
//   programsOffered,
//   educationMethod,
//   minAge,
//   maxAge,
//   studentTeacherRacFat,
//   schoolBoard,
//   subjects,
//   standard,
//   deliveryMode5,
//   country,
//   servicesOffered,
//   educationStream,
//   productType1,
//   industry,
//   keyFeatures,
//   YearOfRegistrationCar
// } from "../../env";
// import { stateMain } from "../../Location";
// import { decrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
// import { isMobile } from "react-device-detect";
// import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

// // import Success from "../../Pages/success/success";
// // import ReactDOM from 'react-dom';
// // import $ from 'jquery';
// // import ImageUpload from "./ImageUpload/ImageUpload";
// // import { SignalWifiStatusbarNullSharp } from "@mui/icons-material";
// // import Spiner from "../../Spiner";
// import MultiImageInput from "react-multiple-image-input";
// import { CreateAds } from "../../../error/errorMessage";
// import { TbPlayerTrackNext } from "react-icons/tb"
// import { UserContext } from "../../../App";
// import GoogleAutoComplteforAddProduct from "../../Home/GoogleAutoComplte/GoogleAutoComplteforAddProduct";
// import { useDispatch, useSelector } from "react-redux";
// import { add } from "../../../store/Track/trackUserSlice";
// import { addActivePlan } from "../../../store/activePlanSlice";
// import SubcategoryCommonInput from "../../../Shared/SubCategory/subcategoryCommonInput";
// import { subCategoryInput } from "../../../Model/SubCategoryCons";
// import "antd/dist/antd.css";
// import { HolaAutoComplete } from "../components/HolaAutoComplete";
// import { LocalSeeRounded } from "@mui/icons-material";
// import { getUserPlan } from "../../../store/allPlanDetails";
// import axios from "axios";
// import { BsExclamationTriangle } from "react-icons/bs";
// import { isNull } from "lodash";
// import { IoVideocamOutline } from "react-icons/io5";
// // import Service1 from "./Service1";

// const array = [];
// console.log("!!! 747 array,", array);

// const mobileStyle = {
//   width: "100%",
//   // margin: "5px",
//   justifyContent: "center",
// };
// const desktopStyle = {
//   width: 300,
//   //  margin: "5px",
// };
// const mobileStyle1 = {
//   padding: "1px",
// };
// const desktopStyle1 = {
//   padding: "50px",
//   //  margin: "5px",
// };
// const mobstyle = {
//   display: "flex",
//   flexDirection: "column",
//   marginLeft: "15px",
//   marginTop: "35px",
//   gap: "30px",
// };
// const deskstyle = {
//   display: "flex",
//   flexDirection: "row",
//   gap: "20%",
//   marginLeft: "20px",
//   marginTop: "35px",
// };
// const { Option } = Select;
// const Addservices = (props) => {

//   // geocode
//   const UserData = useContext(UserContext);
//   const [address, setAddress] = useState();
//   const [addressObj, setAddressObj] = useState();
//   const [selectedLocation, setSelectedLocation] = useState("");

//   const getCityName = (address_components) => {
//     let city = "";
//     address_components.forEach((component) => {
//       if (component.types.includes("locality")) {
//         city = component.long_name;
//       }
//     });
//     return city;
//   };

//   useEffect(() => {
//     const detectUserLocation = () => {
//       if ("geolocation" in navigator) {
//         navigator.permissions
//           .query({ name: "geolocation" })
//           .then((permissionStatus) => {
//             if (permissionStatus.state === "granted") {
//               navigator.geolocation.getCurrentPosition(
//                 async (position) => {
//                   const { latitude, longitude } = position.coords;
//                   const geocoder = new window.google.maps.Geocoder();
//                   const latlng = { lat: latitude, lng: longitude };

//                   geocoder.geocode({ location: latlng }, async (results, status) => {
//                     if (status === "OK" && results[0]) {
//                       setAddress({ label: getCityName(results[0].address_components), value: { place_id: results[0].place_id } });
//                     }
//                   });
//                 },
//                 (error) => {
//                   console.error("Error getting user location:", error);
//                 }
//               );
//             } else if (permissionStatus.state === "prompt") {
//               navigator.geolocation.getCurrentPosition(
//                 () => {
//                   detectUserLocation();
//                 },
//                 (error) => {
//                   console.error("Error getting user location:", error);
//                 }
//               );
//             }
//           })
//           .catch((error) => {
//             console.error("Error checking geolocation permission:", error);
//           });
//       } else {
//         console.error("Geolocation not available in this browser.");
//       }
//     };

//     // Automatically detect user's location when the component mounts
//     detectUserLocation();
//   }, []);

//   useEffect(() => {
//     const func = async () => {
//       const geocodeObj =
//         address &&
//         address.value &&
//         (await geocodeByPlaceId(address.value.place_id));
//       UserData.setsearchBoxLocaity(address?.label);
//       const addressObject =
//         geocodeObj && getCityName(geocodeObj[0].address_components);

//       setAddressObj(addressObject);
//     };
//     func();
//   }, [address]);

//   useEffect(() => {
//     // Update the selected location whenever the address changes
//     setSelectedLocation(address?.label || "");
//   }, [address]);
//   // geo end
//   const disptach = useDispatch();
//   useEffect(() => {
//     disptach(add({ view: ["contactForm"] }));
//   }, []);

//   //loading1 button
//   //  const [loading1s, setloadings] = useState<boolean[]>([]);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const [visible, setVisible] = useState(false);
//   // const [style1, setStyle1] = useState({color:"red!important"});
//   // const [style2, setStyle2] = useState("cont");
//   // const [buttoncheck,setbuttoncheck]=useState(null)

//   // after cgha
//   const handleChange = (value) => {
//     console.log(`selected ${value}`);
//   };

//   const dataUser = useContext(UserContext);

//   const crop = {
//     // unit: "%",
//     // aspect: 6 / 6,
//     width: "auto",
//     height: "auto",
//   };

//   const { categories } = useParams();

//   const [rentErr, setRentErr] = useState(false);
//   const [typeErr, setTypeErr] = useState(false);
//   const [newError, setNewError] = useState(false);
//   const [cityErr, setCityErr] = useState(false);
//   const [catErr, setCatErr] = useState(false);
//   const [subCat, setSubCat] = useState(false);
//   const [afterSubmitFlg, setAfterSubmitFlg] = useState(false);
//   const [images, setImages] = useState({});
//   const [imageError, setImageError] = useState(false);
//   const [id, setId] = useState();
//   const [image, setImage] = useState(null);
//   const [title, setTitle] = useState("");
//   const [titleError, setTitleError] = useState(false);

//   const [price, setPrice] = useState("");
//   const [priceError, setPriceError] = useState(false);
//   const [jobprice, setJobPrice] = useState(10);
//   const [jobtags, setJobTags] = useState("asa");
//   const [tags, setTags] = useState("");
//   const [description, setDescription] = useState("");
//   const [category, setCategory] = useState(categories); //13
//   // const [subcategory, setSubcategory] = useState("") //13
//   const [subcategoryForAll, setSubcategoryForAll] = useState("");
//   const [brand, setBrand] = useState("");
//   const [brandError, setBrandError] = useState(false);
//   const [condition, setCondition] = useState("Good");
//   const [state, setState] = useState("");
//   const [stateError, setStateError] = useState(false);
//   const [city, setCity] = useState(null);
//   const [cityError, setCityError] = useState(false);
//   const [locality, setLocality] = useState("");
//   const [localityError, setLocalityError] = useState(false);
//   const [cordinates, setcordinates] = useState("");
//   const [zipcode, setZipcode] = useState("");
//   const [zipcodeError, setZipcodeError] = useState(false);
//   const [date, setDate] = useState("");
//   const [dateError, setDateError] = useState(false);
//   const [active, setActive] = useState("DashAds");
//   const [loading1, setloading1] = useState(false);
//   //loader
//   const [isLoading, setIsLoading] = useState(false);
//   const [success, setSuccess] = useState("");
//   const [error, setError] = useState(null);
//   const [storeadsFlag, setstoreadsFlag] = useState(false);
//   const [realEsateSubCategory, setrealEsateSubCategory] = useState(null);

//   const [subCategoryValue, setsubCategoryValue] = useState(null); //28
//   const [selectedCountry, setSelectedCountry] = useState(null);
//   const [selectedColor, setSelectedColor] = useState(null);
//   const [selectedFruit, setSelectedFruit] = useState(null);

//   const [subCategoryType, setsubCategoryType] = useState(null);
//   const userDataValue = localStorage.getItem("userdata")
//     ? decrypt("userdata")
//     : null;
//   const [successvalue, setSuccessValue] = useState(false);
//   const [errorvalue, setErrorValue] = useState(false);
//   const [message, setMessage] = useState(null);
//   const [MoreImage, setMoreImage] = useState(false);
//   const [phoneNumber, setphoneNumber] = useState("");
//   const [subCategoryError, setSubCategoryError] = useState(false);
//   const [descriptionError, setDescriptionError] = useState(false);

//   // const [lat,setLat]=useState(false)
//   // const [long,setLong]=useState(false)
//   const [lat, setLat] = useState(false);
//   const [long, setLong] = useState(false);
//   ///category real esate

//   const [BuildUpArea, setBuildUpArea] = useState(null);
//   const [Flor, setFlor] = useState(null);
//   const [ApartMentType, setApartMentType] = useState(null);
//   const [Availability, setAvailability] = useState(null);
//   const [FurnishedType, setFurnishedType] = useState(null);
//   const [Property, setProperty] = useState(null);
//   const [Parking, setParking] = useState(null);
//   const [PowerBackup, setPowerBackup] = useState(null);
//   const [Gym, setGym] = useState(null);
//   const [Garden, setGarden] = useState(null);
//   const [Pool, setPool] = useState(null);
//   const [Lift, setLift] = useState(null);

//   const [formDisable, setFormDisable] = useState(true);
//   // when ads limit over

//   const [adsLimitCheck, setAdsLimitCheck] = useState(false);
//   const [currentSelectedplan, setcurrentSelectedplan] = useState(null);

//   //label change code
//   const [labelBrand, setLableBrand] = useState("Brand");
//   useEffect(() => {
//     setLableBrand(subCategoryValue === "Animal" ? "Breed" : "Brand");
//   }, [subCategoryValue]);
//   const [colorCheck, setcolor] = useState(null);
//   const [sizeCheck, setsize] = useState(null);

//   const [oldPetsCheck, setoldPets] = useState(null);

//   const cancelHandler = () => {
//     setOpenNewModal2((prevState) => !prevState);
//   }

//   // state error
//   //checkbox

//   var planCategory =
//     dataUser?.activePlan[dataUser?.activePlan?.length - 1]?.fields?.category;

//   // all ads data

//   const [allAdsCount, setAllAdsCount] = useState([]);

//   // userPlan

//   const { loading, data } = useSelector((state) => state.planData);

//   console.log("~~~databyplan187", data);

//   // for change plan
//   const [updateAds_left, setUpdateAds_left] = useState(null);
//   const [updateCategory, setUpdateCategory] = useState(null);
//   const [updateFeatured_ads, setUpdateFeatured_ads] = useState(null);
//   const [updateAds_limit, setUpdateAds_limit] = useState(null);
//   const [updateTop_listing, setUpdateTop_listing] = useState(null);
//   const [updateSupport, setUpdateSupport] = useState(null);
//   const [updateAds_timimg, setUpdateAds_timimg] = useState(null);
//   let userid = localStorage.getItem("userid");
//   // let userid=localStorage.getItem("userid")
//   // aminities checkbox value
//   const [coordinates, setCoordinates] = useState('');
//   const [aminities, setAminities] = useState([]);

//   const companyChangeHandler = (e) => {
//     setcompanyname(e.target.value)
//     setCompanyNameError(false);
//   }



//   const areaCoveredHandler = (e) => {
//     setareacovered(e.target.value);
//     setareacoveredError(false);
//   }

//   const cityservicedHandler = (e) => {
//     setcityserviced(e.target.value);
//     setcityservicedError(false)
//   }

//   const emailChangeHandler = (e) => {
//     setemail(e.target.value);
//     setEmailError(false);
//   }

//   const descriptionChangeHandler = (e) => {
//     setDescription(e.target.value);
//     setDescriptionError(false);
//   }

//   const websiteChangeHandler = (e) => {
//     setwebsite(e.target.value);
//     setWebsiteError(false);
//   }

//   const noOfWorkingHoursHandler = (e) => {
//     setworkinghours(e.target.value);
//     setWorkingHoursError(false);
//   }

//   const NoOfEmployeesHandler = (e) => {
//     setnoofemp(e.target.value);
//     setnoofempError(false);
//   }

//   const issueChangeHandler = (e) => {
//     setissuehandled(e.target.value);
//     setissuehandledError(false);
//   }

//   const localityChangeHandler = (e) => {
//     setLocality(e.target.value);
//     setLocalityError(false);
//   }

//   const zipcodeHandler = (e) => {
//     setZipcode(e.target.value);
//     setZipcodeError(false);
//   }

//   window.onload = () => {
//     getLocation();
//   };

//   const getLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(showPosition, showError);
//     } else {
//       alert("Geolocation is not supported by this browser.");
//     }
//   };

//   // const MyComponent = () => {
//   useEffect(() => {
//     getLocation();
//   }, []);

//   const showPosition = (position) => {
//     const latitude = position.coords.latitude;
//     const longitude = position.coords.longitude;
//     const coordinatesInput = document.getElementById("coordinatesInput");

//     // Update the input field with the latitude and longitude
//     coordinatesInput.value = `${latitude}, ${longitude}`;
//     setCoordinates(`${latitude}, ${longitude}`);
//     setLat(`${latitude}`);
//     setLong(`${longitude}`);
//   };


//   const showError = (error) => {
//     switch (error.code) {
//       case error.PERMISSION_DENIED:
//         alert("User denied the request for Geolocation.");
//         break;
//       case error.POSITION_UNAVAILABLE:
//         alert("Location information is unavailable.");
//         break;
//       case error.TIMEOUT:
//         alert("The request to get user location timed out.");
//         break;
//       case error.UNKNOWN_ERROR:
//         alert("An unknown error occurred.");
//         break;
//     }
//   };

//   console.log("aminites", aminities);
//   let dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(getUserPlan(userid));
//   }, []);
//   const getData = async () => {
//     let headersList = {
//       Accept: "*/*",
//     };

//     let bodyContent = new FormData();
//     bodyContent.append("user", userid);

//     let response = await fetch(localUrl + "adsapi/allPlanData", {
//       method: "POST",
//       body: bodyContent,
//       headers: headersList,
//     });
//     let data = await response.json();
//     setAllAdsCount(data);
//   };
//   const [updateState, setUpdateState] = useState(null);
//   const [cityWithState, setCityWithState] = useState(null);
//   useEffect(() => {
//     Object.keys(stateMain)?.filter((val) => {
//       if (val === updateState) {
//         setCityWithState(stateMain[val]);
//       }
//     });
//   }, [updateState]);
//   useEffect(() => {
//     getData();
//   }, []);

//   const [dataByPlan, setDataByplan] = useState([]);
//   const [plan, setPlan] = useState("");
//   const [adsCategory, setAdsCategory] = useState("");
//   const [usertype, setusertype] = useState('');
//   const [dayslimit, setDaysLimit] = useState(null);
//   const [planCategory1, setPlanCategory] = useState(null);
//   const [planType, setPlanType] = useState(null);
//   const [expireData, setExpireDate] = useState(null);
//   // error for without choosing plan
//   const [planError, setPlanError] = useState(false);
//   //set new loader
//   const [newLoader, setNewLoader] = useState(false);

//   // set New modal
//   const [openNewModal, setOpenNewModal] = useState(false);
//   const [openNewModal2, setOpenNewModal2] = useState(false);
//   const handleeOk = () => {
//     setOpenNewModal2(false);
//   };

//   const handleeCancel = () => {
//     setOpenNewModal2(false);
//   };

//   useEffect(() => {
//     if (plan !== "") {
//       setAdsCategory(data?.postAdsForm[plan]?.category[0]);
//     }
//   }, [plan]);

//   useEffect(() => {
//     setCity(null);
//   }, [state]);

//   useEffect(() => {
//     setsubCategoryValue(null);
//   }, [category]);

//   const [business, setBusiness] = useState(false); // Initialize with null
//   const [leftPlan, setLeftPlan] = useState([]); // Initialize with null
//   // const dataUser = useContext(UserContext);

//   useEffect(() => {
//     var formdata = new FormData();
//     formdata.append("userid", dataUser.userid);

//     var requestOptions = {
//       method: "POST",
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(url + "api/adsapi/checkBusinessPlan", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         setBusiness(result.businessPlan);
//         setLeftPlan(result.leftPlan);
//         console.log(result, "resvaerarasrar");
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, []);

//   useEffect(() => {
//     if (adsCategory !== "" && plan !== "") {
//       let days;
//       if (plan === "Silver") {
//         days = 15;
//       } else if (plan === "Gold") {
//         days = 30;
//       } else if (plan === "Free") {
//         days = 7;
//       } else if (plan === "Platinum") {
//         days = 60;
//       } else if (plan === "featured") {
//         days = 90;
//       } else if (plan === "premium") {
//         days = 90;
//       }
//       setDaysLimit(days);
//       setPlanCategory(adsCategory);
//       setPlanType(plan);
//       setExpireDate(false);
//       setPlanError(false);
//     }
//   }, [adsCategory]);

//   useEffect(() => {
//     console.log(data.leftPlan, "jj");
//     var today = new Date();

//     var datevalue =
//       today.getFullYear() +
//       "-" +
//       (today.getMonth() + 1) +
//       "-" +
//       today.getDate();
//     console.log(datevalue);

//     setDate(datevalue);
//   }, []);

//   const navigate = useNavigate();

//   if (localStorage.getItem("access_token") == null) {
//     navigate("/login/");
//   }

//   const CategoryList = Category;
//   const coords = useGeolocated();
//   const handlePay = () => {
//     let formdata = {};
//     formdata["title"] = title;
//     formdata["price"] = price;
//     formdata["tags"] = tags;
//     formdata["description"] = description;
//     formdata["category"] = category;
//     formdata["subCategoryValue"] = subCategoryValue;
//     formdata["brand"] = brand;
//     formdata["condition"] = condition;
//     formdata["state"] = state;
//     formdata["city"] = city;
//     formdata["locality"] = dataUser?.locality;
//     formdata["zip_code"] = zipcode;
//     formdata["user"] = id;

//     if (image !== null) {
//       formdata["image"] = image;
//     }
//     console.log("checking value", formdata);
//     localStorage.setItem("payAdsData", JSON.stringify(formdata));
//   };
//   const name = userDataValue?.name;
//   const selectShortlistedApplicant = (e) => {
//     const checked = e.target.checked;
//     const value = e.target.value;

//     setrealEsateSubCategory(value);
//     if (checked) {
//       console.log("checkined");
//     } else {
//       // setfeatureTrue(null)
//       console.log("//unchecked");
//     }
//   };
//   const [categorysubCategoryFlag, setcategorysubCategoryFlag] = useState(false);
//   // if(category=="RealEstate"){
//   //   setcategorysubCategoryFlag(true)
//   // }
//   // const [lat ,setLat] =useState(null)
//   // const [long ,setLong]=useState(null)
//   const selector = useSelector((state) => state.activePlan);
//   const user1 = useContext(UserContext);
//   useEffect(() => {
//     console.log("##pricing plan ,selector ", selector, user1.pricing);
//     if (parseInt(dataUser?.activePlan) <= 0) {
//       setFormDisable(false);
//     }
//   }, [selector]);

//   const selectorExtraSlice = useSelector(
//     (state) => state.adsPostingExtraFiledSlice
//   );
//   useEffect(() => {
//     console.log("#### checking add product ", selectorExtraSlice);
//   }, [selectorExtraSlice]);
//   useEffect(() => {
//     if (true) {
//       var formdata = new FormData();
//       formdata.append("user", localStorage.getItem("userid"));

//       var requestOptions = {
//         method: "POST",
//         body: formdata,
//         redirect: "follow",
//       };

//       fetch(localUrl + "adsapi/getPricingViews", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           console.log("## pricing service response result", result);
//           disptach(addActivePlan({ plan: result }));
//           dataUser.setActivePlan(result);
//         })
//         .catch((error) => console.log("error", error));
//     }
//     console.log("###value user active plan", dataUser?.activePlan);
//   }, []);
//   // useEffect(() => {
//   //   getLocationSearchMethod();
//   // }, []);
//   const getLocationSearchMethod = () => {
//     const options = {
//       method: "GET",
//       url: "https://google-maps-geocoding.p.rapidapi.com/geocode/json",
//       params: {
//         address: dataUser?.locality,
//         language: "en",
//       },
//       headers: {
//         "X-RapidAPI-Key": "7fded38e7emsh3c4fb60f3b8017cp1c084bjsn32180c499f5f",
//         "X-RapidAPI-Host": "google-maps-geocoding.p.rapidapi.com",
//       },
//     };

//     axios
//       .request(options)
//       .then(function (response) {
//         console.log(response.data);

//         console.log(
//           "lat long ",
//           response.data.results[0].geometry.location.lat,
//           response.data.results[0].geometry.location.lng
//         );

//         setLat(response.data.results[0].geometry.location.lat);
//         setLong(response.data.results[0].geometry.location.lng);

//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   };

//   const qrcodeGenrate = (props) => {
//     console.log("|datauser vsalue", dataUser?.activePlan);
//     var myHeaders = new Headers();
//     myHeaders.append(
//       "Authorization",
//       "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkxMTQ1MzkwLCJpYXQiOjE2NTk2MDkzOTAsImp0aSI6IjM5MzJjZjJkZTYyNTQwMjRiNTEyZDk4ZTE4ZDM1Mjk3IiwidXNlcl9pZCI6MjJ9.6uTC3ZTlxdqyhBewkVN5O4MduQ6O6YCNB6p9QJhOF3w"
//     );

//     var formdata = new FormData();
//     formdata.append("product", props);

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(url + "api/user/qrCodeAds", requestOptions)
//       .then((response) => response.text())
//       .then((result) => console.log(result))
//       .catch((error) => console.log("error", error));
//   };

//   const imageUpload = (props) => {
//     setloading1(true);
//     setNewLoader(true);
//     qrcodeGenrate(props);
//     console.log("WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW", images);
//     for (var file in images) {
//       console.log(images[file]);

//       var formdata = new FormData();
//       formdata.append("imageList", images[file]);
//       formdata.append("adsId", props);

//       var requestOptions = {
//         method: "POST",
//         body: formdata,
//         redirect: "follow",
//       };
//       fetch(localUrl + `adsapi/adsUpload`, requestOptions)
//         .then((response) => response.text())
//         .then((result) => {
//           setloading1(true);
//           setNewLoader(false);
//           console.log(result);
//           setOpenNewModal(true);
//           // navigate(`/ads-listing/${props}/`);
//         })
//         .catch((error) => {
//           setloading1(true);
//           setNewLoader(false);
//           setError("Somethig went wrong..");
//         });
//     }
//     setloading1(false);
//   };

//   const handleInputChange = () => {
//     setAfterSubmitFlg(false);
//   };

//   const [ErrorExtraField, setErrorExtraField] = useState(false);
//   useEffect(() => {
//     if (category === "Jobs") {
//       setPrice(jobprice);
//       setTags(jobtags);
//     } else {
//       setPrice("");
//       setTags("");
//     }
//   }, [category]);
//   const handlebrandname = (value) => {
//     setBrand(value);
//     setBrandError(false);
//   };

//   const dateChangeHandler = (e) => {
//     setDate(e.target.value);
//     setDateError(false);
//   }

//   const aboutServicesHandler = (e) => {
//     setaboutservices(e.target.value);
//     setaboutservicesError(false);
//   }

//   const [companyname, setcompanyname] = useState('');
//   const [companyNameError, setCompanyNameError] = useState(false);
//   const [email, setemail] = useState('');
//   const [emailError, setEmailError] = useState(false);

//   const [website, setwebsite] = useState('');
//   const [websiteError, setWebsiteError] = useState(false);

//   const [workinghours, setworkinghours] = useState('');
//   const [workingHoursError, setWorkingHoursError] = useState(false);

//   const handleworkinghour = (value) => {
//     setworkinghours(value);
//     setWorkingHoursError(false);
//   };

//   const [noofemp, setnoofemp] = useState('');
//   const [noofempError, setnoofempError] = useState(false);
//   const [bussinesssince, setbussinesssince] = useState('');
//   const [businesssinceError, setbusinesssinceError] = useState(false);

//   const handlebussinesssince = (value) => {
//     setbussinesssince(value);
//     setbusinesssinceError(false);
//   };

//   const titleChangeHandler = (e) => {
//     setTitle(e.target.value);
//     setTitleError(false);
//   }

//   const [issuehandled, setissuehandled] = useState('');
//   const [issuehandledError, setissuehandledError] = useState(false);
//   const [aboutservices, setaboutservices] = useState('');
//   const [aboutservicesError, setaboutservicesError] = useState(false);
//   const [areacovered, setareacovered] = useState('');
//   const [areacoveredError, setareacoveredError] = useState(false);
//   const [cityserviced, setcityserviced] = useState('');
//   const [cityservicedError, setcityservicedError] = useState(false);


//   const addNewProduct = async () => {
//     setNewLoader(true);

//     setIsLoading(true);
//     var aKeys = Object.keys(finalObj).sort();
//     var bKeys = Object.keys(obj).sort();

//     if (!(JSON.stringify(aKeys) === JSON.stringify(bKeys))) {
//       setErrorExtraField(true);
//       setAdsLimitCheck(true);
//       setIsLoading(false);
//     } else {
//       setErrorExtraField(false);
//       setstoreadsFlag(true);

//       if (
//         expireData ||
//         !("0" in images) ||
//         !title ||
//         // !tags ||
//         !city ||
//         !description ||
//         !category ||
//         !state ||
//         !usertype ||
//         !price ||
//         // !dataUser?.locality ||
//         !zipcode ||
//         zipcode.length < 6 ||
//         !phoneNumber ||
//         phoneNumber.length < 10
//       ) {
//         setAfterSubmitFlg(true);
//         setError("* Please fill this field..");
//         setNewLoader(false);
//         setIsLoading(false);
//       } else if (!data.leftPlan.length) {
//         console.log("hgh");
//         showModal();
//         setIsLoading(false);
//       } else {
//         let formdata = new FormData();
//         if (true) {
//           setNewLoader(false);
//           for (var file in images) {
//             formdata.append("image", images[file]);
//             console.log("~~~image");
//             break;
//           }
//         }

//         var myHeaders = new Headers();
//         myHeaders.append(
//           "Authorization",
//           "Bearer " + localStorage.getItem("access_token")
//         );

//         formdata.append("title", title);

//         formdata.append("price", price);
//         // formdata.append("tags", tags);
//         formdata.append("description", description);
//         formdata.append("category", category);
//         formdata.append("brand", brand);
//         formdata.append("Engine", Engine);
//         formdata.append("Year", Year);
//         formdata.append("condition", condition);
//         formdata.append("state", state);
//         formdata.append("City", city);
//         formdata.append("locality", locality);
//         // formdata.append("locality", dataUser?.locality);
//         // formdata.append("locality", dataUser?.locality);
//         // formdata.append("locality","kihash");
//         formdata.append("zip_code", zipcode);
//         formdata.append("user", userDataValue.id);
//         formdata.append("subCategoryType", subCategoryType);
//         formdata.append("subCategoryValue", subCategoryValue);
//         formdata.append("lati", lat);
//         formdata.append("long", long);
//         formdata.append("phoneNumber", phoneNumber);
//         formdata.append("BuildUpArea", BuildUpArea);
//         formdata.append("Flor", Flor);
//         formdata.append("ApartMentType", ApartMentType);
//         formdata.append("Availability", Availability);
//         formdata.append("FurnishedType", FurnishedType);
//         formdata.append("Property", Property);
//         formdata.append("Parking", Parking);
//         formdata.append("PowerBackup", PowerBackup);
//         formdata.append("Gym", Gym);
//         formdata.append("Garden", Garden);
//         formdata.append("Pool", Pool);
//         formdata.append("Lift", Lift);
//         formdata.append("colorCheck", colorCheck);
//         formdata.append("sizeCheck", sizeCheck);
//         formdata.append("oldPetsCheck", oldPetsCheck);
//         // for chat purpose
//         formdata.append("uuid", localStorage.getItem("uuid"));
//         formdata.append("date_created", date);
//         formdata.append("PlanCategory", updateCategory);
//         formdata.append("featured_ads", updateFeatured_ads);
//         formdata.append("ads_limit", updateAds_limit);
//         formdata.append("ads_timing", updateAds_timimg);
//         formdata.append("top_listing", updateTop_listing);
//         formdata.append("support", updateSupport);
//         formdata.append("user_type", usertype);

//         formdata.append("adsType", planCategory1);
//         formdata.append("plan", planType);
//         formdata.append("DaysLimit", dayslimit);
//         formdata.append("expiry", expireData);
//         // dayslimit, planCategory1, planType, expireData
//         const dataToSend = {
//           companyname: companyname,
//           email: email,
//           address: website,
//           working: workinghours,
//           employee: noofemp,
//           Since: bussinesssince,
//           issuehandled: issuehandled,
//           aboutservices: aboutservices,
//           areacovered: areacovered,
//           cityserviced: cityserviced,
//           finalObj
//         };

//         formdata.append("extraField", JSON.stringify(dataToSend));

//         var requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: formdata,
//           redirect: "follow",
//         };
//         setloading1(true);
//         fetch(url + "api/adsapi/", requestOptions)
//           .then((response) => response.json())
//           .then((result) => {
//             setNewLoader(false);
//             setSuccessValue(true);
//             setErrorValue(false);
//             if (result?.detail) {
//               setErrorValue(true);
//               setSuccessValue(false);
//               alert("token expire");
//               localStorage.removeItem("access_token");
//               navigate("/");
//               setloading1(false);
//             }
//             console.log("result", result);
//             if (result.status !== "OK" && result?.DaysLimit && result?.expiry) {
//               setPlanError(true);
//             } else if (!result?.id) {
//               alert("Ads not posted");
//             } else {
//               imageUpload(result.id);
//             }
//             setIsLoading(false);
//             console.log(result);
//           })
//           .catch((error) => {
//             //isloading false
//             setIsLoading(false);
//             setloading1(false);
//             setError("Something went wrong! Please come after Sometimes..");
//             setErrorValue(true);
//             setSuccessValue(false);
//             setNewLoader(false);
//             console.log("error", error);
//           });
//       }
//       setstoreadsFlag(false);
//       setloading1(false);
//       // setIsLoading(false);
//     }
//   };
//   const [files, setFiles] = useState([]);
//   ///function for multiple image upload
//   const fileSelectedHandler = (e) => {
//     setImageError(false);
//     console.log("imag efiles that one", e.target.files);
//     files.push(e.target.files[0]);
//     setFiles(files);
//     console.log("!!!!!!!!!!!state", files);
//   };

//   useEffect(() => {
//     console.log("@@@@@  datause.locality", dataUser?.locality);
//     if (dataUser?.locality) {
//       getLocationSearchMethod();
//     }
//   }, [dataUser?.locality]);
//   useEffect(() => {
//     var today = new Date();
//     var datevalue =
//       today.getFullYear() +
//       "-" +
//       (today.getMonth() + 1) +
//       "-" +
//       today.getDate();
//     console.log(datevalue);
//     setDate(datevalue);
//   }, []);

//   //for adding multiple values their

//   const [finalObj, SetFinalObj] = useState({});
//   const [obj, setObj] = useState({});
//   const [extraFeildToggle, setExtraFeildToggle] = useState(false);
//   useEffect(() => {
//     if (subCategoryValue && subCategoryInput[category] !== undefined) {
//       let subcategoryy = subCategoryInput[category];
//       if (subcategoryy[subCategoryValue] !== undefined) {
//         setExtraFeildToggle(true);
//         let values = subcategoryy[subCategoryValue];
//         let obj1 = values;
//         Object.keys(obj1).forEach((key) => {
//           let varkey = [];
//           varkey = obj1[key].map((result) => {
//             if (typeof result === "object" && result !== null) {
//               return result;
//             } else {
//               return { value: result };
//             }
//           });
//           obj1[key] = varkey;
//         });

//         console.log(obj1, subcategoryy, subcategoryy[subCategoryValue]);
//         setObj(obj1);
//       } else {
//         setExtraFeildToggle(false);
//       }
//     } else {
//       setExtraFeildToggle(false);
//     }
//   }, [subCategoryValue]);

//   useEffect(
//     (e) => {
//       console.log("!!!category changes");
//       // setsubCategoryType(e.currentTarget)
//     },
//     [category]
//   );
//   const onChangeplan = (e) => {
//     setcurrentSelectedplan(e.target.value);
//     console.log(`checked = ${e.target.value}`);
//   };
//   console.log("obj", obj);
//   const style = {
//     position: "fixed",
//     top: "50%",
//     left: "60%",
//     transform: "translate(-50%, -50%)",
//   };
//   document.title = "Hola9 - AddProducts";
//   const [change, setChange] = useState("1");
//   const [changeState, setChangeState] = useState("2");
//   const [changeCity, setChangeCity] = useState("1");
//   const [changeSubCat, setChangeSubCat] = useState("1");
//   const [subCateReal, setSubCateReal] = useState("1");
//   const [subCateRealType, setSubCateRealType] = useState("1");
//   const [showSecondSection, setShowSecondSection] = useState(false);
//   const [showThirdSection, setShowThirdSection] = useState(false);
//   const [otpverifychecklocal, setotpverifychecklocal] = useState(null);
//   const [PhoneNumbertemp, setPhoneNumbertemp] = useState(true);

//   const toggleSecondSection = () => {
//     setShowSecondSection((prevState) => !prevState);
//   };

//   const toggleSecondSection1 = () => {
//     setOpenNewModal2((prevState) => !prevState);
//   };
//   localStorage.setItem("number", phoneNumber);
//   console.log(
//     "lcaolp phone number",
//     localStorage.getItem("number"),
//     phoneNumber
//   );
//   const storePhoneNumer = (phone) => {
//     var formdata = new FormData();
//     formdata.append("phoneNubmer", phone);

//     var requestOptions = {
//       method: "POST",
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(localUrl + "adsapi/collectVisitPhoneNumber", requestOptions)
//       .then((response) => response.text())
//       .then((result) => console.log(result))
//       .catch((error) => console.log("error", error));
//   };

//   const SendOtp = (e) => {
//     e.preventDefault();
//     var gen = "1231";
//     setOpenNewModal2(true);
//     if (subCategoryValue == null) {
//       setSubCategoryError(true);
//     }
//     if (Object.entries(images).length === 0) {
//       setImageError(true);
//     } else {
//       setImageError(false);
//     }
//     // console.log("phonenumber", phoneNumber);
//     setotpverifychecklocal(gen);
//     axios({
//       method: "POST",
//       url: url + "api/phone/sendOTP/",
//       data: {
//         number: phoneNumber,
//       },
//     })
//       .then((res) => {
//         if (true) {
//           if (res.data.OTPSent === true) {
//             setOpenNewModal2(true);
//             storePhoneNumer(phoneNumber);
//           } else {
//             alert("OTP Was Not sent");
//           }
//         } else {
//           alert("Enter Valid 10 Digits Number Only");
//         }
//       })
//       .catch((e) => console.log(e));
//   };

//   const [otpVeriftemp, setotpVeriftemp] = useState(true);

//   const [Otpdetails, setOtpdetails] = useState(null);
//   const [buttonshow, setbuttonshow] = useState(false);

//   const otpverifysumbit = (e) => {
//     // console.log(typeof Otpdetails);
//     console.log(Otpdetails);
//     e.preventDefault();
//     axios({
//       method: "PUT",
//       url: url + "api/phone/checkOTP/",
//       data: {
//         number: phoneNumber,
//         otp: Otpdetails,
//       },
//     })
//       .then((res) => {
//         if (res.data.status === true) {
//           //calling registe  method
//           setShowSecondSection(true);
//           setOpenNewModal2(false);
//           setotpVeriftemp(false);
//           setbuttonshow(true);
//         } else {
//           alert("Incorrect OTP");
//           // setOpenNewModal2(false);
//           // setShowSecondSection(true)
//         }
//       })
//       .catch((e) => console.log(e));
//   };

//   const ContactDetails = (e) => {
//     getLocation();
//     e.preventDefault();
//     if (companyname == '') {
//       setCompanyNameError(true);
//     }
//     if (email == '') {
//       setEmailError(true);
//     }
//     if (website == '') {
//       setWebsiteError(true);
//     }
//     if (description == '') {
//       setDescriptionError(true);
//     }
//     if (workinghours == '') {
//       setWorkingHoursError(true)
//     }
//     if (noofemp == '') {
//       setnoofempError(true)
//     }
//     if (bussinesssince == '') {
//       setbusinesssinceError(true);
//     }

//     if (companyNameError || emailError || websiteError || descriptionError || workingHoursError || noofempError || businesssinceError) {
//       setShowThirdSection(false);

//     }
//     if (!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError) {
//       setShowThirdSection(true);
//     }
//     // if(!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError){
//     //   setShowThirdSection(true);
//     // }
//   };

//   const [onevisible, setonevisible] = useState(true);
//   const [onevisible1, setonevisible1] = useState(false);
//   const [onevisible2, setonevisible2] = useState(false);
//   const [onevisible3, setonevisible3] = useState(false);
//   const [onevisible4, setonevisible4] = useState(false);

//   const handlevis1 = (e) => {
//     e.preventDefault();
//     if(subCategoryValue == null  || Object.entries(images).length === 0) {
//       setImageError(true);
//       if (Object.entries(images).length !== 0) {
//         setImageError(false);
//       }
//       setSubCategoryError(true);
//       if (subCategoryValue !== null) {
//         setSubCategoryError(false);
//     }
//   }else {
//     setonevisible(false)
//     setonevisible1(true)
//     setonevisible2(true)
//     setonevisible3(false)
//     }
//   }
//   const handlevis2 = () => {
//     setonevisible(true)
//     setonevisible1(false)
//     setonevisible3(true)
//     setonevisible2(false)
//     setonevisible4(false)

//   }
//   const handlevis3 = () => {



//     if (issuehandled == '') {
//       setissuehandledError(true)
//     }
//     if (brand == '') {
//       setBrandError(true)
//     }
//     if (aboutservices == '') {
//       setaboutservicesError(true);
//     }
//     if (areacovered == '') {
//       setareacoveredError(true);
//     }
//     if (cityserviced == '') {
//       setcityservicedError(true);
//     }
//     if (price == '') {
//       setPriceError(true);
//     }
//     if (date == '') {
//       setDateError(true);
//     }
//     if (state == '') {
//       setStateError(true);
//     }
//     if (city == '') {
//       setCityError(true);
//     }
//     if (locality == '') {
//       setLocalityError(true);
//     }
//     if (zipcode == '') {
//       setZipcodeError(true);
//     }

//     if (issuehandledError || brandError || aboutservicesError || cityservicedError || areacoveredError || cityservicedError || priceError || stateError || cityError || localityError || zipcodeError) {
//       setonevisible3(false);
//       setonevisible2(false);
//       setonevisible4(false)
//     }
//     if (!issuehandledError && !brandError && !aboutservicesError && !cityservicedError && !areacoveredError && !cityservicedError && !priceError && !stateError && !cityError && !localityError && !zipcodeError) {
//       setShowSecondSection(false)
//       setShowThirdSection(false)
//       setonevisible3(true);
//       setonevisible4(true);
//     }
//   }
//   const handlevis4 = () => {
//     setonevisible3(false)
//     setonevisible2(true)
//     setonevisible1(true)
//     setonevisible(false)
//     setonevisible4(false)
//   }

//   const handleImageUpload = (newImages) => {
//     setImages(newImages);
//     setImageError(false);
//   };


//   const [logoFile, setLogoFile] = useState(null);

//   const handleLogoFileChange = (event) => {
//     const file = event.target.files[0];
//     setLogoFile(file);
//   }; 



//   const isFormValid = () => {
//     return (
//     Category &&
//     phoneNumber.length === 10 &&
//     // disc &&
//     title &&
//     price &&
//     state &&
//     city &&
//     zipcode.length === 6 
//     );
//   };

//   const [showVideoForm, setShowVideoForm] = useState(false);

//   const handleCheckboxChange = (e) => {
//     setShowVideoForm(e.target.checked);
//   };

//   const fileInputRef = useRef(null);
//   const [uploadedVideos, setUploadedVideos] = useState([]);

//   const handleIconClick = (e) => {
//     e.preventDefault();
//     fileInputRef.current.click();
//   };

//   const handleRemoveVideo = (index) => {
//     const newUploadedVideos = [...uploadedVideos];
//     newUploadedVideos.splice(index, 1);
//     setUploadedVideos(newUploadedVideos);
//   };

//   const handleFileChange = async (e) => {
//     const files = e.target.files;
//     let validVideo = null;

//     for (let i = 0; i < files.length; i++) {
//       const video = document.createElement("video");
//       video.src = URL.createObjectURL(files[i]);

//       // Wait for metadata to be loaded
//       await new Promise((resolve) => {
//         video.onloadedmetadata = resolve;
//       });

//       // Check if video duration is less than or equal to 30 seconds
//       if (video.duration <= 30) {
//         validVideo = files[i];
//         break; // Stop checking further files once a valid video is found
//       }
//     }

//     // Update state with the valid uploaded video (clearing previous videos)
//     setUploadedVideos(validVideo ? [validVideo] : []);
//   };

//   return (
//     <div className="container shadow bg-white">
//       {/* <Spin tip="Loading..." spinning={newLoader} /> */}
//       {!formDisable && <h2 className="text-danger">Your plan has expired</h2>}

//       <fieldset disabled={!formDisable}>
//         <div>
//           <div
//             className="white mx-auto"
//             style={isMobile ? mobileStyle1 : desktopStyle1}
//           >
//             <h1 style={{ fontFamily: "serif", fontSize: "40px", fontWeight: "bold", color: "#4a4a4a" }}>Post Your <span style={{ color: "red" }}>{categories}</span> Ads Here</h1>
//             <br />

//             {onevisible1 && (
//               <div style={{ width: "100%", backgroundColor: "whitesmoke", display: "flex", justifyContent: "space-between", alignItems: "center", border: "2px dotted black" }}>
//                 <div style={{ backgroundColor: "navy", color: "white", padding: "10px 15px", borderRadius: "20px", fontWeight: "700", marginLeft: "15px" }}>1</div>
//                 <h2 style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}>Ads Details</h2>
//                 <div onClick={handlevis2} style={{ marginRight: "15px", fontSize: "25px", fontWeight: "bolder", color: "blue" }}> <TbPlayerTrackNext /> </div>
//               </div>
//             )}
//             <br></br>
//             {onevisible && (
//               <>
//                 <div className="row p-2">
//                   <div className="col-lg-12">
//                     <label>Upload Templets</label>
//                     <MultiImageInput
//                       max={10}
//                       images={images}
//                       setImages={handleImageUpload}
//                       cropConfig={{ crop, ruleOfThirds: true }}
//                       theme={{
//                         background: "#ffffff",
//                         outlineColor: "#111111",
//                         textColor: "rgba(255,255,255,0.6)",
//                         buttonColor: "#ff0e1f",
//                         modalColor: "#ffffff",
//                       }}
//                     />
//                   </div>
//                   {imageError && <p style={{ color: 'red' }}>Please Upload the Images</p>}
//                   {MoreImage ? (
//                     <div className="col-lg-8">
//                       <div>
//                         <form className="padingmanage">
//                           <input type="file" onChange={fileSelectedHandler} />
//                         </form>
//                         <Container>
//                           <Row>
//                             {files.map((result) => {
//                               return (
//                                 <Col xs={2} md={3}>
//                                   {" "}
//                                   <a alt="" href="" className="block-icon">
//                                     <Image
//                                       src={URL.createObjectURL(result)}
//                                       thumbnail
//                                     />

//                                     <FaCut
//                                       className=" icon-tag"
//                                       onClick={(e) => {
//                                         e.preventDefault();
//                                         // this.removeItem(result)
//                                       }}
//                                     />
//                                   </a>{" "}
//                                 </Col>
//                               );
//                             })}
//                           </Row>
//                         </Container>
//                       </div>
//                     </div>
//                   ) : null}
//                   {!("0" in images) && (
//                     <small className="text-danger ml-2">{error}</small>
//                   )}

//                   {/* <div className="col-lg-6 col-sm-12">
//               <label>Video upload</label>
//               <div style={{border:'2px solid black',padding:'20px'}}>
//                <VideoInput height={200}/>
//                </div>
//               </div> */}
//                 </div>

//                 <div className="row p-2">
//                   <div className="col-lg-12">
//                     <label>
//                       Upload Videos &nbsp;{" "}
//                       <input
//                         type="checkbox"
//                         onChange={handleCheckboxChange}
//                         style={{
//                           visibility: "visible",
//                           opacity: "1",
//                           zIndex: "1",
//                           background: "black",
//                           WebkitAppearance: "checkbox",
//                           cursor: "pointer",
//                           position: "relative",
//                         }}
//                       />{" "}
//                     </label>
//                     {showVideoForm && (
//                       <>
//                         <div
//                           style={{
//                             border: "2px solid black",
//                             padding: "20px",
//                             display: "flex",
//                             flexDirection: "row",
//                             alignItems: "center",
//                           }}
//                         >
//                           {uploadedVideos.map((video, index) => (
//                             <div
//                               key={index}
//                               style={{
//                                 position: "relative",
//                                 marginRight: "10px",
//                               }}
//                             >
//                               <video
//                                 src={URL.createObjectURL(video)}
//                                 width="80"
//                                 height="60"
//                                 controls
//                               />
//                               <p
//                                 onClick={() => handleRemoveVideo(index)}
//                                 style={{
//                                   position: "absolute",
//                                   top: 0,
//                                   right: 0,
//                                   background: "darkred",
//                                   border: "none",
//                                   cursor: "pointer",
//                                   color: "white",
//                                   fontSize: "10px",
//                                   borderRadius: "50%",
//                                   padding: "0 3px",
//                                   marginTop: "-7px",
//                                   marginRight: "3px",
//                                 }}
//                               >
//                                 X
//                               </p>
//                             </div>
//                           ))}

//                           <label
//                             htmlFor="fileInput"
//                             style={{
//                               display: "flex",
//                               flexDirection: "column",
//                               cursor: "pointer",
//                             }}
//                           >
//                             <IoVideocamOutline
//                               style={{ fontSize: "60px" }}
//                               onClick={handleIconClick}
//                             />
//                             <p style={{ fontSize: "75%", marginBottom: "0" }}>
//                               ADD VIDEO
//                             </p>
//                             {/* <i style={{color:'red', fontSize:'60%'}}>video length should be below 30seconds</i> */}
//                           </label>

//                           <input
//                             ref={fileInputRef}
//                             type="file"
//                             accept="video/*"
//                             id="fileInput"
//                             style={{ display: "none" }}
//                             onChange={handleFileChange}
//                           />
//                         </div>
//                       </>
//                     )}
//                   </div>
//                 </div>



//                 <div className="row p-3" style={{ marginBottom: "-20px" }}>
//                   <div className="col-lg-6 col-sm-12" style={{ display: "none" }}>
//                     <label className="mb-0 mt-2">Category</label>
//                     <input
//                       className="myinput"
//                       type="text"
//                       name="category"
//                       placeholder="enter category here"
//                       value={category}
//                       style={{
//                         padding: "7px",
//                         borderRadius: "3px",
//                       }}
//                     />
//                     {!change && (
//                       <small className="text-danger ml-2">
//                         *Select right category
//                       </small>
//                     )}
//                     {change == false ? (
//                       <span className="text-danger ml-2">
//                         select right category
//                       </span>
//                     ) : change && !category && afterSubmitFlg ? (
//                       <small className="text-danger ml-2">
//                         {CreateAds.Category}
//                       </small>
//                     ) : null}
//                     {/* {!category && afterSubmitFlg && <span className="text-danger ml-2">{CreateAds.Category}</span>} */}
//                   </div>

//                   {category != "RealEstate" ? (
//                     <div className="col-lg-6 col-sm-12">
//                       <label className="mb-0 mt-2">Sub Category</label>

//                       <Select
//                         className="custom-select1"
//                         style={{
//                           // padding: "10px",
//                           width: "100%",
//                           zIndex: "-10px",
//                           // borderRadius: "3px",
//                         }}
//                         getPopupContainer={(triggerNode) =>
//                           triggerNode.parentElement
//                         }
//                         allowClear
//                         value={subCategoryValue}
//                         status={changeSubCat ? "" : "error"}
//                         onChange={(value) => {
//                           console.log("1276", value);

//                           setsubCategoryValue("");
//                           setSubCategoryError(false);
//                           setAfterSubmitFlg(false);
//                           setTimeout(() => {
//                             setsubCategoryValue(value);
//                           }, 0);

//                           setChangeSubCat(value);

//                           SetFinalObj({});
//                         }}
//                         showSearch
//                         placeholder="Select Sub Category"
//                         optionFilterProp="children"
//                         filterOption={(input, option) =>
//                           (option?.label ?? "").includes(input)
//                         }
//                         filterSort={(optionA, optionB) =>
//                           (optionA?.label ?? "")
//                             .toLowerCase()
//                             .localeCompare((optionB?.label ?? "").toLowerCase())
//                         }
//                         options={
//                           category == "Services"
//                             ? Services.sort().map((result) => {
//                               return { value: result, label: result };
//                             })
//                             : null
//                         }
//                       />

//                       {subCategoryError == true && <p style={{ color: 'red' }}>Please Select the SubCategory</p>}
//                       {!changeSubCat && (
//                         <small className="text-danger ml-2">
//                           *Select right sub category
//                         </small>
//                       )}
//                       {subcategoryForAll && subCat == false ? (
//                         <small className="text-danger ml-2">
//                           *Select right category
//                         </small>
//                       ) : changeSubCat && !subCategoryValue && afterSubmitFlg ? (
//                         <small className="text-danger ml-2">
//                           *Please Enter The Sub Category
//                         </small>
//                       ) : null}
//                     </div>
//                   ) : null}

//                   {/* Education Subcategory Combination of dropdowns and textfields */}

//                   <div>
//                     <Row className="my-1">
//                       {subCategoryValue && obj && extraFeildToggle
//                         ? Object?.keys(obj)?.map((key, index) => {
//                           return (
//                             <Col span={6} style={{ marginTop: "15px" }}>
//                               <Select
//                                 getPopupContainer={(trigger) =>
//                                   trigger.parentNode
//                                 }
//                                 style={isMobile ? mobileStyle : desktopStyle}
//                                 notFoundContent="Please select right field"
//                                 options={obj[key]}
//                                 placeholder={key}
//                                 autoFocus={true}
//                                 onChange={(e) => {
//                                   finalObj[key] = e;
//                                   SetFinalObj(finalObj);
//                                 }}
//                                 filterOption={(inputValue, option) =>
//                                   option.value
//                                     .toUpperCase()
//                                     .indexOf(inputValue.toUpperCase()) !== -1
//                                 }
//                               />
//                             </Col>
//                           );
//                         })
//                         : null}
//                     </Row>
//                   </div>
//                 </div>




//                 <div className="row p-3 d-flex" style={{ marginTop: "-40px", display: "flex" }}>

//                   <div className="col-lg-6 col-sm-12">
//                     <label className="mb-0 mt-2">Phone Number</label>
//                     <input
//                       // className="inpstyle"
//                       className="myinput"
//                       name="phoneNumber"
//                       placeholder="Enter phone number"
//                       maxLength={10}
//                       type="number"
//                       onInput={(e) => {
//                         if (e.target.value.length > e.target.maxLength)
//                           e.target.value = e.target.value.slice(
//                             0,
//                             e.target.maxLength
//                           );
//                       }}
//                       onKeyDown={(e) =>
//                         ["e", "E", "+", "-", "."].includes(e.key) &&
//                         e.preventDefault()
//                       }
//                       style={{
//                         width: "100%",
//                         zIndex: "-50px",
//                         border: "1px solid #5d5d5d3d",
//                         padding: "7px",
//                         borderRadius: "3px",
//                       }}
//                       onChange={(e) => {
//                         setphoneNumber(e.target.value);
//                       }}
//                     />
//                     {phoneNumber.length < 10 && phoneNumber.length > 0 ? (
//                       <small className="text-danger ml-2">Enter valid Number</small>
//                     ) : phoneNumber.length > 10 ? (
//                       <small className="text-danger ml-2">
//                         Enter only 10 digit Number
//                       </small>
//                     ) : !phoneNumber && afterSubmitFlg ? (
//                       <small className="text-danger ml-2">
//                         {CreateAds.PhoneNumber}
//                       </small>
//                     ) : null}
//                   </div>
//                   <div>
//                     {/* <Button varient="outlined"  onClick={(e) => {toggleSecondSection1(); sendOtp(e);}} style={{float:"right",color:"green"}}>Send Otp</Button> */}

//                     <Button
//                       varient="outlined"
//                       onClick={SendOtp}
//                       style={{ float: "right", color: "green" }}
//                     >
//                       Send Otp
//                     </Button>
//                   </div>
//                 </div>
//                 {buttonshow && (
//                   <Button varient="outlined" onClick={subCategoryValue === "" ? null : handlevis1} style={{ display: "flex", float: "right", color: "red" }}> Next </Button>
//                 )
//                 }
//               </>

//             )}

//             <br></br>
//             {openNewModal2 && (
//               <Modal title="VERIFY OTP" open={openNewModal2} onOK={otpverifysumbit} onCancel={cancelHandler}>
//                 <form class="form-inline">
//                   <div class="col-lg-12 " style={{ marginTop: "-20px" }}>
//                     <label htmlFor="input" className="sr-only">
//                       Enter Your otp
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="text"
//                       onChange={(e) => {
//                         setOtpdetails(e.target.value);
//                       }}
//                       placeholder="Enter Your otp"
//                     />
//                   </div>
//                   <button
//                     className="custom-btns btn-1 mt-3 mx-3 "
//                     onClick={otpverifysumbit}
//                   >
//                     verify number
//                   </button>
//                 </form>
//               </Modal>
//             )}
//             <br /><br />
//             {/* <div classname="my-5">
//                 {category === "Services" ? <Service1 /> : null}
//               </div> */}
//             {/* <div className="row-hover"> */}
//             {onevisible3 && (
//               <div style={{ width: "100%", backgroundColor: "whitesmoke", display: "flex", justifyContent: "space-between", alignItems: "center", border: "2px dotted black" }}>
//                 <div style={{ backgroundColor: "navy", color: "white", padding: "10px 15px", borderRadius: "20px", fontWeight: "700", marginLeft: "15px" }}>2</div>
//                 <h2 style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}>Personal & Bussines Details</h2>
//                 <div onClick={handlevis4} style={{ marginRight: "15px", fontSize: "25px", fontWeight: "bolder", color: "blue" }}> <TbPlayerTrackNext /> </div>
//               </div>
//             )}
//             <br></br>
//             {onevisible2 && (
//               <>
//                 {showSecondSection && (
//                   <>
//                     <h5> Contact Details</h5>
//                     <div className="d-flex" style={{ marginTop: "-5px" }}>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">User Name</label>
//                         <input
//                           className="myinput"
//                           style={{
//                             borderRadius: "3px",
//                             padding: "7px",
//                           }}
//                           type="text"
//                           name="id"
//                           disable={true}
//                           placeholder="USER NAME"
//                           value={name}
//                         />
//                         {!name && <p style={{ color: 'red' }}>Enter the User Name</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Company Name</label>
//                         <input
//                           autoComplete="none"
//                           className="myinput"
//                           type="text"
//                           name="price"
//                           placeholder="Enter Your Name here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={companyChangeHandler}
//                         />
//                         {companyNameError && <p style={{ color: 'red' }}>Enter the Company Name</p>}
//                       </div>

//                     </div>
//                     <div className="d-flex">
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Email id</label>
//                         <input
//                           className="myinput"
//                           type="email"
//                           name="locality"
//                           placeholder="Enter Email"
//                           onChange={emailChangeHandler}
//                         />
//                         {emailError && <p style={{ color: 'red' }}>Enter the Email Address</p>}
//                       </div>
//                     </div>
//                     <br></br>
//                     <h5> Business Details</h5>
//                     <div className="d-flex" style={{ marginTop: "-5px" }}>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Website</label>
//                         <input
//                           autoComplete="none"
//                           className="myinput"
//                           type="text"
//                           name="Website"
//                           placeholder="Enter Your Website here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={websiteChangeHandler}
//                         />
//                         {websiteError && <p style={{ color: 'red' }}>Enter Your Website Address</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">
//                           About Company (if Applicable)
//                         </label>
//                         <input
//                           className="myinput"
//                           name="About Company "
//                           placeholder="Enter About Company"
//                           maxLength={10}
//                           type="text"
//                           style={{ resize: "none" }}
//                           value={description}
//                           onChange={descriptionChangeHandler}
//                         />
//                         {descriptionError && <p style={{ color: 'red' }}>Enter About Company</p>}
//                         {!description && afterSubmitFlg && (
//                           <small className="text-danger ml-2">{CreateAds.disc}</small>
//                         )}
//                       </div>
//                     </div>
//                     <div className="d-flex" style={{ marginTop: "-5px" }}>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Working Hours</label>
//                         <Select
//                           className="custom-select1"
//                           style={{
//                             // padding: "10px",
//                             width: "100%",
//                             zIndex: "-10px",
//                             // borderRadius: "3px",
//                           }}
//                           placeholder="Working Hours"
//                           getPopupContainer={(triggerNode) =>
//                             triggerNode.parentElement
//                           }
//                           allowClear

//                           options={
//                             Workinghours.sort().map((result) => {
//                               return { value: result, label: result };
//                             })

//                           }
//                           onChange={handleworkinghour}
//                         />
//                         {workingHoursError && <p style={{ color: 'red' }}>Enter the Working Hours</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">No of Employees</label>
//                         <input
//                           className="myinput"
//                           name="No of Employees"
//                           placeholder="Enter No of Employees"
//                           maxLength={10}
//                           type="number"
//                           style={{
//                             width: "100%",
//                             zIndex: "-50px",
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={NoOfEmployeesHandler}
//                         />
//                         {noofempError && <p style={{ color: 'red' }}>Enter the No Of Employees</p>}
//                       </div>
//                     </div>
//                     <div className="d-flex" style={{ marginTop: "-5px" }}>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Business Since</label>
//                         <Select
//                           className="custom-select1"
//                           style={{
//                             // padding: "10px",
//                             width: "100%",
//                             zIndex: "-10px",
//                             // borderRadius: "3px",
//                           }}
//                           placeholder="Bussines Since"
//                           getPopupContainer={(triggerNode) =>
//                             triggerNode.parentElement
//                           }
//                           allowClear

//                           options={
//                             YearOfRegistrationCar.sort().map((result) => {
//                               return { value: result, label: result };
//                             })

//                           }
//                           onChange={handlebussinesssince}
//                         />
//                         {businesssinceError && <p style={{ color: 'red' }}>Enter the Business since</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Upload Logo</label>
//                         <input
//                           className="myinput"
//                           name="Upload Logo"
//                           placeholder="Upload Logo"
//                           maxLength={10}
//                           type="file"
//                           style={{
//                             width: "100%",
//                             zIndex: "-50px",
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={handleLogoFileChange}
//                         />
//                          {!logoFile && <p style={{ color: 'red' }}>Enter the Business since</p>}
//                       </div>
//                     </div>
//                     <br></br>
//                     <div>
//                       <Button
//                         varient="outlined"
//                         onClick={ContactDetails}
//                         style={{ float: "right", color: "green" }}
//                       >
//                         Submit details
//                       </Button>
//                     </div>
//                   </>
//                 )}


//                 {!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError && showThirdSection && (
//                   <>
//                     <div>
//                       <h5> Service Details</h5>
//                       <div style={{ marginTop: "-5px" }}>
//                         <div className="col-lg-6 col-sm-12">
//                           <label className="mb-0 mt-2">Service Name</label>
//                           <input
//                             className="myinput"
//                             type="text"
//                             name="Service Name"
//                             placeholder="Enter Your Service Name here"
//                             value={subCategoryValue}
//                             style={{
//                               padding: "7px",
//                               borderRadius: "3px",
//                             }}
//                             onChange={titleChangeHandler}
//                           />
//                           {!title && afterSubmitFlg && (
//                             <small className="text-danger ml-2">{CreateAds.title}</small>
//                           )}
//                         </div>
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">
//                           Types of Service
//                         </label>
//                         <input
//                           className="myinput"
//                           type="text"
//                           name="Types of Service"
//                           placeholder="Enter Your Types of Service here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           value={JSON.stringify(finalObj)}
//                         />
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Issues Handled</label>
//                         <input
//                           className="myinput"
//                           type="text"
//                           name="Issues Handled"
//                           placeholder="Enter Your Issues Handled here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={issueChangeHandler}
//                         />
//                         {issuehandledError && <p style={{ color: 'red' }}>Enter Your Issues Handled here</p>}
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Brand</label>
//                         <Select
//                           className="custom-select1"
//                           placeholder="Brand Name"
//                           style={{
//                             // padding: "10px",
//                             width: "100%",
//                             zIndex: "-10px",
//                             // borderRadius: "3px",
//                           }}
//                           getPopupContainer={(triggerNode) =>
//                             triggerNode.parentElement
//                           }
//                           allowClear

//                           options={
//                             WashingMachineBrand.sort().map((result) => {
//                               return { value: result, label: result };
//                             })

//                           }
//                           onChange={handlebrandname}
//                         />
//                         {brandError && <p style={{ color: 'red' }}>Select the brand Name</p>}
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">About service</label>
//                         <input
//                           className="myinput"
//                           type="text"
//                           name="About service"
//                           placeholder="Enter Your About service here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={aboutServicesHandler}
//                         />
//                         {aboutservicesError && <p style={{ color: 'red' }}>Enter Your About Services Here</p>}
//                       </div>

//                       {/* <div className="col-lg-6 col-sm-12">
//                     <label className="mb-0 mt-2">Upload image</label>
//                     <input
//                       className="myinput"
//                       type="file"
//                       name="Area Covered"
//                       placeholder="Enter Your Area Covered here"
//                       style={{
//                         border: "1px solid #5d5d5d3d",
//                         padding: "7px",
//                         borderRadius: "3px",
//                       }}
//                     />
//                   </div> */}

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Area Covered</label>
//                         <input
//                           className="myinput"
//                           type="text "
//                           name="Area Covered"
//                           placeholder="Enter Your Area Covered here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={areaCoveredHandler}
//                         />
//                         {areacoveredError && <p style={{ color: 'red' }}>Enter Your Area Covered here</p>}
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Cities Serviced</label>
//                         <input
//                           className="myinput"
//                           type="text"
//                           name="Cities Serviced"
//                           placeholder="Enter Your Cities Serviced here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={cityservicedHandler}
//                         />
//                         {cityservicedError && <p style={{ color: 'red' }}>Enter the Cities Serviced Here</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Price</label>
//                         <input
//                           autoComplete="none"
//                           className="myinput"
//                           type="number"
//                           name="price"
//                           placeholder="Enter price here"
//                           value={price}
//                           onKeyDown={(e) =>
//                             ["e", "E", "+", "-", "."].includes(e.key) &&
//                             e.preventDefault()
//                           }
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={(e) => {
//                             /[^0-9a-zA-Z]/.test(setPrice(e.target.value))
//                               ? /[^0-9a-zA-Z]/.test(setPrice(e.target.value))
//                               : setPrice(e.target.value);
//                             setPriceError(false);
//                           }}
//                           pattern="/^[0-9a-zA-Z]+$/"
//                         />
//                         {!price && afterSubmitFlg && (
//                           <small className="text-danger ml-2">{CreateAds.Price}</small>
//                         )}
//                         {priceError && <p style={{ color: 'red' }}>Enter the Price</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Date</label>
//                         <input
//                           className="myinput p-2"
//                           style={{
//                             border: "1px solid #8080804d",
//                             borderRadius: "3px",
//                             padding: "6px",
//                           }}
//                           type="text"
//                           name="date"
//                           placeholder="enter date here"
//                           value={date}
//                           onChange={dateChangeHandler}
//                           disabled
//                         />
//                         {dateError && <p style={{ color: 'red' }}>Enter Date here</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Coordinates</label>
//                         <div className="d-flex">
//                           <input
//                             type="text"
//                             className="myinput"
//                             id="coordinatesInput"
//                             readOnly value={coordinates}
//                           />
//                           {/* <FaAdn  /> */}
//                         </div>

//                       </div>
//                     </div>
//                     <div>
//                       <h5>More information</h5>
//                       <div className="d-flex">
//                         <div className="col-lg-6 col-sm-12">
//                           <label className="mb-0 mt-2">State</label>

//                           <Select
//                             className="custom-select1"
//                             style={{
//                               // padding: "10px",
//                               width: "100%",
//                               zIndex: "-10px",
//                               // borderRadius: "3px",
//                             }}
//                             autoComplete="none"
//                             getPopupContainer={(triggerNode) => triggerNode.parentElement}
//                             allowClear
//                             status={changeState ? "" : "error"}
//                             onChange={(value) => {
//                               setChangeState(value);
//                               console.log("1553", value);
//                               setUpdateState(value);
//                               setState(value);
//                               setStateError(false);
//                               setChangeCity("");
//                             }}
//                             showSearch
//                             placeholder="Select State"
//                             optionFilterProp="children"
//                             filterOption={(input, option) =>
//                               (option?.label ?? "").includes(input)
//                             }
//                             filterSort={(optionA, optionB) =>
//                               (optionA?.label ?? "")
//                                 .toLowerCase()
//                                 .localeCompare((optionB?.label ?? "").toLowerCase())
//                             }
//                             options={Object.keys(stateMain)?.map((result) => {
//                               return { value: result, label: result };
//                             })}
//                           />

//                           {!changeState && (
//                             <small className="text-danger ml-2">
//                               *Select right state
//                             </small>
//                           )}
//                           {changeState == false ? (
//                             <small className="text-danger ml-2">
//                               *Choose right state
//                             </small>
//                           ) : changeState && !state && afterSubmitFlg ? (
//                             <small className="text-danger ml-2">{CreateAds.State}</small>
//                           ) : null}
//                           {stateError && <p style={{ color: 'red' }}>Select Your State</p>}
//                           {/* {!state && afterSubmitFlg && <span className="text-danger ml-2">{newError==false?"choose right state":newError==true?"good job" :CreateAds.State}</span>} */}
//                         </div>
//                         <div className="col-lg-6 col-sm-12">
//                           <label className="mb-0 mt-2">City</label>

//                           <Select
//                             className="custom-select1"
//                             autoComplete="none"
//                             style={{
//                               width: "100%",
//                               zIndex: "-10px",
//                             }}
//                             value={city}
//                             getPopupContainer={(triggerNode) => triggerNode.parentElement}
//                             allowClear
//                             status={changeCity ? "" : "error"}
//                             onChange={(value) => {
//                               setChangeCity(value);
//                               console.log("1553", value);

//                               // if (Object.keys(stateMain)?.includes(value)) {
//                               //   setNewError(true);
//                               // } else {
//                               //   setNewError(false);

//                               //   console.log("not match");
//                               // }

//                               setCity(value);
//                               setCityError(false);
//                             }}
//                             showSearch
//                             placeholder="Select City Name"
//                             optionFilterProp="children"
//                             filterOption={(input, option) =>
//                               (option?.label ?? "").includes(input)
//                             }
//                             filterSort={(optionA, optionB) =>
//                               (optionA?.label ?? "")
//                                 .toLowerCase()
//                                 .localeCompare((optionB?.label ?? "").toLowerCase())
//                             }
//                             options={
//                               !changeCity &&
//                               updateState &&
//                               cityWithState?.map((result) => {
//                                 return { value: result, label: result };
//                               })
//                             }
//                           />

//                           {!changeCity && (
//                             <small className="text-danger ml-2">*Select right city</small>
//                           )}
//                           {city == false ? (
//                             <small className="text-danger ml-2">*Choose right city</small>
//                           ) : changeCity && !city && afterSubmitFlg ? (
//                             <small className="text-danger ml-2">{CreateAds.City}</small>
//                           ) : null}
//                           {cityError && <p style={{ color: 'red' }}>Select Your City</p>}
//                           {/* {!city && afterSubmitFlg && <span className="text-danger ml-2">{CreateAds.City}</span>} */}
//                         </div>
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Locality</label>
//                         {/* <GoogleAutoComplteforAddProduct /> */}
//                         {/* <input type="text" /> */}
//                         <div className="App">
//                           <GooglePlacesAutocomplete
//                             apiKey="AIzaSyDEnLDetZt4PvT03AWs7ZTyzAdA3K-Sz1g"
//                             selectProps={{
//                               isClearable: true,
//                               value: address,
//                               onChange: (val) => {
//                                 setAddress(val);
//                               },
//                               placeholder: 'Enter City Name',
//                               styles: {
//                                 input: (provided) => ({
//                                   ...provided,
//                                   boxShadow: 0,
//                                   padding: "4px",
//                                   "&:hover": {
//                                     border: "1px solid purple",
//                                   },
//                                 }),
//                                 singleValue: (provided) => ({
//                                   ...provided,
//                                   boxShadow: 0,
//                                   "&:hover": {
//                                     border: "1px solid purple",
//                                   },
//                                 }),
//                               },
//                             }}
//                             autocompletionRequest={{
//                               types: ['(cities)'], // Restrict autocomplete to cities only
//                             }}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Zip Code</label>
//                         <input
//                           className="myinput"
//                           name="zipcode"
//                           placeholder="enter zipcode here"
//                           value={zipcode}
//                           type="number"
//                           maxLength={6}
//                           onInput={(e) => {
//                             if (e.target.value.length > e.target.maxLength)
//                               e.target.value = e.target.value.slice(
//                                 0,
//                                 e.target.maxLength
//                               );
//                           }}
//                           onKeyDown={(e) =>
//                             ["e", "E", "+", "-", "."].includes(e.key) &&
//                             e.preventDefault()
//                           }
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={zipcodeHandler}
//                         />
//                         {zipcode.length < 6 && zipcode.length > 0 ? (
//                           <span className="text-danger ml-2">
//                             Please enter valid zipcode
//                           </span>
//                         ) : zipcode.length > 6 ? (
//                           <span className="text-danger ml-2">
//                             Please enter valid zipcode
//                           </span>
//                         ) : !zipcode && afterSubmitFlg ? (
//                           <small className="text-danger ml-2">
//                             {CreateAds.ZipCode}
//                           </small>
//                         ) : null}
//                         {zipcodeError && <p style={{ color: 'red' }}>Enter the Zipcode</p>}
//                       </div>


//                     </div>
//                     <br />
//                     <Button
//                       style={{ marginTop: "50px" }}
//                       type="primary"
//                       className="btn btn-block style1 text-white p-1"
//                       onClick={handlevis3} disabled={!isFormValid()}> submit </Button>
//                     <br />
//                   </>
//                 )}
//               </>
//             )}
//             {!issuehandledError && !brandError && !aboutservicesError && !cityservicedError && !areacoveredError && !cityservicedError && !priceError && !stateError && !cityError && !localityError && !zipcodeError && onevisible4 && (
//               <>
//                 <div className="dflex row pl-3 pr-3">
//                   <div className="col-lg-12 col-sm-12 mb-3">
//                     <small className="text-danger">
//                       *Choose a plan category and type for this post.{" "}
//                     </small>

//                     <span className="px-3">Plan Category</span>

//                     {business === true ? (
//                       <Select
//                         defaultValue="Choose"
//                         getPopupContainer={(triggerNode) =>
//                           triggerNode.parentElement
//                         }
//                         style={{
//                           width: 150,
//                         }}
//                         onChange={(value) => {
//                           // getDataByPlan(value);
//                           setPlan(value);
//                           setAdsCategory("");
//                         }}
//                         options={leftPlan?.map((val) => {
//                           return { value: val, label: val };
//                         })}
//                       />
//                     ) : (
//                       <Select
//                         defaultValue="Choose"
//                         getPopupContainer={(triggerNode) =>
//                           triggerNode.parentElement
//                         }
//                         style={{
//                           width: 150,
//                         }}
//                         onChange={(value) => {
//                           // getDataByPlan(value);
//                           setPlan(value);
//                           setAdsCategory("");
//                         }}
//                         options={data?.leftPlan?.map((val) => {
//                           return { value: val, label: val };
//                         })}
//                       />
//                     )}

//                     <span className="px-3">
//                       Days limit for this post : {dayslimit}
//                     </span>
//                   </div>
//                   <div
//                     className="col-lg-6 col-sm-12 "
//                     style={{ display: "flex", flexDirection: "column" }}
//                   >
//                     <>
//                       <label className="mb-0 mt-2">User Type: &nbsp;</label>
//                       <Select style={{ width: "10rem", marginRight: "-2rem" }}
//                         getPopupContainer={(triggerNode) => triggerNode.parentNode}
//                         className="classclass"
//                         placeholder="User Type"
//                         options={["Institution", "Freelancer"].map((result) => ({
//                           value: result,
//                           label: result,
//                         }))}
//                         onChange={(value) => {
//                           // getDataByPlan(value);
//                           setusertype(value);
//                         }}
//                       />{!usertype && afterSubmitFlg ? (
//                         <small className="text-danger ml-2">
//                           {CreateAds.userty}
//                         </small>
//                       ) : null}
//                     </>
//                   </div>
//                 </div>
//                 <Button
//                   style={{ marginTop: "32px" }}
//                   type="primary"
//                   className="btn btn-block style1 text-white p-1"
//                   loading={isLoading}
//                   disabled={isLoading}
//                   onClick={addNewProduct}
//                 >
//                   POST ADD
//                 </Button>
//               </>
//             )}

//             {planError ? (
//               <>
//                 <p className="text-danger">Please choose a plan !</p>
//               </>
//             ) : null}
//             {true ? (
//               <>

//                 {data.leftPlan && (
//                   <Modal
//                     // title="Basic Modal"
//                     open={isModalOpen}
//                     onOk={() => navigate("/pricing/")}
//                     onCancel={handleCancel}
//                   >
//                     <div className="mt-2 text-center">
//                       <BsExclamationTriangle className="fs-22 text-warning" />{" "}
//                       &nbsp;&nbsp;
//                       <span className="text-danger">
//                         Please choose a plan !
//                       </span>
//                     </div>
//                   </Modal>
//                 )}
//               </>
//             ) : (
//               <Link to="/payment" className="headline">
//                 {" "}
//                 **For Premium ADs
//                 <button
//                   type="button"
//                   class="btn style1 btn-block"
//                   onClick={handlePay}
//                 >
//                   Pay & Add Add
//                 </button>
//               </Link>
//             )}
//             {active === "Payments" && <Payments />}
//             {ErrorExtraField ? (
//               <>
//                 <Modal
//                   zIndex={9999}
//                   style={{ zIndex: "99999" }}
//                   title="Warning"
//                   open={adsLimitCheck}
//                   okType="danger"
//                   onOk={() => setAdsLimitCheck(false)}
//                   onCancel={() => setAdsLimitCheck(false)}
//                 >
//                   <p>Please fill the extra fields </p>
//                 </Modal>
//               </>
//             ) : null}
//             {/* {successvalue ? (
//                   <div className="text-success">Succesfully Stored</div>
//                 ) : null} */}
//             {errorvalue ? <div className="text-danger">Error </div> : null}


//           </div>
//         </div>

//         {loading1 && (
//           <Modal
//             title="under process"
//             open={loading1}
//             onOk={() => {
//               navigate("/dashboard");
//             }}
//           >
//             It is under <span className="text-warning">verification</span>
//             <p className="p-0 m-0"> It will approve within 24 hours.</p>
//           </Modal>
//         )}
//         {openNewModal && (
//           <Modal
//             title="Ads post successfully"
//             open={openNewModal}
//             icon=<CheckCircleFilled />
//             fill="green"
//             onOk={() => {
//               navigate("/dashboard");
//             }}
//             onCancel={() => navigate("/")}
//           >
//             <p className="p-0 m-0">Your ads have been posted successfully.</p>
//             <p className="p-0 m-0">
//               It is under <span className="text-warning">verification</span>
//             </p>
//             <p className="p-0 m-0"> It will approve within 24 hours.</p>
//           </Modal>
//         )}
//       </fieldset>
//     </div>
//   );
// };

// export default Addservices;








































// import React, { useContext, useEffect, useState } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import { Checkbox } from "antd";
// import Payments from "../Payment/Payments";
// import "./AddProducts.css";
// import validator from "validator";
// import { Image, Container, Row, Col } from "react-bootstrap";
// import "../Allads/ImageUpload/ImageUpload.css";
// import { FaCut, FaAdn } from "react-icons/fa";
// import "./AddProducts.css";
// import { DatePicker, Space, Select, Spin, Alert } from "antd";
// import { CheckCircleFilled } from "@ant-design/icons";
// import BiCurrentLocation from "react-icons/bi";
// import { useGeolocated } from "react-geolocated";
// import { useParams } from "react-router-dom";
// import { Button, Modal } from "antd";
// import {
//   Category,
//   Electronics,
//   Furniture,
//   Pets,
//   Cars,
//   Bikes,
//   Services,
//   Mobiles,
//   localUrl,
//   Workinghours,
//   WashingMachineBrand,
//   subcategoryRealEstateBuy,
//   subcategoryRealEstateRent,
//   subcategoryType1,
//   url,
//   Engine,
//   Year,
//   Jobs,
//   Education,
//   countryOptions,
//   colorOptions,
//   fruitOptions,
//   careerStream,
//   deliveryMode,
//   careerDomain,
//   deliveryMode1,
//   listedBy,
//   programType,
//   deliveryMode2,
//   careerStream2,
//   deliveryMode3,
//   hobby,
//   eligibility,
//   classesHeldOn,
//   classTimings,
//   programsOffered,
//   educationMethod,
//   minAge,
//   maxAge,
//   studentTeacherRacFat,
//   schoolBoard,
//   subjects,
//   standard,
//   deliveryMode5,
//   country,
//   servicesOffered,
//   educationStream,
//   productType1,
//   industry,
//   keyFeatures,
//   YearOfRegistrationCar
// } from "../../env";
// import { stateMain } from "../../Location";
// import { decrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
// import { isMobile } from "react-device-detect";
// import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

// // import Success from "../../Pages/success/success";
// // import ReactDOM from 'react-dom';
// // import $ from 'jquery';
// // import ImageUpload from "./ImageUpload/ImageUpload";
// // import { SignalWifiStatusbarNullSharp } from "@mui/icons-material";
// // import Spiner from "../../Spiner";
// import MultiImageInput from "react-multiple-image-input";
// import { CreateAds } from "../../../error/errorMessage";
// import { TbPlayerTrackNext } from "react-icons/tb"
// import { UserContext } from "../../../App";
// import GoogleAutoComplteforAddProduct from "../../Home/GoogleAutoComplte/GoogleAutoComplteforAddProduct";
// import { useDispatch, useSelector } from "react-redux";
// import { add } from "../../../store/Track/trackUserSlice";
// import { addActivePlan } from "../../../store/activePlanSlice";
// import SubcategoryCommonInput from "../../../Shared/SubCategory/subcategoryCommonInput";
// import { subCategoryInput } from "../../../Model/SubCategoryCons";
// import "antd/dist/antd.css";
// import { HolaAutoComplete } from "../components/HolaAutoComplete";
// import { LocalSeeRounded } from "@mui/icons-material";
// import { getUserPlan } from "../../../store/allPlanDetails";
// import axios from "axios";
// import { BsExclamationTriangle } from "react-icons/bs";
// import { isNull } from "lodash";
// import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
// import { IoVideocamOutline } from "react-icons/io5";
// // import Service1 from "./Service1";

// const array = [];
// console.log("!!! 747 array,", array);

// const mobileStyle = {
//   width: "100%",
//   // margin: "5px",
//   justifyContent: "center",
// };
// const desktopStyle = {
//   width: 300,
//   //  margin: "5px",
// };
// const mobileStyle1 = {
//   padding: "1px",
// };
// const desktopStyle1 = {
//   padding: "50px",
//   //  margin: "5px",
// };
// const mobstyle = {
//   display: "flex",
//   flexDirection: "column",
//   marginLeft: "15px",
//   marginTop: "35px",
//   gap: "30px",
// };
// const deskstyle = {
//   display: "flex",
//   flexDirection: "row",
//   gap: "20%",
//   marginLeft: "20px",
//   marginTop: "35px",
// };
// const { Option } = Select;
// const Addservices = (props) => {

//   // geocode
//   const UserData = useContext(UserContext);
//   const [address, setAddress] = useState();
//   const [addressObj, setAddressObj] = useState();
//   const [selectedLocation, setSelectedLocation] = useState("");

//   const getCityName = (address_components) => {
//     let city = "";
//     address_components.forEach((component) => {
//       if (component.types.includes("locality")) {
//         city = component.long_name;
//       }
//     });
//     return city;
//   };

//   useEffect(() => {
//     const detectUserLocation = () => {
//       if ("geolocation" in navigator) {
//         navigator.permissions
//           .query({ name: "geolocation" })
//           .then((permissionStatus) => {
//             if (permissionStatus.state === "granted") {
//               navigator.geolocation.getCurrentPosition(
//                 async (position) => {
//                   const { latitude, longitude } = position.coords;
//                   const geocoder = new window.google.maps.Geocoder();
//                   const latlng = { lat: latitude, lng: longitude };

//                   geocoder.geocode({ location: latlng }, async (results, status) => {
//                     if (status === "OK" && results[0]) {
//                       setAddress({ label: getCityName(results[0].address_components), value: { place_id: results[0].place_id } });
//                     }
//                   });
//                 },
//                 (error) => {
//                   console.error("Error getting user location:", error);
//                 }
//               );
//             } else if (permissionStatus.state === "prompt") {
//               navigator.geolocation.getCurrentPosition(
//                 () => {
//                   detectUserLocation();
//                 },
//                 (error) => {
//                   console.error("Error getting user location:", error);
//                 }
//               );
//             }
//           })
//           .catch((error) => {
//             console.error("Error checking geolocation permission:", error);
//           });
//       } else {
//         console.error("Geolocation not available in this browser.");
//       }
//     };

//     // Automatically detect user's location when the component mounts
//     detectUserLocation();
//   }, []);

//   useEffect(() => {
//     const func = async () => {
//       const geocodeObj =
//         address &&
//         address.value &&
//         (await geocodeByPlaceId(address.value.place_id));
//       UserData.setsearchBoxLocaity(address?.label);
//       const addressObject =
//         geocodeObj && getCityName(geocodeObj[0].address_components);

//       setAddressObj(addressObject);
//     };
//     func();
//   }, [address]);

//   useEffect(() => {
//     // Update the selected location whenever the address changes
//     setSelectedLocation(address?.label || "");
//   }, [address]);
//   // geo end
//   const disptach = useDispatch();
//   useEffect(() => {
//     disptach(add({ view: ["contactForm"] }));
//   }, []);

//   //loading1 button
//   //  const [loading1s, setloadings] = useState<boolean[]>([]);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const [visible, setVisible] = useState(false);
//   // const [style1, setStyle1] = useState({color:"red!important"});
//   // const [style2, setStyle2] = useState("cont");
//   // const [buttoncheck,setbuttoncheck]=useState(null)

//   // after cgha
//   const handleChange = (value) => {
//     console.log(`selected ${value}`);
//   };

//   const dataUser = useContext(UserContext);

//   const crop = {
//     // unit: "%",
//     // aspect: 6 / 6,
//     width: "auto",
//     height: "auto",
//   };

//   const { categories } = useParams();

//   const [rentErr, setRentErr] = useState(false);
//   const [typeErr, setTypeErr] = useState(false);
//   const [newError, setNewError] = useState(false);
//   const [cityErr, setCityErr] = useState(false);
//   const [catErr, setCatErr] = useState(false);
//   const [subCat, setSubCat] = useState(false);
//   const [afterSubmitFlg, setAfterSubmitFlg] = useState(false);
//   const [images, setImages] = useState({});
//   const [imageError, setImageError] = useState(false);
//   const [id, setId] = useState();
//   const [image, setImage] = useState(null);
//   const [title, setTitle] = useState("");
//   const [titleError, setTitleError] = useState(false);

//   const [price, setPrice] = useState("");
//   const [priceError, setPriceError] = useState(false);
//   const [jobprice, setJobPrice] = useState(10);
//   const [jobtags, setJobTags] = useState("asa");
//   const [tags, setTags] = useState("");
//   const [description, setDescription] = useState("");
//   const [category, setCategory] = useState(categories); //13
//   // const [subcategory, setSubcategory] = useState("") //13
//   const [subcategoryForAll, setSubcategoryForAll] = useState("");
//   const [brand, setBrand] = useState("");
//   const [brandError, setBrandError] = useState(false);
//   const [condition, setCondition] = useState("Good");
//   const [state, setState] = useState("");
//   const [stateError, setStateError] = useState(false);
//   const [city, setCity] = useState(null);
//   const [cityError, setCityError] = useState(false);
//   const [locality, setLocality] = useState("");
//   const [localityError, setLocalityError] = useState(false);
//   const [cordinates, setcordinates] = useState("");
//   const [zipcode, setZipcode] = useState("");
//   const [zipcodeError, setZipcodeError] = useState(false);
//   const [date, setDate] = useState("");
//   const [dateError, setDateError] = useState(false);
//   const [active, setActive] = useState("DashAds");
//   const [loading1, setloading1] = useState(false);
//   //loader
//   const [isLoading, setIsLoading] = useState(false);
//   const [success, setSuccess] = useState("");
//   const [error, setError] = useState(null);
//   const [storeadsFlag, setstoreadsFlag] = useState(false);
//   const [realEsateSubCategory, setrealEsateSubCategory] = useState(null);

//   const [subCategoryValue, setsubCategoryValue] = useState(null); //28
//   const [selectedCountry, setSelectedCountry] = useState(null);
//   const [selectedColor, setSelectedColor] = useState(null);
//   const [selectedFruit, setSelectedFruit] = useState(null);

//   const [subCategoryType, setsubCategoryType] = useState(null);
//   const userDataValue = localStorage.getItem("userdata")
//     ? decrypt("userdata")
//     : null;
//   const [successvalue, setSuccessValue] = useState(false);
//   const [errorvalue, setErrorValue] = useState(false);
//   const [message, setMessage] = useState(null);
//   const [MoreImage, setMoreImage] = useState(false);
//   const [phoneNumber, setphoneNumber] = useState("");
//   const [subCategoryError, setSubCategoryError] = useState(false);
//   const [descriptionError, setDescriptionError] = useState(false);

//   // const [lat,setLat]=useState(false)
//   // const [long,setLong]=useState(false)
//   const [lat, setLat] = useState(false);
//   const [long, setLong] = useState(false);
//   ///category real esate

//   const [BuildUpArea, setBuildUpArea] = useState(null);
//   const [Flor, setFlor] = useState(null);
//   const [ApartMentType, setApartMentType] = useState(null);
//   const [Availability, setAvailability] = useState(null);
//   const [FurnishedType, setFurnishedType] = useState(null);
//   const [Property, setProperty] = useState(null);
//   const [Parking, setParking] = useState(null);
//   const [PowerBackup, setPowerBackup] = useState(null);
//   const [Gym, setGym] = useState(null);
//   const [Garden, setGarden] = useState(null);
//   const [Pool, setPool] = useState(null);
//   const [Lift, setLift] = useState(null);

//   const [formDisable, setFormDisable] = useState(true);
//   // when ads limit over

//   const [adsLimitCheck, setAdsLimitCheck] = useState(false);
//   const [currentSelectedplan, setcurrentSelectedplan] = useState(null);

//   //label change code
//   const [labelBrand, setLableBrand] = useState("Brand");
//   useEffect(() => {
//     setLableBrand(subCategoryValue === "Animal" ? "Breed" : "Brand");
//   }, [subCategoryValue]);
//   const [colorCheck, setcolor] = useState(null);
//   const [sizeCheck, setsize] = useState(null);

//   const [oldPetsCheck, setoldPets] = useState(null);

//   const cancelHandler = () => {
//     setOpenNewModal2((prevState) => !prevState);
//   }

//   // state error
//   //checkbox

//   var planCategory =
//     dataUser?.activePlan[dataUser?.activePlan?.length - 1]?.fields?.category;

//   // all ads data

//   const [allAdsCount, setAllAdsCount] = useState([]);

//   // userPlan

//   const { loading, data } = useSelector((state) => state.planData);

//   console.log("~~~databyplan187", data);

//   // for change plan
//   const [updateAds_left, setUpdateAds_left] = useState(null);
//   const [updateCategory, setUpdateCategory] = useState(null);
//   const [updateFeatured_ads, setUpdateFeatured_ads] = useState(null);
//   const [updateAds_limit, setUpdateAds_limit] = useState(null);
//   const [updateTop_listing, setUpdateTop_listing] = useState(null);
//   const [updateSupport, setUpdateSupport] = useState(null);
//   const [updateAds_timimg, setUpdateAds_timimg] = useState(null);
//   let userid = localStorage.getItem("userid");
//   // let userid=localStorage.getItem("userid")
//   // aminities checkbox value
//   const [coordinates, setCoordinates] = useState('');
//   const [aminities, setAminities] = useState([]);

//   const companyChangeHandler = (e) => {
//     setcompanyname(e.target.value)
//     setCompanyNameError(false);
//   }



//   const areaCoveredHandler = (e) => {
//     setareacovered(e.target.value);
//     setareacoveredError(false);
//   }

//   const cityservicedHandler = (e) => {
//     setcityserviced(e.target.value);
//     setcityservicedError(false)
//   }

//   const emailChangeHandler = (e) => {
//     setemail(e.target.value);
//     setEmailError(false);
//   }

//   const descriptionChangeHandler = (e) => {
//     setDescription(e.target.value);
//     setDescriptionError(false);
//   }

//   const websiteChangeHandler = (e) => {
//     setwebsite(e.target.value);
//     setWebsiteError(false);
//   }

//   const noOfWorkingHoursHandler = (e) => {
//     setworkinghours(e.target.value);
//     setWorkingHoursError(false);
//   }

//   const NoOfEmployeesHandler = (e) => {
//     setnoofemp(e.target.value);
//     setnoofempError(false);
//   }

//   const issueChangeHandler = (e) => {
//     setissuehandled(e.target.value);
//     setissuehandledError(false);
//   }

//   const localityChangeHandler = (e) => {
//     setLocality(e.target.value);
//     setLocalityError(false);
//   }

//   const zipcodeHandler = (e) => {
//     setZipcode(e.target.value);
//     setZipcodeError(false);
//   }

//   window.onload = () => {
//     getLocation();
//   };

//   const getLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(showPosition, showError);
//     } else {
//       alert("Geolocation is not supported by this browser.");
//     }
//   };

//   // const MyComponent = () => {
//   useEffect(() => {
//     getLocation();
//   }, []);

//   const showPosition = (position) => {
//     const latitude = position.coords.latitude;
//     const longitude = position.coords.longitude;
//     const coordinatesInput = document.getElementById("coordinatesInput");

//     // Update the input field with the latitude and longitude
//     coordinatesInput.value = `${latitude}, ${longitude}`;
//     setCoordinates(`${latitude}, ${longitude}`);
//     setLat(`${latitude}`);
//     setLong(`${longitude}`);
//   };


//   const showError = (error) => {
//     switch (error.code) {
//       case error.PERMISSION_DENIED:
//         alert("User denied the request for Geolocation.");
//         break;
//       case error.POSITION_UNAVAILABLE:
//         alert("Location information is unavailable.");
//         break;
//       case error.TIMEOUT:
//         alert("The request to get user location timed out.");
//         break;
//       case error.UNKNOWN_ERROR:
//         alert("An unknown error occurred.");
//         break;
//     }
//   };

//   console.log("aminites", aminities);
//   let dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(getUserPlan(userid));
//   }, []);
//   const getData = async () => {
//     let headersList = {
//       Accept: "*/*",
//     };

//     let bodyContent = new FormData();
//     bodyContent.append("user", userid);

//     let response = await fetch(localUrl + "adsapi/allPlanData", {
//       method: "POST",
//       body: bodyContent,
//       headers: headersList,
//     });
//     let data = await response.json();
//     setAllAdsCount(data);
//   };
//   const [updateState, setUpdateState] = useState(null);
//   const [cityWithState, setCityWithState] = useState(null);
//   useEffect(() => {
//     Object.keys(stateMain)?.filter((val) => {
//       if (val === updateState) {
//         setCityWithState(stateMain[val]);
//       }
//     });
//   }, [updateState]);
//   useEffect(() => {
//     getData();
//   }, []);

//   const [dataByPlan, setDataByplan] = useState([]);
//   const [plan, setPlan] = useState("");
//   const [adsCategory, setAdsCategory] = useState("");
//   const [usertype, setusertype] = useState('');
//   const [dayslimit, setDaysLimit] = useState(null);
//   const [planCategory1, setPlanCategory] = useState(null);
//   const [planType, setPlanType] = useState(null);
//   const [expireData, setExpireDate] = useState(null);
//   // error for without choosing plan
//   const [planError, setPlanError] = useState(false);
//   //set new loader
//   const [newLoader, setNewLoader] = useState(false);

//   // set New modal
//   const [openNewModal, setOpenNewModal] = useState(false);
//   const [openNewModal2, setOpenNewModal2] = useState(false);
//   const handleeOk = () => {
//     setOpenNewModal2(false);
//   };

//   const handleeCancel = () => {
//     setOpenNewModal2(false);
//   };

//   useEffect(() => {
//     if (plan !== "") {
//       setAdsCategory(data?.postAdsForm[plan]?.category[0]);
//     }
//   }, [plan]);

//   useEffect(() => {
//     setCity(null);
//   }, [state]);

//   useEffect(() => {
//     setsubCategoryValue(null);
//   }, [category]);

//   const [business, setBusiness] = useState(false); // Initialize with null
//   const [leftPlan, setLeftPlan] = useState([]); // Initialize with null
//   // const dataUser = useContext(UserContext);
//   // const dataUser = useContext(UserContext);
//   const useridst = localStorage.getItem("userid");


//   useEffect(() => {
//     var formdata = new FormData();
//     formdata.append("userid", dataUser.userid);

//     var requestOptions = {
//       method: "POST",
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(url + "api/adsapi/checkBusinessPlan", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         setBusiness(result.businessPlan);
//         setLeftPlan(result.leftPlan);
//         console.log(result, "resvaerarasrar");
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, []);

//   useEffect(() => {
//     if (adsCategory !== "" && plan !== "") {
//       let days;
//       if (plan === "Silver") {
//         days = 15;
//       } else if (plan === "Gold") {
//         days = 30;
//       } else if (plan === "Free") {
//         days = 7;
//       } else if (plan === "Platinum") {
//         days = 60;
//       } else if (plan === "featured") {
//         days = 90;
//       } else if (plan === "premium") {
//         days = 90;
//       }
//       setDaysLimit(days);
//       setPlanCategory(adsCategory);
//       setPlanType(plan);
//       setExpireDate(false);
//       setPlanError(false);
//     }
//   }, [adsCategory]);

//   useEffect(() => {
//     console.log(data.leftPlan, "jj");
//     var today = new Date();

//     var datevalue =
//       today.getFullYear() +
//       "-" +
//       (today.getMonth() + 1) +
//       "-" +
//       today.getDate();
//     console.log(datevalue);

//     setDate(datevalue);
//   }, []);

//   const navigate = useNavigate();

//   if (localStorage.getItem("access_token") == null) {
//     navigate("/login/");
//   }

//   const CategoryList = Category;
//   const coords = useGeolocated();
//   const handlePay = () => {
//     let formdata = {};
//     formdata["title"] = title;
//     formdata["price"] = price;
//     formdata["tags"] = tags;
//     formdata["description"] = description;
//     formdata["category"] = category;
//     formdata["subCategoryValue"] = subCategoryValue;
//     formdata["brand"] = brand;
//     formdata["condition"] = condition;
//     formdata["state"] = state;
//     formdata["city"] = city;
//     formdata["locality"] = dataUser?.locality;
//     formdata["zip_code"] = zipcode;
//     formdata["user"] = id;

//     if (image !== null) {
//       formdata["image"] = image;
//     }
//     console.log("checking value", formdata);
//     localStorage.setItem("payAdsData", JSON.stringify(formdata));
//   };
//   const name = userDataValue?.name;
//   const selectShortlistedApplicant = (e) => {
//     const checked = e.target.checked;
//     const value = e.target.value;

//     setrealEsateSubCategory(value);
//     if (checked) {
//       console.log("checkined");
//     } else {
//       // setfeatureTrue(null)
//       console.log("//unchecked");
//     }
//   };
//   const [categorysubCategoryFlag, setcategorysubCategoryFlag] = useState(false);
//   // if(category=="RealEstate"){
//   //   setcategorysubCategoryFlag(true)
//   // }
//   // const [lat ,setLat] =useState(null)
//   // const [long ,setLong]=useState(null)
//   const selector = useSelector((state) => state.activePlan);
//   const user1 = useContext(UserContext);
//   useEffect(() => {
//     console.log("##pricing plan ,selector ", selector, user1.pricing);
//     if (parseInt(dataUser?.activePlan) <= 0) {
//       setFormDisable(false);
//     }
//   }, [selector]);

//   const selectorExtraSlice = useSelector(
//     (state) => state.adsPostingExtraFiledSlice
//   );
//   useEffect(() => {
//     console.log("#### checking add product ", selectorExtraSlice);
//   }, [selectorExtraSlice]);
//   useEffect(() => {
//     if (true) {
//       var formdata = new FormData();
//       formdata.append("user", localStorage.getItem("userid"));

//       var requestOptions = {
//         method: "POST",
//         body: formdata,
//         redirect: "follow",
//       };

//       fetch(localUrl + "adsapi/getPricingViews", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           console.log("## pricing service response result", result);
//           disptach(addActivePlan({ plan: result }));
//           dataUser.setActivePlan(result);
//         })
//         .catch((error) => console.log("error", error));
//     }
//     console.log("###value user active plan", dataUser?.activePlan);
//   }, []);
//   // useEffect(() => {
//   //   getLocationSearchMethod();
//   // }, []);
//   const getLocationSearchMethod = () => {
//     const options = {
//       method: "GET",
//       url: "https://google-maps-geocoding.p.rapidapi.com/geocode/json",
//       params: {
//         address: dataUser?.locality,
//         language: "en",
//       },
//       headers: {
//         "X-RapidAPI-Key": "7fded38e7emsh3c4fb60f3b8017cp1c084bjsn32180c499f5f",
//         "X-RapidAPI-Host": "google-maps-geocoding.p.rapidapi.com",
//       },
//     };

//     axios
//       .request(options)
//       .then(function (response) {
//         console.log(response.data);

//         console.log(
//           "lat long ",
//           response.data.results[0].geometry.location.lat,
//           response.data.results[0].geometry.location.lng
//         );

//         setLat(response.data.results[0].geometry.location.lat);
//         setLong(response.data.results[0].geometry.location.lng);

//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   };

//   const qrcodeGenrate = (props) => {
//     console.log("|datauser vsalue", dataUser?.activePlan);
//     var myHeaders = new Headers();
//     myHeaders.append(
//       "Authorization",
//       "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkxMTQ1MzkwLCJpYXQiOjE2NTk2MDkzOTAsImp0aSI6IjM5MzJjZjJkZTYyNTQwMjRiNTEyZDk4ZTE4ZDM1Mjk3IiwidXNlcl9pZCI6MjJ9.6uTC3ZTlxdqyhBewkVN5O4MduQ6O6YCNB6p9QJhOF3w"
//     );

//     var formdata = new FormData();
//     formdata.append("product", props);

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(url + "api/user/qrCodeAds", requestOptions)
//       .then((response) => response.text())
//       .then((result) => console.log(result))
//       .catch((error) => console.log("error", error));
//   };

//   const imageUpload = (props) => {
//     setloading1(true);
//     setNewLoader(true);
//     qrcodeGenrate(props);
//     console.log("WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW", images);
//     for (var file in images) {
//       console.log(images[file]);

//       var formdata = new FormData();
//       formdata.append("imageList", images[file]);
//       formdata.append("adsId", props);

//       var requestOptions = {
//         method: "POST",
//         body: formdata,
//         redirect: "follow",
//       };
//       fetch(localUrl + `adsapi/adsUpload`, requestOptions)
//         .then((response) => response.text())
//         .then((result) => {
//           setloading1(true);
//           setNewLoader(false);
//           console.log(result);
//           setOpenNewModal(true);
//           // navigate(`/ads-listing/${props}/`);
//         })
//         .catch((error) => {
//           setloading1(true);
//           setNewLoader(false);
//           setError("Somethig went wrong..");
//         });
//     }
//     setloading1(false);
//   };

//   const handleInputChange = () => {
//     setAfterSubmitFlg(false);
//   };

//   const [ErrorExtraField, setErrorExtraField] = useState(false);
//   useEffect(() => {
//     if (category === "Jobs") {
//       setPrice(jobprice);
//       setTags(jobtags);
//     } else {
//       setPrice("");
//       setTags("");
//     }
//   }, [category]);
//   const handlebrandname = (value) => {
//     setBrand(value);
//     setBrandError(false);
//   };

//   const dateChangeHandler = (e) => {
//     setDate(e.target.value);
//     setDateError(false);
//   }

//   const aboutServicesHandler = (e) => {
//     setaboutservices(e.target.value);
//     setaboutservicesError(false);
//   }

//   const [companyname, setcompanyname] = useState('');
//   const [companyNameError, setCompanyNameError] = useState(false);
//   const [email, setemail] = useState('');
//   const [emailError, setEmailError] = useState(false);

//   const [website, setwebsite] = useState('');
//   const [websiteError, setWebsiteError] = useState(false);

//   const [workinghours, setworkinghours] = useState('');
//   const [workingHoursError, setWorkingHoursError] = useState(false);

//   const handleworkinghour = (value) => {
//     setworkinghours(value);
//     setWorkingHoursError(false);
//   };

//   const [noofemp, setnoofemp] = useState('');
//   const [noofempError, setnoofempError] = useState(false);
//   const [bussinesssince, setbussinesssince] = useState('');
//   const [businesssinceError, setbusinesssinceError] = useState(false);

//   const handlebussinesssince = (value) => {
//     setbussinesssince(value);
//     setbusinesssinceError(false);
//   };

//   const titleChangeHandler = (e) => {
//     setTitle(e.target.value);
//     setTitleError(false);
//   }

//   const [issuehandled, setissuehandled] = useState('');
//   const [issuehandledError, setissuehandledError] = useState(false);
//   const [aboutservices, setaboutservices] = useState('');
//   const [aboutservicesError, setaboutservicesError] = useState(false);
//   const [areacovered, setareacovered] = useState('');
//   const [areacoveredError, setareacoveredError] = useState(false);
//   const [cityserviced, setcityserviced] = useState('');
//   const [cityservicedError, setcityservicedError] = useState(false);


//   const addNewProduct = async () => {
//     setNewLoader(true);

//     setIsLoading(true);
//     var aKeys = Object.keys(finalObj).sort();
//     var bKeys = Object.keys(obj).sort();

//     if (!(JSON.stringify(aKeys) === JSON.stringify(bKeys))) {
//       setErrorExtraField(true);
//       setAdsLimitCheck(true);
//       setIsLoading(false);
//     } else {
//       setErrorExtraField(false);
//       setstoreadsFlag(true);

//       if (
//         expireData ||
//         !("0" in images) ||
//         !title ||
//         // !tags ||
//         !city ||
//         !description ||
//         !category ||
//         !state ||
//         !usertype ||
//         !price ||
//         // !dataUser?.locality ||
//         !zipcode ||
//         zipcode.length < 6 ||
//         !phoneNumber ||
//         phoneNumber.length < 10
//       ) {
//         setAfterSubmitFlg(true);
//         setError("* Please fill this field..");
//         setNewLoader(false);
//         setIsLoading(false);
//       } else if (!data.leftPlan.length) {
//         console.log("hgh");
//         showModal();
//         setIsLoading(false);
//       } else {
//         let formdata = new FormData();
//         if (true) {
//           setNewLoader(false);
//           for (var file in images) {
//             formdata.append("image", images[file]);
//             console.log("~~~image");
//             break;
//           }
//         }

//         var myHeaders = new Headers();
//         myHeaders.append(
//           "Authorization",
//           "Bearer " + localStorage.getItem("access_token")
//         );

//         formdata.append("title", title);

//         formdata.append("price", price);
//         // formdata.append("tags", tags);
//         formdata.append("description", description);
//         formdata.append("category", category);
//         formdata.append("brand", brand);
//         formdata.append("Engine", Engine);
//         formdata.append("Year", Year);
//         formdata.append("condition", condition);
//         formdata.append("state", state);
//         formdata.append("City", city);
//         formdata.append("locality", locality);
//         // formdata.append("locality", dataUser?.locality);
//         // formdata.append("locality", dataUser?.locality);
//         // formdata.append("locality","kihash");
//         formdata.append("zip_code", zipcode);
//         formdata.append("user", userDataValue.id);
//         formdata.append("subCategoryType", subCategoryType);
//         formdata.append("subCategoryValue", subCategoryValue);
//         formdata.append("lati", lat);
//         formdata.append("long", long);
//         formdata.append("phoneNumber", phoneNumber);
//         formdata.append("BuildUpArea", BuildUpArea);
//         formdata.append("Flor", Flor);
//         formdata.append("ApartMentType", ApartMentType);
//         formdata.append("Availability", Availability);
//         formdata.append("FurnishedType", FurnishedType);
//         formdata.append("Property", Property);
//         formdata.append("Parking", Parking);
//         formdata.append("PowerBackup", PowerBackup);
//         formdata.append("Gym", Gym);
//         formdata.append("Garden", Garden);
//         formdata.append("Pool", Pool);
//         formdata.append("Lift", Lift);
//         formdata.append("colorCheck", colorCheck);
//         formdata.append("sizeCheck", sizeCheck);
//         formdata.append("oldPetsCheck", oldPetsCheck);
//         // for chat purpose
//         formdata.append("uuid", localStorage.getItem("uuid"));
//         formdata.append("date_created", date);
//         formdata.append("PlanCategory", updateCategory);
//         formdata.append("featured_ads", updateFeatured_ads);
//         formdata.append("ads_limit", updateAds_limit);
//         formdata.append("ads_timing", updateAds_timimg);
//         formdata.append("top_listing", updateTop_listing);
//         formdata.append("support", updateSupport);
//         formdata.append("user_type", usertype);

//         formdata.append("adsType", planCategory1);
//         formdata.append("plan", planType);
//         formdata.append("DaysLimit", dayslimit);
//         formdata.append("expiry", expireData);
//         // dayslimit, planCategory1, planType, expireData
//         const dataToSend = {
//           companyname: companyname,
//           email: email,
//           address: website,
//           working: workinghours,
//           employee: noofemp,
//           Since: bussinesssince,
//           issuehandled: issuehandled,
//           aboutservices: aboutservices,
//           areacovered: areacovered,
//           cityserviced: cityserviced,
//           finalObj
//         };

//         formdata.append("extraField", JSON.stringify(dataToSend));

//         var requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: formdata,
//           redirect: "follow",
//         };
//         setloading1(true);
//         fetch(url + "api/adsapi/", requestOptions)
//           .then((response) => response.json())
//           .then((result) => {
//             setNewLoader(false);
//             setSuccessValue(true);
//             setErrorValue(false);
//             if (result?.detail) {
//               setErrorValue(true);
//               setSuccessValue(false);
//               alert("token expire");
//               localStorage.removeItem("access_token");
//               navigate("/");
//               setloading1(false);
//             }
//             console.log("result", result);
//             if (result.status !== "OK" && result?.DaysLimit && result?.expiry) {
//               setPlanError(true);
//             } else if (!result?.id) {
//               alert("Ads not posted");
//             } else {
//               imageUpload(result.id);
//             }
//             setIsLoading(false);
//             console.log(result);
//           })
//           .catch((error) => {
//             //isloading false
//             setIsLoading(false);
//             setloading1(false);
//             setError("Something went wrong! Please come after Sometimes..");
//             setErrorValue(true);
//             setSuccessValue(false);
//             setNewLoader(false);
//             console.log("error", error);
//           });
//       }
//       setstoreadsFlag(false);
//       setloading1(false);
//       // setIsLoading(false);
//     }
//   };
//   const [files, setFiles] = useState([]);
//   ///function for multiple image upload
//   const fileSelectedHandler = (e) => {
//     setImageError(false);
//     console.log("imag efiles that one", e.target.files);
//     files.push(e.target.files[0]);
//     setFiles(files);
//     console.log("!!!!!!!!!!!state", files);
//   };

//   useEffect(() => {
//     console.log("@@@@@  datause.locality", dataUser?.locality);
//     if (dataUser?.locality) {
//       getLocationSearchMethod();
//     }
//   }, [dataUser?.locality]);
//   useEffect(() => {
//     var today = new Date();
//     var datevalue =
//       today.getFullYear() +
//       "-" +
//       (today.getMonth() + 1) +
//       "-" +
//       today.getDate();
//     console.log(datevalue);
//     setDate(datevalue);
//   }, []);

//   //for adding multiple values their

//   const [finalObj, SetFinalObj] = useState({});
//   const [obj, setObj] = useState({});
//   const [extraFeildToggle, setExtraFeildToggle] = useState(false);
//   useEffect(() => {
//     if (subCategoryValue && subCategoryInput[category] !== undefined) {
//       let subcategoryy = subCategoryInput[category];
//       if (subcategoryy[subCategoryValue] !== undefined) {
//         setExtraFeildToggle(true);
//         let values = subcategoryy[subCategoryValue];
//         let obj1 = values;
//         Object.keys(obj1).forEach((key) => {
//           let varkey = [];
//           varkey = obj1[key].map((result) => {
//             if (typeof result === "object" && result !== null) {
//               return result;
//             } else {
//               return { value: result };
//             }
//           });
//           obj1[key] = varkey;
//         });

//         console.log(obj1, subcategoryy, subcategoryy[subCategoryValue]);
//         setObj(obj1);
//       } else {
//         setExtraFeildToggle(false);
//       }
//     } else {
//       setExtraFeildToggle(false);
//     }
//   }, [subCategoryValue]);

//   useEffect(
//     (e) => {
//       console.log("!!!category changes");
//       // setsubCategoryType(e.currentTarget)
//     },
//     [category]
//   );
//   const onChangeplan = (e) => {
//     setcurrentSelectedplan(e.target.value);
//     console.log(`checked = ${e.target.value}`);
//   };
//   console.log("obj", obj);
//   const style = {
//     position: "fixed",
//     top: "50%",
//     left: "60%",
//     transform: "translate(-50%, -50%)",
//   };
//   document.title = "Hola9 - AddProducts";
//   const [change, setChange] = useState("1");
//   const [changeState, setChangeState] = useState("2");
//   const [changeCity, setChangeCity] = useState("1");
//   const [changeSubCat, setChangeSubCat] = useState("1");
//   const [subCateReal, setSubCateReal] = useState("1");
//   const [subCateRealType, setSubCateRealType] = useState("1");
//   const [showSecondSection, setShowSecondSection] = useState(false);
//   const [showThirdSection, setShowThirdSection] = useState(false);
//   const [otpverifychecklocal, setotpverifychecklocal] = useState(null);
//   const [PhoneNumbertemp, setPhoneNumbertemp] = useState(true);

//   const toggleSecondSection = () => {
//     setShowSecondSection((prevState) => !prevState);
//   };

//   const toggleSecondSection1 = () => {
//     setOpenNewModal2((prevState) => !prevState);
//   };
//   localStorage.setItem("number", phoneNumber);
//   console.log(
//     "lcaolp phone number",
//     localStorage.getItem("number"),
//     phoneNumber
//   );
//   const storePhoneNumer = (phone) => {
//     var formdata = new FormData();
//     formdata.append("phoneNubmer", phone);

//     var requestOptions = {
//       method: "POST",
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(localUrl + "adsapi/collectVisitPhoneNumber", requestOptions)
//       .then((response) => response.text())
//       .then((result) => console.log(result))
//       .catch((error) => console.log("error", error));
//   };

//   const SendOtp = (e) => {
//     e.preventDefault();
//     var gen = "1231";
//     setOpenNewModal2(true);
//     if (subCategoryValue == null) {
//       setSubCategoryError(true);
//     }
//     if (Object.entries(images).length === 0) {
//       setImageError(true);
//     } else {
//       setImageError(false);
//     }
//     // console.log("phonenumber", phoneNumber);
//     setotpverifychecklocal(gen);
//     axios({
//       method: "POST",
//       url: url + "api/phone/sendOTP/",
//       data: {
//         number: phoneNumber,
//       },
//     })
//       .then((res) => {
//         if (true) {
//           if (res.data.OTPSent === true) {
//             setOpenNewModal2(true);
//             storePhoneNumer(phoneNumber);
//           } else {
//             alert("OTP Was Not sent");
//           }
//         } else {
//           alert("Enter Valid 10 Digits Number Only");
//         }
//       })
//       .catch((e) => console.log(e));
//   };

//   const [otpVeriftemp, setotpVeriftemp] = useState(true);

//   const [Otpdetails, setOtpdetails] = useState(null);
//   const [buttonshow, setbuttonshow] = useState(false);

//   const otpverifysumbit = (e) => {
//     // console.log(typeof Otpdetails);
//     console.log(Otpdetails);
//     e.preventDefault();
//     axios({
//       method: "PUT",
//       url: url + "api/phone/checkOTP/",
//       data: {
//         number: phoneNumber,
//         otp: Otpdetails,
//       },
//     })
//       .then((res) => {
//         if (res.data.status === true) {
//           //calling registe  method
//           setShowSecondSection(true);
//           setOpenNewModal2(false);
//           setotpVeriftemp(false);
//           setbuttonshow(true);
//         } else {
//           alert("Incorrect OTP");
//           // setOpenNewModal2(false);
//           // setShowSecondSection(true)
//         }
//       })
//       .catch((e) => console.log(e));
//   };

//   const ContactDetails = (e) => {
//     getLocation();
//     e.preventDefault();
//     if (companyname == '') {
//       setCompanyNameError(true);
//     }
//     if (email == '') {
//       setEmailError(true);
//     }
//     if (website == '') {
//       setWebsiteError(true);
//     }
//     if (description == '') {
//       setDescriptionError(true);
//     }
//     if (workinghours == '') {
//       setWorkingHoursError(true)
//     }
//     if (noofemp == '') {
//       setnoofempError(true)
//     }
//     if (bussinesssince == '') {
//       setbusinesssinceError(true);
//     }

//     if (companyNameError || emailError || websiteError || descriptionError || workingHoursError || noofempError || businesssinceError) {
//       setShowThirdSection(false);

//     }
//     if (!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError) {
//       setShowThirdSection(true);
//     }
//     // if(!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError){
//     //   setShowThirdSection(true);
//     // }
//   };

//   const [onevisible, setonevisible] = useState(true);
//   const [onevisible1, setonevisible1] = useState(false);
//   const [onevisible2, setonevisible2] = useState(false);
//   const [onevisible3, setonevisible3] = useState(false);
//   const [onevisible4, setonevisible4] = useState(false);

//   const handlevis1 = () => {
//     setonevisible(false)
//     setonevisible1(true)
//     setonevisible2(true)
//     setonevisible3(false)
//   }
//   const handlevis2 = () => {
//     setonevisible(true)
//     setonevisible1(false)
//     setonevisible3(true)
//     setonevisible2(false)
//     setonevisible4(false)

//   }
//   const handlevis3 = () => {



//     if (issuehandled == '') {
//       setissuehandledError(true)
//     }
//     if (brand == '') {
//       setBrandError(true)
//     }
//     if (aboutservices == '') {
//       setaboutservicesError(true);
//     }
//     if (areacovered == '') {
//       setareacoveredError(true);
//     }
//     if (cityserviced == '') {
//       setcityservicedError(true);
//     }
//     if (price == '') {
//       setPriceError(true);
//     }
//     if (date == '') {
//       setDateError(true);
//     }
//     if (state == '') {
//       setStateError(true);
//     }
//     if (city == '') {
//       setCityError(true);
//     }
//     if (locality == '') {
//       setLocalityError(true);
//     }
//     if (zipcode == '') {
//       setZipcodeError(true);
//     }

//     if (issuehandledError || brandError || aboutservicesError || cityservicedError || areacoveredError || cityservicedError || priceError || stateError || cityError || localityError || zipcodeError) {
//       setonevisible3(false);
//       setonevisible2(false);
//       setonevisible4(false)
//     }
//     if (!issuehandledError && !brandError && !aboutservicesError && !cityservicedError && !areacoveredError && !cityservicedError && !priceError && !stateError && !cityError && !localityError && !zipcodeError) {
//       setShowSecondSection(false)
//       setShowThirdSection(false)
//       setonevisible3(true);
//       setonevisible4(true);
//     }
//   }
//   const handlevis4 = () => {
//     setonevisible3(false)
//     setonevisible2(true)
//     setonevisible1(true)
//     setonevisible(false)
//     setonevisible4(false)
//   }

//   return (
//     <div className="container shadow bg-white">
//       {/* <Spin tip="Loading..." spinning={newLoader} /> */}
//       {!formDisable && <h2 className="text-danger">Your plan has expired</h2>}

//       <fieldset disabled={!formDisable}>
//         <div>
//           <div
//             className="white mx-auto"
//             style={isMobile ? mobileStyle1 : desktopStyle1}
//           >
//             <h1 style={{ fontFamily: "serif", fontSize: "40px", fontWeight: "bold", color: "#4a4a4a" }}>Post Your <span style={{ color: "red" }}>{categories}</span> Ads Here</h1>
//             <br />

//             {onevisible1 && (
//               <div style={{ width: "100%", backgroundColor: "whitesmoke", display: "flex", justifyContent: "space-between", alignItems: "center", border: "2px dotted black" }}>
//                 <div style={{ backgroundColor: "navy", color: "white", padding: "10px 15px", borderRadius: "20px", fontWeight: "700", marginLeft: "15px" }}>1</div>
//                 <h2 style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}>Ads Details</h2>
//                 <div onClick={handlevis2} style={{ marginRight: "15px", fontSize: "25px", fontWeight: "bolder", color: "blue" }}> <TbPlayerTrackNext /> </div>
//               </div>
//             )}
//             <br></br>
//             {onevisible && (
//               <>
//                 <div className="row p-2">
//                   <div className="col-lg-12">
//                     <label>Upload Templets</label>
//                     <MultiImageInput
//                       max={10}
//                       images={images}
//                       setImages={setImages}
//                       cropConfig={{ crop, ruleOfThirds: true }}
//                       theme={{
//                         background: "#ffffff",
//                         outlineColor: "#111111",
//                         textColor: "rgba(255,255,255,0.6)",
//                         buttonColor: "#ff0e1f",
//                         modalColor: "#ffffff",
//                       }}
//                     />
//                   </div>
//                   {imageError && <p style={{ color: 'red' }}>Please Upload the Images</p>}
//                   {MoreImage ? (
//                     <div className="col-lg-8">
//                       <div>
//                         <form className="padingmanage">
//                           <input type="file" onChange={fileSelectedHandler} />
//                         </form>
//                         <Container>
//                           <Row>
//                             {files.map((result) => {
//                               return (
//                                 <Col xs={2} md={3}>
//                                   {" "}
//                                   <a alt="" href="" className="block-icon">
//                                     <Image
//                                       src={URL.createObjectURL(result)}
//                                       thumbnail
//                                     />

//                                     <FaCut
//                                       className=" icon-tag"
//                                       onClick={(e) => {
//                                         e.preventDefault();
//                                         // this.removeItem(result)
//                                       }}
//                                     />
//                                   </a>{" "}
//                                 </Col>
//                               );
//                             })}
//                           </Row>
//                         </Container>
//                       </div>
//                     </div>
//                   ) : null}
//                   {!("0" in images) && (
//                     <small className="text-danger ml-2">{error}</small>
//                   )}

//                   {/* <div className="col-lg-6 col-sm-12">
//               <label>Video upload</label>
//               <div style={{border:'2px solid black',padding:'20px'}}>
//                <VideoInput height={200}/>
//                </div>
//               </div> */}
//                 </div>
//                 <div className="row p-3" style={{ marginBottom: "-20px" }}>
//                   <div className="col-lg-6 col-sm-12" style={{ display: "none" }}>
//                     <label className="mb-0 mt-2">Category</label>
//                     <input
//                       className="myinput"
//                       type="text"
//                       name="category"
//                       placeholder="enter category here"
//                       value={category}
//                       style={{
//                         padding: "7px",
//                         borderRadius: "3px",
//                       }}
//                     />
//                     {!change && (
//                       <small className="text-danger ml-2">
//                         *Select right category
//                       </small>
//                     )}
//                     {change == false ? (
//                       <span className="text-danger ml-2">
//                         select right category
//                       </span>
//                     ) : change && !category && afterSubmitFlg ? (
//                       <small className="text-danger ml-2">
//                         {CreateAds.Category}
//                       </small>
//                     ) : null}
//                     {/* {!category && afterSubmitFlg && <span className="text-danger ml-2">{CreateAds.Category}</span>} */}
//                   </div>

//                   {category != "RealEstate" ? (
//                     <div className="col-lg-6 col-sm-12">
//                       <label className="mb-0 mt-2">Sub Category</label>

//                       <Select
//                         className="custom-select1"
//                         style={{
//                           // padding: "10px",
//                           width: "100%",
//                           zIndex: "-10px",
//                           // borderRadius: "3px",
//                         }}
//                         getPopupContainer={(triggerNode) =>
//                           triggerNode.parentElement
//                         }
//                         allowClear
//                         value={subCategoryValue}
//                         status={changeSubCat ? "" : "error"}
//                         onChange={(value) => {
//                           console.log("1276", value);

//                           setsubCategoryValue("");
//                           setSubCategoryError(false);
//                           setAfterSubmitFlg(false);
//                           setTimeout(() => {
//                             setsubCategoryValue(value);
//                           }, 0);

//                           setChangeSubCat(value);

//                           SetFinalObj({});
//                         }}
//                         showSearch
//                         placeholder="Select Sub Category"
//                         optionFilterProp="children"
//                         filterOption={(input, option) =>
//                           (option?.label ?? "").includes(input)
//                         }
//                         filterSort={(optionA, optionB) =>
//                           (optionA?.label ?? "")
//                             .toLowerCase()
//                             .localeCompare((optionB?.label ?? "").toLowerCase())
//                         }
//                         options={
//                           category == "Services"
//                             ? Services.sort().map((result) => {
//                               return { value: result, label: result };
//                             })
//                             : null
//                         }
//                       />

//                       {subCategoryError == true && <p style={{ color: 'red' }}>Please Select the SubCategory</p>}
//                       {!changeSubCat && (
//                         <small className="text-danger ml-2">
//                           *Select right sub category
//                         </small>
//                       )}
//                       {subcategoryForAll && subCat == false ? (
//                         <small className="text-danger ml-2">
//                           *Select right category
//                         </small>
//                       ) : changeSubCat && !subCategoryValue && afterSubmitFlg ? (
//                         <small className="text-danger ml-2">
//                           *Please Enter The Sub Category
//                         </small>
//                       ) : null}
//                     </div>
//                   ) : null}

//                   {/* Education Subcategory Combination of dropdowns and textfields */}

//                   <div>
//                     <Row className="my-1">
//                       {subCategoryValue && obj && extraFeildToggle
//                         ? Object?.keys(obj)?.map((key, index) => {
//                           return (
//                             <Col span={6} style={{ marginTop: "15px" }}>
//                               <Select
//                                 getPopupContainer={(trigger) =>
//                                   trigger.parentNode
//                                 }
//                                 style={isMobile ? mobileStyle : desktopStyle}
//                                 notFoundContent="Please select right field"
//                                 options={obj[key]}
//                                 placeholder={key}
//                                 autoFocus={true}
//                                 onChange={(e) => {
//                                   finalObj[key] = e;
//                                   SetFinalObj(finalObj);
//                                 }}
//                                 filterOption={(inputValue, option) =>
//                                   option.value
//                                     .toUpperCase()
//                                     .indexOf(inputValue.toUpperCase()) !== -1
//                                 }
//                               />
//                             </Col>
//                           );
//                         })
//                         : null}
//                     </Row>
//                   </div>
//                 </div>




//                 <div className="row p-3 d-flex" style={{ marginTop: "-40px", display: "flex" }}>

//                   <div className="col-lg-6 col-sm-12">
//                     <label className="mb-0 mt-2">Phone Number</label>
//                     <input
//                       // className="inpstyle"
//                       className="myinput"
//                       name="phoneNumber"
//                       placeholder="Enter phone number"
//                       maxLength={10}
//                       type="number"
//                       onInput={(e) => {
//                         if (e.target.value.length > e.target.maxLength)
//                           e.target.value = e.target.value.slice(
//                             0,
//                             e.target.maxLength
//                           );
//                       }}
//                       onKeyDown={(e) =>
//                         ["e", "E", "+", "-", "."].includes(e.key) &&
//                         e.preventDefault()
//                       }
//                       style={{
//                         width: "100%",
//                         zIndex: "-50px",
//                         border: "1px solid #5d5d5d3d",
//                         padding: "7px",
//                         borderRadius: "3px",
//                       }}
//                       onChange={(e) => {
//                         setphoneNumber(e.target.value);
//                       }}
//                     />
//                     {phoneNumber.length < 10 && phoneNumber.length > 0 ? (
//                       <small className="text-danger ml-2">Enter valid Number</small>
//                     ) : phoneNumber.length > 10 ? (
//                       <small className="text-danger ml-2">
//                         Enter only 10 digit Number
//                       </small>
//                     ) : !phoneNumber && afterSubmitFlg ? (
//                       <small className="text-danger ml-2">
//                         {CreateAds.PhoneNumber}
//                       </small>
//                     ) : null}
//                   </div>
//                   <div>
//                     {/* <Button varient="outlined"  onClick={(e) => {toggleSecondSection1(); sendOtp(e);}} style={{float:"right",color:"green"}}>Send Otp</Button> */}

//                     <Button
//                       varient="outlined"
//                       onClick={SendOtp}
//                       style={{ float: "right", color: "green" }}
//                     >
//                       Send Otp
//                     </Button>
//                   </div>
//                 </div>
//                 {buttonshow && (
//                   <Button varient="outlined" onClick={subCategoryValue === "" ? null : handlevis1} style={{ display: "flex", float: "right", color: "red" }}> Next </Button>
//                 )
//                 }
//               </>

//             )}

//             <br></br>
//             {openNewModal2 && (
//               <Modal title="VERIFY OTP" open={openNewModal2} onOK={otpverifysumbit} onCancel={cancelHandler}>
//                 <form class="form-inline">
//                   <div class="col-lg-12 " style={{ marginTop: "-20px" }}>
//                     <label htmlFor="input" className="sr-only">
//                       Enter Your otp
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="text"
//                       onChange={(e) => {
//                         setOtpdetails(e.target.value);
//                       }}
//                       placeholder="Enter Your otp"
//                     />
//                   </div>
//                   <button
//                     className="custom-btns btn-1 mt-3 mx-3 "
//                     onClick={otpverifysumbit}
//                   >
//                     verify number
//                   </button>
//                 </form>
//               </Modal>
//             )}
//             <br /><br />
//             {/* <div classname="my-5">
//                 {category === "Services" ? <Service1 /> : null}
//               </div> */}
//             {/* <div className="row-hover"> */}
//             {onevisible3 && (
//               <div style={{ width: "100%", backgroundColor: "whitesmoke", display: "flex", justifyContent: "space-between", alignItems: "center", border: "2px dotted black" }}>
//                 <div style={{ backgroundColor: "navy", color: "white", padding: "10px 15px", borderRadius: "20px", fontWeight: "700", marginLeft: "15px" }}>2</div>
//                 <h2 style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}>Personal & Bussines Details</h2>
//                 <div onClick={handlevis4} style={{ marginRight: "15px", fontSize: "25px", fontWeight: "bolder", color: "blue" }}> <TbPlayerTrackNext /> </div>
//               </div>
//             )}
//             <br></br>
//             {onevisible2 && (
//               <>
//                 {showSecondSection && (
//                   <>
//                     <h5> Contact Details</h5>
//                     <div className="d-flex" style={{ marginTop: "-5px" }}>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">User Name</label>
//                         <input
//                           className="myinput"
//                           style={{
//                             borderRadius: "3px",
//                             padding: "7px",
//                           }}
//                           type="text"
//                           name="id"
//                           disable={true}
//                           placeholder="USER NAME"
//                           value={name}
//                         />
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Company Name</label>
//                         <input
//                           autoComplete="none"
//                           className="myinput"
//                           type="text"
//                           name="price"
//                           placeholder="Enter Your Name here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={companyChangeHandler}
//                         />
//                         {companyNameError && <p style={{ color: 'red' }}>Enter the Company Name</p>}
//                       </div>

//                     </div>
//                     <div className="d-flex">
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Email id</label>
//                         <input
//                           className="myinput"
//                           type="email"
//                           name="locality"
//                           placeholder="Enter Email"
//                           onChange={emailChangeHandler}
//                         />
//                         {emailError && <p style={{ color: 'red' }}>Enter the Email Address</p>}
//                       </div>
//                     </div>
//                     <br></br>
//                     <h5> Business Details</h5>
//                     <div className="d-flex" style={{ marginTop: "-5px" }}>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Website</label>
//                         <input
//                           autoComplete="none"
//                           className="myinput"
//                           type="text"
//                           name="Website"
//                           placeholder="Enter Your Website here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={websiteChangeHandler}
//                         />
//                         {websiteError && <p style={{ color: 'red' }}>Enter Your Website Address</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">
//                           About Company (if Applicable)
//                         </label>
//                         <input
//                           className="myinput"
//                           name="About Company "
//                           placeholder="Enter About Company"
//                           maxLength={10}
//                           type="text"
//                           style={{ resize: "none" }}
//                           value={description}
//                           onChange={descriptionChangeHandler}
//                         />
//                         {descriptionError && <p style={{ color: 'red' }}>Enter About Company</p>}
//                         {!description && afterSubmitFlg && (
//                           <small className="text-danger ml-2">{CreateAds.disc}</small>
//                         )}
//                       </div>
//                     </div>
//                     <div className="d-flex" style={{ marginTop: "-5px" }}>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Working Hours</label>
//                         <Select
//                           className="custom-select1"
//                           style={{
//                             // padding: "10px",
//                             width: "100%",
//                             zIndex: "-10px",
//                             // borderRadius: "3px",
//                           }}
//                           placeholder="Working Hours"
//                           getPopupContainer={(triggerNode) =>
//                             triggerNode.parentElement
//                           }
//                           allowClear

//                           options={
//                             Workinghours.sort().map((result) => {
//                               return { value: result, label: result };
//                             })

//                           }
//                           onChange={handleworkinghour}
//                         />
//                         {workingHoursError && <p style={{ color: 'red' }}>Enter the Working Hours</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">No of Employees</label>
//                         <input
//                           className="myinput"
//                           name="No of Employees"
//                           placeholder="Enter No of Employees"
//                           maxLength={10}
//                           type="number"
//                           style={{
//                             width: "100%",
//                             zIndex: "-50px",
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={NoOfEmployeesHandler}
//                         />
//                         {noofempError && <p style={{ color: 'red' }}>Enter the No Of Employees</p>}
//                       </div>
//                     </div>
//                     <div className="d-flex" style={{ marginTop: "-5px" }}>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Business Since</label>
//                         <Select
//                           className="custom-select1"
//                           style={{
//                             // padding: "10px",
//                             width: "100%",
//                             zIndex: "-10px",
//                             // borderRadius: "3px",
//                           }}
//                           placeholder="Bussines Since"
//                           getPopupContainer={(triggerNode) =>
//                             triggerNode.parentElement
//                           }
//                           allowClear

//                           options={
//                             YearOfRegistrationCar.sort().map((result) => {
//                               return { value: result, label: result };
//                             })

//                           }
//                           onChange={handlebussinesssince}
//                         />
//                         {businesssinceError && <p style={{ color: 'red' }}>Enter the Business since</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Upload Logo</label>
//                         <input
//                           className="myinput"
//                           name="Upload Logo"
//                           placeholder="Upload Logo"
//                           maxLength={10}
//                           type="file"
//                           style={{
//                             width: "100%",
//                             zIndex: "-50px",
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                         />
//                       </div>
//                     </div>
//                     <br></br>
//                     <div>
//                       <Button
//                         varient="outlined"
//                         onClick={ContactDetails}
//                         style={{ float: "right", color: "green" }}
//                       >
//                         Submit details
//                       </Button>
//                     </div>
//                   </>
//                 )}


//                 {!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError && showThirdSection && (
//                   <>
//                     <div>
//                       <h5> Service Details</h5>
//                       <div style={{ marginTop: "-5px" }}>
//                         <div className="col-lg-6 col-sm-12">
//                           <label className="mb-0 mt-2">Service Name</label>
//                           <input
//                             className="myinput"
//                             type="text"
//                             name="Service Name"
//                             placeholder="Enter Your Service Name here"
//                             value={subCategoryValue}
//                             style={{
//                               padding: "7px",
//                               borderRadius: "3px",
//                             }}
//                             onChange={titleChangeHandler}
//                           />
//                           {!title && afterSubmitFlg && (
//                             <small className="text-danger ml-2">{CreateAds.title}</small>
//                           )}
//                         </div>
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">
//                           Types of Service
//                         </label>
//                         <input
//                           className="myinput"
//                           type="text"
//                           name="Types of Service"
//                           placeholder="Enter Your Types of Service here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           value={JSON.stringify(finalObj)}
//                         />
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Issues Handled</label>
//                         <input
//                           className="myinput"
//                           type="text"
//                           name="Issues Handled"
//                           placeholder="Enter Your Issues Handled here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={issueChangeHandler}
//                         />
//                         {issuehandledError && <p style={{ color: 'red' }}>Enter Your Issues Handled here</p>}
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Brand</label>
//                         <Select
//                           className="custom-select1"
//                           placeholder="Brand Name"
//                           style={{
//                             // padding: "10px",
//                             width: "100%",
//                             zIndex: "-10px",
//                             // borderRadius: "3px",
//                           }}
//                           getPopupContainer={(triggerNode) =>
//                             triggerNode.parentElement
//                           }
//                           allowClear

//                           options={
//                             WashingMachineBrand.sort().map((result) => {
//                               return { value: result, label: result };
//                             })

//                           }
//                           onChange={handlebrandname}
//                         />
//                         {brandError && <p style={{ color: 'red' }}>Select the brand Name</p>}
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">About service</label>
//                         <input
//                           className="myinput"
//                           type="text"
//                           name="About service"
//                           placeholder="Enter Your About service here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={aboutServicesHandler}
//                         />
//                         {aboutservicesError && <p style={{ color: 'red' }}>Enter Your About Services Here</p>}
//                       </div>

//                       {/* <div className="col-lg-6 col-sm-12">
//                     <label className="mb-0 mt-2">Upload image</label>
//                     <input
//                       className="myinput"
//                       type="file"
//                       name="Area Covered"
//                       placeholder="Enter Your Area Covered here"
//                       style={{
//                         border: "1px solid #5d5d5d3d",
//                         padding: "7px",
//                         borderRadius: "3px",
//                       }}
//                     />
//                   </div> */}

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Area Covered</label>
//                         <input
//                           className="myinput"
//                           type="text "
//                           name="Area Covered"
//                           placeholder="Enter Your Area Covered here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={areaCoveredHandler}
//                         />
//                         {areacoveredError && <p style={{ color: 'red' }}>Enter Your Area Covered here</p>}
//                       </div>

//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Cities Serviced</label>
//                         <input
//                           className="myinput"
//                           type="text"
//                           name="Cities Serviced"
//                           placeholder="Enter Your Cities Serviced here"
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={cityservicedHandler}
//                         />
//                         {cityservicedError && <p style={{ color: 'red' }}>Enter the Cities Serviced Here</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Price</label>
//                         <input
//                           autoComplete="none"
//                           className="myinput"
//                           type="number"
//                           name="price"
//                           placeholder="Enter price here"
//                           value={price}
//                           onKeyDown={(e) =>
//                             ["e", "E", "+", "-", "."].includes(e.key) &&
//                             e.preventDefault()
//                           }
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={(e) => {
//                             /[^0-9a-zA-Z]/.test(setPrice(e.target.value))
//                               ? /[^0-9a-zA-Z]/.test(setPrice(e.target.value))
//                               : setPrice(e.target.value);
//                             setPriceError(false);
//                           }}
//                           pattern="/^[0-9a-zA-Z]+$/"
//                         />
//                         {!price && afterSubmitFlg && (
//                           <small className="text-danger ml-2">{CreateAds.Price}</small>
//                         )}
//                         {priceError && <p style={{ color: 'red' }}>Enter the Price</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Date</label>
//                         <input
//                           className="myinput p-2"
//                           style={{
//                             border: "1px solid #8080804d",
//                             borderRadius: "3px",
//                             padding: "6px",
//                           }}
//                           type="text"
//                           name="date"
//                           placeholder="enter date here"
//                           value={date}
//                           onChange={dateChangeHandler}
//                           disabled
//                         />
//                         {dateError && <p style={{ color: 'red' }}>Enter Date here</p>}
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Coordinates</label>
//                         <div className="d-flex">
//                           <input
//                             type="text"
//                             className="myinput"
//                             id="coordinatesInput"
//                             readOnly value={coordinates}
//                           />
//                           {/* <FaAdn  /> */}
//                         </div>

//                       </div>
//                     </div>
//                     <div>
//                       <h5>More information</h5>
//                       <div className="d-flex">
//                         <div className="col-lg-6 col-sm-12">
//                           <label className="mb-0 mt-2">State</label>

//                           <Select
//                             className="custom-select1"
//                             style={{
//                               // padding: "10px",
//                               width: "100%",
//                               zIndex: "-10px",
//                               // borderRadius: "3px",
//                             }}
//                             autoComplete="none"
//                             getPopupContainer={(triggerNode) => triggerNode.parentElement}
//                             allowClear
//                             status={changeState ? "" : "error"}
//                             onChange={(value) => {
//                               setChangeState(value);
//                               console.log("1553", value);
//                               setUpdateState(value);
//                               setState(value);
//                               setStateError(false);
//                               setChangeCity("");
//                             }}
//                             showSearch
//                             placeholder="Select State"
//                             optionFilterProp="children"
//                             filterOption={(input, option) =>
//                               (option?.label ?? "").includes(input)
//                             }
//                             filterSort={(optionA, optionB) =>
//                               (optionA?.label ?? "")
//                                 .toLowerCase()
//                                 .localeCompare((optionB?.label ?? "").toLowerCase())
//                             }
//                             options={Object.keys(stateMain)?.map((result) => {
//                               return { value: result, label: result };
//                             })}
//                           />

//                           {!changeState && (
//                             <small className="text-danger ml-2">
//                               *Select right state
//                             </small>
//                           )}
//                           {changeState == false ? (
//                             <small className="text-danger ml-2">
//                               *Choose right state
//                             </small>
//                           ) : changeState && !state && afterSubmitFlg ? (
//                             <small className="text-danger ml-2">{CreateAds.State}</small>
//                           ) : null}
//                           {stateError && <p style={{ color: 'red' }}>Select Your State</p>}
//                           {/* {!state && afterSubmitFlg && <span className="text-danger ml-2">{newError==false?"choose right state":newError==true?"good job" :CreateAds.State}</span>} */}
//                         </div>
//                         <div className="col-lg-6 col-sm-12">
//                           <label className="mb-0 mt-2">City</label>

//                           <Select
//                             className="custom-select1"
//                             autoComplete="none"
//                             style={{
//                               width: "100%",
//                               zIndex: "-10px",
//                             }}
//                             value={city}
//                             getPopupContainer={(triggerNode) => triggerNode.parentElement}
//                             allowClear
//                             status={changeCity ? "" : "error"}
//                             onChange={(value) => {
//                               setChangeCity(value);
//                               console.log("1553", value);

//                               // if (Object.keys(stateMain)?.includes(value)) {
//                               //   setNewError(true);
//                               // } else {
//                               //   setNewError(false);

//                               //   console.log("not match");
//                               // }

//                               setCity(value);
//                               setCityError(false);
//                             }}
//                             showSearch
//                             placeholder="Select City Name"
//                             optionFilterProp="children"
//                             filterOption={(input, option) =>
//                               (option?.label ?? "").includes(input)
//                             }
//                             filterSort={(optionA, optionB) =>
//                               (optionA?.label ?? "")
//                                 .toLowerCase()
//                                 .localeCompare((optionB?.label ?? "").toLowerCase())
//                             }
//                             options={
//                               !changeCity &&
//                               updateState &&
//                               cityWithState?.map((result) => {
//                                 return { value: result, label: result };
//                               })
//                             }
//                           />

//                           {!changeCity && (
//                             <small className="text-danger ml-2">*Select right city</small>
//                           )}
//                           {city == false ? (
//                             <small className="text-danger ml-2">*Choose right city</small>
//                           ) : changeCity && !city && afterSubmitFlg ? (
//                             <small className="text-danger ml-2">{CreateAds.City}</small>
//                           ) : null}
//                           {cityError && <p style={{ color: 'red' }}>Select Your City</p>}
//                           {/* {!city && afterSubmitFlg && <span className="text-danger ml-2">{CreateAds.City}</span>} */}
//                         </div>
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Locality</label>
//                         {/* <GoogleAutoComplteforAddProduct /> */}
//                         {/* <input type="text" /> */}
//                         <div className="App">
//                           <GooglePlacesAutocomplete
//                             apiKey="AIzaSyDEnLDetZt4PvT03AWs7ZTyzAdA3K-Sz1g"
//                             selectProps={{
//                               isClearable: true,
//                               value: address,
//                               onChange: (val) => {
//                                 setAddress(val);
//                               },
//                               placeholder: 'Enter City Name',
//                               styles: {
//                                 input: (provided) => ({
//                                   ...provided,
//                                   boxShadow: 0,
//                                   padding: "4px",
//                                   "&:hover": {
//                                     border: "1px solid purple",
//                                   },
//                                 }),
//                                 singleValue: (provided) => ({
//                                   ...provided,
//                                   boxShadow: 0,
//                                   "&:hover": {
//                                     border: "1px solid purple",
//                                   },
//                                 }),
//                               },
//                             }}
//                             autocompletionRequest={{
//                               types: ['(cities)'], // Restrict autocomplete to cities only
//                             }}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-6 col-sm-12">
//                         <label className="mb-0 mt-2">Zip Code</label>
//                         <input
//                           className="myinput"
//                           name="zipcode"
//                           placeholder="enter zipcode here"
//                           value={zipcode}
//                           type="number"
//                           maxLength={6}
//                           onInput={(e) => {
//                             if (e.target.value.length > e.target.maxLength)
//                               e.target.value = e.target.value.slice(
//                                 0,
//                                 e.target.maxLength
//                               );
//                           }}
//                           onKeyDown={(e) =>
//                             ["e", "E", "+", "-", "."].includes(e.key) &&
//                             e.preventDefault()
//                           }
//                           style={{
//                             border: "1px solid #5d5d5d3d",
//                             padding: "7px",
//                             borderRadius: "3px",
//                           }}
//                           onChange={zipcodeHandler}
//                         />
//                         {zipcode.length < 6 && zipcode.length > 0 ? (
//                           <span className="text-danger ml-2">
//                             Please enter valid zipcode
//                           </span>
//                         ) : zipcode.length > 6 ? (
//                           <span className="text-danger ml-2">
//                             Please enter valid zipcode
//                           </span>
//                         ) : !zipcode && afterSubmitFlg ? (
//                           <small className="text-danger ml-2">
//                             {CreateAds.ZipCode}
//                           </small>
//                         ) : null}
//                         {zipcodeError && <p style={{ color: 'red' }}>Enter the Zipcode</p>}
//                       </div>


//                     </div>
//                     <br />
//                     <Button
//                       style={{ marginTop: "50px" }}
//                       type="primary"
//                       className="btn btn-block style1 text-white p-1"
//                       onClick={handlevis3}> submit </Button>
//                     <br />
//                   </>
//                 )}
//               </>
//             )}
//             {!issuehandledError && !brandError && !aboutservicesError && !cityservicedError && !areacoveredError && !cityservicedError && !priceError && !stateError && !cityError && !localityError && !zipcodeError && onevisible4 && (
//               <>
//                 <div className="dflex row pl-3 pr-3">
//                   <div className="col-lg-12 col-sm-12 mb-3">
//                     <small className="text-danger">
//                       *Choose a plan category and type for this post.{" "}
//                     </small>

//                     <span className="px-3">Plan Category</span>

//                     {business === true ? (
//                       <Select
//                         defaultValue="Choose"
//                         getPopupContainer={(triggerNode) =>
//                           triggerNode.parentElement
//                         }
//                         style={{
//                           width: 150,
//                         }}
//                         onChange={(value) => {
//                           // getDataByPlan(value);
//                           setPlan(value);
//                           setAdsCategory("");
//                         }}
//                         options={leftPlan?.map((val) => {
//                           return { value: val, label: val };
//                         })}
//                       />
//                     ) : (
//                       <Select
//                         defaultValue="Choose"
//                         getPopupContainer={(triggerNode) =>
//                           triggerNode.parentElement
//                         }
//                         style={{
//                           width: 150,
//                         }}
//                         onChange={(value) => {
//                           // getDataByPlan(value);
//                           setPlan(value);
//                           setAdsCategory("");
//                         }}
//                         options={data?.leftPlan?.map((val) => {
//                           return { value: val, label: val };
//                         })}
//                       />
//                     )}

//                     <span className="px-3">
//                       Days limit for this post : {dayslimit}
//                     </span>
//                   </div>
//                   <div
//                     className="col-lg-6 col-sm-12 "
//                     style={{ display: "flex", flexDirection: "column" }}
//                   >
//                     <>
//                       <label className="mb-0 mt-2">User Type: &nbsp;</label>
//                       <Select style={{ width: "10rem", marginRight: "-2rem" }}
//                         getPopupContainer={(triggerNode) => triggerNode.parentNode}
//                         className="classclass"
//                         placeholder="User Type"
//                         options={["Institution", "Freelancer"].map((result) => ({
//                           value: result,
//                           label: result,
//                         }))}
//                         onChange={(value) => {
//                           // getDataByPlan(value);
//                           setusertype(value);
//                         }}
//                       />{!usertype && afterSubmitFlg ? (
//                         <small className="text-danger ml-2">
//                           {CreateAds.userty}
//                         </small>
//                       ) : null}
//                     </>
//                   </div>
//                 </div>
//                 <Button
//                   style={{ marginTop: "32px" }}
//                   type="primary"
//                   className="btn btn-block style1 text-white p-1"
//                   loading={isLoading}
//                   disabled={isLoading}
//                   onClick={addNewProduct}
//                 >
//                   POST ADD
//                 </Button>
//               </>
//             )}

//             {planError ? (
//               <>
//                 <p className="text-danger">Please choose a plan !</p>
//               </>
//             ) : null}
//             {true ? (
//               <>

//                 {data.leftPlan && (
//                   <Modal
//                     // title="Basic Modal"
//                     open={isModalOpen}
//                     onOk={() => navigate("/pricing/")}
//                     onCancel={handleCancel}
//                   >
//                     <div className="mt-2 text-center">
//                       <BsExclamationTriangle className="fs-22 text-warning" />{" "}
//                       &nbsp;&nbsp;
//                       <span className="text-danger">
//                         Please choose a plan !
//                       </span>
//                     </div>
//                   </Modal>
//                 )}
//               </>
//             ) : (
//               <Link to="/payment" className="headline">
//                 {" "}
//                 **For Premium ADs
//                 <button
//                   type="button"
//                   class="btn style1 btn-block"
//                   onClick={handlePay}
//                 >
//                   Pay & Add Add
//                 </button>
//               </Link>
//             )}
//             {active === "Payments" && <Payments />}
//             {ErrorExtraField ? (
//               <>
//                 <Modal
//                   zIndex={9999}
//                   style={{ zIndex: "99999" }}
//                   title="Warning"
//                   open={adsLimitCheck}
//                   okType="danger"
//                   onOk={() => setAdsLimitCheck(false)}
//                   onCancel={() => setAdsLimitCheck(false)}
//                 >
//                   <p>Please fill the extra fields </p>
//                 </Modal>
//               </>
//             ) : null}
//             {/* {successvalue ? (
//                   <div className="text-success">Succesfully Stored</div>
//                 ) : null} */}
//             {errorvalue ? <div className="text-danger">Error </div> : null}


//           </div>
//         </div>

//         {loading1 && (
//           <Modal
//             title="under process"
//             open={loading1}
//             onOk={() => {
//               navigate("/dashboard");
//             }}
//           >
//             It is under <span className="text-warning">verification</span>
//             <p className="p-0 m-0"> It will approve within 24 hours.</p>
//           </Modal>
//         )}
//         {openNewModal && (
//           <Modal
//             title="Ads post successfully"
//             open={openNewModal}
//             icon=<CheckCircleFilled />
//             fill="green"
//             onOk={() => {
//               navigate("/dashboard");
//             }}
//             onCancel={() => navigate("/")}
//           >
//             <p className="p-0 m-0">Your ads have been posted successfully.</p>
//             <p className="p-0 m-0">
//               It is under <span className="text-warning">verification</span>
//             </p>
//             <p className="p-0 m-0"> It will approve within 24 hours.</p>
//           </Modal>
//         )}
//       </fieldset>
//     </div>
//   );
// };

// export default Addservices;








import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Checkbox } from "antd";
import Payments from "../Payment/Payments";
import "./AddProducts.css";
import validator from "validator";
import { Image, Container, Row, Col } from "react-bootstrap";
import "../Allads/ImageUpload/ImageUpload.css";
import { FaCut, FaAdn } from "react-icons/fa";
import "./AddProducts.css";
import { DatePicker, Space, Select, Spin, Alert } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import BiCurrentLocation from "react-icons/bi";
import { useGeolocated } from "react-geolocated";
import { useParams } from "react-router-dom";
import { Button, Modal } from "antd";
import {
  Category,
  Electronics,
  Furniture,
  Pets,
  Cars,
  Bikes,
  Services,
  Mobiles,
  localUrl,
  Workinghours,
  WashingMachineBrand,
  subcategoryRealEstateBuy,
  subcategoryRealEstateRent,
  subcategoryType1,
  url,
  Engine,
  Year,
  Jobs,
  Education,
  countryOptions,
  colorOptions,
  fruitOptions,
  careerStream,
  deliveryMode,
  careerDomain,
  deliveryMode1,
  listedBy,
  programType,
  deliveryMode2,
  careerStream2,
  deliveryMode3,
  hobby,
  eligibility,
  classesHeldOn,
  classTimings,
  programsOffered,
  educationMethod,
  minAge,
  maxAge,
  studentTeacherRacFat,
  schoolBoard,
  subjects,
  standard,
  deliveryMode5,
  country,
  servicesOffered,
  educationStream,
  productType1,
  industry,
  keyFeatures,
  YearOfRegistrationCar
} from "../../env";
import { stateMain } from "../../Location";
import { decrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
import { isMobile } from "react-device-detect";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

// import Success from "../../Pages/success/success";
// import ReactDOM from 'react-dom';
// import $ from 'jquery';
// import ImageUpload from "./ImageUpload/ImageUpload";
// import { SignalWifiStatusbarNullSharp } from "@mui/icons-material";
// import Spiner from "../../Spiner";
import MultiImageInput from "react-multiple-image-input";
import { CreateAds } from "../../../error/errorMessage";
import { TbPlayerTrackNext } from "react-icons/tb"
import { UserContext } from "../../../App";
import GoogleAutoComplteforAddProduct from "../../Home/GoogleAutoComplte/GoogleAutoComplteforAddProduct";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../../../store/Track/trackUserSlice";
import { addActivePlan } from "../../../store/activePlanSlice";
import SubcategoryCommonInput from "../../../Shared/SubCategory/subcategoryCommonInput";
import { subCategoryInput } from "../../../Model/SubCategoryCons";
import "antd/dist/antd.css";
import { HolaAutoComplete } from "../components/HolaAutoComplete";
import { LocalSeeRounded } from "@mui/icons-material";
import { getUserPlan } from "../../../store/allPlanDetails";
import axios from "axios";
import { BsExclamationTriangle } from "react-icons/bs";
import { isNull } from "lodash";
import { IoVideocamOutline } from "react-icons/io5";
// import Service1 from "./Service1";

const array = [];
console.log("!!! 747 array,", array);

const mobileStyle = {
  width: "100%",
  // margin: "5px",
  justifyContent: "center",
};
const desktopStyle = {
  width: 300,
  //  margin: "5px",
};
const mobileStyle1 = {
  padding: "1px",
};
const desktopStyle1 = {
  padding: "50px",
  //  margin: "5px",
};
const mobstyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "15px",
  marginTop: "35px",
  gap: "30px",
};
const deskstyle = {
  display: "flex",
  flexDirection: "row",
  gap: "20%",
  marginLeft: "20px",
  marginTop: "35px",
};
const { Option } = Select;
const Addservices = (props) => {

  // geocode
  const UserData = useContext(UserContext);
  const [address, setAddress] = useState();
  const [addressObj, setAddressObj] = useState();
  const [selectedLocation, setSelectedLocation] = useState("");

  const getCityName = (address_components) => {
    let city = "";
    address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
    });
    return city;
  };

  useEffect(() => {
    const detectUserLocation = () => {
      if ("geolocation" in navigator) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            if (permissionStatus.state === "granted") {
              navigator.geolocation.getCurrentPosition(
                async (position) => {
                  const { latitude, longitude } = position.coords;
                  const geocoder = new window.google.maps.Geocoder();
                  const latlng = { lat: latitude, lng: longitude };

                  geocoder.geocode({ location: latlng }, async (results, status) => {
                    if (status === "OK" && results[0]) {
                      setAddress({ label: getCityName(results[0].address_components), value: { place_id: results[0].place_id } });
                    }
                  });
                },
                (error) => {
                  console.error("Error getting user location:", error);
                }
              );
            } else if (permissionStatus.state === "prompt") {
              navigator.geolocation.getCurrentPosition(
                () => {
                  detectUserLocation();
                },
                (error) => {
                  console.error("Error getting user location:", error);
                }
              );
            }
          })
          .catch((error) => {
            console.error("Error checking geolocation permission:", error);
          });
      } else {
        console.error("Geolocation not available in this browser.");
      }
    };

    // Automatically detect user's location when the component mounts
    detectUserLocation();
  }, []);

  useEffect(() => {
    const func = async () => {
      const geocodeObj =
        address &&
        address.value &&
        (await geocodeByPlaceId(address.value.place_id));
      UserData.setsearchBoxLocaity(address?.label);
      const addressObject =
        geocodeObj && getCityName(geocodeObj[0].address_components);

      setAddressObj(addressObject);
    };
    func();
  }, [address]);

  useEffect(() => {
    // Update the selected location whenever the address changes
    setSelectedLocation(address?.label || "");
  }, [address]);
  // geo end
  const disptach = useDispatch();
  useEffect(() => {
    disptach(add({ view: ["contactForm"] }));
  }, []);

  //loading1 button
  //  const [loading1s, setloadings] = useState<boolean[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [visible, setVisible] = useState(false);
  // const [style1, setStyle1] = useState({color:"red!important"});
  // const [style2, setStyle2] = useState("cont");
  // const [buttoncheck,setbuttoncheck]=useState(null)

  // after cgha
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const dataUser = useContext(UserContext);

  const crop = {
    // unit: "%",
    // aspect: 6 / 6,
    width: "auto",
    height: "auto",
  };

  const { categories } = useParams();

  const [rentErr, setRentErr] = useState(false);
  const [typeErr, setTypeErr] = useState(false);
  const [newError, setNewError] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [catErr, setCatErr] = useState(false);
  const [subCat, setSubCat] = useState(false);
  const [afterSubmitFlg, setAfterSubmitFlg] = useState(false);
  const [images, setImages] = useState({});
  const [imageError, setImageError] = useState(false);
  const [id, setId] = useState();
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [jobprice, setJobPrice] = useState(10);
  const [jobtags, setJobTags] = useState("asa");
  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(categories); //13
  // const [subcategory, setSubcategory] = useState("") //13
  const [subcategoryForAll, setSubcategoryForAll] = useState("");
  const [brand, setBrand] = useState("");
  const [brandError, setBrandError] = useState(false);
  const [condition, setCondition] = useState("Good");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(false);
  const [locality, setLocality] = useState("");
  const [localityError, setLocalityError] = useState(false);
  const [cordinates, setcordinates] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);
  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState(false);
  const [active, setActive] = useState("DashAds");
  const [loading1, setloading1] = useState(false);
  //loader
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState(null);
  const [storeadsFlag, setstoreadsFlag] = useState(false);
  const [realEsateSubCategory, setrealEsateSubCategory] = useState(null);

  const [subCategoryValue, setsubCategoryValue] = useState(null); //28
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedFruit, setSelectedFruit] = useState(null);

  const [subCategoryType, setsubCategoryType] = useState(null);
  
  const userDataValue = localStorage.getItem("userdata")
    ? decrypt("userdata")
    : null;

    const nameseradd = localStorage.getItem("name")
  const [successvalue, setSuccessValue] = useState(false);
  const [errorvalue, setErrorValue] = useState(false);
  const [message, setMessage] = useState(null);
  const [MoreImage, setMoreImage] = useState(false);
  const [phoneNumber, setphoneNumber] = useState("");
  const [subCategoryError, setSubCategoryError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  // const [lat,setLat]=useState(false)
  // const [long,setLong]=useState(false)
  const [lat, setLat] = useState(false);
  const [long, setLong] = useState(false);
  ///category real esate

  const [BuildUpArea, setBuildUpArea] = useState(null);
  const [Flor, setFlor] = useState(null);
  const [ApartMentType, setApartMentType] = useState(null);
  const [Availability, setAvailability] = useState(null);
  const [FurnishedType, setFurnishedType] = useState(null);
  const [Property, setProperty] = useState(null);
  const [Parking, setParking] = useState(null);
  const [PowerBackup, setPowerBackup] = useState(null);
  const [Gym, setGym] = useState(null);
  const [Garden, setGarden] = useState(null);
  const [Pool, setPool] = useState(null);
  const [Lift, setLift] = useState(null);

  const [formDisable, setFormDisable] = useState(true);
  // when ads limit over

  const [adsLimitCheck, setAdsLimitCheck] = useState(false);
  const [currentSelectedplan, setcurrentSelectedplan] = useState(null);

  //label change code
  const [labelBrand, setLableBrand] = useState("Brand");
  useEffect(() => {
    setLableBrand(subCategoryValue === "Animal" ? "Breed" : "Brand");
  }, [subCategoryValue]);
  const [colorCheck, setcolor] = useState(null);
  const [sizeCheck, setsize] = useState(null);

  const [oldPetsCheck, setoldPets] = useState(null);

  const cancelHandler = () => {
    setOpenNewModal2((prevState) => !prevState);
  }

  // state error
  //checkbox

  var planCategory =
    dataUser?.activePlan[dataUser?.activePlan?.length - 1]?.fields?.category;

  // all ads data

  const [allAdsCount, setAllAdsCount] = useState([]);

  // userPlan

  const { loading, data } = useSelector((state) => state.planData);

  console.log("~~~databyplan187", data);

  // for change plan
  const [updateAds_left, setUpdateAds_left] = useState(null);
  const [updateCategory, setUpdateCategory] = useState(null);
  const [updateFeatured_ads, setUpdateFeatured_ads] = useState(null);
  const [updateAds_limit, setUpdateAds_limit] = useState(null);
  const [updateTop_listing, setUpdateTop_listing] = useState(null);
  const [updateSupport, setUpdateSupport] = useState(null);
  const [updateAds_timimg, setUpdateAds_timimg] = useState(null);
  let userid = localStorage.getItem("userid");
  // let userid=localStorage.getItem("userid")
  // aminities checkbox value
  const [coordinates, setCoordinates] = useState('');
  const [aminities, setAminities] = useState([]);

  const companyChangeHandler = (e) => {
    setcompanyname(e.target.value)
    setCompanyNameError(false);
  }



  const areaCoveredHandler = (e) => {
    setareacovered(e.target.value);
    setareacoveredError(false);
  }

  const cityservicedHandler = (e) => {
    setcityserviced(e.target.value);
    setcityservicedError(false)
  }

  const emailChangeHandler = (e) => {
    setemail(e.target.value);
    setEmailError(false);
  }

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
    setDescriptionError(false);
  }

  const websiteChangeHandler = (e) => {
    setwebsite(e.target.value);
    setWebsiteError(false);
  }

  const noOfWorkingHoursHandler = (e) => {
    setworkinghours(e.target.value);
    setWorkingHoursError(false);
  }

  const NoOfEmployeesHandler = (e) => {
    setnoofemp(e.target.value);
    setnoofempError(false);
  }

  const issueChangeHandler = (e) => {
    setissuehandled(e.target.value);
    setissuehandledError(false);
  }

  const localityChangeHandler = (e) => {
    setLocality(e.target.value);
    setLocalityError(false);
  }

  const zipcodeHandler = (e) => {
    setZipcode(e.target.value);
    setZipcodeError(false);
  }

  window.onload = () => {
    getLocation();
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  // const MyComponent = () => {
  useEffect(() => {
    getLocation();
  }, []);

  const showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const coordinatesInput = document.getElementById("coordinatesInput");

    // Update the input field with the latitude and longitude
    coordinatesInput.value = `${latitude}, ${longitude}`;
    setCoordinates(`${latitude}, ${longitude}`);
    setLat(`${latitude}`);
    setLong(`${longitude}`);
  };


  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
    }
  };

  console.log("aminites", aminities);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserPlan(userid));
  }, []);
  const getData = async () => {
    let headersList = {
      Accept: "*/*",
    };

    let bodyContent = new FormData();
    bodyContent.append("user", userid);

    let response = await fetch(localUrl + "adsapi/allPlanData", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });
    let data = await response.json();
    setAllAdsCount(data);
  };
  const [updateState, setUpdateState] = useState(null);
  const [cityWithState, setCityWithState] = useState(null);
  useEffect(() => {
    Object.keys(stateMain)?.filter((val) => {
      if (val === updateState) {
        setCityWithState(stateMain[val]);
      }
    });
  }, [updateState]);
  useEffect(() => {
    getData();
  }, []);

  const [dataByPlan, setDataByplan] = useState([]);
  const [plan, setPlan] = useState("");
  const [adsCategory, setAdsCategory] = useState("");
  const [usertype, setusertype] = useState('');
  const [dayslimit, setDaysLimit] = useState(null);
  const [planCategory1, setPlanCategory] = useState(null);
  const [planType, setPlanType] = useState(null);
  const [expireData, setExpireDate] = useState(null);
  // error for without choosing plan
  const [planError, setPlanError] = useState(false);
  //set new loader
  const [newLoader, setNewLoader] = useState(false);

  // set New modal
  const [openNewModal, setOpenNewModal] = useState(false);
  const [openNewModal2, setOpenNewModal2] = useState(false);
  const handleeOk = () => {
    setOpenNewModal2(false);
  };

  const handleeCancel = () => {
    setOpenNewModal2(false);
  };

  useEffect(() => {
    if (plan !== "") {
      setAdsCategory(data?.postAdsForm[plan]?.category[0]);
    }
  }, [plan]);

  useEffect(() => {
    setCity(null);
  }, [state]);

  useEffect(() => {
    setsubCategoryValue(null);
  }, [category]);

  const [business, setBusiness] = useState(false); // Initialize with null
  const [leftPlan, setLeftPlan] = useState([]); // Initialize with null
  // const dataUser = useContext(UserContext);

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("userid", dataUser.userid);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/adsapi/checkBusinessPlan", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBusiness(result.businessPlan);
        setLeftPlan(result.leftPlan);
        console.log(result, "resvaerarasrar");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if (adsCategory !== "" && plan !== "") {
      let days;
      if (plan === "Silver") {
        days = 15;
      } else if (plan === "Gold") {
        days = 30;
      } else if (plan === "Free") {
        days = 7;
      } else if (plan === "Platinum") {
        days = 60;
      } else if (plan === "featured") {
        days = 90;
      } else if (plan === "premium") {
        days = 90;
      }
      setDaysLimit(days);
      setPlanCategory(adsCategory);
      setPlanType(plan);
      setExpireDate(false);
      setPlanError(false);
    }
  }, [adsCategory]);

  useEffect(() => {
    console.log(data.leftPlan, "jj");
    var today = new Date();

    var datevalue =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    console.log(datevalue);

    setDate(datevalue);
  }, []);

  const navigate = useNavigate();

  if (localStorage.getItem("access_token") == null) {
    navigate("/login/");
  }

  const CategoryList = Category;
  const coords = useGeolocated();
  const handlePay = () => {
    let formdata = {};
    formdata["title"] = title;
    formdata["price"] = price;
    formdata["tags"] = tags;
    formdata["description"] = description;
    formdata["category"] = category;
    formdata["subCategoryValue"] = subCategoryValue;
    formdata["brand"] = brand;
    formdata["condition"] = condition;
    formdata["state"] = state;
    formdata["city"] = city;
    formdata["locality"] = dataUser?.locality;
    formdata["zip_code"] = zipcode;
    formdata["user"] = id;

    if (image !== null) {
      formdata["image"] = image;
    }
    console.log("checking value", formdata);
    localStorage.setItem("payAdsData", JSON.stringify(formdata));
  };
  const name = userDataValue?.name;
  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;

    setrealEsateSubCategory(value);
    if (checked) {
      console.log("checkined");
    } else {
      // setfeatureTrue(null)
      console.log("//unchecked");
    }
  };
  const [categorysubCategoryFlag, setcategorysubCategoryFlag] = useState(false);
  // if(category=="RealEstate"){
  //   setcategorysubCategoryFlag(true)
  // }
  // const [lat ,setLat] =useState(null)
  // const [long ,setLong]=useState(null)
  const selector = useSelector((state) => state.activePlan);
  const user1 = useContext(UserContext);
  useEffect(() => {
    console.log("##pricing plan ,selector ", selector, user1.pricing);
    if (parseInt(dataUser?.activePlan) <= 0) {
      setFormDisable(false);
    }
  }, [selector]);

  const selectorExtraSlice = useSelector(
    (state) => state.adsPostingExtraFiledSlice
  );
  useEffect(() => {
    console.log("#### checking add product ", selectorExtraSlice);
  }, [selectorExtraSlice]);
  useEffect(() => {
    if (true) {
      var formdata = new FormData();
      formdata.append("user", localStorage.getItem("userid"));

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(localUrl + "adsapi/getPricingViews", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("## pricing service response result", result);
          disptach(addActivePlan({ plan: result }));
          dataUser.setActivePlan(result);
        })
        .catch((error) => console.log("error", error));
    }
    console.log("###value user active plan", dataUser?.activePlan);
  }, []);
  // useEffect(() => {
  //   getLocationSearchMethod();
  // }, []);
  const getLocationSearchMethod = () => {
    const options = {
      method: "GET",
      url: "https://google-maps-geocoding.p.rapidapi.com/geocode/json",
      params: {
        address: dataUser?.locality,
        language: "en",
      },
      headers: {
        "X-RapidAPI-Key": "7fded38e7emsh3c4fb60f3b8017cp1c084bjsn32180c499f5f",
        "X-RapidAPI-Host": "google-maps-geocoding.p.rapidapi.com",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);

        console.log(
          "lat long ",
          response.data.results[0].geometry.location.lat,
          response.data.results[0].geometry.location.lng
        );

        setLat(response.data.results[0].geometry.location.lat);
        setLong(response.data.results[0].geometry.location.lng);

      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const qrcodeGenrate = (props) => {
    console.log("|datauser vsalue", dataUser?.activePlan);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkxMTQ1MzkwLCJpYXQiOjE2NTk2MDkzOTAsImp0aSI6IjM5MzJjZjJkZTYyNTQwMjRiNTEyZDk4ZTE4ZDM1Mjk3IiwidXNlcl9pZCI6MjJ9.6uTC3ZTlxdqyhBewkVN5O4MduQ6O6YCNB6p9QJhOF3w"
    );

    var formdata = new FormData();
    formdata.append("product", props);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/qrCodeAds", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const imageUpload = (props) => {
    setloading1(true);
    setNewLoader(true);
    qrcodeGenrate(props);
    console.log("WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW", images);
    for (var file in images) {
      console.log(images[file]);

      var formdata = new FormData();
      formdata.append("imageList", images[file]);
      formdata.append("adsId", props);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(localUrl + `adsapi/adsUpload`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setloading1(true);
          setNewLoader(false);
          console.log(result);
          setOpenNewModal(true);
          // navigate(`/ads-listing/${props}/`);
        })
        .catch((error) => {
          setloading1(true);
          setNewLoader(false);
          setError("Somethig went wrong..");
        });
    }
    setloading1(false);
  };

  const handleInputChange = () => {
    setAfterSubmitFlg(false);
  };

  const [ErrorExtraField, setErrorExtraField] = useState(false);
  useEffect(() => {
    if (category === "Jobs") {
      setPrice(jobprice);
      setTags(jobtags);
    } else {
      setPrice("");
      setTags("");
    }
  }, [category]);
  const handlebrandname = (value) => {
    setBrand(value);
    setBrandError(false);
  };

  const dateChangeHandler = (e) => {
    setDate(e.target.value);
    setDateError(false);
  }

  const aboutServicesHandler = (e) => {
    setaboutservices(e.target.value);
    setaboutservicesError(false);
  }

  const [companyname, setcompanyname] = useState('');
  const [companyNameError, setCompanyNameError] = useState(false);
  const [email, setemail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [website, setwebsite] = useState('');
  const [websiteError, setWebsiteError] = useState(false);

  const [workinghours, setworkinghours] = useState('');
  const [workingHoursError, setWorkingHoursError] = useState(false);

  const handleworkinghour = (value) => {
    setworkinghours(value);
    setWorkingHoursError(false);
  };

  const [noofemp, setnoofemp] = useState('');
  const [noofempError, setnoofempError] = useState(false);
  const [bussinesssince, setbussinesssince] = useState('');
  const [businesssinceError, setbusinesssinceError] = useState(false);

  const handlebussinesssince = (value) => {
    setbussinesssince(value);
    setbusinesssinceError(false);
  };

  const titleChangeHandler = (e) => {
    setTitle(e.target.value);
    setTitleError(false);
  }

  const [issuehandled, setissuehandled] = useState('');
  const [issuehandledError, setissuehandledError] = useState(false);
  const [aboutservices, setaboutservices] = useState('');
  const [aboutservicesError, setaboutservicesError] = useState(false);
  const [areacovered, setareacovered] = useState('');
  const [areacoveredError, setareacoveredError] = useState(false);
  const [cityserviced, setcityserviced] = useState('');
  const [cityservicedError, setcityservicedError] = useState(false);


  const addNewProduct = async () => {
    setNewLoader(true);

    setIsLoading(true);
    var aKeys = Object.keys(finalObj).sort();
    var bKeys = Object.keys(obj).sort();

    if (!(JSON.stringify(aKeys) === JSON.stringify(bKeys))) {
      setErrorExtraField(true);
      setAdsLimitCheck(true);
      setIsLoading(false);
    } else {
      setErrorExtraField(false);
      setstoreadsFlag(true);

      if (
        expireData ||
        !("0" in images) ||
        !title ||
        // !tags ||
        !city ||
        !description ||
        !category ||
        !state ||
        !usertype ||
        !price ||
        // !dataUser?.locality ||
        !zipcode ||
        zipcode.length < 6 ||
        !phoneNumber ||
        phoneNumber.length < 10
      ) {
        setAfterSubmitFlg(true);
        setError("* Please fill this field..");
        setNewLoader(false);
        setIsLoading(false);
      } else if (!data.leftPlan.length) {
        console.log("hgh");
        showModal();
        setIsLoading(false);
      } else {
        let formdata = new FormData();
        if (true) {
          setNewLoader(false);
          for (var file in images) {
            formdata.append("image", images[file]);
            console.log("~~~image");
            break;
          }
        }

        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token")
        );

        formdata.append("title", title);
        formdata.append("name", nameseradd);
        formdata.append("price", price);
        // formdata.append("tags", tags);
        formdata.append("description", description);
        formdata.append("category", category);
        formdata.append("brand", brand);
        formdata.append("Engine", Engine);
        formdata.append("Year", Year);
        formdata.append("condition", condition);
        formdata.append("state", state);
        formdata.append("City", city);
        formdata.append("locality", locality);
        // formdata.append("locality", dataUser?.locality);
        // formdata.append("locality", dataUser?.locality);
        // formdata.append("locality","kihash");
        formdata.append("zip_code", zipcode);
        formdata.append("user", userDataValue.id);
        formdata.append("subCategoryType", subCategoryType);
        formdata.append("subCategoryValue", subCategoryValue);
        formdata.append("lati", lat);
        formdata.append("long", long);
        formdata.append("phoneNumber", phoneNumber);
        formdata.append("BuildUpArea", BuildUpArea);
        formdata.append("Flor", Flor);
        formdata.append("ApartMentType", ApartMentType);
        formdata.append("Availability", Availability);
        formdata.append("FurnishedType", FurnishedType);
        formdata.append("Property", Property);
        formdata.append("Parking", Parking);
        formdata.append("PowerBackup", PowerBackup);
        formdata.append("Gym", Gym);
        formdata.append("Garden", Garden);
        formdata.append("Pool", Pool);
        formdata.append("Lift", Lift);
        formdata.append("colorCheck", colorCheck);
        formdata.append("sizeCheck", sizeCheck);
        formdata.append("oldPetsCheck", oldPetsCheck);
        // for chat purpose
        formdata.append("uuid", localStorage.getItem("uuid"));
        formdata.append("date_created", date);
        formdata.append("PlanCategory", updateCategory);
        formdata.append("featured_ads", updateFeatured_ads);
        formdata.append("ads_limit", updateAds_limit);
        formdata.append("ads_timing", updateAds_timimg);
        formdata.append("top_listing", updateTop_listing);
        formdata.append("support", updateSupport);
        formdata.append("user_type", usertype);

        formdata.append("adsType", planCategory1);
        formdata.append("plan", planType);
        formdata.append("DaysLimit", dayslimit);
        formdata.append("expiry", expireData);
        // dayslimit, planCategory1, planType, expireData
        const dataToSend = {
          companyname: companyname,
          email: email,
          address: website,
          working: workinghours,
          employee: noofemp,
          Since: bussinesssince,
          issuehandled: issuehandled,
          aboutservices: aboutservices,
          areacovered: areacovered,
          cityserviced: cityserviced,
          finalObj
        };

        formdata.append("extraField", JSON.stringify(dataToSend));

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        setloading1(true);
        fetch(url + "api/adsapi/", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setNewLoader(false);
            setSuccessValue(true);
            setErrorValue(false);
            if (result?.detail) {
              setErrorValue(true);
              setSuccessValue(false);
              alert("token expire");
              localStorage.removeItem("access_token");
              navigate("/");
              setloading1(false);
            }
            console.log("result", result);
            if (result.status !== "OK" && result?.DaysLimit && result?.expiry) {
              setPlanError(true);
            } else if (!result?.id) {
              alert("Ads not posted");
            } else {
              imageUpload(result.id);
            }
            setIsLoading(false);
            console.log(result);
          })
          .catch((error) => {
            //isloading false
            setIsLoading(false);
            setloading1(false);
            setError("Something went wrong! Please come after Sometimes..");
            setErrorValue(true);
            setSuccessValue(false);
            setNewLoader(false);
            console.log("error", error);
          });
      }
      setstoreadsFlag(false);
      setloading1(false);
      // setIsLoading(false);
    }
  };
  const [files, setFiles] = useState([]);
  ///function for multiple image upload
  const fileSelectedHandler = (e) => {
    setImageError(false);
    console.log("imag efiles that one", e.target.files);
    files.push(e.target.files[0]);
    setFiles(files);
    console.log("!!!!!!!!!!!state", files);
  };

  useEffect(() => {
    console.log("@@@@@  datause.locality", dataUser?.locality);
    if (dataUser?.locality) {
      getLocationSearchMethod();
    }
  }, [dataUser?.locality]);
  useEffect(() => {
    var today = new Date();
    var datevalue =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    console.log(datevalue);
    setDate(datevalue);
  }, []);

  //for adding multiple values their

  const [finalObj, SetFinalObj] = useState({});
  const [obj, setObj] = useState({});
  const [extraFeildToggle, setExtraFeildToggle] = useState(false);
  useEffect(() => {
    if (subCategoryValue && subCategoryInput[category] !== undefined) {
      let subcategoryy = subCategoryInput[category];
      if (subcategoryy[subCategoryValue] !== undefined) {
        setExtraFeildToggle(true);
        let values = subcategoryy[subCategoryValue];
        let obj1 = values;
        Object.keys(obj1).forEach((key) => {
          let varkey = [];
          varkey = obj1[key].map((result) => {
            if (typeof result === "object" && result !== null) {
              return result;
            } else {
              return { value: result };
            }
          });
          obj1[key] = varkey;
        });

        console.log(obj1, subcategoryy, subcategoryy[subCategoryValue]);
        setObj(obj1);
      } else {
        setExtraFeildToggle(false);
      }
    } else {
      setExtraFeildToggle(false);
    }
  }, [subCategoryValue]);

  useEffect(
    (e) => {
      console.log("!!!category changes");
      // setsubCategoryType(e.currentTarget)
    },
    [category]
  );
  const onChangeplan = (e) => {
    setcurrentSelectedplan(e.target.value);
    console.log(`checked = ${e.target.value}`);
  };
  console.log("obj", obj);
  const style = {
    position: "fixed",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
  };
  document.title = "Hola9 - AddProducts";
  const [change, setChange] = useState("1");
  const [changeState, setChangeState] = useState("2");
  const [changeCity, setChangeCity] = useState("1");
  const [changeSubCat, setChangeSubCat] = useState("1");
  const [subCateReal, setSubCateReal] = useState("1");
  const [subCateRealType, setSubCateRealType] = useState("1");
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [showThirdSection, setShowThirdSection] = useState(false);
  const [otpverifychecklocal, setotpverifychecklocal] = useState(null);
  const [PhoneNumbertemp, setPhoneNumbertemp] = useState(true);

  const toggleSecondSection = () => {
    setShowSecondSection((prevState) => !prevState);
  };

  const toggleSecondSection1 = () => {
    setOpenNewModal2((prevState) => !prevState);
  };
  localStorage.setItem("number", phoneNumber);
  console.log(
    "lcaolp phone number",
    localStorage.getItem("number"),
    phoneNumber
  );
  const storePhoneNumer = (phone) => {
    var formdata = new FormData();
    formdata.append("phoneNubmer", phone);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(localUrl + "adsapi/collectVisitPhoneNumber", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const SendOtp = (e) => {
    e.preventDefault();
    var gen = "1231";
    setOpenNewModal2(true);
    if (subCategoryValue == null) {
      setSubCategoryError(true);
    }
    if (Object.entries(images).length === 0) {
      setImageError(true);
    } else {
      setImageError(false);
    }
    // console.log("phonenumber", phoneNumber);
    setotpverifychecklocal(gen);
    axios({
      method: "POST",
      url: url + "api/phone/sendOTP/",
      data: {
        number: phoneNumber,
      },
    })
      .then((res) => {
        if (true) {
          if (res.data.OTPSent === true) {
            setOpenNewModal2(true);
            storePhoneNumer(phoneNumber);
          } else {
            alert("OTP Was Not sent");
          }
        } else {
          alert("Enter Valid 10 Digits Number Only");
        }
      })
      .catch((e) => console.log(e));
  };

  const [otpVeriftemp, setotpVeriftemp] = useState(true);

  const [Otpdetails, setOtpdetails] = useState(null);
  const [buttonshow, setbuttonshow] = useState(false);

  const otpverifysumbit = (e) => {
    // console.log(typeof Otpdetails);
    console.log(Otpdetails);
    e.preventDefault();
    axios({
      method: "PUT",
      url: url + "api/phone/checkOTP/",
      data: {
        number: phoneNumber,
        otp: Otpdetails,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          //calling registe  method
          setShowSecondSection(true);
          setOpenNewModal2(false);
          setotpVeriftemp(false);
          setbuttonshow(true);
        } else {
          alert("Incorrect OTP");
          // setOpenNewModal2(false);
          // setShowSecondSection(true)
        }
      })
      .catch((e) => console.log(e));
  };

  const ContactDetails = (e) => {
    getLocation();
    e.preventDefault();
    if (companyname == '') {
      setCompanyNameError(true);
    }
    if (email == '') {
      setEmailError(true);
    }
    if (website == '') {
      setWebsiteError(true);
    }
    if (description == '') {
      setDescriptionError(true);
    }
    if (workinghours == '') {
      setWorkingHoursError(true)
    }
    if (noofemp == '') {
      setnoofempError(true)
    }
    if (bussinesssince == '') {
      setbusinesssinceError(true);
    }

    if (companyNameError || emailError || websiteError || descriptionError || workingHoursError || noofempError || businesssinceError) {
      setShowThirdSection(false);

    }
    if (!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError) {
      setShowThirdSection(true);
    }
    // if(!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError){
    //   setShowThirdSection(true);
    // }
  };

  const [onevisible, setonevisible] = useState(true);
  const [onevisible1, setonevisible1] = useState(false);
  const [onevisible2, setonevisible2] = useState(false);
  const [onevisible3, setonevisible3] = useState(false);
  const [onevisible4, setonevisible4] = useState(false);

  const handlevis1 = (e) => {
    e.preventDefault();
    if(subCategoryValue == null  || Object.entries(images).length === 0) {
      setImageError(true);
      if (Object.entries(images).length !== 0) {
        setImageError(false);
      }
      setSubCategoryError(true);
      if (subCategoryValue !== null) {
        setSubCategoryError(false);
    }
  }else {
    getLocation();
    setonevisible(false)
    setonevisible1(true)
    setonevisible2(true)
    setonevisible3(false)
    }
  }
  const handlevis2 = () => {
    setonevisible(true)
    setonevisible1(false)
    setonevisible3(true)
    setonevisible2(false)
    setonevisible4(false)

  }
  const handlevis3 = () => {



    if (issuehandled == '') {
      setissuehandledError(true)
    }
    if (brand == '') {
      setBrandError(true)
    }
    if (aboutservices == '') {
      setaboutservicesError(true);
    }
    if (areacovered == '') {
      setareacoveredError(true);
    }
    if (cityserviced == '') {
      setcityservicedError(true);
    }
    if (price == '') {
      setPriceError(true);
    }
    if (date == '') {
      setDateError(true);
    }
    if (state == '') {
      setStateError(true);
    }
    if (city == '') {
      setCityError(true);
    }
    if (locality == '') {
      setLocalityError(true);
    }
    if (zipcode == '') {
      setZipcodeError(true);
    }

    if (issuehandledError || brandError || aboutservicesError || cityservicedError || areacoveredError || cityservicedError || priceError || stateError || cityError || localityError || zipcodeError) {
      setonevisible3(false);
      setonevisible2(false);
      setonevisible4(false)
    }
    if (!issuehandledError && !brandError && !aboutservicesError && !cityservicedError && !areacoveredError && !cityservicedError && !priceError && !stateError && !cityError && !localityError && !zipcodeError) {
      setShowSecondSection(false)
      setShowThirdSection(false)
      setonevisible3(true);
      setonevisible4(true);
    }
  }
  const handlevis4 = () => {
    setonevisible3(false)
    setonevisible2(true)
    setonevisible1(true)
    setonevisible(false)
    setonevisible4(false)
  }

  const handleImageUpload = (newImages) => {
    setImages(newImages);
    setImageError(false);
  };


  const [logoFile, setLogoFile] = useState(null);

  const handleLogoFileChange = (event) => {
    const file = event.target.files[0];
    setLogoFile(file);
  }; 



  const isFormValid = () => {
    return (
    Category &&
    phoneNumber.length === 10 &&
    // disc &&
    title &&
    price &&
    state &&
    city &&
    zipcode.length === 6 
    );
  };

  const [showVideoForm, setShowVideoForm] = useState(false);

  const handleCheckboxChange = (e) => {
    setShowVideoForm(e.target.checked);
  };

  const fileInputRef = useRef(null);
  const [uploadedVideos, setUploadedVideos] = useState([]);

  const handleIconClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleRemoveVideo = (index) => {
    const newUploadedVideos = [...uploadedVideos];
    newUploadedVideos.splice(index, 1);
    setUploadedVideos(newUploadedVideos);
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    let validVideo = null;

    for (let i = 0; i < files.length; i++) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(files[i]);

      // Wait for metadata to be loaded
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      // Check if video duration is less than or equal to 30 seconds
      if (video.duration <= 30) {
        validVideo = files[i];
        break; // Stop checking further files once a valid video is found
      }
    }

    // Update state with the valid uploaded video (clearing previous videos)
    setUploadedVideos(validVideo ? [validVideo] : []);
  };

  return (
    <div className="container shadow bg-white">
      {/* <Spin tip="Loading..." spinning={newLoader} /> */}
      {!formDisable && <h2 className="text-danger">Your plan has expired</h2>}

      <fieldset disabled={!formDisable}>
        <div>
          <div
            className="white mx-auto"
            style={isMobile ? mobileStyle1 : desktopStyle1}
          >
            <h1 style={{ fontFamily: "serif", fontSize: "40px", fontWeight: "bold", color: "#4a4a4a" }}>Post Your <span style={{ color: "red" }}>{categories}</span> Ads Here</h1>
            <br />

            {onevisible1 && (
              <div style={{ width: "100%", backgroundColor: "whitesmoke", display: "flex", justifyContent: "space-between", alignItems: "center", border: "2px dotted black" }}>
                <div style={{ backgroundColor: "navy", color: "white", padding: "10px 15px", borderRadius: "20px", fontWeight: "700", marginLeft: "15px" }}>1</div>
                <h2 style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}>Ads Details</h2>
                <div onClick={handlevis2} style={{ marginRight: "15px", fontSize: "25px", fontWeight: "bolder", color: "blue" }}> <TbPlayerTrackNext /> </div>
              </div>
            )}
            <br></br>
            {onevisible && (
              <>
                <div className="row p-2">
                  <div className="col-lg-12">
                    <label>Upload Templets</label>
                    <MultiImageInput
                      max={10}
                      images={images}
                      setImages={handleImageUpload}
                      cropConfig={{ crop, ruleOfThirds: true }}
                      theme={{
                        background: "#ffffff",
                        outlineColor: "#111111",
                        textColor: "rgba(255,255,255,0.6)",
                        buttonColor: "#ff0e1f",
                        modalColor: "#ffffff",
                      }}
                    />
                  </div>
                  {imageError && <p style={{ color: 'red' }}>* Please Upload the Images</p>}
                  {MoreImage ? (
                    <div className="col-lg-8">
                      <div>
                        <form className="padingmanage">
                          <input type="file" onChange={fileSelectedHandler} />
                        </form>
                        <Container>
                          <Row>
                            {files.map((result) => {
                              return (
                                <Col xs={2} md={3}>
                                  {" "}
                                  <a alt="" href="" className="block-icon">
                                    <Image
                                      src={URL.createObjectURL(result)}
                                      thumbnail
                                    />

                                    <FaCut
                                      className=" icon-tag"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // this.removeItem(result)
                                      }}
                                    />
                                  </a>{" "}
                                </Col>
                              );
                            })}
                          </Row>
                        </Container>
                      </div>
                    </div>
                  ) : null}
                  {!("0" in images) && (
                    <small className="text-danger ml-2">{error}</small>
                  )}

                  {/* <div className="col-lg-6 col-sm-12">
              <label>Video upload</label>
              <div style={{border:'2px solid black',padding:'20px'}}>
               <VideoInput height={200}/>
               </div>
              </div> */}
                </div>

                <div className="row p-2">
                  <div className="col-lg-12">
                    <label>
                      Upload Videos &nbsp;{" "}
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        style={{
                          visibility: "visible",
                          opacity: "1",
                          zIndex: "1",
                          background: "black",
                          WebkitAppearance: "checkbox",
                          cursor: "pointer",
                          position: "relative",
                        }}
                      />{" "}
                    </label>
                    {showVideoForm && (
                      <>
                        <div
                          style={{
                            border: "2px solid black",
                            padding: "20px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {uploadedVideos.map((video, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                marginRight: "10px",
                              }}
                            >
                              <video
                                src={URL.createObjectURL(video)}
                                width="80"
                                height="60"
                                controls
                              />
                              <p
                                onClick={() => handleRemoveVideo(index)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "darkred",
                                  border: "none",
                                  cursor: "pointer",
                                  color: "white",
                                  fontSize: "10px",
                                  borderRadius: "50%",
                                  padding: "0 3px",
                                  marginTop: "-7px",
                                  marginRight: "3px",
                                }}
                              >
                                X
                              </p>
                            </div>
                          ))}

                          <label
                            htmlFor="fileInput"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <IoVideocamOutline
                              style={{ fontSize: "60px" }}
                              onClick={handleIconClick}
                            />
                            <p style={{ fontSize: "75%", marginBottom: "0" }}>
                              ADD VIDEO
                            </p>
                            {/* <i style={{color:'red', fontSize:'60%'}}>video length should be below 30seconds</i> */}
                          </label>

                          <input
                            ref={fileInputRef}
                            type="file"
                            accept="video/*"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>



                <div className="row p-3" style={{ marginBottom: "-20px" }}>
                  <div className="col-lg-6 col-sm-12" style={{ display: "none" }}>
                    <label className="mb-0 mt-2">Category</label>
                    <input
                      className="myinput"
                      type="text"
                      name="category"
                      placeholder="enter category here"
                      value={category}
                      style={{
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                    />
                    {!change && (
                      <small className="text-danger ml-2">
                        *Select right category
                      </small>
                    )}
                    {change == false ? (
                      <span className="text-danger ml-2">
                        select right category
                      </span>
                    ) : change && !category && afterSubmitFlg ? (
                      <small className="text-danger ml-2">
                        {CreateAds.Category}
                      </small>
                    ) : null}
                    {/* {!category && afterSubmitFlg && <span className="text-danger ml-2">{CreateAds.Category}</span>} */}
                  </div>

                  {category != "RealEstate" ? (
                    <div className="col-lg-6 col-sm-12">
                      <label className="mb-0 mt-2">Sub Category</label>

                      <Select
                        className="custom-select1"
                        style={{
                          // padding: "10px",
                          width: "100%",
                          zIndex: "-10px",
                          // borderRadius: "3px",
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        allowClear
                        value={subCategoryValue}
                        status={changeSubCat ? "" : "error"}
                        onChange={(value) => {
                          console.log("1276", value);

                          setsubCategoryValue("");
                          setSubCategoryError(false);
                          setAfterSubmitFlg(false);
                          setTimeout(() => {
                            setsubCategoryValue(value);
                          }, 0);

                          setChangeSubCat(value);

                          SetFinalObj({});
                        }}
                        showSearch
                        placeholder="Select Sub Category"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={
                          category == "Services"
                            ? Services.sort().map((result) => {
                              return { value: result, label: result };
                            })
                            : null
                        }
                      />

                      {subCategoryError == true && <p style={{ color: 'red' }}>* Please Select the SubCategory</p>}
                      {!changeSubCat && (
                        <small className="text-danger ml-2">
                          *Select right sub category
                        </small>
                      )}
                      {subcategoryForAll && subCat == false ? (
                        <small className="text-danger ml-2">
                          *Select right category
                        </small>
                      ) : changeSubCat && !subCategoryValue && afterSubmitFlg ? (
                        <small className="text-danger ml-2">
                          *Please Enter The Sub Category
                        </small>
                      ) : null}
                    </div>
                  ) : null}

                  {/* Education Subcategory Combination of dropdowns and textfields */}

                  <div>
                    <Row className="my-1">
                      {subCategoryValue && obj && extraFeildToggle
                        ? Object?.keys(obj)?.map((key, index) => {
                          return (
                            <Col span={6} style={{ marginTop: "15px" }}>
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                style={isMobile ? mobileStyle : desktopStyle}
                                notFoundContent="Please select right field"
                                options={obj[key]}
                                placeholder={key}
                                autoFocus={true}
                                onChange={(e) => {
                                  finalObj[key] = e;
                                  SetFinalObj(finalObj);
                                }}
                                filterOption={(inputValue, option) =>
                                  option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                                }
                              />
                            </Col>
                          );
                        })
                        : null}
                    </Row>
                  </div>
                </div>




                <div className="row p-3 d-flex" style={{ marginTop: "-40px", display: "flex" }}>

                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Phone Number</label>
                    <input
                      // className="inpstyle"
                      className="myinput"
                      name="phoneNumber"
                      placeholder="Enter phone number"
                      maxLength={10}
                      type="number"
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      style={{
                        width: "100%",
                        zIndex: "-50px",
                        border: "1px solid #5d5d5d3d",
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                      onChange={(e) => {
                        setphoneNumber(e.target.value);
                      }}
                    />
                    {phoneNumber.length < 10 && phoneNumber.length > 0 ? (
                      <small className="text-danger ml-2">Enter valid Number</small>
                    ) : phoneNumber.length > 10 ? (
                      <small className="text-danger ml-2">
                        Enter only 10 digit Number
                      </small>
                    ) : !phoneNumber && afterSubmitFlg ? (
                      <small className="text-danger ml-2">
                        {CreateAds.PhoneNumber}
                      </small>
                    ) : null}
                  </div>
                  <div>
                    {/* <Button varient="outlined"  onClick={(e) => {toggleSecondSection1(); sendOtp(e);}} style={{float:"right",color:"green"}}>Send Otp</Button> */}

                    <Button
                      varient="outlined"
                      onClick={SendOtp}
                      style={{ float: "right", color: "green" }}
                    >
                      Send Otp
                    </Button>
                  </div>
                </div>
                {buttonshow && (
                  <Button varient="outlined" onClick={subCategoryValue === "" ? null : handlevis1} style={{ display: "flex", float: "right", color: "red" }}> Next </Button>
                )
                }
              </>

            )}

            <br></br>
            {openNewModal2 && (
              <Modal title="VERIFY OTP" open={openNewModal2} onOK={otpverifysumbit} onCancel={cancelHandler}>
                <form class="form-inline">
                  <div class="col-lg-12 " style={{ marginTop: "-20px" }}>
                    <label htmlFor="input" className="sr-only">
                      Enter Your otp
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text"
                      onChange={(e) => {
                        setOtpdetails(e.target.value);
                      }}
                      placeholder="Enter Your otp"
                    />
                  </div>
                  <button
                    className="custom-btns1 btn-1 mt-3 mx-3 "
                    onClick={otpverifysumbit}
                  >
                    verify number
                  </button>
                </form>
              </Modal>
            )}
            <br /><br />
            {/* <div classname="my-5">
                {category === "Services" ? <Service1 /> : null}
              </div> */}
            {/* <div className="row-hover"> */}
            {onevisible3 && (
              <div style={{ width: "100%", backgroundColor: "whitesmoke", display: "flex", justifyContent: "space-between", alignItems: "center", border: "2px dotted black" }}>
                <div style={{ backgroundColor: "navy", color: "white", padding: "10px 15px", borderRadius: "20px", fontWeight: "700", marginLeft: "15px" }}>2</div>
                <h2 style={{ fontFamily: "sans-serif", fontWeight: "lighter" }}>Personal & Bussines Details</h2>
                <div onClick={handlevis4} style={{ marginRight: "15px", fontSize: "25px", fontWeight: "bolder", color: "blue" }}> <TbPlayerTrackNext /> </div>
              </div>
            )}
            <br></br>
            {onevisible2 && (
              <>
                {showSecondSection && (
                  <>
                    <h5> Contact Details</h5>
                    <div className="d-flex" style={{ marginTop: "-5px" }}>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">User Name</label>
                        <input
                          className="myinput"
                          style={{
                            borderRadius: "3px",
                            padding: "7px",
                          }}
                          type="text"
                          name="id"
                          disable={true}
                          placeholder="USER NAME"
                          value={nameseradd}
                        />
                        {!nameseradd && <p style={{ color: 'red' }}>* Enter the User Name</p>}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Company Name</label>
                        <input
                          autoComplete="none"
                          className="myinput"
                          type="text"
                          name="price"
                          placeholder="Enter Your Name here"
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={companyChangeHandler}
                        />
                        {companyNameError && <p style={{ color: 'red' }}>* Enter the Company Name</p>}
                      </div>

                    </div>
                    <div className="d-flex">
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Email id</label>
                        <input
                          className="myinput"
                          type="email"
                          name="locality"
                          placeholder="Enter Email"
                          onChange={emailChangeHandler}
                        />
                        {emailError && <p style={{ color: 'red' }}>* Enter the Email Address</p>}
                      </div>
                    </div>
                    <br></br>
                    <h5> Business Details</h5>
                    <div className="d-flex" style={{ marginTop: "-5px" }}>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Website</label>
                        <input
                          autoComplete="none"
                          className="myinput"
                          type="text"
                          name="Website"
                          placeholder="Enter Your Website here"
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={websiteChangeHandler}
                        />
                        {websiteError && <p style={{ color: 'red' }}>*  Website URL</p>}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">
                          About Company (if Applicable)
                        </label>
                        <input
                          className="myinput"
                          name="About Company "
                          placeholder="Enter About Company"
                          maxLength={10}
                          type="text"
                          style={{ resize: "none" }}
                          value={description}
                          onChange={descriptionChangeHandler}
                        />
                        {descriptionError && <p style={{ color: 'red' }}>* Enter About Company</p>}
                        {!description && afterSubmitFlg && (
                          <small className="text-danger ml-2">{CreateAds.disc}</small>
                        )}
                      </div>
                    </div>
                    <div className="d-flex" style={{ marginTop: "-5px" }}>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Working Hours</label>
                        <Select
                          className="custom-select1"
                          style={{
                            // padding: "10px",
                            width: "100%",
                            zIndex: "-10px",
                            // borderRadius: "3px",
                          }}
                          placeholder="Working Hours"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          allowClear

                          options={
                            Workinghours.sort().map((result) => {
                              return { value: result, label: result };
                            })

                          }
                          onChange={handleworkinghour}
                        />
                        {workingHoursError && <p style={{ color: 'red' }}>* Enter the Working Hours</p>}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">No of Employees</label>
                        <input
                          className="myinput"
                          name="No of Employees"
                          placeholder="Enter No of Employees"
                          maxLength={10}
                          type="number"
                          style={{
                            width: "100%",
                            zIndex: "-50px",
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={NoOfEmployeesHandler}
                        />
                        {noofempError && <p style={{ color: 'red' }}>* Enter the No Of Employees</p>}
                      </div>
                    </div>
                    <div className="d-flex" style={{ marginTop: "-5px" }}>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Business Since</label>
                        <Select
                          className="custom-select1"
                          style={{
                            // padding: "10px",
                            width: "100%",
                            zIndex: "-10px",
                            // borderRadius: "3px",
                          }}
                          placeholder="Bussines Since"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          allowClear

                          options={
                            YearOfRegistrationCar.sort().map((result) => {
                              return { value: result, label: result };
                            })

                          }
                          onChange={handlebussinesssince}
                        />
                        {businesssinceError && <p style={{ color: 'red' }}>* Enter the Business since</p>}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Upload Logo</label>
                        <input
                          className="myinput"
                          name="Upload Logo"
                          placeholder="Upload Logo"
                          maxLength={10}
                          type="file"
                          style={{
                            width: "100%",
                            zIndex: "-50px",
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={handleLogoFileChange}
                        />
                         {!logoFile && <p style={{ color: 'red' }}>* Enter the Business since</p>}
                      </div>
                    </div>
                    <br></br>
                    <div>
                      <Button
                        varient="outlined"
                        onClick={ContactDetails}
                        style={{ float: "right", color: "green" }}
                      >
                        Submit details
                      </Button>
                    </div>
                  </>
                )}


                {!companyNameError && !emailError && !websiteError && !descriptionError && !workingHoursError && !noofempError && !businesssinceError && showThirdSection && (
                  <>
                    <div>
                      <h5> Service Details</h5>
                      <div style={{ marginTop: "-5px" }}>
                        <div className="col-lg-6 col-sm-12">
                          <label className="mb-0 mt-2">Service Name</label>
                          <input
                            className="myinput"
                            type="text"
                            name="Service Name"
                            placeholder="Enter Your Service Name here"
                            value={subCategoryValue}
                            style={{
                              padding: "7px",
                              borderRadius: "3px",
                            }}
                            onChange={titleChangeHandler}
                          />
                          {!title && afterSubmitFlg && (
                            <small className="text-danger ml-2">{CreateAds.title}</small>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">
                          Types of Service
                        </label>
                        <input
                          className="myinput"
                          type="text"
                          name="Types of Service"
                          placeholder="Enter Your Types of Service here"
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          value={JSON.stringify(finalObj)}
                        />
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Issues Handled</label>
                        <input
                          className="myinput"
                          type="text"
                          name="Issues Handled"
                          placeholder="Enter Your Issues Handled here"
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={issueChangeHandler}
                        />
                        {issuehandledError && <p style={{ color: 'red' }}>* Enter Your Issues Handled here</p>}
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Brand</label>
                        <Select
                          className="custom-select1"
                          placeholder="Brand Name"
                          style={{
                            // padding: "10px",
                            width: "100%",
                            zIndex: "-10px",
                            // borderRadius: "3px",
                          }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          allowClear

                          options={
                            WashingMachineBrand.sort().map((result) => {
                              return { value: result, label: result };
                            })

                          }
                          onChange={handlebrandname}
                        />
                        {brandError && <p style={{ color: 'red' }}>* Select the brand Name</p>}
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">About service</label>
                        <input
                          className="myinput"
                          type="text"
                          name="About service"
                          placeholder="Enter Your About service here"
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={aboutServicesHandler}
                        />
                        {aboutservicesError && <p style={{ color: 'red' }}>Enter Your  Services </p>}
                      </div>

                      {/* <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Upload image</label>
                    <input
                      className="myinput"
                      type="file"
                      name="Area Covered"
                      placeholder="Enter Your Area Covered here"
                      style={{
                        border: "1px solid #5d5d5d3d",
                        padding: "7px",
                        borderRadius: "3px",
                      }}
                    />
                  </div> */}

                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Area Covered</label>
                        <input
                          className="myinput"
                          type="text "
                          name="Area Covered"
                          placeholder="Enter Your Area Covered here"
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={areaCoveredHandler}
                        />
                        {areacoveredError && <p style={{ color: 'red' }}>*  Enter Your Area Covered here</p>}
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Cities Serviced</label>
                        <input
                          className="myinput"
                          type="text"
                          name="Cities Serviced"
                          placeholder="Enter Your Cities Serviced here"
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={cityservicedHandler}
                        />
                        {cityservicedError && <p style={{ color: 'red' }}>*  Enter the Cities Serviced Here</p>}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Price</label>
                        <input
                          autoComplete="none"
                          className="myinput"
                          type="number"
                          name="price"
                          placeholder="Enter price here"
                          value={price}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={(e) => {
                            /[^0-9a-zA-Z]/.test(setPrice(e.target.value))
                              ? /[^0-9a-zA-Z]/.test(setPrice(e.target.value))
                              : setPrice(e.target.value);
                            setPriceError(false);
                          }}
                          pattern="/^[0-9a-zA-Z]+$/"
                        />
                        {!price && afterSubmitFlg && (
                          <small className="text-danger ml-2">{CreateAds.Price}</small>
                        )}
                        {priceError && <p style={{ color: 'red' }}>* Enter the Price</p>}
                      </div>
                      {/* <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Date</label>
                        <input
                          className="myinput p-2"
                          style={{
                            border: "1px solid #8080804d",
                            borderRadius: "3px",
                            padding: "6px",
                          }}
                          type="text"
                          name="date"
                          placeholder="enter date here"
                          value={date}
                          onChange={dateChangeHandler}
                          disabled
                        />
                        {dateError && <p style={{ color: 'red' }}>* Enter Date here</p>}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Coordinates</label>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="myinput"
                            id="coordinatesInput"
                            readOnly value={coordinates}
                          /> */}
                          {/* <FaAdn  /> */}
                        {/* </div>

                      </div> */}
                    </div>
                    <div>
                      <h5>More information</h5>
                      <div className="d-flex">
                        <div className="col-lg-6 col-sm-12">
                          <label className="mb-0 mt-2">State</label>

                          <Select
                            className="custom-select1"
                            style={{
                              // padding: "10px",
                              width: "100%",
                              zIndex: "-10px",
                              // borderRadius: "3px",
                            }}
                            autoComplete="none"
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            allowClear
                            status={changeState ? "" : "error"}
                            onChange={(value) => {
                              setChangeState(value);
                              console.log("1553", value);
                              setUpdateState(value);
                              setState(value);
                              setStateError(false);
                              setChangeCity("");
                            }}
                            showSearch
                            placeholder="Select State"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare((optionB?.label ?? "").toLowerCase())
                            }
                            options={Object.keys(stateMain)?.map((result) => {
                              return { value: result, label: result };
                            })}
                          />

                          {!changeState && (
                            <small className="text-danger ml-2">
                              *Select right state
                            </small>
                          )}
                          {changeState == false ? (
                            <small className="text-danger ml-2">
                              *Choose right state
                            </small>
                          ) : changeState && !state && afterSubmitFlg ? (
                            <small className="text-danger ml-2">{CreateAds.State}</small>
                          ) : null}
                          {stateError && <p style={{ color: 'red' }}>* Select Your State</p>}
                          {/* {!state && afterSubmitFlg && <span className="text-danger ml-2">{newError==false?"choose right state":newError==true?"good job" :CreateAds.State}</span>} */}
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <label className="mb-0 mt-2">City</label>

                          <Select
                            className="custom-select1"
                            autoComplete="none"
                            style={{
                              width: "100%",
                              zIndex: "-10px",
                            }}
                            value={city}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            allowClear
                            status={changeCity ? "" : "error"}
                            onChange={(value) => {
                              setChangeCity(value);
                              console.log("1553", value);

                              // if (Object.keys(stateMain)?.includes(value)) {
                              //   setNewError(true);
                              // } else {
                              //   setNewError(false);

                              //   console.log("not match");
                              // }

                              setCity(value);
                              setCityError(false);
                            }}
                            showSearch
                            placeholder="Select City Name"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare((optionB?.label ?? "").toLowerCase())
                            }
                            options={
                              !changeCity &&
                              updateState &&
                              cityWithState?.map((result) => {
                                return { value: result, label: result };
                              })
                            }
                          />

                          {!changeCity && (
                            <small className="text-danger ml-2">*Select right city</small>
                          )}
                          {city == false ? (
                            <small className="text-danger ml-2">*Choose right city</small>
                          ) : changeCity && !city && afterSubmitFlg ? (
                            <small className="text-danger ml-2">{CreateAds.City}</small>
                          ) : null}
                          {cityError && <p style={{ color: 'red' }}>* Select Your City</p>}
                          {/* {!city && afterSubmitFlg && <span className="text-danger ml-2">{CreateAds.City}</span>} */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Locality</label>
                        {/* <GoogleAutoComplteforAddProduct /> */}
                        {/* <input type="text" /> */}
                        <div className="App">
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyDEnLDetZt4PvT03AWs7ZTyzAdA3K-Sz1g"
                            selectProps={{
                              isClearable: true,
                              value: address,
                              onChange: (val) => {
                                setAddress(val);
                              },
                              placeholder: 'Enter City Name',
                              styles: {
                                input: (provided) => ({
                                  ...provided,
                                  boxShadow: 0,
                                  padding: "4px",
                                  "&:hover": {
                                    border: "1px solid purple",
                                  },
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  boxShadow: 0,
                                  "&:hover": {
                                    border: "1px solid purple",
                                  },
                                }),
                              },
                            }}
                            autocompletionRequest={{
                              types: ['(cities)'], // Restrict autocomplete to cities only
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <label className="mb-0 mt-2">Zip Code</label>
                        <input
                          className="myinput"
                          name="zipcode"
                          placeholder="enter zipcode here"
                          value={zipcode}
                          type="number"
                          maxLength={6}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                          }}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                          style={{
                            border: "1px solid #5d5d5d3d",
                            padding: "7px",
                            borderRadius: "3px",
                          }}
                          onChange={zipcodeHandler}
                        />
                        {zipcode.length < 6 && zipcode.length > 0 ? (
                          <span className="text-danger ml-2">
                            Please enter valid zipcode
                          </span>
                        ) : zipcode.length > 6 ? (
                          <span className="text-danger ml-2">
                            Please enter valid zipcode
                          </span>
                        ) : !zipcode && afterSubmitFlg ? (
                          <small className="text-danger ml-2">
                            {CreateAds.ZipCode}
                          </small>
                        ) : null}
                        {zipcodeError && <p style={{ color: 'red' }}>* Enter the Zipcode</p>}
                      </div>


                    </div>
                    <br />
                    <Button
                      style={{ marginTop: "50px" }}
                      type="primary"
                      className="btn btn-block style1 text-white p-1"
                      onClick={handlevis3} disabled={!isFormValid()}> submit </Button>
                    <br />
                  </>
                )}
              </>
            )}
            {!issuehandledError && !brandError && !aboutservicesError && !cityservicedError && !areacoveredError && !cityservicedError && !priceError && !stateError && !cityError && !localityError && !zipcodeError && onevisible4 && (
              <>
                <div className="dflex row pl-3 pr-3">
                  <div className="col-lg-12 col-sm-12 mb-3">
                    <small className="text-danger">
                      *Choose a plan category and type for this post.{" "}
                    </small>

                    <span className="px-3">Plan Category</span>

                    {business === true ? (
                      <Select
                        defaultValue="Choose"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        style={{
                          width: 150,
                        }}
                        onChange={(value) => {
                          // getDataByPlan(value);
                          setPlan(value);
                          setAdsCategory("");
                        }}
                        options={leftPlan?.map((val) => {
                          return { value: val, label: val };
                        })}
                      />
                    ) : (
                      <Select
                        defaultValue="Choose"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        style={{
                          width: 150,
                        }}
                        onChange={(value) => {
                          // getDataByPlan(value);
                          setPlan(value);
                          setAdsCategory("");
                        }}
                        options={data?.leftPlan?.map((val) => {
                          return { value: val, label: val };
                        })}
                      />
                    )}

                    <span className="px-3">
                      Days limit for this post : {dayslimit}
                    </span>
                  </div>
                  <div
                    className="col-lg-6 col-sm-12 "
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <>
                      <label className="mb-0 mt-2">User Type: &nbsp;</label>
                      <Select style={{ width: "10rem", marginRight: "-2rem" }}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        className="classclass"
                        placeholder="User Type"
                        options={["Institution", "Freelancer"].map((result) => ({
                          value: result,
                          label: result,
                        }))}
                        onChange={(value) => {
                          // getDataByPlan(value);
                          setusertype(value);
                        }}
                      />{!usertype && afterSubmitFlg ? (
                        <small className="text-danger ml-2">
                          {CreateAds.userty}
                        </small>
                      ) : null}
                    </>
                  </div>
                </div>
                <Button
                  style={{ marginTop: "32px" }}
                  type="primary"
                  className="btn btn-block style1 text-white p-1"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={addNewProduct}
                >
                  POST ADD
                </Button>
              </>
            )}

            {planError ? (
              <>
                <p className="text-danger">Please choose a plan !</p>
              </>
            ) : null}
            {true ? (
              <>

                {data.leftPlan && (
                  <Modal
                    // title="Basic Modal"
                    open={isModalOpen}
                    onOk={() => navigate("/pricing/")}
                    onCancel={handleCancel}
                  >
                    <div className="mt-2 text-center">
                      <BsExclamationTriangle className="fs-22 text-warning" />{" "}
                      &nbsp;&nbsp;
                      <span className="text-danger">
                        Please choose a plan !
                      </span>
                    </div>
                  </Modal>
                )}
              </>
            ) : (
              <Link to="/payment" className="headline">
                {" "}
                **For Premium ADs
                <button
                  type="button"
                  class="btn style1 btn-block"
                  onClick={handlePay}
                >
                  Pay & Add Add
                </button>
              </Link>
            )}
            {active === "Payments" && <Payments />}
            {ErrorExtraField ? (
              <>
                <Modal
                  zIndex={9999}
                  style={{ zIndex: "99999" }}
                  title="Warning"
                  open={adsLimitCheck}
                  okType="danger"
                  onOk={() => setAdsLimitCheck(false)}
                  onCancel={() => setAdsLimitCheck(false)}
                >
                  <p>Please fill the extra fields </p>
                </Modal>
              </>
            ) : null}
            {/* {successvalue ? (
                  <div className="text-success">Succesfully Stored</div>
                ) : null} */}
            {errorvalue ? <div className="text-danger">Error </div> : null}


          </div>
        </div>

        {loading1 && (
          <Modal
            title="under process"
            open={loading1}
            onOk={() => {
              navigate("/dashboard");
            }}
          >
            It is under <span className="text-warning">verification</span>
            <p className="p-0 m-0"> It will approve within 24 hours.</p>
          </Modal>
        )}
        {openNewModal && (
          <Modal
            title="Ads post successfully"
            open={openNewModal}
            icon=<CheckCircleFilled />
            fill="green"
            onOk={() => {
              navigate("/dashboard");
            }}
            onCancel={() => navigate("/")}
          >
            <p className="p-0 m-0">Your ads have been posted successfully.</p>
            <p className="p-0 m-0">
              It is under <span className="text-warning">verification</span>
            </p>
            <p className="p-0 m-0"> It will approve within 24 hours.</p>
          </Modal>
        )}
      </fieldset>
    </div>
  );
};

export default Addservices;








