// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyCnq2npFxp3pM_M6qTO8Y4YIexWDKg0uVk",
  authDomain: "hola9-fb2ce.firebaseapp.com",
  projectId: "hola9-fb2ce",
  storageBucket: "hola9-fb2ce.appspot.com",
  messagingSenderId: "1004012550209",
  appId: "1:1004012550209:web:f1758c04051b349e9a2ec0",
  measurementId: "G-D5R0KG66PE",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyC2NEFSAkQYWZvOAiKe_RWP7y3u5Ddcn1A",
//   authDomain: "hola9-e8361.firebaseapp.com",
//   projectId: "hola9-e8361",
//   storageBucket: "hola9-e8361.appspot.com",
//   messagingSenderId: "1033170719336",
//   appId: "1:1033170719336:web:5f977d328f0be2b96921dd",
//   measurementId: "G-JNKNDVQZ64",
// };

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
