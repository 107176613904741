import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic";
import SearchProducts from "./SearchProducts";
import ShowProduct from "./ShowProduct";
const ShowProductsSearch = () => {
  
  const { data, Searchloading, searchError } = useSelector(
    (state) => state.ToggleSearchSlice
  );

  useEffect(() => {
    console.log(data, "toggle seach", data.length > 0);
    console.log('data',data);
  }, [data]);
 
  

  if (Searchloading) {
    return <YoutubeMagic1 />;
  }

  return (
    <>
      {/* {data.length > 0 || data.message ? ( */}
        <SearchProducts data={data} />
      {/* ) : (
        <ShowProduct />
      )} */}
      
    </>
  );
};

export default ShowProductsSearch;