import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import { addSearchData } from "../../../../store/SearchCategory/searchCategorySlice"; // Importing addSearchData function from searchCategorySlice
import {
  All,
  Mobiles,
  subcategoryRealEstateBuy, // Imported from "../../../env"
  subcategoryRealEstateRent, // Imported from "../../../env"
  subcategoryType1, // Imported from "../../../env"
} from "../../../env";
import { stateMain, locationData } from "../../../Location";
import "./SearchBox.css";
import { Link } from "react-router-dom";

import { Checkbox, Row, Col, Select, Input } from "antd";
import { Button } from "@mui/material";

const { Option } = Select;

const SearchBox = (props) => {
  // State variables
  const [subCategoryType, setsubCategoryType] = useState(null); // State for subcategory type
  const [subCategoryValue, setsubCategoryValue] = useState(null);
  const [rangeValues, setRangeValues] = useState({ min: 100, max: 1000000000 }); // State for subcategory value
  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    minPrice: null,
    maxPrice: null,
    searchValue: null,
    category: "Mobiles",
  }); // State for search form data
  const [ExtraFieldCopy, setExtraFieldCopy] = useState({});
  const [ExtraFieldCopy1, setExtraFieldCopy1] = useState({});
  const [ExtraFieldCopy2, setExtraFieldCopy2] = useState({});
  const [ExtraFieldCopy3, setExtraFieldCopy3] = useState({});
  const [ExtraFieldCopy4, setExtraFieldCopy4] = useState({});
  const [ExtraFieldCopy5, setExtraFieldCopy5] = useState({});
  const [ExtraFieldCopy6, setExtraFieldCopy6] = useState({});
  const [selectedage, setselectedage] = useState([]);
  const [buildup, setbuildup] = useState();
  const [rent, setrent] = useState();
  const [deposite, setdeposite] = useState();
  const [carpet, setcarpet] = useState();

  const handleDropdownChange = (value11) => {
    setbuildup({ "Build Up Area": value11 });
  };

  const handleTypeage = (selectedValues) => {
    setselectedage(selectedValues);
  };

  const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setsearchSending({ ...searchSending, [name]: value });
    disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  return (
    <div style={{ backgroundColor: "transparent" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <h6
          name="subCategoryType"
          style={{
            padding: "10px 30px",
            color: "white",

            fontWeight: "bold",
            background: "#2c4354",
          }}
        >
          Search Mobiles, Accessories & Tablets
        </h6>
      </div>
      <br />
      <div className="dropdown bootstrap-select show-tick houzez-field-textual form-control elementor-size-sm status-js mt-2">
    
        {(
          <div>
            <input
              className="inpstyle"
              placeholder="Select Any Category"
              type="text"
              list="subcategoryRealEstateRent"
              style={{
                padding: "10px 20px",
                width: "100%",
                zIndex: "-50px",
          
              }}
              name="subCategoryValue"
              onChange={handleChange}
            />
            <datalist id="subcategoryRealEstateRent">
              {!searchSending.subCategoryType ||
              searchSending.subCategoryType !== "Accessories"
                ? Mobiles.map((result) => {
                    return (
                      <option value={result} key={result}>
                        {result}
                      </option>
                    );
                  })
                : null}
            </datalist>
          </div>
        )}
      </div>

      {searchSending.subCategoryValue === "Accessories" ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div className="mt-2">
              <h6>Accessory Type</h6>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass w-100"
                placeholder="Accessory Type"
                options={[
                  "Anti Radiation  Stickers",
                  "Battery",
                  "Cables",
                  "Car Accessory",
                  "Cases/Cover",
                  "Charger",
                  "Earphones",
                  "Head phones",
                  "Memory Card",
                  "Mobile Body Panels",
                  "Mobile Flashes",
                  "Power Banks",
                  "Screen Guard",
                  "Selfie Sticks",
                  "Sim Adaptor",
                  "Speaker",
                  "Others",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange}
              />
            </div>
          </div>

          <div className="mt-2 mb-2">
            <h6>Brand Name </h6>
            <Checkbox.Group>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Checkbox
                    value="Boat"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Boat" });
                    }}
                  >
                    Boat
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Iphone"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Iphone" });
                    }}
                  >
                    Iphone
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Mivi"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Mivi" });
                    }}
                  >
                    Mivi
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Noise"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Noise" });
                    }}
                  >
                    Noise
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Nokia"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Nokia" });
                    }}
                  >
                    Nokia
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="One Plus"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "One Plus" });
                    }}
                  >
                    One Plus
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Oppo"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Oppo" });
                    }}
                  >
                    Oppo
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Samsung"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Samsung" });
                    }}
                  >
                    Samsung
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Sony"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Sony" });
                    }}
                  >
                    Sony
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Vivo"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Vivo" });
                    }}
                  >
                    Vivo
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="Xiomi"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "Xiomi" });
                    }}
                  >
                    Xiomi
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="pTron"
                    onClick={() => {
                      setExtraFieldCopy4({ "Power Backup": "pTron" });
                    }}
                  >
                    pTron
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </>
      ) : null}

      {searchSending.subCategoryValue === "Mobile Phones" ? (
        <>
          <div className="topcheck mt-2">
            <div>
              <h6>Brand Name</h6>
              <Checkbox.Group>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Apple"
                      onClick={() => {
                        setExtraFieldCopy({ "Property Type": "Apple" });
                      }}
                    >
                      Apple
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Blackberry"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Blackberry",
                        });
                      }}
                    >
                      Blackberry
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Blueberry"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Blueberry",
                        });
                      }}
                    >
                      Blueberry
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Centric"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Centric",
                        });
                      }}
                    >
                      Centric
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Creo"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Creo",
                        });
                      }}
                    >
                      Creo
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="HTC"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "HTC",
                        });
                      }}
                    >
                      HTC
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Lava"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Lava",
                        });
                      }}
                    >
                      Lava
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="MI"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "MI",
                        });
                      }}
                    >
                      MI
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Nokia"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Nokia",
                        });
                      }}
                    >
                      Nokia
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Oneplus"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Oneplus",
                        });
                      }}
                    >
                      Oneplus
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Oppo"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Oppo",
                        });
                      }}
                    >
                      Oppo
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Samsung"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Samsung",
                        });
                      }}
                    >
                      Samsung
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Sony"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Sony",
                        });
                      }}
                    >
                      Sony
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Others"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Others",
                        });
                      }}
                    >
                      Others
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <h6>Physical Condition</h6>
                <Checkbox.Group value={selectedage} onChange={handleTypeage}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Excellent"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Excellent",
                          });
                        }}
                      >
                        Excellent
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Good"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Good",
                          });
                        }}
                      >
                        Good
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Fair"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Fair",
                          });
                        }}
                      >
                        Fair
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>

              <div className="mt-2">
                <h6>Model</h6>
                <Checkbox.Group>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Old"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Furnishing Type": "Old",
                          });
                        }}
                      >
                        Old
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="New"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Furnishing Type": "New",
                          });
                        }}
                      >
                        New
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              zIndex: 10000,
            }}
          >
            <div className="mt-3 mb-3">
              <h6>Year Of Purchase</h6>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass w-100"
                placeholder="Year Of Purchase"
                options={[
                  "2000",
                  "2001",
                  "2002",
                  "2003",
                  "2004",
                  "2005",
                  "2006",
                  "2007",
                  "2008",
                  "2009",
                  "2010",
                  "2011",
                  "2012",
                  "2013",
                  "2014",
                  "2015",
                  "2016",
                  "2017",
                  "2018",
                  "2019",
                  "2020",
                  "2021",
                  "2022",
                  "2023",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange}
              />
            </div>
          </div>
        </>
      ) : null}

      {searchSending.subCategoryValue === "Tablets" ? (
        <>
          <div className="topcheck mt-2 mb-2">
            <div>
              <h6>Brand Name</h6>
              <Checkbox.Group>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Apple"
                      onClick={() => {
                        setExtraFieldCopy({ "Property Type": "Apple" });
                      }}
                    >
                      Apple
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Blackberry"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Blackberry",
                        });
                      }}
                    >
                      Blackberry
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Blueberry"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Blueberry",
                        });
                      }}
                    >
                      Blueberry
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Centric"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Centric",
                        });
                      }}
                    >
                      Centric
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Creo"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Creo",
                        });
                      }}
                    >
                      Creo
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="HTC"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "HTC",
                        });
                      }}
                    >
                      HTC
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Lava"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Lava",
                        });
                      }}
                    >
                      Lava
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="MI"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "MI",
                        });
                      }}
                    >
                      MI
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Nokia"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Nokia",
                        });
                      }}
                    >
                      Nokia
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Oneplus"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Oneplus",
                        });
                      }}
                    >
                      Oneplus
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Oppo"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Oppo",
                        });
                      }}
                    >
                      Oppo
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Samsung"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Samsung",
                        });
                      }}
                    >
                      Samsung
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Sony"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Sony",
                        });
                      }}
                    >
                      Sony
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Others"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Property Type": "Others",
                        });
                      }}
                    >
                      Others
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <h6>Condition</h6>
                <Checkbox.Group value={selectedage} onChange={handleTypeage}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Brand New"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Brand New",
                          });
                        }}
                      >
                        Brand New
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Used"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Used",
                          });
                        }}
                      >
                        Used
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div
        style={{
          display: "flex",
          marginTop: "5px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>
      <br />
      <input
        className="inpstyle"
        placeholder="location"
        type="text"
        list="state"
        style={{
          padding: "12px",
          width: "100%",
          zIndex: "-50px",
        }}
        name="City"
        onChange={handleChange}
      />
      <datalist id="state">
        {/* Iterate over locationData array */}
        {locationData.map((result) => {
          return <option value={result}>{result}</option>;
        })}
      </datalist>

      <br />
      <center>
        <div
          className="col-lg-3 col-sm-12"
          style={{ float: "right", marginLeft: "-20px" }}
        >
          {/* Search button */}
          <Link
            to={`/ads-listing/`}
            onClick={() => {
              console.log(searchSending);
              for (const key in searchSending) {
                if (
                  searchSending[key]?.trim() === "" ||
                  searchSending[key] === null
                ) {
                  delete searchSending[key];
                }
              }
              searchSending["extraField"] = {
                ...ExtraFieldCopy,
                ...ExtraFieldCopy1,
                ...ExtraFieldCopy2,
                ...ExtraFieldCopy3,
                ...ExtraFieldCopy4,
                ...ExtraFieldCopy5,
                ...ExtraFieldCopy6,
                ...buildup,
                ...carpet,
              }; // Object Assign to searchSending object

              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
            }}
          >
            <Button varient="outlined" className="">
              Search
            </Button>
          </Link>
        </div>
      </center>
      <br />
    </div>
  );
};

export default SearchBox;
