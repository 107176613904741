import React, { useEffect, useState } from "react";
import "./ServicesSlider.css";
import { Select } from "antd";
import { Citys, cities, location1 } from "../../component/Location";
import { Services } from "../../component/env";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select3 from 'react-select';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select2, { SelectChangeEvent } from '@mui/material/Select';
import { getExtraField } from "../../store/ProductFilterSlice";
const images = [
  "https://mkengineer.com/wp-content/uploads/2019/11/SERVICES.jpg",
  "https://www.housedecorin.com/wp-content/uploads/2021/07/s2-1.jpg",
  "https://media.istockphoto.com/id/846037302/photo/working-man-plumber-repairs-washing-machine-in-laundry.jpg?s=612x612&w=0&k=20&c=QrUHGOe5NRg3autdNIChUBGnholwYWlR7Wi9GT2vUFU=",
  "https://media.istockphoto.com/id/614436310/photo/technician-checking-dishwasher-with-digital-multimeter.jpg?s=612x612&w=0&k=20&c=l1vH2U-MkvbKV2GNmqSQbFoIEru_wtqTqCynfOYJZKg=",
  "https://media.istockphoto.com/id/928791064/photo/technician-repairing-laptop-computer-closeup.jpg?s=612x612&w=0&k=20&c=QF43BNi5BL9wXRYBbUiRrp-oqnQgM1hsN7XhlHsvTSc=",
  "https://www.teahub.io/photos/full/38-384463_photo-wallpaper-design-modern-interior-home-luxury-home.jpg",
  "https://t4.ftcdn.net/jpg/02/95/37/91/360_F_295379180_nGEQOJRF2JlkhpXRAAlZL2JQ3zLIxdXb.jpg",
  "https://media.istockphoto.com/id/928077000/photo/man-spraying-pesticide-in-kitchen.jpg?s=612x612&w=0&k=20&c=SaoLjCzBfVe5eqjMCVbgnapIkFav0Vs6Fxi-Dif3q3I=",
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ServiceSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedOption,setSelectedOption]=useState(null);
  const [subCategory, setSubCategory] = useState();
  const [types,setTypes]=useState();
  const [extraFieldCopy,setExtraFieldCopy]=useState({});
  const [extraFieldCopy1,setExtraFieldCopy1]=useState();
  const [extraFieldCopy2,setExtraFieldCopy2]=useState();

  const [visible1, setVisible1] = useState(false);
  const handleDropdownVisibleChange = (open) => {
    setVisible1(open);
  };

  let cities=[];

    Citys.map((value)=>{
     return cities.push({label: value, value:value});
    });

    const handleChanges = (selectedOption) => {
      
      setSelectedOption(selectedOption);
      console.log('selectedOption',selectedOption);
    setExtraFieldCopy2(selectedOption.value);
    };
 
  const [visible2, setVisible2] = useState(false);
  const handleDropdownVisibleChange2 = (open) => {
    setVisible2(open);
  };

  const [open2,setOpen2] = useState(false);

  const handleChange2 = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setsearchSending({ ...searchSending, [name]: value });
    disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  const handleClose=()=>{
    setOpen2(false);
  }

  const renderModalContent=()=>{
    if(subCategory == 'Appliance Repair'){
    return (
    <>
    <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
    
  >
   <MenuItem value="Air Conditioner" onClick={(e)=>{setTypes("Air Conditioner")
  setExtraFieldCopy1({"Types":"Air Conditioner"})}}>Air Conditioner</MenuItem>
    <MenuItem value="Washing Machine"
    onClick={(e)=>{setTypes("Washing Machine")
      setExtraFieldCopy1({"Types":"Washing Machine"})}}>Washing Machine</MenuItem>
    <MenuItem value="Refrigerator" onClick={(e)=>{setTypes("Refrigerator")
  setExtraFieldCopy1({"Types": "Refrigerator"})}}>Refrigerator</MenuItem>
    <MenuItem value="Geyser Repair" onClick={(e)=>{setTypes("Geyser Repair")
  setExtraFieldCopy1({"Types": "Geyser Repair"})}}>Geyser Repair</MenuItem> 
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

   
    </>)
    }
  else if(subCategory == 'Computer Repair'){
    return(
      <>
      <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select available amenities</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={amenities}
    label="Select Types"
   
  >
   <MenuItem value="1" onClick={(e)=>{setAmenities(1)
  setExtraFieldCopy1({"Types":1})}}>1</MenuItem>
    <MenuItem value="2"  onClick={(e)=>{setAmenities(2)
  setExtraFieldCopy1({"Types":2})}}>2</MenuItem>
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

   
      </>
    )
  }else if(subCategory == 'Home Cleaning'){
    return(
      <>
       <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
  >
   <MenuItem value="Full Home Deep Cleaning" onClick={(e)=>{setTypes("Full Home Deep Cleaning")
  setExtraFieldCopy1({"Types": "Full Home Deep Cleaning"})}} >Full Home Deep Cleaning</MenuItem>
    <MenuItem value="Basic Home Cleaning" onClick={(e)=>{setTypes("Basic Home Cleaning")
  setExtraFieldCopy1({"Types" : "Basic Home Cleaning"})}}>Basic Home Cleaning</MenuItem>
    <MenuItem value="Sofa-set Cleaning" onClick={(e)=>{setTypes("Sofa-set Cleaning")
  setExtraFieldCopy1({"Types": "Sofa-set Cleaning"})}}>Sofa-set Cleaning</MenuItem>
    <MenuItem value="Kitchen Cleaning" onClick={(e)=>{setTypes("Kitchen Cleaning")
  setExtraFieldCopy1({"Types": "Kitchen Cleaning"})}}>Kitchen Cleaning</MenuItem> 
    <MenuItem value="Water-tank Cleaning" onClick={(e)=>{setTypes("Water-tank Cleaning")
  setExtraFieldCopy1({"Types": "Water-tank Cleaning"})}}>Water-tank Cleaning</MenuItem>
    <MenuItem value="Sanitation services" onClick={(e)=>{setTypes("Sanitation services")
  setExtraFieldCopy1({"Types": "Sanitation services"})}}>Sanitation services</MenuItem> 
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

   
      </>
    )
  }else if(subCategory == 'Home Help'){
    return(
      <>
       <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
  >
   <MenuItem value="Cook" onClick={(e)=>{setTypes("Cook")
  setExtraFieldCopy1({"Types" : "Cook"})}}>Cook</MenuItem>
    <MenuItem value="Baby Sitter" onClick={(e)=>{setTypes("Baby Sitter")
  setExtraFieldCopy1({"Types": "Baby Sitter"})}}>Baby Sitter</MenuItem>
    <MenuItem value="Driver" onClick={(e)=>{setTypes("Driver")
  setExtraFieldCopy1({"Types": "Driver"})}}>Driver</MenuItem>
    <MenuItem value="Home nurse" onClick={(e)=>{setTypes("Home nurse")
  setExtraFieldCopy1({"Types": "Home nurse"})}}>Home nurse</MenuItem> 
    <MenuItem value="Maid" onClick={(e)=>{setTypes("Maid")
  setExtraFieldCopy1({"Types": "Maid"})}}>Maid</MenuItem>
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

   
      </>
    )
  }else if(subCategory == 'Home Repair'){
    return(
      <>
       <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
 
  >
   <MenuItem value="Carpenter" onClick={(e)=>{setTypes("Carpenter")
  setExtraFieldCopy1({"Types": "Carpenter"})}}>Carpenter</MenuItem>
    <MenuItem value="Electrician" onClick={(e)=>{setTypes("Electrician")
  setExtraFieldCopy1({"Types": "Electrician"})}}>Electrician</MenuItem>
    <MenuItem value="Plumber" onClick={(e)=>{setTypes("Plumber")
  setExtraFieldCopy1({"Types": "Plumber"})}}>Plumber</MenuItem>
    <MenuItem value="Water Proofing" onClick={(e)=>{setTypes("Water Proofing")
  setExtraFieldCopy1({"Types": "Water Proofing"})}}>Water Proofing</MenuItem> 
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

   
      </>
    )
  }
  else if(subCategory == 'Interior Designing'){
    return(
      <>
       <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
   
  >
   <MenuItem value="Modular Kitchen" onClick={(e)=>{setTypes("Modular Kitchen")
  setExtraFieldCopy1({"Types": "Modular Kitchen"})}}>Modular Kitchen</MenuItem>
    <MenuItem value="Interior Design" onClick={(e)=>{setTypes("Interior Design")
  setExtraFieldCopy1({"Types":"Interior Design"})}}>Interior Design</MenuItem>
    <MenuItem value="Painting" onClick={(e)=>{setTypes("Painitng")
  setExtraFieldCopy1({"Types":"Painting"})}}>Painting</MenuItem>
    <MenuItem value="Furniture" onClick={(e)=>{setTypes("Furniture")
  setExtraFieldCopy1({"Types": "Furniture"})}}>Furniture</MenuItem> 
    <MenuItem value="Metal Fabrication" onClick={(e)=>{setTypes("Metal Fabrication")
  setExtraFieldCopy1({"Types": "Metal Fabrication"})}}>Metal Fabrication</MenuItem>
    <MenuItem value="Home Automation" onClick={(e)=>{setTypes("Home Automation")
  setExtraFieldCopy1({"Types":"Home Automation"})}}>Home Automation</MenuItem> 
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

      </>
    )
  }
  else if(subCategory == 'Invertor & Batteries'){
    return(
      <>
       <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
   
  >
   <MenuItem value="Invertor Installation" onClick={(e)=>{setTypes("Invertor Installation")
  setExtraFieldCopy1({"Types":"Invertor Installation"})}}>Invertor Installation</MenuItem>
    <MenuItem value="Invertor Repair & Maintainence" onClick={(e)=>{setTypes("Invertor Repair & Maintainence")
  setExtraFieldCopy1({"Types":"Invertor Repair & Maintainence"})}}>Invertor Repair & Maintainence</MenuItem>
    <MenuItem value="Battery Repair" onClick={(e)=>{setTypes("Battery Repair")
  setExtraFieldCopy1({"Types":"Battery Repair"})}}>Battery Repair</MenuItem>
    <MenuItem value="Service" onClick={(e)=>{setTypes("Service")
  setExtraFieldCopy1({"Types":"Service"})}}>Service</MenuItem> 
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

      </>
    )
  }
  else if(subCategory == 'Kitchen Appliance Repair'){
    return(<>
    <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
    onChange={(e)=>{
      setTypes(e.target.value);
      setExtraFieldCopy({ "Types": e.target.value})}}
  >
   <MenuItem value="MicroWave-Oven Repair" onClick={(e)=>{setTypes("MicroWave-Oven Repair")
  setExtraFieldCopy1({"Types":"MicroWave-Oven Repair"})}}>MicroWave-Oven Repair</MenuItem>
    <MenuItem value="RO-Water Purifier" onClick={(e)=>{setTypes("RO-Water Purifier")
  setExtraFieldCopy1({"Types":"RO-Water Purifier"})}}>RO-Water Purifier</MenuItem>
    <MenuItem value="Electric Chimney"onClick={(e)=>{setTypes("Electric Chimney")
  setExtraFieldCopy1({"Types":"Electric Chimney"})}}>Electric Chimney</MenuItem>
    <MenuItem value="HOB-Stove" onClick={(e)=>{setTypes("HOB-Stove")
  setExtraFieldCopy1({"Types":"HOB-Stove"})}}>HOB-Stove</MenuItem> 
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

    </>)
  }else if(subCategory == 'Mobile & Tablet Repair'){
    return(
      <>
      <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
    
  >
   <MenuItem value="Mobile Repair" onClick={(e)=>{setTypes("Mobile Repair")
  setExtraFieldCopy1({"Types":"Mobile Repair"})}} >Mobile Repair</MenuItem>
    <MenuItem value="Tablet Repair" onClick={(e)=>{setTypes("Tablet Repair")
  setExtraFieldCopy1({"Types":"Tablet Repair"})}}>Tablet Repair</MenuItem>
    <MenuItem value="iphone-ipad Repair" onClick={(e)=>{setTypes("iphone-ipad Repair")
  setExtraFieldCopy1({"Types":"iphone-ipad Repair"})}}>iphone-ipad Repair</MenuItem>
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

      </>
    )
  }else if(subCategory == 'Packers & Movers'){
    return(
      <>
       <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Types</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={types}
    label="Select Types"
    onChange={(e)=>{
      setTypes(e.target.value);
      setExtraFieldCopy({ "Types": e.target.value})}}
  >
   <MenuItem value="Within City" onClick={(e)=>{setTypes("Within City")
  setExtraFieldCopy1({"Types":"Within City"})}}>Within City</MenuItem>
    <MenuItem value="Between Cities" onClick={(e)=>{setTypes("Between Cities")
  setExtraFieldCopy1({"Types":"Between Cities"})}}>Between Cities</MenuItem>
    <MenuItem value="International" onClick={(e)=>{setTypes("International")
  setExtraFieldCopy1({"Types":"International"})}}>International</MenuItem>
  </Select2>
</FormControl>

<InputLabel id="demo-simple-select-label2">City</InputLabel>
<Select3
value={selectedOption}
onChange={handleChanges}
 options={cities}
/>

<div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange2}
        />

        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange2}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>

      </>
    )

  }
}

  const handleModal=()=>{
    setOpen2(true);
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [images.length]);

  const mobilestyle = {
    height: "200px",
    width: "100%",
  };
  const desktopstyle = {
    height: "340px",
    width: "90%",
    marginLeft: "1.5cm",
    marginRight: "2.5cm",
    borderRadius: "20px",
  };
  const mobstyle = {
    display: "flex", flexDirection: "column"
  };
  const deskstyle = {
    display: "flex", flexDirection: "row"
  };

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [amenities,setAmenities]=useState();


  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch();
  const dispatch = useDispatch();
  // Accessing the Redux dispatch function
  const [errorMessage, setErrorMessage] = useState(null);
  const [city, setCity] = useState();
  const [category, setCategory] = useState();
  const [cityError, setCityError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    City: null,
    searchValue: null,
    category: "Services",
    minPrice: null,
    maxPrice: null,
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  return (
    <>
    <Modal
  open={open2}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Services
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
<div>
    <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">SubCategory</InputLabel>
  <Select2
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={subCategory}
    label="Age"
    onChange={(e)=>{setSubCategory(e.target.value);
     setExtraFieldCopy({ "subCategory": e.target.value})}}
  >
    <MenuItem value="Appliance Repair">Appliance Repair</MenuItem>
    <MenuItem value="Computer Repair">Computer Repair</MenuItem>
    <MenuItem value="Home Cleaning">Home Cleaning</MenuItem>
    <MenuItem value="Home Help">Home Help</MenuItem>
    <MenuItem value="Home Repair">Home Repair</MenuItem>
    <MenuItem value="Interior Designing">Interior Designing</MenuItem>
    <MenuItem value="Invertor & Batteries">Invertor & Batteries</MenuItem>
    <MenuItem value="Kitchen Appliance Repair">Kitchen Appliance Repair</MenuItem>
    <MenuItem value="Mobile & Tablet Repair">Mobile & Tablet Repair</MenuItem>
    <MenuItem value="Packers & Movers">Packers & Movers</MenuItem>
  </Select2>
</FormControl>
</div>
<div>
{renderModalContent()}
<Link
                to={`/ads-listing/`}
                onClick={() => {
                  debugger;
                  console.log(searchSending);
                  for (const key in searchSending) {
                    if (
                      searchSending[key]?.trim() === "" ||
                      searchSending[key] === null
                    ) {
                      delete searchSending[key];
                    }
                  }

             
                   searchSending["subCategoryValue"] = subCategory;
      
                   searchSending["extraField"] = {...extraFieldCopy1};
                   searchSending["City"] = extraFieldCopy2 // Object Assign to searchSending object
                 
                  dispatch(getExtraField(searchSending)); // Di/spatching getExtraField action
                }}
              >
                <button
                  className="btn houzez-search-button elementor-button elementor-size-sm"
                  style={{
                    background: "#a71616bf",
                    color: "white",
                  }}
                  id="serbtn"
                >
                  Search
                </button>
              </Link>
</div>
    </Typography>
  </Box>
</Modal>
      <div
        className="servicesslide mt-4 mb-3 servicesslide-row"
        // style={isMobile ? mobstyle : deskstyle}
        style={{display:"flex", flexWrap:'wrap'}}
        
      >
        <div className="carousel-inner1 carousel-inner1-div">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item${
                index === activeIndex ? " active" : ""
              }`}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                // style={isMobile ? mobilestyle : desktopstyle}
                style={{width:'100%', height:'100%'}}
              />
            </div>
          ))}
        </div>
        <div className="search-form services-search-form">
        <h1
          style={{
            fontSize: "20px",
            fontFamily: "times",
            borderBottom: "1px double white",
            borderTop: "1px double white",
            fontWeight: "bold",
            color: "brown",
          }}
        >
          SEARCH FOR SERVICES
        </h1>
  
        <div className="form-group mt-3">
          <h6
            style={{
              fontFamily: "verdana",
              color: "gray",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            Select City :
          </h6>
          <Select
            open={visible1}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            style={{
              width: "100%",
              padding: "5px 0px",
            }}
            placeholder="Select City"
            value={city || null}
            onChange={(value) => setCity(value)}
            options={Citys.map((value) => ({
              value: value,
              label: value,
            }))}
            showSearch={true}
            filterOption={(inputValue, option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
          {cityError && <p className="error-message">{cityError}</p>}
        </div>
        <div className="form-group">
          <h6
            style={{
              fontFamily: "verdana",
              color: "gray",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            Select Category :
          </h6>
          <Select
            open={visible2}
            onDropdownVisibleChange={handleDropdownVisibleChange2}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            style={{ width: "100%" }}
            placeholder="select category"
            value={category || null}
            onChange={(value) => setCategory(value)}
            options={Services.map((value) => ({
              value: value,
              label: value,
            }))}
          />
          {categoryError && <p className="error-message">{categoryError}</p>}
        </div>
        <Link
          to={`/ads-listing/`}
          onClick={(e) => {
            // Check if both "City" and "Category" have been selected
            if (!city || !category) {
              e.preventDefault(); // Prevent the default navigation behavior
              console.log("Please select both City and Category.");
              setErrorMessage("Please select both City and Category."); // Set an error message
            } else {
              setErrorMessage(null); // Clear any previous error message
  
              for (const key in searchSending) {
                if (
                  searchSending[key]?.trim() === "" ||
                  searchSending[key] === null
                ) {
                  delete searchSending[key];
                }
              }
              searchSending["extraField"] = { ...extraFieldCopy };
              searchSending["subCategoryValue"] = category;
              searchSending["City"] = city;
  
              disptach(getExtraField(searchSending)); // Dispatching getExtraField action
            }
          }}
        >
          <button className="buttonno">Search</button>
          
        </Link>
        <button className="buttonno2" onClick={handleModal}>Filter</button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      
      </div>
    </>
  );
};

export default ServiceSlider;
