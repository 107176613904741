import React from 'react'
import BusinessProfileDashboard from './BusinessProfileDashboard'
import LeftBussinessDashboard from './LeftBussinessDashboard'
import { useParams } from 'react-router-dom'

function BussinessDashboard() {
  const {qnameb}=useParams();
  return (
    <div>
      <BusinessProfileDashboard />
      <LeftBussinessDashboard  qnameb={qnameb}/>
    </div>
  )
}

export default BussinessDashboard
