import React, { useState } from "react";
import Hola9logo from "../../images/Hola9-logo.jpg";
import CurrentLocation from "./CurrentLocation.js";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { decrypt } from "../encryptDecrypt/encryptDecrypt";
import { isMobile } from "react-device-detect";
import "./mobileheader.css"
import { MdOutlineNotificationsActive } from "react-icons/md";
import {BiHomeAlt,BiLogOutCircle} from "react-icons/bi"
import {FcAbout,FcAddImage} from "react-icons/fc"
import {GrAdd} from "react-icons/gr"
import {GiReceiveMoney} from "react-icons/gi"
import {MdSpaceDashboard,MdVideogameAsset,MdConnectWithoutContact,MdShoppingCart} from "react-icons/md"
import Autolocation from "./Autolocation";



const mob = {
  marginTop:"300px",
  bottom: "-70px",
  left:"15%",
  display: "flex",
  justifyContent: "center",
  // alignItems: "center"
};
const desk ={
 marginTop:"-300px"
}

const Mobileheader = () => {
  const wishlistData = useSelector((state) => state.wishlistSlice);
  const userData = useSelector((state) => state.userIdSlice);
  const [decryptedData, setdecryptedData] = useState(null);


  const [menuActive, setMenuActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const [isPopupVisible, setPopupVisible] = useState(false);

  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };
  return (
    <>
      <nav className="navbar sticky-top bg-white" style={{ zIndex: "5px" }}>
        <div className="col-sm-12 col-xs-12  d-lg-none hidden-md visible-xs-block visible-sm-block d-flex m-0">
        
          <Link title="Logo" to="/" className="site__brand__logo ">
            <img
              src={Hola9logo}
              style={{ width: "90px", height: "40px", marginLeft: "15px",marginRight:"115px" }}
              alt="Hola9"
            />
          </Link>
        <div style={{display: "flex",flexDirection: "row"}}>
        <div className="pt-2">
          <Autolocation/>
          </div>
          &nbsp; &nbsp;
          <div className="pt-2">
          <MdOutlineNotificationsActive
            style={{ fontSize: '24px', color: '#012a3b' }}
            onClick={openPopup}
          />
        </div>
  
        {isPopupVisible && (
          <div className="custom-popup">
            <button className="close-buttonno" onClick={closePopup}>
              X
            </button>
            {/* Add your popup content here */}
            <b style={{border: "1px dotted gray", padding: "2px 20px"}}>Your Notifications</b>
            <br></br>
            <h5 style={{color: "green"}}>There is no new notification...!</h5>
            <br></br>
            <h6 style={{color: "red"}}>Upcoming Notifications...!</h6>
            <br></br>
          </div>
        )}
        </div>
          <Sidebar />
        </div>
      </nav>   
      {/* floating nav */}
      {/* <div style={isMobile ? mob : desk} className="visible">
              {localStorage.getItem("access_token") == null ? (
                <center className="floatcontainer">
      <ul className={`menu ${menuActive ? 'active' : ''}`}>
        <div className={`toggle ${menuActive ? 'active' : ''}`} id='btnads' onClick={toggleMenu}>
         <GrAdd/>
        </div>
        <li style={{ '--i': 0 }} className={activeIndex === 0 ? 'active' : ''}>
          <Link to="/" onClick={() => handleItemClick(0)}>
            <BiHomeAlt/>
          </Link>
        </li>
        <li style={{ '--i': 1 }} className={activeIndex === 1 ? 'active' : ''}>
          <Link to="/allCategories/" onClick={() => handleItemClick(1)}>
            <GiReceiveMoney/>
          </Link>
        </li>
        <li style={{ '--i': 2 }} className={activeIndex === 2 ? 'active' : ''}>
          <Link to="/add-product/" onClick={() => handleItemClick(2)}>
          <FcAddImage/>
          </Link>
        </li>
        <li style={{ '--i': 3 }} className={activeIndex === 3 ? 'active' : ''}>
          <Link to="/dashboard/" onClick={() => handleItemClick(3)}>
          <MdSpaceDashboard/>
          </Link>
        </li>
        <li style={{ '--i': 4 }} className={activeIndex === 4 ? 'active' : ''}>
          <Link to="/contact/" onClick={() => handleItemClick(4)}>
          <MdConnectWithoutContact/>
          </Link>
        </li>
        <li style={{ '--i': 5 }} className={activeIndex === 5 ? 'active' : ''}>
          <Link to="/ads-listing/" onClick={() => handleItemClick(5)}>
          <MdVideogameAsset/>
          </Link>
        </li>
        <li style={{ '--i': 6 }} className={activeIndex === 6 ? 'active' : ''}>
          <Link to="/dashboard/wishlist/" onClick={() => handleItemClick(6)}>
          <MdShoppingCart/>
          </Link>
        </li>
        <li style={{ '--i': 7 }} className={activeIndex === 7 ? 'active' : ''}>
          <Link to="/logout/" onClick={() => handleItemClick(7)}>
          <BiLogOutCircle/>
          </Link>
        </li>

        <div className="indicator"></div>
      </ul>
    </center>
              ) : (
                <center className="floatcontainer">
      <ul className={`menu ${menuActive ? 'active' : ''}`}>
        <div className={`toggle ${menuActive ? 'active' : ''}`} id='btnads' onClick={toggleMenu}>
          <GrAdd/>
        </div>
        <li style={{ '--i': 0 }} className={activeIndex === 0 ? 'active' : ''}>
          <Link to="/" onClick={() => handleItemClick(0)}>
            <BiHomeAlt/>
          </Link>
        </li>
        <li style={{ '--i': 1 }} className={activeIndex === 1 ? 'active' : ''}>
          <Link to="/allCategories/" onClick={() => handleItemClick(1)}>
            <GiReceiveMoney/>
          </Link>
        </li>
        <li style={{ '--i': 2 }} className={activeIndex === 2 ? 'active' : ''}>
          <Link to="/add-product/" onClick={() => handleItemClick(2)}>
          <FcAddImage/>
          </Link>
        </li>
        <li style={{ '--i': 3 }} className={activeIndex === 3 ? 'active' : ''}>
          <Link to="/dashboard/" onClick={() => handleItemClick(3)}>
          <MdSpaceDashboard/>
          </Link>
        </li>
        <li style={{ '--i': 4 }} className={activeIndex === 4 ? 'active' : ''}>
          <Link to="/contact/" onClick={() => handleItemClick(4)}>
          <MdConnectWithoutContact/>
          </Link>
        </li>
        <li style={{ '--i': 5 }} className={activeIndex === 5 ? 'active' : ''}>
          <Link to="/ads-listing/" onClick={() => handleItemClick(5)}>
          <MdVideogameAsset/>
          </Link>
        </li>
        <li style={{ '--i': 6 }} className={activeIndex === 6 ? 'active' : ''}>
          <Link to="/dashboard/wishlist/" onClick={() => handleItemClick(6)}>
          <MdShoppingCart/>
          </Link>
        </li>
        <li style={{ '--i': 7 }} className={activeIndex === 7 ? 'active' : ''}>
          <Link to="/logout/" onClick={() => handleItemClick(7)}>
          <BiLogOutCircle/>
          </Link>
        </li>

        <div className="indicator"></div>
      </ul>
    </center>
              )}
      </div> */}
    
    </>
  );
};

export default Mobileheader;
