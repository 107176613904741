import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./Scroll.css";
import { isMobile } from "react-device-detect";
// import mumbaitaj from "../../images/mumbaicity.png";
import mumbaitaj from "../../images/mumbaitaj.jpeg";

import bangalorevidhan from "../../images/bangalorevidhan.jpg";
// import hydrabadminar from "../../images/hydrbadcity.png";
// import chennaistation from "../../images/chennaicity.png";
import hydrabadminar from "../../images/hydrabadminar.jpg";
import chennaistation from "../../images/chennaistation.jpg";
import puneshanivar from "../../images/puneshanivar.jpg";
import ahem from "../../images/ahem.jpg";
// import delhiindia from "../../images/delhicity.png";
import delhiindia from "../../images/delhiindia.jpg";
import jaipur from "../../images/jaipur.jpg";
import indore from "../../images/indore.jpg";
import noida from "../../images/noida.jpg";
import lucknow from "../../images/lucknow.jpg";
import amrit from "../../images/amrit.jpg";
import vado from "../../images/vado.jpg";
import kol from "../../images/kol.jpg";

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 14,
  slidesToScroll: 9,
  initialSlide: 0,
  // autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 7,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 4,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      },
    },
  ],
};
const City = () => {
  const mobileStyle = {
    backgroundSize: "cover",
    width: "40px",
    height: "40px",

  };

  const mobileStyle2001 = {
    zIndex: "2",
    // backdropFilter: "blur(3px)",
    // padding:"15px",
    marginTop: "-5px",
    marginBottom: "-13%",
    height: "140px",
    borderRadius: "5px",

  };
  const desktopStyle = {
    backgroundSize: "cover",
    // borderRadius: "15px",
    width: "50px",
    height: "50px",
  };
  const desktopStyle2001 = {
    zIndex: "2",
    // backdropFilter: "blur(3px)",
    // padding:"15px",
    marginTop: "-5px",
    marginBottom: "-3%",
    height: "140px",
    borderRadius: "5px",
  };
  const mobStyle = {
    fontSize: "10px"
  };
  const deskStyle = {
  };

  return (
    <>
      <div
        className="justify-content-center mainwrapper"
        style={isMobile ? mobileStyle2001 : desktopStyle2001}
      >
        <Slider {...settings}>
          <div>
            <center>
              <Link to={`/ads-listing/location/${"Bengaluru"}/`}>

                <img
                  alt="imag"
                  src={bangalorevidhan}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p
                  className="text-black fs-14 mt-1"
                  style={{ textAlign: "center" }}
                >
                  Bengaluru
                </p>
              </Link>
            </center>
          </div>

          <div><center>
            <Link to={`/ads-listing/location/${"Mumbai"}/`}>
              <img
                alt="imag"
                src={mumbaitaj}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Mumbai
              </p>
            </Link></center>
          </div>

          <div><center>
            <Link to={`/ads-listing/location/${"Hyderabad"}/`}>

              <img
                alt="imag"
                src={hydrabadminar}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Hyderabad
              </p>
            </Link></center>
          </div>

          <div><center>
            <Link to={`/ads-listing/location/${"Chennai"}/`}>
              <img
                alt="imag"
                src={chennaistation}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Chennai
              </p>
            </Link></center>
          </div>
          <div><center>
            <Link to={`/ads-listing/location/${"Pune"}/`}>
              <img
                alt="imag"
                src={puneshanivar}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Pune
              </p>
            </Link></center>
          </div>
          <div>
            <center>
              <Link to={`/ads-listing/location/${"Ahmedabad"}/`}>
                <img
                  alt="imag"
                  src={ahem}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p
                  className="text-black fs-14 mt-1"
                  style={{ textAlign: "center" }}
                >
                  Ahmedabad
                </p>
              </Link></center>
          </div>

          <div>
            <center>
              <Link to={`/ads-listing/location/${"Delhi"}/`}>
                <img
                  alt="imag"
                  src={delhiindia}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p
                  className="text-black fs-14 mt-1"
                  style={{ textAlign: "center" }}
                >
                  Delhi
                </p>
              </Link></center>
          </div>

          <div><center>
            <Link to={`/ads-listing/location/${"Jaipur"}/`}>
              <img
                alt="imag"
                src={jaipur}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Jaipur
              </p>
            </Link></center>
          </div>
          <div><center>
            <Link to={`/ads-listing/location/${"Indore"}/`}>
              <img
                alt="imag"
                src={indore}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Indore
              </p>
            </Link></center>
          </div>
          <div><center>
            <Link to={`/ads-listing/location/${"Noida"}/`}>
              <img
                alt="imag"
                src={noida}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Noida
              </p>
            </Link></center>
          </div>
          <div><center>
            <Link to={`/ads-listing/location/${"Lucknow"}/`}>
              <img
                alt="imag"
                src={lucknow}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Lucknow
              </p>
            </Link></center>
          </div>
          <div><center>
            <Link to={`/ads-listing/location/${"Chandigarh"}/`}>
              <img
                alt="imag"
                src={amrit}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Chandigarh
              </p>
            </Link></center>
          </div>
          <div><center>
            <Link to={`/ads-listing/location/${"Vadodara"}/`}>
              <img
                alt="imag"
                src={vado}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Vadodara
              </p>
            </Link></center>
          </div>
          <div><center>
            <Link to={`/ads-listing/location/${"Kolkata"}/`}>
              <img
                alt="imag"
                src={kol}
                style={isMobile ? mobileStyle : desktopStyle}
                className="circleHover"
              />
              <p
                className="text-black fs-14 mt-1"
                style={{ textAlign: "center" }}
              >
                Kolkata
              </p>
            </Link></center>
          </div>

        </Slider>
      </div>
    </>
  );
};

export default City;
