import React, { useContext, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../../../../firebase";
import { AuthContext } from "../context/AuthContext";
import Register from "../Auth/Register";
import { MdClose, MdSearch } from "react-icons/md";
import { MdOutlineForwardToInbox } from "react-icons/md";

const Navbar = ({ searchForUsers, showSearch, setShowSearch, setLoading }) => {
  const { currentUser } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleInputChange = (value) => {
    setLoading(true);
    clearTimeout(debounceTimeout);
    setDebounceTimeout(null);

    setDebounceTimeout(
      setTimeout(() => {
        searchForUsers(value);
        setLoading(false);
      }, 1000)
    );
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    handleInputChange(newValue);
  };

  const logout = () => {
    // debugger;
    signOut(auth).then(() => {});
  };
  return (
    <div className="navbar">
      <span className="logo"> <MdOutlineForwardToInbox style={{fontSize:"24px"}}/>&nbsp;Inbox</span>
      <div className="search-bar">
        {showSearch ? (
          <MdClose
            onClick={() => {
              setShowSearch(false);
              setSearchTerm("");
            }}
            className="search-icon"
          />
        ) : (
          <MdSearch
            onClick={() => setShowSearch(true)}
            className="search-icon"
          />
        )}
        {showSearch && (
          <div
            className={`search-input-container ${showSearch ? "active" : ""}`}
          >
            <input
              autoFocus
              type="text"
              placeholder="Find a user"
              style={{ width: "120%" , marginTop:"20px" , marginLeft:"70px" }}
              value={searchTerm}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
