import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Searchnew.css";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Citys, location1 } from "./../../Location";
import { Category } from "./../../env";
import { useGeolocated } from "react-geolocated";
import { UserContext } from "../../../App";
import { useDispatch } from "react-redux";
import { add } from "../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../store/ProductFilterSlice";
import { MdWavingHand } from "react-icons/md";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { IoDownloadOutline } from "react-icons/io5";
import { decrypt } from "../encryptDecrypt/encryptDecrypt";
const Searchnew = () => {
  const { Option } = Select;
  const navigate = useNavigate();
  const LocationList = location1;
  const CategoryList = Category;
  const [category, setCategory] = useState(null);
  const [location, setLocation] = useState(null);
  const UserData = useContext(UserContext);
  const dispatch = useDispatch();
  // const [name, setName] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [locationError, setLocationError] = useState("");


  const name = localStorage.getItem("name");

  const handleSearch = () => {
    if (!category) {
      setCategoryError("*Please select a category.");
    } else {
      setCategoryError(""); // Clear the error message
    }

    if (!location) {
      setLocationError("*Please select a location.");
    } else {
      setLocationError(""); // Clear the error message
    }

    if (category && location) {
      // Create an object with location, category, and an empty extra field
      let obj = {
        location: location,
        category: category,
        extraField: {},
      };

      // Dispatch an action with the obj to set extra fields
      dispatch(getExtraField(obj));

      const newPathname =
        category && UserData.searchBoxLocality
          ? `/ads-listing/category/${category}/location/${UserData.searchBoxLocality}`
          : `/ads-listing`;

      // Navigate to the specified pathname
      navigate(newPathname);
    }
  };

  return (
    <>
      {/* DESKTOP RESPONSIVE SECTION */}
      <div
        className="row mb-3 hidden-sm hidden-xs visible-md-block visible-lg-block"
        style={{ marginLeft: "5rem" }}
      >
        <div className="align-items-center">
          <form className="">
            <div className="row d-flex">
              <main className="containerani">
                <h2
                  className="animate-charcter"
                  style={{ fontWeight: "bold", fontSize: "160%", fontFamily: "sans-serif" }}
                >
                  Anything, Anywhere
                </h2>
                <section className="animationnew">
                  <div className="first">
                    <h2 style={{ fontWeight: "bold", fontSize: "160%", fontFamily: "sans-serif" }}>Expand Your Universe</h2>
                  </div>
                  <div className="second">
                    <h2 style={{ fontWeight: "bold", fontSize: "160%", fontFamily: "sans-serif" }}>You Will Get</h2>
                  </div>
                  <div className="third">
                    <h2 style={{ fontWeight: "bold", fontSize: "160%", fontFamily: "sans-serif" }}>
                      Where Imagination Knows No Bounds
                    </h2>
                  </div>
                </section>
              </main>
              <div className="d-flex">
              <div className="col-lg-2 ml-3 p-0" style={{ backgroundColor: "#1d0c54" }}>
                  <Select
                    required
                    className="custom-select2 rounded-lg"
                    style={{
                      width: "110%",
                      zIndex: "2",
                      // borderRadius: "65px",
                      border: "solid black 1.5px",
                    }}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    allowClear
                    onChange={(value) => {
                      setLocation(value); // Update the location state
                      setLocationError(""); // Clear location error when selected
                    }}
                    showSearch
                    placeholder={locationError ? <span style={{ color: 'red' }}> {locationError}</span> : "Select Location"}
                    options={Citys.map((value) => ({
                      value: value,
                      label: value,
                    }))}
                    value={location}
                  />
                  {/* {locationError && (
                    <div className="error-message">{locationError}</div>
                  )} */}
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="col-lg-3 ml-3 p-0" style={{ backgroundColor: "#1d0c54" }}>
                  <Select
                    required
                    className="custom-select2 rounded-lg"
                    style={{
                      width: "20rem",
                      zIndex: "2",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      border: "solid black 1.5px",
                    }}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    allowClear
                    onChange={(value) => {
                      setCategory(value);
                      setCategoryError(""); // Clear category error when selected
                    }}
                    showSearch
                    placeholder={categoryError ? <span style={{ color: 'red' }}> {categoryError}</span> : "Find Mobiles, Cars, Homes and More..."}
                    options={CategoryList.map((value) => {
                      return { value: value, label: value };
                    })}
                    value={category}
                  />
                  {/* {categoryError && (
                    <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>{categoryError}</div>
                  )} */}
                </div>
                <div className="" style={{ marginLeft: "-25px", zIndex: 5 }}>
                  <button type="button" onClick={handleSearch}>
                    <div
                      className="serchb"
                      style={{
                        color: "white",
                        background: "linear-gradient(to right,#0f1c54, #2b224c)",
                        fontSize: "25px",
                        height: "47px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "60px",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <SearchOutlined />
                    </div>
                  </button>
                </div>
                {/* animation name start */}
                <div className="animationnew2001" style={{marginLeft: "200px",marginTop:"-3px" }}>
                  <div className="first" style={{ textAlign: "left" }}>
                    <b style={{ color: "black" }}>Hello</b>
                    &nbsp;
                    <MdWavingHand
                      style={{ color: "yellowgreen", fontSize: "24px" }}
                    />
                    &nbsp;
                    <b
                      style={{
                        color: "#005eff",
                        textTransform: "capitalize",
                        fontSize: "16px",
                      }}
                    >
                      {name}..!
                    </b>
                  </div>
                  <div className="second" style={{marginTop:"20px"}}>
                    <b
                      style={{
                        color:"#016897",
                        textTransform: "capitalize",
                        fontSize: "16px",
                      }}
                    >
                      Download the App{<IoDownloadOutline style={{color:"red",padding:"2px", fontSize:"24px"}}/>}
                    </b>
                  </div> <div className="second" style={{marginTop:"20px"}}>
                    <b
                      style={{
                        color: "#01445f",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        textShadow: "0.5px 0.5px 2px rgba(0, 0, 0, 0.5)"
                      }}
                    >
                      Hola9, "Create genuine value"
                    </b>
                  </div>
                </div>


                {/* animation name start */}
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="downloadbtn" style={{marginTop:"-28px",marginLeft:"60px"}} >
                  Download App

                </div>

          
                <div className="phoneicon" >
                <img src="http://cliparts.co/cliparts/8Tz/rb8/8Tzrb8bac.gif" alt="hhah" style={{height:"75px" ,width:"85px"}} />
                </div>  */}

              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Searchnew;
