// import React from 'react'
// import "./educationSlider.css"
// import EnqueryForm from '../../Shared/Category/EnqueryForm'
// import CommonSectionCategory from '../CommonSectionCategory'
// import { categoryModel } from "../../Model/categoryCons"
// import { Link } from 'react-router-dom'
// import { isMobile } from 'react-device-detect'

// const EducationSlider = (props) => {
//   console.log("@@sliderCategory values", props)
//   const mobstyle={
//     fontFamily: "Times New Roman, Times, serif",
//     color: "#1a4759",
//     fontSize: "30px"
//   }
//   const deskstyle={
//     fontFamily:  "Times New Roman, Times, serif",
//     color: "#1a4759",
//     fontSize: "50px"
//   }
//   return (
//     <div>
//       <div className="site-wrap" id="home-section">
//         <div className='bg-light'>
//         {/* style={{backgroundImage:"url(https://images.unsplash.com/photo-1659559466975-1e37ab8acce4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60)",backgroundSize: "cover" ,height:"50px"}} */}
//           <div className="container bg-light" style={{ height: "100px", marginTop: "-20px" }}>
//             <div className="row align-items-center justify-content-center">
//               <div className="col-lg-10">
//                 <h1 style={isMobile? mobstyle :deskstyle}>
//                 <b> The Hub of Tutorials</b>
//                 </h1>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="site-section" style={{background: "#1a4759"}}>
//           <div className="container" style={{marginTop: "-50px"}}>
//             <div className="row">
//               <div className="col">
//                 <div className="heading mb-4">
//                   <span className="caption">Tutorial Courses</span>
//                   <h2 style={{color: "white"}}>Choose Course</h2>
//                 </div>
//               </div>
//             </div>
//             <div className="row ">
//               <div className="col-lg-2 col-sm-2 col-xs-2 ">
//                 <Link to="/ads-listing/" className="course bg-light">
//                   <span className="wrap-icon brand-adobeillustrator" />
//                   <h3>Illustrator</h3>
//                 </Link>
//               </div>
//               <div className="col-lg-2 col-sm-2 col-xs-2">
//                 <Link to="/ads-listing/" className="course">
//                   <span className="wrap-icon brand-adobephotoshop" />
//                   <h3>Photoshop</h3>
//                 </Link>
//               </div>
//               <div className="col-lg-2 col-sm-2 col-xs-2">
//                 <Link to="/ads-listing/" className="course">
//                   <span className="wrap-icon brand-angular" />
//                   <h3>Angular</h3>
//                 </Link>
//               </div>
//               <div className="col-lg-2 col-sm-2 col-xs-2">
//                 <Link to="/ads-listing/" className="course">
//                   <span className="wrap-icon brand-javascript" />
//                   <h3>JavaScript</h3>
//                 </Link>
//               </div>
//               <div className="col-lg-2 col-sm-2 col-xs-2">
//                 <Link to="/ads-listing/" className="course">
//                   <span className="wrap-icon brand-react" />
//                   <h3>React</h3>
//                 </Link>
//               </div>
//               <div className="col-lg-2 col-sm-2 col-xs-2">
//                 <Link to="/ads-listing/" className="course">
//                   <span className="wrap-icon brand-vue-dot-js" />
//                   <h3>Vue</h3>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
// export default EducationSlider
import React from "react";
import "./educationSlider.css";
import { isMobile } from "react-device-detect";

// Styling object for mobile devices
const mobstyle = {
  fontFamily: "Times New Roman, Times, serif",
  color: "#1a4759",
  fontSize: "30px",
};

// Styling object for desktop devices
const deskstyle = {
  fontFamily: "Times New Roman, Times, serif",
  color: "#01021a",
  fontSize: "50px",
};

// Card Component: Represents a single card in the education slider
const Card = ({ title, description, image, button, link }) => {
  // Function to handle button click and redirect the user to the provided link
  const handleButtonClick = () => {
    window.location.href = link;
  };

  return (
    <>
      <div className="card">
        <div className="card-image">
          {/* Render the image for the card */}
          <img src={image} alt={title} style={{ height: "10rem" }} />
        </div>
        <div className="card-content">
          {/* Render the title of the card */}
          <h3 className="card-title">{title}</h3>
          {/* Render the description of the card */}
          <p className="card-description" style={{ color: "blue" }}>
            {description}
          </p>
          {/* Render the button for the card with an onClick event handler */}
          <button className="card-button" onClick={handleButtonClick}>
            {button}
          </button>
        </div>
      </div>
    </>
  );
};

// EducationSlider Component: Represents the main education slider component
const EducationSlider = () => {
  // Array of card data objects defining the properties for each card
  const cardsData = [
    {
      title: "HTML", // Title of the card
      description: "Learn HTML Through Our Tutorials", // Description of the card
      image:
        "https://arquivo.devmedia.com.br/noticias/devcast/devcast_formularios-em-html-5-conhecendo-e-usando_28579.jpg", // Image URL for the card
      button: "Learn More", // Button text for the card
     link: "/contact", // Link to redirect when the button is clicked
    },
    {
      title: "CSS",
      description: "Learn Cascading Style Sheet Through Our Tutorials",
      image: "https://pixelmechanics.com.sg/wp-content/uploads/2019/04/css.jpg",
      button: "Learn More",
     link: "/contact",
    },
    {
      title: "JAVA SCRIPT",
      description: "Learn Javascript Through Our Tutorials",
      image:
        "https://courses.wscubetech.com/s/store/courses/5f4f8a0c0cf244357e65e13b/cover.jpg?v=1",
      button: "Learn More",
     link: "/contact",
    },
    {
      title: "REACT JS",
      description: "Learn React JS Through Our Tutorials",
      image:
        "https://img2.exportersindia.com/product_images/bc-full/2020/4/7004235/react-js-course-1586418353-5364397.jpg",
      button: "Learn More",
      link: "/contact",
    },
    {
      title: "PYTHON",
      description: "Learn Python Through Our Tutorials",
      image:
        "https://qph.cf2.quoracdn.net/main-qimg-5a9ec3f6c297d5bd717d71c9ee35c83a-lq",
      button: "Learn More",
     link: "/contact",
    },
    {
      title: "D-JANGO",
      description: "Learn d-jango Through Our Tutorials",
      image: "https://img-c.udemycdn.com/course/750x422/4015616_32a9_2.jpg",
      button: "Learn More",
     link: "/contact",
    },
    {
      title: "SQL",
      description: "Learn Sequel Through Our Tutorials",
      image:
        "https://www.simplilearn.com/ice9/app/IntroductiontoSQL_360x194.jpg",
      button: "Learn More",
     link: "/contact",
    },
    {
      title: "REDUX",
      description: "Learn Redux Through Our Tutorials",
      image:
        "https://miro.medium.com/v2/resize:fit:1400/1*7nLBkCmTiyXKFMk4fVCVqw.jpeg",
      button: "Learn More",
     link: "/contact",
    },
  ];

  return (
    <>
      <center>
        <div className="col-lg-10 mt-4 mb-4">
          {/* Render the title for the education slider */}
          <h1 style={isMobile ? mobstyle : deskstyle}>
            <b>Welcome To The Hub of Tutorials</b>{" "}
          </h1>{" "}
        </div>
      </center>
      <div className="card-container" style={{ background: "#01021a" }}>
        {/* Map over the cardsData array and render a Card component for each item */}
        {cardsData.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </>
  );
};

export default EducationSlider;
