// // import React, { useEffect, useState } from "react"
// // import FeaturedAds from '../../../Ads/FeaturedAds/featuredAds';
// import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
// import CategoryUiSection from "../CategoryUiScetion/CategoryUiSection"; // Importing the 'CategoryUiSection' component
// import SearchBox from "../searchBox/SearchBox"; // Importing the 'SearchBox' component
// import RealEstateSection from "../section/realEstateSection"; // Importing the 'RealEstateSection' component
// import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { subCategoryInput } from "../../../../Model/SubCategoryCons";
// import { SearchOutlined } from "@ant-design/icons";
// import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
// import MyGallery from "./MyGallery";
// import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
// import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
// import { Input, Spin } from "antd";
// import { useDispatch } from "react-redux";
// import { add } from "../../../../store/Track/trackUserSlice";
// import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
// import { stateMain, locationData } from "../../../Location";
// import "../searchBox/SearchBox.css";
// import { Link } from "react-router-dom";
// import BikeSlider from "../../../../Shared/CategorySlider/BikeSlider";
// import { Carousel } from "antd";
// import { FitScreen } from "@mui/icons-material";
// import "./allfilter.css";
// import { isMobile } from "react-device-detect";
// import { searchApi } from "../../../../store/ToggleSearchSlice";
// import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
// import Allcatads from "../../../Allcatads";

// const mobstyle = {
//   width: "98%",
//   margin:"auto"
// };
// const deskstyle = {
//   padding: "20px",
//   marginBottom: "-10px",
//   marginRight: "10rem",
//   marginLeft: "10rem",
// };
// const mobi={



// }
// const deski={
//   marginLeft:"7rem",marginRight:"7rem"
// }

// // const mobifilter={



// // }
// // const deskifilter={
// //   marginLeft:"7rem",marginRight:"7rem"
// // }
// const Bikescat = () => {
//   const [selectedFilter, setSelectedFilter] = useState(null);
//   const [SearchValue, setSearchValue] = useState("Bike");
//   const [active,setActive]=useState(null);
//   const [active1,setActive1]=useState(null);
//   const [budget,setBudget]=useState(null);
//   const [bikeType,setBikeType]=useState(null);
//   const [bikeType1,setBikeType1]=useState(null);
//   const [years,setYears]=useState();
//   const [kms,setKms]=useState();
//   // const [selectedFilter1, setSelectedFilter1] = useState(null);

//   const handleFilterButtonClick = (filterType) => {
//     setSelectedFilter(filterType);
//   };

//   const greeting = "Bikes";

//   const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
//   const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
//   const disptach = useDispatch();
//   const dispatch = useDispatch();
//    // Accessing the Redux dispatch function

//   useEffect(() => {
//     disptach(add({ view: ["Bikescat"] })); // Dispatching an action using Redux to add the view to the user's track
//   }, []);

//   const [extraFieldCopy, setExtraFieldCopy] = useState();
//   const [extraFieldCopy1,setExtraFieldCopy1] = useState();
//   const [extraFieldCopy2,setExtraFieldCopy2] = useState();
//   const [extraFieldCopy3,setExtraFieldCopy3] = useState();
//   const [extraFieldCopy4,setExtraFieldCopy4] = useState();

//   let year = [];
//   var date = new Date().getFullYear();
//   for (let i = 1980; i <= date; i++) {
//     year.push(i);
//   }

//   let km=[];
//   var Driven = subCategoryInput.Bikes.Bikes["KM_Driven "];
//   for (let i=0;i<Driven.length;i++){
//     km.push(Driven[i])
//   }

//   const [searchSending, setsearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: "Bikes",
//     minPrice: null,
//     maxPrice: null,
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook

//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setExtraFieldCopy({ [name]: value });
//     // setsearchSending({ ...searchSending, [name]: value });
//     // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   useEffect(() => {
//     // Update searchSending state with the selected filter value
//     if (selectedFilter !== null) {
//       setsearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);
//   const sliderStyle = {
//     width: "100%",
//     // marginTop:"2cm",
//     // maxWidth: "100vw",
//   };
//   const [selectedButton, setSelectedButton] = useState();

//   // Step 2: Create CSS classes to control modal positioning
//   const modalContainerStyle = {
//     top: "-50%", // Adjust this value to control the distance from the button

//     // width:"100%"
//   };

//   const modalStyle = {
//     // position: "relative",

//     // display:"flex",
//     // width:"100%",
//     top: "-50%", // Adjust this value to control the distance from the button
//     left: 0,
//     zIndex: 10, // Ensure the modal is above other elements

//     boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
//   };

//   const renderModalContent = () => {
//     if (selectedButton === "button1") {
//       return (
//         <div style={modalStyle} id="barndimgId" className="brandimg">
//           <img
//             src="https://imgd.aeplcdn.com/0X0/bw/makes/honda20200511152343.jpg?q=75"
//             alt=""
//             height={100}
//             width={100}
//             className={`filimg ${active === "Honda" ? 'selected':''}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Honda" });
//               setActive("Honda");
//             }}
//           />
//           <img
//             src="https://imgd.aeplcdn.com/0X0/bw/makes/royal-enfield20200508193112.jpg?q=75"
//             alt=""
//             height={100}
//             width={100}
//             className={`filimg ${active === "Royal Enfield" ? 'selected':''}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Royal Enfield"});
//               setActive("Royal Enfield")
//             }}
//           />
//           <img
//             src="https://imgd.aeplcdn.com/0X0/bw/makes/hero20200508192826.jpg?q=75"
//             alt=""
//             height={100}
//             width={100}
//             className={`filimg ${active === "Hero" ? 'selected':''}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Hero" });
//               setActive("Hero");
//             }}
//           />
//           <img
//             src="https://imgd.aeplcdn.com/0X0/bw/makes/yamaha20200508193220.jpg?q=75"
//             alt=""
//             height={100}
//             width={100}
//             className={`filimg ${active === "Yamaha" ? 'selected':''}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Yamaha" });
//               setActive("Yamaha");
//             }}
//           />
//           <img
//             src="https://imgd.aeplcdn.com/0X0/bw/makes/bajaj20200508192534.jpg?q=75"
//             alt=""
//             height={100}
//             width={100}
//             className={`filimg ${active === "Bajaj" ? 'selected':''}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "Bajaj" });
//               setActive("Bajaj");
//             }}
//           />
//           <img
//             src="https://imgd.aeplcdn.com/0X0/bw/makes/tvs20200508193203.jpg?q=75"
//             alt=""
//             height={100}
//             width={100}
//             className={`filimg ${active === "TVS" ? 'selected':''}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "TVS" });
//               setActive("TVS");
//             }}
//           />
//           <img
//             src="https://imgd.aeplcdn.com/0X0/bw/makes/bmw20200508192553.jpg?q=75"
//             alt=""
//             height={100}
//             width={100}
//             className={`filimg ${active === "BMW" ? 'selected':''}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "BMW" });
//               setActive("BMW");
//             }}

//           />
//           <img
//             src="https://imgd.aeplcdn.com/0X0/bw/makes/ktm20200518163508.jpg?q=75"
//             alt=""
//             height={100}
//             width={100}
//             className={`filimg ${active === "KTM" ? 'selected':''}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Brand Name": "KTM" });
//               setActive("KTM")
//             }}
//           />
//         </div>
//       );
//     } else if (selectedButton === "button2") {
//       return (
//         <div style={modalStyle} className="brandimg">
//           <p
//             className={`pricep ${budget === 20000 ? 'budget':''}`}
//             onClick={() => {

//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "20000"
//               }));
//               setBudget(20000);
//             }}
//           >
//             Under 20000
//           </p>
//           <p
//             className={`pricep ${budget === 50000 ? 'budget':''}`}
//             onClick={() => {

//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "50000",
//               }));
//               setBudget(50000);
//             }}
//           >
//             Under 50000
//           </p>
//           <p
//              className={`pricep ${budget === 70000 ? 'budget':''}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "70000",
//               }));
//               setBudget(70000)
//             }}
//           >
//             Under 70000
//           </p>
//           <p
//             className={`pricep ${budget === 90000 ? 'budget':''}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "90000",
//               }));
//               setBudget(90000);
//             }}
//           >
//             Under 90000
//           </p>
//           <p
//             className={`pricep ${budget === 120000 ? 'budget':''}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "120000",
//               }));
//               setBudget(120000);
//             }}
//           >
//             Under 1.2 Lakh
//           </p>
//           <p
//             className={`pricep ${budget === 150000 ? 'budget':''}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "150000",
//               }));
//                setBudget(150000)
//             }}
//           >
//             Under 1.5 Lakh
//           </p>
//           <p
//             className={`pricep ${budget === 170000 ? 'budget':''}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "0",
//                 maxPrice: "170000",
//               }));
//                setBudget(170000);
//             }}
//           >
//             Under 1.7 Lakh
//           </p>
//           <p
//             className={`pricep ${budget === 200000 ? 'budget':''}`}
//             onClick={() => {
//               setsearchSending((prev) => ({
//                 ...prev,
//                 minPrice: "200000",
//                 maxPrice: "999999999",
//               }));
//               setBudget(200000)
//             }}
//           >
//             Above 2Lakh
//           </p>
//         </div>
//       );
//     } else if (selectedButton === "button3") {
//       return (
//         <div className="biky"
//         style={{ width: '90%', display: 'flex', gap:'5%' }}>
//         <div className="brandimg"
//         style={{
//           position: 'relative',
//           top: '-50%',
//           left: '0',
//           zIndex: '10',
//           boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
//           padding: '15px',
//           borderRadius: '10px',
//           backgroundColor: '#fff',
//           textAlign: 'center',
//           display:'flex',
//           width:'45%',
//           flexDirection: 'column',
//         }}>
//         <b><h6 style={{display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'600'}}>Condition</h6></b>
//         <div>
//           <p
//             className={`pricep ${bikeType === "New Bike"? "bikeType1":""}`}
//             onClick={() => {
//               setExtraFieldCopy1({ "Bike Type ": "New Bike" });
//               setBikeType("New Bike")
//               }}
//               style={{
//                 display: 'inline-block',
//                 padding: '8px 15px',
//                 margin: '5px 0',
//                 border: '1px solid rgb(9, 25, 56)',
//                 borderRadius: '5px',
//                 color: '#333',
//                 marginRight:'5%',
//               }}>
//            New Bikes
//           </p>

//           <p
//             className={`pricep ${bikeType === "Used Bike"? "bikeType1":""}`}
//             onClick={() => {
//               setExtraFieldCopy({ "Bike Type ": "Used Bike" });
//               setBikeType("Used Bike");
//             }}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//             }}>
//            Used Bikes
//           </p>
//           </div>
//         </div>
//         <div style={{
//           position: 'relative',
//           top: '-50%',
//           left: '0',
//           zIndex: '10',
//           boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
//           padding: '15px',
//           borderRadius: '10px',
//           backgroundColor: '#fff',
//           textAlign: 'center',
//           display:'flex',
//           width:'45%',
//           flexDirection: 'column',
//         }} className="brandimg">
//         <b><h6 style={{display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'600'}}>FuelType</h6></b>
//           <div>
//           <p
//             className={`pricep ${bikeType1 === "Electric" ? "bikeType":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//               marginRight:'5%',
//             }}
//             onClick={() => { 
//               setExtraFieldCopy({ "Fuel Type ": "Electric" });
//               setBikeType1("Electric");
//             }}>
//            Electric
//           </p>
//           <p
//             className={`pricep ${bikeType1 === "Petrol" ? "bikeType":""}`}
//             style={{
//               display: 'inline-block',
//               padding: '8px 15px',
//               margin: '5px 0',
//               border: '1px solid rgb(9, 25, 56)',
//               borderRadius: '5px',
//               color: '#333',
//               marginRight:'5%',
//             }}
//             onClick={() => {
//               setExtraFieldCopy({ "Fuel Type ": "Petrol" });
//               setBikeType1("Petrol");
//             }}>
//            Petrol
//           </p>
//           </div>
//         </div>
//         </div>
//       );
//     } else if (selectedButton === "button4"){
//       return (
//         <div className="biky">
//           <div style={{alignItems:'center',width:"30%",justifyContent:'end'}} id="Km123" className="brandimg">
//             <h6 style={{ marginBottom: '8px', }}>Year of Registration</h6>
//             <select 
//             style={{
//               width: '100%',
//               padding: '8px',
//               marginTop: '4px', // Adjust this value as needed
//               border: '1px solid #ccc',
//               borderRadius: '4px',
//               boxSizing: 'border-box', // Include padding in height calculation
//               justifyContent:'end',
//             }}
//             onChange={(e)=>{setExtraFieldCopy2({"year":e.target.value});setYears(e.target.value)}}>
//               {
//                 year.map((ye)=>{
//                   return <option >{ye.toString()}</option>
//                 })
//               }
//             </select>
//           </div>
//         </div>
//         )
//     } else if (selectedButton === "button5"){
//       return(
//         <div className="biky">
//           <div style={{alignItems:'center',width:"30%",justifyContent:'end'}} id="Km123" className="brandimg">

//               <b><h6 style={{ marginBottom: '8px' }}>KM Driven</h6></b>
//               <select 
//               style={{
//                 width: '100%',
//                 padding: '8px',
//                 marginTop: '4px', // Adjust this value as needed
//                 border: '1px solid #ccc',
//                 borderRadius: '4px',
//                 boxSizing: 'border-box', // Include padding in height calculation
//                 justifyContent:'end',
//               }}
//               onChange={(e)=>{setExtraFieldCopy3({ "KM_Driven":e.target.value});
//                 setKms(e.target.value)}}>
//                   {
//                     km.map((k)=>{
//                       return <option>{k}</option>;

//                     })

//                   }

//               </select>

//           </div>
//         </div>
//       )
//     }
//     return null;
//   };

//   const searchArray = () => {
//     if (SearchValue?.trim()) {
//       dispatch(searchApi(SearchValue));
//     }
//   };

//   // AdsCards logic start

//   const [advClose, setadvClose] = useState(true);
//   useEffect(() => {
//     const isPopupClosed = sessionStorage.getItem('popupClosed');
//     const name = localStorage.getItem('name')
//     if (isPopupClosed && name) {
//       setadvClose(false);
//     }
//   }, []);
//   const handleCloseadv = () => {
//     setadvClose(false);
//     sessionStorage.setItem('popupClosed', 'true');
//   };
//   // AdsCards logic end




//   return (
//     <>


//      {/* AdsCards logic start */}
//      {advClose ? 

//      (
//       <div style={{ width: '400px', height:'230px', position:'fixed', bottom:"2%", right:"3%", padding: '20px', background: 'radial-gradient(circle, #3f8bff, #0059ff)', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', transition: 'box-shadow 0.3s ease-in-out',zIndex:"3" }}>
//      <span style={{ float: 'right', padding: '6px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"24px" }} onClick={handleCloseadv}>x</span>
//   {/* <img src="real-estate-app-icon.png" alt="Real Estate App Icon" style={{ width: '150px', height: '35px', marginBottom: '5px' }} /> */}
//   <div style={{ textAlign: 'center' }}>
//     <h2 style={{ color: '#fff', fontSize: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Find Your Best Bike!</h2>
//     <p style={{ color: '#f0f0f0', fontSize: '16px', marginBottom: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Discover thousands of listings and make your dream of owning a premium Bike.</p>
//     <div style={{ marginTop: '50px' }}>
//       <div style={{ height: '60px', width: '130px', borderRadius: '10px', padding: '14px 0px 8px', fontSize: '22px', fontWeight: 'bolder', color: '#ffb300', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', background: '#1ad09a', textAlign: 'center', position: 'absolute', left: '15%', top: '72%', zIndex: '1' }}>1,000+<span style={{ fontSize: '14px' }}>subs</span></div>
//      <Link to="/Pricing"><span id="quote" style={{ fontStyle: 'normal',fontWeight:"bolder", fontSize: '12px', height: '30px', width: '170px', backgroundColor: '#eae436', position: 'absolute', left: '45%', top: '77%', zIndex: '2', padding: '4px', color:"green" }}>Upgrade your plan now!</span></Link>
//       {/* <button className="download-btn">Download Now</button> */}
//     </div>
//   </div>
// </div>

//       ) : null}
//       {/* AdsCards logic end */}



//       <div style={sliderStyle}>
//         {/* <Carousel autoplay>
//       {images.map((image, index) => (
//         <div key={index}>
//           <img src={image.url} alt={`Image ${index + 1}`} style={{ width: "100%" }} height={300}/>
//         </div>
//       ))}
//     </Carousel>  */}
//         <BikeSlider />
//         <div className="filetr22">
//           <input
//             name="Brand Name"
//             required
//             className="custom-select2 rounded-lg"
//             style={{
//               // padding: "2px",
//               // position: "relative",
//               height: "47px",
//               width: "50%",
//               // zIndex: "2",
//               borderTopLeftRadius: "10px",
//               borderBottomLeftRadius: "10px",
//               border: "solid black 1.5px",
//               textTransform: "uppercase",
//               // border: "2px solid whiteSmoke",
//             }}
//             placeholder="Search your bikes ;eg-Yamaha mt-15"
//             onChange={(e) =>
//                               setSearchValue(e.target.value.trim())
//                             }
//             // dropdownClassName="select-dropdown"
//           />
//           <Link
//             to={`/ads-listing1/`}
//             onClick={searchArray}
//           >
//             <button
//               style={{
//                 color: "white",
//                 backgroundColor: "black",
//                 fontSize: "30px",
//                 height: "47px",
//                 width: "45px",
//                 display: "block",
//                 // padding: "8px 6px",
//                 marginLeft: "-0.5cm",
//                 borderTopRightRadius: "4px",
//                 borderBottomRightRadius: "4px",
//               }}
//             >
//               <SearchOutlined />
//             </button>
//           </Link>
//         </div>
//       </div>
//       <div className="wholefil mt-0">
//         {/*filter button start */}
//         <div>
//           <center>
//             <div className="headdingforfind">
//             <div className="caption"> 
//               <h3 >
//                 Find Your Dream Bike:
//               </h3>
//               <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }

//                   searchSending["extraField"] = { ...extraFieldCopy , ...extraFieldCopy1,...extraFieldCopy2,...extraFieldCopy3}; // Object Assign to searchSending object
//                   searchSending["subCategoryValue"] = "Bikes" 
//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <button
//                   className="btn houzez-search-button elementor-button elementor-size-sm"
//                   style={{
//                     background: "#a71616bf",
//                     color: "white",

//                   }}
//                   id="serbtn321"
//                 >
//                   Search
//                 </button>
//               </Link>
//             </div>

//               <div className="Button231">
//                 <div className="mainfilbtn">
//                 <button
//                   onClick={() => {setSelectedButton("button1");
//                   setActive1("act")} }
//                   className={`button321 ${active1 ==="act" ? 'act':''}`}
//                 >
//                   Brand Name
//                 </button>
//                 <button
//                   onClick={() => {setSelectedButton("button2");
//                   setActive1("act1")} }
//                   className={`button321 ${active1 ==="act1" ? 'act':''}`}

//                 >
//                   Budget
//                 </button>
//                 <button
//                   onClick={() => {setSelectedButton("button3");
//                   setActive1("act2")} }
//                   className={`button321 ${active1 ==="act2" ? 'act':''}`}
//                 >
//                   BikeTYpe
//                 </button>
//                 <button
//                   onClick={() => {setSelectedButton("button4");
//                   setActive1("act3")} }
//                   className={`button321 ${active1 ==="act3" ? 'act':''}`}
//                 >
//                  Year Of Reg
//                 </button>
//                 <button
//                   onClick={() => {setSelectedButton("button5");
//                   setActive1("act4")} }
//                   className={`button321 ${active1 ==="act4" ? 'act':''}`}
//                 >
//                   KM Driven
//                 </button>

//                 </div>
//                 &nbsp; &nbsp;
//               </div>
//               <br />
//               <div style={{ width: "90%" }}>
//                 {selectedButton && renderModalContent()}
//               </div>
//             </div>
//           </center>
//         </div>
//         {/*filter button end */}

//         {/* bike cat head end */}
//         {/* search end */}
//         {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
//           <div>
//             <h5>
//               {" "}
//               <div className="example">
//                 <Spin />
//               </div>{" "}
//             </h5>{" "}
//           </div>
//         )}
//         {!loading && ( // Conditionally rendering the content if 'loading' is false
//         <>
//           <div className="mb-3" style={isMobile ? mobstyle : deskstyle}>
//             <Feature value="Bikes" />{" "}
//             {/* Rendering the 'Feature' component with value "RealEstate" */}
//             {/* <Owldemo1 greeting={greeting} subcategory={"Bikes"} />{" "}
//             {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "PG-Hostel" */}
//             {/* <Owldemo1
//               greeting={greeting}
//               subcategory={"Spare Parts - Accessories"}
//             />{" "} */} 
//             <Allcatads greeting={greeting} subcategory={"Bikes"}/>
//             {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
//           </div>
//           <div style={isMobile? mobi: deski}>
//       <TrendingAds/>
//       </div></>
//         )}
//         <NewsArticles category={"Bikes"} />{" "}
//         {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
//         <MyGallery selectedCategory="Bikes"/>
//       </div>
//       {/* <NewsArticles category={"RealEstate"}/> */}
//     </>
//   );
// };

// export default Bikescat;























// import React, { useEffect, useState } from "react"
// import FeaturedAds from '../../../Ads/FeaturedAds/featuredAds';
import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import CategoryUiSection from "../CategoryUiScetion/CategoryUiSection"; // Importing the 'CategoryUiSection' component
import SearchBox from "../searchBox/SearchBox"; // Importing the 'SearchBox' component
import RealEstateSection from "../section/realEstateSection"; // Importing the 'RealEstateSection' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { subCategoryInput } from "../../../../Model/SubCategoryCons";
import { SearchOutlined } from "@ant-design/icons";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import { stateMain, locationData } from "../../../Location";
import "../searchBox/SearchBox.css";
import { Link } from "react-router-dom";
import BikeSlider from "../../../../Shared/CategorySlider/BikeSlider";
import { Carousel } from "antd";
import { FitScreen } from "@mui/icons-material";
import "./allfilter.css";
import { isMobile } from "react-device-detect";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import Allcatads from "../../../Allcatads";
import { FcSearch } from "react-icons/fc";
import { BsSearch } from "react-icons/bs";




const mobstyle = {
  width: "98%",
  margin: "auto"
};
const deskstyle = {
  padding: "20px",
  marginBottom: "-10px",
  marginRight: "10rem",
  marginLeft: "10rem",
};
const mobi = {



}
const deski = {
  marginLeft: "7rem", marginRight: "7rem"
}

// const mobifilter={



// }
// const deskifilter={
//   marginLeft:"7rem",marginRight:"7rem"
// }
const Bikescat = () => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [SearchValue, setSearchValue] = useState("Bike");
  const [active, setActive] = useState(null);
  const [active1, setActive1] = useState(null);
  const [budget, setBudget] = useState(null);
  const [bikeType, setBikeType] = useState(null);
  const [bikeType1, setBikeType1] = useState(null);
  const [years, setYears] = useState();
  const [kms, setKms] = useState();
  // const [selectedFilter1, setSelectedFilter1] = useState(null);

  const handleFilterButtonClick = (filterType) => {
    setSelectedFilter(filterType);
  };

  const greeting = "Bikes";

  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch();
  const dispatch = useDispatch();
  // Accessing the Redux dispatch function

  useEffect(() => {
    disptach(add({ view: ["Bikescat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);

  const [extraFieldCopy, setExtraFieldCopy] = useState();
  const [extraFieldCopy1, setExtraFieldCopy1] = useState();
  const [extraFieldCopy2, setExtraFieldCopy2] = useState();
  const [extraFieldCopy3, setExtraFieldCopy3] = useState();
  const [extraFieldCopy4, setExtraFieldCopy4] = useState();

  let year = [];
  var date = new Date().getFullYear();
  for (let i = 1980; i <= date; i++) {
    year.push(i);
  }

  let km = [];
  var Driven = subCategoryInput.Bikes.Bikes["KM_Driven "];
  for (let i = 0; i < Driven.length; i++) {
    km.push(Driven[i])
  }

  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    searchValue: null,
    category: "Bikes",
    minPrice: null,
    maxPrice: null,
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  const sliderStyle = {
    width: "100%",
    // marginTop:"2cm",
    // maxWidth: "100vw",
  };
  const [selectedButton, setSelectedButton] = useState();

  // Step 2: Create CSS classes to control modal positioning
  const modalContainerStyle = {
    top: "-50%", // Adjust this value to control the distance from the button

    // width:"100%"
  };

  const modalStyle = {
    // position: "relative",

    // display:"flex",
    // width:"100%",
    top: "-50%", // Adjust this value to control the distance from the button
    left: 0,
    zIndex: 10, // Ensure the modal is above other elements

    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const renderModalContent = () => {
    if (selectedButton === "button1") {
      return (
        <div style={modalStyle} id="barndimgId" className="brandimg">
          <img
            src="https://imgd.aeplcdn.com/0X0/bw/makes/honda20200511152343.jpg?q=75"
            alt=""
            height={100}
            width={100}
            className={`filimg ${active === "Honda" ? 'selected' : ''}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Honda" });
              setActive("Honda");
            }}
          />
          <img
            src="https://imgd.aeplcdn.com/0X0/bw/makes/royal-enfield20200508193112.jpg?q=75"
            alt=""
            height={100}
            width={100}
            className={`filimg ${active === "Royal Enfield" ? 'selected' : ''}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Royal Enfield" });
              setActive("Royal Enfield")
            }}
          />
          <img
            src="https://imgd.aeplcdn.com/0X0/bw/makes/hero20200508192826.jpg?q=75"
            alt=""
            height={100}
            width={100}
            className={`filimg ${active === "Hero" ? 'selected' : ''}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Hero" });
              setActive("Hero");
            }}
          />
          <img
            src="https://imgd.aeplcdn.com/0X0/bw/makes/yamaha20200508193220.jpg?q=75"
            alt=""
            height={100}
            width={100}
            className={`filimg ${active === "Yamaha" ? 'selected' : ''}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Yamaha" });
              setActive("Yamaha");
            }}
          />
          <img
            src="https://imgd.aeplcdn.com/0X0/bw/makes/bajaj20200508192534.jpg?q=75"
            alt=""
            height={100}
            width={100}
            className={`filimg ${active === "Bajaj" ? 'selected' : ''}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Bajaj" });
              setActive("Bajaj");
            }}
          />
          <img
            src="https://imgd.aeplcdn.com/0X0/bw/makes/tvs20200508193203.jpg?q=75"
            alt=""
            height={100}
            width={100}
            className={`filimg ${active === "TVS" ? 'selected' : ''}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "TVS" });
              setActive("TVS");
            }}
          />
          <img
            src="https://imgd.aeplcdn.com/0X0/bw/makes/bmw20200508192553.jpg?q=75"
            alt=""
            height={100}
            width={100}
            className={`filimg ${active === "BMW" ? 'selected' : ''}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "BMW" });
              setActive("BMW");
            }}

          />
          <img
            src="https://imgd.aeplcdn.com/0X0/bw/makes/ktm20200518163508.jpg?q=75"
            alt=""
            height={100}
            width={100}
            className={`filimg ${active === "KTM" ? 'selected' : ''}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "KTM" });
              setActive("KTM")
            }}
          />
        </div>
      );
    } else if (selectedButton === "button2") {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${budget === 20000 ? 'budget' : ''}`}
            onClick={() => {

              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "20000"
              }));
              setBudget(20000);
            }}
          >
            Under 20000
          </p>
          <p
            className={`pricep ${budget === 50000 ? 'budget' : ''}`}
            onClick={() => {

              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "50000",
              }));
              setBudget(50000);
            }}
          >
            Under 50000
          </p>
          <p
            className={`pricep ${budget === 70000 ? 'budget' : ''}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "70000",
              }));
              setBudget(70000)
            }}
          >
            Under 70000
          </p>
          <p
            className={`pricep ${budget === 90000 ? 'budget' : ''}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "90000",
              }));
              setBudget(90000);
            }}
          >
            Under 90000
          </p>
          <p
            className={`pricep ${budget === 120000 ? 'budget' : ''}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "120000",
              }));
              setBudget(120000);
            }}
          >
            Under 1.2 Lakh
          </p>
          <p
            className={`pricep ${budget === 150000 ? 'budget' : ''}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "150000",
              }));
              setBudget(150000)
            }}
          >
            Under 1.5 Lakh
          </p>
          <p
            className={`pricep ${budget === 170000 ? 'budget' : ''}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "170000",
              }));
              setBudget(170000);
            }}
          >
            Under 1.7 Lakh
          </p>
          <p
            className={`pricep ${budget === 200000 ? 'budget' : ''}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "200000",
                maxPrice: "999999999",
              }));
              setBudget(200000)
            }}
          >
            Above 2.0 Lakh
          </p>
        </div>
      );
    } else if (selectedButton === "button3") {
      return (
        <div className="biky"
          style={{ width: '90%', display: 'flex', gap: '5%' }}>
          <div className="brandimg"
            style={{
              position: 'static',
              top: '-50%',
              left: '0',
              zIndex: '10',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
              padding: '15px',
              borderRadius: '10px',
              backgroundColor: '#fff',
              textAlign: 'center',
              display: 'flex',
              width: '45%',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <b><h6 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600' }}>Condition</h6></b>
            <div>
              <p
                className={`pricep ${bikeType === "New Bike" ? "bikeType1" : ""}`}

                onClick={() => {
                  setExtraFieldCopy1({ "Bike Type ": "New Bike" });
                  setBikeType("New Bike")
                }}
                style={{
                  display: 'inline-block',
                  padding: '8px 15px',
                  margin: '5px 0',
                  border: '1px solid rgb(9, 25, 56)',
                  borderRadius: '5px',
                  color: '#333',
                  marginRight: '5%',
                }} >
                New Bikes
              </p>

              <p
                className={`pricep ${bikeType === "Used Bike" ? "bikeType1" : ""}`}
                onClick={() => {
                  setExtraFieldCopy({ "Bike Type ": "Used Bike" });
                  setBikeType("Used Bike");
                }}
                style={{
                  display: 'inline-block',
                  padding: '8px 15px',
                  margin: '5px 0',
                  border: '1px solid rgb(9, 25, 56)',
                  borderRadius: '5px',
                  color: '#333',

                }}>
                Used Bikes
              </p>
            </div>
          </div>
          <div style={{
            position: 'static',
            top: '-50%',
            left: '0',
            zIndex: '10',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
            padding: '15px',
            borderRadius: '10px',
            backgroundColor: '#fff',
            textAlign: 'center',
            display: 'flex',
            width: '45%',
            flexDirection: 'column',
            justifyContent: 'center',
          }} className="brandimg">
            <b><h6 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600' }}>FuelType</h6></b>
            <div>
              <p
                className={`pricep ${bikeType1 === "Electric" ? "bikeType" : ""}`}
                style={{
                  display: 'inline-block',
                  padding: '8px 15px',
                  margin: '5px 0',
                  border: '1px solid rgb(9, 25, 56)',
                  borderRadius: '5px',
                  color: '#333',
                  marginRight: '5%',
                }}
                onClick={() => {
                  setExtraFieldCopy({ "Fuel Type ": "Electric" });
                  setBikeType1("Electric");
                }}>
                Electric
              </p>
              <p
                className={`pricep ${bikeType1 === "Petrol" ? "bikeType" : ""}`}
                style={{
                  display: 'inline-block',
                  padding: '8px 15px',
                  margin: '5px 0',
                  border: '1px solid rgb(9, 25, 56)',
                  borderRadius: '5px',
                  color: '#333',
                  marginRight: '5%',
                }}
                onClick={() => {
                  setExtraFieldCopy({ "Fuel Type ": "Petrol" });
                  setBikeType1("Petrol");
                }}>
                Petrol
              </p>
            </div>
          </div>
        </div>
      );
    } else if (selectedButton === "button4") {
      return (
        <div className="biky">
          <div id="Km123" className="brandimg regest-BIKE-fil-but">
            {/* <b><h6 className="regest-BIKE-fil-header">Select Year</h6></b> */}
            <select className="regest-BIKE-fil-SELECT"
              // style={{
              //   width: '100%',
              //   padding: '8px',
              //   marginTop: '4px', // Adjust this value as needed
              //   border: '1px solid #ccc',
              //   borderRadius: '4px',
              //   boxSizing: 'border-box', // Include padding in height calculation
              //   justifyContent:'end',

              // }}
              onChange={(e) => { setExtraFieldCopy2({ "year": e.target.value }); setYears(e.target.value) }}>
              {
                year.map((ye) => {
                  return <option className="regest-BIKE-fil-opt">{ye.toString()}</option>
                })
              }
            </select>
          </div>
        </div>
      )
    } else if (selectedButton === "button5") {
      return (
        <div className="biky">
          <div id="Km123" className="brandimg regest-BIKE-fil-but">

            {/* <b><h6 className="regest-BIKE-fil-header" >KM Driven</h6></b> */}
            <select className="regest-BIKE-fil-SELECT"
              // style={{
              //   width: '100%',
              //   padding: '8px',
              //   marginTop: '4px', // Adjust this value as needed
              //   border: '1px solid #ccc',
              //   borderRadius: '4px',
              //   boxSizing: 'border-box', // Include padding in height calculation
              //   justifyContent:'end',
              // }}
              onChange={(e) => {
                setExtraFieldCopy3({ "KM_Driven": e.target.value });
                setKms(e.target.value)
              }}>
              {
                km.map((k) => {
                  return <option className="regest-BIKE-fil-opt">{k}</option>;

                })

              }

            </select>

          </div>
        </div>
      )
    }
    return null;
  };

  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };

  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);
  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end




  return (
    <>


      {/* AdsCards logic start */}
      {advClose ?

        (
          <div style={{ width: '400px', height: '230px', position: 'fixed', bottom: "2%", right: "3%", padding: '20px', background: 'radial-gradient(circle, #3f8bff, #0059ff)', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', transition: 'box-shadow 0.3s ease-in-out', zIndex: "3" }}>
            <span style={{ float: 'right', padding: '6px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px', fontSize: "24px" }} onClick={handleCloseadv}>x</span>
            {/* <img src="real-estate-app-icon.png" alt="Real Estate App Icon" style={{ width: '150px', height: '35px', marginBottom: '5px' }} /> */}
            <div style={{ textAlign: 'center' }}>
              <h2 style={{ color: '#fff', fontSize: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Find Your Best Bike!</h2>
              <p style={{ color: '#f0f0f0', fontSize: '16px', marginBottom: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Discover thousands of listings and make your dream of owning a premium Bike.</p>
              <div style={{ marginTop: '50px' }}>
                <div style={{ height: '60px', width: '130px', borderRadius: '10px', padding: '14px 0px 8px', fontSize: '22px', fontWeight: 'bolder', color: '#ffb300', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', background: '#1ad09a', textAlign: 'center', position: 'absolute', left: '15%', top: '72%', zIndex: '1' }}>1,000+<span style={{ fontSize: '14px' }}>subs</span></div>
                <Link to="/Pricing"><span id="quote" style={{ fontStyle: 'normal', fontWeight: "bolder", fontSize: '12px', height: '30px', width: '170px', backgroundColor: '#eae436', position: 'absolute', left: '45%', top: '77%', zIndex: '2', padding: '4px', color: "green" }}>Upgrade your plan now!</span></Link>
                {/* <button className="download-btn">Download Now</button> */}
              </div>
            </div>
          </div>

        ) : null}
      {/* AdsCards logic end */}


      {/* <BikeSlider /> */}
      <div style={sliderStyle}>

         <BikeSlider />
         <div className="filetr22 Fil-BIKE-maisearch-awi2">
           <input
             name="Brand Name"
             required
             className="custom-select2 rounded-lg custom-select2-awi2"
             style={{
               // padding: "2px",
               // position: "relative",
               height: "47px",
               width: "50%",
               // zIndex: "2",
               borderTopLeftRadius: "10px",
               borderBottomLeftRadius: "10px",
               border: "solid black 1.5px",
               textTransform: "uppercase",
               // border: "2px solid whiteSmoke",
             }}
             placeholder="Search your bikes ;eg-Yamaha mt-15"
             onChange={(e) =>
                               setSearchValue(e.target.value.trim())
                             }
             // dropdownClassName="select-dropdown"
           />
           <Link
             to={`/ads-listing1/`}
             onClick={searchArray}
           >
             <button
              style={{
                color: "white",
                backgroundColor: "black",
                fontSize: "30px",
                height: "47px",
                width: "45px",
                display: "block",
                // padding: "8px 6px",
                marginLeft: "-0.5cm",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <SearchOutlined />
            </button>
          </Link>
        </div>
      </div>

      {/* <div  className="filetr22 Fil-BIKE-maisearch-awi">
        <input
          name="Brand Name"
          required
          className="custom-select2 rounded-lg custom-select2-awi2"
          style={{
            paddingLeft: "10px",
            // position: "relative",
            height: "47px",
            width: "50%",
            // zIndex: "2",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            border: "solid black 1.5px",
            textTransform: "uppercase",
            // border: "2px solid whiteSmoke",
          }}
          placeholder="Search your bikes ;eg-Yamaha mt-15"
          onChange={(e) =>
            setSearchValue(e.target.value.trim())
          }
        // dropdownClassName="select-dropdown"
        />
        <Link
          to={`/ads-listing1/`}
          onClick={searchArray}
        >
          <button
            style={{
              color: "white",
              backgroundColor: "black",
              fontSize: "30px",
              height: "47px",
              width: "45px",
              display: "block",
              // padding: "8px 6px",
              marginLeft: "-0.5cm",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
            }}
          >
            <SearchOutlined />
          </button>
        </Link>
      </div> */}
 





      <div className="wholefil mt-0">
        {/*filter button start */}
        <div>
          <center>
            <div className="headdingforfind">
              {/* <div className="caption  caption-BIKECAT-24">
                <h3>
                  Find Your Dream Bike:
                </h3>
                <Link
                  to={`/ads-listing/`}
                  onClick={() => {
                    console.log(searchSending);
                    for (const key in searchSending) {
                      if (
                        searchSending[key]?.trim() === "" ||
                        searchSending[key] === null
                      ) {
                        delete searchSending[key];
                      }
                    }

                    searchSending["extraField"] = { ...extraFieldCopy, ...extraFieldCopy1, ...extraFieldCopy2, ...extraFieldCopy3 }; // Object Assign to searchSending object
                    searchSending["subCategoryValue"] = "Bikes"
                    disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                  }}
                >
                  <button
                    className="btn houzez-search-button elementor-button elementor-size-sm"
                    style={{
                      background: "#a71616bf",
                      color: "white",
                      paddingBottom: "25px"
                    }}
                    id="serbtn321"
                  >
                    Search
                  </button>
                </Link>
              </div> */}

              <div className="Button231 but-BIKE-whole-cont">
                <div className="mainfilbtn">
                  <button
                    onClick={() => {
                      setSelectedButton("button1");
                      setActive1("act")
                    }}
                    className={`button321 ${active1 === "act" ? 'act' : ''}`}
                  >
                    Brand Name
                  </button>
                  <button
                    onClick={() => {
                      setSelectedButton("button2");
                      setActive1("act1")
                    }}
                    className={`button321 ${active1 === "act1" ? 'act' : ''}`}

                  >
                    Budget
                  </button>
                  <button
                    onClick={() => {
                      setSelectedButton("button3");
                      setActive1("act2")
                    }}
                    className={`button321 ${active1 === "act2" ? 'act' : ''}`}
                  >
                    BikeTYpe
                  </button>
                  <button
                    onClick={() => {
                      setSelectedButton("button4");
                      setActive1("act3")
                    }}
                    className={`button321 ${active1 === "act3" ? 'act' : ''}`}
                  >
                    Year Of Reg
                  </button>
                  <button
                    onClick={() => {
                      setSelectedButton("button5");
                      setActive1("act4")
                    }}
                    className={`button321 ${active1 === "act4" ? 'act' : ''}`}
                  >
                    KM Driven
                  </button>

                  {/* NEW ADDITIONOF BUTTON */}
                  <Link
                    to={`/ads-listing/`}
                    onClick={() => {
                      console.log(searchSending);
                      for (const key in searchSending) {
                        if (
                          searchSending[key]?.trim() === "" ||
                          searchSending[key] === null
                        ) {
                          delete searchSending[key];
                        }
                      }

                      searchSending["extraField"] = { ...extraFieldCopy, ...extraFieldCopy1, ...extraFieldCopy2, ...extraFieldCopy3 }; // Object Assign to searchSending object
                      searchSending["subCategoryValue"] = "Bikes"
                      disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                    }}
                  >
                    <button className='button321 button-BIKECAT-24' 
                      // className="button-BIKECAT-24"
                      // style={{ backgroundColor: 'whitesmoke', color: "rgb(9, 25, 56)" }}
                      // id="serbtn321"
                    >
                    {/* <FcSearch className="Fcsearch-class" /> */}
                    <BsSearch  className="Fcsearch-class" />
                      &nbsp;  Search
                    
                    </button>
                  </Link>

                  {/* TILLHERE */}
                </div>
                &nbsp; &nbsp;
              </div>
              <br />
              <div style={{ width: "90%" }}>
                {selectedButton && renderModalContent()}
              </div>
            </div>
          </center>
        </div>
        {/*filter button end */}

        {/* bike cat head end */}
        {/* search end */}
        {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
          <div>
            <h5>
              {" "}
              <div className="example">
                <Spin />
              </div>{" "}
            </h5>{" "}
          </div>
        )}
        {!loading && ( // Conditionally rendering the content if 'loading' is false
          <>
            <div className="mb-3" style={isMobile ? mobstyle : deskstyle}>
              <Feature value="Bikes" />{" "}
              {/* Rendering the 'Feature' component with value "RealEstate" */}
              {/* <Owldemo1 greeting={greeting} subcategory={"Bikes"} />{" "}
            {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "PG-Hostel" */}
              {/* <Owldemo1
              greeting={greeting}
              subcategory={"Spare Parts - Accessories"}
            />{" "} */}
              <Allcatads greeting={greeting} subcategory={"Bikes"} />
              {/* Rendering the 'Owldemo1' component with greeting "RealEstate" and subcategory "Rent-Commercial" */}
            </div>
            <div style={isMobile ? mobi : deski}>
              <TrendingAds />
            </div></>
        )}
        <NewsArticles category={"Bikes"} />{" "}
        {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
        <MyGallery selectedCategory="Bikes" />
      </div>
  {/* <NewsArticles category={"RealEstate"}/> */ }
    </>
  );
};

export default Bikescat;
