import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import {MdVerifiedUser} from "react-icons/md"
import { isMobile } from "react-device-detect";
import { url } from "../../../env";
import './ActiveAds.css'

const ActiveAds = ({ ads, deleteAds }) => {
  const mobileStyle = { 
  marginRight: "250px",
  };
  const desktopStyle = {
    marginRight: "160px",

  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteAds(ads.pk);
    setIsModalOpen(false);
  };
  // onClick={() => deleteAds(ads.pk)}
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const[verified,setVerified]=useState(false)
  const userid = localStorage.getItem("userid");

  useEffect(()=>{
    // const apiResult = { verified: true };
    var formdata = new FormData();
formdata.append("userid",userid );

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch( url+"api/adsapi/checkVerified", requestOptions)
  .then(response => response.json())
  .then(result => {
    setVerified(result.verifiedCustomer)
    console.log(result,"resvar")
  })
  .catch(error => console.log('error', error));
  },[])
console.log(verified,"lo")
  return (
    <div className="verfication-product-card-m" 
    // style={{width :"80%",marginLeft: "10%", marginRight: "10%"}}
    >
    <div className="col-sm-4 col-md-4 col-lg-12  verfication-product-card-w" 
    // style={{width: "270px",}}
    >
      <div
        className="product-card verfication-product-card mb-4"
        style={{ border: "2px solid gray",borderRadius:'0px', boxShadow: "lightgray 1px 2px 6px 3px",}}
      >
        <div className="product-media">
          <div className="product-img">
            <img
              src={ads.fields.image}
              alt="product"
              style={{ borderradius: "3px", width: "100%", height: "180px" }}
            />
          </div>
          
          <div className="product-type d-flex">
          <div  style={isMobile ? mobileStyle : desktopStyle} className="start-0"> 
          {verified? (
                        <div className="start-0"><MdVerifiedUser style={{color:"greenyellow",fontSize:"30px"}}/></div>
                      ):(
                        <div></div>
                      )
                      }

          </div>
          <div>  
          
              <b className="flat-badge booking text-white" style={{backgroundColor: "#006f80"}}>
                Verified
              </b>
            {/* ) : (
              <b className="flat-badge booking text-danger ">On Verification</b>
            )} */}
            {ads.fields.is_featured ? (
              <span className="flat-badge booking">featured</span>
            ) : null} 
          </div>
          </div>
          <ul className="product-action">
            <li className="view">
              <i className="fas fa-eye" />
              <span>264</span>
            </li>
            <li className="click">
              <i className="fas fa-mouse" />
              <span>134</span>
            </li>
            <li className="rating">
              <i className="fas fa-star" />
              <span>4.5/7</span>
            </li>
          </ul>
        </div>
        <div className="product-content pb-2" style={{backgroundColor: "whitesmoke"}}>
          <h4 className="fs-14 mt-1" style={{ marginTop: "-10px" }}>
            <b className="font-weight-bold">
              {ads.fields.title.slice(0, 20)}...
            </b>
          </h4>
          <div className="product-meta">
            <span>
              <i
                className="fas fa-map-marker-alt"
                style={{ fontSize: "10px" }}
              />
              {ads.fields.City.slice(0, 10)}
              &nbsp;,&nbsp;{ads.fields.state.slice(0, 10)}
            </span><br/>
            <span>
              <i className="fas fa-clock" style={{ fontSize: "10px" }} />

              {ads.fields.date_created?.slice(0, 16)}
            </span>
          </div>
          <div className="product-info">
            {ads.fields.category == "Jobs" ? null : (
              <h5 className="product-price fs-14">₹{ads.fields.price}</h5>
            )}
          </div>
          <div className="d-flex" style={{ marginTop: "-10px",}}>
            <Link
              to={`/editAds/${ads.pk}`}
              className="btn-sm w-50 text-center text-white"
              style={{borderRadius: "0px",border: '2px solid white', backgroundColor: "#006f80",}}
            >
              <button type="button" >Edit </button>
            </Link>
            <button
              type="button"
              className="btn-sm  w-50 text-center text-white"
              value={ads.pk}
              // onClick={() => deleteAds(ads.pk)}
              onClick={showModal}
              style={{borderRadius: "0px",border: '2px solid white', backgroundColor: "#006f80",}}
            >
              Delete
            </button>
            <Modal
              title="Basic Modal"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p>Are You Sure You Want To Delete this Ad</p>
            </Modal>
            {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ActiveAds;
