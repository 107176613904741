import React, { useEffect, useState } from "react";
import {
  BsFillHeartFill,
  BsList,
  BsPersonSquare,
  BsCart4,
} from "react-icons/bs";
import { IoMdLogOut } from "react-icons/io";
import { IoMdLogIn } from "react-icons/io";
import { VscAccount } from "react-icons/vsc";
import { VscDashboard } from "react-icons/vsc";
import { VscPersonAdd } from "react-icons/vsc";
import { GiReceiveMoney } from "react-icons/gi";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { decrypt } from "../encryptDecrypt/encryptDecrypt";
// import { useDispatch } from "react-redux";
import { getExtraField } from "../../../store/ProductFilterSlice";
import { Electronics, Furniture, Pets, Mobiles, url } from "../../env";

import UserProfileDetailsSlice from "../../../store/UserProfileDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import side from "../../images/side.png";

const Sidebar = () => {
  const userProfileDetails = useSelector((state) => state.userProfileData);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [toggles, setToggles] = useState(false);
  const dispatch = useDispatch();
  const handleClick = (category) => {
    let obj = { category, extraField: {} };
    dispatch(getExtraField(obj));
  };
  const userData = useSelector((state) => state.userIdSlice);
  // const dispatch = useDispatch();

  var email1 = email; // Replace with the actual email
  console.log("email1", email1);

  const [businessver, setBusinessver] = useState(false); // Initialize with null

  useEffect(() => {
    if (email) {
      // Ensure email is not undefined
      var formdata = new FormData();
      formdata.append("email", email);
      console.log("myone", email);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(url + "api/adsapi/businessProfileVerified", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setBusinessver(result.business_profile_verified);
          console.log(result, "resvaerarasrar");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [email]);

  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      let result = decrypt("userdata");
      setName(result.name);
      setEmail(result.email);
    }
  }, []);
  useEffect(() => {
    if (toggles) {
      setToggles(false);
    }
  }, [toggles]);

  return (
    <>
      {/* <div className="offcanvas offcanvas-start w-25" tabIndex={-1} id="offcanvas" */}
      {!toggles ? (
        <div
          className="offcanvas offcanvas-start "
          style={{ width: "280px", position: "fixed" }}
          tabIndex={-1}
          id="offcanvas"
          data-bs-keyboard="false"
          data-bs-backdrop="false"
        >
          <div
            className="offcanvas-header"
            style={{ background: "linear-gradient(60deg,#0f1c54,#2b224c)" }}
          >
            <h6 className="offcanvas-title text-white" id="offcanvas">
              Hola9
            </h6>
            {/* <h6 className="offcanvas-title d-none d-sm-block" id="offcanvas">
        Menu
      </h6> */}
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{ backgroundColor: "white" }}
            />
          </div>
          <div
            className="offcanvas-body p-0"
            style={{ background: "linear-gradient(60deg,#0f1c54,#2b224c)" }}
          >
            <ul
              style={{ overflowX: "hidden" }}
              className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
              id="menu"
            >
              {localStorage.getItem("access_token") == null ? (
                <li
                  className="nav-item"
                  style={{
                    background: "linear-gradient(60deg,#0f1c54,#2b224c)",
                  }}
                >
                  <hr
                    style={{
                      marginTop: "0px",
                      width: "200px",
                      color: "rgb(15 56 84 / 11%)",
                    }}
                  />
                  <Link
                    to="/signup/"
                    onClick={() => setToggles(!toggles)}
                    className="nav-link text-truncate text-white"
                    style={{ marginTop: "-15px" }}
                  >
                    <VscPersonAdd style={{ fontSize: "20px" }} />
                    <span className="ms-1  d-sm-inline">
                      <b>Signup</b>
                    </span>
                  </Link>
                  <hr
                    style={{
                      marginTop: "0px",
                      width: "200px",
                      color: "rgb(15 56 84 / 11%)",
                    }}
                  />

                  <Link
                    to="/login/"
                    onClick={() => setToggles(!toggles)}
                    className="nav-link text-truncate p-2 text-white rounded"
                    style={{ marginTop: "-15px" }}
                  >
                    <IoMdLogIn style={{ fontSize: "20px" }} />
                    <span className="ms-1  d-sm-inline">
                      <b>Login</b>
                    </span>
                  </Link>

                  <hr
                    style={{
                      marginTop: "0px",
                      width: "280px",
                      color: "rgb(15 56 84 / 11%)",
                    }}
                  />
                </li>
              ) : (
                <div
                  style={{
                    display: "flex",
                    background: "linear-gradient(60deg,#0f1c54,#2b224c)",
                  }}
                >
                  {userProfileDetails.status ? (
                    <div class="loader "></div>
                  ) : (
                    <img
                      className="m-1 p-0"
                      alt="profile"
                      style={{
                        width: "80px",
                        height: "80px",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      src={
                        userProfileDetails?.data.length === 0 ||
                        userProfileDetails?.data[0]?.fields?.image ==
                          "undefined"
                          ? side
                          : userProfileDetails?.data[0]?.fields?.image
                      }
                    />
                  )}
                  <li
                    className="nav-item"
                    style={{
                      background: "linear-gradient(60deg,#0f1c54,#2b224c)",
                      marginTop: "-7px",
                    }}
                  >
                    <Link
                      to="/dashboard/profile"
                      onClick={() => setToggles(!toggles)}
                      className="nav-link text-truncate p-2 text-white "
                    >
                      <VscAccount style={{ fontSize: "20px" }} />
                      <span className="ms-1  d-sm-inline">
                        <b>{name}</b>
                      </span>
                    </Link>
                    <Link
                      to="/dashboard/"
                      className="nav-link text-truncate p-2 text-white rounded"
                      onClick={() => setToggles(!toggles)}
                      style={{
                        marginTop: "-12px",
                        background: "linear-gradient(60deg,#0f1c54,#2b224c)",
                      }}
                    >
                      <VscDashboard style={{ fontSize: "20px" }} />
                      <span className="ms-1  d-sm-inline ">
                        <b>Dashboard</b>
                      </span>
                    </Link>
                    
                      {businessver === true ? (
                   <Link
                   to="/allCategories/"
                   className="nav-link text-truncate text-white p-2"
                   style={{ marginTop: "-15px" }}
                 >
                   <GiReceiveMoney style={{ fontSize: "20px" }} />
                   <span className="ms-1  d-sm-inline">
                     <b>For Bussiness</b>
                   </span>
                 </Link>
                ) : (
                  <Link
                      to="/businessform"
                      className="nav-link text-truncate text-white p-2"
                      style={{ marginTop: "-15px" }}
                    >
                      <GiReceiveMoney style={{ fontSize: "20px" }} />
                      <span className="ms-1  d-sm-inline">
                        <b>For Bussiness</b>
                      </span>
                    </Link>
                )}
                    <Link
                      to="/logout/"
                      className="nav-link text-truncate text-white p-2"
                      style={{ marginTop: "-15px" }}
                    >
                      <IoMdLogOut style={{ fontSize: "20px" }} />
                      <span className="ms-1  d-sm-inline">
                        <b>Logout</b>
                      </span>
                    </Link>
                    <hr
                      style={{
                        marginTop: "0px",
                        width: "280px",
                        color: "rgb(15 56 84 / 11%)",
                      }}
                    />

                    <hr
                      style={{
                        marginTop: "0px",
                        width: "280px",
                        color: "rgb(15 56 84 / 11%)",
                      }}
                    />
                  </li>
                </div>
              )}
              <div
                style={{
                  background:
                    "linear-gradient(60deg, rgb(15 56 84 / 89%), rgb(43 34 76 / 88%))",
                  width: "280px",
                }}
              >
                <li className="nav-item mx-2 ">
                  <span className="nav-link text-truncate">
                    <Link
                      to="/"
                      className="mycategories"
                      onClick={() => setToggles(!toggles)}
                    >
                      <b className="text-white">Home</b>{" "}
                    </Link>
                  </span>
                </li>
                <li className="nav-item mx-2 ">
                  <span className="nav-link text-truncate">
                    <Link
                      to="/aboutUs/"
                      className="mycategories"
                      onClick={() => setToggles(!toggles)}
                    >
                      <b className="text-white">About us</b>{" "}
                    </Link>
                  </span>
                </li>

                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                    <Link
                      to="/contact/"
                      className="mycategories"
                      onClick={() => setToggles(!toggles)}
                    >
                      <b className="text-white">Contact us</b>{" "}
                    </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                    <Link
                      to="/blogs-listing/"
                      className="mycategories"
                      onClick={() => setToggles(!toggles)}
                    >
                      <b className="text-white">Blogs</b>
                    </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                    <Link
                      to="/ads-listing/"
                      className="mycategories"
                      onClick={() => setToggles(!toggles)}
                    >
                      <b className="text-white">All Ads </b>
                    </Link>
                  </span>
                </li>
                <li className="nav-item">
                  {localStorage.getItem("access_token") !== null ? (
                    <Link
                      className="my mx-2 text-white"
                      style={{ marginTop: "-10px" }}
                      to="/allcatforpost/"
                      onClick={() => setToggles(!toggles)}
                    >
                      <b className="text-white">Create Ads</b>
                    </Link>
                  ) : (
                    ""
                  )}
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                    <Link
                      to="/dashboard/wishlist/"
                      className="mycategories"
                      onClick={() => setToggles(!toggles)}
                    >
                      <b className="text-white">Mycart</b>
                    </Link>
                  </span>
                </li>

                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("RealEstate");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"RealEstate"}`}
                      >
                        <b className="text-white">RealEstate</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Furniture");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Furniture"}`}
                      >
                        <b className="text-white">Furniture</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Electronics");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Electronics"}`}
                      >
                        <b className="text-white">Electronics</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Mobiles");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Mobiles"}`}
                      >
                        <b className="text-white">Mobiles</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Pets");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Pets"}`}
                      >
                        <b className="text-white">Pets</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Cars");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Cars"}`}
                      >
                        <b className="text-white">Cars</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Jobs");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Jobs"}`}
                      >
                        <b className="text-white">Jobs</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Bikes");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Bikes"}`}
                      >
                        <b className="text-white">Bikes</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Services");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Services"}`}
                      >
                        <b className="text-white">Services</b>
                      </Link>
                  </span>
                </li>
                <li className="nav-item mx-2">
                  <span className="nav-link text-truncate">
                  <Link
                        onClick={() => {
                          handleClick("Education");
                          setToggles(!toggles);
                        }}
                        to={`/ads-listing/category/${"Education"}`}
                      >
                        <b className="text-white">Education</b>
                      </Link>
                  </span>
                </li>
             
              </div>
            </ul>
          </div>
        </div>
      ) : null}
      {/* <div className="container-fluid"> */}

      <div style={{ position: "absolute", left: "0", marginLeft: "-10px" }}>
        {/* <div className="col min-vh-10 p-4"> */}
        {/* <div style={{ position: 'absolute', right: '0', marginRight: '5px' }}> */}

        {/* toggler */}
        {/* <button className="btn float-end" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvas" role="button"> */}
        {!toggles ? (
          <button data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
            <BsList style={{ fontSize: "30px" }} />
            <i
              className="bi bi-arrow-right-square-fill fs-3"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas"
            />
          </button>
        ) : (
          <button
            data-bs-toggle="offcanvas"
            onClick={() => setToggles(!toggles)}
            data-bs-target="#offcanvas"
          >
            <BsList style={{ fontSize: "30px" }} />
            <i
              className="bi bi-arrow-right-square-fill fs-3"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas"
            />
          </button>
        )}
      </div>
    </>
  );
};

export default Sidebar;
