import React, { useState, useEffect } from 'react';
import "./Adminverify.css"
import { Link } from 'react-router-dom';
import Modal from './Popextra';
import { LocationCity } from '@mui/icons-material';
import { FaCheck, FaTimes } from 'react-icons/fa';
import YoutubeMagic from '../ContentLoader/YoutubeMagic';
const Bussinessadminverify = () => {
  const [businessData, setBusinessData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [itemsToLoad, setItemsToLoad] = useState(25);
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
 
  useEffect(() => {
    // Fetch data from the API
    fetch(`https://databytess.com/api/adsapi/?limit=${itemsToLoad}`)
      .then(response => response.json())
      .then(data => {
        setBusinessData(data);
        setIsLoading(false); // Set loading to false once data is fetched
        setIsLoading1(false); // Set loading to false once data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false in case of an error
        setIsLoading1(false); // Set loading to false in case of an error
      });
  }, [itemsToLoad]);
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 25);
    setLimitIncreaseCount((prevCount) => prevCount + 1);

  };
  const [prevDataLength, setPrevDataLength] = useState(0);
  const [cardCount, setCardCount] = useState(20);

  const loadMoreCards = () => {
    // Increment the card count by 30.
    setCardCount(cardCount + 20);
      setLimitIncreaseCount(0);

  };
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading && limitIncreaseCount != 3) {
        const newDataLength = businessData.length;
  
        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, businessData, prevDataLength,limitIncreaseCount]);

  return (
    <>
    <div>
      {isLoading ? (
        <p><YoutubeMagic/></p>
      ) : (
        businessData.slice(0,cardCount).map((data) => (
          <>
          <BusinessProfileItem key={data.id} data={data} load={isLoading1} />
          </>
        ))
      )}

    </div>
    {cardCount > businessData.length && (
      <>
      {isLoading1 && <p className="loading-message"> <YoutubeMagic/> </p>}
      </>
      )}
      {cardCount < businessData.length && (
                    <div
                      className="loadbt text-center"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      <button className="loadmore" onClick={loadMoreCards}>
                        Load More
                      </button>
                    </div>
                  )}
</>
  );
};

const BusinessProfileItem = ({ data }) => {
  const [isVerified, setIsVerified] = useState(data.is_active);
  const [isVerifying, setIsVerifying] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showMore1, setShowMore1] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const handleUpdateVerification = async () => {
    try {
      const response = await fetch(`https://databytess.com/api/adsapi/productdetail/${data.id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_active: !isVerified }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setIsVerified(!isVerified);
      console.log(`is_verified updated to: ${!isVerified}`);
    } catch (error) {
      console.error('Error updating verification:', error.message);
    }finally {
        setIsVerifying(false); // Set loading to false after the request completes
      }
  };
  const handleDelete = async () => {
    const userConfirmed = window.confirm('Are you sure you want to delete this data?');

    if (!userConfirmed) {
      return;
    }
    try {
      const response = await fetch(`https://databytess.com/api/adsapi/productdetail/${data.id}/`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Notify the parent component to update its state (e.g., refetch data)
setIsDeleted(true);
     
      console.log(`Data with ID ${data.id} deleted`);
    } catch (error) {
      console.error('Error deleting data:', error.message);
    }
      finally {
        setIsDeleting(false); // Set loading to false after the request completes
      }
  };
  const handleCloseModal = () => {
    setShowMore(false);
  };
  const handleLoadMore = () => {
    setShowMore(true);
  }
  const handleCloseModal1 = () => {
    setShowMore1(false);
  };
  const handleLoadMore1 = () => {
    setShowMore1(true);
  }
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };
  return (
    <div className="ads-total-data-container" style={{marginTop:"80px"}}>
    <table className={`ads-table ${isDeleted ? 'deleted' : ''}`} key={data.id} style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <thead style={{ backgroundColor: '#f2f2f2' }}>
        <tr>
          <th>No</th>
          <th>Title</th>
          <th>Price</th>
          <th>Date</th>
          <th>City</th>
          <th>Images</th>
          <th>Extra Field</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ borderBottom: '1px solid #ddd' }}>
          <td style={{ color: 'black', padding: '10px' }}>{data.id}</td>
          <td style={{ color: 'black', padding: '10px' }}>{data.title} <br/>{data.user_type} <br/>{data.category}  <br/>      
          <button onClick={handleLoadMore1} style={{ backgroundColor: 'transparent', border: 'none', color: 'blue', cursor: 'pointer' }}>View Details</button>
</td>
          <td style={{ color: 'black', padding: '10px' }}>{data.price}</td>
          <td style={{ color: 'black', padding: '10px' }}>{data.date_created}</td>
          <td style={{ color: 'black', padding: '10px' }}><LocationCity/> {data.City}<br/>{data.state}<br/>{data.locality}<br/>{data.zip_code}</td>
          <td style={{ color: 'black', padding: '10px' }}>
            <img src={data.image} alt={`Product ${data.id}`} className="product-image" style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '5px' }} />
            <Link to={`/verifyimg/${data.id}`} target="_blank" style={{ textDecoration: 'none', color: 'blue' }}>View More</Link>
          </td>
          <td style={{ color: 'black', padding: '10px' }}>
            {data?.extraField &&
              Object?.keys(
                JSON?.parse(data.extraField) || {}
              ).slice(0, 3).map((key) => (
                <p key={key} style={{ color: 'navy', marginBottom: '5px' }}>
                  {key} :{' '}
                  <span style={{ color: 'red' }}>
                    {(JSON?.parse(data.extraField))[key]}
                  </span>
                </p>
              ))}
            <button onClick={handleLoadMore} style={{ backgroundColor: 'transparent', border: 'none', color: 'blue', cursor: 'pointer' }}>View Details</button>
          </td>
          <td>
          {isVerifying ? (
          <p style={{color:"navy",fontWeight:"bold"}}>Verifying...</p>
        ) : (
          <button onClick={handleUpdateVerification} style={{ backgroundColor: isVerified ? 'green' : 'red', color: 'white', padding: '8px', borderRadius: '5px', cursor: 'pointer' }}>
            {isVerified ? <> <FaCheck /> verified</> : <><FaTimes /> Not verified</>}
          </button>
        )}
        {isDeleting ? (
          <p style={{color:"red",fontWeight:"bold"}}>Deleting...</p>
        ) : (
          <button onClick={handleDelete} style={{ marginLeft: '10px', background: 'red', color: 'white', padding: '8px', borderRadius: '5px', cursor: 'pointer' }}>
            Delete
          </button>
        )}
          </td>
        </tr>
      </tbody>
    </table>
    {showMore && (
        <Modal onClose={handleCloseModal}>
          {/* Render the data inside the modal */}
          {data?.extraField &&
                            Object?.keys(
                              JSON?.parse(data.extraField) || {}
                            ).map((key) => {
                              return (
                                  <p style={{color:"navy"}}>
                                    {key} :{" "}
                                    <span style={{color:"green"}}>
                                      {(JSON?.parse(data.extraField))[key]}
                                    </span>
                                  </p>
                              );
                            })}
        </Modal>
      )}
    {showMore1 && (
        <Modal onClose={handleCloseModal1}>
          {/* Render the data inside the modal */}
          <div style={{ padding: '15px', border: '1px solid #ddd', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
      {data.subCategoryType != "null" ?(<div style={{ color: 'navy', marginBottom: '10px' }}>
        Type: {data.subCategoryType}
      </div>):null}
      <div style={{ color: 'navy', marginBottom: '10px' }}>
        Subcategory: {data.subCategoryValue}
      </div>

      <div style={{ marginBottom: '10px' }}>
        <strong>Description:</strong>
        <br />
        <span style={{color:"black",fontWeight:"bold"}}>{data.description}</span>
      </div>

      <div style={{ marginBottom: '10px' }}>
        Date:<span style={{color:"green"}}> {data.date_created}</span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        Phonenumber:<span style={{color:"green"}}> {data.phoneNumber}</span>
      </div>

      <div style={{ marginBottom: '10px' }}>
        Plan:<span style={{color:"red"}}> {data.plan}</span>
      </div>
    </div>
        </Modal>
      )}
  </div>
  );
};

export default Bussinessadminverify;
