import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import "./AddComment.css";
import { decrypt } from "../Base/encryptDecrypt/encryptDecrypt";
import { url } from "../env";
import { Alert, Space, Spin } from "antd";
import { faBullseye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { BsStarFill } from 'react-icons/bs';
import { isMobile } from "react-device-detect";

const mobRating ={
  marginLeft:"5%",
  marginTop:"12%"
}
const deskRating ={
  marginLeft:"67%",
  marginTop:"15px",
}
const AddComment = (props) => {
  const [adsComment, setadsComment] = useState({
    user: "",
    ads: "",
    date: "",
    comment: "",
  });
  const localmail = localStorage.getItem("email");

  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loginFlag, setloginFlag] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [comments, setComment] = useState([]);
  const [commentChange, setCommentChange] = useState(1);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);

    var formdata = new FormData();
    formdata.append("ads", props.id.id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/adsapi/adsCommentBox", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        setComment(result);
      })
      .catch((error) => console.log("error", error));
  }, [commentChange]);
  const names = comments.map((item) => item.fields.pk);
  const handleSubmit = (e) => {
    setLoader(true);

    e.preventDefault();
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
      setloginFlag(true);
      setError("please login first ");
    } else {
      var formdata = new FormData();
      formdata.append("ads", props.id.id);
      formdata.append("email", localmail);
      formdata.append("message", messageValue);
      formdata.append("rating", formData.rating);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(url + "api/adsapi/adsCommentBox", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLoader(false);

          // finalComment()
          setMessageValue(null);
        })
        .catch((error) => console.log("error", error));
      setTimeout(() => {
        setCommentChange(commentChange + 1);
      }, 200);
    }
    setMessageValue("");
  };
  const [visibleComments, setVisibleComments] = useState(1); // Initial visible comments

  // ... other code ...

  const handleSeeMoreClick = () => {
    // Increase the visible comment count to show all comments
    setVisibleComments(comments.length);
  };
  const handleDeleteComment = (commentId) => {
    console.log("Deleting comment with id:", commentId);

    setLoader(true);

    const localmail = localStorage.getItem("email");

    // Create FormData and append necessary values
    const formData = new FormData();
    formData.append("email", localmail);
    formData.append("ads", props.id.id);
    formData.append("id", commentId);

    axios
      .delete(url + "api/adsapi/adsCommentBox", {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data", // Make sure to set the correct content type
        },
      })
      .then((response) => {
        setLoader(false);

        // Update the state to reflect the deleted comment
        const updatedComments = comments.filter(
          (comment) => comment.pk !== commentId
        );
        setComment(updatedComments);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error deleting comment:", error);
      });
  };

  document.title = "Hola9 - ContactUs";

  // star veview code start
  const [formData, setFormData] = useState({ 
    rating: null,
  });

  const handleRatingChange = (rating) => {
    setFormData({ ...formData, rating });
  };

  const renderStarRating = () => {
    const stars = [];
    const emojis = ["😢", "😟", "😐", "😊", "😄"]; // Add corresponding emojis
    // const ratingTexts = ["Poor", "Fair", "Average", "Good", "Excellent"];

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <label key={i}>
          <input
            type="radio"
            name="rating"
            value={i}
            checked={formData.rating === i}
            onChange={() => handleRatingChange(i)}
            required
          />
          <BsStarFill
            className="star"
            color={i <= formData.rating ? 'gold' : 'gray'}
            size={25}
          />
        </label>
      );
    }

    // Add a div to display the selected emoji
    stars.push(
      <div key="emoji" className="emoji-container" style={{marginTop:"0px", fontSize:"25px"}}>
        {formData.rating !== null && <span>{emojis[formData.rating - 1]}</span>}
        {/* {formData.rating !== null && <span>{ratingTexts[formData.rating - 1]}</span>} */}
      </div>
    );

    return stars;
  };

  // star veview code end
  return (
    <>
      <main id="main" className="site-main contact-main">
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Spin tip="Loading..." spinning={loader}>
            <div className=" mt-4">
              <div className="d-flex paddingleft row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="d-flex flex-column comment-section">
                    {comments.slice(0, visibleComments).map((result, index) => {
                      return (
                        <div className="bg-white p-2">
                          <div className="d-flex flex-row user-info">
                            <img
                              className="rounded-circle"
                              src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
                              width={45}
                            />
                            <div className="d-flex flex-column justify-content-start ml-2">
                              <span className="d-block font-weight-bold name">
                                {result.fields.email}
                              </span>
                              <span className="date text-black-50"
                                style={{ marginTop: '-5px' }}>
                                {result.fields.datetimeValue}
                              </span>
                            </div>
                          </div>
                          <div className="mt-2">
                            <p className="comment-text mx-5" style={{ marginTop: '-15px' }}>
                              {result.fields.message} &nbsp;
                              {localmail === result.fields.email && (
                                <Delete
                                  style={{ color: "red", cursor: "pointer", fontSize: '13px' }}
                                  onClick={() => handleDeleteComment(result.pk)}
                                />
                              )}
                            </p>
                          </div>
                          <div className="text-right"></div>
                        </div>
                      );
                    })}
                    {comments.length > visibleComments && (
                      <div
                        style={{ color: "blue", float: "right" }}
                        className="text-center"
                      >
                        <p
                          onClick={handleSeeMoreClick}
                          style={{ color: "navy", cursor: "pointer" }}
                        >
                          Read More
                        </p>
                      </div>
                    )}
                    <div
                      style={{
                        borderRadius: "20px",
                      }}
                    // className="bg-light p-2"
                    >
                      <div className="d-flex flex-column align-items-start position-relative">
                        {/* <img
                          className="rounded-circle"
                          src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
                          width={40}
                          alt="round"
                        /> */}
                        <textarea
                          // style={{
                          //   borderRadius:"20px"
                          //  }}
                          className="form-control ml-1 shadow-none textarea"
                          placeholder="Post Your Comment ...."
                          // defaultValue={messageValue}
                          value={messageValue}
                          onChange={(e) => {
                            setMessageValue(e.target.value);
                          }}
                        />
                        <h6 htmlFor="rating" className='d-flex fw-bold position-absolute left-0' style={isMobile ? mobRating : deskRating}>{renderStarRating()}</h6>

                      </div>
                      <div className="mt-2 text-right">
                        <button
                          style={{
                            // borderRadius: "20px",
                            border: 'none',
                            position: 'relative', top: '-86.5px'
                          }}
                          className="btn-primary btn-sm shadow-none btn-cmnt-pst"
                          type="button"
                          onClick={handleSubmit}
                          disabled={messageValue == "" || messageValue == null}
                        >
                          Post comment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </Space>
      </main>
    </>
  );
};

export default AddComment;
