import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { db } from "../../../../../firebase";
import Message from "./Message";
import { AuthContext } from "../context/AuthContext";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const { data } = useContext(ChatContext);
  const messagesRef = useRef(null);
  const { currentUser } = useContext(AuthContext);

  const readMessage = async () => {
    const messageRef = doc(db, "chats", data?.chatId);
    console.log(data?.chatId, "*** useruuu");

    try {
      let result = await getDoc(messageRef);
      const chats = result.data();
      let messagesArray = chats?.messages;

      messagesArray.map((item) => {
        item["readBy"][currentUser?.uid] = true;
        console.log(item, "*** item");
      });
      await updateDoc(doc(db, "chats", data?.chatId), {
        messages: messagesArray,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    readMessage();
  }, [data]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unSub();
    };
  }, [data.chatId]);

  return (
    <div className="messages" ref={messagesRef}>
      {messages.map((m) => (
        <Message message={m} key={m.id} chatId={data?.chatId} />
      ))}
    </div>
  );
};

export default Messages;
