import { useDispatch } from "react-redux";
import { Col, Row, Tooltip, Button, Modal } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import {
  url,
} from "../../env";
import { useInterval } from "react-interval-hook";
import { MDBCol } from "mdbreact";
import { isMobile } from "react-device-detect";
import Spiner from "../../Spiner";
import { getFormLabelUtilityClasses, SliderValueLabel } from "@mui/material";
import SweetPagination from "sweetpagination";
// import Distance from "../../distance";
import DynamicFont from "react-dynamic-font";
import {
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper,
  BsFillCartPlusFill,
  BsHeart, BsHeartFill, BsCoin, BsFillPinMapFill, BsFillBookmarkFill
} from "react-icons/bs";
import { remove, add1 } from "../../../store/wishlistSlice";
import { add } from "../../../store/Track/trackUserSlice";
import { useSelector } from "react-redux";
import { removeSearchData } from "../../../store/SearchCategory/searchCategorySlice";
import { FilterBy } from "../../../FilterBy";
import {
  getExtraField,
  removeFiled,
  removeKeyPair,
} from "../../../store/ProductFilterSlice";
import { filterData } from "../../../store/getDataFeature";

import { UserContext } from "../../../App";
import {
  newProductValue,
  productValueRemove,
  productValueValue,
} from "../../../store/showProductsliceValue";

import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";

import Distance from "../../distance";
import NoDataFound from "../../datanotfound/NoDataFound";
import { searchApi } from "../../../store/ToggleSearchSlice";
// import Search from "antd/lib/input/Search";

// import { searchValue } from "../../../store/seachAdsArray";
import { Input, Space } from "antd";
import zIndex from "@mui/material/styles/zIndex";
import YoutubeMagic from "../../ContentLoader/YoutubeMagic";
import { AiFillDelete } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";

import verified from "../Allads/verified.jpg";
const mobistyle = {
  width: "auto",
  marginLeft: "10px",
  marginRight: "10px",
  background: "transparent"
}
const deskistyle = {
  width: "14rem",
  background: "gray",
  //  borderRadius: "2px",
  border: "1px solid gray",
}
const mobist = {
  width: "auto",
  marginLeft: "16px",
  marginRight: "16px"

}
const deskist = {
  // background: "gray",
  //  borderRadius: "2px",
  //  border: "1px solid gray",
  marginLeft: "11.5rem", marginRight: "11.5rem", marginTop: "34px",
}
const mobilestyle = {
  height: "140px",
  width: "350px",
  borderRadius: "20px",
}
const desktopstyle = {
  height: "165px",
  width: "350px",
}
// const JsonSearch = require("search-array").default;

const TrendingAds = (props) => {
  const adsData = useContext(UserContext);
  const [category, setCateory] = useState(null);
  const [isloading, setIsLoading] = useState(true);
  const [currentPageData, setCurrentPageData] = useState([]);
  useEffect(() => {
    if (props?.value) {
      setCateory(props?.value);
    }
  }, []);
  const [products, setProduct] = useState([])
  const [loading, setLoading] = useState(true);
  const [lockInterval, setLockInterval] = useState(true);
  const [interval, setInterval] = useState({ start: 1, end: 10 });
  const wishlistData = useSelector((state) => state.wishlistSlice);
  const [wishlistID] = ["wishlistId"].map(
    document.getElementById.bind(document)
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add({ value: value }));
    }

    // wishlistID.addEventListener('click', e => e.stopPropagation());
  };

 
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://databytess.com/api/adsapi/trendingads", {
          method: "POST",
          body: JSON.stringify({}),
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once after mount
  const [showBan, setShowBan] = useState(true);
  const handleBanClose = () => {
    setShowBan(false);
  };
  const [cardCount, setCardCount] = useState(12);
  const loadMoreCards = () => {
    // Increment the card count by 30.
    setCardCount(cardCount + 12);
  };

  if (loading) {
    return <YoutubeMagic/>;
  }


  return (
    <>
      <h3 style={{ textAlign: "center", fontFamily: "fantasy" }}>TrendingAds</h3>
      <>
        {products.length ? (
          <section className="inner-section ad-list-part mb-2" id="scroller" style={isMobile ? mobist : deskist}>
            <div className="mx-2">
              <div className="row content-reverse">
                <div className="col-lg-12 col-md-12 col-xl-12">

                  {/* isloading ? <YoutubeMagic1/>: */}
                  <div
                    className="row"
                  >
                    {/* {error && <div className="alert alert-warning">{error}</div>} */}
                    {/* {loading && <Spiner />} */}
                    {/* currentPageData?.length > 0 ? (  insted of product.is_active line*/}
                    <div className="ads-container">
                      <div className="card-grid">
                        {products
                          .slice(0, cardCount)
                          .sort((a, b) => b.viewsproduct - a.viewsproduct)
                          .map((ad, index) =>
                             (

                              <div key={ad.pk} className="ad-card" onClick={() => navigate(`/ads-listing/${ad?.pk === undefined ? ad?.pk : ad.pk
                                }/`)}>
                                <div className="glassmorphism">
                                  <div>
                                    <div className="ad-image">
                                      <div className="wtrmrk">Hola9.com</div>
                                      <img className="imghover-scale"
                                        src={
                                          !ad?.fields.image
                                            ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                            : ad.fields.image
                                        }
                                        alt="ad"
                                        onError={(e) => {
                                          e.target.src =
                                            "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                                          e.onerror = null;
                                        }}
                                      />
                                    </div>
                                    <div className="product-type">
                                    <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                      <span className="flat-badge booking" >
                                        <b>
                                          <span
                                            className="flat-badge booking "
                                            style={{
                                              background:
                                                ad?.fields?.plan === "Silver"
                                                  ? "#cce6ff"
                                                  : ad?.fields?.plan === "Gold"
                                                    ? "gold"
                                                    : ad?.fields?.plan === "Platinum"
                                                      ? "green"
                                                      : ad?.fields?.plan === "Premium"
                                                        ? "linear-gradient(to right, #800080, #8A2BE2)"
                                                        : ad?.fields?.plan === "Featured"
                                                          ? "linear-gradient(to right, #090979, #00d4ff)"
                                                          // : ad?.plan === "Free"
                                                          // ? "green"
                                                          : "transparent",
                                              color:
                                                ad?.fields?.plan === "Silver"
                                                  ? "blue"
                                                  : ad?.fields?.plan === "Gold"
                                                    ? "black"
                                                    : ad?.fields?.plan === "Platinum"
                                                      ? "white"
                                                      : ad?.fields?.plan === "Premium"
                                                        ? "white"
                                                        : ad?.fields?.plan === "Featured"
                                                          ? "white"
                                                          // : ad?.plan === "Free"
                                                          // ? "white"
                                                          : "transparent",
                                              padding: "5px",

                                              // backgroundColor: "white",
                                              // float:"left",
                                              // border: "2px solid black",
                                            }}
                                          >
                                            {ad?.fields?.plan}
                                          </span>
                                        </b>
                                      </span>
                                      <img
                                      src={verified}
                                      width={22}
                                      height={22}
                                      style={{
                                        marginRight: "-10px",
                                        borderRadius: "50%",
                                      }} />
                                </div>

                                    </div>
                                    <ul className="viewsub">
                                      <li className="view">
                                        <i
                                          className="fas fa-eye"
                                          style={{ color: "white" }}
                                        />
                                        <span style={{ color: "white" }}>
                                          {ad?.fields?.viewsproduct}
                                        </span>
                                      </li>
                                      {/* <li className="click">
                                              <i className="fas fa-mouse" />
                                              <span>134</span>
                                            </li> */}
                                      <li className="rating">
                                        <i className="" style={{ color: "white" }} />
                                        <span style={{ color: "white" }}>
                                          <BsNewspaper />
                                          &nbsp;  {ad?.fields?.subCategoryValue?.slice(0, 12) + "..."}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="ad-details  m-0 p-0">
                                    {/* DESKTOP VIEW */}
                                    <p className="titlefont hidden-sm hidden-xs  m-0 p-0">
                                      <Tooltip
                                        placement="topLeft"
                                        title={ad?.fields?.title}
                                      >
                                        {ad?.fields?.title?.length > 17 ? (
                                          <p className="titlefont">
                                            {ad?.fields?.title?.slice(0, 17)}
                                            {ad?.fields?.title?.length > 17
                                              ? "..."
                                              : null}
                                          </p>
                                        ) : (
                                          <div>
                                            <p className="titlefont">
                                              <DynamicFont
                                                content={ad?.fields?.title}
                                              />
                                            </p>
                                          </div>
                                        )}
                                      </Tooltip>
                                    </p>
                                    {/* DESKTOP VIEW */}

                                    {/* MOBILE VIEW */}
                                    <p className="titlefont d-lg-none">
                                      <Tooltip
                                        placement="topLeft"
                                        title={ad?.fields?.title}
                                      >
                                        {ad?.fields?.title?.length > 12 ? (
                                          <p className="titlefont">
                                            {ad?.fields?.title?.slice(0, 12)}
                                            {ad?.fields?.title?.length > 12
                                              ? "..."
                                              : null}
                                          </p>
                                        ) : (
                                          <div style={{}}>
                                            <p className="titlefont">
                                              <DynamicFont
                                                content={ad?.title}
                                              />
                                            </p>
                                          </div>
                                        )}
                                      </Tooltip>
                                    </p>
                                    {/* MOBILE VIEW */}
                                    <div
                                      className="condiv justify-content-between "
                                      style={{ marginTop: "-15px", alignItems:'center', marginBottom:'6px' }}
                                    >
                                      <div className="widhdiv d-flex justify-content-between m-0 p-0  mob-v-space">
                                        <p
                                          className="fs-10 m-0 p-0 mt-1"
                                          style={{
                                            color: "#062544",
                                            fontSize: "90%",
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          <BsGeoAltFill
                                            className="mr-1 "
                                            style={{ color: "red" }}
                                          />
                                          <b>{ad?.fields?.City}</b>
                                        </p>
                                        <BsFillBookmarkFill
                                          id="wishlibyn"
                                          style={{
                                            fontSize: "20px",
                                            marginTop: "6px",
                                          }}
                                          className={
                                            wishlistData?.data?.indexOf(ad.pk) !==
                                              -1
                                              ? "removemob"
                                              : "heartmob"
                                          }
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            addingWishlist(ad.pk);
                                          }}
                                        />
                                      </div>
                                      <div className="mob-v-space">
                                      {ad?.fields?.category == "RealEstate" ? (
                                        <p
                                          className="conpir fs-14"
                                          style={{
                                            color: "gray",
                                            fontSize: "90%",
                                            fontFamily: "sans-serif",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <BsBuilding
                                            className="mr-2"
                                            style={{
                                              color: "green",
                                              fontSize: "90%",
                                            }}
                                          />
                                          {ad.fields?.subCategoryType?.slice(0, 9)}
                                        </p>
                                      ) : (
                                        <p
                                          className="conpir fs-14"
                                          style={{

                                            fontSize: "90%",
                                            fontFamily: "sans-serif",

                                          }}
                                        >
                                          <BsFillCheckSquareFill
                                            className="mr-1"
                                            style={{
                                              color: "green",
                                              fontSize: "90%",
                                            }}
                                          />
                                          {ad?.fields?.condition?.slice(0, 9)}
                                        </p>
                                      )}
                                         <span className="mob-star">
                                <i className="fa fa-star" style={{color:'gold'}}> <span style={{color:'gray'}}>4.5</span></i>
                              </span>
                                </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'15px'}}>
                                    {ad?.fields?.category != "Jobs" ?
                                (
                                <p
                                  className="ad-price mr-1"
                                  style={{
                                    fontSize: "90%",
                                    fontFamily: "verdana",
                                    marginTop: "-15px",
                                  }}
                                >
                                  &nbsp;₹ <b>{ad?.fields?.price}</b>
                                </p>):(<>
                                  {ad?.fields?.extraField && (
 <p  className="ad-price mr-1"
 style={{
   fontSize: "90%",
   fontFamily: "verdana",
   marginTop: "-15px",
 }}>

&nbsp;<GiMoneyStack /><b>{(JSON?.parse(ad?.fields?.extraField)["MaxSalery"])}</b>
</p>
)}
                                </>)}
                                <span className="desk-star">
                                <i className="fa fa-star" style={{color:'gold'}}> <span style={{color:'gray'}}>4.5</span></i>
                              </span>
                              </div>
                                    <button
                                      id="wishbbt"
                                      style={{ marginTop: "-15px" }}
                                      className={
                                        wishlistData?.data?.indexOf(ad.pk) !== -1
                                          ? "remove-button"
                                          : "wishlist-button"
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        addingWishlist(ad.pk);
                                      }}
                                    >
                                      {wishlistData?.data?.indexOf(ad.pk) !== -1 ? (
                                        <>
                                          <AiFillDelete />
                                          &nbsp;&nbsp;Remove Wishlist
                                        </>
                                      ) : (
                                        <>
                                          <BsFillBookmarkFill />
                                          &nbsp;&nbsp;Add To Wishlist
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) 
                          )}
                      </div></div>
                    {cardCount < products.length>=12 && (
                      <div className="loadbt text-center" style={{ textAlign: "center" }}>
                        <button className="loadmore" onClick={loadMoreCards}>
                          Load More
                        </button>
                      </div>
                    )}

                  </div>

                </div>
              </div>
            </div>
          </section>) : <h3>Not found data</h3>}
        {/* {isloading ? <YoutubeMagic /> : null} */}
      </>
      <div style={{ display: "none" }}>
      </div>
    </>
  );
};
export default TrendingAds;
