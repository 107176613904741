import React, { useEffect } from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import Cwe from "./Cwe";
import Owldemo2 from "../../Owldemo2";
import { isMobile } from "react-device-detect";





const mobuse = {
  marginLeft: "5px", marginRight: "5px"
}
const deskuse = {
  marginLeft: "11.5rem", marginRight: "11.5rem"
}





 // allAds geetings start
 const greeting = "RealEstate";
 const greeting1 = "Furniture";
 const greeting2 = "Electronics";
 const greeting3 = "Cars";
 const greeting4 = "Bikes";
 const greeting5 = "Pets";
 const greeting6 = "Mobiles";
 const greeting7 = "Services";
 const greeting8 = "Education";
 const greeting9 = "Jobs";
 //  allAds geetings end

const Contact = () => {
  // Set the document title
  document.title = "Hola9 - Contact Us";

  return (
    <>
      <main id="main" className="site-main contact-main">
        <div
          className="container-fluid bgcolor"
          style={{ display: "flex", justifyContent: "center", height: "90px" }}
        >
          {/* Render the title */}
          <p style={{ color: "white", paddingTop: "10px", fontSize: "30px" }}>
            Contact Us
          </p>{" "}
          <br></br>
        </div>
        {/* .page-title */}
        <div>
          <div
            className="container"
            style={{
              background: "#fcffff",
              marginTop: "30px",
              marginBottom: "20px",
              boxShadow: "0 0 5px 2px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <div
                  className="container mb-4 mt-1 ml-3"
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    marginRight: "25px",
                  }}
                >
                  {/* Render the section title */}
                  <h2
                    style={{
                      textAlign: "center",
                      fontSize: "32px",
                      marginTop: "-10px",
                      marginBottom: "30px",
                      color: "#010526",
                    }}
                  >
                    Get In Touch With Us
                  </h2>
                  {/* Render the contact form */}
                  <Cwe />
                </div>
              </div>

              <div className="col-md-6">
                <div className="contact-text d-grid justify-content-center">
                  {/* Render the office address section title */}
                  <span
                    style={{
                      fontSize: "32px",
                      marginTop: "-30px",
                      marginBottom: "30px",
                      color: "#08103b",
                    }}
                  >
                    Our Office Address
                  </span>
                  <div className="contact-box">
                    {/* Render the office address */}
                    <h4 style={{ color: "#545252" }}>
                      Bengaluru (Head Quaters)
                    </h4>
                    <p style={{ color: "#545252" }}>
                      {/* Render the office address details */}
                      #12 Second Floor, 3rd Cross
                      <br />
                      Patel Narayana Reddy Layout
                      <br />
                      6th Block Koramangala
                      <br />
                      Bengaluru - 560095
                    </p>
                    {/* Render the "Get Direction" link */}
                    <a
                      href="https://goo.gl/maps/CbhJY8tjNq8WxS627"
                      className="anchor"
                    >
                      <span>Get Direction</span>
                    </a>
                    {/* <Link to="/" title="Get Direction">
                      Get Direction
                    </Link> */}
                  </div>
                </div>
              </div>




            </div>
          </div>
        </div>
        {/* .site-content */}
      </main>

      <div>
{/* <div className="row" style={isMobile ? mobuse : deskuse}>
        <u><p style={{
          fontSize: "38px",
          fontWeight: "bold",
          marginBottom: "10px",
          color: "#007BFF",
          textTransform: "capitalize",
          letterSpacing: "1px",
          textAlign: "center",
          background: "linear-gradient(to right, #007BFF, #00BFFF)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4))",
          transition: "transform 0.3s ease-in-out",
          cursor: "pointer",
        }}>
          Categorie's
        </p>
        </u>
        <Owldemo2 greeting={greeting} />
        <Owldemo2 greeting={greeting1} />
        <Owldemo2 greeting={greeting2} />
        <Owldemo2 greeting={greeting3} />
        <Owldemo2 greeting={greeting4} />
        <Owldemo2 greeting={greeting5} />
        <Owldemo2 greeting={greeting6} />
        <Owldemo2 greeting={greeting7} />
        <Owldemo2 greeting={greeting8} />
        <Owldemo2 greeting={greeting9} />
      </div> */}
</div>
      

      {/* Render the map section */}
      <div style={{ marginBottom: "500px" }}>
        <h3 style={{ fontSize: "25px", paddingLeft: "30px" }}>
          Location &amp; Maps
        </h3>
        {/* Render the Google Maps iframe */}
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1944.2552970218364!2d77.6254499578763!3d12.939146011824848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15aa83004911%3A0xf04e6ab0127d1810!2sHOLA9%20CLASSIFIEDS%20INDIA%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1672904395635!5m2!1sen!2sin"
          width={1310}
          height={450}
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style={{
            border: 0,
            paddingTop: "6px",
            paddingLeft: "2px",
            paddingRight: "0.5px",
          }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />


        
      </div>
    </>
  );
};

export default Contact;
