import React, { useEffect, useState, useRef } from "react";
// import Search from '../../Base/Search/search';
import Searchnew from "../../Base/Search/Searchnew";
import "./homeSection.css";
import { subCategoryInput } from "../../../Model/SubCategoryCons";
import { Link } from "react-router-dom";
import any2 from "../../images/packer.webp";
import any3 from "../../images/interior.webp";
import any4 from "../../images/ragent.jpeg";
import any1 from "../../images/pet.jpg";
import any5 from "../../images/mobilesc.jpg";
import any6 from "../../images/caragent.png";
import any7 from "../../images/bikew.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronDown } from "react-icons/bs";
import City from "../../Ads/category/City";
import SearchForm from "../../Base/Search/SearchForm";
import { getExtraField } from "../../../store/ProductFilterSlice";
import { useDispatch } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import {
  allSubcategory,
  Bikes,
  Pets,
  Category,
  Electronics,
  subcategoryRealEstateBuy,
  Furniture,
  subcategoryType1,
  Services,
  Mobiles,
  subcategoryRealEstateRent,
  Cars,
  Education,
  Jobs,
} from "../../env.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
const HomeSection = () => {
  const dispatch = useDispatch();
  const handleClick = (category, subCategoryType, subCategoryValue) => {
    const obj = {
      category,
      ...(subCategoryType !== undefined ? { subCategoryType } : {}),
      ...(subCategoryValue !== undefined ? { subCategoryValue } : {}),
      extraField: {},
    };
    dispatch(getExtraField(obj));
  };

  const handleClick1 = (subCategoryInput) => {
    const obj1 = { subCategoryInput, extraField: {} };
    dispatch(getExtraField(obj1));
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleHover = () => {
    setIsDropdownVisible(true);
  };

  const handleLeave = () => {
    setIsDropdownVisible(false);
  };

  const handleContentHover = () => {
    setIsDropdownVisible(true);
  };

  const handleContentLeave = () => {
    setIsDropdownVisible(false);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const images = [any2, any4, any3, any7, any1, any5, any6]; // Replace with the appropriate image sources

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]);
  const slideRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const handleClickNext = () => {
    let items = slideRef.current.querySelectorAll(".item23");
    slideRef.current.appendChild(items[0]);
  };

  const handleClickPrev = () => {
    let items = slideRef.current.querySelectorAll(".item23");
    slideRef.current.prepend(items[items.length - 1]);
  };

  const mobilestylee = {
    display: "none",
  };
  const desktopstylee = {};

  const data = [
    {
      id: 1,
      // imgUrl:
      //   "https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?cs=srgb&dl=architecture-family-house-front-yard-106399.jpg&fm=jpg",
      imgUrl:"https://www.financialexpress.com/wp-content/uploads/2023/11/real-estate-bangalore-1.jpg",
      desc:
        "Explore Residential or Commercial or PG-Hostels in Your Nearby Areas ",
      name: "REAL ESTATE",
      linkTo: "/cat/RealEstate",
    },
    {
      id: 2,
      // imgUrl:
      //   "https://th.bing.com/th/id/R.b902096f54f203b8e5b617b6e3c41e16?rik=HBp%2b2iyOkTG%2b2w&riu=http%3a%2f%2fwww.pixelstalk.net%2fwp-content%2fuploads%2f2016%2f08%2fBest-Cars-Full-HD-Photos-1920x1080.jpg&ehk=%2fjLuRL0avCLGt9lgNqfWbojNBcWD3KIb2S1OLUBUNa4%3d&risl=&pid=ImgRaw&r=0",
    
        imgUrl:"https://cdn.jdpower.com/Average%20Weight%20Of%20A%20Car.jpg",

        desc:
        "Explore New and Used Certified Cars With Genuine Services All Over INDIA",
      name: "CARS",
      linkTo: "/cat/Cars",
    },
    {
      id: 3,
      // imgUrl:
      //   "https://images.pexels.com/photos/18203171/pexels-photo-18203171/free-photo-of-yamaha-yzf-r15-motorcycle-parked-on-the-seaside-promenade.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
     
        imgUrl: "https://bd.gaadicdn.com/processedimages/royal-enfield/roadstar/640X309/roadstar62f099c71e568.jpg",
     desc:
        "Explore New and Used Certified Bikes With Genuine Services All Over INDIA",
      name: "BIKES",
      linkTo: "/cat/Bikes",
    },
    {
      id: 4,
      // imgUrl: "https://images.pexels.com/photos/2061057/pexels-photo-2061057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
     
      imgUrl: "https://media.istockphoto.com/id/1497909628/photo/cat-and-dog-sitting-together-on-meadow.webp?b=1&s=170667a&w=0&k=20&c=zefJTTBpaVjbLPOAQSXqcET76UH4cG_nnLH0GVFpIDU=",
      desc:
        "Are You A Pet Lover..? Are You Searching For Any Kind Of Pets Click on See More",
      name: "PETS",
      linkTo: "/cat/Pets",
    },
    {
      id: 5,
      imgUrl:
        "https://images.pexels.com/photos/341523/pexels-photo-341523.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "Are You Looking For Mobiles Then Click on See More To Find Your Drem Bike",
      name: "MOBILES",
      linkTo: "/cat/Mobiles",
    },
    {
      id: 6,
      imgUrl:
        "https://uploads-ssl.webflow.com/5aaee34b7fb511129d1ffaa7/5ee8f81c3d6235181dc75518_5%20job%20search%20tools%20at%20work.jpg",
      desc: "Are You Looking For JOB Then Click on See More To Get a Job",
      name: "JOBS",
      linkTo: "/cat/Jobs",
    },
    {
      id: 7,
      // imgUrl:
      //   "https://images.pexels.com/photos/439227/pexels-photo-439227.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      imgUrl: "https://media.istockphoto.com/id/1293762741/photo/modern-living-room-interior-3d-render.jpg?s=612x612&w=0&k=20&c=iZ561ZIXOtPYGSzqlKUnLrliorreOYVz1pzu8WJmrnc=",
        desc: "Are You Looking For Furnitures Then Click on See More To Get a hub",
      name: "FURNITURES",
      linkTo: "/cat/furniture",
    },
    {
      id: 8,
      imgUrl:
        "https://media.istockphoto.com/id/1059548978/photo/technical-support-concept-business-person-touching-helpdesk-icon-on-screen-hotline-assistance.jpg?s=612x612&w=0&k=20&c=ur4WfDWZzBWZ4-k8UdZ5SPxJ9M4r1uRAsgFx6GoBs-4=",
      desc: "Are You Looking For Services Then Click on See More To Get a hub",
      name: "SERVICES",
      linkTo: "/cat/Services1",
    },
    {
      id: 9,
      imgUrl:
        "https://media.istockphoto.com/id/1460007178/photo/library-books-on-table-and-background-for-studying-learning-and-research-in-education-school.webp?b=1&s=170667a&w=0&k=20&c=TRED57BZuROoCEP9kR85pW38PLz32onmM8106OoXeGQ=",
      desc: "Are You Looking For Education Then Click on See More To Get a hub",
      name: "EDUCATION",
      linkTo: "/cat/education1",
    },
    {
      id: 10,
      imgUrl:
        "https://media.istockphoto.com/id/1211554164/photo/3d-render-of-home-appliances-collection-set.jpg?s=612x612&w=0&k=20&c=blm3IyPyZo5ElWLOjI-hFMG-NrKQ0G76JpWGyNttF8s=",
      desc: "Are You Looking For Electronics Then Click on See More To Get a hub",
      name: "ELECTRONICS",
      linkTo: "/cat/electronics",
    },
  ];

  const autoScrollLeft = () => {
    setInterval(() => {

      handleClickNext();
    }, 10000);
  };

  useEffect(() => {
    autoScrollLeft();
  }, []);
  return (
    <>
      <div className="hidden-sm hidden-xs visible-md-block visible-lg-block">
        <div
          className="container-fluid bgcolor"
          style={{
            display: "flex",
            justifyContent: "center",
            height: "45px",
            backgroundColor: "#5EBEC4",
          }}
        >
          <div className="dropdown">
            <Link
              className="categor text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("RealEstate")}
              to={`/ads-listing/category/${"RealEstate"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Real Estate
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  className="maindiv"
                  onClick={(e) => {
                    handleClick("RealEstate", e?.target?.textContent);
                  }}
                  to={`/ads-listing/category/${"RealEstate"}/`}
                >
                  Commercial
                </Link>
                <Link
                  className="subdiv"
                  onClick={(e) =>
                    handleClick(
                      "RealEstate",
                      "Commercial",
                      e.target.textContent
                    )
                  }
                  to={`/ads-listing/category/${"RealEstate"}/`}
                >
                  Buy-Commercial
                </Link>
                <Link
                  className="subdiv"
                  onClick={(e) =>
                    handleClick(
                      "RealEstate",
                      "Commercial",
                      e.target.textContent
                    )
                  }
                  to={`/ads-listing/category/${"RealEstate"}/`}
                >
                  Rent-Commercial
                </Link>
                <Link
                  className="maindiv"
                  onClick={(e) =>
                    handleClick("RealEstate", e?.target?.textContent)
                  }
                  to={`/ads-listing/category/:${"RealEstate"}/subcategory/:${"Kitchenware"}`}
                >
                  Residential
                </Link>
                <Link
                  className="subdiv"
                  onClick={(e) =>
                    handleClick(
                      "RealEstate",
                      "Residential",
                      e.target.textContent
                    )
                  }
                  to={`/ads-listing/category/${"RealEstate"}/`}
                >
                  Buy-Residential
                </Link>
                <Link
                  className="subdiv"
                  onClick={(e) =>
                    handleClick(
                      "RealEstate",
                      "Residential",
                      e.target.textContent
                    )
                  }
                  to={`/ads-listing/category/${"RealEstate"}/`}
                >
                  Rent-Residential
                </Link>
                <Link
                  className="subdiv"
                  onClick={(e) =>
                    handleClick(
                      "RealEstate",
                      "Residential",
                      e.target.textContent
                    )
                  }
                  to={`/ads-listing/category/${"RealEstate"}/`}
                >
                  PG-Hostel
                </Link>
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("Furniture")}
              to={`/ads-listing/category/${"Furniture"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Furnitures
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Furniture", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Furniture"}/`}
                >
                  Furniture For Home & Office
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Furniture", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Furniture"}/`}
                >
                  Home Decors & Furnishings
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Furniture", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Furniture"}/`}
                >
                  Household
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Furniture", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/:${"Furniture"}/subcategory/:${"Kitchenware"}`}
                >
                  Kitchen Ware
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Furniture", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Furniture"}/`}
                >
                  Painting
                </Link>
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("Mobiles")}
              to={`/ads-listing/category/${"Mobiles"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Mobiles
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Mobiles", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Mobiles"}/`}
                >
                  Accessories
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Mobiles", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Mobiles"}/`}
                >
                  Mobile Phones
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Mobiles", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Mobiles"}/`}
                >
                  Tablets
                </Link>
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("Bikes")}
              to={`/ads-listing/category/${"Bikes"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Bikes
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Bikes", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Bikes"}/`}
                >
                  Bikes
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Bikes", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Bikes"}/`}
                >
                  Spare Parts - Accessories
                </Link>
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("Cars")}
              to={`/ads-listing/category/${"Cars"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Cars
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Cars", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Cars"}/`}
                >
                  Cars
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Cars", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Cars"}/`}
                >
                  Spare Parts - Accessories
                </Link>
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("Electronics")}
              to={`/ads-listing/category/${"Electronics"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Electronics
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Air Conditioners - AC
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Laptops Or (Desktops - Computers)
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Air Coolers
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Camera Accessories
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Cameras
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Gas Stove
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Induction Cook Tops
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Microwave Ovens
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Mixer - Grinder - Juicer
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Monitors - Printers - Scanners
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Music System & Home Theatre
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Fridge - Refrigerators
                </Link>
                <Link
                  onClick={(e) => handleClick("Electronics", undefined, "TV")}
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Television(TV)
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Video Games - Consoles
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Washing Machine
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Water Heaters - Geysers
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Electronics", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Electronics"}/`}
                >
                  Water Purifiers
                </Link>
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("Pets")}
              to={`/ads-listing/category/${"Pets"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Pets
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Pets", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Pets"}/`}
                >
                  Pet Care - Accessories​
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Pets", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Pets"}/`}
                >
                  Pet Clinics
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Pets", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Pets"}/`}
                >
                  Pet Food
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Pets", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Pets"}/`}
                >
                  Pet Shop
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Pets", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Pets"}/`}
                >
                  Pet Training & Grooming
                </Link>
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("Education")}
              to={`/ads-listing/category/${"Education"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Education
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Career Counseling
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Training & Certification
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Compitative Exams Coaching
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Distance Learning Courses
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Entrance Exam Coaching
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Hobby Classes
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Play Schools - Creche
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Schools & School Tutions
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Study Abroad Consultants
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Text Books & Study Materials
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Education", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Education"}/`}
                >
                  Vocational Skill Training
                </Link>
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={() => handleClick("Jobs")}
              to={`/ads-listing/category/${"Jobs"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Jobs
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Jobs", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Jobs"}/`}
                >
                  Contract Job
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Jobs", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Jobs"}/`}
                >
                  Full Time Jobs
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Jobs", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Jobs"}/`}
                >
                  Walk-In
                </Link>
                {/*<Link
                  onClick={(e) =>
                    handleClick("Jobs", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Jobs"}/`}
                >
                  Work From Home
                </Link>*/}
              </div>
            )}
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="dropdown">
            <Link
              className="text-white fs-14 fw-900 pb-2"
              onClick={(e) =>
                handleClick("Services")
              }
              to={`/ads-listing/category/${"Services"}/`}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
            >
              Services
            </Link>
            {isDropdownVisible && (
              <div
                className="dropdown-content "
                onMouseEnter={handleContentHover}
                onMouseLeave={handleContentLeave}
              >
                {" "}
                <span
                  className="dropdown-indicator text-white pt-2"
                  style={{ background: "transparent" }}
                >
                  ▲
                </span>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Appliance Repair
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Computer Repair
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Home Cleaning
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Home Help
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Home Repair
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Interior Designing & Home improvements
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Invertor & Batteries
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Kitchen Appliance Repair
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Mobiles & Tablets Repair
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Packers and Movers
                </Link>
                <Link
                  onClick={(e) =>
                    handleClick("Services", undefined, e.target.textContent)
                  }
                  to={`/ads-listing/category/${"Services"}/`}
                >
                  Pest Control
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-lg-none hidden-md visible-xs-block visible-sm-block">
        <SearchForm />
      </div>
      <Searchnew />

      <div
        className="wholeone123 ml-5 mr-5"
        style={isMobile ? mobilestylee : desktopstylee}
      >
        <div className="">
          <div id="slide23" ref={slideRef}>
            {data.map((item) => (
              <div
                onClick={autoScrollLeft}
                key={item.id}
                className="item23"
                style={{ backgroundImage: `url(${item.imgUrl})` }}
              >
                <div className="content234">
                  <div className="name2344">{item.name}</div>
                  <div className="des122">{item.desc}</div>
                  <Link
                    to={item.linkTo}
                    style={{
                      color: "white",
                      background: "linear-gradient(60deg,#0f1c54,#2b224c)",
                      padding: "2px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    See More...!
                  </Link>{" "}
                </div>
              </div>
            ))}
          </div>

          <div className="buttons123 mr-5">
            <button id="prev" onClick={handleClickPrev}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <button id="next" onClick={handleClickNext}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
      </div>

      <br />
      <div className="mx-2 mt-1 hidden-sm hidden-xs visible-md-block visible-lg-block">
        <City />
      </div>

      <div
        className="d-lg-none hidden-md visible-xs-block visible-sm-block "
        style={{ marginTop: "-50px", marginBottom: "-5px" }}
      >
        <City />
      </div>
    </>
  );
};

export default HomeSection;
