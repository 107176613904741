import { useContext, useEffect, useState} from "react";
import { UserContext } from "../../../../App";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { isMobile } from "react-device-detect";
import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import { SearchOutlined } from "@ant-design/icons";

 // Importing the 'UserContext' from the 'App' component
import './allfilter.css'; // Make sure to adjust the path if needed
import { getExtraField } from "../../../../store/ProductFilterSlice";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import {BsFilter} from "react-icons/bs"
import Allcatads from "../../../Allcatads";
import { Button } from "@mui/material";
import MyGallery from "./MyGallery";
import '../Allfilter/Furniturecat.css'
import {
Furniture,
Mobiles,
ProductCondition,
furnitureCondition,
} from "../../../env";
import { stateMain, locationData } from "../../../Location";




// slider shows start in furniture

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

// slider shows end in furniture

  const mobi={


  
  }
  const deski={
    marginLeft:"7rem",marginRight:"7rem"
  }

  const mobstyle = {
    width: "auto",
    marginLeft: "16px",
    marginRight: "16px",
  };
  const deskstyle = {
    padding: "20px",
    marginBottom: "-10px",
    // marginRight: "10rem",
    // marginLeft: "10rem",
  };
  const mobstyle1 = {
    height: "auto",
    width: "90%",
    margin :"0 1rem ",
    bordeRadius: "10px",
    border: "solid black 1.5px",
  };
  const deskstyle1 = {
    height: "47px",
    width: "50%",
bordeRadius: "10px",
    border: "solid black 1.5px",
    textTransform: "uppercase",
  };

const Furniturecat = (props) => {

  const greeting = "Furniture";

  const [selectedFilter, setSelectedFilter] = useState(null);
  // const [selectedFilter1, setSelectedFilter1] = useState(null);
  const [SearchValue, setSearchValue] = useState("");
  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };

  const handleFilterButtonClick = (filterType) => {
    
    setSelectedFilter(filterType);
  };
  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch();
  const dispatch = useDispatch();
   // Accessing the Redux dispatch function

  useEffect(() => {
    disptach(add({ view: ["Furniturecat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleFilterClick = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [sub, setsub] = useState();

  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    searchValue: null,
    category: "Furniture",
    minPrice: null,
    maxPrice: null,
  });
  
  // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  const modalContainerStyle = {
    top: "-50%", // Adjust this value to control the distance from the button

    // width:"100%"
  };

  const modalStyle = {
    // position: "relative",

    // display:"flex",
    // width:"100%",
    top: "-50%", // Adjust this value to control the distance from the button
    left: 0,
    zIndex: 10, // Ensure the modal is above other elements
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };
  const renderModalContent = () => {
    if (selectedButton === "button1") {
      return (
        <div style={modalStyle} className="brandimg">
        <p className={`pricep123 ${types == 'Furniture For Home & Office' ? "types":""}`}
            onClick={() => {
              setsub("Furniture For Home & Office");
              setTypes("Furniture For Home & Office")
            }}
          >Furniture For Home & Office</p>
         <p className={`pricep123 ${types == 'Home Decor - Furnishings' ? "types":""}`}
            onClick={() => {
              setsub("Home Decor - Furnishings");
              setTypes("Home Decor - Furnishings")
            }}
          >Home Decor - Furnishings</p>
         <p className={`pricep123 ${types == 'Household' ? "types":""}`}
            onClick={() => {
              setsub("Household");
              setTypes("Household");
            }}
          >Household</p>
         <p className={`pricep123 ${types == 'Kitchenware' ? "types":""}`}
            onClick={() => {
              setsub("Kitchenware");
              setTypes("Kitchenware")
            }}
          >Kitchenware</p>
         <p className={`pricep123 ${types == 'Painting' ? "types":""}`}
            onClick={() => {
              setsub("Painting");
              setTypes("Painting");
            }}
          >Painting</p>
        </div>
      );
    } else if (selectedButton === "button2") {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${budget === 2000 ?"budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "2000",
              }));
              setBudget(2000);
            }}
          >
            Under 2000
          </p>
          <p
            className={`pricep ${budget === 5000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "5000",
              }));
              setBudget(5000);
            }}
          >
            Under 5000
          </p>
          <p
            className={`pricep ${budget ===7000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "7000",
              }));
              setBudget(7000)
            }}
          >
            Under 7000
          </p>
          <p
            className={`pricep ${budget === 10000?"budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "10000",
              }));
              setBudget(10000)
            }}
          >
            Under 10000
          </p>
          <p
            className={`pricep ${budget === 20000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "20000",
              }));
              setBudget(20000)
            }}
          >
            Under 20000
          </p>
          <p
            className={`pricep ${budget == 50000?"budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "50000",
              }));
              setBudget(50000)
            }}
          >
            Under 50000
          </p>
          <p
            className={`pricep ${budget == 70000?"budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "70000",
              }));
              setBudget(70000)
            }}
          >
            Under 70000
          </p>
          <p
            className={`pricep ${budget == 100000 ? "budget":""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "100000",
                maxPrice: "999999999",
              }));
              setBudget(100000)
            }}
          >
            Above 1Lakh
          </p>
        </div>
      );
    } else if (selectedButton === "button3") {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${condition == 'Brand New'?'condition':""}`}
            onClick={() => {
              setExtraFieldCopy({ "Condition": "Brand New" });
              setCondition("Brand New")
            }}>
           Brand new
          </p>
          <p
            className={`pricep ${condition =='Gently Used'?"condition":""}`}
            onClick={() => {
              setExtraFieldCopy({ "Condition": "Gently Used" });
              setCondition("Gently Used");
            }}>
           Gently Used
          </p>
          <p
            className={`pricep ${condition =='Heavily Used'?'condition':""}`}
            onClick={() => {
              setExtraFieldCopy({ "Condition": "Heavily used" });
              setCondition("Heavily Used");
            }}>
           Heavily used
          </p>
          <p
            className={`pricep ${condition == 'Unboxed'?"condition":""}`}
            onClick={() => {
              setExtraFieldCopy({ "Condition": "Unboxed" });
              setCondition("Unboxed")
            }}>
           Unboxed
          </p>
         
        </div>
      );
    }

    return null;
  };
  const openPopup = () => {
    setPopupOpen(true);
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  const [selectedButton, setSelectedButton] = useState("button1", "furniture");
  const [types,setTypes] = useState(null);
  const [budget,setBudget] = useState(null);
  const [condition,setCondition]=useState(null);


  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);
  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end



  
  return (
    <div>


     {/* AdsCards logic start */}
     {advClose ? (
      <div style={{ width: '400px', height:'200px', position:'fixed', bottom:"2%", right:"3%", padding: '20px',  background: "linear-gradient(to bottom right, #ff7e5f, #feb47b)", borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', transition: 'box-shadow 0.3s ease-in-out',zIndex:"3" }}>
     <span style={{ float: 'right', padding: '6px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"24px" }} onClick={handleCloseadv}>x</span>
  <img src="real-estate-app-icon.png" alt="Real Estate App Icon" style={{ width: '150px', height: '35px', marginBottom: '5px' }} />
  <div style={{ textAlign: 'center' }}>
    <h2 style={{ color: '#fff', fontSize: '20px', textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)' }}>Find Your best suited furniture!</h2>
    <div style={{ display:"flex", flexDirection:"row",justifyContent:"space-evenly" }}>
        <div>
            <div style={{  fontSize: '30px', fontWeight: 'bolder', color: '#1ad09a', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', textAlign: 'center' }}>1,000+<span style={{ fontSize: '14px' }}>subs</span></div>
            <span id="quote" style={{ fontStyle: 'normal',fontWeight:"bolder", fontSize: '12px',  padding: '4px', color:"whitesmoke" }}>Upgrade your plan now!</span>
        </div>
        <Link to='/Pricing' ><button  style={{width:"125px",
        fontWeight:"bolder",
        borderRadius: '5px',
        backgroundColor: '#ffd700',
        padding: '5px 10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        textShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)',color:"white"
    }} > Hurry up! </button></Link> 
          {/* <img src="https://img.freepik.com/free-photo/mid-century-modern-living-room-interior-design-with-monstera-tree_53876-129804.jpg"    style={{height:"145px", width:"120px"}}/> */}
    </div>

  </div>
</div>
      ) : null}
      {/* AdsCards logic end */}



      <div>
       <img src="https://i0.wp.com/metercube.com/wp-content/uploads/2023/06/Mod_Kitchen_Page_Desktop_300_px-01-jpg.webp?fit=1400%2C573&ssl=1"
         />
         <div className="wholemid">
        <div className="filetr2234">
   <div className="furnifil">
   <input
            name="Brand Name"
            required
            className="realserac"
            style={{
              borderRadius:"20px",
              // padding: "2px",
              // position: "relative",
              // height: "47px",
              // width: "30%",
              // zIndex: "2",
              textTransform: "uppercase",
              border:"none",
              // margin
              // border: "2px solid whiteSmoke",
            }}
            placeholder="Search your property"
            onChange={(e) =>
                              setSearchValue(e.target.value.trim())
                            }
            // dropdownClassName="select-dropdown"
          />
          <div className="tildiv">
          

<Link
            to={`/ads-listing1/`}
            onClick={searchArray}
          >
            <button
            className="search-btn-furniture"
            >
              Search
            </button>
          </Link>
<a href="#filopen">
          <button  className="filter-btn-furniture" onClick={handleFilterClick} ><BsFilter/>filter</button></a>
          </div>
   </div>
        </div>
        </div>
       </div>

       
                               {/* side slider */}

         <div className="furniture-cat-slider" id="filopen" >
         <Slider  {...settings}>
          <div className="furniture-item" >
          <a href="#Furnitureforhome">
            <img src="https://i0.wp.com/metercube.com/wp-content/uploads/2023/02/bathroom-accessories-jpg.webp?fit=1280%2C720&ssl=1" alt="" />
            </a>
          </div>
          <div className="furniture-item">
          <a href="#Furnitureforhome1">
            <img src="https://i0.wp.com/metercube.com/wp-content/uploads/2023/02/bed-jpg.webp?fit=1280%2C720&ssl=1" alt="" />
           </a> 
          </div>
          <div className="furniture-item">
          <a href="#Furnitureforhome2">
            <img src="https://i0.wp.com/metercube.com/wp-content/uploads/2023/02/dining-tables-jpg.webp?fit=1280%2C720&ssl=1" alt="" />
          </a>  
          </div>
          <div className="furniture-item">
          <a href="#Furnitureforhome3">
            <img src="https://i0.wp.com/metercube.com/wp-content/uploads/2023/02/center-table-and-side-tables-jpg.webp?fit=1280%2C720&ssl=1" alt="" />
            </a>
          </div>
          <div className="furniture-item">
          <a href="#Furnitureforhome4">
            <img src="https://i0.wp.com/metercube.com/wp-content/uploads/2023/02/outdoor-table-1-jpg-webp.webp?fit=1280%2C720&ssl=1" alt="" />
            </a>
          </div>
         
        </Slider>
        </div>
       
        {
          isPopupOpen&&(

          <div className="popup-container " >
            
            <div>
            <button style={{color:'red',marginLeft:'331px'}} onClick={handleClosePopup}>X</button>
            </div>
            <h6
          name="subCategoryType"
          style={{
            padding: "10px 30px",
            color: "white",
            fontWeight: "bold",
            background: "#2c4354",
            margin:"10px",
            alignItems:"center",
          }}
        >
          Search Furnitures
        </h6>
        <div>
        <div className="dropdown bootstrap-select show-tick houzez-field-textual form-control elementor-size-sm status-js mt-2">
    
    {(
      <div>
        <input
          className="inpstyle"
          placeholder="Select Any Category"
          type="text"
          list="subcategoryFurniture"
          style={{
            padding: "10px 20px",
            width: "90%",
            zIndex: "-50px",
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft:"15px",
          }}
          name="subCategoryValue"
          onChange={handleChange}
        />
        <datalist id="subcategoryFurniture">
          {!searchSending.subCategoryType ||
          searchSending.subCategoryType !== "Accessories"
            ? Furniture.map((result) => {
                return (
                  <option value={result} key={result}>
                    {result}
                  </option>
                );
              })
            : null}
        </datalist>
      </div>
    )}
  </div>
  <div
        style={{
          display: "flex",
          marginTop: "5px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px",width:'43%', }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder=" Min. Price"
          onChange={handleChange}
        />

        <input
          style={{ borderRadius: "10px",width:'43%',marginTop:"" }}
          type="number"
          onChange={handleChange}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="  Max. Price"
        />
      </div>
      <br />
      <input
        className="inpstyle"
        placeholder="location"
        type="text"
        list="state"
        style={{
          padding: "12px",
          width: "90%",
          zIndex: "-50px",
          marginLeft:"15px",
        }}
        name="City"
        onChange={handleChange}
      />
      <datalist id="state">
        {/* Iterate over locationData array */}
        {locationData.map((result) => {
          return <option value={result}>{result}</option>;
        })}
      </datalist>
      <br></br>
      <input
        className="inpstyle"
        placeholder="condition"
        type="text"
        list="condition"
        style={{
          marginTop:'15px',
          padding: "12px",
          width: "90%",
          zIndex: "-50px",
          marginLeft:"15px",
        }}
        onChange={(e) => {
          setCondition(e.target.value);
          setCondition(e.target.value);
        }}
      />
      <datalist id="condition">
                  {furnitureCondition.map((result) => {
                    return <option value={result}>{result}</option>;
                  })}
                </datalist>
        <Button varient="outlined" style={{marginLeft:'261px'}}>
              Search
            </Button>
        </div>
          </div>)
        }
              
       {!loading && ( 
        <>
          <div className="mb-1 mgntp-mnd" style={isMobile ? mobstyle : deskstyle}>
        
            <Feature value="Furniture" />{" "}
            {/* Rendering the 'Feature' component with value "RealEstate" */}
            <div >
            <Allcatads onClick={openPopup} greeting={greeting} subcategory={"Furniture For Home & Office"}/>
            </div>
          </div>
          {/* <div style={isMobile? mobi: deski}> */}
          <div className="mgntp-mnd">
      <TrendingAds/>
      <MyGallery selectedCategory="Furniture" />
      </div></>
        )}
    </div>
  );
};

export default Furniturecat;
