import React, { useState, useEffect } from 'react';
import { FaLocationArrow, FaMap, FaMapMarked, FaMapPin } from 'react-icons/fa';
import { RiMapPinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { location1 } from '../../Location';
import { TbCurrentLocation, TbLocation } from 'react-icons/tb';
import Googleautoloation from './Googlelocation';
import { locationcurrent } from '../../env';

function Autolocation() {
  const [city, setCity] = useState('');
  const [permissionAsked, setPermissionAsked] = useState(false);
  const LocationList = location1;


  const askForPermission = () => {
    const userPermission = window.confirm('Allow this website to access your location?');
    if (userPermission) {
      handleLocationClick();
      localStorage.setItem('geolocationPermission', 'granted');
    } else {
      console.log('User denied geolocation permission.');
    }
    setPermissionAsked(true);
  };

  const handleLocationClick = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDEnLDetZt4PvT03AWs7ZTyzAdA3K-Sz1g`
          );

          if (response.ok) {
            const data = await response.json();
            const { results } = data;
            if (results.length > 0) {
              const cityComponent = results[0].address_components.find(
                (component) => component.types.includes('locality')
              );

              if (cityComponent) {
                const newCity = cityComponent.long_name;
                setCity(newCity);
                // Save the city to local storage
                localStorage.setItem('selectedCity', newCity);
              } else {
                console.log('City not found in location data.');
              }
            }
          } else {
            console.log('Error: Unable to fetch location');
          }
        } catch (error) {
          console.log('Error: Unable to fetch location', error);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleCitySelect = (selectedCity) => {
    setCity(selectedCity);
    // Save the selected city to local storage only if it's selected from the dropdown
    if (LocationList.includes(selectedCity)) {
      localStorage.setItem('selectedCity', selectedCity);
    }
  };
  const [data1, setData1] = useState([]);

  useEffect(() => {
    // Fetch data from your API
    fetch('https://script.google.com/macros/s/AKfycbxj8ZdfzTZ2KLXvS_1nO2VNfcdhCYlehaPqoylrblFhOrhbubBMVigvrG5TEMjBg88/exec?action=getData')
      .then(response => response.json())
      .then(data => setData1(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  useEffect(() => {
    const storedPermission = localStorage.getItem('geolocationPermission');
    if (storedPermission === 'granted') {
      handleLocationClick();
    } else {
      askForPermission();
    }
  }, []);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from your API
    fetch('https://script.google.com/macros/s/'+locationcurrent)
      .then(response => response.json())
      .then(data => setData(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  useEffect(() => {
    // Load the selected city from local storage on component mount
    const storedCity = localStorage.getItem('selectedCity');
    if (storedCity) {
      setCity(storedCity);
    } else {
      // Fetch the current location city and save it to local storage
      handleLocationClick();
    }
  }, []); // Empty dependency array ensures this effect runs only on mount
  const storedCity = localStorage.getItem('selectedCity');

  return (
    <div className="dropdown show">
      <Link
        className="dropdown-toggle"
        to="#"
        role="button"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      ><span style={{fontWeight:"bold",color:"navy"}}>
        <FaMapPin style={{marginTop:"-3px",color:"red",fontSize:"20px"}}/>{storedCity}</span>
      </Link>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <a
          className="dropdown-item"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleLocationClick();
          }}
        >
          <Googleautoloation data={data}/>
          <TbCurrentLocation style={{color:"navy"}}/> <span style={{textTransform:"capitalize",fontFamily:"sans-serif",color:"navy"}}>detect location</span>
        </a>
        {/* <select
          onChange={(e) => handleCitySelect(e.target.value)}
          value={city}
          style={{ marginTop: '10px' }}
        >
          {cities.map((cityOption) => (
            <option key={cityOption} value={cityOption}>
              {cityOption}
            </option>
          ))}
        </select> */}
         <input className='inpstyle' required placeholder='location' type="text" list="locations" style={{width:'100%'}} 
                onChange={(e) => handleCitySelect(e.target.value)} />
         <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              
             <datalist id="locations">
            {LocationList.map(result=>{
             return <option value={result} >{result}</option>
            })
            }
            </datalist>
            </ul>
      </div>
    </div>
  );
}

export default Autolocation;
