export const CreateAds = {
  title: "*Please Enter The Title",
  Price: "*Please Enter The Price",
  Tags: "*Please Enter The Tags",
  Brand: "*Please Enter The Brand",
  Category: "*Please Enter The Category",
  PhoneNumber: "*Please Enter The PhoneNumber",
  State: "*Please Enter The State",
  City: "*Please Enter The City",
  Locality: "*Please Enter The Locality",
  ZipCode: "*Please Enter The ZipCode",
  disc: "*Please Enter The Description",
  userty: "*Please Enter The user type",
};

export const sendMessage = {
  PhoneNumber: "Please Enter Phone Number",
  Message: "Please Enter Message",
  PhoneNumberValidation: "Please enter 10 digit Number",
};
