import { Alert, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { localUrl } from "../env";
import Spiner from "../Spiner";
import { Margin } from "@mui/icons-material";
import Button1 from '@mui/material/Button';
import axios from 'axios';
import "./myBlog.css";
const MyBlog = () => {
  let navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [blogs,setBlogs]=useState([]);

  let barererToken = "Bearer " + localStorage.getItem("access_token");

  var user=localStorage.getItem('userid');
  
  const getBlogs = async () => {
    let loadedResponse=[];
    
    const response = await axios(localUrl + "blogsapi/");
    for(let i in response.data){
      if(response.data[i].user == 96){
         console.log('user',user);
        loadedResponse.push(response.data[i]);
      }
      
    }
    console.log('loadedResponse',loadedResponse);
    setBlogs([...loadedResponse]);
    console.log('blogs',blogs);
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", barererToken);
    myHeaders.append(
      "Cookie",
      "csrftoken=UHWWjOHzO3WiptxXgYHa05GQMsZXWezftGUBXm6hphCOKwEPglvTph3YAUBkSCfS"
    );

    var formdata = new FormData();

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(localUrl + "user/blogsby/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(true);
        console.log('result',result);
        // setBlogs(result);
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
    getBlogs();
    
  //     const getBlogs = async () => {
  //   const response = await axios(localUrl + "blogsapi/");

  //   setBlogs(response.data);
  //   console.log('blogs',response.data);
  //   setTempBlog(response.data);
  // };
      
    
  }, []);

  const editBlogHandler=(i)=>{
   console.log('hi'+i);
   navigate(`/editBlog/${i}`);
  }



  return (
    <>
      
        <div>
          <div>
                      <div>
                          {blogs.length == 0 ? (
                            <Space
                              direction="vertical"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Alert
                                message="No blog"
                                showIcon
                                description="Please post blog "
                                type="warning"
                                action={
                                  <Button
                                    size="small"
                                    danger
                                    onClick={() => navigate("/add-blog/")}
                                  >
                                    Post Blog
                                  </Button>
                                }
                              />
                            </Space>
                          ) : <Button
                          size="big"
                          danger
                          onClick={() => navigate("/add-blog/")} style={{marginTop:"30px",marginBottom:"30px"}}
                        >
                          Post Blog
                        </Button>}
                        </div>
                        <div className="row">
                          {blogs.map((blogs,index) => {
                            
                            return (
                              <div className="card mb-3" >
                                <div className="row g-0 p-2" >
                                  <div className="col-md-12">
                                    {/* "http://hola9.live/adsapi/"+ */}
                                    
                                    <img
                                      src={blogs.image}
                                      className="img-fluid rounded-start"
                                      alt="..." style={{height:"125px"}}
                                    />
                                    
                                  </div>
                                  <div className="col-md-12">
                                    <div className="card-bodyyy">
                                      <h5 className="card-titlee">{blogs.title}</h5>
                                      <p className="card-text_text">{blogs.description}</p>
                                      <p className="card-textt">
                                        <small className="text-mutedd">
                                        {!blogs.published_time
                                    ? "Unknown Date"
                                    : blogs.published_time}
                                        </small>
                                      </p>
                                      <Button1 variant="contained" size='small' color="warning" onClick={()=>editBlogHandler(blogs.id)}>Edit</Button1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          </div>
          </div>
          </div>
      
    </>
  );
};

export default MyBlog;
