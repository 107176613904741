import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const UserDashPlanDetails = () => {
  const { loading, data } = useSelector((state) => state.planData);
  const [showToggleScreen, setShowToggleScreen] = useState(false);

  const handleToggleButtonClick = () => {
    setShowToggleScreen(!showToggleScreen);
  };

  //all ads count
  console.log("!!!data", data);

  const [allAdsCount, setAllAdsCount] = useState({});
  const [staticLoader, setStaticLoader] = useState(false);
  const today = new Date();
  return (
    <div>
      <div className="dp-ud-b">
        {" "}
        <div className="">
          <div className="row ad-history">
            {/* <div className="col-md-12 col-sm-4 col-xs-12"> */}
              <div className="user-stats pl-3 pb-0">
                <div className="inlineAds text-white">
                  <table className="table ">
                    <thead style={{textAlign : 'center'}}>
                      <th className="text-black py-0 pl-0 fw-600">Ads Type</th>
                      <th className="text-black py-0 pl-0">No Of Ads</th>
                      <th className="text-black py-0 pl-0">Ads Left</th>
                      <th className="text-black py-0 pl-0">Plans Validity</th>
                      {/* <th className="text-white py-0 pl-0">Expire Date</th> */}
                    </thead>
                    <tbody>
                      {data.hasOwnProperty("postAdsForm") &&
                        Object?.keys(data?.postAdsForm)?.map((result) => {
                          if (result === "Free") {
                            return (
                              <tr>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>{result}</td>

                              
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>
                                  {
                                    data?.planDataDetails[result][
                                      "PostedregualAds"
                                    ]}
                                </td>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>
                                  {
                                    data?.planDataDetails[result][
                                      "leftRegularAds"
                                    ]
                                  }
                                </td>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>
                                 7 Days
                                </td>

                             
                              </tr>
                            );
                          } else if(result === "Gold") {
                            return (
                              <tr>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>{result}</td>
                              
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>
                                    {
                                      data?.planDataDetails[result][
                                        "PostedregualAds"
                                      ]
                                    }
                                 
                                </td>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>
                                     { data?.planDataDetails[result][
                                        "leftRegularAds"
                                      ]
                                   
                                    }
                                </td>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}s>
                                  15 Days
                                </td>
                                
                              </tr>
                            );
                            
                          }else if(result === "Silver") {
                            return (
                              <tr  style={{textAlign:'center'}}>
                                <td className="m-0 p-0" style={{textAlign:'center'}}>{result}</td>
                              
                                <td className="m-0 p-0" style={{textAlign:'center'}}>
                                  {/* <tr className="m-0 p-0" style={{textAlign:'center'}}> */}
                                    {
                                      data?.planDataDetails[result][
                                        "PostedregualAds"
                                      ]
                                    }
                                  {/* </tr> */}
                                 
                                </td>
                                <td className="m-0 p-0" style={{textAlign:'center'}}>
                                  {/* <tr className="m-0 p-0" > */}
                                    {
                                    
                                      data?.planDataDetails[result][
                                        "leftRegularAds"
                                      ]
                                    }
                                  {/* </tr> */}
                                </td>
                                <td className="m-0 p-0" style={{textAlign:'center'}}>
                                 30 Days
                                </td>
                                
                              </tr>
                            );
                            
                          }
                          else if(result === "Platinum") {
                            return (
                              <tr>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>{result}</td>
                              
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>
                                    {
                                      data?.planDataDetails[result][
                                        "PostedregualAds"
                                      ]
                                    }
                                 
                                </td>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>
                                    {
                                      data?.planDataDetails[result][
                                        "leftRegularAds"
                                      ]
                                    }
                                </td>
                                <td className="m-0 p-0"  style={{textAlign:'center'}}>
                                  60 Days
                                </td>
                               
                              </tr>
                            );
                            
                          }
                        })}
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="user-stats">
          <h2>
            {localStorage.getItem("wishlist")
              ? JSON.parse(decrypt("wishlist")).length
              : 0}
          </h2>
          <small>Favourites Ads</small>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="user-stats">
          <h2>{Blog.length}</h2>
          <small>Total Blogs</small>
        </div>
      </div> */}
          </div>
        </div>
      </div>
    // </div>
  );
};

export default UserDashPlanDetails;
