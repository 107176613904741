import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../Login/PhoneNumber.scss";
import { url } from "../../env";
import hlogo from "../../images/Hola9-logo.jpg";
import validator from "validator";

export default function GetNumber(props) {
  const [inputNumber, setInputNumber] = useState("");
  const [showlogin, setshowlogin] = useState(true);
  const hnd=()=>{
    setshowlogin(false)
  }
  const navigate = useNavigate();
  localStorage.setItem("number", inputNumber);
  console.log(
    "lcaolp phone number",
    localStorage.getItem("number"),
    inputNumber
  );
  const SendOtp = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: url + "api/phone/sendOTP/",
      data: {
        number: inputNumber,
        // validator.isMobilePhone
      },
    })
      .then((res) => {
        if (inputNumber.length == 10) {
          if (res.data.OTPSent === true) {
            localStorage.setItem("phoneNumber", inputNumber);
            //   history.push("/CheckOTP");
            navigate("/CheckOTP");
            setshowlogin(false)
          } else {
            alert("OTP Was Not sent");
          }
        } else {
          alert("Enter Valid 10 Digits Number Only");
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
    {showlogin&&(
    <>
     <div className="popup-overlay">
  <div className="popupf">
    <button className="close" style={{ fontSize: "32px", float: "right", color: "red" }} onClick={hnd}>
      &times;
    </button>
    <br></br>
    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: "20px" }}>
  <div>
  <h1 style={{ fontSize: "24px", fontWeight: "bold", color: "navy", margin: "0" ,borderRight:"solid 2px gray",paddingRight:"20px"}}>Wel<span style={{color:"red"}}>come</span></h1>

  </div>
  <div style={{ marginLeft: "20px" }}>
  <img src={hlogo} alt="hola9" style={{ height: "100%", width: "100%" }} />

  </div>
</div>

    <br />
    <p style={{ fontSize: "16px", color: "CaptionText", margin: "5px 0",fontFamily:"fantasy" }}>Unlock a World of Possibilities</p>

    <p className="h4 mb-4 fw-normal mt-4" style={{fontFamily:"fantasy",color:"navy"}}>
      Login with Mobile Number
    </p>

    <div className="">
      <input  style={{
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "2px solid #007BFF",
    outline: "none",
    width: "100%",
    padding: "5px",
    fontSize: "16px",
    color: "#333",
  }}
  type="text"
  name="Number"
  id="floatingInput"
  placeholder="Mobile Number"
        onChange={(e) => {
          setInputNumber(e.target.value);
        }}
        onKeyDown={(e) => {
          if (!/^[0-9]+$/.test(e.key) && e.key !== "Backspace") {
            e.preventDefault();
          }
        }}
        maxLength={10}
        onInput={(e) => {
          if (e.target.value.length > e.target.maxLength)
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }}
      />
      {inputNumber.length < 10 || inputNumber.length > 10 ? (
        <div style={{ color: "red",fontSize:"small" }}>
          * Please Enter 10 Digit Mobile Number Only
        </div>
      ) : null}
    </div>

    <div className="">
 
    <button style={{ backgroundColor: "#007BFF", color: "white", padding: "10px 20px", borderRadius: "5px", border: "none", cursor: "pointer",marginTop:"20px" }} type="submit"
        onClick={(e) => {
          SendOtp(e);
        }}>Login With OTP</button>

    </div>
  </div>
</div>

    </>
    )}
    </>
  );
}
