import React, { useState, useEffect } from 'react';
import "./Notification.css"
function NotificationComponent() {
  const [notifications, setNotifications] = useState([]);
  const [prevLength, setPrevLength] = useState(0);
  const storedPrevLength = localStorage.getItem('prevLength');
  const userid = localStorage.getItem("userid");

  useEffect(() => {
      fetch(`https://databytess.com/api/adsapi/notifications?user=${userid}`)
        .then(response => response.json())
        .then(data => {
          // Calculate the number of new notifications
          const newNotificationsCount = data.length - storedPrevLength;
          if (newNotificationsCount > 0) {
            // Get the latest notifications
            const latestNotifications = data.slice(data.length - newNotificationsCount);
            // Show popup for each new notification
            latestNotifications.forEach((notification, index) => {
              showPopup(notification.message, index);
            });
          }
          // Update state with new notifications and store the current length
          setNotifications(data);
         
          // Store the current length in local storage
          localStorage.setItem('prevLength', data.length);
        })
        .catch(error => console.error('Error fetching notifications:', error));
    }, []); // Fetch notifications only once on component mount
  
    const showPopup = (message, index) => {
      // Create a new popup for the given message
      const popup = document.createElement('div');
      popup.className = 'popup1234';
      popup.style.bottom = `${index * 70 + 20}px`; // Adjust the spacing between popups
      popup.innerHTML = `<span class="close" onClick="this.parentNode.remove()">&times;</span>
        <p class="mass">${message}</p>`;
      document.body.appendChild(popup);
  
      // Close the popup after 5 seconds
      setTimeout(() => {
        popup.remove();
      }, 5000);
    };

//   return (
//     <div>
//       {/* Render your notifications here */}
//       <ul>
//         {notifications.map(notification => (
//           <li key={notification.id}>{notification.message}</li>
//         ))}
//       </ul>
//     </div>
//   );
}

export default NotificationComponent;
